import AutoSaveDateRange from "../../../components/custom-ui/input-fields/auto-save-date-range";
import AutoSaveText from "../../../components/custom-ui/input-fields/auto-save-field";
import AutoSaveToggle from "../../../components/custom-ui/input-fields/auto-save-toggle";
import AutoSaveWysiwyg from "../../../components/custom-ui/input-fields/auto-save-wysiwyg";
import { collectionNames } from "../../../data/dictionary/collectionNames";
import { Timestamp } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { useMarketingCampaign } from "../../../data/marketing";
import AutoSaveNumber from "../../../components/custom-ui/input-fields/auto-save-number";
import "../../../styles/dangerouslySet.css";
import CampaignCardDetails from "../parts/campaign-card-details";
import { TailwindHoverEnlarge } from "../../../util/tailwind/tailwind-styles";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faCircleXmark,
} from "@fortawesome/pro-regular-svg-icons";

import { useState } from "react";
import { useEffect, forwardRef } from "react";
import { updateAutoSave } from "../../../data/utility";

function CampaignDetails({}) {
  const IMAGE_FOLDER_LOCATION = "images/marketing/wysiwygImages/";
  const { campaignId = "" } = useParams();
  const { data } = useMarketingCampaign(campaignId);
  const [permanentCampaign, setPermanentCampaign] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [newStartDate, setNewStartDate] = useState(null);
  const [newEndDate, setNewEndDate] = useState(null);

  const onDone = (value: any) => {
    setPermanentCampaign(!permanentCampaign);
  };

  useEffect(() => {
    if (permanentCampaign) {
      updateAutoSave(collectionNames.marketing, campaignId, {
        "schedule.dateRange.endDate": null,
      });
    }
  }, [permanentCampaign]);

  useEffect(() => {
    if (data) {
      setStartDate(data?.schedule?.dateRange?.startDate?.toDate() || null);
      setEndDate(data.schedule?.dateRange?.endDate?.toDate() || null);
    }
  }, [data]);

  async function saveDate(id: string, field: string, value: any) {
    await updateAutoSave("Marketing", id, {
      [field]: value,
    });
  }

  useEffect(() => {
    if (newEndDate !== null) {
      saveDate(data.id, "schedule.dateRange.endDate", newEndDate);
      setNewEndDate(null);
    }
  }, [newEndDate]);

  useEffect(() => {
    if (newStartDate !== null) {
      saveDate(data.id, "schedule.dateRange.startDate", newStartDate);
      setNewStartDate(null);
    }
  }, [newStartDate]);

  const CustomDateInput = forwardRef(
    (
      { value, onClick, disabled }: { value: any; onClick: any; disabled: any },
      ref: any,
    ) => (
      <div id={data?.id} className="custom-date-input w-full">
        <input
          ref={ref}
          className="px-8 w-full"
          type="text"
          value={value}
          onClick={onClick}
          onChange={(e) => {
            e.preventDefault();
          }}
          placeholder="Select date"
        />
        <span className="absolute left-2 top-1.5" onClick={onClick}>
          <FontAwesomeIcon
            icon={faCalendarDays}
            className="text-instant-teams-gray-D1"
          />
        </span>
      </div>
    ),
  );

  return (
    <>
      {data && (
        <div className="flex justify-around md:flex-col lg:justify-between space-x-1 lg:space-x-1 ">
          <div
            className={
              TailwindHoverEnlarge(103) +
              `w-1/2 md:w-full  shadow-input-field p-8 bg-white rounded-lg `
            }
          >
            <div>
              <p className="mb-8 p-1 text-xl text-instant-teams-teal-Main font-bold  flex justify-center">
                {data?.campaignName}
              </p>
            </div>

            <div className="">
              <AutoSaveText
                value={data?.title}
                collection={collectionNames.marketing}
                recId={data?.id}
                required={true}
                name="title"
                display="Salutation"
                placeholder={"Hello There Seeker!"}
                directions=""
                readOnly={false}
              />
            </div>
            <div className="my-2 space-y-5">
              <p className="text-instant-teams-teal-Main font-bold p-1">
                Schedule Information
              </p>
              <div className="mb-12">
                <AutoSaveToggle
                  value={data?.active}
                  collection={collectionNames.marketing}
                  id={data?.id}
                  name={"active"}
                  on={"Active"}
                  off={"Inactive"}
                />
              </div>
              <div className="grid grid-cols-4">
                <div className="mb-5">
                  <div id={"displayDates"} className="flex items-center">
                    <button
                      type="button"
                      className={`cursor-pointer ${
                        permanentCampaign
                          ? "bg-instant-teams-green-Main"
                          : "bg-gray-300"
                      } relative inline-flex items-center h-6 rounded-full w-11`}
                      onClick={onDone}
                    >
                      <span
                        className={`${
                          permanentCampaign ? "translate-x-6" : "translate-x-1"
                        } inline-block w-4 h-4 transform bg-white rounded-full`}
                      />
                    </button>
                    <span className="ml-3">
                      {permanentCampaign ? "Permanent" : "Seasonal"}
                    </span>
                  </div>
                </div>
                {!permanentCampaign ? (
                  <div className="col-span-4 grid grid-cols-2 gap-2">
                    <DatePicker
                      showIcon
                      minDate={startDate}
                      selected={startDate}
                      onChange={(date: any) =>
                        setNewStartDate(Timestamp.fromDate(date))
                      }
                      customInput={<CustomDateInput />}
                    />
                    <DatePicker
                      showIcon
                      minDate={startDate}
                      selected={endDate}
                      onChange={(date: any) =>
                        setNewEndDate(Timestamp.fromDate(date))
                      }
                      customInput={<CustomDateInput />}
                    />
                  </div>
                ) : null}
              </div>
              <div className="grid grid-cols-2 ">
                <div className="mb-12">
                  <AutoSaveToggle
                    value={data?.schedule?.showOnLogIn}
                    collection={collectionNames.marketing}
                    id={data?.id}
                    name={"schedule.showOnLogIn"}
                    on={"Show Everytime"}
                    off={"Show Daily"}
                  />
                </div>

                {data?.schedule?.showOnLogIn ? (
                  <AutoSaveNumber
                    value={
                      data?.schedule?.showCount !== undefined
                        ? data?.schedule?.showCount
                        : "0"
                    }
                    collection={collectionNames.marketing}
                    recId={data?.id}
                    required={false}
                    name={"schedule.showCount"}
                    display={"Show Count"}
                    placeholder={"42"}
                    directions={`0 = Always`}
                    readOnly={false}
                    minValue={0}
                    maxValue={365}
                  />
                ) : null}
              </div>
            </div>
            <div className="my-5">
              <AutoSaveWysiwyg
                value={data?.body}
                collection={collectionNames.marketing}
                recId={data?.id}
                required={true}
                rows={15}
                name={"body"}
                display={"Card Image"}
                placeholder={"Add a Description"}
                directions={""}
                readOnly={false}
                maxLength={500}
                imageFolderLocation={IMAGE_FOLDER_LOCATION}
              />
            </div>
            <div>
              <p className="py-5 text-instant-teams-teal-Main font-bold">
                Button Information
              </p>
              <div className="grid grid-cols-2 gap-4 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 ">
                <div className="col-span-2">
                  <AutoSaveToggle
                    value={data?.button?.showButton}
                    collection={collectionNames.marketing}
                    id={data?.id}
                    name={"button.showButton"}
                    on={"Yes"}
                    off={"No"}
                  />
                </div>
                {data?.button?.showButton ? (
                  <>
                    <div className="col-span-1">
                      <AutoSaveText
                        value={data?.button?.text}
                        collection={collectionNames.marketing}
                        recId={data?.id}
                        required={true}
                        name="button.text"
                        display="Button Text"
                        placeholder={"Open"}
                        directions=""
                        readOnly={false}
                      />
                    </div>
                    <div className=" col-span-1">
                      <AutoSaveText
                        value={data?.button?.link}
                        collection={collectionNames.marketing}
                        recId={data?.id}
                        required={true}
                        name="button.link"
                        display="Link"
                        placeholder={"Full URL"}
                        directions=""
                        readOnly={false}
                      />
                    </div>
                    <div className="col-span-1">
                      <AutoSaveText
                        value={data?.button?.bgColor}
                        collection={collectionNames.marketing}
                        recId={data?.id}
                        required={false}
                        name="button.bgColor"
                        display="Background Color"
                        placeholder={"#000"}
                        directions="Hex Value w/ #"
                        readOnly={false}
                      />
                    </div>
                    <div className="col-span-1">
                      <AutoSaveText
                        value={data?.button?.textColor}
                        collection={collectionNames.marketing}
                        recId={data?.id}
                        required={false}
                        name="button.color"
                        display="Text Color"
                        placeholder={"#FFF"}
                        directions="Hex Value w/ #"
                        readOnly={false}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>

          <div className={TailwindHoverEnlarge(103) + `w-80 `}>
            <p className="my-1 text-center text-instant-teams-blue-Main font-semibold">
              Preview
            </p>
            <div className="w-full bg-white p-8 rounded-xl shadow-xl">
              <CampaignCardDetails data={data} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CampaignDetails;
