function Avatar({
  user,
}: {
  user: { photoURL: string; status: string; name: string };
}) {
  return (
    <img
      src={user?.photoURL ?? "/images/default_user.png"}
      className={`rounded-full h-12 w-12 border-solid border-4 my-auto ${
        user?.status !== "active"
          ? " border-instant-teams-red-Main"
          : "border-instant-teams-green-D1"
      }`}
      alt={`${user?.name?.toUpperCase()}`}
    />
  );
}

export default Avatar;
