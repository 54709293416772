import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useJobInterviewQuestions } from '../../../data/jobDescriptions';
import EInterviewJobDefault from './einterview-job-default';
import EInterviewJobCustom from './einterview-job-custom';


function QuestionListJob() {
  const params = useParams();
  const [jobId, setJobId] = useState(
    params.id,
  );
  const [employerId, setEmployerId] = useState(
    params.employerId,
  );
  const [eInterviewSource, setEInterviewSource] = useState("default");
  const { data: jobQuestions, status: eqStatus, error } = useJobInterviewQuestions(jobId);

useEffect(() => {
  if(jobQuestions && jobQuestions.length > 0 ){
    setEInterviewSource("job");
  }
}, [jobQuestions, jobId])

  return (
    <>
      {eInterviewSource === "job" && (
        <EInterviewJobCustom jobId={jobId}/>
      )}
      {eInterviewSource === "default" && (
        <EInterviewJobDefault jobId={jobId} employerId={employerId} callback={setEInterviewSource}/>
      )}
    </>
  );
}
export default QuestionListJob;
