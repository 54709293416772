import { useEffect, useState } from "react";
import { orderBy, isEmpty, isNil } from "lodash";
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import { Handle, Track, TooltipRail } from "./range-slider/RangeSlider";
import { updateAutoSave } from "../../../data/utility";
import AutoSaveWysiwyg from "./auto-save-wysiwyg";
import {
  collectionNames,
  subCollectionNames,
} from "../../../data/dictionary/collectionNames";
import { useParams } from "react-router-dom";

function AutoSaveShiftSchedule({
  optionsDays,
  optionsShift,
  name,
  initialValues = [],
  collection,
  recId,
  disabled = false,
}) {
  const params = useParams();

  const { id: jobId = "", positionId = "", employerId = "" } = params ?? {};
  const [daySelections, setDaySelections] = useState(initialValues?.days ?? []);
  const [range, setRange] = useState(initialValues?.range ?? [420, 1020]);

  const [shiftOption, setShiftOption] = useState(
    initialValues?.shiftOption ?? {},
  );
  /**
   * shiftSchedule
   * range values are minutes past midnight
   * {days: [{code: "MON", label: "Monday", sortOrder: 1}, {code: "TUE", label: "Tuesday", sortOrder: 2}], range: [780, 1185], shiftOption: }
   */
  const [shiftSchedule, setShiftSchedule] = useState(initialValues ?? {});

  const standardDays = optionsDays.filter(
    (day) => day.code !== "SAT" && day.code !== "SUN",
  );
  const standardHours = [540, 1020];

  useEffect(() => {
    if (shiftOption.code === "STANDARD") {
      setShiftSchedule({
        days: standardDays,
        range: standardHours,
        shiftOption,
      });
    } else if (shiftOption.code === "CUSTOM") {
      setShiftSchedule({
        // custom schedules dont have days or time range, only the description is relevant
        days: [{}], // allows validation to pass
        range: [0, 0], // allows validation to pass
        shiftOption,
      });
    } else {
      setShiftSchedule({ days: daySelections, range, shiftOption });
    }
  }, [daySelections, range, shiftOption]);

  // set selected days and update shiftShedule with default values
  // when new day is added
  const handleChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setDaySelections((prevSelections) => [
        ...prevSelections,
        JSON.parse(value),
      ]);
    } else {
      const omittedUnchecked = daySelections.filter(
        (item) => item.code !== JSON.parse(value).code,
      );
      setDaySelections(omittedUnchecked);
    }
  };

  const handleRadioChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setShiftOption(JSON.parse(value));
    }
  };

  // update shiftSchedule moment slider movement ends
  const handleSlideEnd = (range) => {
    setRange(range);
  };

  useEffect(() => {
    if (!isEmpty(shiftSchedule) && !isNil(shiftSchedule)) {
      updateAutoSave(collection, recId, { shiftSchedule });
    }
  }, [shiftSchedule]);

  return (
    <div id={name} className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
      <div className="mb-4 col-span-3">
        <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
          {optionsShift.map((item) => (
            <div key={item.id} className="flex items-start ">
              <>
                <input
                  className="h-5 w-5 border-gray-300 focus:ring-2 focus:ring-blue-300"
                  id={item.id}
                  name={name ?? "radioGroup"}
                  type="radio"
                  value={JSON.stringify(item)}
                  onChange={handleRadioChange}
                  checked={initialValues?.shiftOption?.code === item.code}
                  disabled={disabled}
                />
                <label
                  className="mb-1 px-2 text-sm  font-semibold text-instant-teams-blue-Main"
                  htmlFor={item.id}
                >
                  {item.label}
                </label>
              </>
            </div>
          ))}
        </div>
      </div>
      {/* Show days selection and time slider if Non-Standard shift option */}
      {shiftOption.code === "NONSTANDARD" && (
        <>
          {optionsDays.map((item) => (
            <div key={item.id} className="flex items-start ">
              <>
                <input
                  className="h-5 w-5 shadow checked:bg-instant-teams-blue-Main focus:bg-instant-teams-blue-L1 leading-8 bg-white rounded border-instant-teams-blue-Main border-gray-300 outline-none focus:border-instant-teams-blue-Main focus:ring-1"
                  id={item.id}
                  name={name ?? "checkboxGroup"}
                  type="checkbox"
                  value={JSON.stringify(item)}
                  onChange={handleChange}
                  checked={
                    !!initialValues.days.find((val) => val.code === item.code)
                  }
                  disabled={disabled}
                />
                <label
                  className="mb-1 px-2 text-sm  font-semibold text-instant-teams-blue-Main"
                  htmlFor={item.id}
                >
                  {item.code}
                </label>
              </>
            </div>
          ))}

          <div className="col-span-3">
            <div className="h-16">
              <div className="mt-12 col-span-2">
                {/* TimeSlider is defined below  */}
                <TimeSlider
                  initialValues={initialValues?.range}
                  handleSlideEnd={handleSlideEnd}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {shiftOption.code === "CUSTOM" && (
        <div className="col-span-3">
          <AutoSaveWysiwyg
            value={shiftOption.description}
            rows={10}
            collection={`${collectionNames.jobDescriptions}/${jobId}/${subCollectionNames.positions}/`}
            recId={positionId}
            required={false}
            name="shiftSchedule.shiftOption.description"
            display="Shift Descritpion"
            placeholder={"Client Provided Custom Schedule"}
            directions=""
            readOnly={false}
            editorStyle={{ height: "240px" }}
          />
        </div>
      )}
    </div>
  );
}

function TimeSlider({ initialValues, handleSlideEnd, disabled = false }) {
  function validateInitialValues(initialValues) {
    // check if initialValues from db contain valid numbers, if so then
    // initialize the "values" array with those numbers
    // we dont want to override db if data is valid where valid data
    // we consider valid data an array of numbers
    if (
      Array.isArray(initialValues) &&
      initialValues.length > 0 &&
      !initialValues.some(isNaN)
    ) {
      return [...initialValues];
    } else {
      // minutes past midnight
      // 9:00am to 5:00pm
      return [540, 1020];
    }
  }

  function formatTooltip(minutesFromMidnight) {
    const hr = minutesFromMidnight / 60;
    const min = minutesFromMidnight % 60;

    const amOrPm = hr < 12 ? "am" : "pm";
    const meridiem = hr % 12 || 12;
    return `${Math.floor(meridiem) || 12}:${min ? min : "00"} ${amOrPm}`;
  }

  // minutes past midnight 0 to 24 * 60
  const minMaxRange = [0, 1440];

  const sliderStyle = {
    position: "relative",
    width: "100%",
  };

  return (
    <>
      <div>
        <Slider
          mode={3}
          step={15}
          domain={minMaxRange}
          rootStyle={sliderStyle}
          onSlideEnd={(range) => handleSlideEnd(range)}
          values={validateInitialValues(initialValues)}
          disabled={disabled}
        >
          <Rail>{(railProps) => <TooltipRail {...railProps} />}</Rail>
          <Handles>
            {({ handles, activeHandleID, getHandleProps }) => {
              return (
                <div className="slider-handles">
                  {handles.map((handle) => (
                    <Handle
                      key={handle.id}
                      handle={handle}
                      domain={minMaxRange}
                      isActive={handle.id === activeHandleID}
                      getHandleProps={getHandleProps}
                      formatTooltip={formatTooltip}
                    />
                  ))}
                </div>
              );
            }}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
        </Slider>
      </div>
    </>
  );
}

export default AutoSaveShiftSchedule;
