import React, { useEffect, useState } from "react";
import { useUtilitySearch } from "../data/utility";

function SingularCount({ title, collection, field, status, id, name }) {
  const { data: searchValues } = useUtilitySearch(
    collection,
    name,
    field,
    status,
    id,
  );

  return (
    <>
      <div className="w-full mb-4 shadow-lg rounded-b-lg">
        <div className="flex flex-wrap justify-center text-sm mb-2 bg-instant-teams-teal-Main text-white p-2 rounded-t-lg uppercase">
          {title}
        </div>
        <div className="flex flex-wrap justify-center text-2xl text-instant-teams-teal-Main font-black pb-2">
          {searchValues?.length || 0}
        </div>
      </div>
    </>
  );
}

export default SingularCount;
