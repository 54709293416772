import { Outlet } from "react-router-dom";
import { requireAuth } from "../../../providers/auth";
import useKeepNavbarActiveTab from "../../../hooks/useKeepNavbarActiveTab";

function AcademyOutlet() {
  useKeepNavbarActiveTab("dashboard", "learning");
  return (
    <div>
      <div className="md:container w-full">
        <Outlet />
      </div>
    </div>
  );
}
export default requireAuth(AcademyOutlet);
