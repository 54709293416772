/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useQuery,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  doc,
  getDocs,
  collection,
  query,
  where,
  orderBy,
  addDoc,
  limit,
  deleteDoc,
} from "firebase/firestore";

import { db, createQuery } from "../providers/database";
import { SyncRequest, EnrollmentProcess } from "../dataTypes/academy";
import { collectionNames } from "./dictionary/collectionNames";


export function addRotatorImage(
  data: Object,
) {
  return addDoc(
    collection(
      db,
      `${collectionNames.rotatorImages}`,
    ),
    {...data},
  );
}

// Temporary List of all Components
export function useRotatorImages(trigger: string){
  return useQuery(
    ["images", !!trigger],
    createQuery(() =>
      query(
        collection(db, collectionNames.rotatorImages),
      ),
    ),
    { enabled: !!trigger },
  );
}

export function useRotatorImagesByStatus(status: string){
  return useQuery(
    ["images", !!status],
    createQuery(() =>
      query(
        collection(db, collectionNames.rotatorImages),
        where("status", "==", status),
      ),
    ),
    { enabled: !!status },
  );
}

export function useRotatorImage(id: string) {
  return useQuery(
    ["image", { id }],
    createQuery(() => doc(db, collectionNames.rotatorImages, id)),
    { enabled: !!id },
  );
}

export function deleteImage(id: string) {
  return deleteDoc(doc(db,`${collectionNames.rotatorImages}/`,id,),);
}