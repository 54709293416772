import { useEffect, useState } from "react";
import { updateAutoSave } from "../../../data/utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../styles/IconCheckboxGroup.css";

function AutoSaveCheckboxIconsGroup({
  options,
  initialValues = [],
  collection,
  id,
  name,
  disabled = false,
  ...props
}) {
  const [selections, setSelections] = useState(initialValues ?? []);
  // wait until mount before trying to write to db.
  const [trigger, setTrigger] = useState();
  useEffect(() => {
    setTrigger(true);
  }, []);

  useEffect(() => {
    if (trigger) {
      updateAutoSave(collection, id, { [name]: selections })
        .then(() => console.log("AutoSaveCheckbox saved selections..."))
        .catch((error) => console.log(error));
    }
  }, [selections]);

  const handleChange = (e) => {
    const { value, checked } = e.target;
    console.log("Checked: ", value, checked);
    if (checked) {
      setSelections((prevSelections) => [...prevSelections, value]);
    } else {
      const omittedUnchecked = selections.filter((item) => item !== value);
      setSelections(omittedUnchecked);
    }
    console.log("AutoSaveCheckbox values: ", selections);
  };

  return (
    <div id={name} className="grid grid-cols-1">
      {options.map((item) => (
        <div key={item.id}>
          <div className="icon-checkbox mb-2">
            <label
              className="
              cursor-pointer text-sm font-semibold text-instant-teams-blue-Main"
              htmlFor={item.id}
            >
              <input
                className="align-baseline cursor-pointer h-6 w-6 mr-2 text-instant-teams-blue-L1 leading-8 bg-white rounded  outline-none focus:border-instant-teams-blue-Main focus:ring-1"
                id={item.id}
                name={name ?? "checkboxGroup"}
                type="checkbox"
                value={item.code}
                onChange={handleChange}
                disabled={disabled}
                checked={
                  // only run find if initialValues is an array, otherwise return false
                  Array.isArray(initialValues)
                    ? initialValues.find((val) => val === item.code)
                      ? true
                      : false
                    : false
                }
              />
              <FontAwesomeIcon
                className="mr-2 ml-2 text-center align-baseline"
                icon={["fas", `${item.icon}`]}
                size={"2x"}
              />
              <span className="pl-2 text-base">{item.label}</span>
            </label>
          </div>
        </div>
      ))}
    </div>
  );
}

export default AutoSaveCheckboxIconsGroup;
