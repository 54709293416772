import { JobDescription } from "../dataTypes/JobDescription";
import firebase from "firebase/app";
import {
  useQuery,
  useQueryClient,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  getFirestore,
  connectFirestoreEmulator,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  limit,
  runTransaction,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { db, client, createQuery, format } from "../providers/database";

import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";

import { outreachList } from "../callable-cloud-functions/cloudFunctions";

export function useOutreachList(jobId: string) {
  return useQuery(
    ["outreachList", { jobId }],
    async () => {
      const result = await outreachList({ jobId });
      return result;
    },
    {
      enabled: !!jobId,
    },
  );
}

export function refetchOutreachList(queryClient: QueryClient, jobId: string) {
  queryClient.invalidateQueries(["outreachList", { jobId }]);
}
