import { useApplicationsByEmployer } from "../../../data/applications";
import RedactedApplicationItem from "../../application/RedactedApplicationItem";
import { useAuth } from "../../../providers/auth";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import {
  SearchBox,
  Hits,
  Configure,
  HitsPerPage,
  Pagination,
} from "react-instantsearch-hooks-web";

const ExternalApplicants = () => {
  const { userClaims } = useAuth();

  return (
    <>
      <GreenUnderlineHeading title="Applications" />
      <div className="flex">
        <div className="w-1/5 pr-4">
          <div className="mt-[18%] ">
            <SearchBox
              placeholder="Keyword Search"
              classNames={{
                input: "w-full",
                submitIcon: "hidden",
                resetIcon: "hidden",
                submit: "hidden",
              }}
            />
          </div>
        </div>
        <div className="w-4/5">
          <div className="flex justify-end w-full">
            <div className=" pr-4">Results per page:</div>
            <HitsPerPage
              items={[
                { label: "10", value: 10, default: true },
                { label: "25", value: 25 },
                { label: "50", value: 50 },
                { label: "100", value: 100 },
              ]}
              classNames={{ select: "text-xs h-6 py-0" }}
            />
          </div>
          <Configure
            filters={`employer:${userClaims.claims.employerId} AND status:presented OR status:conversation`}
          />
          <Hits
            hitComponent={({ hit }: { hit: any }) => (
              <RedactedApplicationItem
                application={hit}
                showJobDescription={false}
              />
            )}
            classNames={{ list: " mt-4" }}
          />

          <div className="mt-12 w-full flex justify-center">
            <Pagination
              showNext={true}
              padding={1}
              classNames={{
                list: "flex",
                item: "h-8 w-8 text-center rounded-m rounded mx-1 bg-white",
                selectedItem: "scale-125 border-black",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExternalApplicants;
