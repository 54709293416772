import React from "react";
import DisplaySkillNames from "../../components/custom-ui/DisplaySkill/DisplaySkillNames";
import { faTrumpet } from "@fortawesome/pro-duotone-svg-icons";
import { useLookup } from "../../data/lookup";
import { useState } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import DropdownCheckbox from "./DropDown";

const CohortSideBar = ({ skills, tacVerified, exactMatch, selectedOptions, setSelectedOptions, affiliation, setAffiliation }: any) => {

    const [hide, setHide] = useState()
    const toggleHide = () => {
        setHide(!hide);
    };
    const handleAffiliationChange = (key: string) => {
        setAffiliation((prevState: any) => ({
            ...prevState,
            [key]: prevState[key] === false ? true : false
        }));
    };

    return (
        <div className=" basis-1/6 mt-[49px] overflow-y-auto no-scrollbar ">
            <div className="px-2">
                <div className="bg-white shadow-md rounded-md p-3 mb-2">
                    <div className="flex justify-between items-center h-2 py-2">
                        <p className="font-bold text-base">Search Criteria</p>
                        <div className="text-sm" >
                            <button onClick={toggleHide} className="focus:outline-none ">
                                {hide ? (
                                    <FontAwesomeIcon icon={faChevronDown} />
                                ) : (
                                    <FontAwesomeIcon icon={faChevronUp} />
                                )}
                            </button>
                        </div>

                    </div>
                    {!hide && (
                        <>
                            <div className="flex py-2 text-[12px] justify-between items-center">
                                <div className="flex w-full">
                                    <p>TAC Verified:</p>
                                    <p>{tacVerified ? "\u00A0ON" : "\u00A0OFF"}</p>
                                </div>
                                <div className="flex items-center justify-end w-full">
                                    <p>Exact Match:</p>
                                    <p>{exactMatch ? "\u00A0ON" : "\u00A0OFF"}</p>
                                </div>
                            </div>
                            <div>
                                {skills?.map((skill: any, index: number) => (
                                    <DisplaySkillNames
                                        key={index}
                                        skillname={skill.skill}
                                        displayCloseIcon={false}
                                        removeSkill={() => { }}
                                        customSkill={skill.customSkill}
                                        sizeSmall={true}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                </div>
                <div className="bg-white min-h-[60vh] m-0 shadow-md rounded-md p-3">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                        <div className="flex flex-col space-y-1">
                            <label htmlFor="location" className="whitespace-nowrap font-bold text-base ">
                                Filter by Location
                            </label>
                            <DropdownCheckbox
                                selectedOptions={selectedOptions}
                                toggleSerachResult={setHide}
                                setSelectedOptions={setSelectedOptions} />

                        </div>

                        <div className="flex flex-col space-y-2 pb-10 h-auto ">
                            <label
                            
                                htmlFor="militaryAffiliation"
                                className="whitespace-nowrap mb-2 font-bold text-base"
                            >
                                Filter by Military Affiliation
                            </label>
                            <div className="flex items-center space-x-2">
                                <input
                                    type="checkbox"
                                    id="hybrid"
                                    value="hybrid"
                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                    onChange={() => handleAffiliationChange("milVerified")}
                                />
                                <label htmlFor="hybrid " className="text-[14px] text-gray-700">Military Verified</label>
                            </div>
                            <div className="flex items-center space-x-2">
                                <input
                                    type="checkbox"
                                    id="hybrid"
                                    value="hybrid"
                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                    onChange={() => handleAffiliationChange("milSpouse")}
                                />
                                <label htmlFor="hybrid" className="text-[14px] text-gray-700">Military Spouse</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CohortSideBar;