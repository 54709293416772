import { useEffect, useState } from "react";
import { useJobPositionDetails } from '../../../../data/positions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SeekerPositionListItem({ jobId, positionId }:{ jobId: string, positionId: string }) {
  const { data: positionData } = useJobPositionDetails(jobId, positionId);  

  return (
    <>
      {positionData && (
        <div className="pr-4">
          <FontAwesomeIcon
            className="mr-2 align-middle text-instant-teams-green-D2"
            // @ts-ignore
            icon={["fas", "map-pin"]}
            size="sm"
          />
          {positionData.positionName.split('|')[0]}
        </div>  
      )}
    </>
  );
}

export default SeekerPositionListItem;
