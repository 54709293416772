/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ToastAlert from "../../../custom-ui/toast-alert/ToastAlert";
import { useUser } from "../../../../providers/database";
import { useEnrollmentsByEmail } from "../../../../data/enrollments";
import Badge from "../../../enrollments/badge";
import GreenUnderlineHeading from "../../../custom-ui/headings/heading";
import SkillManagement from "../../settings/SkillManagement";

function UserEnrollments() {
  const params = useParams();
  const [id, setId] = useState(params.userId);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  // user id from params may be labeled userId or seekerId
  const {
    data: userData,
    status: userStatus,
    error: userError,
  } = useUser(id ?? params.seekerId);
  useEffect(() => {
    setEmail(userData?.email);
  }, [userData]);

  const {
    data: enrollments = [],
    error: enrollmentsErrors,
    status: enrollmentStatus,
  } = useEnrollmentsByEmail(email);

  const completedEnrollments = enrollments.filter(
    (item: any) => item.status === "completed",
  );

  const invitedEnrollments = enrollments.filter(
    (item: any) => item.status === "invited",
  );

  return (
    <div>
      <div>
        {userData && <SkillManagement userId={userData?.id} tacView={true} />}
      </div>
      <GreenUnderlineHeading title="Earned" />
      <div className="w-full grid grid-cols-6">
        {completedEnrollments &&
          completedEnrollments.map((enrollment: any) => (
            <Badge
              key={enrollment.id}
              enrollment={enrollment}
              type={"earned"}
            />
          ))}
        <ToastAlert />
      </div>
      <GreenUnderlineHeading title="In Progress" />
      <div className="w-full grid grid-cols-6">
        {invitedEnrollments &&
          invitedEnrollments.map((enrollment: any) => (
            <Badge
              key={enrollment.id}
              enrollment={enrollment}
              type={"invited"}
            />
          ))}
        <ToastAlert />
      </div>
    </div>
  );
}

export default UserEnrollments;
