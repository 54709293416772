
import {
  getFirestore,

  collection,
  getDocs,
} from "firebase/firestore";

import { firebaseApp } from "../providers/firebase";
import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";

export const db = getFirestore(firebaseApp);

export const manageConfiguration = async () => {
  try {
    const configuration = collection(db, "Configuration");
    const querySnapshot = await getDocs(configuration);
    
    if (!querySnapshot.empty) {
      const manageConfiguration = querySnapshot.docs.map(doc => doc.data());
      return manageConfiguration;
    } else {
      console.log('No matching documents.');
      return [];
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};
