import { useEffect, useState } from "react";
import { updateAutoSave } from "../../../data/utility";

function AutoSaveRadioGroup({
  options,
  initialValue,
  collection,
  id,
  name,
  disabled = false,
  ...props
}) {
  const [selection, setSelection] = useState(initialValue ?? "");

  // wait until mount before trying to write to db.
  const [trigger, setTrigger] = useState();
  useEffect(() => {
    setTrigger(true);
  }, []);

  useEffect(() => {
    if (trigger) {
      updateAutoSave(collection, id, { [name]: selection })
        .then(() => console.log("AutoRadiosaved selections..."))
        .catch((error) => console.log(error));
    }
  }, [selection]);

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelection(value);
    }
  };

  return (
    <div id={name} className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
      {options.map((item) => (
        <div key={item.id} className="flex items-start ">
          <>
            <input
              // className=" shadow checked:bg-instant-teams-blue-Main focus:bg-instant-teams-blue-L1 leading-8 bg-white rounded border-instant-teams-blue-Main border-gray-300 outline-none focus:border-instant-teams-blue-Main focus:ring-1"
              className="h-5 w-5 border-gray-300 focus:ring-2 focus:ring-blue-300"
              id={item.id}
              name={name ?? "radioGroup"}
              type="radio"
              value={item?.code}
              onChange={handleChange}
              checked={initialValue === item.code ? true : false}
              disabled={disabled}
            />
            <label
              className="mb-1 px-2 text-sm  font-semibold text-instant-teams-blue-Main"
              htmlFor={item.id}
            >
              {item.label}
            </label>
          </>
        </div>
      ))}
    </div>
  );
}

export default AutoSaveRadioGroup;
