import React from "react";
import { Transition, Dialog } from "@headlessui/react";
import { useAuth } from "../../../providers/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function DialogWrapper({
  children,
  title,
  size = "max-w-md",
  onClose,
  titleColor = "bg-instant-teams-teal-Main",
  bgColor = "bg-white",
  displayCloseIcon = false
}: {
  children: JSX.Element;
  title: string;
  size?: string;
  titleColor?: string;
  bgColor?: string;
  displayCloseIcon?: boolean;
  onClose: () => void;
}) {
  const auth = useAuth();
  const user = auth?.user;

  return (
    <>
      <Transition appear={true} show={true}>
        <Dialog
          onClose={onClose}
          className="fixed inset-0 z-50 mb-4  flex items-center justify-center"
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={`${size} w-full max-h-[90vh] overflow-hidden bg-white rounded-2xl shadow-xl transform transition-all`}
            >
              {title && (
                <Dialog.Title className={`text-lg font-medium leading-6 p-2 pl-4 ${titleColor} text-white w-full`}>
                  <div className="flex justify-between">
                    {title}
                    {displayCloseIcon && (
                      <div
                        className="flex items-center justify-center w-7 h-7 bg-white rounded-full cursor-pointer"
                        onClick={() => onClose()}
                      >
                        <FontAwesomeIcon icon={faTimes} color="#565656" />
                      </div>
                    )}
                  </div>
                </Dialog.Title>
              )}
              <div className="p-6 overflow-y-auto max-h-[70vh]">
                {children}
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
}

export default DialogWrapper;
