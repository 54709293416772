import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const DownloadIconWithLabel = ({ tooltipText, viewDown = false }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef(null);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };
    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <div
            className="relative ml-2"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={tooltipRef}
        >
            <FontAwesomeIcon
                icon={faDownload}
                className="text-[#1E596D] text-xl cursor-pointer"

            //   onMouseEnter={() => setIsHovered(true)}
            //   onMouseLeave={() => setIsHovered(false)}
            //   onClick={() => {
            //     // Add your download logic here
            //   }}
            />
            {showTooltip && (
                <div className={`absolute left-full mt-[-10px] z-50  transform  ${viewDown ? "ml-[-90px]" : "ml-1"} ${viewDown ? "mt-[2px]" : "mt-2"}  w-36 p-2 bg-gray-50 text-black text-sm rounded shadow-lg fill-zinc-100`}>
                    <span dangerouslySetInnerHTML={{ __html: tooltipText }} />
                </div>
            )}
        </div>
    );
};

export default DownloadIconWithLabel;
