/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import SettingsPassword from "./SettingsPassword";
import useModal from "../../../hooks/useModal";
import AddressForm from "../../address/AddressForm";
import SettingsMFA from "../../auth/multi-factor/SettingsMFA";
import Avatar from "./Avatar";
import toast from "react-hot-toast";
import ToastAlert from "../../custom-ui/toast-alert/ToastAlert";
import Tooltip from "../../custom-ui/tooltip/tooltip";
import PhoneNumber from "../../custom-ui/input-fields/phoneNumber";

import { useAuth } from "../../../providers/auth";
import { updateUser } from "../../../data/user";
import { useRole } from "../../../hooks/useRole";
import { Type } from "../../../data/dictionary/type";

import AutoSaveText from "../../custom-ui/input-fields/auto-save-field";
import AutoSaveSelectByLookup from "../../custom-ui/input-fields/auto-save-select-by-lookup";
import { useUser } from "../../../providers/database";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";

function SettingsGeneral(props) {
  const memberDateOptions = { year: "numeric", month: "short" };
  const timeOptions = { hour: "2-digit", minute: "2-digit" };
  const auth = useAuth();
  const { data: userData } = useUser(auth.user.uid);
  const isInternalUser = auth?.user?.claims?.type === "A";
  const [avatar, setAvatar] = useState(auth?.user?.auth?.currentUser?.photoURL);
  // const [role, setRole] = useState("");
  const [type, setType] = useState("");
  const [mapSource, setMapSource] = useState(
    "https://maps.google.com/maps?q=35.856737, 10.606619&z=15&output=embed",
  );

  const [metadata, setMetadata] = useState({
    creationTime: "",
    lastSignInTime: "",
  });
  const { isShowing: showAvatarEditModal, toggle: toggleAvatarEditModal } =
    useModal();

  const today = new Date().toLocaleDateString();
  const lastTimeSignedInDate = new Date(
    auth.user.metadata.lastSignInTime,
  ).toLocaleDateString();

  const linkedInRegx = new RegExp(
    /(https?:\/\/(www.)|(www.))?linkedin.com\/(mwlite\/|m\/)?in\/[a-zA-Z0-9_.-]+\/?/gm,
  );
  const getRole = useRole();
  const role = getRole(auth.user.claims.role, true);

  // updates image as soon as it's available
  useEffect(() => {
    if (avatar === undefined || avatar == null) {
      setAvatar("/images/default_user.png");
    }
    setAvatar(userData?.photoURL);
  }, [userData]);

  useEffect(() => {
    setType(Type[auth.user.claims.type]);
    if (auth.user?.address) {
      setMapSource(
        "https://maps.google.com/maps?q=" +
          auth.user.address.latitude +
          ", " +
          auth.user.address.longitude +
          "&z=15&output=embed",
      );
    }
    if (today === lastTimeSignedInDate) {
      setMetadata({
        creationTime: new Date(
          auth.user.metadata.creationTime,
        ).toLocaleDateString(undefined, memberDateOptions),
        lastSignInTime: new Date(
          auth.user.metadata.lastSignInTime,
        ).toLocaleTimeString(undefined, timeOptions),
      });
    } else {
      setMetadata({
        creationTime: new Date(
          auth.user.metadata.creationTime,
        ).toLocaleDateString(undefined, memberDateOptions),
        lastSignInTime: new Date(
          auth.user.metadata.lastSignInTime,
        ).toLocaleDateString(),
      });
    }
  }, [auth]);

  const inputFieldClasses = `
    py-1 mt-0.5
    w-full leading-8 
    bg-white
    outline-none 
    border border-gray-300 
  `;

  return (
    <div>
      <div className="flex justify-evenly space-x-2">
        {/* 1st Column */}
        <div className="mt-5">
          <img
            alt="avatar"
            type="button"
            src={avatar ?? "/images/default_user.png"}
            className="w-48  max-h-56 object-cover rounded-xl   cursor-pointer mb-6"
            onClick={() => toggleAvatarEditModal()}
          />
          <div className="flex flex-col items-center uppercase text-sm text-instant-teams-blue-Main">
            <div className="font-semibold text-lg justify-self-end">
              Member since {metadata.creationTime}
            </div>
            <div className="grid grid-cols-2">
              <div className="font-semibold mr-3 justify-self-end">
                Last login
              </div>
              <div className="justify-self-start">
                {" "}
                {metadata.lastSignInTime}
              </div>
              <div className="font-semibold mr-3 justify-self-end">
                User Type
              </div>
              <div> {type}</div>
              <div className="font-semibold mr-3 justify-self-end">
                User Role
              </div>
              <div> {role}</div>
              {auth?.user?.claims?.milspouse ? (
                <Tooltip text="Member of 12 Million Plus">
                  <img
                    src="/images/12-mil-plus/12-mil-logo.png"
                    alt="milspouse logo"
                    className="w-12 h-12 mt-1 justify-self-end"
                  />
                </Tooltip>
              ) : (
                // empty div to keep the icons in place
                <div></div>
              )}
              {auth?.user?.claims?.milVerified ? (
                <Tooltip text="military association verified">
                  <img
                    src="/images/mil-verified/military-logo.png"
                    alt="mil verified logo"
                    className="w-12 h-12 mt-1 justify-self-start"
                  />
                </Tooltip>
              ) : (
                // empty div to keep the icons in place
                <div></div>
              )}
            </div>
          </div>
        </div>

        {showAvatarEditModal ? (
          <Avatar toggleModal={toggleAvatarEditModal} />
        ) : null}
        <div className="w-96">
          {/* 2nd Column */}
          <GreenUnderlineHeading title="Basic Information" />
          <AutoSaveSelectByLookup
            value={auth.user.pronouns}
            group="pronoun"
            collection="Users"
            recId={auth.user.id}
            required={false}
            name="pronouns"
            display="Pronouns"
            placeholder="Select Pronouns"
            directions=""
          />
          <div className="mt-3">
            <AutoSaveText
              value={auth.user.name}
              collection="Users"
              recId={auth.user.id}
              required={true}
              name="name"
              display="Full Legal Name"
              placeholder="John Doe"
              directions="Name used on Driver's License or ID"
              readOnly={false}
            />
          </div>

          <AutoSaveText
            value={auth.user.preferredName}
            collection="Users"
            recId={auth.user.id}
            required={false}
            name="preferredName"
            display="Preferred Name"
            placeholder="Johnny Doe"
            directions="This will be your displayed name"
            readOnly={false}
          />

          <AutoSaveText
            value={auth.user.title}
            collection="Users"
            recId={auth.user.id}
            required={false}
            name="title"
            display="Title"
            placeholder=""
            directions=""
            readOnly={false}
          />
          <AutoSaveText
            value={auth.user.email}
            collection="Users"
            recId={auth.user.id}
            required={false}
            name="email"
            display="Email"
            placeholder=""
            directions=""
            readOnly={true}
          />
          <AutoSaveText
            value={auth.user.linkedin}
            collection="Users"
            recId={auth.user.id}
            required={false}
            name="linkedin"
            display="LinkedIn"
            placeholder="Full LinkedIn URL"
            directions="Full URL"
            readOnly={false}
          />
          {/* Currently only internal users can use Calendly links to schedule meetings. Marketplace can
          only track meeting updates for those users that are members of the instant teams organization  */}
          {isInternalUser && (
            <AutoSaveText
              value={auth?.user?.calendly}
              collection="Users"
              recId={auth.user.id}
              required={false}
              name="calendly"
              display="Calendly Link"
              placeholder="Enter your calendly link"
              directions="Full URL"
              readOnly={false}
              maxlength={150}
            />
          )}
        </div>

        {/* 3rd Column */}
        <div className="w-96">
          {/* Password Begin */}
          {auth?.user?.claims?.type !== "A" && (
            <SettingsPassword inputFieldClasses={inputFieldClasses} />
          )}
          <GreenUnderlineHeading title="Two Factor Authentication(2fa)" />

          <SettingsMFA onStatus={props.onStatus} />

          <GreenUnderlineHeading title="Mailing Address" />
          {auth?.user?.address && (
            <div className="mx-auto center-items mb-4">
              <iframe
                src={mapSource}
                width="360"
                height="270"
                frameBorder="0"
                style={{ border: 0 }}
              ></iframe>
            </div>
          )}
          <AddressForm
            id={auth.user.id}
            address={auth.user.address}
            addressLabel="primary"
            onSubmit={updateUser}
            onDone={() => toast.success("Your address has been updated!")}
          />
        </div>
      </div>
      <ToastAlert />
    </div>
  );
}

export default SettingsGeneral;
