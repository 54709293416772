import React from "react";
import { subCollectionNames } from "../../../data/dictionary/collectionNames";
import { useAllSharedNotesByCollectionAndId } from "../../../data/notes";
import NoteDisplay from "../../notes/note-display";
import GeneralNote from "../../custom-ui/input-fields/modals/GeneralNote";
import useModal from "../../../hooks/useModal";
import { collectionNames } from "../../../data/dictionary/collectionNames";
import Tooltip from "../../custom-ui/tooltip/tooltip";
import { useLocation } from "react-router-dom";
function CompanyNotesListing({
  collection,
  paramId,
}: {
  collection: string;
  paramId: string;
}) {
  const location = useLocation();
  const { notes } = subCollectionNames;

  const { data: fetchedNotes } = useAllSharedNotesByCollectionAndId(
    paramId,
    collection,
    notes,
  );

  const { isShowing: isShowingNote, toggle: toggleNote } = useModal();

  const handleDone = () => {
    toggleNote();
  };

  return (
    <>
      <div className="mt-2 flex justify-between w-10/12">
        <h3 className="pl-1 text-xl text-instant-teams-blue-Main font-semibold uppercase pb-6">
          Notes
        </h3>
        {location?.pathname?.includes("jobs") ? (
          <Tooltip text="Add note">
            <i
              onClick={toggleNote}
              className="fak fa-notes-add-white text-instant-teams-teal-Main pr-1 text-xl "
            ></i>
          </Tooltip>
        ) : null}
      </div>

      <table className="w-10/12">
        {fetchedNotes?.length !== 0 ? (
          fetchedNotes?.map((row: any, index: string) => {
            return <NoteDisplay entry={row} key={index} />;
          })
        ) : (
          <div>Nothing to display.</div>
        )}
      </table>
      {isShowingNote && (
        <GeneralNote
          onDone={handleDone}
          collection={collectionNames.jobDescriptions}
          id={paramId}
        />
      )}
    </>
  );
}

export default CompanyNotesListing;
