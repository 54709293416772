import { useQuery } from "react-query";
import { doc, collection, query, orderBy } from "firebase/firestore";
import { db, createQuery } from "../providers/database";

import { collectionNames } from "./dictionary/collectionNames";
import { InterviewQuestion } from "../dataTypes/InterviewQuestion";

const getInterviewQuestionsDocRef = (questionId: string) =>
  doc(db, collectionNames.interviewQuestions, questionId);

export function useInterviewQuestion(questionId: string) {
  return useQuery(
    ["interviewQuestion", { questionId }],
    createQuery(() => getInterviewQuestionsDocRef(questionId)),
    { enabled: !!questionId },
  );
}

export function useAllEInterviews(trigger: object) {
  return useQuery(
    ["templates"],
    createQuery(() =>
      query(
        collection(db, collectionNames.interviewQuestions),
        orderBy("displayName"),
      ),
    ),
    { enabled: !!trigger },
  );
}
