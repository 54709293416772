import React, { createContext, useContext, useState } from "react";

const SkillContext = createContext({
    skillsToFilter: [],
    setSkillsToFilter: () => null,
});

export const useSkillState = () => useContext(SkillContext);

const GlobalStateProvider = ({ children }) => {
    const [skillsToFilter, setSkillsToFilter] = useState([]);

    return (
        <SkillContext.Provider value={{ skillsToFilter, setSkillsToFilter }}>
            {children}
        </SkillContext.Provider>
    );
};

export default GlobalStateProvider;
