import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({ file, label }: { file: string; label: string }) => {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);


  const onDocumentLoadSuccess = ({
    numPages,
    width,
  }: {
    numPages: number;
    width: number;
  }) => {
    setNumPages(numPages);

    // Calculate the scale to fit the PDF file in the available space
    const containerWidth =
      document.getElementById("pdf-container")?.clientWidth;
    if (containerWidth && width) {
      const newScale = containerWidth / width;
      setScale(newScale);
    }
  };

  const handlePreviousPage = () => {
    setPageNumber(Math.max(pageNumber - 1, 1));
  };

  const handleNextPage = () => {
    setPageNumber(Math.min(pageNumber + 1, numPages || 1));
  };

  const handleClick = () => {
    window.open(file, "_blank");
  };

  const handleResumeDownload = async() => {
    console.log("file====",file,label);
    const response = await fetch(file);
    const blob = await response.blob();
    const url = URL?.createObjectURL(blob);
    const anchor = document?.createElement('a');
    anchor.href = url;
    anchor.download = label;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const props = {
    file: file,
    key: file,
    onLoadSuccess: onDocumentLoadSuccess,
    error: "Failed to load PDF file",
    loading: "Loading PDF file...",
    noData: "No PDF file selected",
    renderMode: "canvas",
    options: {
      cMapUrl: "cmaps/",
      cMapPacked: true,
    },
    scale: scale,
  };

  return (
    <div id="pdf-container=" className="w-full h-screen" style={{zIndex:1000}}>
      <div className="mt-1 flex justify-between w-full mb-4">
        {numPages > 1 && (
          <div className="flex justify-between w-1/2 mr-4">
            <>
              <button
                className="rounded-lg bg-instant-teams-teal-Main text-white px-4 py-2"
                onClick={handlePreviousPage}
              >
                Previous
              </button>
              <span className="flex-1 text-center p-2">
                {pageNumber} of {numPages}
              </span>
              <button
                className="rounded-lg bg-instant-teams-teal-Main text-white px-4 py-2"
                onClick={handleNextPage}
              >
                Next
              </button>
            </>
          </div>
        )}
        <div className="flex justify-end w-1/2 ml-4">
          <button
            className="rounded-lg bg-instant-teams-teal-Main text-white px-4 py-2"
            onClick={handleClick}
          >
            View in New Tab
          </button>
        
        </div>
        <div className="flex justify-end w-1/2 ml-4">
        <button
            className="rounded-lg bg-instant-teams-teal-Main text-white px-4 py-2"
            onClick={handleResumeDownload}
          >
            Download Resume
          </button>
          </div>
      </div>
      <Document {...props}>
        <Page pageNumber={pageNumber} />
      </Document>
      <div className="flex justify-between w-full mt-4">
        <div className="flex justify-between w-3/4 mr-4">
          {numPages > 1 && (
            <>
              <button
                className="rounded-lg bg-instant-teams-teal-Main text-white px-4 py-2"
                onClick={handlePreviousPage}
              >
                Previous
              </button>
              <span className="flex-1 text-center p-2">
                {pageNumber} of {numPages}
              </span>
              <button
                className="rounded-lg bg-instant-teams-teal-Main text-white px-4 py-2"
                onClick={handleNextPage}
              >
                Next
              </button>
              <div className="flex justify-end w-1/4 ml-4">
                <button
                  className="rounded-lg bg-instant-teams-teal-Main text-white px-4 py-2"
                  onClick={handleClick}
                >
                  View in New Tab
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PDFViewer;
