import { useState } from "react";
import { useAuth } from "../../../providers/auth";
import { useForm } from "react-hook-form";
import { Employer } from "../../../dataTypes/Employer";
import { createJobDescription } from "../../../data/jobDescriptions";
import { useLookup } from "../../../data/lookup";
import { errorLogger } from "../../../providers/clientLogger";
import ForwardArrowButton from "../../custom-ui/button/ForwardArrowButton";

function CreateJobListingForm({
  employer,
  jobListing,
  setJobListingId,
  onDone,
}: {
  employer: Employer;
  jobListing?: any;
  setJobListingId: Function;
  onDone: Function;
}) {
  const { user } = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const {
    data: classification = [],
    status: classificationQueryStatus = "",
    error: classificationQueryError,
  } = useLookup("classification");

  const {
    data: model = [],
    status: modelQueryStatus = "",
    error: modelQueryError,
  } = useLookup("model");

  const {
    data: payType = [],
    status: payTypeStatus = "",
    error: payTypeQueryError,
  } = useLookup("payType");

  const {
    data: term = [],
    status: termQueryStatus = "",
    error: termQueryError,
  } = useLookup("term");

  const { handleSubmit, register, errors, getValues } = useForm({
    defaultValues: {
      title: jobListing?.title ?? "",
      employer: employer.id,
      createdBy: user.id,
      status: "tac_review",
      model: [""],
      term: [""],
      classification: [""],
      payType: [""],
    },
  });

  const onSubmit = (data: any) => {
    setPending(true);
    createJobDescription(data)
      .then((docRef) => {
        setJobListingId(docRef);
        onDone();
        setPending(false);
      })
      .catch((error) => {
        setFormAlert(`There was a problem creating ${data.title}`);
        errorLogger(error.message, {
          component: "Create Job Description createJobDescription()",
          flow: "Employer Onboarding",
          userId: user.id,
        });
        setPending(false);
      })
      .finally(() => setPending(false));
  };

  return (
    <>
      <h2 className="mb-4 uppercase font-semibold text-instant-teams-blue-Main">
        Job Listing
      </h2>
      {formAlert && (
        <div className="mb-4 text-red-600">{formAlert.message}</div>
      )}{" "}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <input
            className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            name="employer"
            type="hidden"
            ref={register({})}
          />
          <input
            className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            name="createdBy"
            type="hidden"
            ref={register({})}
          />
          <label
            className="py-1 px-3 mb-1 uppercase block text-left font-semibold text-instant-teams-blue-Main"
            htmlFor="title"
          >
            Job Title
          </label>

          <input
            className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            id="title"
            name="title"
            type="text"
            placeholder="Enter Job Title"
            ref={register({
              required: "Please enter a job title.",
            })}
          />
          {errors.title && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.title.message}
            </p>
          )}
        </div>
        <div className="mb-4">
          <h3 className="py-1 px-3 mb-1 uppercase block text-left font-semibold text-instant-teams-blue-Main">
            Model
          </h3>
          <div className="py-1 px-3 text-left text-xs text-instant-teams-blue-Main">
            * Select all that apply
          </div>
          {model.map((item: any) => (
            <div key={item.id} className="flex items-start">
              <>
                <input
                  className="leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                  id={item.id}
                  name="model"
                  type="checkbox"
                  value={item.code}
                  ref={register({
                    required: "please select a job model.",
                  })}
                />
                <label
                  className="mb-1 px-2 text-sm  font-semibold text-instant-teams-blue-Main"
                  htmlFor={item.id}
                >
                  {item.label}
                </label>
              </>
            </div>
          ))}
          {/* {errors.model && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.model.message}
            </p>
          )} */}
        </div>

        <div className="flex justify-between">
          <div className="mb-2">
            <h3 className="py-1 px-3 mb-1 uppercase block text-left font-semibold text-instant-teams-blue-Main">
              Term
            </h3>
            <div className="py-1 px-3 text-left text-xs text-instant-teams-blue-Main">
              * Select all that apply
            </div>
            {term.map((item: any) => (
              <div key={item.id} className="flex items-start">
                <input
                  className="leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                  id={item.id}
                  name="term"
                  type="checkbox"
                  value={item.code}
                  ref={register({})}
                />
                <label
                  className="mb-1 px-2 text-sm  font-semibold text-instant-teams-blue-Main"
                  htmlFor="permanent"
                >
                  {item.label}
                </label>
              </div>
            ))}
          </div>
          <div className="mb-2">
            <h3 className="py-1 px-3 mb-1 uppercase block text-left font-semibold text-instant-teams-blue-Main">
              Classification
            </h3>
            <div className="py-1 px-3 text-left text-xs text-instant-teams-blue-Main">
              * Select all that apply
            </div>

            {classification.map((item: any) => (
              <div key={item.id} className="flex items-start">
                <input
                  className="leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                  id={item.id}
                  name="classification"
                  value={item.code}
                  type="checkbox"
                  ref={register({})}
                />
                <label
                  className="mb-1 px-2 text-sm  font-semibold text-instant-teams-blue-Main"
                  htmlFor={item.id}
                >
                  {item.label}
                </label>
              </div>
            ))}

            {/* {errors.remote && (
              <p className="mt-1 text-sm text-left text-red-600">
                {errors.remote.message}
              </p>
            )} */}
          </div>
        </div>
        <div className="mb-4">
          <h3 className="py-1 px-3 mb-1 uppercase block text-left font-semibold text-instant-teams-blue-Main">
            Pay Type
          </h3>
          <div className="flex items-start">
            {payType.map((item: any) => (
              <div key={item.id}>
                <input
                  className="leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                  id={item.id}
                  name="payType"
                  type="checkbox"
                  value={item.code}
                  ref={register({})}
                />
                <label
                  className="mb-1 px-2 text-sm  font-semibold text-instant-teams-blue-Main"
                  htmlFor={item.id}
                >
                  {item.label}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-4">
          <label
            className="py-1 px-3 mb-1 uppercase block text-left font-semibold text-instant-teams-blue-Main"
            htmlFor="seats"
          >
            How many positions do you want to hire?
          </label>

          <input
            className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            id="seats"
            name="seats"
            type="text"
            placeholder="Number of positions"
            ref={register({
              required: "Please enter a job title.",
            })}
          />
          {errors.title && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.title.message}
            </p>
          )}
        </div>
        <ForwardArrowButton pending={pending} type="submit" />
      </form>
    </>
  );
}

export default CreateJobListingForm;
