import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../../../../../providers/auth';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useGlobalState } from '../../../../../providers/globalState/GlobalStateProvider';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import NotificationList_NewUI from '../../../../notifications/NotificationList_NewUI';
import ProfilePopup from './Profile';


const Header = () => {
   
    const { comapanyId = "" } = useParams();
    const location = useLocation();
    const currentPath = location.pathname;
    const navigate = useNavigate();
    const auth = useAuth();
    const { globalState, setGlobalState } = useGlobalState();
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearching, setIsSearching] = useState(false);   
    useEffect(() => {
        setSearchTerm("");
        setIsSearching(false);
        setGlobalState({ ...globalState, searchKeyWords: '' });
    }, [currentPath]);

    useEffect(() => {
        setSearchTerm(globalState.searchKeyWords);
    },[globalState.searchKeyWords])

    const handleSearch = () => {
        if (isSearching) {
            setSearchTerm("");
            setIsSearching(false);
            setGlobalState({ ...globalState, searchKeyWords: '' });
        } else if (searchTerm.trim()) {
            setIsSearching(true);
            setGlobalState({ ...globalState, searchKeyWords: searchTerm.trim() });
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && searchTerm.trim()) {
            setIsSearching(true);
            setGlobalState({ ...globalState, searchKeyWords: searchTerm.trim() });
        }
    };

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value.trim() === '') {
            setIsSearching(false);
            setGlobalState({ ...globalState, searchKeyWords: '' });
        }
    };

    return (
        <div style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + '/images/banner_bg-1.png'})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}
            className={`h-40 fixed w-full z-50 p-1 bg-cover`}>
        
            {!currentPath.startsWith("/social") && (<><div className='w-full h-24 flex justify-between items-center'>
                <div onClick={() => { navigate("/jobs") }} className='ml-8 cursor-pointer w-48'>
                    <img src="/images/InstantTeamsLogo_Main_1.png" alt="Instant Teams Logo" />
                </div>
                <div className='flex text-instant-teams-teal-D1 space-x-4  ml-20  items-center'>
                    <div onClick={() => { navigate("/jobs") }} className={`px-4 py-2 cursor-pointer ${currentPath.startsWith("/jobs") ? "border-b-2 border-[#4357a7]" : ""}`}>
                        <h1 className='border-b-[#4357a7] '>Jobs</h1>
                    </div>
                    <div onClick={() => { navigate("/companies"); setGlobalState({...globalState, searchKeyWords: '' }) }} className={`px-2 cursor-pointer py-2 ${currentPath.startsWith("/companies") ? "border-b-2 border-[#4357a7]" : ""}`}>
                        <h1 className='border-b-[#4357a7] '>Companies</h1>
                    </div>
                </div>
                <div className='flex items-center  mr-8'>
                    <div onClick={() =>  navigate("/faq")} className='px-4'>
                        <span className="text-lg font-bold cursor-pointer text-[#1E596D] border-[#1E596D] flex h-6 w-6 items-center justify-center rounded-full border-2 bg-white shadow-lg">
                            i
                        </span>
                    </div>
                    <div className='px-3'>
                    
                        <NotificationList_NewUI userId={auth?.user?.id} />
                    </div>
                    <div onClick={() =>  navigate(`/messages/${auth.user.id}`)} className='px-3'>
                        <i className='far fa-comment-alt text-[22px] cursor-pointer text-[#1E596D]'></i>
                    </div>
                    <div className='px-3 cursor-pointer'>
                       <ProfilePopup/>
                    </div>
                    {/* () => navigate("/settings/newprofile" */}
                </div>
            </div>

                {/* Search Bar */}
                <div className='w-full pb-3 flex justify-center'>
                    <div className='relative w-[40%] md:w-1/2'>
                        <input
                            type='text'
                            value={searchTerm}
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}
                            placeholder={currentPath.startsWith("/companies") ? "Search by Company Name" : 'Search by Job Title or Company Name'}
                            className='w-full pl-4 pr-10 py-3 text-gray-600 bg-white rounded-full text-sm shadow-md focus:outline-none focus:ring-2 focus:ring-blue-300'
                        />
                        <div onClick={handleSearch} className='absolute inset-y-0 right-4 flex items-center cursor-pointer'>
                            <FontAwesomeIcon
                                icon={(isSearching&&searchTerm||((searchTerm)&&comapanyId) )? faTimes : faSearch}
                                className="text-gray-400 w-4 h-4"
                            />
                        </div>
                    </div>
                </div></>)}
        </div>
    );
};

export default Header;
