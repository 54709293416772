import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useGlobalState } from "../../../providers/globalState/GlobalStateProvider";

function PrependMeta({
  name = null,
  content = null,
  media = null,
  title = null,
  property = null,
  keyValue = null,
} = {}) {  
  useEffect(() => {
    const metaLine = document.createElement('meta');
    if(name !== null){
      metaLine.setAttribute('name', `${name}`);
    }
    if(content !== null){
      metaLine.setAttribute('content', `${content}`);
    }
    if(media !== null){
      metaLine.setAttribute('media', `${media}`);
    }
    if(title !== null){
      metaLine.setAttribute('title', `${title}`);
    }
    if(property !== null){
      metaLine.setAttribute('property', `${property}`);
    }
    if(keyValue !== null){
      metaLine.setAttribute('key', `${keyValue}`);
    }
    const head = document.querySelector('head');
    head.insertBefore(metaLine, head.firstChild);
  }, []);

  return (
    <>
    </>
  );
}

export default PrependMeta;
