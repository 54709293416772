import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ToastAlert from "../../../custom-ui/toast-alert/ToastAlert";

import Meta from "../../../custom-ui/meta/Meta";

import GreenUnderlineHeading from "../../../custom-ui/headings/heading";
import { useMeetingsByUserId } from "../../../../data/meetings";
import MeetingList from "../../../live/MeetingList";
import SeekerStandingList from "../parts/seekerStandingList";
import MessagingCenterLite from "../../../messages/MessagingCenterLite";

const SeekerOverview = () => {
  const params = useParams();
  const [id, setId] = useState(params?.seekerId || "");

  const { data: meetings } = useMeetingsByUserId(id);
  useEffect(() => {console.log("MEET: ",meetings)},[meetings]);
  // Filter meeting list
  const scheduledMeetings = meetings?.filter(
    (meeting: any) => meeting?.status === "scheduled",
  );

  const rescheduleRequest = meetings?.filter(
    (meeting: any) => meeting?.status === "reschedule",
  );

  const awaitingResponse = meetings?.filter(
    (meeting: any) => meeting?.status === "sent" || meeting.status === "pending",
  );

  const complete = meetings?.filter(
    (meeting: any) => meeting.status === "complete",
  );

  const cancelledAndExpired = meetings?.filter(
    (meeting: any) =>
      meeting.status === "cancelled" || meeting.status === "expired",
  );

  const sortedMeetings = [
    ...rescheduleRequest || [],
    ...scheduledMeetings || [],
    ...awaitingResponse || [],
    ...complete || [],
  ];
  useEffect(() => {
    setId(params?.seekerId);
    return () => setId("");
  }, [params.seekerId]);
  return (
    <div className="w-full">
      <Meta title="Seeker Overview" />
      <div className="w-full mt-6 flex">
        <div className="w-full grid grid-cols-2 gap-10 mb-4">
          <div>
            <GreenUnderlineHeading title="Current Standing" />
            <SeekerStandingList userId={id} />
            <GreenUnderlineHeading title="Meetings" />
            <MeetingList meetings={sortedMeetings} />
          </div>
          <div>
            <MessagingCenterLite recipientId={id} />
          </div>
        </div>
      </div>

      <ToastAlert />
    </div>
  );
};

export default SeekerOverview;
