import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  runTransaction,
  onSnapshot,
  doc,
  collection,
  collectionGroup,
  getCountFromServer,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  limit,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { db, client, createQuery } from "../providers/database";
import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";

import { Resume } from "../dataTypes/Resume";

type ResumeFile = {
  label: string;
  type: string;
  link: string;
};

export const getResumeDocRef = (id: string) =>
  doc(db, collectionNames.resume, id);

export function useResume(uid: string) {
  return useQuery(
    ["resume", { uid }],
    createQuery(() => doc(db, collectionNames.resume, uid)),
    { enabled: !!uid },
  );
}

export function createResume(data: Resume) {
  const { id, ...resume } = data;
  return setDoc(doc(db, collectionNames.resume, id), resume);
}

export function useFiles(id: string) {
  return useQuery(
    ["allFiles", { id }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.resume}/${id}/${subCollectionNames.files}`,
        ),
      );
    }),
    { enabled: !!id },
  );
}

export function useOffersByEmployee(userId: any, employerId: any) {
  return useQuery(
    ["offersByEmployee", { userId, employerId }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.offers),
        // @ts-ignore
        where("resumeId", "==", userId),
        // @ts-ignore
        where("employerId", "==", employerId),
        orderBy("createdAt", "desc"),
      );
    }),
    { enabled: !!userId && !!employerId },
  );
}

export function deleteFile(resumeId: string, fileId: string) {
  return deleteDoc(
    doc(
      db,
      `${collectionNames.resume}/${resumeId}/${subCollectionNames.files}`,
      fileId,
    ),
  );
}

export function writeResume(id: string, file: ResumeFile) {
  const resumeDocRef = getResumeDocRef(id);
  const resumeFileSubCollectionRef = collection(
    resumeDocRef,
    subCollectionNames.files,
  );
  return addDoc(resumeFileSubCollectionRef, { file: file });
}

export function useAllActiveApplications(id: string) {
  return useQuery(
    ["seekerApplication", { id }],
    createQuery(() =>
      query(
        collection(db, collectionNames.applications),
        where("resumeId", "==", id),
        orderBy("timestamp", "desc"),
      ),
    ),
    { enabled: !!id },
  );
}

export function useAllActiveOffers(id: string) {
  return useQuery(
    ["seekerOffers", { id }],
    createQuery(() =>
      query(
        collection(db, collectionNames.offers),
        where("resumeId", "==", id),
        orderBy("createdAt", "desc"),
      ),
    ),
    { enabled: !!id },
  );
}
export function useOffersByStatus(id: string, status: string) {
  return useQuery(
    ["seekerOffersByStatus", { id, status }],
    createQuery(() =>
      query(
        collection(db, collectionNames.offers),
        where("resumeId", "==", id),
        where("status", "==", status),
        orderBy("createdAt", "desc"),
      ),
    ),
    { enabled: !!id },
  );
}
// pass multiple statuses in an array eg. ['hired', 'cancelled', 'rejected'];
export function useOffersByStatuses(id: string, statuses: string[]) {
  return useQuery(
    ["seekerOffersByStatuses", { id, statuses }],
    createQuery(() =>
      query(
        collection(db, collectionNames.offers),
        where("resumeId", "==", id),
        where("status", "in", statuses),
        orderBy("createdAt", "desc"),
      ),
    ),
    { enabled: !!id },
  );
}

export function useAllActiveSkills(email: string) {
  return useQuery(
    ["seekerSkills", { email }],
    createQuery(() =>
      query(
        collection(db, collectionNames.enrollments),
        where("email", "==", email),
        where("status", "==", "completed"),
      ),
    ),
    { enabled: !!email },
  );
}
