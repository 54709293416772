const InstantTeamsSpinner = ({height, width }: {height?:string, width?: string}) => {
  return (
    <>
      <svg
        className="spinner__working"
        width={width || "20rem"}
        height={height || "8rem"}
        viewBox="0 0 100 125"
      >
        <circle
          fill="none"
          stroke="#36bcee"
          strokeMiterlimit={10}
          strokeWidth={3}
          cx={"50%"}
          cy={"50%"}
          r={15}
        >
          <animateTransform
            attributeName="transform"
            begin="0s"
            dur="5s"
            type="rotate"
            from="360 75 75"
            to="0 75 75"
            repeatCount="indefinite"
          />
        </circle>

        <circle
          fill="none"
          stroke="#203e5c"
          strokeMiterlimit={10}
          strokeWidth={2.5}
          cx={"50%"}
          cy={"50%"}
          r={10}
        >
          <animateTransform
            attributeName="transform"
            begin="0s"
            dur="5s"
            type="rotate"
            from="0 70 70"
            to="360 70 70"
            repeatCount="indefinite"
          />
        </circle>

        <circle
          fill="none"
          stroke="#6bb755"
          strokeMiterlimit={10}
          strokeWidth={3.5}
          r={20}
        >
          <animateMotion
            attributeName="transform"
            begin="0s"
            dur="5s"
            path="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </>
  );
};

export default InstantTeamsSpinner;
