export type Offer = {
  offerStatusHistory?: OfferStatusHistory[];
  applicationId: string;
  commissionDetails: string;
  createdAt: string;
  createdBy: string;
  embeddedSigningUrl: string;
  embeddedPreviewUrl: string;
  employerId: string;
  isCommission: boolean;
  jobId: string;
  offerLetterFileName: string;
  offerLetterUrl: string;
  payRate: string;
  payType: string;
  positionId: string;
  recipientEmail: string;
  resumeId: string;
  singwellDocId: string;
  startDate: string;
  status: string;
};

export type OfferStatusHistory = {
  effectiveDate: string;
  alteredBy: string;
  active: boolean;
  offerStatus: OfferStatus;
};

export type OfferStatus = {
  label: string;
  sortOrder: number;
  icon: string | null;
  description: string;
};

export const openStatuses = ["pending", "preparation"];
export const historyStatuses = ["hired", "cancelled", "rejected"];
