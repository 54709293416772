function OveralProgress({ application }: { application: any }) {
  return (
    <>
      <div className="w-full  bg-instant-teams-teal-L5 rounded-full text-center border-1 border-white text-white">
        <div
          className={
            application?.overallProgress
              ? `bg-instant-teams-green-L2 text-xs font-medium text-instant-teams-blue-Main text-center leading-none rounded-full w-full p-2`
              : `text-xs font-medium text-instant-teams-blue-Main text-center leading-none rounded-full w-full p-2`
          }
          style={{
            width: `${application?.overallProgress ?? 100}%`,
          }}
        >
          {application?.overallProgress ?? 0}%
        </div>
      </div>
    </>
  );
}

export default OveralProgress;
