import { useState } from "react";
import { upload } from "../../../../storage/storage";
import folderNames from "../../../../storage/dictionary/folderNames";
import { friendlyFirebaseError } from "../../../../providers/firebase-errors/friendlyFirebaseError";
import { writeResume } from "../../../../data/seeker";
import { useResume } from "../../../../data/seeker";
import AutoSaveWysiwyg from "../../../custom-ui/input-fields/auto-save-wysiwyg";
import ForwardArrowButton from "../../../custom-ui/button/ForwardArrowButton";
import DragAndDropVariantA from "../../../custom-ui/drag-and-drop/DragAndDropFile";
import DragAndDropVariantB from "../../../custom-ui/drag-and-drop/DragAndDropFileSmall";
import SeekerFiles from "../parts/SeekerFiles";
function Resume({
  userId,
  handleDone,
  children,
}: {
  userId: string;
  handleDone: Function;
  children?: React.ReactNode;
}) {
  const [file, setFile] = useState();
  const [formAlert, setFormAlert] = useState(null);

  const { data: seekerData, error: seekerError } = useResume(userId);

  const confirmUpload = () => {
    upload(file, folderNames.resume, `${userId}_${file?.name}`).then(
      (fileUrl) => {
        writeResume(userId, {
          label: file?.name,
          type: file?.type,
          link: fileUrl,
        })
          .then((docRef) => {
            setFile();
          })
          .catch((error: Error) => {
            setFormAlert({
              type: "error",
              message: friendlyFirebaseError(error),
            });
          })
          .catch((error: Error) => {});
      },
    );
  };
  return (
    <>
      {formAlert && (
        <span className="mb-4 text-red-600">{formAlert.message}</span>
      )}{" "}
      <div className="w-full text-center mb-2">
        <DragAndDropVariantB setFile={setFile} />
        <div className="relative flex justify-center">
          {file && (
            <button
              className="absolute bottom-3 mt-4 py-2 px-4 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
              onClick={confirmUpload}
            >
              Upload File
            </button>
          )}
        </div>
        <SeekerFiles id={userId} />
      </div>
      <div className="w-full text-base mt-4">
        <AutoSaveWysiwyg
          value={seekerData?.resume}
          rows={5}
          collection="Resumes"
          recId={userId}
          required={false}
          name="resume"
          display="Enter Resume"
          placeholder={"Tell us about yourself..."}
          directions=""
          readOnly={false}
          editorStyle={{ height: "240px" }}
        />
      </div>
      <div className="flex flex-col justify-center items-center">
        <button
          className="cursor-pointer text-instant-teams-gray-D1 background-transparent font-bold uppercase underline px-3 py-1 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => {
            handleDone();
          }}
        >
          Skip
        </button>
        {children}
        <ForwardArrowButton
          pending={false}
          onClick={() => handleDone()}
          type="button"
        />
      </div>
    </>
  );
}

export default Resume;
