/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useQuery,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  orderBy,
  addDoc,
  limit,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { db, createQuery } from "../providers/database";
import { SyncRequest } from "../dataTypes/academy";
import { collectionNames } from "./dictionary/collectionNames";
import { RequiredSkill } from "../dataTypes/Assessment";
import { getFirstDocData } from "../providers/database";
import { Enrollment } from "../dataTypes/Enrollment";
import { getDocData, getDocRef } from "../providers/database";

export function useEnrollments(assessmentId: string) {
  return useQuery(
    ["enrollments", assessmentId],
    createQuery(() =>
      query(
        collection(db, "Enrollments"),
        where("assessmentId", "==", assessmentId),
        orderBy("status"),
        orderBy("email"),
      ),
    ),
    { enabled: !!assessmentId },
  );
}

export function useEnrollmentsByEmail(email: string) {
  return useQuery(
    ["enrollments", email],
    createQuery(() =>
      query(collection(db, "Enrollments"), where("email", "==", email)),
    ),
    { enabled: !!email },
  );
}

export function useEnrollmentsByEmailAndAssessment(
  email: string,
  assessment: number,
) {
  return useQuery(
    ["enrollments", email, assessment],
    createQuery(() =>
      query(
        collection(db, "Enrollments"),
        where("email", "==", email),
        where("assessmentId", "==", assessment),
      ),
    ),
    { enabled: !!email && !!assessment },
  );
}

const getDocConverter = <T>() => ({
  toFirestore: (data: any) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) => {
    return { ...snap.data(), id: snap.id } as T;
  },
});

export const getCollectionRef = <T>(collectionPath: string) =>
  collection(db, collectionPath).withConverter(getDocConverter<T>());
const enrollmentsRef = getCollectionRef<Enrollment>(
  collectionNames.enrollments,
);
export const getEnrollmentQuery = (
  assessementId?: string,
  email?: string | null,
) =>
  assessementId && email
    ? query(
        enrollmentsRef,
        where("email", "==", email),
        where("assessmentId", "==", assessementId),
        orderBy("average", "desc"),
        limit(1),
      )
    : undefined;

const assessmentRef = (id: string) =>
  getDocRef(collectionNames.academyComponents, id);

export const getBadge = async (
  { assessmentId, tier }: RequiredSkill,
  email?: string | null,
) => {
  const enrollment = await getFirstDocData(
    getEnrollmentQuery(assessmentId, email),
  );
  const skill = await getDocData(assessmentRef(assessmentId));
  const min = skill?.tier && tier ? parseInt(skill?.tier[tier], 10) : 80;
  let result = "";
  if (!enrollment?.average && !enrollment?.personality) {
    result = "pending";
  } else if (
    enrollment?.status === "completed" &&
    ((enrollment?.average ?? 0) >= min || !!enrollment?.personality)
  ) {
    result = "success";
  } else {
    result = "failed";
  }
  return {
    skill,
    result,
  };
};

export const createAssessmentEmail = (
  assessment_instructions: string,
  linkToAssessment: string,
  recipientName: string,
  to: string,
) => {
  return addDoc(collection(db, collectionNames.emails), {
    template: {
      data: {
        assessment_instructions,
        linkToAssessment,
        recipientName,
      },
      name: "seekerLinkToAssessment",
    },
    to,
  });
};
