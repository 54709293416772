import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/pro-regular-svg-icons";
import { useApplicationInterviewQuestions } from "../../../data/applications";
import { Application } from "../../../dataTypes/Application";

function EInterviewListing({
  application,
  handleEInterview,
}: {
  application: Application;
  handleEInterview: Function;
}) {
  const {
    data: interviewQuestionData = [],
    isLoading: isQuestionDataLoading,
    isError: isQuestionDataError,
  } = useApplicationInterviewQuestions(
    application?.objectID || application?.id,
  );
  const isQuestionDataAvailable =
    !isQuestionDataLoading && !isQuestionDataError;

  let appScore = 0;
  appScore += application?.appearance || 0;
  appScore += application?.conduct || 0;
  appScore += application?.tone || 0;
  appScore += application?.webcam || 0;
  appScore += application?.workspace || 0;

  let questionSum = 0;
  interviewQuestionData?.map((question: any) => {
    questionSum += question?.grade || 0;
  });

  const maxScore = interviewQuestionData
    ? interviewQuestionData.length * 2 + 10
    : 10;

  return (
    <div
      className={`${isQuestionDataLoading ? "cursor-wait" : "cursor-pointer"} ${
        isQuestionDataError ? "cursor-not-allowed" : "cursor-pointer"
      }w-7/8 col-span-1 my-auto py-2 px-2 text-sm rounded-lg shadow-xl bg-instant-teams-teal-Main z-10 cursor-pointer mt-12`}
      onClick={
        isQuestionDataAvailable
          ? () => {
              handleEInterview();
            }
          : () => {
              //noop
              // if no data is available do nothing
            }
      }
    >
      <div className="uppercase font-semibold text-white">
        <span>e-interview</span>
        <span className="float-right text-right text-bold">
          {appScore + questionSum}/{maxScore}
        </span>
      </div>
      <FontAwesomeIcon
        className="mt-2 w-full fa-2x text-instant-teams-blue-Main"
        icon={faPlayCircle}
        inverse
      />
    </div>
  );
}

export default EInterviewListing;
