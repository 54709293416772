import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  updateEmployer,
  useEmployer,
  useEmployerPrimary,
  useEmployerBilling,
} from "../../../../data/employer";
import AddressForm from "../../../address/AddressForm";
import DisplayUser from "../../../custom-ui/input-fields/displayUser";
import AutoSaveText from "../../../custom-ui/input-fields/auto-save-field";
import AutoSaveSelectByRole from "../../../custom-ui/input-fields/auto-save-select-by-role";
import EmployerUserCardSmall from "../parts/employerUserCardSmall";
import toast from "react-hot-toast";
import ToastAlert from "../../../custom-ui/toast-alert/ToastAlert";
import GreenUnderlineHeading from "../../../custom-ui/headings/heading";

const EmployerDetails = () => {
  const params = useParams();

  const { data: employer } = useEmployer(params.employerId ?? "");

  const [locationParts, setLocationParts] = useState([]);

  const { data: primaryData = {} } = useEmployerPrimary(employer.id);
  const { data: billingData = {} } = useEmployerBilling(employer.id);

  const [mapSource, setMapSource] = useState(
    "https://maps.google.com/maps?q=35.856737, 10.606619&z=15&output=embed",
  );

  useEffect(() => {
    setLocationParts(employer.intel?.location.split(", "));
    if (employer?.address) {
      setMapSource(
        "https://maps.google.com/maps?q=" +
          employer.address.latitude +
          ", " +
          employer.address.longitude +
          "&z=15&output=embed",
      );
    }
  }, [employer]);

  return (
    <>
      <div className="flex-auto w-full pl-2 pt-1">
        <div className="flex space-x-10 w-full text-lg font-bold pr-5">
          <div className="flex-auto w-1/3">
            <div className="grid grid-rows-3 gap-2 w-full mb-4">
              <AutoSaveText
                value={employer.displayName}
                collection="Employer"
                recId={employer.id}
                required={true}
                name="displayName"
                display="DBA Name"
                placeholder={"DBA Name"}
                directions=""
                readOnly={false}
              />
              <AutoSaveText
                value={employer.legalName}
                collection="Employer"
                recId={employer.id}
                required={true}
                name="legalName"
                display="Legal Name"
                placeholder={"Legal Name"}
                directions=""
                readOnly={false}
              />
              <AutoSaveText
                value={employer.domain}
                collection="Employer"
                recId={employer.id}
                required={true}
                name="domain"
                display="Domain Name"
                placeholder={"Primary or Email Domain"}
                directions="Value after the @ in email"
                readOnly={false}
              />
            </div>

            <div className="flex-auto w-full mt-5">
              <GreenUnderlineHeading title="EIN/TIN Check" />
              <AutoSaveText
                value={employer.taxId}
                collection="Employer"
                recId={employer.id}
                required={false}
                name="taxId"
                display="Tax ID"
                placeholder={"EIN/TIN of the Employer"}
                directions=""
                readOnly={false}
              />
              {employer.EIN?.isValid ? (
                <div className="pr-2 pl-2">
                  <p className="text-xs">Formatted: {employer.EIN?.display}</p>
                  <p className="text-xs text-green-500">
                    Valid {employer.EIN?.format} from the{" "}
                    {employer.EIN?.country}
                  </p>
                </div>
              ) : (
                <p className="text-xs text-red-500">
                  {employer.EIN?.type
                    ? "Invalid because " +
                      employer.EIN?.type +
                      " & No Entity Found"
                    : "Missing Entry"}
                </p>
              )}
              {employer?.EIN?.lookup ? (
                <>
                  <div className="pr-2 pl-2">
                    <p className="text-xs">
                      Identifier: {employer?.EIN?.lookup?.name}
                    </p>
                    <p className="text-xs">
                      Location: {employer.EIN?.lookup?.address}
                    </p>
                  </div>
                </>
              ) : (
                <div className="pr-2 pl-2">
                  <p className="text-xs">Extended Data Unavailable</p>
                </div>
              )}
            </div>
          </div>
          <div className="flex-auto w-1/3">
            <form className="grid grid-rows-3 gap-2 w-full">
              <DisplayUser
                value={employer.createdBy}
                collection="Employer"
                recId={employer.id}
                required={false}
                name="createdBy"
                display="Creator"
                placeholder={"Employer Created By"}
                directions=""
                readOnly={true}
              />
              <AutoSaveSelectByRole
                value={employer.AM}
                role="sales"
                collection="Employer"
                recId={employer.id}
                required={false}
                name="AM"
                display="Account Manager"
                placeholder={"Account Manager"}
                directions=""
              />
              <AutoSaveSelectByRole
                value={employer.TAC}
                role="tac"
                collection="Employer"
                recId={employer.id}
                required={false}
                name="TAC"
                display="Talent Acquisition Coordinator"
                placeholder="Talent Acquisition Coordinator"
                directions=""
              />
            </form>
            <div className="flex-auto w-full py-2">
              <GreenUnderlineHeading title="Account Owners" />

              {primaryData &&
                Object.keys(primaryData).length > 0 &&
                Object.keys(primaryData).map((id) => (
                  <EmployerUserCardSmall id={primaryData[id].id} />
                ))}
            </div>
            <div className="flex-auto w-full py-2">
              <GreenUnderlineHeading title="Billing" />

              {billingData &&
                Object.keys(billingData).length > 0 &&
                Object.keys(billingData).map((id) => (
                  <EmployerUserCardSmall id={billingData[id].id} key={id} />
                ))}
            </div>
          </div>
          <div className="flex-auto w-1/3">
            {employer?.address?.latitude && (
              <div className="mx-auto center-items mb-4 w-full">
                <iframe
                  className="w-full"
                  src={mapSource}
                  width="360"
                  height="270"
                  frameBorder="0"
                  title={mapSource}
                  style={{ border: 0 }}
                ></iframe>
              </div>
            )}
            <AddressForm
              id={employer.id}
              address={employer?.address}
              addressLabel="primary"
              onSubmit={updateEmployer}
              onDone={() =>
                toast.success(
                  `${employer.intel?.name} address has been updated!`,
                )
              }
              employerField={true}
            />
          </div>
        </div>
        <div className="flex-auto w-full mt-5">
          <GreenUnderlineHeading title="Business Intelligence" />
        </div>
        {employer?.error || employer?.intel === null ? (
          <div className="flex-auto w-full text-instant-teams-orange-D2">
            <p className="text-center">
              There is no available data for this organization. This could mean
              that the company is too small to have the digital footprint needed
              to collect relevant information or that scanning of this companies
              information has been disabled. You will need to collect the
              relevant data from the company representative or other means.
            </p>
          </div>
        ) : (
          <div className="flex w-full">
            <div className="flex-auto ">
              <p className="flex w-full font-normal text-base">
                Legal Name: {employer.intel?.legalName}
              </p>
              <p className="flex w-full font-normal text-base">
                DBA Name: {employer.intel?.name}
              </p>
              <p className="w-full font-normal text-base">
                Company Type: {employer.intel?.type.toUpperCase()}
              </p>
              <p className="flex w-full font-normal text-base">
                Founded Year: {employer.intel?.foundedYear}
              </p>
              <p className="w-full font-normal text-base">
                Years in Business:{" "}
                {new Date().getFullYear() - employer.intel?.foundedYear}
              </p>
              <p className="w-full font-normal text-base">Full Address:</p>
              {locationParts && (
                <div className="">
                  <p className="w-full font-normal text-base">
                    {locationParts[0]}
                  </p>
                  <p className="w-full font-normal text-base">
                    {locationParts[1]}
                  </p>
                </div>
              )}
              <p className="w-full font-normal text-base">
                Phone: {employer.intel?.phone}
              </p>
            </div>
            <div className="flex-auto">
              <p className="w-full font-normal text-base">
                URL:{" "}
                <a href={employer.intel?.url} target="_blank" rel="noreferrer">
                  {employer.intel?.name}
                </a>
              </p>
              <p className="w-full font-normal text-base">Tags:</p>
              <div className="">
                {employer.intel?.tags &&
                  Object.keys(employer.intel?.tags).length > 0 &&
                  Object.keys(employer.intel?.tags).map((tag) => (
                    <p key={tag} className="w-full font-normal text-base">
                      {employer.intel?.tags[tag]}
                    </p>
                  ))}
              </div>
            </div>
            <div className="flex-auto">
              <p className="flex w-full font-normal text-base">
                Industry Group: {employer.intel?.category?.industryGroup}
              </p>
              <p className="flex w-full font-normal text-base">
                Industry: {employer.intel?.category?.industry}
              </p>
              <p className="w-full font-normal text-base">
                Sub-Industry: {employer.intel?.category?.subIndustry}
              </p>
              <p className="flex w-full font-normal text-base">
                Sector: {employer.intel?.category?.sector}
              </p>
              <p className="flex w-full font-normal text-base">
                NAICS: {employer.intel?.category?.naicsCode}
              </p>
              <p className="flex w-full font-normal text-base">
                Annual Revenue: {employer.intel?.metrics?.annualRevenue}
              </p>
              <p className="flex w-full font-normal text-base">
                Annual Revenue Est:{" "}
                {employer.intel?.metrics?.estimatedAnnualRevenue}
              </p>
              <p className="flex w-full font-normal text-base">
                Employee Est: {employer.intel?.metrics?.employees}
              </p>
              <p className="flex w-full font-normal text-base">
                Employee Range: {employer.intel?.metrics?.employeesRange}
              </p>
            </div>
            <div className="flex-auto">
              <p className="flex w-full font-normal text-base">
                Fiscal Year-End: {employer.intel?.metrics?.fiscalYearEnd}
              </p>
              <p className="flex w-full font-normal text-base">
                Market Cap: {employer.intel?.metrics?.marketCap}
              </p>
              <p className="flex w-full font-normal text-base">
                Funding Raised: {employer.intel?.metrics?.raised}
              </p>
              <p className="flex w-full font-normal text-base">
                TRANCO Rank: {employer.intel?.metrics?.trancoRank}
              </p>
              <p className="w-full font-normal text-base">Aliases:</p>
              <div className="">
                {employer.intel?.aliases &&
                  Object.keys(employer.intel?.aliases).length > 0 &&
                  Object.keys(employer.intel?.aliases).map((tag) => (
                    <p key={tag} className="w-full font-normal text-base">
                      {employer.intel?.aliases[tag]}
                    </p>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastAlert />
    </>
  );
};

export default EmployerDetails;
