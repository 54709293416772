import { Outlet } from "react-router-dom";
import Meta from "../../../custom-ui/meta/Meta";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AssignBadge from "./AssignAssessmentBadge";
import toast from "react-hot-toast";
import ToastAlert from "../../../custom-ui/toast-alert/ToastAlert";
import SeekerInfo from "../parts/seekerInfo";
import { useAuth } from "../../../../providers/auth";
import { useUser } from "../../../../providers/database";
function SeekerPageOutlet() {
  const auth = useAuth();
  const { userClaims } = useAuth();
  const role = userClaims?.claims?.role;
  const [name, setName] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  const [id, setId] = useState(params?.seekerId || "");
  const { data: userData, status: userStatus, error: userError } = useUser(id);

  useEffect(() => {
    setName(userData?.name || "");
  }, [userData]);

  const handleResetPassword = () => {
    auth.sendPasswordResetEmail(userData.email).then(() => {
      toast.success("Email sent");
    });
  };

  return (
    <div>
      <Meta title="Seekers" />
      <div className="md:container w-full">
        <div className="flex flex-row">
          <div className="w-3/4 mb-12">
            <SeekerInfo userId={id} />
          </div>

          {role === "sadmin" || role === "tac" || role === "pops" ? (
            <div className="mt-24">
              <button
                onClick={handleResetPassword}
                className="w-48 mb-5 min-h-12 h-12 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
              >
                Reset password
              </button>
              <AssignBadge seekerId={id} />
            </div>
          ) : null}
        </div>
        <div className="mt-10">
          <Outlet />
        </div>
      </div>
      <ToastAlert />
    </div>
  );
}

export default SeekerPageOutlet;
