import React from "react";
import { Link } from "react-router-dom";
import { sanitizeMarkup } from "../../../../util/sanitization/sanitize-html";
import CourseQuestions from "./courseQuestions";
import TagDisplay from "../../../custom-ui/utils/Tags/TAG1/TAG1";

import Popover from "../../../custom-ui/popover/Popover";
import { TailwindHoverEnlarge } from "../../../../util/tailwind/tailwind-styles";
import "../../../../styles/dangerouslySet.css";
import { getLevel } from '../../../../util/assessments/level';

const CourseCard = ({ hit }) => {
  let duration = hit?.duration;

  const PopoverCourseContent = (
    <div className=" ">
      <div className="w-full font-bold text-instant-teams-teal-Main ">
        {hit.name}
      </div>
      <div className="text-xs flex">
        <div className="mx-2 text-bold">{duration} minutes</div>
        {hit.skillFamily ? (
          <div className="mx-2 text-bold">{hit.skillFamily}</div>
        ) : null}
      </div>
      {hit?.shortDescription && (
        <div
          className="dangerously text-justify w-full break-normal px-2 text-lg mt-4 mb-4 break-words "
          dangerouslySetInnerHTML={sanitizeMarkup(hit?.shortDescription)}
        ></div>
      )}
      <div className="mx-2">
        <TagDisplay tags={hit?.tags} />
      </div>
      <div className="w-full mb-4">
        <p className="text-instant-teams-teal-Main font-bold w-full">
          Skills:{" "}
        </p>
        {hit &&
          hit?.tests_detail?.map((item, index) => {
            return (
              <div
                className={
                  index > 0 ? "w-full border-t border-black" : "w-full"
                }
                key={index}
              >
                <div className="w-full">
                  <CourseQuestions details={item?.test?.covered_skills} />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );

  return (
    <>
      <Link className={"w-full cursor-pointer"} to={"/academy/" + hit.objectID}>
        <Popover content={PopoverCourseContent} size="w-80 min-h-64">
          <div
            key={hit.objectID}
            className={
              TailwindHoverEnlarge(103) +
              "w-56 h-72 shadow-input-field rounded-lg bg-white"
            }
            id={hit.objectID}
          >
            <>
              <div className="w-full">
                <img
                  src={hit?.badge || "/images/badge.png"}
                  alt={hit.name}
                  className="mx-auto w-3/4 rounded-xl"
                />
              </div>
              <div className="w-full text-center">
                <div className="w-full font-bold text-instant-teams-teal-Main">
                  {hit.name}
                </div>
                {hit.skillFamily ? (
                  <div className="mx-2">{` · ${hit.skillFamily} · `}</div>
                ) : null}
              </div>
            </>
          </div>
        </Popover>
      </Link>
    </>
  );
};

export default CourseCard;
