import { Message } from "../../dataTypes/Messages";
import { User } from "../../dataTypes/User";
import { DisplayTime } from "../../util/timestamps/timestamp";

const MessageItem = ({
  isCurrentUser,
  userData,
  message,
  minimize,
}: {
  isCurrentUser: boolean;
  userData: User;
  message: Message;
  minimize: boolean;
}) => {
  const containerClassName =
    isCurrentUser && !minimize ? "justify-end" : "justify-start";
  const senderName = userData?.name;
  const { body, senderId, id: messageId } = message;

  return (
    <>
      {userData && (
        <div className={`flex w-full py-4 px-4 ${containerClassName}`}>
          {isCurrentUser && !minimize ? (
            <>
              <div className="flex items-center flex-row-reverse">
                <div className="flex-none flex flex-col items-center space-y-1 ml-4">
                  <img
                    className="rounded-full w-10 h-10"
                    src={userData?.photoURL || "/images/default_user.png"}
                  />
                  <a href="#" className="block text-xs hover:underline">
                    {userData?.name || userData?.preferredName}
                  </a>
                  <div className="flex-1 text-xxs">
                    {DisplayTime(message?.createdAt)}
                  </div>
                </div>
                <div className="flex-1 bg-indigo-100 text-gray-800 p-2 rounded-lg mb-2 relative">
                  {body && body !== "" && <div>{body}</div>}
                  <div className="absolute right-0 top-1/2 transform translate-x-1/2 rotate-45 w-2 h-2 bg-indigo-100"></div>
                  {message &&
                    message?.hasOwnProperty("link") &&
                    message?.link?.hasOwnProperty("href") && (
                      <div className="text-instant-teams-teal-Main p-2 w-auto">
                        <button className="w-full px-4 py-2 h-auto text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none text-sm">
                          <a
                            href={message?.link?.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block text-xs"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 512 512"
                              className="fill-white inline-block pr-2"
                            >
                              <path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z" />
                            </svg>
                            {message?.link?.title}
                          </a>
                        </button>
                      </div>
                    )}
                  {message &&
                    message?.file !== undefined &&
                    message?.file !== null && (
                      <div className="text-instant-teams-teal-Main p-2 w-auto">
                        <button className="w-full px-4 py-2 h-auto text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none text-sm">
                          <a
                            href={message?.file}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block text-xs"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 512 512"
                              className="fill-white inline-block pr-2 w-auto"
                            >
                              <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z" />
                            </svg>
                            Download File
                          </a>
                        </button>
                      </div>
                    )}
                  {message && message?.hasOwnProperty("image") && (
                    <div className="text-instant-teams-teal-Main p-2">
                      <img
                        className="object-cover w-[250px]"
                        src={message?.image}
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : minimize ? (
            <>
              <div className="w-3/4">
                <div className="flex items-center">
                  <div
                    className={`" font-extrabold pl-4 justify-center ${
                      minimize ? "text-sm" : "text-xl"
                    }"`}
                  >
                    {senderName}
                  </div>
                  <div className="pl-4 text-sm flex items-center text-gray-500">
                    {DisplayTime(message?.createdAt)}
                  </div>
                </div>
                {body && body !== "" && <div className="pl-16">{body}</div>}
                {message &&
                  message?.hasOwnProperty("link") &&
                  message?.link?.hasOwnProperty("href") && (
                    <div className="text-instant-teams-teal-Main p-2 w-auto">
                      <button className="w-full px-4 py-2 h-auto text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none text-sm">
                        <a
                          href={message?.link?.href}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block text-xs"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                            className="fill-white inline-block pr-2"
                          >
                            <path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z" />
                          </svg>
                          {message?.link?.title}
                        </a>
                      </button>
                    </div>
                  )}
                {message &&
                  message?.file !== undefined &&
                  message?.file !== null && (
                    <div className="text-instant-teams-teal-Main p-2 w-auto">
                      <button className="w-full px-4 py-2 h-auto text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none text-sm">
                        <a
                          href={message?.file}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block text-xs"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                            className="fill-white inline-block pr-2 w-auto"
                          >
                            <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z" />
                          </svg>
                          Download File
                        </a>
                      </button>
                    </div>
                  )}
                {message && message?.hasOwnProperty("image") && (
                  <div className="text-instant-teams-teal-Main p-2">
                    <img
                      className="object-cover w-[250px]"
                      src={message?.image}
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="flex items-center mb-4">
              <div className="flex-none flex flex-col items-center space-y-1 mr-4">
                <img
                  className="rounded-full w-10 h-10"
                  src={userData?.photoURL || "/images/default_user.png"}
                />
                <a href="#" className="block text-xs hover:underline">
                  {userData?.preferredName || userData?.name}
                </a>
                <div className="flex-1 text-xxs">
                  {DisplayTime(message?.createdAt)}
                </div>
              </div>
              <div className="flex-1 bg-indigo-400 text-white p-2 rounded-lg mb-2 relative">
                <div>{body}</div>
                <div className="absolute left-0 top-1/2 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-indigo-400"></div>
                {message &&
                  message?.hasOwnProperty("link") &&
                  message?.link?.hasOwnProperty("href") && (
                    <div className="text-instant-teams-teal-Main p-2 w-auto">
                      <button className="w-full px-4 py-2 h-auto text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none text-sm">
                        <a
                          href={message?.link?.href}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block text-xs"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                            className="fill-white inline-block pr-2"
                          >
                            <path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z" />
                          </svg>
                          {message?.link?.title}
                        </a>
                      </button>
                    </div>
                  )}
                {message && message?.hasOwnProperty("image") && (
                  <div className="text-instant-teams-teal-Main p-2">
                    <img
                      className="object-cover w-[250px]"
                      src={message?.image}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MessageItem;
