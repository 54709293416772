import { useQuery } from "react-query";
import { doc, query, where, collection, limit } from "firebase/firestore";
import { db, createQuery } from "../providers/database";
import { collectionNames } from "./dictionary/collectionNames";

export function useEmploymentByEmployee(employeeId: string) {
  return useQuery(
    ["employee by employee Id", { employeeId }],
    createQuery(() => doc(db, collectionNames.employees, employeeId)),
    { enabled: !!employeeId },
  );
}

export function useEmploymentByUserIdAndJobId(userId: string, jobId: string) {
  return useQuery(
    ["employeebyuser", { userId, jobId }],
    createQuery(() =>
      query(
        collection(db, collectionNames.employees),
        where("userId", "==", userId),
        where("jobId", "==", jobId),
        limit(1),
      ),
    ),
    { enabled: !!userId },
  );
}
export function useAllUserEmployment(userId: string) {
  return useQuery(
    ["allUserEmployment", { userId }],
    createQuery(() =>
      query(collection(db, "Employees"), where("userId", "==", userId)),
    ),
    { enabled: !!userId },
  );
}

export function useEmployeesByJobId(jobIds: Array<string>) {
  return useQuery(
    ["allEmployeesByJobId", { jobIds }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.employees}`,
        ),
        where("jobId", "in", jobIds),
      );
    }),
    { enabled: !!jobIds },
  );
}

export function useEmployeesByTraining(training: string) {
  return useQuery(
    ["allEmployeesByTraining", { training }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.employees}`,
        ),
        where("training", "==", training),
        where("status", "in", ["pending", "started"]),
      );
    }),
    { enabled: !!training },
  );
}