import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useEmployerInterviewQuestions } from '../../../data/employer';
import EInterviewDefault from './einterview-default';
import EInterviewEmployerCustom from './einterview-employer-custom';

function QuestionListEmployer() {
  const params = useParams();
  const [employerId, setEmployerId] = useState(
    params.employerId,
  );
  const [eInterviewSource, setEInterviewSource] = useState("default");
  const { data: employerQuestions, status: eqStatus, error } = useEmployerInterviewQuestions(employerId);

useEffect(() => {
  if(employerQuestions && employerQuestions.length > 0 ){
    setEInterviewSource("employer");
  }
}, [employerQuestions, employerId])

  return (
    <>
      {eInterviewSource === "employer" && (
        <EInterviewEmployerCustom employerId={employerId}/>
      )}
      {eInterviewSource === "default" && (
        <EInterviewDefault employerId={employerId} callback={setEInterviewSource}/>
      )}
    </>
  );
}
export default QuestionListEmployer;
