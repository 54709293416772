import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  useAllEmployerLocations,
  useEmployerUsers,
} from "../../../../data/employer";
import useModal from "../../../../hooks/useModal";
import { Locations, LocationItems } from "../../../../dataTypes/Company";
import DialogWrapper from "../../../custom-ui/dialog/Dialog";
import CreateEmployerLocation from "../parts/CreateEmployerLocation";
import LocationItem from "../parts/LocationItem";
import { useCurrentUserEmployerId } from "../../../../hooks/useCurrentUserEmployerId";

const EmployerLocationListing = () => {
  const userEmployerId = useCurrentUserEmployerId();
  const params = useParams();
  const [employerId, setEmployerId] = useState(
    params.employerId || userEmployerId,
  );
  const { toggle: createLocationToggle, isShowing: createDept } = useModal();
  const {
    data: locations = [],
    status: locationsStatus,
    error: locationsError,
  } = useAllEmployerLocations(employerId ?? userEmployerId);

  const {
    data: employees = [],
    status: employeesStatus,
    error: employeesError,
  } = useEmployerUsers(employerId ?? userEmployerId);

  useEffect(() => {
    setEmployerId(params.employerId ?? userEmployerId);
  }, [params.employerId]);

  return (
    <div>
      <div className="flex flex-row justify-end px-10 mb-5">
        <button
          className="bg-instant-teams-teal-Main rounded-md shadow-md text-white px-1.5 py-1.5 "
          onClick={() => {
            createLocationToggle();
          }}
        >
          Add Location
        </button>
      </div>
      <div className="grid grid-cols-4 gap-4 ml-5 pr-20">
        {locations.map((location: LocationItems) => (
          <LocationItem
            key={location.id}
            employerId={employerId}
            location={location}
          />
        ))}
      </div>
      {createDept && (
        <DialogWrapper
          title="Create New Location"
          size="mt-36 w-1/4"
          onClose={() => {
            createLocationToggle();
          }}
        >
          <CreateEmployerLocation
            closeModal={() => {
              createLocationToggle();
            }}
          />
        </DialogWrapper>
      )}
    </div>
  );
};

export default EmployerLocationListing;
