import { useParams } from "react-router-dom";
import { useListing } from "../../data/listings";
import Meta from "../custom-ui/meta/Meta";
import GreenUnderlineHeading from "../custom-ui/headings/heading";
import { useLookup } from "../../data/lookup";
import { LookUp } from "../../dataTypes/LookUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTimeZone, getTimeFromMinute } from "../../util/timezone/timezone";
import { useJobPositionDetails, useActiveJobPositions } from "../../data/positions";
import { useJobDescription } from "../../data/jobDescriptions";
import { sanitizeMarkup } from "../../util/sanitize/sanitize-html";
import "../../styles/dangerouslySet.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useEmployer } from '../../data/employer';
import { useJobDescriptionSkills } from '../../data/jobDescriptions';
import { useComponent } from '../../data/academy';
import JobListingShareSkill from './JobSocialSkills';
import JobListingSharePosition from './JobListingDetailsPosition';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, TwitterIcon, FacebookIcon, LinkedinIcon } from 'react-share';
import { useAuth } from '../../providers/auth';
import { useLocation } from 'react-router-dom';
import { getLevel } from '../../util/assessments/level';

function JobListingShareRender({
  share, 
  jobData, 
  employerData,
  jobPositions,
  jobDescriptionSkills,
}:{
  share:any, 
  jobData:any, 
  employerData:any,
  jobPositions:any,
  jobDescriptionSkills:any
}) {
  const[ jobTitle, setJobTitle ] = useState(null);
  const [columns, setColumns] = useState(4);
  const [url, setUrl] = useState(window.location.href);


  useEffect(() => {
    if(jobData && employerData) {
      setJobTitle(`${employerData?.displayName} - ${jobData.title}`);
    }
  },[jobData, employerData])

  useEffect(() => {
    if(jobPositions && jobPositions.length > 0) {
      const count = jobPositions.length;
      if(count < 5){
        setColumns(count);
      } else {
        if(count % 2 === 0) {
          setColumns(count/2);
        } else {
          setColumns(count/2 + 1);
        }
      }
    }},[jobPositions])

  return (
    <>
      {
        jobTitle && 
        <Meta 
          title={jobTitle} 
          postTitle={share.title}
          shareDescription={share.description}
          twitterImage={share.square?.imageUrl}
          images={share.wide?.imageUrl}
          shareUrl={url}
        />
      }      
      <section className="pb-16">
        <div className="grid grid-flow-row mx-auto grid-cols-8 gap-y-3 w-3/4">
            <img
              className="my-auto col-span-1 w-full h-24 object-contain object-center"
              src={employerData?.logo}
              alt={employerData?.name}
            />
          <div className="pl-5 col-span-5 my-auto  text-instant-teams-blue-Main justify-start md:text-sm">
            <div className="text-xl">{employerData?.displayName}</div>
            <div className="font-semibold uppercase text-2xl">{jobData?.title}</div>
          </div>
          <div className="col-span-2 space-y-5">
            <button className="py-3 w-full uppercase bg-instant-teams-green-Main hover:bg-instant-teams-blue-Main text-white rounded-lg md:py-2 md:px-8 md:text-sm lg:py-2 lg:px-16 xl:px-20">
              Join Now to Apply
            </button>
            <div className="grid grid-cols-5 gap-2">
              <div className="flex flex-col col-span-2 uppercase text-instant-teams-blue-Main font-bold h-42 justify-center align-middle">
                Share to:
              </div>
              <div className='w-full'>
                <FacebookShareButton
                  url={window.location.href}
                  quote={share?.title || "New Job Announcement"}
                  hashtag={share?.hashtags || "#instantteams"}
                >
                  <FacebookIcon size={42} round />
                </FacebookShareButton>
              </div>
              <div className='w-full'>
                <LinkedinShareButton
                  url={window.location.href}
                  title={share?.title || "New Job Announcement"} 
                  summary={share?.description || "Look at this great Job on Instant Teams Marketplace"}
                  source="Instant Teams Marketplace"
                >
                  <LinkedinIcon size={42} round />
                </LinkedinShareButton>
              </div>
              <div className='w-full'>
                <TwitterShareButton 
                  url={window.location.href}
                  title={share?.title || "New Job Announcement"}
                  hashtags={[share?.hashtags] || ["#instantteams"]}
                >
                  <TwitterIcon size={42} round />
                </TwitterShareButton>
              </div>                
            </div>
          </div>
          <div className="col-span-8 h-34 bg-instant-teams-teal-Main w-full p-2 mt-4 rounded-xl text-center">
            <div className="grid grid-cols-5 gap-4">
              <div></div>
              <div className='text-xl font-bold uppercase text-white pr-4 h-16 pb-[8px] min-h-16 text-center pt-8'>
                Download the app:
              </div>
              <div className='text-center pt-4'>
                <a 
                  className="h-16 inline-block"
                  href={`https://apps.apple.com/us/app/instant-teams-marketplace/id1666030415?itsct=apps_box_badge&amp;itscg=30200`} 
                  target="_blank"
                >
                  <img 
                    className="w-full h-16"
                    src={`https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1685577600`} 
                    alt="Download on the App Store" 
                  />
                </a>
              </div>
              <div className='text-center'>
                <a 
                  className="h-20 inline-block"
                  href='https://play.google.com/store/apps/details?id=com.instantteams.athena&utm_source=landingPage&utm_campaign=launch&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&utm_source=build.instantteams.com&utm_campaign=Build&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' 
                  target="_blank"
                >
                  <img 
                    className="w-full h-24"
                    alt='Get it on Google Play' 
                    src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                  />
                </a>
              </div>
            </div>              
          </div>
          <div className="col-span-8">
            <GreenUnderlineHeading title={ jobPositions?.length > 1 ? "Available Positions" : "Available Position"} />
            {jobPositions && jobPositions.length > 0 && (
              <div className={`grid grid-cols-${columns} gap-6`}>
                {jobPositions?.map((position: any) => {
                  return (
                    <JobListingSharePosition key={position.id} position={position} />
                  );
                })}
              </div>
            )}
          </div>
          {jobDescriptionSkills && jobDescriptionSkills.length > 0 && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="required assessments" />
              <div className="flex space-x-5">
                {jobDescriptionSkills?.map((skill: any) => {
                  return (
                    <JobListingShareSkill key={skill.id} skill={skill} />
                  );
                })}
              </div>
            </div>
          )}
          {jobData?.description?.dayInTheLife && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="Day in the life" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  jobData?.description?.dayInTheLife,
                )}
              ></div>
            </div>
          )}
          {jobData?.description?.mustHaves && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="must have" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  jobData?.description?.mustHaves,
                )}
              ></div>
            </div>
          )}
          {jobData?.description?.training && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="Training" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  jobData?.description?.training,
                )}
              ></div>
            </div>
          )}
          {jobData?.description?.benefits && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="Benefits" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  jobData?.description?.benefits,
                )}
              ></div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default JobListingShareRender;
