import Meta from "../../components/custom-ui/meta/Meta";
import { requireAuth, useAuth } from "../../providers/auth";
import {
  SearchBox,
  RefinementList,
  CurrentRefinements,
  ClearRefinements,
  Hits,
  HitsPerPage,
  RefinementListProps,
  Pagination,
// } from "react-instantsearch-hooks-web"; // deprecated to "react-instantsearch"
} from "react-instantsearch";
import JobNewListingLineComponent from "../../components/jobs/parts/JobNewListingLineItem";
import CreateJobNewBasicForm from "../../components/jobs/parts/CreateJobNewBasicForm";
import DialogWrapper from "../../components/custom-ui/dialog/Dialog";
import useModal from "../../hooks/useModal";
import { getLabelNew } from "../../util/search/getLabel";
import { useAllJobDescriptionsByStatus } from "../../data/jobDescriptions";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import ToastAlert from "../../components/custom-ui/toast-alert/ToastAlert";

function JobsNewManagementPage() {
  const { userClaims } = useAuth();
  const { isShowing, toggle } = useModal();
  const [jobList, setJobList] = useState([]);
  const [formAlert, setFormAlert] = useState(null);
  const { data: allLiveJobs } = useAllJobDescriptionsByStatus("live");

  const transformStatus: RefinementListProps["transformItems"] = (items) => {
    items = items.map((item: any) => ({
      ...item,
      label: getLabelNew(item.label, "jobState"),
    }));

    return items;
  };

  const handleFormAlert = (alert: string) => {
    // alert {type:string, message:string}
    setFormAlert(alert);
    if (alert === "error") {
      toast.error("There was an error creating your job");
    } else if (alert === "success") {
      toast.success("New Job (New) successfully created");
    }
  };

  const transformSolution: RefinementListProps["transformItems"] = (items) => {
    items = items.map((item: any) => ({
      ...item,
      label: getLabelNew(item.label, "solutionType"),
    }));
    return items;
  };

  useEffect(() => {
    if (allLiveJobs && allLiveJobs.length > 0) {
      const currentJobs: Array<string> = [];
      allLiveJobs.map(async (job: any) => {
        currentJobs.push(job.id);
      });
      setJobList(currentJobs);
    }
  }, [allLiveJobs]);

  return (
    <>
      {formAlert && (
        <div
          className={
            "mb-4 mx-auto w-5/6 text-center" +
            (formAlert.type === "error" ? " text-red-600" : "") +
            (formAlert.type === "success" ? " text-green-600" : "")
          }
        >
          {formAlert.message}
        </div>
      )}
      <div>
        <Meta title="Jobs Management" />
        <div className="flex pt-36">
          <div className="basis-1/6">
            <div className="px-6">
              <div className="flex flex-col items-center">
                <button
                  className=" w-[90%] h-10 mb-4 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                  onClick={toggle}
                >
                  Create New Job
                </button>
              </div>
              <SearchBox
                placeholder="Keyword Search"
                classNames={{
                  input: "w-full",
                  resetIcon: "hidden",
                  submitIcon: "hidden",
                }}
              />
              <CurrentRefinements />
              Filter by Status
              <RefinementList
                attribute="jobState"
                operator="and"
                classNames={{
                  root: "capitalize",
                  showMore: " text-instant-teams-blue-Main text-xs",
                }}
                limit={5}
                showMore={true}
                transformItems={transformStatus}
              />
              Filter by Solution Type
              <RefinementList
                attribute="solutionType"
                operator="and"
                classNames={{
                  root: "capitalize",
                  showMore: " text-instant-teams-blue-Main text-xs",
                }}
                limit={5}
                showMore={true}
                transformItems={transformSolution}
              />
              <ClearRefinements
                translations={{ resetButtonText: "Clear Filters" }}
                classNames={{
                  button:
                    " bg-instant-teams-teal-Main text-white p-2 rounded mt-5 mb-10 text-center w-full",
                }}
              />
            </div>
          </div>
          <div className="basis-5/6 mb-36">
            <div className="flex justify-end w-[90%]">
              <div className="flex items-center">
                <div className=" pr-4">Results per page:</div>
                <HitsPerPage
                  items={[
                    { label: "10", value: 10 },
                    { label: "25", value: 25 },
                    { label: "50", value: 50, default: true },
                    { label: "100", value: 100 },
                  ]}
                  classNames={{ select: "text-xs h-6 py-0" }}
                />
              </div>
            </div>

            <div className="mt-4 w-[90%]">
              <Hits
                hitComponent={(hit: any) => (
                  <JobNewListingLineComponent row={hit} />
                )}
                classNames={{
                  root: "ais-InfiniteHits",
                  list: "ais-InfiniteHits-list grid grid-cols-5 gap-4 w-full",
                }}
              />
            </div>
            <div className="flex justify-center">
              <div className="mt-12">
                <Pagination
                  showNext={true}
                  padding={1}
                  classNames={{
                    list: "flex",
                    item: "h-8 w-8 text-center rounded-m rounded mx-1 bg-white",
                    selectedItem: "scale-125 border-black",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {isShowing && (
          <DialogWrapper title="Create Job (New)" onClose={toggle}>
            <CreateJobNewBasicForm
              handleFormAlert={handleFormAlert}
              toggle={toggle}
            />
          </DialogWrapper>
        )}
      </div>
      <ToastAlert />
    </>
  );
}

export default requireAuth(JobsNewManagementPage);
