import CompanyNotesListing from "./CompanyNotesListing";
import { useParams } from "react-router-dom";

function ExternalNotesListing() {
  const params = useParams();
  const { id } = params;

  return (
    <>
      <CompanyNotesListing
        collection={"JobDescriptions"}
        paramId={id as string}
      />
    </>
  );
}

export default ExternalNotesListing;
