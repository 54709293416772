import { useParams } from "react-router";
import JobNewViewApplyMetricsChart from "../../../charts/jobNewViewApplyMetricsChart";
import OffersMade from "../parts/OffersMade";
import JobListingSocials from "../parts/JobListingSocials";
// import { useJobDetailsNewCollection } from "../../../data/jobDetailsNewCollection";

function JobNewOverview() {
  const params = useParams();
  const jobId = params.id ?? "";
  // const { user } = useAuth();
  // const { data: job } = useJobDetailsNewCollection(jobId);

  return (
    <div className="w-full pb-36 flex flex-col gap-y-40">
      <div>
        <JobListingSocials id={jobId} />
      </div>
      <div>
        <JobNewViewApplyMetricsChart jobId={jobId} />
      </div>
    </div>
  );
}

export default JobNewOverview;
