import { useEffect } from "react";
import { requireAuth } from "../../../providers/auth";
import { useParams } from "react-router-dom";
import { useComponent } from "../../../data/academy";
import { sanitizeMarkup } from "../../../util/sanitize/sanitize-html";
import Meta from "../../custom-ui/meta/Meta";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import TestQuestions from "../parts/testQuestions";
import "../../../styles/dangerouslySet.css";
import { getLevel } from '../../../util/assessments/level';
const CatalogDetails = () => {
  const params = useParams();
  const { assessmentId = "" } = params ?? {};
  const { data: assessment } = useComponent(assessmentId);

  return (
    <>
      <Meta title={assessment?.name || "Catalog Details"} />
      <div className="w-4/5 flex pl-15 pb-24">
        <div className="ml-6 pt-36 pb-4 grid grid-cols-4 gap-4">
          <div className=" pl-6 col-span-1">
            <img
              className=" w-full rounded-xl"
              src={assessment?.badge}
              alt={assessment?.name}
            />
          </div>
          <div className=" pl-6 col-span-1 justify-center align-middle items-center min-h-full flex">
            <p className="text-3xl text-instant-teams-teal-Main font-bold text-center">
              {assessment?.name}
            </p>
          </div>
          <div className="flex flex-col text-xl justify-center ">
            <p>Completion Time: {assessment?.duration ?? 0} mins</p>

            <p>Career Seekers Completed: {assessment?.finished ?? 0}</p>
          </div>
          <div className="pl-6 ml-6 col-span-4 pr-16">
            <GreenUnderlineHeading title="description" />
            {assessment?.description && (
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  assessment?.description,
                )}
              ></div>
            )}
          </div>
          <div className="pl-6 ml-6 col-span-4 pr-16">
            <GreenUnderlineHeading title="Skills" />
            {assessment &&
              assessment?.tests_detail?.map((item: any, index: any): any => {
                return (
                  <div
                    className={
                      index > 0 ? "w-full border-t border-black" : "w-full"
                    }
                    key={index}
                  >
                    <div className="w-full">
                      <TestQuestions details={item?.test?.covered_skills} />
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="w-1/5 fixed top-28 right-0 min-h-screen">
            <img
              className="absolute top-0 right-0 w-full min-h-screen z-10 object-cover"
              src="/images/catalog.png"
              alt="Catalog"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default requireAuth(CatalogDetails);
