import * as z from "zod";

export const getJobStatusNoteNewSchema = (
  isClosed: boolean,
  isFilled: boolean,
) => {
  // closedFilledBy
  // isFilled

  return z.object({
    ...(!isClosed
      ? {
          statusId: z.string({
            required_error: "Status Transition is required",
          }),
          closedType: z.string().optional(),
          closedFilledBy: z.string().optional(),
          closedReason: z.string().optional(),
        }
      : {
          statusId: z.string().optional(),
          closedType: z
            .string({
              required_error: "Closed Type is required",
            })
            .min(1, "Closed Type is required"),
          ...(isFilled
            ? {
                closedFilledBy: z
                  .string({
                    required_error: "Filled via is required",
                  })
                  .min(1, "Filled via is required"),
                closedReason: z.string().optional(),
              }
            : {
                closedFilledBy: z.string().optional(),
                closedReason: z
                  .string({
                    required_error: "Reason is required",
                  })
                  .min(1, "Reason is required"),
              }),
        }),
  });
};
