import React, { useState, useEffect } from "react";
import { updateAutoSave, logActivity } from "../../../data/utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faCircleExclamation } from "@fortawesome/pro-duotone-svg-icons";
import { useAuth } from "../../../providers/auth";

const AutoSaveNumber = ({
  value: initialValue,
  collection,
  recId,
  required,
  name,
  display,
  placeholder,
  directions,
  readOnly,
  maxValue,
  minValue,
}) => {
  const auth = useAuth();
  const [coreValue, setValue] = useState(0);
  const [check, setCheck] = useState(initialValue);
  const [isValid, setIsValid] = useState(true);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (
      initialValue !== null &&
      initialValue !== undefined &&
      initialValue <= maxValue &&
      initialValue >= minValue
    ) {
      setValue(initialValue);
    } else {
      setValue(minValue);
      updateAutoSave(collection, recId, { [name]: minValue });
      console.log(maxValue);
    }
  }, [initialValue, minValue]);

  const handleBlur = async () => {
    if (!coreValue && required) {
      setIsValid(false);
      return;
    }
    if (coreValue !== check) {
      if (coreValue <= maxValue && coreValue >= minValue) {
        setIsValid(true);
        setCheck(coreValue);
        await updateAutoSave(collection, recId, { [name]: coreValue });
        await logActivity(
          collection,
          recId,
          name,
          check,
          coreValue,
          auth.user.uid,
          check === "" ? "Added" : "Edited",
        );
        setSaved(true);
        setError(false);
      } else {
        setError(true);
      }
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (coreValue <= maxValue && coreValue >= minValue) {
      setError(false);
    } else {
      setError(true);
    }
  }, [coreValue]);

  return (
    <div id={name} className="flex-auto">
      <div className="relative mb-4">
        <label className="text-instant-teams-teal-Main text-sm  absolute  duration-300 transform -translate-y-4 scale-75 top-4 origin-[0] left-2.5 peer-focus:text-instant-teams-blue-Main peer-placeholder-shown:scale-70 peer-placeholder-shown:translate-y-0 peer-focus:scale-55 peer-focus:-translate-y-4">
          {display} {directions ? " - " + directions : null}
          {readOnly ? " - LOCKED" : null}
        </label>
        <input
          type="number"
          name={name}
          placeholder={placeholder}
          value={coreValue || maxValue}
          onChange={(e) => handleChange(e)}
          onBlur={handleBlur}
          className="form-input shadow-md rounded-md w-full pt-3 pb-3"
          readOnly={readOnly}
        />
        {saved && (
          <span className="absolute right-2 top-2 text-xxs">
            {display} saved!{" "}
            <FontAwesomeIcon icon={faCircleCheck} style={{ color: "green" }} />
          </span>
        )}
        {coreValue >= minValue && coreValue <= maxValue ? (
          <></>
        ) : (
          <span className="absolute right-2 top-8 text-xxs">
            Entry Exceeds Maximum Value of {maxValue}!{" "}
            <FontAwesomeIcon
              icon={faCircleExclamation}
              style={{ color: "red" }}
            />
          </span>
        )}
        {error && (
          <div className="error text-xs">
            Unable to save, check field {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default AutoSaveNumber;
