import { Outlet } from "react-router-dom";
import { useEmployer } from "../../../data/employer";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const EmployerInfo = () => {
  const params = useParams();
  const { error: employerError } = useEmployer(params.employerId ?? "");

  useEffect(() => {
    if (employerError) {
      console.log(employerError);
    }
  }, [employerError]);

  return (
    <div className=" md:container w-full">
      <Outlet />
    </div>
  );
};

export default EmployerInfo;
