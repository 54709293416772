import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { subCollectionNames } from "../../data/dictionary/collectionNames";
import { useAllStatusHistoryByCollectionAndId } from "../../data/changeLogs";
import ChangeLogItem from "./change-log-item";
import GreenUnderlineHeading from "../custom-ui/headings/heading";

function ChangeLogs({
  collection,
  paramId,
}: {
  collection: string;
  paramId: string;
}) {
  const { statusHistory } = subCollectionNames;

  const params = useParams();
  const [id, setId] = useState("");
  useEffect(() => {
    if (paramId === "userId") {
      setId(params.userId);
    } else if (paramId === "employerId") {
      setId(params.employerId);
    } else if (paramId === "jobId") {
      setId(params.id);
    }
  }, [params, paramId]);

  const { data: fetchedStatusHistories } = useAllStatusHistoryByCollectionAndId(
    id,
    collection,
    statusHistory,
  );

  return (
    <div>
      <GreenUnderlineHeading title="Change Logs" />
      <table className="w-10/12">
        {fetchedStatusHistories?.length !== 0 ? (
          fetchedStatusHistories?.map((row: any, index: string) => {
            return <ChangeLogItem entry={row} key={index} />;
          })
        ) : (
          <div>Nothing to display.</div>
        )}
      </table>
    </div>
  );
}

export default ChangeLogs;
