import { Timestamp } from "./Utilities";
export type AuthOrigin = {
  id?: string;
  email?: string;
  origin?: string;
  status?: string;
  lastUpdated?: Timestamp;
};

export enum Origin {
  community = "COMM",
  seekerWeb = "SKRW",
  seekerMobile = "SKRM",
  employerSelfRegistration = "EMPS",
  employerInvited = "EMPI",
}

export enum AuthStatus {
  start = "started",
  complete = "completed",
}
