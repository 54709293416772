import { NavLink } from "react-router-dom";

import { useAuth } from "../../../providers/auth";
function SidebarWrapper({
  options,
  headerSection: HeaderSection,

  children,
}: {
  options: any;
  headerSection?: any;
  children: any;
}) {
  const { role } = useAuth();

  const activeStyle =
    "bg-instant-teams-teal-Main text-white border-l-4 border-0 border-green-400 text-left pl-4  block w-screen py-2 cursor-pointer";
  const inActiveStyle =
    "text-left  hover:bg-instant-teams-teal-Main w-full pl-4 hover:border-l-4 hover:border-green-400 block w-screen py-2 cursor-pointer";
  return (
    <>
      <div className="absolute left-0 top-28 min-h-screen overflow-hidden ">
        <div
          id="tabmenu"
          className="bg-instant-teams-blue-Main text-white font-medium pt-8 pb-36 w-1/6 h-screen fixed overflow-auto no-scrollbar shadow-black shadow-inner"
        >
          <div className=" sticky top-0 bg-instant-teams-blue-Main ">
            {HeaderSection && <HeaderSection />}
          </div>
          <div>
            {options.map((pageObj: any, index: number) => {
              return (
                <div key={pageObj.id}>
                  {pageObj.viewer !== undefined &&
                    pageObj.viewer.includes(role.id) &&
                    pageObj.menuDisplay && (
                      <div className="w-full">
                        <NavLink
                          id={pageObj.id}
                          key={pageObj.id}
                          to={pageObj.path}
                          className={({ isActive }: { isActive: boolean }) =>
                            isActive ? activeStyle : inActiveStyle
                          }
                        >
                          {pageObj.name}
                        </NavLink>
                      </div>
                    )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex-1 ml-[20%] pt-36 pr-10">{children}</div>
    </>
  );
}

export default SidebarWrapper;
