import moment from 'moment';
import 'moment-timezone';

export const getTimeFromMinute = (minutes?: number) =>
  minutes
    ? moment
        .utc(minutes * 60 * 1000)
        // .tz(timeZone ?? moment.tz.guess())
        .format('h:mm A')
    : undefined;

export const getTimeZone = (timeZone?: string) => (timeZone ? moment.tz(timeZone).zoneAbbr() : undefined);