import AutoSaveText from "../../custom-ui/input-fields/auto-save-field";
import AutoSaveNumber from "../../custom-ui/input-fields/auto-save-number";
import AutoSaveCheckboxGroup from "../../custom-ui/input-fields/auto-save-checkbox-group";
import AutoSaveDateRange from "../../custom-ui/input-fields/auto-save-date-range";
import AutoSaveSlider from "../../custom-ui/input-fields/auto-save-slider";
import AutoSavePayTypeWysiwyg from "../../custom-ui/input-fields/auto-save-pay-type-wysiwyg";
import FadeInFromTop from "../../custom-ui/animations/transitions/FadeInFromTop";
import { isNil } from "lodash";
import Meta from "../../custom-ui/meta/Meta";
import { Timestamp } from "firebase/firestore";
import { useLookup } from "../../../data/lookup";
import { useJobDescription } from "../../../data/jobDescriptions";
import { useEmployer, useEmployerDepartments } from "../../../data/employer";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { collectionNames } from "../../../data/dictionary/collectionNames";
import AutoSaveSelectGeneric from "../../custom-ui/input-fields/auto-save-select-generic";
import { updateAutoSave } from "../../../data/utility";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../providers/database";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import DragAndDropVariantA from "../../custom-ui/drag-and-drop/DragAndDropFile";
import JobListingFiles from "../../jobs/parts/JobListingFiles";
import { useState } from "react";
import { upload } from "../../../storage/storage";
import folderNames from "../../../storage/dictionary/folderNames";
import { writeFileLocation } from "../../../data/jobDescriptions";
import { friendlyFirebaseError } from "../../../providers/firebase-errors/friendlyFirebaseError";
import { createNotification } from "../../../data/notifications";
import { useAuth } from "../../../providers/auth";
import { useEffect } from "react";
import { updateStatusHistory } from "../../../data/utility";

function ExternalNewJob() {
  const { userClaims } = useAuth();
  const { jobDescriptions } = collectionNames;
  const params = useParams();
  const navigate = useNavigate();
  const jobId = params?.id ?? "";
  const { data: job = {}, status: jobStatus } = useJobDescription(jobId);
  const { data: dept = {} } = useEmployerDepartments(job?.employer);
  const { data: model = [] } = useLookup("model");
  const { data: term = [] } = useLookup("term");
  const { data: classification = [] } = useLookup("classification");
  const { data: payType = [] } = useLookup("payType");
  const [file, setFile] = useState();
  const [formAlert, setFormAlert] = useState(null);
  const [error, setError] = useState(false)
  // Has Seasonal Schedule
  const hasSeasonal = () => {
    if (Array.isArray(job?.information?.term)) {
      return !!job?.information?.term.find((item: string) => item === "SEA");
    }
    return false;
  };

  const { data: employer } = useEmployer(userClaims?.claims?.employerId);
  const employerName = employer?.displayName
    ? employer?.displayName
    : employer?.legalName;
  const handleSave = async () => {

    if (job?.information?.model?.length <= 0 || job?.information?.classification?.length <=0 || job?.information?.term?.length<=0 ||
      (job?.information?.payType && Object.keys(job?.information?.payType).length === 0)
    ) {
      setError(true)   
    }
    else {
      await updateAutoSave("JobDescriptions", jobId, { status: "tac_review" });
      const history = {
        alteredBy: userClaims.claims.user_id,
        effectiveDate: Timestamp.now(),
        from: jobStatus,
        status: "tac_review",
      };
      await updateStatusHistory("JobDescriptions", jobId, history);
      createNotification({
        group: "tac",
        title: "New Job Created",
        body: `${employerName} submitted a new job for review.`,
        createdBy: `${userClaims?.claims?.user_id}`,
        isPushNotification: false,
        url: `jobs/${jobId}/${userClaims?.claims?.employerId}/description`,
      })
        .then((res) => {
          console.log("notification created");
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          navigate("/jobs", { replace: true });
        });
    }
  };

  const handleCancel = async () => {
    await deleteDoc(doc(db, "JobDescriptions", jobId));
    navigate("/jobs", { replace: true });
  };

  const confirmUpload = () => {
    upload(file, folderNames.jobDescriptions, `${jobId}_${file?.name}`).then(
      (fileUrl) => {
        writeFileLocation(jobId, {
          label: file.name,
          type: file.type,
          link: fileUrl,
        })
          .then((docRef) => {
            setFile();
          })
          .catch((error: Error) => {
            setFormAlert({
              type: "error",
              message: friendlyFirebaseError(error),
            });
          })
          .catch((error: Error) => {});
      },
    );
  };

  return (
    <div className="pt-36 min-h-screen">
      <Meta title="New Job" />
      <div className="flex justify-center space-x-40">
        <div className="flex space-x-20 justify-between">
          {/* First column */}
          <div className="w-96">
            <AutoSaveText
              value={job?.title}
              collection={jobDescriptions}
              recId={jobId}
              required={true}
              name="title"
              display="Job Title"
              placeholder={"Enter Job Title"}
              directions=""
              readOnly={false}
            />
            <div className="mb-8 pr-4">
              {formAlert && (
                <div className="mb-4 text-red-600">{formAlert.message}</div>
              )}
              <GreenUnderlineHeading title="Job Description Upload" />
              <div className="text-center mb-2">
                <DragAndDropVariantA setFile={setFile} />
                <div className="relative flex justify-center">
                  {file && (
                    <button
                      className="absolute bottom-3 mt-4  py-2 px-4  text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                      onClick={confirmUpload}
                    >
                      Upload File
                    </button>
                  )}
                </div>
              </div>
            </div>
            <GreenUnderlineHeading title="Number of seats" />
            <AutoSaveNumber
              value={!isNil(job?.seats) ? Number(job.seats) : 1}
              collection={jobDescriptions}
              recId={jobId}
              required={false}
              name={"seats"}
              display={""}
              placeholder={"Enter the number of seats."}
              directions={""}
              readOnly={false}
              minValue={1}
              maxValue={300}
            />
            <div className="mb-2">
              <GreenUnderlineHeading title="Work Settings" />
              <AutoSaveCheckboxGroup
                name="information.model"         
                options={model}
                initialValues={job?.information?.model}
                collection={jobDescriptions}
                id={jobId}
              />
              {job?.information?.model?.length<=0 && error && <p className="text-red-500">This is Required</p>}
            </div>
            <div className="mb-2">
              <GreenUnderlineHeading title="Work Type" />
              <AutoSaveCheckboxGroup
                name="information.classification"       
                options={classification}
                initialValues={job?.information?.classification}
                collection={jobDescriptions}
                id={jobId}
              />
              {job?.information?.classification?.length<=0 && error && <p className="text-red-500">This is Required</p>}

            </div>
            <div className="mb-4">
              <GreenUnderlineHeading title="Work Term" />
              <div className="mb-2">
                <AutoSaveCheckboxGroup
                  name="information.term"       
                  options={term}
                  initialValues={job?.information?.term}
                  collection={jobDescriptions}
                  id={jobId}
                />
                {job?.information?.term?.length<=0 && error && <p className="text-red-500">This is Required</p>}

              </div>
              <FadeInFromTop show={hasSeasonal()}>
                <div>
                  <AutoSaveDateRange
                    disabled={!hasSeasonal()}
                    initialStart={
                      job?.information?.seasonalDateRange?.startDate instanceof
                      Timestamp
                        ? job?.information?.seasonalDateRange?.startDate.toDate()
                        : null
                    }
                    initialEnd={
                      job?.information?.seasonalDateRange?.endDate instanceof
                      Timestamp
                        ? job?.information?.seasonalDateRange?.endDate.toDate()
                        : null
                    }
                    name="information.seasonalDateRange"
                    collection={jobDescriptions}
                    id={jobId}
                  />
                </div>
              </FadeInFromTop>
            </div>
          </div>
          {/* Second Column */}
          <div className="w-96">
            <AutoSaveSelectGeneric
              value={job?.department??dept[0]?.id}
              options={dept}
              optionValue={"id"} //  key used to get the value from options object. By default it is "code"
              collection={jobDescriptions}
              recId={jobId}
              required={false}
              name="department"
              display="Department"
              placeholder="Select a Department"
              directions=""
            />
            <div className="mb-8  pl-4">
              <GreenUnderlineHeading title="File List" />
              <div className="text-center mb-2">
                <JobListingFiles id={jobId} />
              </div>
            </div>
            <div className="mb-24">
              <GreenUnderlineHeading title="Hours per week" />
              <div className="mt-12 pl-2">
                <AutoSaveSlider
                  initialValues={job?.information?.hoursPerWeek ?? []}
                  collection={jobDescriptions}
                  field={"information.hoursPerWeek"}
                  id={jobId}
                  min={10}
                  max={60}
                  step={1}
                />
              </div>
            </div>
            <div className="mb-4">
              <GreenUnderlineHeading title="Pay Rate" />
              <AutoSavePayTypeWysiwyg
                options={payType}
                collection={jobDescriptions}
                recId={jobId}
                payTypeInitialValue={job?.information?.payType ?? {}}
                name="information.payType"
              />
             { job?.information?.payType && Object.keys(job?.information?.payType).length === 0 && error&&<p className="text-red-500">This field is Required</p>}
            </div>
          </div>
        </div>
        {/* Third Column */}
        <div className="flex flex-col space-y-5 ">
          <button
            onClick={handleSave}
            className="px-24 py-1 border border-instant-teams-blue-Main rounded-sm text-instant-teams-blue-Main font-semibold uppercase"
          >
            Submit
          </button>
          <button
            onClick={handleCancel}
            className="px-24 py-1 border border-instant-teams-blue-Main rounded-sm text-instant-teams-blue-Main font-semibold uppercase"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default ExternalNewJob;
