import { useEffect, useState } from "react";
import ForwardArrowButton from "../../../custom-ui/button/ForwardArrowButton";
import { partiallyMaskEmail } from "../../../../util/strings/masks";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ToastAlert from "../../../custom-ui/toast-alert/ToastAlert";
import { useAuth, isEmailVerified } from "../../../../providers/auth";
import { updateUser } from "../../../../data/user";

function EmailVerification() {
  const navigate = useNavigate();
  const auth = useAuth();
  const { sendVerificationEmail, user, reloadUser, isMultiFactorUser } = auth;
  const { emailVerified, email = "" } = user ?? {};
  const isOnboarded = auth?.userClaims?.claims?.onboarded;

  const [notVerifiedError, setNotVerifiedError] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(reloadUserAndCheckEmailVerified, 10000);
    return () => clearInterval(intervalId);
  }, []);

  async function reloadUserAndCheckEmailVerified() {
    try {
      await reloadUser();
      isEmailVerified()
        .then((user) => {
          updateUser(auth?.user?.id, { emailVerified: true })
          .then(() => console.log("updated user settings."))
          .catch((error) =>
            console.error("Problem updating user record", error),
          );
          navigateNextStep();
        })
        .catch((error) => {
          console.log(error);
          setNotVerifiedError(true);
        });
    } catch (error) {}
  }

  const resendVerificationEmail = () => {
    sendVerificationEmail()
      .then(() => { toast .success("Verification resent to your mail ")})
      .catch((error: Error) => {
        console.error(error);
      });
  };

  const maskedEmail = partiallyMaskEmail(email);

  function navigateNextStep() {
    console.log("Navigating to next step");
    // is the user mfa enrolled?
    // is the user onboarded
    if (!isMultiFactorUser()) {
      navigate("/registration/multifactor-enrollment");
      return;
    } else if (!isOnboarded) {
      navigate("/onboarding");
      return;
    } else {
      navigate("/jobs");
    }
  }

  return (
    <div className="h-full flex justify-center items-center">
      <div className="w-60 text-center text-instant-teams-gray-D1">
        {emailVerified ? (
          <>
            <p>Your email has been verified!</p>
            <p className="mb-8">
              Please proceed to continue the account creation process.
            </p>
          </>
        ) : (
          <div className="flex flex-col items-center">
            <p>An email has been sent to : </p>
            <p className="font-bold my-4">{maskedEmail}</p>
            <p className={`${notVerifiedError ? "text-red-600" : ""}`}>
              Please click on the link provided in the email to verify your
              email address and continue the account creation process.
            </p>
            <button
              onClick={resendVerificationEmail}
              className="w-48 my-4 mb-5 min-h-12 h-12 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
            >
              Resend Verification Email
            </button>
          </div>
        )}
        <ForwardArrowButton
          pending={false}
          onClick={() => {
            reloadUserAndCheckEmailVerified();
          }}
        />
      </div>
  <ToastAlert/>
    </div>
  );
}

export default EmailVerification;
