import React, { useState, useEffect } from "react";
import { useLookup } from "../../../data/lookup";
import { updateAutoSave } from "../../../data/utility";
import { LookUp } from "../../../dataTypes/LookUp";
import useModal from "../../../hooks/useModal";
import DialogWrapper from "../dialog/Dialog";
import GreenUnderlineHeading from "../headings/heading";

const StateListing = ({
  currentStates,
  collection,
  recId,
  name,
  title,
  disabled = false,
  text,
}: {
  currentStates: Array<string>;
  collection: string;
  recId: string;
  name: string;
  title: string;
  disabled?: boolean;
  text?: string;
}) => {
  const [type, setType] = useState(collection);
  const [options, setOptions] = useState([]);
  const [states, setStates] = useState([]);
  const { isShowing: showModal, toggle: toggleModal } = useModal();

  const { data: stateList } = useLookup("state");

  async function updateStates(state: string) {
    const newStates = [...currentStates];
    newStates.push(state);
    await updateAutoSave(collection, recId, { [name]: newStates });
    return true;
  }

  async function removeState(state: string) {
    const newStates = states.filter((t: string) => t !== state);
    await updateAutoSave(collection, recId, { [name]: newStates });
    return true;
  }

  useEffect(() => {
    if (currentStates !== undefined && currentStates.length > 0) {
      setStates(currentStates);
    }
  }, [currentStates]);

  useEffect(() => {
    let finalOptions = [];
    setOptions([]);
    const newOptions: Array<string> = [];
    if (stateList && stateList.length > 0) {
      stateList.map((state: any, index: number) => {
        newOptions.push(state.label);
      });
      if (states.length > 0) {
        finalOptions = newOptions.filter((value) => !states.includes(value));
      } else {
        finalOptions = newOptions;
      }
      setOptions(finalOptions);
    }
  }, [stateList, states]);

  return (
    <div
      id={name}
      className="w-full mb-4 text-instant-teams-teal-Main font-bold "
    >
      <p className="w-full mb-1">
        <GreenUnderlineHeading
          title={`ASSIGNED ${title.toUpperCase()}`}
          showLockIcon={true}
          isLocked={disabled}
          text={text ?? ""}
        />
        {disabled ? (
          <span className="float-right text-sm text-instant-teams-gray-D1 text-bold cursor-not-allowed">
            + ADD
          </span>
        ) : (
          <span
            className="float-right text-sm text-instant-teams-green-D2 text-bold cursor-pointer hover:text-instant-teams-orange-D1"
            onClick={() => toggleModal()}
          >
            + ADD
          </span>
        )}
      </p>
      <ul className="w-full -mx-1 mb-4">
        {states && states.length > 0 ? (
          states.map((state: string) => (
            <li
              key={state}
              className="inline-block bg-white rounded-lg p-1 text-sm font-semibold m-1 cursor-pointer hover:bg-red-600 hover:text-white transition-colors"
              onClick={() => {
                if (disabled) return;
                removeState(state);
              }}
            >
              {state.toUpperCase()}
            </li>
          ))
        ) : (
          <li
            key="none"
            className="inline-block bg-white rounded-lg p-1 text-sm font-semibold m-1 cursor-pointer hover:bg-red-600 hover:text-white transition-colors"
            onClick={() => {
              if (disabled) return;
              toggleModal();
            }}
          >
            None
          </li>
        )}
      </ul>
      {showModal && (
        <DialogWrapper
          title="Available States"
          onClose={() => toggleModal()}
          size="sm"
        >
          <div className="transition-max-height ease-out duration-300 flex overflow-hidden p-5">
            <ul className="w-full -mx-1">
              {options.map((state: any) => (
                <li
                  key={state}
                  className="inline-block bg-instant-teams-teal-Main rounded-lg text-sm m-1 cursor-pointer hover:bg-green-600 text-white transition-colors p-2 font-bold"
                  onClick={() => {
                    updateStates(state);
                  }}
                >
                  {state.toUpperCase()}
                </li>
              ))}
            </ul>
          </div>
        </DialogWrapper>
      )}
    </div>
  );
};

export default StateListing;
