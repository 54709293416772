import { useAuth } from "../../providers/auth";
function UserStatusPage() {
  const auth = useAuth();
  return (
    <div className="p-36">
      <h1>User Status</h1>
      {`Your user status is: ${auth?.user?.status}`}
      <br />
      {`Your user role is: ${auth?.user?.role}`}
    </div>
  );
}

export default UserStatusPage;
