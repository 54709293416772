import { useState } from "react";
import Meta from "../../components/custom-ui/meta/Meta";
import { requireAuth, useAuth } from "../../providers/auth";
import {
  SearchBox,
  ClearRefinements,
  CurrentRefinements,
  RefinementList,
  RefinementListProps,
  Hits,
  HitsPerPage,
  Pagination,
  Configure,
} from "react-instantsearch-hooks-web";
import UserManagementLineComponent from "../../components/user/listing/user-management-line-component";
import { getLabel } from "../../util/search/getLabel";
import useRoleGuard from "../../hooks/useRoleGuard";

function UserManagementPage() {
  useRoleGuard();

  const [showCommunity, setShowCommunity] = useState(false);
  const transformType: RefinementListProps["transformItems"] = (items) => {
    items = items.map((item: any) => ({
      ...item,
      label: getLabel(item.label, "userTypes"),
    }));
    return items;
  };

  const transformRole: RefinementListProps["transformItems"] = (items) => {
    items = items.map((item: any) => ({
      ...item,
      label: getLabel(item.label, "userRoles"),
    }));
    return items;
  };

  const handleClick = () => {
    setShowCommunity(!showCommunity);
  };

  return (
    <>
      <div className="">
        <Meta title="User Management" />
        <div className="flex pt-36">
          <div className="basis-1/6">
            <div className=" px-6">
              <SearchBox
                placeholder="Keyword Search"
                classNames={{
                  input: "w-full",
                  submitIcon: "hidden",
                  resetIcon: "hidden",
                  submit: "hidden",
                }}
              />
              <div className="flex items-center mb-4">
                <button
                  type="button"
                  className={`cursor-pointer ${
                    showCommunity
                      ? "bg-instant-teams-green-Main"
                      : "bg-gray-300"
                  } relative inline-flex items-center h-5 rounded-full w-10 mr-4`}
                  onClick={handleClick}
                >
                  <span
                    className={`${
                      showCommunity ? "translate-x-5" : "translate-x-1"
                    } inline-block w-4 h-4 transform bg-white rounded-full transition ease-linear`}
                  />
                </button>
                <p>Show Community Members</p>
              </div>
              <CurrentRefinements />
              Filter by Role
              <RefinementList
                attribute="claims.role"
                operator="and"
                transformItems={transformRole}
                classNames={{
                  root: "capitalize",
                  showMore: " text-instant-teams-blue-Main text-xs",
                }}
                limit={5}
                showMore={true}
              />
              Filter by Status
              <RefinementList
                attribute="status"
                operator="and"
                classNames={{
                  root: "capitalize",
                  showMore: " text-instant-teams-blue-Main text-xs",
                }}
                limit={5}
                showMore={true}
              />
              Filter by Type
              <RefinementList
                attribute="claims.type"
                operator="and"
                transformItems={transformType}
                classNames={{
                  root: "capitalize",
                  showMore: " text-instant-teams-blue-Main text-xs",
                }}
                limit={5}
                showMore={true}
              />
              <ClearRefinements
                translations={{ resetButtonText: "Clear Filters" }}
                classNames={{
                  button:
                    " bg-instant-teams-teal-Main text-white p-2 rounded mt-5 text-center w-full",
                }}
              />
            </div>
          </div>
          <div className="basis-5/6 mb-36">
            <div className="flex justify-end w-[90%]">
              <div className="flex items-center">
                <div className=" pr-4">Results per page:</div>
                <HitsPerPage
                  items={[
                    { label: "10", value: 10 },
                    { label: "25", value: 25 },
                    { label: "50", value: 50, default: true },
                    { label: "100", value: 100 },
                  ]}
                  classNames={{ select: "text-xs h-6 py-0" }}
                />
              </div>
            </div>
            <div className="mt-4 w-[90%]">
              <Configure
                filters={!showCommunity ? "NOT claims.role:COMM" : ""}
              />
              <Hits
                hitComponent={(hit: any) => (
                  <UserManagementLineComponent row={hit} />
                )}
                classNames={{
                  root: "ais-InfiniteHits",
                  list: "ais-InfiniteHits-list grid grid-cols-5 gap-4 w-full",
                }}
              />
              <div className="flex justify-center">
                <div className="mt-12">
                  <Pagination
                    padding={1}
                    classNames={{
                      list: "flex",
                      item: "h-8 w-12 text-center rounded-m rounded mx-1 bg-white w-full",
                      selectedItem: "scale-125 border-black",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default requireAuth(UserManagementPage);
