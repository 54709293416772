import Tooltip from "../../custom-ui/tooltip/tooltip";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCabinetFiling,
  faDoorClosed,
} from "@fortawesome/pro-duotone-svg-icons";
import useModal from "../../../hooks/useModal";
import GeneralNote from "../../custom-ui/input-fields/modals/GeneralNote";
import { collectionNames } from "../../../data/dictionary/collectionNames";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

function HeaderLogItems({
  collection,
  recId,
  viewableItems,
}: {
  collection: string;
  recId: string;
  viewableItems: Array<string>;
}) {
  const params = useParams();

  const { users, employers } = collectionNames;
  const [id, setId] = useState(params.userId ?? "");

  const { isShowing: isShowingNote, toggle: toggleNote } = useModal();
  const handleDone = () => {
    toggleNote();
  };

  useEffect(() => {
    setId(params.userId ?? params.employerId);
  }, [params]);
  return (
    <div className="py-2 px-6 flex justify-between">
      <div className="flex space-x-3">
        {viewableItems.includes("access") && (
          <div>
            <Tooltip text="Access logs">
              <NavLink to="access-logs" id={`${collection}-access-logs`}>
                <FontAwesomeIcon icon={faDoorClosed} className="text-xl" />
              </NavLink>
            </Tooltip>
          </div>
        )}
        {viewableItems.includes("audit") && (
          <div>
            <Tooltip text="Audit logs">
              <NavLink to="audit-logs" id={`${collection}-audit-logs`}>
                <FontAwesomeIcon icon={faCabinetFiling} className="text-xl" />
              </NavLink>
            </Tooltip>
          </div>
        )}
      </div>
      {viewableItems.includes("notes") && (
        <div onClick={toggleNote} id={`${collection}-add-note`}>
          <Tooltip text="Add note">
            <i className="fak fa-notes-add-white text-slate-400 text-xl"></i>
          </Tooltip>
        </div>
      )}
      {isShowingNote && (
        <GeneralNote onDone={handleDone} collection={collection} id={recId} />
      )}
    </div>
  );
}
export default HeaderLogItems;
