import { useState } from "react";
import { useForm } from "react-hook-form";
import { createJobPosition } from "../../../data/positions";

function JobPositionForm({
  jobId,
  departmentId,
  employerId,
  onDone = () => {},
}: {
  jobId: string;
  departmentId: string;
  employerId: string;
  onDone?: Function;
}) {
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const { handleSubmit, register, errors, getValues, setValue } = useForm({
    defaultValues: {
      jobId,
      positionName: "",
      departmentId,
      employerId,
    },
  });

  const onSubmit = (data: {
    jobId: string;
    positionName: string;
    employerId: string;
    departmentId: string;
  }) => {
    setPending(true);
    createJobPosition(data)
      .then((res) => {
        onDone(res.id);
      })
      .catch((error) => {
        console.log("There was a problem...: ", error);
      })
      .finally(() => setPending(false));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
          name="employerId"
          type="hidden"
          ref={register()}
        />
        <input
          className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
          name="departmentId"
          type="hidden"
          ref={register()}
        />
        <input
          className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
          name="jobId"
          type="hidden"
          ref={register()}
        />
        <div className="mb-2">
          <input
            className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            name="positionName"
            type="text"
            placeholder="Position Name"
            ref={register({
              required: "Please enter a position name.",
            })}
          />
          {errors.positionName && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.positionName.message}
            </p>
          )}
        </div>
        <button
          className="py-2 px-4 w-full text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
          type="submit"
          disabled={pending}
        >
          {pending ? "..." : "Create Position"}
        </button>
      </form>
    </>
  );
}

export default JobPositionForm;
