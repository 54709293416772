import toast from "react-hot-toast";
import ToastAlert from "../../../custom-ui/toast-alert/ToastAlert";
import { Timestamp } from "firebase/firestore";
import { requireAuth, useAuth } from "../../../../providers/auth";
import { createSyncRequest } from "../../../../data/academy";
import {
  SearchBox,
  Configure,
  RefinementList,
  CurrentRefinements,
  ClearRefinements,
  Hits,
  HitsPerPage,
  Pagination,
  RefinementListProps,
} from "react-instantsearch-hooks-web";
import Meta from "../../../custom-ui/meta/Meta";
import { getLabel } from "../../../../util/search/getLabel";
import useModal from '../../../../hooks/useModal';
import DialogWrapper from '../../../custom-ui/dialog/Dialog';
import AddAcceleratorModal from '../../parts/Accelerator/addAcceletator';
import AcceleratorItem from '../../parts/Accelerator/acceleratorItem';

const AcceleratorListing = () => {
  const { user } = useAuth();
  const { isShowing: isShowingAdd, toggle: toggleAdd } = useModal();
  const transformSkillLevel: RefinementListProps["transformItems"] = (
    items,
  ) => {
    items = items.map((item) => ({
      ...item,
      label: getLabel(item.label, "skillLevel"),
    }));
    return items;
  };

  return (
    <div className="min-h-screen grid grid-cols-12 gap-2 w-full">
      <Meta title="Learning & Development - Accelerators" />
      <div className="w-full pt-6 inline-flex min-h-screen col-span-10">
        <div className="pb-4 mt-8  pr-4 w-1/5">
          <SearchBox
            placeholder="Keyword Search"
            classNames={{
              input: "w-full",
              submitIcon: "hidden",
              resetIcon: "hidden",
              submit: "hidden",
            }}
          />
          <CurrentRefinements />
          {/* Filter by Level
          <RefinementList
            attribute="level"
            operator="and"
            classNames={{ root: "capitalize" }}
            transformItems={transformSkillLevel}
          />
          Filter by Skill Family
          <RefinementList
            attribute="skillFamily"
            operator="and"
            classNames={{ root: "capitalize" }}
          /> */}
          <ClearRefinements
            translations={{ resetButtonText: "Clear Filters" }}
            classNames={{
              button:
                " bg-instant-teams-teal-Main text-white p-2 rounded mt-5 text-center w-full",
            }}
          />
          <ToastAlert />
        </div>
        <div className="pl-4 w-4/5 pb-36">
          <div className="flex justify-between w-[100%] mb-2">
            <div></div>
            <div className="flex items-center">
              <div className=" pr-4">Results per page:</div>
              <HitsPerPage
                items={[
                  { label: "10", value: 10 },
                  { label: "25", value: 25 },
                  { label: "50", value: 50, default: true },
                  { label: "100", value: 100 },
                ]}
                classNames={{ select: "text-xs h-6 py-0" }}
              />
            </div>
          </div>
          <Configure />
          <Hits
            hitComponent={(hit: any) => <AcceleratorItem hit={hit} />}
            classNames={{
              root: "ais-InfiniteHits",
              item: "mb-2",
            }}
          />
          <div className=" w-full flex justify-center">
            <div className="mt-12">
              <Pagination
                showNext={true}
                padding={1}
                classNames={{
                  list: "flex",
                  item: "h-8 w-8 text-center rounded-m rounded mx-1 bg-white",
                  selectedItem: "scale-125 border-black",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-2">
        <button 
          className="bg-instant-teams-teal-Main rounded-md shadow-md text-white px-1.5 py-1.5 w-full" 
          onClick={toggleAdd}
        >
          Add Accelerator
        </button>
      </div>
      {
        isShowingAdd &&
        <DialogWrapper
        title="Add Accelerators"
        onClose={toggleAdd}
        >
          <AddAcceleratorModal toggle={toggleAdd}/>
        </DialogWrapper>
      }
    </div>
  );
};

export default AcceleratorListing;
