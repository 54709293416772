import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  getFirestore,
  connectFirestoreEmulator,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  limit,
  writeBatch,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { createQuery, format } from "../providers/database";
import { firebaseApp } from "../providers/firebase";
import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";
import { errorLogger } from "../providers/clientLogger";
import { User } from "../dataTypes/User";

export const db = getFirestore(firebaseApp);

export function useAffiliations(id: string) {
  return useQuery(
    ["allAffiliations", { id }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.users}/${id}/${subCollectionNames.affiliations}`,
        ),
      );
    }),
    { enabled: !!id },
  );
}

export function addUserAffiliation(
  userId: string,
  branch: string,
  affiliation: string,
) {
  return addDoc(
    collection(
      db,
      `${collectionNames.users}/${userId}/${subCollectionNames.affiliations}`,
    ),
    { branch: branch, affiliation: affiliation },
  );
}
export async function addOrUpdateUserAffiliation(
  userId: string,
  branch: string,
  affiliation: string,
) {
  const affiliationRef = collection(
    db,
    `${collectionNames.users}/${userId}/${subCollectionNames.affiliations}`
  );
console.log("affiliation for trirgged")
  try {
    // Fetch the existing affiliation records
    const affiliationSnapshot = await getDocs(affiliationRef);
    const affiliationDocs = affiliationSnapshot.docs;

    if (affiliationDocs.length > 0) {
      // If there is already a 0th index record, update it
      const firstAffiliationDoc = affiliationDocs[0]; // 0th index
      const firstAffiliationDocRef = doc(
        db,
        `${collectionNames.users}/${userId}/${subCollectionNames.affiliations}`,
        firstAffiliationDoc.id
      );

      console.log("updated")

      // Update the 0th index document with new data
      return updateDoc(firstAffiliationDocRef, {
        branch: branch,
        affiliation: affiliation
      });
    } else {
      // If no documents exist, add new data at 0th index (first record)
      console.log("added")
      return addDoc(
        affiliationRef,
        { branch: branch, affiliation: affiliation }
      );
    }
  } catch (error) {
    console.error("Error adding or updating affiliation: ", error);
    throw error;
  }
}


export async function addUserAffiliationNew(
  userId: string,
  branch: any,
  affiliation: any,
  email: string, 
  name: string,
  phone: any,
  preferredName: string,
  stateResidence: string
) {
  const batch = writeBatch(db);
  const userDocRef = doc(db, collectionNames.users, userId);

  // Update user document with main user details
  batch.update(userDocRef, {
    name: name,
    email: email,
    phone: phone,
    preferredName: preferredName,
    address: { state: stateResidence }
  });

  // Only update affiliations sub-collection if branch is not "No"
  if (branch !== "No") {
    const affiliationCollectionRef = collection(
      db,
      `${collectionNames.users}/${userId}/${subCollectionNames.affiliations}`
    );
    const newAffiliationDocRef = doc(affiliationCollectionRef);
    batch.set(newAffiliationDocRef, { branch: branch, affiliation: affiliation });
  }

  // Commit the batch
  await batch.commit();
}


export function deleteUserAffiliation(userId: string, affiliationId: string) {
  return deleteDoc(
    doc(
      db,
      `${collectionNames.users}/${userId}/${subCollectionNames.affiliations}`,
      affiliationId,
    ),
  );
}

export async function deleteAffiliations(userId: string) {
  const affiliationsRef = collection(
    db,
    `${collectionNames.users}/${userId}/${subCollectionNames.affiliations}`
  );
  try {
    const affiliationSnapshot = await getDocs(affiliationsRef);
    const batchDeletePromises = affiliationSnapshot.docs.map((affiliationDoc) => 
      deleteDoc(doc(
        db,
        `${collectionNames.users}/${userId}/${subCollectionNames.affiliations}`,
        affiliationDoc.id
      ))
    );
    await Promise.all(batchDeletePromises);
    console.log('All affiliations have been successfully deleted.');
  } catch (error) {
    console.error('Error deleting affiliations: ', error);
    throw error;
  }
}
