import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";
import { useComponent } from "../../data/academy";
import DialogWrapper from "../../components/custom-ui/dialog/Dialog";
import SeekerAssessmentDetails from "../../components/seekerView/AssessmentDetails";
import useModal from "../../hooks/useModal";
import { getLevel } from '../../util/assessments/level';

function JobSkillListing({ skill }: { skill: any }) {
  const { data: assessment } = useComponent(skill?.assessmentId);
  const { isShowing, toggle } = useModal();
  
  return (
    <div
      className={TailwindHoverEnlarge(105) + "items-center cursor-pointer"}
      onClick={toggle}
    >
      <img
        src={skill?.badge}
        alt={skill?.skillName}
        className="object-contain h-32 w-32 md:h-16 md:w-16 mx-auto"
      />
      <div className="w-full text-center text-xs">{skill?.skillName}</div>
      <div className="text-center text-xs">{getLevel(skill?.tier)}</div>
      {isShowing ? (
        <DialogWrapper
          title="Assessment Details"
          size="mt-36 w-1/2"
          onClose={toggle}
        >
          <SeekerAssessmentDetails assessment={assessment} enrollment={skill} />
        </DialogWrapper>
      ) : null}
    </div>
  );
}

export default JobSkillListing;
