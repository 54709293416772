import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import ForwardArrowButton from "../button/ForwardArrowButton";

function SuccessCircleCheck({
  message,
  action,
  children,
}: {
  message: string;
  action?: () => void;
  children?: React.ReactNode;
}) {
  const bounceAnimationStyle = {
    "--fa-animation-iteration-count": 1,
    "--fa-bounce-start-scale-x": 1,
    "--fa-bounce-start-scale-y": 1,
    "--fa-bounce-jump-scale-x": 1,
    "--fa-bounce-jump-scale-y": 1,
    "--fa-bounce-land-scale-x": 1,
    "--fa-bounce-land-scale-y": 1,
    "--fa-bounce-rebound": 0,
  };

  return (
    <div className=" flex flex-col justify-center">
      <FontAwesomeIcon
        className="mb-4 text-instant-teams-green-D2 fa-bounce"
        style={bounceAnimationStyle}
        icon={faCircleCheck}
        size="4x"
      />
      <p className="text-instant-teams-gray-D1 mb-10 text-center">{message}</p>
      {action && <ForwardArrowButton onClick={action} />}
      {children}
    </div>
  );
}

export default SuccessCircleCheck;
