import GreenUnderlineHeading from "../../components/custom-ui/headings/heading";
import DashboardUserListing from "../../components/user/listing/dashboard-listing";
import { useUsersByStatus } from "../../data/user";
import { User } from "../../dataTypes/User";

function LimitedUsers() {
  const { data: limitedUsers, status: limitedUsersStatus } =
    useUsersByStatus("limited");

  return (
    <>
      <GreenUnderlineHeading title="Limited Users" />
      <div className="flex flex-wrap">
        {limitedUsersStatus === "success" && limitedUsers.length > 0 ? (
          <>
            {limitedUsers?.map((limitedUser: User) => {
              return (
                <DashboardUserListing
                  user={limitedUser}
                  key={limitedUser?.id}
                />
              );
            })}
          </>
        ) : (
          <p>No limited users right now.</p>
        )}
      </div>
    </>
  );
}

export default LimitedUsers;
