import { useParams } from "react-router-dom";
import QuestionListJob from '../../interviews/Jobs/QuestionListJob';
function JobInterviewQuestions() {
  const params = useParams();
  const jobId = params.id ?? "";
  return (
    <>
      <QuestionListJob />
    </>
  );
}

export default JobInterviewQuestions;
