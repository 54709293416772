import React from 'react';
import { requireAuth } from '../../../../../../providers/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const CompaniesList = ({ empData, setEmpDetailData,setSelectedCompanyId,selectedCompanyId}) => {

    const handleClick = () => {
        setEmpDetailData(empData);
        setSelectedCompanyId(empData.id); // Update the selected job ID
    };

    const isSelected = selectedCompanyId === empData.id;
  
    const address = empData?.address?.standardizedAddress.split(",")
    return (
        <div onClick={handleClick} className={`relative max-w-xl mx-auto mb-3 bg-white  rounded-2xl    p-4 py-5 flex flex-col md:flex-row items-start gap-4 ${isSelected?"border-2 border-instant-teams-blue-Main ":""}`}>
            <div className="flex w-full">
                <div className="flex-shrink-0">

                    <img
                        src={empData?.logo}
                        alt="Company Logo"
                        className="w-16 h-16 rounded object-contain"
                    />
                </div>
                <div className="ml-2 mt-2 flex-grow">
                    <div className="flex justify-between items-center">
                        <div className='cursor-pointer'  >
                            <h2 className="text-lg font-semibold text-[#15415E]">
                                {empData?.displayName}</h2>
                            {empData?.address?.standardizedAddress && <div className="text-gray-500  text-sm font-thin flex items-center">
                                <div className='border-b-2 text-gray-400 border-[#15415E] '>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} className=" text-[#15415E]" />
                                </div>
                                <p className="text-[#15415E]  ml-1 text-sm font-thin flex items-center">
                                    <span>{address[1]},{address[3]}</span>
                                </p>


                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default requireAuth(CompaniesList);
