import { updateAutoSave } from "../../../data/utility";
import { useParams, useNavigate } from "react-router-dom";

function RescindOfferModal({
  collection,
  toggleRescindOffer,
  passedOfferId,
}: {
  collection: string;
  toggleRescindOffer: () => void;
  passedOfferId?: string;
}) {
  const navigate = useNavigate();
  const params = useParams();
  const { offerId = "" } = params ?? {};
  console.log("paramssss", offerId);
  const handleRescindOffer: any = async () => {
    let activeOffer = offerId !== "" ? offerId : passedOfferId;
    await updateAutoSave(collection, activeOffer as string, {
      status: "declined",
    });
    toggleRescindOffer();
    navigate("/jobs");
  };
  return (
    <>
      <p className="py-6">Are you certain you want to rescind this offer?</p>
      <div className="flex justify-center space-x-4">
        <button
          onClick={handleRescindOffer}
          className="py-2 px-10 text-white bg-instant-teams-blue-Main rounded border-0 hover:border  hover:bg-instant-teams-teal-Main  focus:outline-none"
        >
          {" "}
          Yes
        </button>
        <button
          onClick={toggleRescindOffer}
          className="py-2 px-10  rounded hover:text-white  hover:border  hover:bg-instant-teams-teal-Main border border-instant-teams-blue-Main bg-white text-instant-teams-blue-Main focus:outline-none"
        >
          No
        </button>
      </div>
    </>
  );
}
export default RescindOfferModal;
