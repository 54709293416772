import { useQuery } from "react-query";
import {
  doc,
  collection,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  updateDoc,
  addDoc,
  runTransaction,
  deleteDoc,
} from "firebase/firestore";
import { db, createQuery } from "../providers/database";

import { Contact, Employer } from "../dataTypes/Employer";
import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";
import { Department, Locations } from "../dataTypes/Company";
import { deptDashboard } from "../callable-cloud-functions/cloudFunctions";
import { Timestamp } from 'firebase/firestore';

const getEmployerDocRef = (employerId: string) =>
  doc(db, collectionNames.employers, employerId);

/**** EMPLOYERS ****/
// Subscribe to employer data
export function useEmployer(employerId: string) {
  return useQuery(
    ["employer", { employerId }],
    createQuery(() => doc(db, collectionNames.employers, employerId)),
    { enabled: !!employerId },
  );
}

// Temporary List of all Employers
export function useAllEmployers(trigger: object) {
  return useQuery(
    ["employers"],
    createQuery(() =>
      query(collection(db, collectionNames.employers), orderBy("displayName")),
    ),
    { enabled: !!trigger },
  );
}

export function useEmployeesByEmployer(employerId: string) {
  return useQuery(
    ["employeesByEmployerId", { employerId }],
    createQuery(() =>
      query(
        collection(db, `${collectionNames.employees}`),
        where("employerId", "==", employerId),
      ),
    ),
    { enabled: !!employerId },
  );
}

export function useAllActiveEmployers(trigger: object) {
  return useQuery(
    ["employers"],
    createQuery(() =>
      query(
        collection(db, collectionNames.employers),
        where("status", "==", "active"),
        orderBy("displayName"),
      ),
    ),
    { enabled: !!trigger },
  );
}
export function useAllActiveEmployersNewUi(trigger: object) {
  return useQuery(
    ["employers"],
    createQuery(() =>
      query(
        collection(db, collectionNames.employers),
        where("status", "==", "active"),
        orderBy("displayName"),
      ),
    ),
    { enabled: !trigger },
  );
}

export function useAllParticipatingEmployers(trigger: boolean) {
  return useQuery(
    ["employers"],
    createQuery(() =>
      query(
        collection(db, collectionNames.employers),
        where("status", "!=", "lead"),
        where("status", "!=", "invited"),
        where("status", "!=", "under_review"),
        where("status", "!=", "dormant"),
        where("status", "!=", "removed"),
        where("status", "!=", "closed"),
        orderBy("displayName"),
      ),
    ),
    { enabled: !!trigger },
  );
}

export async function getAllActiveEmployers() {
  const employerDocs: Array<object> = [];
  const q = query(
    collection(db, collectionNames.employers),
    where("status", "==", "active"),
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    employerDocs.push(doc.data());
  });
  return employerDocs;
}

// Create a new employer
export function createEmployer(
  employer: { displayName: string; domain: string },
  createdBy: string,
) {
  // add employer status "lead"
  return addDoc(collection(db, collectionNames.employers), {
    ...employer,
    status: "lead",
    createdBy,
  });
}

// Disable Company
export async function disableCompanyEmployees(employerId: string) {
  const DbRef = doc(db, "Company", employerId);
  await runTransaction(db, async (transaction) => {
    const employee = query(
      collection(db, "Users"),
      where("employerID", "==", DbRef),
    );
    const querySnapshot = await getDocs(employee);
    querySnapshot.forEach((document) => {
      // disableUser(document.id);
      const userRef = doc(db, "Users", document.id);
      transaction.update(userRef, { active: false });
    });
  });
}

export async function disableCompanyPipelines(employerId: string, status: any) {
  const DbRef = doc(db, "Company", employerId);
  await runTransaction(db, async (transaction) => {
    const q = query(
      collection(db, "JobDescriptions"),
      where("Company", "==", DbRef),
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((document) => {
      const jobRef = doc(db, "JobDescriptionStatus", document.id);
      transaction.update(jobRef, { status });
    });
  });
}

export async function disableCompany(employerID: string, status: any) {
  updateDoc(doc(db, "Company/CompanyStatus", employerID), status);
}

export function useEmployerContact(employerId: string, contactType: string) {
  return useQuery(
    ["Contact", { contactType }],
    createQuery(() =>
      doc(
        db,
        `${collectionNames.employers}/${employerId}/Contacts`,
        contactType,
      ),
    ),
    { enabled: !!employerId },
  );
}

export function useGetContact(userId: string) {
  return useQuery(
    ["Users", { userId }],
    createQuery(() => doc(db, "Users", userId)),
    { enabled: !!userId },
  );
}

export function updateEmployerLogo(employer: Employer) {
  const dbRef = doc(db, collectionNames.employers, employer.id);
  return updateDoc(dbRef, { logo: employer.logo });
}
// Update employer
export function updateEmployer(id: string, employer: Employer) {
  return updateDoc(doc(db, collectionNames.employers, id), employer);
}

// Create employer contact
export function addEmployerContacts(employerId: string, contact: Contact) {
  const employerDocRef = getEmployerDocRef(employerId);
  return runTransaction(db, async (transaction) => {
    const employerDoc = await transaction.get(employerDocRef);
    if (!employerDoc.exists()) {
      throw Error(`Employer - ${employerId} does not exist.`);
    }

    // if a contact already exists
    const existingContact = employerDoc.data().contact;
    transaction.update(employerDocRef, {
      contact: { ...existingContact, ...contact },
    });
  });
}

export function addEmployerDepartment(
  employerId: string,
  department: Department,
) {
  return addDoc(
    collection(
      db,
      `${collectionNames.employers}/${employerId}/${subCollectionNames.departments}`,
    ),
    { ...department },
  );
}

export function addEmployerLocation(employerId: string, location: Locations) {
  return addDoc(
    collection(
      db,
      `${collectionNames.employers}/${employerId}/${subCollectionNames.locations}`,
    ),
    { ...location },
  );
}

export function useEmployerStatus(id: string) {
  return useQuery(
    ["EmployerStatus", { id }],
    createQuery(() => doc(db, "EmployerStatus", id)),
    { enabled: !!id },
  );
}

export function useEmployerUsers(id: string) {
  return useQuery(
    ["primaryUser", { id }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.users),
        where("claims.employerId", "==", id),
      );
    }),
    { enabled: !!id },
  );
}

export function useEmployerPrimary(id: string) {
  return useQuery(
    ["allEmployerUsers", { id }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.users),
        where("claims.employerId", "==", id),
        where("claims.role", "==", "PRI"),
      );
    }),
    { enabled: !!id },
  );
}

export function useEmployerBilling(id: string) {
  return useQuery(
    ["allEmployerBilling", { id }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.users),
        where("claims.employerId", "==", id),
        where("claims.role", "==", "BILL"),
      );
    }),
    { enabled: !!id },
  );
}

export function useEmployerInterviewQuestions(id: string) {
  return useQuery(
    ["allEmployerInterviewQuestions", { id }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.employers}/${id}/${subCollectionNames.interviewQuestions}`,
        ),
        orderBy("sortOrder"),
      );
    }),
    { enabled: !!id },
  );
}

export function useEmployerInterviewQuestion(id: string, questionId: string) {
  return useQuery(
    ["employerQuestion", { id, questionId }],
    createQuery(() =>
      doc(
        db,
        `${collectionNames.employers}/${id}/${subCollectionNames.interviewQuestions}/${questionId}`,
      ),
    ),
    { enabled: !!id && !!questionId },
  );
}

export function addEmployerInterviewQuestion(
  employerId: string,
  question: any,
) {
  delete question.id;
  return addDoc(
    collection(
      db,
      `${collectionNames.employers}/${employerId}/${subCollectionNames.interviewQuestions}`,
    ),
    { ...question },
  );
}

export function removeEmployerInterviewQuestion(
  employerId: string,
  questionId: string,
) {
  const questionRef = doc(
    db,
    `${collectionNames.employers}/${employerId}/${subCollectionNames.interviewQuestions}/${questionId}`,
  );
  return deleteDoc(questionRef);
}

export function useEmployerDepartment(id: string, departmentId: string) {
  return useQuery(
    ["employerDepartment", { id, departmentId }],
    createQuery(() =>
      doc(
        db,
        `${collectionNames.employers}/${id}/${subCollectionNames.departments}/${departmentId}`,
      ),
    ),
    { enabled: !!id && !!departmentId },
  );
}

export function useEmployerDepartments(id: string) {
  return useQuery(
    ["employerDepartments", { id }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.employers}/${id}/${subCollectionNames.departments}`,
        ),
        orderBy("label"),
      );
    }),
    { enabled: !!id },
  );
}

export function useEmployerDeptDashboard(employerId: string) {
  return useQuery(
    ["departmentDashboard", { employerId }],
    async () => {
      const result = await deptDashboard({ employerId });
      return result.data;
    },
    { enabled: !!employerId },
  );
}

export function useAllEmployerLocations(id: string) {
  return useQuery(
    ["allEmployerLocations", { id }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.employers}/${id}/${subCollectionNames.locations}`,
        ),
        orderBy("label"),
      );
    }),
    { enabled: !!id },
  );
}

export function useEmployerLocation(employerId: string, locationId: string) {
  return useQuery(
    ["employerLocation", { employerId, locationId }],
    createQuery(() =>
      doc(
        db,
        `${collectionNames.employers}/${employerId}/${subCollectionNames.locations}/${locationId}`,
      ),
    ),
    { enabled: !!employerId && !!locationId },
  );
}

export function useEmployerJobs(id: string) {
  return useQuery(
    ["allEmployerJobs", { id }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.jobDescriptions),
        where("employer", "==", id),
        orderBy("title"),
      );
    }),
    { enabled: !!id },
  );
}

export function useEmployerJobsByStatus(id: string, status: string) {
  return useQuery(
    ["employerJobsByStatus", { id }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.jobDescriptions),
        where("employer", "==", id),
        where("status", "==", status),
        orderBy("title"),
      );
    }),
    { enabled: !!id },
  );
}

export function useEmployerSearch(key: string, value: string) {
  return useQuery(
    ["allEmployerSearch", { value }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.employers),
        where(key, "==", value),
      );
    }),
    { enabled: !!value && !!key },
  );
}

export async function getEmployerAssignees(id: string) {
  try {
    const employer = await getDoc(doc(db, collectionNames.employers, id));
    const emp = employer.data();
    let AM = "Not Assigned";
    let TAC = "Not Assigned";
    if (emp?.AM !== undefined && emp?.TAC !== undefined) {
      await getDoc(doc(db, collectionNames.users, emp.AM)).then((x) => {
        if (x.data()?.preferredName !== undefined) {
          AM = x.data()?.preferredName;
        }
      });
    }
    if (emp?.TAC !== undefined && emp?.TAC !== null) {
      await getDoc(doc(db, collectionNames.users, emp.TAC)).then((x) => {
        if (x.data()?.preferredName !== undefined && x.data()?.claims.role === "tac" && x.data()?.claims.active) {
          TAC = x.data()?.preferredName;
        }
      });
    }
    const creator = await getDoc(
      doc(db, collectionNames.users, employer?.data()?.createdBy),
    );
    const assignees: { am: string; tac: string; creator: string } = {
      am: AM,
      tac: TAC,
      creator: creator?.data()?.preferredName || "",
    };
    return assignees;
  } catch (error) {
    console.log("Assignees Error: ", error);
  }
}

export function useAllEmployerActiveOffers(id: string) {
  return useQuery(
    ["employerOffers", { id }],
    createQuery(() =>
      query(
        collection(db, collectionNames.offers),
        where("employerId", "==", id),
        orderBy("createdAt", "desc"),
      ),
    ),
    { enabled: !!id },
  );
}

export function useAllEmployerTrainings(id: string) {
  return useQuery(
    ["employerTrainings", { id }],
    createQuery(() =>
      query(
        collection(db, collectionNames.employers, id, subCollectionNames.training),
        orderBy("startDate", "asc"),
      ),
    ),
    { enabled: !!id },
  );
}

export function useEmployerTraining(employerId: string, trainingId: string) {
  return useQuery(
    ["employerTraining", { employerId, trainingId }],
    createQuery(() =>
      doc(
        db,
        `${collectionNames.employers}/${employerId}/${subCollectionNames.training}/${trainingId}`,
      ),
    ),
    { enabled: !!employerId && !!trainingId },
  );
}

export function useAllActiveEmployerTrainings(id: string) {
  const date = Timestamp.now();
  return useQuery(
    ["activeEmployerTrainings", { id }],
    createQuery(() =>
      query(
        collection(db, collectionNames.employers, id, subCollectionNames.training),
        where("status", "==", "active"),
        where("startDate", ">", date),
        orderBy("startDate", "asc"),
      ),
    ),
    { enabled: !!id },
  );
}


export function createTraining(
  employerId: string,
  record: object,
) {
  // add employer status "lead"
  return addDoc(collection(db, collectionNames.employers, employerId, subCollectionNames.training), {
    ...record,
    status: "active"
  });
}