import Chip from '../custom-ui/chips/Chip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function PreScreenQA({
  data
}: {
  data: any
}) {
  return (
    <div className="my-4 relative w-full flex mt-8 border-b border-instant-teams-green-D2">
      <div className="w-[5%] min-h-full my-auto">
        {data.answer === data.correct ? (
          <FontAwesomeIcon
            className="mr-2 align-middle text-instant-teams-green-D2"
            // @ts-ignore
            icon={["fas", "circle-check"]}
            size="xl"
          />
        ) : (
          <FontAwesomeIcon
            className="mr-2 align-middle text-instant-teams-orange-D2"
            // @ts-ignore
            icon={["fas", "circle-x"]}
            size="xl"
          />
        )}
      </div>
      <div className='w-[70%] text-instant-teams-teal-Main font-bold text-lg'>
        <div>{data.question}</div>
        <div className='grid grid-cols-4 gap-4 mt-4'>
          {data?.data && data.data.length > 0 && data.data.map((item: any, index: any) => (
            <Chip key={index} data={item} />
          ))}        
        </div>
        <div className='mt-4 mb-4'>
          <label className='m-2'>
            <input
              type="radio"
              value="true"
              checked={data.answer === true}
              className="mr-2 text-instant-teams-teal-Main"
              readOnly={true}
            />
            Yes
          </label>
          <label className='m-2'>
            <input
              type="radio"
              value="false"
              checked={data.answer === false || data.answer === null}
              className="mr-2 text-instant-teams-teal-Main"
              readOnly={true}
            />
            No
          </label>
        </div>
      </div>
    </div>
  );
}

export default PreScreenQA;
