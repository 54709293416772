import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { getNewRegistrationMetrics } from "../callable-cloud-functions/cloudFunctions";
import { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";

function AcademyRegistrationTrends({}) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  );

  const [chartData, setChartData] = useState([]);
  const [chartKeys, setChartKeys] = useState([]);
  const [daysSelected, setDaysSelected] = useState(10);
  const [chartTitle, setChartTitle] = useState("Last 10 Days");
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(true);
  useEffect(() => {
    async function fetchData() {
      const { data } = await getNewRegistrationMetrics();
      console.log("AcademyRegistrationTrends.js, useEffect, data: ", data);
      const chartData = [];
      const chartKeys = [];
      data.forEach((item) => {
        const date = Object.keys(item)[0];
        const val = item[date];
        chartKeys.push(date);
        chartData.push(val);
      });
      setChartKeys(chartKeys);
      setChartData(chartData);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setChartTitle("Last " + daysSelected + " Days");
  }, [daysSelected]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "New Registrations",
      },
    },
    animation: {
      duration: 1000,
      onProgress: function (animation) {
        const progress = animation.currentStep / animation.numSteps;
        const rounded = Math.round((progress + Number.EPSILON) * 100) / 100;
        const converted = rounded * 100;
        setProgress(converted);
      },
      onComplete: function (animation) {
        setTimeout(() => {
          setProgress(0);
          setShowProgress(false);
        }, 2000);
      },
    },
  };

  const labels = chartKeys;

  const data = {
    labels,
    datasets: [
      {
        label: "New registrations",
        data: chartData,
        borderColor: "#2a6a99",
        backgroundColor: "#2a6a99",
      },
    ],
  };

  return (
    <>
      {showProgress ? (
        <div style={{ width: 200, height: 400, margin: "auto" }}>
          <span>New Registrations</span>
          <CircularProgressbar
            value={progress}
            text={`${progress}%`}
            maxValue={50}
          />
        </div>
      ) : (
        <div style={{ display: "none" }} />
      )}
      <div style={{ width: "100%", height: "100%" }}>
        <Line
          style={showProgress ? { display: "none" } : { display: "block" }}
          options={options}
          data={data}
        />
      </div>
    </>
  );
}

export default AcademyRegistrationTrends;
