import React from "react";

import CSVDownload from "../../../csvDownload/CSVDownload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBroomWide } from "@fortawesome/pro-solid-svg-icons";
import { faArrowAltCircleDown } from "@fortawesome/pro-duotone-svg-icons";
import { faBroom } from "@fortawesome/pro-regular-svg-icons";
import Tooltip from "../../../custom-ui/tooltip/tooltip";

const OffersDashGreenUnderlineHeading = (props: any) => {
  return props.title === "SIGNED OFFERS" ? (
    <>
      <div className="mt-6 mb-6 border-b-2 border-instant-teams-green-D2">
        <span className="uppercase text-xl font-bold text-instant-teams-teal-D1">
          {props.title}
        </span>
        <span className="float-right">
          {props.children && React.cloneElement(props.children)}
        </span>

        <span className="float-right mt-1 -mr-5">
          <CSVDownload
            data={props.data}
            size={"fa-xl"}
            hasDataAggregation={props.hasDataAggregation}
            dataAggregator={props.dataAggregator}
            fileName={"signedOffers.csv"}
          />
        </span>
        <span className="float-right  my-3.5">
          {props.selectedOffers.length > 0 ? (
            <div className="-mr-4">
              <Tooltip text={"Clear Selection"}>
                <i
                  className="fa-regular fa-square-minus fa-xl fa-flip-horizontal text-instant-teams-blue-Main cursor-pointer"
                  onClick={() => {
                    props.clearSelectedOffers();
                  }}
                ></i>
              </Tooltip>
            </div>
          ) : (
            <div className=" mr-5">
              <Tooltip text={"Select All"}>
                <i
                  onClick={() => {
                    props.selectAllOffers();
                  }}
                  className="fa-regular fa-square-check fa-xl cursor-pointer  text-instant-teams-blue-Main"
                ></i>
              </Tooltip>
            </div>
          )}
        </span>
      </div>
    </>
  ) : (
    <>
      <div className="mt-6 mb-6 border-b-2 border-instant-teams-green-D2">
        <span className="uppercase text-xl font-bold text-instant-teams-teal-D1">
          {props.title}
        </span>
        <span className="float-right">
          {props.children && React.cloneElement(props.children)}
        </span>
      </div>
    </>
  );
};

export default OffersDashGreenUnderlineHeading;
