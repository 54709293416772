import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useEmployerJobs } from "../../../data/employer";
import { useCurrentUserEmployerId } from "../../../hooks/useCurrentUserEmployerId";
import Meta from "../../custom-ui/meta/Meta";
import useModal from "../../../hooks/useModal";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import CreateJobBasicForm from "../../jobs/parts/CreateJobBasicForm";
import ExternalEmployerJobListingLineComponent from "./ExternalJobListingLineItem";
import toast from "react-hot-toast";
import ToastAlert from "../../custom-ui/toast-alert/ToastAlert";

const ExternalJobs = () => {
  const employerId = useCurrentUserEmployerId();
  const [id, setId] = useState("");
  const [formAlert, setFormAlert] = useState(null);
  const {
    data: jobs = [],
    status: jobsStatus,
    error: jobsError,
  } = useEmployerJobs(id);

  const { isShowing, toggle } = useModal();

  const handleFormAlert = (alert: string) => {
    // alert {type:string, message:string}
    setFormAlert(alert);
    if (alert === "error") {
      toast.error("There was an error creating your job");
    } else if (alert === "success") {
      toast.success("New job successfully created");
    }
  };

  useEffect(() => {
    setId(employerId);
  }, [employerId]);

  return (
    <div className="pt-36 px-16 min-h-screen">
      {formAlert && (
        <div
          className={
            "mb-4 mx-auto w-5/6 text-center" +
            (formAlert.type === "error" ? " text-red-600" : "") +
            (formAlert.type === "success" ? " text-green-600" : "")
          }
        >
          {formAlert.message}
        </div>
      )}
      <Meta title="Jobs" />
      <div className="py-6 ">
        <button
          onClick={toggle}
          className=" border-instant-teams-blue-Main text-instant-teams-blue-Main uppercase font-semibold border-2 py-2 px-12 cursor-pointer rounded-md"
        >
          <FontAwesomeIcon className="pr-1" icon={faPlus} size="xs" />
          New Job
        </button>
      </div>
      <div className="grid grid-cols-4 gap-4 w-full">
        {id && Object.keys(jobs).length > 0 ? (
          Object.keys(jobs).map((job: any) => (
            <ExternalEmployerJobListingLineComponent
              key={jobs[job]?.id}
              job={jobs[job]}
            />
          ))
        ) : (
          <div>No listings to display</div>
        )}
      </div>
      {isShowing && (
        <DialogWrapper title="Create Job" onClose={toggle}>
          <CreateJobBasicForm
            handleFormAlert={handleFormAlert}
            toggle={toggle}
            employerId={employerId}
          />
        </DialogWrapper>
      )}
      <ToastAlert />
    </div>
  );
};

export default ExternalJobs;
