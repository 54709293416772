import Tooltip from "../../custom-ui/tooltip/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDoorClosed,
  faCabinetFiling,
} from "@fortawesome/pro-duotone-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useComponent } from "../../../data/academy";
import AutoSaveStatusSelector from "../../custom-ui/input-fields/statusSelector";
import { getLevel } from '../../../util/assessments/level';

function AcademySidebarHeader({}) {
  const navigate = useNavigate();
  const params = useParams();
  const [componentId, setComponentId] = useState(params.assessmentId || "");
  const { data: assessment } = useComponent(componentId);

  return (
    <div className="mb-4">
      <div className="flex items-center">
        <div className="flex object-contain mx-auto">
          <img
            className="h-32 w-32 mb-6 rounded-full object-contain"
            src={assessment?.badge || "/images/badge.png"}
            alt="logo"
          />
        </div>
      </div>
      <div className="text-center text-lg text-white pb-1 -mt-6">
        {assessment?.displayName || assessment?.name}
      </div>
      <div className="pt-2 pl-2 pr-2">
        <AutoSaveStatusSelector
          status={assessment?.visable}
          collection="AcademyAssessments"
          recId={componentId}
          required={false}
          name="visable"
          display="Status"
          placeholder=""
          directions=""
        />
      </div>
    </div>
  );
}

export default AcademySidebarHeader;
