import { useEffect, useState } from "react";
import { useUser } from "../../data/user";
import validator from "validator";
import GreenUnderlineHeading from "../custom-ui/headings/heading";
import SectionHeading from "../custom-ui/headings/SectionHeading";

const AddALink = ({
  dataReturn,
  toggle,
}: {
  dataReturn: Function;
  toggle: Function;
}) => {
  const [error, setError] = useState(true);
  const [href, setHref] = useState("");
  const [title, setTitle] = useState("");

  const validate = (value: string) => {
    if (validator.isURL(value)) {
      if (/(http(s?)):\/\//i.test(value)) {
        setError(false);
        setHref(value);
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
  };

  return (
    <div className="w-full">
      <div className="w-full">
        <SectionHeading title={"Link URL"} />
        <div className="relative w-full">
          <input
            type="text"
            className="min-w-full"
            onChange={(e) => validate(e.target.value)}
          ></input>
          <div className="absolute top-0 right-0">
            {error ? (
              <i className="fa-solid fa-circle-exclamation p-2.5 w-10 text-instant-teams-red-Main" />
            ) : (
              <i className="fa-solid fa-circle-check p-2.5 w-10 text-instant-teams-green-Main" />
            )}
          </div>
        </div>
      </div>
      <div className="mt-4">
        <SectionHeading title={"Link Text"} />
        <input
          type="text"
          className="min-w-full"
          onChange={(e) => setTitle(e.target.value)}
        ></input>
      </div>
      <div className="min-w-full mt-4">
        <SectionHeading title={"Preview"} />
        {href !== "" && title !== "" && (
          <div className="text-instant-teams-teal-Main py-2 px-[25%] w-auto">
            <button className="w-full px-4 py-2 h-auto text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none text-sm">
              <a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                className="block text-xs"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="fill-white inline-block pr-2"
                >
                  <path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z" />
                </svg>
                {title}
              </a>
            </button>
          </div>
        )}
      </div>
      <div className="mt-4 grid grid-cols-2 gap-2">
        <div>
          <button
            className="w-full px-4 py-2 h-auto text-white bg-instant-teams-blue-Main border-2 border-instant-teams-blue-Main rounded hover:bg-white hover:text-instant-teams-blue-Main focus:outline-none text-sm"
            onClick={() => dataReturn({ href, title })}
          >
            Save
          </button>
        </div>
        <div>
          <button
            className="w-full px-4 py-2 h-auto bg-white text-instant-teams-blue-Main rounded border-2 border-instant-teams-blue-Main hover:bg-instant-teams-blue-Main hover:text-white focus:outline-none text-sm"
            onClick={() => toggle()}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddALink;
