export function toUSCurrency(rate: number) {
  return Number(rate).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

export function toUSCurrencyNoChange(rate: number) {
  return Number(rate).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
}
