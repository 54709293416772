import { useEffect } from "react";
import { useParams } from "react-router";
import { useEmployer } from "../../../data/employer";
import { useJobDescription } from "../../../data/jobDescriptions";
import { useGlobalState } from "../../../providers/globalState/GlobalStateProvider";
import PositionMetrics from "../../../charts/positionsMetrics";
import JobApplicationTrends from "../../../charts/jobApplicationTrends";
import { Link } from "react-router-dom";

function ExternalJobOverview() {
  const params = useParams();
  const jobId = params.id ?? "";
  const { globalState, setGlobalState } = useGlobalState();
  const { data: job = {} } = useJobDescription(jobId);
  const { data: employer = {} } = useEmployer(job?.employer);

  useEffect(() => {
    const title = job?.title ?? "";
    const employerName = employer?.displayName ?? "";
    if (title && employerName) {
      setGlobalState({
        ...globalState,
        pageTitle: employerName + " - " + title,
      });
    }
  }, [job?.title, employer?.displayName]);

  return (
    <div className="w-full pb-36 min-h-screen">
      <div className="text-center grid grid-flow-col grid-cols-5 gap-4 mb-4">
        <div className="w-full col-span-2">
          <h1 className="text-3xl font-bold">
            {employer?.displayName} - {job?.title}
          </h1>
        </div>
        <div className="w-full">
          <Link to={`/jobs/${params?.id}/applications`}>
            <button className="bg-instant-teams-teal-Main text-white hover:bg-instant-teams-blue-Main w-full rounded-lg h-10">
              Applications
            </button>
          </Link>
        </div>
        <div className="w-full">
          <Link to={`/jobs/${params.id}/offers`}>
            <button className="bg-instant-teams-teal-Main text-white hover:bg-instant-teams-blue-Main w-full rounded-lg h-10">
              Offers
            </button>
          </Link>
        </div>
        <div className="w-full">
          <Link to={`/jobs/${params.id}/${job?.employer}/hired`}>
            <button
              disabled
              className="bg-instant-teams-teal-Main text-white hover:bg-instant-teams-blue-Main w-full rounded-lg h-10"
            >
              Hired
            </button>
          </Link>
        </div>
      </div>
      <div className="text-center grid grid-flow-col grid-cols-2 gap-4">
        <div className="col-span-1">
          <PositionMetrics jobId={jobId} />
        </div>
        <div className="col-span-1">
          <JobApplicationTrends jobId={jobId} />
        </div>
      </div>
    </div>
  );
}

export default ExternalJobOverview;
