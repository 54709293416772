import { useState, useEffect } from "react";
import { updateAutoSave } from "../../../data/utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const AutoSaveTextArea = ({
  value: initialValue,
  collection,
  recId,
  name,
  display,
  directions,
  readOnly,
  required,
}) => {
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [value, setValue] = useState(initialValue);
  const [check, setCheck] = useState(initialValue);
  const [isValid, setIsValid] = useState(true);
  const [saved, setSaved] = useState(false);

  // removes successful save message

  useEffect(() => {
    setTimeout(() => {
      setSaved(false);
    }, 3000);
  }, [saved]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleTextareaChange = (event) => {
    setValue(event.target.value);
  };

  const handleTextareaBlur = async () => {
    await updateAutoSave(collection, recId, { [name]: value });
    setSaved(true);
  };

  const handleTextareaKeydown = async (event) => {
    if (event.keyCode === 13) {
      // Enter key
      setValue(event.target.value);
      await updateAutoSave(collection, recId, { [name]: value });
      setSaved(true);
    }
  };

  return (
    <div id={name} className="relative mb-4 z-0">
      <div className="text-instant-teams-teal-Main font-bold pl-1">
        {display} {directions ? " - " + directions : null}{" "}
        {readOnly ? " - LOCKED" : null}
      </div>
      <textarea
        className="w-full"
        onChange={handleTextareaChange}
        value={value}
        onBlur={handleTextareaBlur}
        onKeyDown={handleTextareaKeydown}
      />
      {saved && (
        <span className="absolute right-2 top-2 text-xs">
          {display} saved!{" "}
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "green" }} />
        </span>
      )}
    </div>
  );
};

export default AutoSaveTextArea;
