// initialized firebase app
import { firebaseApp } from "../providers/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import cloudFunctionNames from "./dictionary/cloudFunctionNames";

const functions = getFunctions(firebaseApp);

export const addressValidation = httpsCallable(
  functions,
  cloudFunctionNames.addressValidation,
);

export const offersDataAggregationCallable = httpsCallable(
  functions,
  cloudFunctionNames.offersDataAggregationCallable,
);

export const deptDashboard = httpsCallable(
  functions,
  cloudFunctionNames.deptDashboard,
);

export const usersByType = httpsCallable(
  functions,
  cloudFunctionNames.usersByType,
);

export const newUsersByDay = httpsCallable(
  functions,
  cloudFunctionNames.newUsersByDay,
);

export const newOpeningsByDay = httpsCallable(
  functions,
  cloudFunctionNames.newOpeningsByDay,
);

export const collectionCounts = httpsCallable(
  functions,
  cloudFunctionNames.collectionCounts,
);

export const sliLogger = httpsCallable(functions, cloudFunctionNames.sliLogger);

export const resetPassword = httpsCallable(
  functions,
  cloudFunctionNames.resetPassword,
);

export const updateResetPassword = httpsCallable(
  functions,
  cloudFunctionNames.updateResetPassword,
);

export const fetchEarnedBadges = httpsCallable(
  functions,
  cloudFunctionNames.fetchEarnedBadges,
);

export const PositionMetrics = httpsCallable(
  functions,
  cloudFunctionNames.positionMetrics,
);

export const generateCohortFile = httpsCallable(
  functions,
  "generateCohortFile",
);

export const outreachList = httpsCallable(
  functions,
  cloudFunctionNames.outreachList,
);

export const getOpenJobMetrics = httpsCallable(
  functions,
  cloudFunctionNames.getOpenJobMetrics,
);

export const getPositionMetrics = httpsCallable(
  functions,
  cloudFunctionNames.getPositionMetrics,
);

export const getApplicationTrendsByDay = httpsCallable(
  functions,
  cloudFunctionNames.getApplicationTrendsByDay,
);

export const getTop10CoursesByCompletion = httpsCallable(
  functions,
  cloudFunctionNames.getTop10CoursesByCompletion,
);

export const getNewRegistrationMetrics = httpsCallable(
  functions,
  cloudFunctionNames.getNewRegistrationMetrics,
);

export const getAssessmentMetricsByStatus = httpsCallable(
  functions,
  cloudFunctionNames.getAssessmentMetricsByStatus,
);

export const getApplicationsPerPosition = httpsCallable(
  functions,
  cloudFunctionNames.getApplicationsPerPosition,
);

export const getApplicationTrendsByDayByJobId = httpsCallable(
  functions,
  cloudFunctionNames.getApplicationTrendsByDayByJobId,
);

export const getJobsNewMetricsChartData = httpsCallable(
  functions,
  cloudFunctionNames.getJobsNewMetricsChartData,
);

export const cleanupUserRole = httpsCallable(
  functions,
  cloudFunctionNames.cleanupUserRole,
);

export const cleanupOrphanedAuth = httpsCallable(
  functions,
  cloudFunctionNames.cleanupOrphanedAuth,
);

export const commCreateUser = httpsCallable(
  functions,
  cloudFunctionNames.commCreateUser,
);

export const commCheckUser = httpsCallable(
  functions,
  cloudFunctionNames.commCheckUser,
);

export const csvUsers = httpsCallable(functions, cloudFunctionNames.csvUsers);

export const deleteApplicationRecursively = httpsCallable(
  functions,
  cloudFunctionNames.deleteApplicationRecursively,
);

export const activateUser = httpsCallable(
  functions,
  cloudFunctionNames.activateUser,
);

export const externalJobsStats = httpsCallable(
  functions,
  cloudFunctionNames.externalJobsStats,
);

export const carrerSeekersDashboard = httpsCallable(
  functions,
  cloudFunctionNames.carrerSeekersDashboard,
);

export const getSheerIdDataByDay = httpsCallable(
  functions,
  cloudFunctionNames.getSheerIdDataByDay,
);
export const employersDashboard = httpsCallable(
  functions,
  cloudFunctionNames.employersDashboard,
);
export const jobsDashboard = httpsCallable(
  functions,
  cloudFunctionNames.jobsDashboard,
);

export const externalJobsPieChart = httpsCallable(
  functions,
  cloudFunctionNames.externalJobsPieChart,
);
export const userPieChart = httpsCallable(
  functions,
  cloudFunctionNames.userPieChart,
);

export const getInteractionsDataByDay = httpsCallable(
  functions,
  cloudFunctionNames.getInteractionsDataByDay,
);

export const sheerIdVerificationDashboardInfo = httpsCallable(
  functions,
  cloudFunctionNames.sheerIdVerificationDashboardInfo,
);
export const csvDataForSuccessfulVerification = httpsCallable(
  functions,
  cloudFunctionNames.csvDataForSuccessfulVerification,
);

export const getActiveUsersCSVData = httpsCallable(
  functions,
  cloudFunctionNames.getActiveUsersCSVData,
);

export const csvDataForFailedConversion = httpsCallable(
  functions,
  cloudFunctionNames.csvDataForFailedConversion,
);
export const csvDataForFailedVerification = httpsCallable(
  functions,
  cloudFunctionNames.csvDataForFailedVerification,
);
export const getInteractionsCSVData = httpsCallable(
  functions,
  cloudFunctionNames.getInteractionsCSVData,
);
export const getNewUsersData = httpsCallable(
  functions,
  cloudFunctionNames.getNewUsersData,
);
export const sendVerificationCode = httpsCallable(
  functions,
  cloudFunctionNames.sendVerificationCode,
);
export const verifyCode = httpsCallable(
  functions,
  cloudFunctionNames.verifyCode,
);
export const getMAUData = httpsCallable(
  functions,
  cloudFunctionNames.getMAUData,
);

export const viewJobMetric = httpsCallable(
  functions,
  cloudFunctionNames.viewJobMetric
);

export const applyNowMetric = httpsCallable(
  functions,
  cloudFunctionNames.applyNowMetric
)
