import React, { useEffect, useState } from "react";
import {
  getNotificationData,
  updateAllIsRead,
  updateIsRead,
} from "../../data/notifications";
import { DisplayTimestamp } from "../../util/timestamps/timestamp";
import { UserNotification } from "../../dataTypes/Notifications";
import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";
const UserNotificationItem = ({
  userNotification,
}: {
  userNotification: UserNotification;
}) => {
  const [notification, setNotification] = useState({});
  const { isRead, notificationId, userId, readAt } = userNotification;

  useEffect(() => {
    handleNotification(notificationId);
  }, [notificationId]);

  async function handleNotification(notificationId: string) {
    const notification = await getNotificationData(notificationId);
    setNotification(notification);
  }

  function handleClick() {
    updateIsRead(notificationId, userId);
  }

  return (
    <>
      <tr
        className={
          TailwindHoverEnlarge(103) + "pl-5 border-b border-black h-24"
        }
      >
        <td className="w-1/4 p-2">
          <strong>{notification?.title}</strong>
          <br />
          <span className="text-xs">
            {notification?.createdAt
              ? DisplayTimestamp(notification?.createdAt)
              : ""}
          </span>
        </td>
        <td className="w-1/2">
          <br />
          {notification?.body}
        </td>
        <td className="w-1/4 text-right">
          {!isRead ? (
            <>
              <button
                className="bg-instant-teams-green-D1 rounded-full p-2 text-white"
                onClick={handleClick}
              >
                Mark Read
              </button>
            </>
          ) : (
            DisplayTimestamp(readAt)
          )}
        </td>
      </tr>
    </>
  );
};

export default UserNotificationItem;
