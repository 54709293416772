import { useState } from "react";
import GreenUnderlineHeading from "../custom-ui/headings/heading";
import useModal from "../../hooks/useModal";
import CreateAlertsForm from "./CreateAlertsForm";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import toast from "react-hot-toast";
import { useAllAlertsForAdmin } from "../../data/alerts";
import { Alert } from "../../dataTypes/Alert";
import AlertItem from "./AlertItem";

const AlertsSection = () => {
  const [numberOfDays, setNumberOfDays] = useState(30);
  const { data: alerts = [], error: alertsError } =
    useAllAlertsForAdmin(numberOfDays);
  const { isShowing, toggle } = useModal();

  const handleDone = (message: string | undefined) => {
    toggle();

    if (message !== undefined) {
      toast.success(message);
    }
  };

  const handleChange = (event: any) => {
    setNumberOfDays(parseInt(event.target.value));
    event.preventDefault();
  };

  return (
    <div className="ml-4 w-full">
      <div className=" pr-12 pb-5">
        <div className="flex justify-end">
          <button
            className=" w-1/5 py-2  text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
            onClick={toggle}
          >
            Create Alert
          </button>
        </div>
        <GreenUnderlineHeading title="Alerts" />
      </div>
      <div className="min-w-full mb-4 pr-12">
        <div className=" h-10 relative mb-4">
          <label className="text-instant-teams-teal-Main text-sm  absolute  duration-300 transform -translate-y-4 scale-75 top-4 origin-[0] left-2.5 peer-focus:text-instant-teams-blue-Main peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 w-full">
            Days Ago
          </label>
          <select
            value={numberOfDays}
            onChange={handleChange}
            className="w-1/6"
          >
            <option value={numberOfDays}>{numberOfDays}</option>
            <option value={7}>7 days</option>
            <option value={14}>14 days</option>
            <option value={30}>30 days</option>
          </select>
        </div>
        <div className="oveflow-hidden">
          <table className="w-full mr-15 bg-white shadow-md rounded-md ">
            <thead className="border-b">
              <tr>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                >
                  Title
                </th>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                >
                  Sent
                </th>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                >
                  Body
                </th>
              </tr>
            </thead>
            <tbody>
              {alerts &&
                alerts?.length > 0 &&
                alerts.map((alert: Alert, index: number) => (
                  <AlertItem alert={alert} key={index} />
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {isShowing && (
        <DialogWrapper onClose={() => toggle()} title="Alert Creation Form">
          <CreateAlertsForm onDone={handleDone} />
        </DialogWrapper>
      )}
    </div>
  );
};

export default AlertsSection;
