import { useEffect, useState } from "react";

import {
  useAllMessagesByChatId,
  sendMessage,
  handleTypingMessage,
  handleDeleteMessage,
} from "../../data/messages";
import MessagePanelItem_NewUI from "./MessagePanelItem_NewUI";
import { DisplayDate } from "../../util/timestamps/timestamp";
import { useUser } from "../../providers/database";
import { useUsersByRole } from "../../data/user";

const MessagePanel_NewUI = ({ chat, minimize }) => {
  const [allMessages, setAllMessages] = useState([]);
  const [messagesByDay, setMessagesByDay] = useState([]);
  const [docId, setDocId] = useState("");
  const [newMessageStarted, setNewMessageStarted] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [unreadIds, setUnreadIds] = useState([]);
  const { id: chatId, recipientId } = chat;
  const { data } = useAllMessagesByChatId(minimize ? chat?.objectID : chatId);
  const senderId = localStorage.getItem("__user_id")?.replace(/"/g, "");
  const { data: userData } = useUser(senderId);
  const { data: recipientData } = useUser(recipientId);
  const { data: usersByRole } = useUsersByRole(chat?.group);

  const handleCleanup = () => {
    if (messageText === "" && docId) {
      handleDeleteMessage(chatId, docId).then(() => {
        setDocId("");
        setNewMessageStarted(false);
      });
    }
  };

  const handleMessageChange = async (e) => {
    setMessageText(e.target.value);
    setNewMessageStarted(true);
    if (!newMessageStarted) {
      const messageId = await handleTypingMessage(chatId, senderId);
      setDocId(messageId);
    }
  };

  useEffect(() => {
    const userIds = usersByRole
      ?.map((item) => item.id)
      .filter((id) => id !== userData?.id);
    if (
      userIds?.length > 0 &&
      recipientId !== null &&
      recipientId !== undefined
    ) {
      setUnreadIds([...userIds, recipientId]);
    }
  }, [usersByRole, data, recipientId, userData?.id]);

  useEffect(() => {
    const hasTypingMessage = allMessages?.some((message) => message?.isTyping);
    setIsTyping(hasTypingMessage);
  }, [allMessages]);

  useEffect(() => {
    handleCleanup();
  }, [messageText]);

  useEffect(() => {
    setAllMessages(data);
  }, [chatId, data]);

  useEffect(() => {
    // Group messages by day
    const groups = allMessages?.reduce((groups, message) => {
      const date = DisplayDate(message?.createdAt);
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});

    // Convert groups object to array of objects
    const groupsArray = groups
      ? Object.entries(groups).map(([date, messages]) => ({
          date,
          messages,
        }))
      : [];

    setMessagesByDay(groupsArray);
  }, [allMessages]);

  useEffect(() => {
    if (messagesByDay?.length > 0) {
      const lastMessage = document.getElementById("chat-feed").lastChild;
      lastMessage.scrollIntoView(false);
    }
  }, [messagesByDay]);

  return (
    <div
      key={chat.id}
      className="overscroll-contain overflow-none no-scrollbar"
    >
      <div
        className={`${
          minimize ? "w-[100%]" : "w-full relative"
        } bg-white overflow-y-scroll overscroll-contain no-scrollbar h-[92%] justify-end`}
      >
        <div
          className="mb-10 flex flex-col justify-end overscroll-contain"
          id="chat-feed"
        >
          {messagesByDay &&
            messagesByDay?.length > 0 &&
            messagesByDay.map((group, index) => (
              <div key={`${group.date}${index}`}>
                <div className="relative flex flex-col items-center">
                  <div className="text-center z-10 bg-white px-4">
                    <p>{group.date}</p>
                  </div>
                  <div className="absolute left-0 right-0 border-t-2 border-instant-teams-teal-Main top-1/2"></div>
                </div>
                {group.messages.map((message, index) => (
                  <MessagePanelItem_NewUI
                    chatId={chatId}
                    message={message}
                    key={`${message?.id}${index}`}
                    minimize={minimize}
                  />
                ))}
              </div>
            ))}
          <div id={"messageEnd"}></div>
        </div>
      </div>
    </div>
  );
};

export default MessagePanel_NewUI;
