import React from "react";
import { useAuth } from "../../providers/auth";
import SadminDashboard from "./roleDashboards/sadmin";
import GenDashboard from "./roleDashboards/gen";

function DashboardSection(props) {
  const auth = useAuth();
  console.log("auth", auth);
  const role = auth?.role?.id;

  return (
    <>
      <div className="container w-full bg-gray-50">
        {/* Admin Dashboards */}
        {role === "sadmin" ? <SadminDashboard /> : null}
        {role === "admin" ? <SadminDashboard /> : null}
        {role === "tac" ? <SadminDashboard /> : null}
        {role === "sales" ? <SadminDashboard /> : null}
        {role === "csss" ? <SadminDashboard /> : null}
        {role === "pops" ? <SadminDashboard /> : null}
        {role === "learn" ? <SadminDashboard /> : null}
        {role === "finance" ? <SadminDashboard /> : null}
        {role === "marketing" ? <SadminDashboard /> : null}
        {role === "general" ? <SadminDashboard /> : null}
        {/* Employer Dashboards */}
        {role === "GEN" ? <GenDashboard /> : null}
        {role === "PRI" ? <GenDashboard /> : null}
        {role === "BILL" ? <GenDashboard /> : null}
      </div>
    </>
  );
}

export default DashboardSection;
