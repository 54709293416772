import { forwardRef } from "react";
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import { cn } from "../../../util/cn";

// Slider rail (background)
const SliderRail = ({ getRailProps }) => (
  <div
    style={{
      position: "absolute",
      width: "100%",
      height: 5,
      borderRadius: 5,
    }}
    className="bg-[#d4dfe3]"
    {...getRailProps()}
  />
);

// Slider handle
const Handle = ({ handle: { id, value, percent }, getHandleProps, disabled = false, handleCenterDot = false, isViewOnly = false }) => (
  <div
    style={{
      left: `${percent}%`,
      position: "absolute",
      transform: "translate(-50%, -50%)",
      zIndex: 2,
      width: 14,
      height: 14,
      top: 2,
      borderRadius: "50%",
    }}

    className={cn("",
      {
        "bg-instant-teams-blue-Main": isViewOnly || !disabled,
      },
      {
        "bg-[#d4dfe3]": disabled,
      }
    )}
    {...getHandleProps(id)}
  >
    {/* White dot in the center */}
    {
      !isViewOnly && handleCenterDot && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 4,  // size of the white dot
            height: 4,
            backgroundColor: "white",
            borderRadius: "50%",
          }}
        />
      )
    }

    {/* <div style={{ position: "absolute", top: 25 }}>{value}</div> */}
  </div>
);

// Track component - handles left/right filling from the center (50)
const Track = ({ source, target, getTrackProps }) => {
  const centerPercent = 50;
  let left, width;

  // Handle filling to the left of the center
  if (source.percent < centerPercent) {
    left = `${source.percent}%`;
    width = `${centerPercent - source.percent}%`;
  }
  // Handle filling to the right of the center
  else {
    left = `${centerPercent}%`;
    width = `${source.percent - centerPercent}%`;
  }

  return (
    <div
      style={{
        position: "absolute",
        height: 5,
        zIndex: 1,
        // backgroundColor: "blue", // color of the track
        borderRadius: 5,
        left: left,
        width: width,
      }}
      className="bg-instant-teams-blue-Main"
      {...getTrackProps()}
    />
  );
};

const SliderCentered = forwardRef(({ value, onChange, className = '', disabled = false, handleCenterDot = false, isViewOnly = false, readOnly = false }, ref) => {
  const domain = [0, 100];
  const defaultValues = [value]; // Center value
  // const defaultValues = [50]; // Center value

  return (
    <div className={cn("w-full relative", className,
      {
        "cursor-pointer": !isViewOnly && !disabled,
      },
      {
        "cursor-not-allowed": readOnly || !isViewOnly && disabled,
      },
      {
        'cursor-default': isViewOnly
      }
    )} >
      <Slider
        ref={ref}
        mode={1}
        step={25}
        domain={domain}
        rootStyle={{
          position: "relative",
          width: "100%",
        }}
        values={defaultValues}
        onUpdate={onChange}
        disabled={readOnly || isViewOnly || disabled}
      >
        {/* Slider Rail */}
        <Rail>
          {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
        </Rail>

        {/* Handles */}
        <Handles>
          {({ handles, getHandleProps }) => (
            <div>
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  getHandleProps={getHandleProps}
                  disabled={disabled}
                  handleCenterDot={handleCenterDot}
                  isViewOnly={isViewOnly}
                />
              ))}
            </div>
          )}
        </Handles>

        {/* Tracks to fill from center */}
        <Tracks left={false} right={true}>
          {({ tracks, getTrackProps }) => (
            <div>
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    </div >
  );
});

SliderCentered.displayName = "SliderCentered";

export default SliderCentered;
