import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../../../../providers/database";
import ToastAlert from "../../../custom-ui/toast-alert/ToastAlert";
import AutoSaveCheckboxGroup from "../../../custom-ui/input-fields/auto-save-checkbox-group";
import { useLookup } from "../../../../data/lookup";
import { collectionNames } from "../../../../data/dictionary/collectionNames";
import { useResume } from "../../../../data/seeker";
import AutoSaveWysiwyg from "../../../custom-ui/input-fields/auto-save-wysiwyg";
import SeekerFiles from "../parts/SeekerFiles";
import DragAndDropVariantB from "../../../custom-ui/drag-and-drop/DragAndDropFileSmall";
import folderNames from "../../../../storage/dictionary/folderNames";
import { upload } from "../../../../storage/storage";
import { writeResume } from "../../../../data/seeker";
import { friendlyFirebaseError } from "../../../../providers/firebase-errors/friendlyFirebaseError";
import useModal from "../../../../hooks/useModal";
import Meta from "../../../custom-ui/meta/Meta";
import { useAllListings, useListing } from "../../../../data/listings";
import { createApplication } from "../../../../data/applications";
import { useApplicationsByUser } from "../../../../data/applications";
import GreenUnderlineHeading from "../../../custom-ui/headings/heading";

const SeekerPreferences = () => {
  const params = useParams();
  const [id, setId] = useState(params?.seekerId || "");
  const { data: seekerData, error: seekerError } = useResume(id);
  const { data: listingData } = useAllListings(id);
  const { error: userError } = useUser(id);
  const { data: model = [] } = useLookup("model");
  const { data: term = [] } = useLookup("term");
  const [viewNote, setViewNote] = useState(true);
  const [viewApplications, setViewApplications] = useState(false);
  const [viewOffers, setViewOffers] = useState(false);
  const [viewEmployment, setViewEmployment] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [invite, setInvite] = useState("");
  const { data: classification = [] } = useLookup("classification");
  const { data: currentApplications } = useApplicationsByUser(id);

  const { isShowing: showModal, toggle: toggleModal } = useModal();

  const { data: inviteData, error: inviteError } = useListing(invite);

  useEffect(() => {
    if (inviteData) {
      setDataLoaded(true);
    }
  }, [inviteData]);

  useEffect(() => {
    if (seekerError) {
      console.log(seekerError);
    }
  }, [seekerError]);
  useEffect(() => {
    if (userError) {
      console.log(userError);
    }
  }, [userError]);

  useEffect(() => {
    setId(params.seekerId ?? "");
  }, [params.seekerId]);

  const [file, setFile] = useState();
  const [formAlert, setFormAlert] = useState(null);
  const active =
    "w-full flex justify-center py-2 px-4 text-white bg-instant-teams-tags-GREEN rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none";
  const inactive =
    "w-full flex justify-center py-2 px-4 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none";

  const { isShowing: isShowingNote, toggle: toggleNote } = useModal();
  const handleDone = () => {
    toggleNote();
  };

  const handleViewNote = () => {
    setViewApplications(false);
    setViewOffers(false);
    setViewEmployment(false);
    setViewNote(true);
  };
  const handleViewApplications = () => {
    setViewNote(false);
    setViewOffers(false);
    setViewEmployment(false);
    setViewApplications(true);
  };
  const handleViewOffers = () => {
    setViewNote(false);
    setViewOffers(true);
    setViewEmployment(false);
    setViewApplications(false);
  };
  const handleViewEmployment = () => {
    setViewNote(false);
    setViewOffers(false);
    setViewEmployment(true);
    setViewApplications(false);
  };

  useEffect(() => {
    console.log(currentApplications);
    console.log(listingData);
  }, [currentApplications, listingData]);

  useEffect(() => {
    if (invite !== "") {
      console.log(invite);
    }
  }, [invite]);

  function handleInvite() {
    if (inviteData && invite !== "") {
      const application = {
        jobId: inviteData.jobId,
        positionId: inviteData.positionId,
        resumeId: id,
        invited: true,
      };
      createApplication(application);
      toggleModal();
      setInvite("");
      setDataLoaded(false);
    }
  }

  const confirmUpload = () => {
    upload(file, folderNames.resume, `${id}_${file?.name}`).then((fileUrl) => {
      writeResume(id, {
        label: file?.name,
        type: file?.type,
        link: fileUrl,
      })
        .then((docRef) => {
          setFile();
        })
        .catch((error: Error) => {
          setFormAlert({
            type: "error",
            message: friendlyFirebaseError(error),
          });
        })
        .catch((error: Error) => {});
    });
  };

  return (
    <div className="w-full">
      <Meta title="Seeker Preferences" />
      <div className="w-full mt-6 flex">
        <div className="w-full grid grid-cols-2 gap-4 mb-4">
          <div className="mb-2 mt-4 mr-4">
            <h1 className=" text-xl font-semibold text-instant-teams-blue-Main uppercase mb-4">
              WORK PREFERENCES
            </h1>

            <GreenUnderlineHeading title="Model" />
            <AutoSaveCheckboxGroup
              name="models"
              options={model}
              initialValues={seekerData?.models}
              collection={collectionNames.resume}
              id={id}
            />

            <GreenUnderlineHeading title="Classification" />
            <AutoSaveCheckboxGroup
              name="classifications"
              options={classification}
              initialValues={seekerData?.classifications}
              collection={collectionNames.resume}
              id={id}
            />

            <GreenUnderlineHeading title="Term" />
            <AutoSaveCheckboxGroup
              name="terms"
              options={term}
              initialValues={seekerData?.terms}
              collection={collectionNames.resume}
              id={id}
            />
          </div>
          <div className="mb-2 mt-11 mr-4">
            <GreenUnderlineHeading title="Files" />
            <div className="w-full grid grid-cols-2 gap-4">
              {formAlert && (
                <span className="mb-4 text-red-600">{formAlert.message}</span>
              )}{" "}
              <div className="text-center mb-2">
                <DragAndDropVariantB setFile={setFile} />
                <div className="relative flex justify-center">
                  {file && (
                    <button
                      className="absolute bottom-3 mt-4 py-2 px-4 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                      onClick={confirmUpload}
                    >
                      Upload File
                    </button>
                  )}
                </div>
              </div>
              <SeekerFiles id={id} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-base mt-4">
        <AutoSaveWysiwyg
          value={seekerData?.resume}
          rows={5}
          collection="Resumes"
          recId={id}
          required={false}
          name="resume"
          display="Resume"
          placeholder={"Tell us about yourself..."}
          directions=""
          readOnly={false}
        />
      </div>
      <ToastAlert />
    </div>
  );
};

export default SeekerPreferences;
