import SeekerBadgesItem from "./SeekerBadgesItem";
function SeekerBadges({
  succeeded,
  badges,
}: {
  succeeded: Boolean;
  badges: any;
}) {
  return (
    <>
      <div className="pb-5">
        <p>
          {succeeded
            ? `Congratulations! You've put in the work and it looks like all the required skills for this position have been verified.`
            : `Thanks for completing the e-interview questions. Take a look at the required skills for this position before completing your application.`}
        </p>
      </div>
      <div>
        {badges &&
          badges.length > 0 &&
          badges.map((badge: any) => {
            return <SeekerBadgesItem badge={badge} />;
          })}
        <div>
          <p className=" p-1 text-instant-teams-blue-Main text-sm">
            <i className="pr-2 fa-solid fa-circle-exclamation text-yellow-400"></i>
            Please note that Skill Assessment Results can take some time to
            reflect on your account.
          </p>
        </div>
      </div>
    </>
  );
}

export default SeekerBadges;
