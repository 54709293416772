import React, { useState, useEffect } from "react";
import { createAlert } from "../../data/alerts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-duotone-svg-icons";
import { useAuth } from "../../providers/auth";

const CreateAlertsForm = ({ onDone }: { onDone: Function }) => {
  const user = useAuth();
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [pending, setPending] = useState(false);
  const [button, setButton] = useState("Create Alert");
  const [formAlert, setFormAlert] = useState(null);

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (title && body) {
      setPending(true);
      setButton("Creating Notification...");
      const data = {
        title: title,
        body: body,
        createdBy: user.user.id,
      };
      createAlert(data)
        .then((res) => {
          onDone(`Added ${data.title}`);
        })
        .catch((error) => {
          setFormAlert(`There was a problem creating ${data.title}`);
          console.log(formAlert, error);
        })
        .finally(() => {
          setPending(false);
          setButton("Create Notification");
        });
    }
  };
  return (
    <>
      <div className="mb-2">
        <input
          className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
          name="title"
          type="text"
          placeholder="title"
          onChange={(e) => setTitle(e.target.value)}
        />
        {title === "" && (
          <span className="absolute right-7 top-30 text-xs">
            {"Required "}
            <FontAwesomeIcon icon={faCircleExclamation} />
          </span>
        )}
      </div>
      <div className="mb-2">
        <textarea
          className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
          name="body"
          placeholder="notification"
          onChange={(e) => setBody(e.target.value)}
        />
        {body === "" && (
          <span className="absolute right-7 top-30 text-xs">
            {"Required "}
            <FontAwesomeIcon icon={faCircleExclamation} />
          </span>
        )}
      </div>
      <button
        className="py-2 px-4 w-full text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
        type="submit"
        onClick={(e) => onSubmit(e)}
        disabled={pending}
      >
        {button}
      </button>
    </>
  );
};

export default CreateAlertsForm;
