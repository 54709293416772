import React from 'react';
import { Helmet } from 'react-helmet';

const CustomMeta = ({ title, postTitle, shareDescription, twitterImage, images, shareUrl }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta property="og:description" content={shareDescription} />
            <meta property="og:image" content={images} />
            <meta property="og:url" content={shareUrl} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={postTitle} />
            <meta name="twitter:description" content={shareDescription} />
            <meta name="twitter:image" content={twitterImage} />
            <meta name="twitter:url" content={shareUrl} />
        </Helmet>
    );
};

export default CustomMeta;
