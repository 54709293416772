import DashboardSection from "../../components/dashboard/DashboardSection";
import { customDataRouteObject } from "../../dataTypes/ReactRouter";

import InterviewInstructions from "../../components/interviews/Employer/InterviewInstructions";

import DepartmentListing from "../../components/employer/details/pages/EmployerDepartments";
import EmployerLocationListing from "../../components/employer/details/pages/EmployerLocations";

import NotesDisplay from "../../components/notes/notes";

import Employees from "../../components/employee/details/pages/Employees";

import UserInfo from "../../components/user/details/pages/userInfo";

import OfferDetails from "../../components/offers/parts/OfferDetails";

import { collectionNames } from "../../data/dictionary/collectionNames";

import JobPositionsListPage from "../../components/jobs/pages/JobPositionsListPage";
import JobInterviewQuestions from "../../components/jobs/pages/JobInterviewQuestions";

import CompanyOverview from "../../components/employerView/pages/CompanyOverview";
import EditCompanyProfile from "../../components/employerView/pages/CompanyProfile";
import { ErrorBoundary } from "react-error-boundary";
import Fallback from "../../components/fallback/Fallback";

import JobApplicants from "../../components/jobs/pages/JobApplicants";

import PendingOffers from "../../components/jobs/pages/PendingOffers";

import ExternalJobOverview from "../../components/jobs/externalView/ExternalJobOverview";
import ExternalJobApplicantDetails from "../../components/jobs/externalView/parts/ExternalJobApplicantsDetails";

import JobApplicantPreScreen from "../../components/jobs/pages/JobApplicantPreScreen";
import JobApplicantInterview from "../../components/jobs/pages/JobApplicantInterview";

import ExternalJobDescriptionPage from "../../components/jobs/externalView/pages/ExternalJobDescription";
import ExternalJobPositionDetails from "../../components/jobs/externalView/parts/ExternalPositionDetails";
import { InstantSearch } from "react-instantsearch-hooks-web";
import algoliasearch from "algoliasearch/lite";
import ExternalJobInformation from "../../components/jobs/externalView/pages/ExternalJobInformation";
import ExternalApplicants from "../../components/employerView/pages/ExternalApplicants";
import ExternalNotesListing from "../../components/employerView/pages/ExternalNotesListing";

import EmployeeDetails from "../../components/employee/details/pages/EmployeeDetails";
import EmployeeSkills from "../../components/employee/details/pages/EmployeeSkills";
import ExternalJobSkills from "../../components/jobs/externalView/parts/ExternalJobSkills";
import ExternalOffers from "../../components/employerView/ExternalOffers";

const { users } = collectionNames;

const searchClient = (() => {
  if (!process.env.REACT_APP_ALGOLIA_APP_ID) {
    throw new Error(
      "REACT_APP_ALGOLIA_APP_ID environment variable not defined",
    );
  }
  if (!process.env.REACT_APP_ALGOLIA_API_KEY) {
    throw new Error(
      "REACT_APP_ALGOLIA_API_KEY environment variable not defined",
    );
  }

  return algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY,
  );
})();

export const ExternalOfferChildRoutes: customDataRouteObject[] = [
  {
    id: "Employer Offer Details",
    name: "Details",
    path: "details",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <OfferDetails />
      </ErrorBoundary>
    ),
    viewer: ["PRI", "GEN"],
    menuDisplay: true,
  },
];

export const ExternalApplicantChildRoutes: customDataRouteObject[] = [
  {
    id: "Application View Details ",
    name: "Details ",
    path: "details",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <ExternalJobApplicantDetails />
      </ErrorBoundary>
    ),
    viewer: ["PRI", "GEN"],
    menuDisplay: true,
  },
  {
    id: "Application View PreScreen",
    name: "PreScreen",
    path: "prescreen",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobApplicantPreScreen />
      </ErrorBoundary>
    ),
    viewer: ["PRI", "GEN"],
    menuDisplay: true,
  },
  {
    id: "Application View eInterview",
    name: "e-Interview",
    path: "einterview",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobApplicantInterview />
      </ErrorBoundary>
    ),
    viewer: ["PRI", "GEN"],
    menuDisplay: true,
  },
];

export const CompanyChildRoutes: customDataRouteObject[] = [
  {
    id: "Overview",
    name: "Overview",
    path: "overview",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <CompanyOverview />
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
  {
    id: "Profile",
    name: "Profile",
    path: "profile",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EditCompanyProfile />
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
  {
    id: "Departments",
    name: "Departments",
    path: "departments",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <DepartmentListing />
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
  {
    id: "Locations",
    name: "Locations",
    path: "locations",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EmployerLocationListing />
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
  {
    id: "interviewInstructions",
    name: "Interview Instructions",
    path: "interview-instructions",
    // @ts-ignore
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InterviewInstructions />
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
  {
    id: "Applicants",
    name: "Applicants",
    path: "applicants",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="Applications">
          <ExternalApplicants />
        </InstantSearch>
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
  {
    id: "Offers",
    name: "Offers",
    path: "offers",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch indexName="Offers" searchClient={searchClient}>
          <ExternalOffers />
        </InstantSearch>
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
  {
    id: "Employees",
    name: "Employees",
    path: "employees",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="Employees">
          <Employees />
        </InstantSearch>
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
];

export const ExternalDashboardChildRoutes: customDataRouteObject[] = [
  {
    id: "Home",
    name: "Home",
    path: "home",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <DashboardSection />
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
];

export const ExEmployeeChildRoutes: customDataRouteObject[] = [
  {
    id: "E Employee View Details",
    name: "Details",
    path: "details",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EmployeeDetails />
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
  {
    id: "E Employee View Skills",
    name: "Skills",
    path: "employee-skills",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EmployeeSkills />
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
];

export const ExternalJobListingChildRoutes: customDataRouteObject[] = [
  {
    id: "Job Metrics",
    name: "Metrics",
    path: "metrics",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <ExternalJobOverview />
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
  {
    id: "Job Applications",
    name: "Applications",
    path: "applications",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobApplicants />
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
  {
    id: "Job Offers",
    name: "Offers",
    path: "offers",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <PendingOffers />
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
  {
    id: "JobEmployees",
    name: "Employees",
    path: "employees",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="Employees">
          <Employees />
        </InstantSearch>
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
  {
    id: "Positions",
    name: "Positions",
    path: "positions",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobPositionsListPage />
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
  {
    id: "Description",
    name: "Description",
    path: "description",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <ExternalJobDescriptionPage />
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
  {
    id: "Information",
    name: "Information",
    path: "information",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <ExternalJobInformation />
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
  {
    id: "Position Details",
    name: "Position Details",
    path: "positions/:positionId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <ExternalJobPositionDetails />
      </ErrorBoundary>
    ),
  },
  {
    id: "ExternalSkills",
    name: "Skills",
    path: "skills",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <ExternalJobSkills />
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
  {
    id: "E-Interview",
    name: "e-interview",
    path: "e-interview",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobInterviewQuestions />
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
  {
    id: "job notes",
    name: "Notes",
    path: "notes",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <ExternalNotesListing />
      </ErrorBoundary>
    ),
    viewer: ["GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
];

export const ExternalUserDetailsChildRoutes: customDataRouteObject[] = [
  {
    id: "User Info",
    name: "User Info",
    path: "user-info",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <UserInfo />
      </ErrorBoundary>
    ),
    viewer: ["PRI"],
    menuDisplay: true,
  },

  {
    id: "User Notes",
    name: "Notes",
    path: "user-notes",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <NotesDisplay collection={users} paramId="userId" />
      </ErrorBoundary>
    ),
    viewer: ["PRI"],
    menuDisplay: true,
  },
];
