import React, { useEffect, useRef, useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { formatRateRange, formatString, formatTimeDifference, getDeviceType } from '../util';
import { useAuth } from '../../../../../../providers/auth';
import { updateUserBookmarks, getUserBookmarks } from '../../../../../../data/user';
import { applyNowMetric } from '../../../../../../callable-cloud-functions/cloudFunctions';
import { sanitizeMarkup } from '../../../../../../util/sanitization/sanitize-html';
import DialogWrapper from '../../../../../custom-ui/dialog/Dialog';
import useModal from '../../../../../../hooks/useModal';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SliderWithCheckbox from '../../../../../custom-ui/old-ui/SliderWithCheckbox';

const JobsDetails = ({ jobsDetailData, bookmarks, setBookmarks, aspectsOfJobMappedWithCode, aboutThisJobMappedWithCode, mustHavesMappedWithCode }) => {
    const data = useAuth();
    const { isShowing: isShowing, toggle: toggleShow } = useModal();
    const [loading, setLoading] = useState(false)
    const commissionRef = useRef(null);
    useEffect(() => {
        const fetchBookmarks = async () => {
            const userBookmarks = await getUserBookmarks(data?.user?.id);
            setBookmarks(userBookmarks);
        };
        fetchBookmarks();
    }, [data]);

    // console.log(aspectsOfJobMappedWithCode, '=========>aspectsOfJobMappedWithCode')
    // console.log(aboutThisJobMappedWithCode, '=========>aboutThisJobMappedWithCode')
    // console.log(mustHavesMappedWithCode, '=========>mustHavesMappedWithCode')

    // console.log(jobsDetailData, '=========>jobsDetailData')

    const {
        companyLogo,
        companyFullName,
        jobName,
        openings,
        workType,
        workTerm,
        workSettings,
        makeLiveDate,
        payRate,
        applicationFlow,
        jobDescription,
        solutionType
    } = jobsDetailData || {};

    const timeDifference = formatTimeDifference(makeLiveDate);
    const platform = getDeviceType()


    const handleApplyJobs = async () => {
        setLoading(true)
        try {
            await applyNowMetric({ jobId: jobsDetailData?.id, userId: data?.user?.id, platform: platform, solutionType: solutionType === "job_board" ? "DH" : "BPO" });
            setLoading(false)
            window.open(applicationFlow, '_blank');
        } catch (error) {
            console.error("Error applying for the job:", error);
        }
    };

    const handleBookMark = async () => {
        await updateUserBookmarks(data?.user?.id, jobsDetailData?.id);

        // Update local bookmarks state
        setBookmarks((prevBookmarks) => {
            if (Array.isArray(prevBookmarks) && prevBookmarks.some((bookmark) => bookmark.jobId === jobsDetailData?.id)) {
                return prevBookmarks.filter((bookmark) => bookmark.jobId !== jobsDetailData?.id);
            } else {
                return [...prevBookmarks, { jobId: jobsDetailData?.id, createdAt: new Date() }];
            }
        });
    };

    const scrollToCommission = () => {
        commissionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    return (
        <div
            style={{
                scrollbarWidth: 'thin',
                scrollbarColor: '#b3b3b3 #f0f0f0',
            }}
            className="w-full mx-auto bg-white  rounded-lg overflow-y-auto overflow-x-hidden h-[92%] p-6 mt-12">
            {/* Header Section */}
            {jobsDetailData ? (
                <>
                    <div className="flex justify-between items-start">
                        <div className="flex">
                            <div className="mr-4">
                                <img
                                    src={solutionType == "bpo" ? "/images/InstantTeamsIcon-05.png" : companyLogo}
                                    alt="Company Logo"
                                    className="w-16 h-16 rounded object-contain "
                                />
                            </div>
                            <div>
                                <h2 className="text-xl font-semibold text-instant-teams-blue-Main">
                                    {jobName}
                                    {timeDifference?.includes("h ago") && <span className="text-red-500 ml-2">🔥</span>}
                                </h2>
                                <p className="text-gray-500 text-sm font-thin">{solutionType == "bpo" ? "Instant Teams" : companyFullName}</p>
                            </div>
                        </div>
                        <div className="flex items-center space-x-4">
                            {openings > 1 && (
                                <span className="bg-instant-teams-teal-D2 text-gray-600 text-xs px-3 py-1 rounded-full">
                                    Multiple Openings
                                </span>
                            )}
                            {/* Bookmark Icon */}
                            <div className="flex-shrink-0 bg-gray-100 flex items-center justify-center w-[50px] h-[50px] rounded-full ml-4 ">
                                <button onClick={handleBookMark} className="text-gray-500 pl-[14px] pt-[14px] hover:text-gray-600">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 42 42"
                                        stroke="currentColor"
                                        className="w-8 h-8"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            fill={Array.isArray(bookmarks) && bookmarks.some((bookmark) => bookmark.jobId === jobsDetailData?.id) ? "#19405E" : ""}
                                            d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3-7 3V5z"
                                        />
                                    </svg>
                                </button>
                            </div>

                            <button onClick={toggleShow} className="bg-instant-teams-blue-Main text-white py-[16px] px-[34px] rounded-full flex items-center text-[16px]">
                                <span className='w-[107px]'>
                                    Apply Now <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="mt-4 flex items-center space-x-5">
                        <div className="bg-[#CCD6FF] py-2 px-6 rounded-lg ">
                            <p className="text-instant-teams-blue-Main text-center  text-sm">Work Type</p>
                            <p className="font-semibold text-instant-teams-blue-Main text-center ">{formatString(workType)}</p>
                        </div>
                        <div className="bg-[#CCD6FF] py-2 px-6 rounded-lg ">
                            <p className="text-instant-teams-blue-Main text-sm  text-center">Work Setting</p>
                            <p className="font-semibold text-instant-teams-blue-Main text-center">{formatString(workSettings)}</p>
                        </div>
                        {payRate?.salary && (
                            <div style={{ backgroundColor: "#3E4C9533" }} onClick={scrollToCommission} className={`${payRate?.commission ? "cursor-pointer" : ""} bg-[#3E4C9533] py-2 px-6  rounded-lg `}>
                                <p className="text-instant-teams-blue-Main text-center text-sm">{payRate?.commission ? "Salary + Commission" : "Salary"}</p>
                                <p className="font-semibold text-instant-teams-blue-Main text-center">{formatRateRange("salary", payRate?.salary)}</p>
                            </div>
                        )}
                        {payRate?.hourly && (
                            <div style={{ backgroundColor: "#3E4C9533" }} onClick={scrollToCommission} className="bg-[#3E4C9533] py-2 px-6  rounded-lg ">
                                <p className="text-instant-teams-blue-Main text-center text-sm">{payRate?.commission ? "Salary + Commission" : "Salary"}</p>
                                <p className="font-semibold text-instant-teams-blue-Main">{formatRateRange("hoursPerWeek", payRate?.hourly)}</p>
                            </div>
                        )}
                        {payRate?.commission && !payRate?.salary && !payRate?.hourly && (
                            <div style={{ backgroundColor: "#3E4C9533" }} className="bg-[#3E4C9533] py-2 px-6  rounded-lg ">
                                <p className="text-instant-teams-blue-Main font-thin  text-center text-sm">Salary</p>
                                <p className="font-semibold text-instant-teams-blue-Main">Commission Based</p>
                            </div>
                        )}
                        {payRate?.type == "unpaid_internship" && (
                            <div style={{ backgroundColor: "#3E4C9533" }} className="bg-[#3E4C9533] py-2 px-6  rounded-lg ">
                                <p className="text-instant-teams-blue-Main font-thin  text-center text-sm">Salary</p>
                                <p className="font-semibold text-instant-teams-blue-Main">Unpaid Internship</p>
                            </div>
                        )}
                    </div>

                    {/* Job Description */}
                    <div className="mt-6 border-t pt-6">
                        {
                            jobDescription?.whatsInItForYou === null && jobDescription?.dayInLife && <div className="mt-4 mb-[30px]">
                                <p className="font-[Gilroy-SemiBold] font-[400] text-[20px] leading-[24.5px] text-[#15415E]">
                                    Job Summary
                                </p>
                                <div className="mt-[5px] font-[Gilroy-Medium] font-[400] text-[16px] leading-[23px] text-[#373737]">

                                    {jobDescription?.dayInLife}
                                </div>
                            </div>
                        }


                        {
                            jobDescription?.whatsInItForYou === null
                                ? (
                                    <div className="flex flex-col">
                                        <p className="font-[Gilroy-SemiBold] font-[400] text-[20px] leading-[24.5px] text-[#15415E]">
                                            Why Work Here
                                        </p>
                                        <div className="mt-[20px] flex flex-row gap-[20px] flex-wrap">
                                            {
                                                jobDescription?.aspectsOfJob.map(aspectsOfJobCode => {
                                                    return (
                                                        <div key={aspectsOfJobMappedWithCode[aspectsOfJobCode]?.id} className="bg-[#F4F7F8] py-[16px] px-[24px] rounded-[70px] font-[Gilroy-SemiBold] font-[400] text-[16px] leading-[19.6px] text-[#373737]">
                                                            {aspectsOfJobMappedWithCode[aspectsOfJobCode]?.label}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        {
                                            jobDescription?.aspectsOfJobOther && (
                                                <div className="mt-[30px] font-[Gilroy-Medium] font-[400] text-[16px] leading-[23px] text-[#373737]">
                                                    {jobDescription?.aspectsOfJobOther}
                                                </div>
                                            )
                                        }
                                    </div>
                                ) : (
                                    <>
                                        <div>
                                            <h1 className='text-instant-teams-teal-Main'>
                                                What's in it for you?
                                            </h1>
                                        </div>
                                        <p className="text-gray-600 text-sm mt-2 leading-relaxed">
                                            <div
                                                className="dangerously list-inside pl-0  ml-0"
                                                dangerouslySetInnerHTML={sanitizeMarkup(
                                                    jobDescription?.whatsInItForYou,
                                                )}
                                            ></div>
                                        </p>
                                    </>
                                )
                        }


                        {
                            // jobDescription?.dayInLife === null
                            jobDescription?.aboutThisJob && jobDescription?.aboutThisJob.length > 0
                                ? (
                                    <div className="flex flex-col mt-[30px]">
                                        <p className="font-[Gilroy-SemiBold] font-[400] text-[20px] leading-[24.5px] text-[#15415E]">
                                            Instant Job Insights
                                        </p>
                                        <div className='w-2/3 pl-[10px]'>
                                            {jobDescription?.aboutThisJob.map((option) => {
                                                return (
                                                    <SliderWithCheckbox
                                                        key={option.code}
                                                        option={option}
                                                        value={option.value}
                                                        isChecked={true}
                                                        onSelectionChange={() => { }}
                                                        isViewOnly={true}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {jobDescription?.dayInLife && <div className="mt-4">
                                            <h3 className="text-instant-teams-teal-Main mb-2">A Day in the Life</h3>
                                            <p className="text-gray-500 text-sm">
                                                <div
                                                    className="dangerously text-sm"
                                                    dangerouslySetInnerHTML={sanitizeMarkup(
                                                        jobDescription?.dayInLife
                                                    )}
                                                ></div>

                                            </p>
                                        </div>}
                                    </>
                                )
                        }


                        {
                            jobDescription?.mustHaves === null
                                ? (
                                    <div className="flex flex-col mt-[30px]">
                                        <p className="font-[Gilroy-SemiBold] font-[400] text-[20px] leading-[24.5px] text-[#15415E]">
                                            Must Haves for Success
                                        </p>

                                        <div className='mt-[20px] flex flex-row gap-[20px] flex-wrap justify-start'>
                                            {
                                                jobDescription?.mustHavesCheckboxes.map(mustHavesCode => {
                                                    return (
                                                        <div key={mustHavesCode}>
                                                            <img
                                                                src={`/images/must-haves-images/${mustHavesCode}.png`}
                                                                alt="Image"
                                                                className="w-[150px] h-auto"
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {jobDescription?.mustHaves && <div className="mt-4">
                                            <h3 className="text-instant-teams-teal-Main mb-2">Must Haves/What You Bring to the Table</h3>
                                            <p className="text-gray-500 text-sm">
                                                <div
                                                    className="dangerously"
                                                    dangerouslySetInnerHTML={sanitizeMarkup(
                                                        jobDescription?.mustHaves
                                                    )}
                                                ></div>

                                            </p>
                                        </div>}
                                    </>
                                )
                        }


                        {
                            payRate.commission && <>
                                <div ref={commissionRef} className='mt-5'>
                                    <h1 className='text-instant-teams-teal-Main mb-2'>
                                        Commission
                                    </h1>
                                </div>
                                <p className="text-gray-500 text-sm">
                                    <div
                                        className="dangerously"
                                        dangerouslySetInnerHTML={sanitizeMarkup(
                                            payRate.commission
                                        )}
                                    ></div>

                                </p>

                            </>
                        }


                    </div >
                </>
            ) : (
                <div className='flex items-center justify-center  h-full  '><h1 className='text-2xl text-gray-400 '> Select a Job</h1></div>
            )}

            {
                isShowing && <DialogWrapper
                    displayCloseIcon={true}
                    onClose={toggleShow}
                >

                    <div className="w-full flex justify-between items-start" >
                        <h1 className='text-instant-teams-blue-Main text-xl text-center font-semibold'>{'        '}</h1>
                        <h1 className='text-instant-teams-blue-Main text-xl text-center font-semibold'>Apply Now</h1>
                        <div
                            className="flex  justify-center items-center w-7 h-7 bg-gray-200 rounded-full cursor-pointer"
                            onClick={toggleShow}
                        >
                            <FontAwesomeIcon icon={faTimes} color="#565656" />
                        </div>
                    </div>
                    <div className='p-3 items-center mt-2  '>

                        <p className='text-[14px]  flex items-center text-gray-600 '>
                            You will be redirected to an external website in a new tab.  Please ensure that your popup blocker settings allow the site to open.
                        </p>
                        <p className='text-[14px] mt-4  flex items-center text-gray-600 '>
                            Please also note that the external website may have different privacy policies from the Instant Teams Marketplace.

                        </p>
                        <div className='w-full flex justify-center items-center '>
                            <button
                                onClick={handleApplyJobs}
                                className={`py-2 px-8 text-white mt-8 items-center font-medium rounded-full w-[200px] h-12 ${false ? "bg-gray-400" : "bg-[#15415E]"
                                    }`}

                            >
                                {loading ? "..." : "Continue"}
                            </button>
                        </div>



                    </div>


                </DialogWrapper>
            }
        </div >
    );
};

export default JobsDetails;
