import { Alert } from "../../dataTypes/Alert";

const AlertDisplay = ({ alerts }: { alerts: Array<Alert> }) => {
  return (
    <div>
      {alerts &&
        alerts.length > 0 &&
        alerts?.map((alert: Alert) => {
          return (
            <div className="border-b-2 pb-4 mb-4">
              <div className="uppercase mb-2 w-full ">{alert.title}</div>
              <div className="ml-4">{alert.body}</div>
            </div>
          );
        })}
    </div>
  );
};

export default AlertDisplay;
