import { Outlet, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../providers/auth";
import { requireAuth } from "../../../providers/auth";
import SeekerInfo from "../../seeker/details/parts/seekerInfo";
import { useApplication } from "../../../data/applications";
import { useJobDescription } from "../../../data/jobDescriptions";
import { useEmployer } from "../../../data/employer";
import { useMeetingsOneOnOne } from "../../../data/meetings";
import ScheduleMeetingActions from "../../live/ScheduleMeetingActions";
import useModal from "../../../hooks/useModal";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../providers/database";
import { collectionNames } from "../../../data/dictionary/collectionNames";
import { deleteApplicationRecursively } from "../../../callable-cloud-functions/cloudFunctions";
function JobApplicantPage() {
  const navigate = useNavigate();
  const params = useParams();
  const { applicationId = "" } = params ?? {};
  const { data: jobApplicationData, status: jobApplicationDataStatus } =
    useApplication(applicationId);

  const { data: employerData } = useEmployer(jobApplicationData?.employer);
  const { data: jobDescriptionData } = useJobDescription(
    jobApplicationData?.jobId,
  );

  const { user: authUser, userClaims } = useAuth();

  const { data: meetingData = [], status: meetingQueryStatus } =
    useMeetingsOneOnOne({
      refId: applicationId,
      refType: "application-meeting",
      invitedBy: authUser?.id,
      inviteeId: jobApplicationData?.resumeId,
    });

  /**
   * only show scheduleInterview if status is one of the following
   * ready, underReview, bench, presented, conversation
   */
  const statusAllowsInterview = [
    "ready",
    "underReview",
    "bench",
    "presented",
    "conversation",
  ];

  /**
   * if there is an active meeting don't show the schedule button here
   * it gets shown in the meeting item after it is scheduled
   *
   * if the application status is not one of the status' that allows interviews then dont show
   */
  const showScheduleActionButton =
    meetingData.length === 0 &&
    statusAllowsInterview.find(
      (status) => status === jobApplicationData?.status,
    );
  const { isShowing, toggle } = useModal();
  const handleDeleteApplication = async () => {
    await deleteApplicationRecursively(applicationId);
    navigate("/jobs");
  };
  return (
    <div>
      {jobApplicationDataStatus === "success" && (
        <div className="flex flex-row">
          <div className="w-3/4 mb-12">
            <SeekerInfo
              imageVisible={true}
              userId={jobApplicationData?.resumeId}
            />
          </div>
          <div className="w-1/4 mb-12 mx-auto my-auto">
            {jobApplicationData?.status === "knockout" &&
            (userClaims?.claims?.role === "sadmin" ||
              userClaims?.claims?.role === "tac") ? (
              <button
                onClick={toggle}
                className=" bg-instant-teams-blue-Main uppercase text-white hover:bg-instant-teams-blue-D1 w-full rounded-lg h-10"
              >
                Delete Application
              </button>
            ) : null}
            {showScheduleActionButton && (
              <ScheduleMeetingActions
                refType="application-meeting" // different types of meetings assoc w/ reftypes
                refId={applicationId}
                inviteeId={jobApplicationData?.resumeId}
                title={`${employerData?.displayName} - ${jobDescriptionData?.title}`}
                extended={{
                  employerId: jobApplicationData?.employer,
                  jobId: jobApplicationData?.jobId,
                }}
              />
            )}
          </div>
        </div>
      )}
      {isShowing ? (
        <DialogWrapper title="Delete Application" onClose={toggle}>
          <>
            <p className="pb-6 mt-2">
              Are you certain you want to delete this application?
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={handleDeleteApplication}
                className="py-2 px-10 text-white bg-instant-teams-blue-Main rounded border-0 hover:border  hover:bg-instant-teams-teal-Main  focus:outline-none"
              >
                {" "}
                Yes
              </button>
              <button
                onClick={toggle}
                className="py-2 px-10  rounded hover:text-white  hover:border  hover:bg-instant-teams-teal-Main border border-instant-teams-blue-Main bg-white text-instant-teams-blue-Main focus:outline-none"
              >
                No
              </button>
            </div>
          </>
        </DialogWrapper>
      ) : null}
      <Outlet />
    </div>
  );
}

export default requireAuth(JobApplicantPage);
