const userTypes = { E: "Employer", A: "Internal", S: "Career Seeker" };
const userRoles = {
  admin: "Administrator",
  // csss: "Career Seeker Support Specialist",
  finance: "Finance",
  general: "Instant Teams",
  learn: "Learning & Development Team",
  marketing: "Marketing Team",
  pops: "People Operations",
  sadmin: "Super Admin",
  sales: "Sales Team",
  tac: "Talent Aquisition Coordinator",
  BILL: "Billing",
  GEN: "General",
  PRI: "Account Owner",
  COMM: "Community Member",
  RTM: "Remote Team Member",
  SKR: "Career Seeker",
  oneOnOne: "1:1",
};

const jobStatus = {
  approvedByCustomer: "Approved By Customer",
  archived: "Archived",
  awaiting: "Awaiting Approval",
  cancelled: "Cancelled",
  draft: "Draft Copy",
  draft_approval: "Awaiting Draft Approval",
  filled: "Positions Filled",
  live: "Live",
  on_hold: "On Hold",
  tac_review: "TAC Review",
  working: "Employer Working",
};

const employerStatus = {
  active: "Active",
  closed: "Closed Account",
  dormant: "Dormant Account",
  invited: "Invited",
  lead: "Sales Working",
  removed: "Account Removed",
  under_review: "Under Review",
};

const skillLevel = {
  level1: "Basic",
  level2: "Intermediate",
  level3: "Advanced",
};

const applicationStatus = {
  applied: "Application Started",
  bench: "Recruiting Interview",
  closed: "Closed",
  conversation: "Conversation",
  decline: "Denied",
  declined: "Declined",
  invited: "Invited",
  knockout: "Failed Pre-screen",
  offered: "Offer Pending",
  preOffer: "Pre-Offer",
  presented: "Fit",
  ready: "Application Submitted",
  serviceApproved: "Service Approved",
  serviceReview: "Service Review",
  topCandidate: "Top Candidate",
  underReview: "Under Review",
  notSelected: "Hiring Closed",
  ineligible: "Failed Assessment",
};

const solutionType = {
  BPO: "BPO",
  DH: "Direct Hire",
};

const offerType = {
  pending: "Active",
  preparation: "Preparation",
  hired: "HIRE - Seeker Accepted",
  cancelled: "Employer Cancelled",
  rejected: "Seeker Rejected",
  error: "Incomplete Offer Letter",
};

const employmentType = {
  cause: "Terminate for Cause",
  closed: "Employment Closed",
  mutual: "End By Mutual Agreement",
  nonStart: "Non-Start",
  pending: "Pending Start",
  prejudice: "Terminate with Prejudice",
  resignationCause: "Resignation for Cause",
  resigned: "Resigned",
  started: "Started",
  voluntary: "Voluntary Resignation",
  withoutPrejudice: "Termination Without Prejudice",
};

const jobState = {
  draft: "Draft",
  live: "Live",
  on_hold: "On Hold",
  closed_filled: "Closed (Filled)",
  closed_cancelled: "Closed (Cancelled)",
};

const solutionTypeNew = {
  job_board: "Job Board",
  bpo: "BPO",
};

export function getLabel(label, group) {
  let types = "";
  if (group === "userTypes") {
    types = userTypes;
  } else if (group === "userRoles") {
    types = userRoles;
  } else if (group === "jobStatus") {
    types = jobStatus;
  } else if (group === "employerStatus") {
    types = employerStatus;
  } else if (group === "skillLevel") {
    types = skillLevel;
  } else if (group === "applicationStatus") {
    types = applicationStatus;
  } else if (group === "solutionType") {
    types = solutionType;
  } else if (group === "offerStatus") {
    types = offerType;
  } else if (group === "employmentStatus") {
    types = employmentType;
  }
  return types[label];
}

export function getLabelNew(label, group) {
  let types = "";

  if (group === "jobState") {
    types = jobState;
  } else if (group === "solutionType") {
    types = solutionTypeNew;
  }
  return types[label];
}

export function getJobStatesForJobNewStatusUpdate(currentStatus) {
  const jobStates = { ...jobState };
  delete jobStates.closed_cancelled;
  delete jobStates.closed_filled;

  jobStates.closed = "Closed";

  if (currentStatus === "draft") {
    // can change status to live only
    delete jobStates.on_hold;
    delete jobStates.closed;
  } else if (currentStatus === "live") {
    // can change status to on_hold & closed
    delete jobStates.draft;
  } else if (currentStatus === "on_hold") {
    // can change status to draft, closed & live
  } else if (
    ["closed", "closed_filled", "closed_cancelled"].includes(currentStatus)
  ) {
    // cannot change status
    delete jobStates.live;
    delete jobStates.draft;
    delete jobStates.on_hold;
    delete jobStates.closed;
  }

  const jobStatesArray = Object.entries(jobStates).map(([id, label]) => ({
    id,
    code: id,
    label,
  }));

  return jobStatesArray;
}

export function getClosedTypes() {
  return [
    {
      id: "closed_filled",
      code: "closed_filled",
      label: "Filled",
    },
    {
      id: "closed_cancelled",
      code: "closed_cancelled",
      label: "Cancel",
    },
  ];
}

export function getClosedFilledByTypes() {
  return [
    {
      id: "instant_teams",
      code: "instant_teams",
      label: "Instant Teams",
    },
    {
      id: "others",
      code: "others",
      label: "Filled via Other",
    },
  ];
}

export function getJobStateLabelFromJobState(jobStateText) {
  return jobState[jobStateText];
}
