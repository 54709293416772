import React from "react";
import Tooltip from "../tooltip/tooltip";
function SendEmail({
  email,
  subject = "Subject of the email",
  body = "Hello User",
}: {
  email: string;
  subject?: string;
  body?: string;
}) {
  const emailDisplay = (email: string) => {
    let formattedEmail = email;
    if (email?.length > 25) {
      const atSymbolIndex = email?.indexOf("@");
      formattedEmail =
        email?.substring(0, atSymbolIndex) +
        "\n" +
        email?.substring(atSymbolIndex);
    }
    return formattedEmail;
  };

  return (
    <Tooltip text="Open email client">
      <a
        id="open-email-anchor-tag"
        href={`mailto:${email}?subject=${encodeURIComponent(
          subject,
        )}&body=${encodeURIComponent(body)}`}
      >
        {emailDisplay(email) ?? "No email"}
      </a>
    </Tooltip>
  );
}

export default SendEmail;
