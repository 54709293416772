import React, { useState } from "react";
import ReauthModalNew from "../../auth/ReauthModalNew";
import ToastAlert from "../../custom-ui/toast-alert/ToastAlert";
import { toast } from "react-hot-toast";
import SettingsGeneral from "./SettingsGeneral";

import { useAuth } from "../../../providers/auth";
import SeekerSettingsGeneralNew from "./SeekerSettingsGeneralNew";

function SectionSettings(props) {
  const { user, userClaims } = useAuth();

  // State to control whether we show a re-authentication flow
  // Required by some security sensitive actions, such as changing password.
  const [reauthState, setReauthState] = useState({
    show: false,
  });

  const validSections = {
    general: true,
    password: true,
    mfa: true,
    billing: true,
  };

  const section = validSections[props.section] ? props.section : "general";

  // Handle status of type "success", "error", or "requires-recent-login"
  // We don't treat "requires-recent-login" as an error as we handle it
  // gracefully by taking the user through a re-authentication flow.
  const handleStatus = ({ type, code, message, callback }) => {
    if (code === "auth/requires-recent-login") {
      // Update state to show re-authentication modal
      setReauthState({
        show: true,
        callback,
        // Failed action to try again after reauth
      });
    } else {
      if (type === "success") {
        toast.success(message);
      } else {
        toast.error(message);
      }
    }
  };

  return (
    <>
      <section className=" px-4">
        {reauthState.show && (
          <ReauthModalNew
            callback={reauthState.callback}
            provider={user?.providers[0]}
            onDone={() => setReauthState({ show: false })}
          />
        )}
        {userClaims?.claims?.type === "S" ? (
          <div className="mx-auto pt-36">
            {<SeekerSettingsGeneralNew onStatus={handleStatus} />}
          </div>
        ) : (
          <div className="mx-auto pt-36">
            {<SettingsGeneral onStatus={handleStatus} />}
          </div>
        )}
        <ToastAlert />
      </section>
    </>
  );
}

export default SectionSettings;
