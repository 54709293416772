import React, { useEffect, useState } from "react";
import { collectionCounts } from "../../../callable-cloud-functions/cloudFunctions";


function GlobalCounts() {
  const [values, setValues] = useState();
  const [collectionNames, setCollectionNames] = useState([
    "Employer",
    "Job Descriptions",
    "Invitations",
    "Email",
  ]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await collectionCounts({
        collections: collectionNames,
      });
      setValues(data);
    }
    fetchData();
  }, [collectionNames]);

  return (
    <div className="w-full  p-3 bg-[#E6F1FC] rounded-lg shadow-lg">
      <div className="flex flex-wrap justify-left text-xl font-extrabold text-[#1E596D]  items-center ">
       Collection Counts
      </div>
      <div className="border-b mt-3 border-gray-300">

      </div>
      <div className="w-full mt-1">
        {values &&
          values.map((value, index) => {
            return (
              <div
                key={index}
                // className="flex justify-between border-b border-gray-300 py-2"
                className="flex justify-between  text-[#1E596D] py-2"
              >
                <span>{value.collection}</span>
                <span className="font-medium">{value.count}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default GlobalCounts;
