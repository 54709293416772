import { useJobInterviewQuestions } from "../../../data/jobDescriptions";
import "../../../styles/dangerouslySet.css";
import { useState } from "react";
function ModalInterviewItem({
  application,
  returnItem,
}: {
  application: any;
  returnItem: Function;
}) {
  const { data: interviewQuestionData } = useJobInterviewQuestions(
    application.jobId,
  );

  const [active, setActive] = useState("");

  const styling = {
    active:
      " text-white bg-instant-teams-teal-Main uppercase font-semibold shadow-input-field rounded-lg py-3 my-5 w-full text-center cursor-pointer hover:bg-instant-teams-teal-Main hover:text-white ",
    inactive:
      " text-instant-teams-teal-Main uppercase font-semibold shadow-input-field rounded-lg py-3 my-5 w-full text-center cursor-pointer hover:bg-instant-teams-teal-Main hover:text-white",
  };

  function handleSelectItem(item: any) {
    setActive(item?.id);
    returnItem(item);
  }
  return (
    <>
      <div className=" col-span-3  ml-10 p-4 my-5 rounded-lg">
        {interviewQuestionData &&
          interviewQuestionData?.map((question: any, index: string) => {
            return (
              <div
                onClick={() => handleSelectItem(question)}
                className={
                  active === question.id ? styling?.active : styling?.inactive
                }
                key={question?.id}
              >
                <p>Question {index + 1}</p>
              </div>
            );
          })}
      </div>
    </>
  );
}
export default ModalInterviewItem;
