import React, { useEffect, useState, useCallback } from "react";
import Meta from "../../components/custom-ui/meta/Meta";
import { requireAuth } from "../../providers/auth";
import CohortSideBar from "./CohortSideBar";
import { RefinementList, Configure } from "react-instantsearch-hooks-web";
import useRoleGuard from "../../hooks/useRoleGuard";
import { useLocation, useNavigate } from "react-router-dom";
import useModal from "../../hooks/useModal";
import InviteCohortList from "./InviteCohortList";
import GenCSVDownload from "./GenerateCSVFile/GenerateCSVFile";
import { generateFilters as originalGenerateFilters } from "./UtilFileForCohort/generateFilter";
import { useAllListingsToInviteJob } from "../../data/listings";
import CustomHits from "./CustomHits/CustomHits";
import PaginationControls from "./PaginationControls/CustomPagination";
import ToastAlert from "../../components/custom-ui/toast-alert/ToastAlert";

function CohortManagementPage() {
  useRoleGuard();

  const jobListingData = useAllListingsToInviteJob();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [affiliation, setAffiliation] = useState({ milVerified: false, milSpouse: false });
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [pageIndex, setPageIndex] = useState({
    startIndex: 0,
    endIndex: 0
  });

  const [allHits, setAllHits] = useState([]);
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const location = useLocation();
  const { skills, tacVerified, exactMatch } = location.state || {};
  const navigate = useNavigate();
  const [filters, setFilters] = useState();

  const itemsPerPage = 50;

  useEffect(() => {
    const dataList = allHits.map((data: any) => ({
      ...data,
      isChecked: selectedUsers.some((user: any) => user.objectID === data.objectID),
    }));
    setUserList(dataList);
  }, [allHits, selectedUsers]);

  const generateFilters = useCallback(
    () => originalGenerateFilters(skills, tacVerified, exactMatch, selectedOptions, affiliation),
    [skills, tacVerified, exactMatch, selectedOptions, affiliation]
  );

  useEffect(() => {
    setFilters(generateFilters());
  }, [generateFilters]);

  const { isShowing: showModal, toggle: toggleModal } = useModal();

  const handleSelectAll = (e: any) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageUsers = userList.slice(startIndex, endIndex);

    if (e.target.checked) {
      setSelectedUsers((prevSelectedUsers: any) => [
        ...prevSelectedUsers,
        ...currentPageUsers.filter(
          (user: any) => !prevSelectedUsers.some((selectedUser: any) => selectedUser.objectID === user.objectID)
        ),
      ]);
      setUserList((prevUserList: any) =>
        prevUserList.map((user: any) =>
          currentPageUsers.some((currentUser: any) => currentUser.objectID === user.objectID)
            ? { ...user, isChecked: true }
            : user
        )
      );
    } else {
      setSelectedUsers((prevSelectedUsers: any) =>
        prevSelectedUsers.filter(
          (selectedUser: any) =>
            !currentPageUsers.some((currentUser: any) => currentUser.objectID === selectedUser.objectID)
        )
      );
      setUserList((prevUserList: any) =>
        prevUserList.map((user: any) =>
          currentPageUsers.some((currentUser: any) => currentUser.objectID === user.objectID)
            ? { ...user, isChecked: false }
            : user
        )
      );
    }
  };

  const handleSelectUser = (user: any) => {
    setSelectedUsers((prevSelectedUsers: any) => {
      const newSelectedUsers = prevSelectedUsers.some((selectedUser: any) => selectedUser.objectID === user.objectID)
        ? prevSelectedUsers.filter((selectedUser: any) => selectedUser.objectID !== user.objectID)
        : [...prevSelectedUsers, user];
      return newSelectedUsers;
    });

    setUserList((prevUserList: any) =>
      prevUserList.map((u: any) =>
        u.objectID === user.objectID ? { ...u, isChecked: !u.isChecked } : u
      )
    );
  };

  const totalPages = Math.ceil(userList.length / itemsPerPage);

  return (
    <>
      <div className=" w-full fixed h-screen mb-1">
        <Meta title="User Management" />
        <div className="flex pt-36 w-full bg-gray-200 h-full">
          <CohortSideBar
            skills={skills}
            tacVerified={tacVerified}
            exactMatch={exactMatch}
            affiliation={affiliation}
            setAffiliation={setAffiliation}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            className="h-full overflow-y-auto hide-scrollbar"
          />
          <div className="basis-5/6 flex flex-col">
            <div className="flex fix justify-between w-full bg-gray-200">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  onChange={handleSelectAll}
                  checked={
                    selectedUsers.filter(
                      (user: any) =>
                        userList
                          .slice(
                            (currentPage - 1) * itemsPerPage,
                            currentPage * itemsPerPage
                          )
                          .some(
                            (pageUser: any) => pageUser.objectID === user.objectID
                          )
                    ).length ===
                    Math.min(itemsPerPage, userList.length - (currentPage - 1) * itemsPerPage) &&
                    userList.length > 0
                  }
                />
                <span>
                  Select{" "}
                  {Math.min(
                    itemsPerPage,
                    userList.length - (currentPage - 1) * itemsPerPage
                  )}{" "}
                  users
                </span>

              </div>
              <div className="flex justify-end items-center space-x-2 mr-5">
                <div>

                  <span className="mr-5" >

                    Total seekers found: {userList.length}

                  </span>
                </div>

                <button
                  className="bg-instant-teams-teal-Main text-white px-4 py-2 rounded"
                  onClick={toggleModal}
                >
                  Invite to Job
                </button>
                <button
                  onClick={() => {
                    navigate("/cohort");
                  }}
                  className="bg-instant-teams-teal-Main text-white px-4 py-2 rounded"
                >
                  Reset Cohort
                </button>
                <GenCSVDownload data={userList} />
              </div>
            </div>
            {showModal ? (
              <InviteCohortList
                toggleModal={toggleModal}
                jobListingData={jobListingData}
                inviteUserList={selectedUsers}
              />
            ) : null}

            <div
              id="user-list"
              className="mt-2 flex-grow overflow-y-auto "
            >
              <div hidden>
                <Configure filters={filters} hitsPerPage={10000} />
                <RefinementList
                  attribute="Skills.tac_verified_skills.skill"
                  filterOnly
                  operator="and"
                  transformItems={(items) =>
                    items.filter((item) => skills.includes(item.label))
                  }
                />
              </div>
              <CustomHits
                onSelect={handleSelectUser}
                selectedUsers={selectedUsers}
                userList={userList}
                setAllHits={setAllHits}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                setPageIndex={setPageIndex}
              />

              <div className="mb-4 mt-4 flex justify-center w-full items-center text-l">
                <div className="w-[42%] mb-5 flex justify-start items-center">
                  Showing {"  "}
                  {pageIndex.startIndex === 0
                    ? userList.length > 0 ? pageIndex.startIndex + 1 : 0
                    : pageIndex.startIndex + 1}{"  "}
                  - {userList.length > 50 ? pageIndex.endIndex : userList.length} out of {userList.length}
                </div>
                <div className={`w-[58%] justify-start items-center`}>
                  <PaginationControls
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <ToastAlert />
    </>
  );
}

export default requireAuth(CohortManagementPage);
