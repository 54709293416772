import { useState, useEffect } from 'react';
import GreenUnderlineHeading from "../../../custom-ui/headings/heading";
import SortableList from './sortable-list';
import { useAllEmployerTrainings } from '../../../../data/employer';
import { collectionNames, subCollectionNames } from '../../../../data/dictionary/collectionNames';
// @ts-ignore 
import TrainingDetailsItem from './TrainingDetailsItem';

function TrainingList({employerId}:{employerId: string}) {
  const [trigger, setTrigger] = useState(true);
  const [trainingId, setTrainingId] = useState(null);
  const [training, setTraining] = useState(null);
  const { data: trainings } = useAllEmployerTrainings(employerId);

  return (
    <div className='w-full'>
      <div className='grid grid-cols-3 gap-2'>
        <div className="col-span-1">
        <GreenUnderlineHeading title={"Cycles"}/>
          <SortableList 
            list={trainings}
            displayField={"title"}
            collection={collectionNames.employers}
            parentId={employerId}
            subcollection={subCollectionNames.training}
            callback={setTrainingId}
            returnItem={setTraining}
          />
        </div>
        <div className="col-span-2 ml-10">
          { trainingId !== null &&
            <TrainingDetailsItem key={trainingId} id={trainingId} employerId={employerId} readOnly={false} data={training} /> 
          }
        </div>
      </div>     
    </div>
  );
}

export default TrainingList;
