import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const SocialButtons = ({ socialData }) => {
  const cpWebURL = socialData?.url;
  const twitterURL = socialData?.twitter;
  const facebookURL = socialData?.facebook;
  const linkedInURL = socialData?.linkedin;

  const handleButtonClick = (url) => {
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className="flex space-x-4 p-4 rounded-full">
      {/* Website Button */}
   { cpWebURL&&  <button
        onClick={() => handleButtonClick(cpWebURL)}
        className="flex items-center space-x-2 px-4 py-2 text-[#15415E] bg-gray-100 rounded-full shadow hover:bg-gray-100"
      >
        <span className="text-gray-700 font-medium">Website</span>
        <FontAwesomeIcon icon={faExternalLinkAlt} className="text-gray-700" />
      </button>}

      {/* Twitter Button */}
   {  twitterURL&& <button
        onClick={() => handleButtonClick(twitterURL)}
        className="w-10 h-10 flex items-center justify-center text-[#15415E] bg-gray-100 rounded-full shadow hover:bg-gray-100"
      >
        <img className='w-3 h-3' src='https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/x-social-media-black-icon.png'/>
        {/* <FontAwesomeIcon icon={faTwitter} className="text-gray-700 text-lg" /> */}
      </button>}

      {/* Facebook Button */}
    { facebookURL&& <button
        onClick={() => handleButtonClick(facebookURL)}
        className="w-10 h-10 flex items-center justify-center text-[#15415E] bg-gray-100 rounded-full shadow hover:bg-gray-100"
      >
        <FontAwesomeIcon icon={faFacebookF} className="text-gray-700 text-lg" />
      </button>}

      {/* LinkedIn Button */}
 { linkedInURL&&    <button
        onClick={() => handleButtonClick(linkedInURL)}
        className="w-10 h-10 flex items-center justify-center text-[#15415E] bg-gray-100 rounded-full shadow hover:bg-gray-100"
      >
        <FontAwesomeIcon icon={faLinkedinIn} className="text-gray-700 text-lg" />
      </button>}
    </div>
  );
};

export default SocialButtons;
