const TechnicalSupportSvg = ({ className = "" }: { className?: string }) => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    className={className}
  >
    <path d="M22.2492 20.628C21.4572 19.9002 20.5151 19.5849 19.4352 19.8189C18.8029 19.9558 18.2598 20.2806 17.741 20.6562C17.1118 21.1116 16.6172 21.0881 16.0084 20.6092C13.5246 18.6529 11.3069 16.4312 9.34978 13.9506C8.93034 13.4193 8.9147 12.9059 9.29893 12.337C9.47343 12.0788 9.65733 11.8237 9.80444 11.5498C10.5447 10.1803 10.3648 8.79992 9.28798 7.67541C8.68699 7.04703 8.07191 6.43039 7.44196 5.83175C6.10538 4.56091 3.96201 4.56951 2.62778 5.83958C2.28815 6.16276 1.95871 6.49691 1.6316 6.8334C0.95862 7.52595 0.485187 8.33744 0.226166 9.26632C0.129914 9.6122 0.0696589 9.96747 -0.0078125 10.3188V11.4903C0.040705 11.7164 0.0899927 11.9426 0.136163 12.1695C0.33884 13.1681 0.885849 14.0015 1.42111 14.8411C5.18277 20.7509 10.0799 25.4993 16.0726 29.1295C16.7917 29.5654 17.5649 29.8471 18.4014 29.9512C18.4679 29.959 18.5321 29.9848 18.5971 30.0021H19.7122C19.7771 29.9841 19.8405 29.9582 19.9063 29.9496C20.814 29.833 21.6834 29.5623 22.3963 28.9965C23.1186 28.4229 23.8252 27.7984 24.4137 27.0934C25.4858 25.8084 25.4028 23.8771 24.283 22.6548C23.6366 21.9497 22.9527 21.2752 22.2484 20.628H22.2492ZM22.5418 25.6965C22.1341 26.1418 21.7014 26.573 21.235 26.9548C20.634 27.4463 19.9102 27.6466 19.1683 27.6599C18.475 27.6474 17.863 27.4721 17.298 27.1309C11.3108 23.5132 6.50369 18.717 2.87818 12.7424C2.01661 11.322 2.19581 9.66854 3.33128 8.44934C3.61064 8.14963 3.90332 7.86009 4.20069 7.57838C4.70777 7.0979 5.37997 7.09555 5.88158 7.58307C6.45675 8.14102 7.02252 8.70837 7.58204 9.28275C7.97253 9.68419 8.00382 9.97764 7.72601 10.4691C7.61568 10.6639 7.47953 10.8439 7.35119 11.0278C6.41215 12.3722 6.46065 14.0727 7.47091 15.3576C9.57672 18.0347 11.9713 20.4238 14.6515 22.528C15.9442 23.543 17.651 23.5837 19.0149 22.6321C19.2387 22.4764 19.4649 22.3206 19.7051 22.1915C20.0213 22.0217 20.3437 22.0546 20.6019 22.3034C21.2624 22.9412 21.926 23.5774 22.5528 24.248C22.9331 24.6542 22.9214 25.2826 22.5411 25.6973L22.5418 25.6965Z" />
    <path d="M29.9332 11.2668C29.5537 8.30409 28.3228 5.73814 26.1504 3.69571C22.3442 0.117158 17.8939 -0.837539 12.9146 0.722845C11.4082 1.19472 10.0646 1.99369 8.85318 3.00551C8.46504 3.32948 8.31322 3.74579 8.44938 4.23645C8.5785 4.70049 8.89934 4.98221 9.37121 5.0769C9.78987 5.16063 10.124 4.98456 10.4472 4.73336C13.5163 2.35053 16.9165 1.7198 20.6061 2.94604C25.443 4.55338 28.4417 9.6751 27.5418 14.6677C27.2053 16.5325 26.4408 18.1923 25.238 19.6564C24.7818 20.212 24.853 20.9218 25.3835 21.3482C25.8985 21.7622 26.6027 21.6777 27.0511 21.137C28.4096 19.4991 29.3229 17.6429 29.7509 15.559C29.8761 14.9494 29.9465 14.3296 30.0412 13.7138V12.0736C30.0044 11.8044 29.9669 11.536 29.9317 11.266L29.9332 11.2668Z" />
    <path d="M16.3123 13.7353C17.0847 14.51 17.8469 15.2956 18.6435 16.0445C18.8407 16.23 19.1459 16.3529 19.419 16.3959C19.8831 16.4695 20.3299 16.1713 20.5341 15.7472C20.7501 15.2972 20.6805 14.7987 20.3142 14.4215C19.7086 13.7986 19.0825 13.1945 18.4784 12.5701C18.3837 12.4722 18.3133 12.3056 18.3125 12.1694C18.3 10.8813 18.307 9.59328 18.3047 8.30522C18.3031 7.56337 17.8171 7.04063 17.1348 7.03907C16.4532 7.0375 15.961 7.5618 15.9586 8.29817C15.9563 9.04002 15.9586 9.78187 15.9586 10.5229C15.9586 11.2937 15.9618 12.0645 15.9571 12.8361C15.9547 13.1867 16.0666 13.488 16.3131 13.7353H16.3123Z" />
  </svg>
);

export default TechnicalSupportSvg;
