import Meta from "../../../components/custom-ui/meta/Meta";
import { requireAuth } from "../../../providers/auth";

import { Outlet } from "react-router-dom";

import useKeepNavbarActiveTab from "../../../hooks/useKeepNavbarActiveTab";
function CompanyPage() {
  const landingPage = "overview";
  const topRoute = "company";
  useKeepNavbarActiveTab(landingPage, topRoute);
  return (
    <div className="pt-4">
      <Meta title="Company" />

      <div>
        <div className=" md:container pb-32 min-h-screen w-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default requireAuth(CompanyPage);
