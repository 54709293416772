import React from "react";

const TagDisplay = (tags) => {
  console.log(tags.tags)
  const list = tags.tags;
  return (
    <div className='flex flex-wrap items-start justify-center'>
      {list && list.length > 0 && list.map((tag) => (
        <span className="bg-instant-teams-teal-L1 rounded-full text-white text-xs py-1 px-2 m-1"  key={tag}>
          {tag}
        </span>
      ) ) }
    </div>
  );
};

export default TagDisplay;
