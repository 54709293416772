import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import InfoIcon from './InfoTextWithIcon';

const CardContainer = ({ cardTitle, children, daysSelected = 0,infoText, showInfo=true ,viewDown=false ,extraHeight=false ,bgColor=""}) => {

    return (
        <div  className={`${bgColor?"bg-[#E6F1FC]":"bg-[#ffffff] "} p-3 w-full ${extraHeight? "h-[335px]":"h-56"}  shadow-lg rounded-lg relative`}>
            <div className="flex items-center">
                <h1 className='text-xl text-[#1E596D] font-bold'>{cardTitle}</h1>
                {daysSelected != 0 && <p className="text-10px ml-[3px] text-[#3b7f96]">
                    {` (Last ${daysSelected} Days)`}
                </p>}
               {showInfo && <InfoIcon  viewDown={viewDown} infoText={infoText} tooltipText={infoText} />}
            </div>
            {children}

        </div>
    );
};

export default CardContainer;
