import { useState } from "react";
import { useAuth } from "../../../../providers/auth";
import { useForm } from "react-hook-form";
import { createInvitation } from "../../../../data/invitation";
import ForwardArrowButton from "../../../../components/custom-ui/button/ForwardArrowButton";
import { errorLogger } from "../../../../providers/clientLogger";
import useModal from "../../../../hooks/useModal";

// Enrollment should only happen once.
function BillingPOCForm({
  employer,
  onDone,
}: {
  employer: any;
  onDone: Function;
}) {
  const { user } = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [showForm, setShowForm] = useState(true);
  const { isShowing: isSameAsPOC, toggle: toggleSameAsPOC } = useModal();

  const {
    handleSubmit,
    register,
    errors,
    getValues,
    setValue,
    control,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      name: "",
      title: "",
      employerId: employer.id,
      company: employer.displayName,
      email: "",
      prefix: "",
      domain: employer.domain,
      sameAsPrimaryPOC: false,
      type: "E",
      invitedBy: user.id,
      employerRole: "BILL",
    },
  });

  const concatEmail = () => {
    const prefix = getValues("prefix");
    const domain = getValues("domain");
    setValue("email", `${prefix}@${domain}`);
  };

  // if sameAsPrimaryPOC display current user's information
  // otherwise send invitation to new user
  const onSubmit = (data: any) => {
    setPending(true);

    // exclude unecessary form data
    const { prefix, sameAsPrimaryPOC, ...invitationData } = data;

    // if creating new Billing POC an invitation must be sent to the new user.
    // if Billing POC is primary contact then proceed without saving.
    if (!data.sameAsPrimaryPOC) {
      createInvitation(invitationData)
        .then(() => {
          onDone();
          setPending(false);
        })
        .catch((error) => {
          setFormAlert(error);
          errorLogger(error.message, {
            component: "BillingPOCForm createInvitation()",
            flow: "Employer Onboarding",
            userId: user.id,
          });
          setPending(false);
        })
        .finally(() => setPending(false));
    } else {
      onDone();
    }
  };

  return (
    <>
      <h2 className="mb-4 uppercase text-left font-semibold text-instant-teams-blue-Main">
        Billing Point of Contact
      </h2>
      {/* <p>
        If you're not sure. You will have the option to update this contact.
      </p> */}
      {formAlert && (
        <div className="mb-4 text-red-600">{formAlert.message}</div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2">
          <div className="flex justify-start">
            <label className="mb-1">
              <input
                className="bg-white mr-1 rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                name="sameAsPrimaryPOC"
                type="checkbox"
                ref={register({})}
                onChange={toggleSameAsPOC}
              />
              Same as Primary Point of Contact
            </label>
          </div>
        </div>

        {!isSameAsPOC ? (
          <>
            <div className="mb-2">
              <input
                className="py-1 px-3 font-bold w-full leading-8 bg-gray-200 rounded border border-gray-300 outline-none"
                readOnly
                name="company"
                type="text"
                ref={register()}
              />
            </div>

            <div className="mb-2">
              <input
                className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                name="employerId"
                type="hidden"
                ref={register()}
              />
              <input
                className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                name="type"
                type="hidden"
                ref={register()}
              />
              <input
                className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                name="invitedBy"
                type="hidden"
                ref={register()}
              />
              <input
                className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                name="employerRole"
                type="hidden"
                ref={register()}
              />
              <input
                className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                name="type"
                type="hidden"
                ref={register()}
              />
              <input
                className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                name="name"
                type="text"
                placeholder="Full Name"
                ref={register({
                  // require value only if POC is false
                  validate: (value) => {
                    if (getValues().sameAsPrimaryPOC === true) return true;
                    if (value) return true;
                    return false;
                  },
                })}
              />
              {errors.name && (
                <p className="mt-1 text-sm text-left text-red-600">
                  Please enter the full name of the Billing Point of Contanct.
                </p>
              )}
            </div>

            <div className="mb-2">
              <input
                className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                name="title"
                type="text"
                placeholder="Title"
                ref={register({
                  validate: (value) => {
                    if (getValues().sameAsPrimaryPOC === true) return true;
                    if (value) return true;
                    return false;
                  },
                })}
              />
              {errors.title && (
                <p className="mt-1 text-sm text-left text-red-600">
                  Please enter the job title of the Billing POC
                </p>
              )}
            </div>

            <div className="mb-2">
              <div className="flex space-x-1">
                <input
                  className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                  name="prefix"
                  placeholder="Email prefix"
                  type="text"
                  onBlur={() => concatEmail()}
                  ref={register({
                    validate: (value) => {
                      if (getValues().sameAsPrimaryPOC === true) return true;
                      if (value) return true;
                      return false;
                    },
                  })}
                />

                <span className="py-1 font-bold text-lg">@</span>
                <input
                  className="py-1 px-3 w-full leading-8 font-bold bg-gray-200 rounded border border-gray-300 outline-none"
                  readOnly
                  name="domain"
                  type="text"
                  ref={register({})}
                />
                <input
                  className="py-1 px-3 leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                  name="email"
                  type="hidden"
                  ref={register({})}
                />
              </div>
              {errors.prefix && (
                <p className="mt-1 text-sm text-left text-red-600">
                  Please complete the email address
                </p>
              )}
            </div>
          </>
        ) : null}
        <ForwardArrowButton pending={pending} type="submit" />
      </form>
    </>
  );
}

export default BillingPOCForm;
