import {
  collection,
  addDoc,
  query,
  where,
  orderBy,
  doc,
} from "firebase/firestore";
import { db, createQuery } from "../providers/database";
import { useQuery } from "react-query";
/**** StatusHistory ****/

export function useAllStatusHistoryByCollectionAndId(
  id: string,
  collectionName: string,
  subcollectionName: string,
) {
  return useQuery(
    ["allStatusHistory", { id, collectionName, subcollectionName }],
    createQuery(() => {
      return query(
        collection(db, `${collectionName}/${id}/${subcollectionName}`),
        orderBy("effectiveDate", "desc"),
      );
    }),
    { enabled: !!id },
  );
}
