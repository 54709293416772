import { useUser } from "../../providers/database";
import { useAuth } from "../../providers/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Affiliations from "../user/details/parts/Affiliations";

import {
  useApplication,
  useApplicationStatusHistory,
} from "../../data/applications";

import { useLookup } from "../../data/lookup";
import { useState } from "react";
import { useEffect } from "react";
import { useJobPositionDetails } from "../../data/positions";
import { useEmployerLocation } from "../../data/employer";
import { DisplayDate } from "../../util/timestamps/timestamp";
import { isArray } from "lodash";
import { useFindOfferStatus } from "../../data/status";
import { Link } from "react-router-dom";
import Tooltip from "../custom-ui/tooltip/tooltip";
function ExternalOfferItem({ offer }: { offer: any }) {
  const [statusDate, setStatusDate] = useState("");
  const { user } = useAuth();

  const { data: applicant = {}, status: applicantQueryStatus } = useUser(
    offer.resumeId,
  );

  const { data: countries } = useLookup("countries");

  const seekerCountry = countries?.find(
    (country: any) => country?.code === applicant?.address?.country,
  );
  const { data: states } = useLookup("state");

  const seekerState = states?.find(
    (state: any) => state?.code === applicant?.address?.state,
  );

  const { data: jobPositionData } = useJobPositionDetails(
    offer?.jobId,
    offer?.positionId,
  );

  const { data: locationData } = useEmployerLocation(
    offer?.employerId,
    jobPositionData?.information?.locationId,
  );

  const { data: statusData = {}, status: statusQueryStatus } =
    useFindOfferStatus(offer?.status);

  const { data: applicationStatusHistory } = useApplicationStatusHistory(
    offer?.applicationId,
  );

  useEffect(() => {
    if (
      !isArray(applicationStatusHistory) ||
      applicationStatusHistory.length === 0
    ) {
      setStatusDate("Unchanged");
    } else {
      setStatusDate(DisplayDate(applicationStatusHistory[0]?.effectiveDate));
    }
  }, [applicationStatusHistory]);

  return (
    <>
      <div className="shadow-lg flex bg-white rounded-lg min-h-full">
        {applicantQueryStatus === "success" && (
          <div className="w-full mb-2 text-instant-teams-teal-Main text-center  rounded-lg">
            <Link
              to={`/offer-details/${offer?.objectID}/details`}
              target="_blank"
            >
              <div className=" h-52 bg-instant-teams-teal-Main pt-2 pb-4 item  rounded-t-lg ">
                <div className="flex justify-center">
                  <img
                    src={
                      applicant?.photoURL
                        ? applicant?.photoURL
                        : "/images/default_user.png"
                    }
                    className={`rounded-full h-12 w-12 border-solid border-4 my-2 ${
                      applicant?.status !== "active"
                        ? " border-instant-teams-red-Main"
                        : "border-instant-teams-green-D1"
                    }`}
                    alt={`${applicant?.name?.toUpperCase()}`}
                  />
                </div>
                <p className="uppercase text-white text-xl pb-2">
                  {applicant?.name}
                </p>
                <p className=" text-white text-sm pb-2">{applicant?.email}</p>
                <div className="flex flex-col items-center  ">
                  <div className=" space-x-1 w-full text-white mb-1 h-8  ">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    <span className="text-lg">
                      {seekerState
                        ? `${seekerState?.label}, ${seekerCountry?.label}`
                        : `${seekerCountry?.label}`}
                    </span>
                  </div>
                  <div className="flex font-black -mt-1 text-instant-teams-teal-D1 ">
                    <Affiliations
                      userId={offer.resumeId}
                      background={"darkBackground"}
                    />
                  </div>
                </div>
              </div>
            </Link>
            <div className="text-sm py-4 h-40 text-black">
              {offer?.awaitingEmployerSignature &&
              user.id === offer?.employerUserId ? (
                <a
                  href={offer?.embeddedSigningUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Tooltip text={"Click to sign document"}>
                    <p className="text-white bg-instant-teams-green-Main hover:bg-white hover:text-instant-teams-blue-Main hover:border hover:border-instant-teams-blue-Main mx-10 py-2 uppercase rounded-md mb-2">
                      Employer Signature Required
                    </p>
                  </Tooltip>
                </a>
              ) : (
                <p>
                  <Tooltip text={statusData?.description}>
                    <p className="text-white bg-instant-teams-blue-Main hover:bg-white hover:text-instant-teams-blue-Main hover:border hover:border-instant-teams-blue-Main mx-10 py-2 uppercase rounded-md mb-2">
                      {statusData?.label}
                    </p>
                  </Tooltip>
                </p>
              )}

              <p className="uppercase text-instant-teams-blue-D1 font-semibold">
                {jobPositionData?.positionName},
              </p>
              <p className="uppercase text-instant-teams-blue-D1 font-semibold">
                {locationData?.label}
              </p>
              <div className="py-4">
                <p className=" text-instant-teams-blue-D1 ">Offered:</p>
                <p className="">{statusDate}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ExternalOfferItem;
