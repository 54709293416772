import { useEffect, useState, memo } from "react";
import { sanitizeMarkup } from "../../../util/sanitize/sanitize-html";
import { fetchEarnedBadges } from "../../../callable-cloud-functions/cloudFunctions";
import { addSkill } from "../../../data/jobDescriptions";
import { useComponent } from "../../../data/academy";
import { useAuth } from "../../../providers/auth";
import "../../../styles/dangerouslySet.css";
import { getLevel } from '../../../util/assessments/level';

function ReqSkillDetails({ assessmentId, onDone }) {
  const { data: assessment } = useComponent(assessmentId);
  const [badgesEarned, setBadgesEarned] = useState({});

  useEffect(() => {
    async function fetchData() {
      const { data } = await fetchEarnedBadges({ id: assessmentId });
      setBadgesEarned(data);
    }
    fetchData();
  }, [assessmentId]);

  return (
    <div key={assessmentId} className="grid grid-cols-2 gap-2">
      <div className="items-center col-span-1">
        <img
          src={assessment?.badge || "/images/badge.png"}
          alt={assessment?.name}
          className="mx-auto w-24 max-h-24 rounded-xl"
        />
      </div>
      <div className="items-center rounded-b-lg relative col-span-1 mt-4">
        <p className="text-center text-instant-teams-teal-Main font-bold w-full text-xl">
          {assessment?.displayName || assessment?.name}
        </p>
        <p className="text-center w-full">
          {assessment?.duration || "??"} minutes
        </p>
        <p className="text-center w-full">
          Career Seekers Earned: {badgesEarned.total || 0}
        </p>
      </div>
      <div className="rounded-b-lg relative col-span-1 mt-4 px-4 items-center">
        <p className="w-full text-instant-teams-teal-Main text-center">
          Tier Minimum Scores
        </p>
        <p className="w-full text-center mt-5 mb-7 text-xl">
          {assessment?.tier.one}%
        </p>
        <p className="w-full text-center mt-3 mb-7 text-xl">
          {assessment?.tier.two}%
        </p>
        <p className="w-full text-center mb-7 mt-3 text-xl">
          {assessment?.tier.three}%
        </p>
      </div>
      <div className="rounded-b-lg relative col-span-1 mt-4 px-3 items-center">
        <p className="w-full text-instant-teams-teal-Main text-center ">
          Current Career Seeker Pool
        </p>
        {badgesEarned.total > 0 && (
          <>
            <p className="w-full text-center mt-5 mb-7 text-xl">
              {badgesEarned.one || 0}
            </p>
            <p className="w-full text-center mt-3 mb-7 text-xl">
              {badgesEarned.two || 0}
            </p>
            <p className="w-full text-center mt-3 mb-7 text-xl">
              {badgesEarned.three || 0}
            </p>
          </>
        )}
      </div>
      <div className="items-center col-span-2 mt-4 px-2">
        <p className="text-instant-teams-teal-Main font-bold w-full">
          Description
        </p>
      </div>
      {assessment?.description && (
        <div
          className="dangerously items-center col-span-2 px-2"
          dangerouslySetInnerHTML={sanitizeMarkup(assessment.description)}
        ></div>
      )}
    </div>
  );
}
export default ReqSkillDetails;
