import {
  useQuery,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import { doc, getDoc } from "firebase/firestore";
import { db, createQuery } from "../providers/database";
import { collectionNames } from "./dictionary/collectionNames";

export function useResetEmail(id: string) {
  return useQuery(
    ["ResetEmails", { id }],
    createQuery(() => doc(db, collectionNames.resetemails, id)),
    { enabled: !!id },
  );
}
