import { Link, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { useLookup } from "../../data/lookup";
import { LookUp } from "../../dataTypes/LookUp";
import { useEmployerLocation } from "../../data/employer";
import { getHrsPerWeek } from "../../util/helpers/getHours";
import { Timestamp } from "firebase/firestore";
import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";
import { DisplayDate } from "../../util/timestamps/timestamp";
import { useParams } from "react-router-dom";
import { useEmployerTraining, useEmployer } from "../../data/employer";
import { useEffect } from "react";

function PositionItem({ position }: { position: any }) {
  const location = useLocation();
  const { data: modelLookup = [] } = useLookup("model");
  const { data: termLookup = [] } = useLookup("term");
  const { data: classificationLookup = [] } = useLookup("classification");
  const { data: payTypeLookup = [] } = useLookup("payType");
  const { data: shiftOptionLookup = [] } = useLookup("shiftOption");
  const { data: trainingData = [] } = useEmployerTraining(
    position?.employer,
    position?.cycle,
  );
  const { data: employerData = [] } = useEmployer(position?.employer);

  const term = termLookup.find(
    (item: LookUp) => item.code === position?.information?.term,
  );

  const workSetting = modelLookup.find(
    (item: LookUp) => item.code === position?.information?.model,
  );

  const workType = classificationLookup.find(
    (item: LookUp) => item.code === position?.information?.classification,
  );

  const shiftOption = shiftOptionLookup.find(
    (item: LookUp) => item.code === position?.shiftSchedule?.shiftOption?.code,
  );

  const payType = payTypeLookup.find(
    (item: LookUp) => item.code === position?.information?.payType?.type,
  );

  const payTypeRange =
    position?.information?.payType?.[
      Object.keys(position?.information?.payType ?? {})[0]
    ] ?? [];

  const { data: positionLocation } = useEmployerLocation(
    position?.employer,
    position?.information?.locationId,
  );
  const shiftScheduleDays = position?.shiftSchedule?.days ?? [
    Array.from(Array(5).keys()), // default if no value found. creates an array of 5 elements. We use the count in the array to determine hours per week
  ];
  const shiftScheduleRange = position?.shiftSchedule?.range ?? [540, 1020]; // default to 9am to 5pm if no values found. Represents minutes past midnight.

  return (
    <div
      className={
        TailwindHoverEnlarge(103) + "w-full rounded-xl shadow-lg bg-white"
      }
    >
      <Link to={`${location.pathname}/${position.id}`}>
        <div
          className={`col-span-2 uppercase block text-center text-white font-semibold ${
            position.active
              ? "bg-instant-teams-blue-Main"
              : "bg-instant-teams-gray-D1"
          } rounded-t-xl px-3 py-2`}
        >
          {position?.positionName}
          <p className="-mt-1 px-1 text-xs font-normal">
            {positionLocation?.label || "No Location Assigned"}
          </p>
          <p className="font-normal text-xs">
            {DisplayDate(position.createdAt)}
          </p>
        </div>
        <div className="grid space-x-3 sm:grid-cols-1 md:grid-cols-1 grid-cols-2 place-content-center p-4">
          <div className="text-center">
            <div className="mb-2">
              <p className="px-1 font-semibold text-instant-teams-blue-Main text-xs">
                Start Date:
              </p>
              <p className="-mt-1 px-1 w-full">
                {position?.information?.startDate ?? {} instanceof Timestamp
                  ? position?.information?.startDate
                      .toDate()
                      .toLocaleDateString()
                  : "No Date selected"}
              </p>
            </div>
            <div className="mb-2">
              <p className="px-1 font-semibold text-instant-teams-blue-Main text-xs">
                Work Term:
              </p>
              <p className="-mt-1 px-1 w-full">
                {term?.label || "Not Assigned"}
              </p>
            </div>
            <div className="mb-2">
              <p className="px-1 font-semibold text-instant-teams-blue-Main text-xs">
                Work Setting:
              </p>
              <p className="-mt-1 px-1 w-full">
                {workSetting?.label || "Not Assigned"}
              </p>
            </div>
            <div className="mb-2">
              <p className="px-1 font-semibold text-instant-teams-blue-Main text-xs">
                Work Type:
              </p>
              <p className="-mt-1 px-1 w-full">
                {workType?.label || "Not Assigned"}
              </p>
            </div>
          </div>
          <div className="text-center">
            {/* Col 2 */}
            <div className="mb-2">
              <p className="px-1 font-semibold text-instant-teams-blue-Main text-xs">
                Shift Option:
              </p>
              <p className="-mt-1 px-1">
                {shiftOption?.label || "Not Assigned"}
              </p>
            </div>
            <div className="mb-2">
              <p className="px-1 font-semibold text-instant-teams-blue-Main text-xs">
                Hours Per Week:
              </p>
              <p className="-mt-1 px-1">
                {getHrsPerWeek({
                  days: shiftScheduleDays,
                  range: shiftScheduleRange,
                })}
              </p>
            </div>

            <div className="mb-2">
              <p className="px-1 font-semibold text-instant-teams-blue-Main text-xs">
                Pay Type:
              </p>
              <p className="-mt-1 px-1">{payType?.label || "Not Assigned"}</p>
            </div>
            <div className="mb-2">
              <p className="px-1 font-semibold text-instant-teams-blue-Main text-xs">
                Pay Rate:
              </p>
              <p className="-mt-1 px-1">
                {!isEmpty(payTypeRange) && payTypeRange.length === 2
                  ? `$${payTypeRange[0]} - $${payTypeRange[1]}`
                  : "Not Assigned"}
              </p>
            </div>
          </div>
        </div>
        {trainingData && employerData && employerData.training && (
          <div className="w-full text-center mb-4">
            <p className="px-1 font-semibold text-instant-teams-blue-Main text-xs">
              Training Cycle:
            </p>
            <p className="-mt-1 px-1">{trainingData.title || "Not Assigned"}</p>
          </div>
        )}
      </Link>
    </div>
  );
}

export default PositionItem;
