
import ButtonWithLoading from "../../../components/custom-ui/button/ButtonWithLoading";
import GreenUnderlineHeading from "../../../components/custom-ui/headings/heading";
import { Timestamp } from "firebase/firestore";
import useModal from "../../../hooks/useModal";
import { useState, useEffect } from "react";
import DialogWrapper from "../../../components/custom-ui/dialog/Dialog";
import { TailwindHoverEnlarge } from "../../../util/tailwind/tailwind-styles";
import { addRotatorImage, useRotatorImages, deleteImage } from '../../../data/rotatorInages';
import { addRotatorQuote, useRotatorQuotes, deleteQuote } from '../../../data/rotatorQuotes';
import { useAuth } from '../../../providers/auth';
import SectionHeading from '../../../components/custom-ui/headings/SectionHeading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash, faTrash, faTextSlash, faFont} from '@fortawesome/free-solid-svg-icons';
import { collectionNames } from '../../../data/dictionary/collectionNames';
import { updateAutoSave } from '../../../data/utility';
import ImageDropzone from '../../../components/custom-ui/drag-and-drop/imageDropzone';
import { upload } from '../../../providers/firebaseStorage';

function RotatorDashboard() {
  const { user } = useAuth();
  const { isShowing: addQuote, toggle: toggleQuote } = useModal();
  const { isShowing: addImage, toggle:toggleImage } = useModal();
  const [pending, setPending] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const [quoteText, setQuoteText] = useState("");
  const [quoteAttribution, setQuoteAttribution] = useState(null);
  const { data: quotesData } = useRotatorQuotes(trigger);
  const { data: imagesData } = useRotatorImages(trigger);

  useEffect(() => {setTrigger(!trigger)}, []);

  useEffect(() => {
    if(imageFile !== ""){
      console.log(imageFile);
      handleAddImage();
    }
  },[imageFile]);

  async function handleAddImage() {
    if(imageFile !== ""){      
      setPending(true);
      const newImageRecord = {
        image: imageFile,
        createdAt: Timestamp.fromDate(new Date()),
        status: "active",
        createdBy: user.uid,
        containQuote: false
      }
      await addRotatorImage(newImageRecord);
      setImageFile("");
    }
  }

  async function handleAddQuote() {
    if(quoteText !== ""){            
      setPending(true);
      const newQuoteRecord = {
        quote: quoteText,
        attribution: quoteAttribution,
        createdAt: Timestamp.fromDate(new Date()),
        status: "active",
        createdBy: user.uid,
      }

      await addRotatorQuote(newQuoteRecord);
      setPending(false);
      setQuoteText("");
      setQuoteAttribution(null);
      toggleQuote();
    }
  }

  async function handleActiveSwitch(id:string, collection:string, name:string, currentStatus:string) {
    const statusUpdate = currentStatus === "active"? "inactive" : "active";
    await updateAutoSave(collection, id, { [name]: statusUpdate })
    setQuoteText("");
    setQuoteAttribution(null);
  }

  async function handleContainQuote(id:string, collection:string, name:string, status:boolean) {
    await updateAutoSave(collection, id, { [name]: status })
    setQuoteText("");
    setQuoteAttribution(null);
  }

  async function handleRemoveQuote(id:string) {
    alert("Are you sure you want to delete this quote?");
    await deleteQuote(id);
    setQuoteText("");
    setQuoteAttribution(null);
  }

  async function handleRemoveImage(id:string) {
    alert("Are you sure you want to delete this image?");
    await deleteImage(id);
    setImageFile("");
  }

  return (
    <div className='grid grid-cols-10 gap-2 w-full'>
      <div className="col-span-10 -mb-8">
        <GreenUnderlineHeading title="Seeker Rotator Interface" />
      </div>
      <div className="w-64 my-5 col-span-6">
      </div>
      <div className="w-64 my-5 col-span-2">
        <ButtonWithLoading value="Add Quote" onClick={toggleQuote} />
      </div>
      <div className="w-64 my-5 col-span-2">
        <ButtonWithLoading value="Add Image" onClick={toggleImage} />
      </div>
      <div className="my-5 col-span-5">
        <SectionHeading title="Quotes" />
        { quotesData && quotesData.length > 0 ? (
          <div className="w-full">
            {quotesData.map((quote: any, index: number) => (
              <div key={index} className="border-b-2 border-instant-teams-teal-Main py-2 pb-8">
                {quote?.attribution === null ? (<p className="font-bold text-sm uppercase">Did you know?</p>) : (null)}
                <p className="text-sm w-full">{quote.quote}{quote?.attribution ? "   -- "+quote.attribution : ""}</p>
                <p className="text-sm text-gray-600 float-right pb-4">
                  <button
                    onClick={() =>{handleActiveSwitch(quote.id, collectionNames.rotatorQuotes, "status", quote.status)}}
                  >
                    {quote.status === "active" ? (<FontAwesomeIcon icon={faEye} className="mr-1 text-green-700" />) : (<FontAwesomeIcon icon={faEyeSlash} className="mr-1 text-red-700" />)}
                  </button>
                  <button
                    onClick={() =>{handleRemoveQuote(quote.id)}}
                  >
                    <FontAwesomeIcon icon={faTrash} className="mr-1 text-instant-teams-blue-Main" />
                  </button>
                </p>
              </div>
            ))}
          </div>
        ):(
          <div className="text-center">
            <p className="text-gray-500 text-sm">No Quotes</p>
          </div>
        )}
      </div>
      <div className="my-5 col-span-5">
        <SectionHeading title="Images" />
        { imagesData && imagesData.length > 0 ? (
          <div className="w-full grid grid-cols-3 gap-4">
            {imagesData.map((image: any, index: number) => (
              <div key={index} className="border-b-2 border-instant-teams-teal-Main py-2 px-4 w-full h-auto">
                <img src={image.image} className="w-full h-auto object-cover" />
                <p className="text-sm w-full mb-4">
                  <span className="text-sm text-gray-600 float-left">
                    <button
                      onClick={() =>{handleContainQuote(image.id, collectionNames.rotatorImages, "containQuote", !image.containQuote)}}
                    >
                      {image.containQuote ? (<FontAwesomeIcon icon={faFont} className="mr-1 text-green-700" />) : (<FontAwesomeIcon icon={faTextSlash} className="mr-1 text-red-700" />)}
                    </button>
                  </span>
                  <span className="text-sm text-gray-600 float-right">
                    <button
                      onClick={() =>{handleActiveSwitch(image.id, collectionNames.rotatorImages, "status", image.status)}}
                    >
                      {image.status === "active" ? (<FontAwesomeIcon icon={faEye} className="mr-1 text-green-700" />) : (<FontAwesomeIcon icon={faEyeSlash} className="mr-1 text-red-700" />)}
                    </button>
                    <button
                      onClick={() =>{handleRemoveImage(image.id)}}
                    >
                      <FontAwesomeIcon icon={faTrash} className="mr-1 text-instant-teams-blue-Main" />
                    </button>
                  </span>
                </p>
              </div>
            ))}
          </div>
        ):(
          <div className="text-center">
            <p className="text-gray-500 text-sm">No Quotes</p>
          </div>
        )}
      </div>
      {addImage && (
        <DialogWrapper title="Add New Image" onClose={toggleImage}>
          <div className='w-full'>
            <ImageDropzone setImage={setImageFile} toggle={toggleImage} userId={user.uid} folder={"rotatorImages"}/>
          </div>
        </DialogWrapper>
      )}
      {addQuote && (
        <DialogWrapper title="Add New Quote" onClose={toggleQuote}>
          <>
            <input
              className="w-full"
              type="text"
              placeholder="Quote Text"
              disabled={pending}
              onChange={(event) => setQuoteText(event.target.value)}
            />
            <input
              className="w-full mt-4"
              type="text"
              placeholder="Quote Attribution"
              disabled={pending}
              onChange={(event) => setQuoteAttribution(event.target.value)}
            />
            <div className="w-full my-2 flex justify-center">
              <button
                className="py-2 px-16 rounded-lg bg-instant-teams-blue-Main text-white hover:bg-instant-teams-blue-D1"
                onClick={handleAddQuote}
              >
                {pending ? "..." : "Add New Quote"}
              </button>
            </div>
          </>
        </DialogWrapper>
      )}
    </div>
  );
}

export default RotatorDashboard;
