import Meta from "../../components/custom-ui/meta/Meta";
import SeekerRegistrationIndex from "../../components/seeker/details/registration/SeekerRegistrationIndex";
function SeekerRegistrationPage() {
  return (
    <>
      <Meta title="Job Seeker Registration" />
      <SeekerRegistrationIndex />
    </>
  );
}

export default SeekerRegistrationPage;
