import { useComponent } from "../../../data/academy";
import Tooltip from "../../custom-ui/tooltip/tooltip";

function PreviewMobileSkillItem({ assessmentId }: { assessmentId: string }) {
  const { data: assessmentData } = useComponent(assessmentId);
  return (
    <>
      <div className="flex items-center w-36 cursor-pointer">
        <img
          className="w-20 h-20"
          src={assessmentData?.badge || "/images/badge.png"}
          alt={assessmentData?.name}
        />
        <p className="text-center w-full text-xs">
          {assessmentData?.displayName || assessmentData?.name}
        </p>
      </div>
    </>
  );
}

export default PreviewMobileSkillItem;
