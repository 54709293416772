import {
  SearchBox,
  Hits,
  Configure,
  HitsPerPage,
  Pagination,
} from "react-instantsearch-hooks-web";
import ExternalOfferItem from "../application/ExternalOfferItem";
import { useAuth } from "../../providers/auth";
import GreenUnderlineHeading from "../custom-ui/headings/heading";
function ExternalOffers() {
  const { userClaims } = useAuth();
  return (
    <>
      <div className="flex flex-col">
        <GreenUnderlineHeading title="Applications" />

        <div className=" w-full">
          <div className="flex justify-between">
            <div className="flex justify-start ">
              <SearchBox
                placeholder="Keyword Search"
                classNames={{
                  input: "w-full",
                  submitIcon: "hidden",
                  resetIcon: "hidden",
                  submit: "hidden",
                }}
              />
            </div>
            <div className="flex justify-end">
              <div className=" pr-4">Results per page:</div>
              <HitsPerPage
                items={[
                  { label: "10", value: 10, default: true },
                  { label: "25", value: 25 },
                  { label: "50", value: 50 },
                  { label: "100", value: 100 },
                ]}
                classNames={{ select: "text-xs h-6 py-0" }}
              />
            </div>
          </div>
          <Configure
            filters={`employerId:${userClaims?.claims?.employerId} AND status:pending OR status:preparation`}
          />
          <Hits
            hitComponent={({ hit }: { hit: any }) => (
              <ExternalOfferItem offer={hit} />
            )}
            classNames={{ list: " grid grid-cols-4 gap-4 mt-4" }}
          />
          <div className="mt-12 w-full flex justify-center">
            <Pagination
              showNext={true}
              padding={1}
              classNames={{
                list: "flex",
                item: "h-8 w-8 text-center rounded-m rounded mx-1 bg-white",
                selectedItem: "scale-125 border-black",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ExternalOffers;
