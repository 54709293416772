import React from "react";
import Meta from "../../components/custom-ui/meta/Meta";
import SectionSettings from "../../components/user/settings/SectionSettings";

import { useParams } from "react-router-dom";
import { requireAuth } from "../../providers/auth";

function SettingsPage(props:any) {
  // const router = useRouter();
  const params = useParams();

  return (
    <div className="pt-6 min-h-screen">
      <Meta title="My Profile" />
      <SectionSettings section={params.section} key={params.section} />
    </div>
  );
}

export default requireAuth(SettingsPage);
