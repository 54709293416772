import { useParams, Outlet } from "react-router-dom";
import { useUser } from "../../../../providers/database";
import { useEmploymentByEmployee } from "../../../../data/employee";
import { useUserGroupChat } from "../../../../data/messages";
import { requireAuth, useAuth } from "../../../../providers/auth";
import SeekerInfo from "../../../seeker/details/parts/seekerInfo";
import useModal from "../../../../hooks/useModal";
import DialogWrapper from "../../../custom-ui/dialog/Dialog";
import NewConversationModal from "../../../messages/NewConversationModal";

const EmployeePage = () => {
  const userId = useAuth()?.user?.id;
  const params = useParams();
  const { userClaims } = useAuth();
  const { data: employmentRecord } = useEmploymentByEmployee(
    params?.employeeId || "",
  );
  const { data: employeeData, status: employeeDataStatus } = useUser(
    employmentRecord?.userId,
  );
  const { data: loggedInUser } = useUser(userId);
  const {
    isShowing: isShowingConversationModal,
    toggle: toggleConversationModal,
  } = useModal();
  const { data: employeeGroupChat } = useUserGroupChat(
    loggedInUser?.claims?.role,
    employeeData?.id,
  );

  return (
    <div className="w-[90%] relative">
      {employeeDataStatus === "success" && (
        <div className="flex flex-row mt-8">
          <div className="w-3/4 mb-2">
            <SeekerInfo imageVisible={true} userId={employeeData?.id} />
          </div>
          {!employeeGroupChat && (
            <div className="w-1/4 mb-2 flex flex-col justify-around">
              <button
                className="w-3/4 mb-5 min-h-12 h-12 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                onClick={toggleConversationModal}
              >
                START CHAT
              </button>
            </div>
          )}
        </div>
      )}
      <Outlet />
      {isShowingConversationModal && (
        <DialogWrapper title="Start Conversation" onClose={toggleConversationModal}>
          <NewConversationModal
            toggle={toggleConversationModal}
            recipientId={employeeData?.id}
          />
        </DialogWrapper>
      )}
    </div>
  );
};

export default requireAuth(EmployeePage);
