import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";
function EInterviewDetailView({
  title,
  index,
  isCompleted,
}: {
  title: string;
  index: any;
  isCompleted: Boolean;
}) {
  return (
    <div className="flex justify-between lg:w-full my-5" key={index}>
      <p className="pl-4 w-64">{title}</p>
      {isCompleted ? (
        <FontAwesomeIcon
          icon={faCircleCheck}
          className=" text-instant-teams-green-Main"
          size="xl"
        />
      ) : (
        <FontAwesomeIcon icon={faCircleQuestion} size="xl" color="grey" />
      )}
    </div>
  );
}

export default EInterviewDetailView;
