import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { Menu, Transition } from "@headlessui/react";
import { useMeetingsByInvitee } from '../../data/meetings';
import Meeting from "./meeting";
import { useAuth } from "../../providers/auth";
import { useEffect,useState } from 'react';

const MeetingsHeaderListSeeker = ({ userId }) => {
  const user = useAuth();
  
  const { data: meetings } = useMeetingsByInvitee(userId);

    const [filteredMeetingData, setFilteredMeetingData] = useState(meetings||[])

  const filterData = ()=>{
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    const twentyFourHoursAgo = currentTime - (24 * 60 * 60); // 24 hours in seconds
    const filteredData = meetings?.filter(item => item?.meetingDetails?.startTime.seconds >= twentyFourHoursAgo);
    setFilteredMeetingData(filteredData);
  }

  useEffect(()=>{
    if(meetings?.length > 0){
      filterData();
    }
  },[meetings])

  return (
    <Menu>
      <Menu.Button className="flex relative h-full w-12 mt-2 pb-2 hover:pb-1 hover:border-b-4 hover:border-green-400 overflow-hidden justify-center items-center">
        <FontAwesomeIcon icon={faCalendar} className="h-8 w-6" />
        {meetings && filteredMeetingData?.length > 0 && (
          <div className="h-5 w-5 absolute top-4 left-2 rounded-full bg-red-700 text-xs flex justify-center items-center">
            {meetings?.length}
          </div>
        )}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={`flex absolute overflow-y-auto  ${filteredMeetingData?.length ? "h-[82vh]" : ""}  top-24 right-4 p-5 w-1/4 pt-2  divide-y divide-gray-100 bg-instant-teams-teal-Main shadow-lg rounded-md`}>
          <div className="flex w-1/4 flex-1 flex-col items-center ">
            <h3 className="font-bold">MEETINGS</h3>
            {meetings &&
              filteredMeetingData?.map((userMeeting) => (
                  <Meeting
                    meetingId={userMeeting.id}
                    key={userMeeting.id}
                  />
                ))}
            {meetings && (meetings.length === 0 || filteredMeetingData?.length == 0) && (
              <div>No Meetings Scheduled</div>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default MeetingsHeaderListSeeker;
