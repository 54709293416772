import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Timestamp } from "firebase/firestore";
import { useJobPositionDetails } from "../../data/positions";
import { updateApplicationPositionStatus } from "../../data/applications";
import { DisplayTimestamp } from "../../util/timestamps/timestamp";
import useModal from "../../hooks/useModal";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import PositionItem from "../positions/PositionItem";
import Tooltip from "../custom-ui/tooltip/tooltip";
import OfferForm from "../offers/parts/OfferForm";
import { useAuth } from "../../providers/auth";
import ExternalOfferForm from "../offers/parts/ExternalOfferForm";
function ApplicationPositionItem({
  jobId,
  positionApplication,
  applicationId,
  applicationData,
}: {
  jobId: string;
  applicationId: string;
  positionApplication: {
    id: string;
    active: boolean;
    createdAt: any;
    removedAt?: any;
  };
  applicationData: any;
}) {
  const { userClaims } = useAuth();
  const { data: positionData = [], status: queryStatus } =
    useJobPositionDetails(jobId, positionApplication.id);

  const { isShowing: showPositionModal, toggle: togglePositionModal } =
    useModal();

  const {
    isShowing: showPositionStatusDialog,
    toggle: togglePositionStatusDialog,
  } = useModal();

  const [positionAction, setPositionAction] = useState({});
  /**
   * {action: "remove", message: "Are you sure you want to remove the following position?", title: "Remove Position"}
   */

  const removePosition = () => {
    setPositionAction({
      action: "Remove",
      isActive: false,
      title: "Remove Position",
      message: "Confirm seeker removal from the following position.",
    });
    togglePositionStatusDialog();
  };

  const addPosition = () => {
    setPositionAction({
      action: "Add",
      isActive: true,
      title: "Add Position",
      message: "Add seeker to the following position",
    });
    togglePositionStatusDialog();
  };

  const confirmPositionAction = (active: boolean) => {
    // if active is false set a removedAt value
    // otherwise write new createdAt value
    const data = {
      active,
      ...(!active && { removedAt: Timestamp.now() }),
      ...(active && { createdAt: Timestamp.now() }),
    };

    updateApplicationPositionStatus(applicationId, positionApplication.id, data)
      .then(() => {
        console.log("updated position...");
        setPositionAction({});
        togglePositionStatusDialog();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const { isShowing: showOfferModal, toggle: toggleOfferModal } = useModal();
  const { positionName = "" } = positionData ?? {};

  return (
    <>
      {queryStatus === "success" && (
        <div className="flex mb-2 p-4 rounded-lg shadow-lg justify-around min-h-full w-full">
          {userClaims?.claims?.type === "A" ? (
            <div className="my-auto">
              {positionApplication.active ? (
                <FontAwesomeIcon
                  className="mr-2 align-middle text-instant-teams-green-D2"
                  // @ts-ignore
                  icon={["fas", "circle-check"]}
                  size="xl"
                />
              ) : (
                <FontAwesomeIcon
                  className="mr-2 align-middle text-instant-teams-orange-D2"
                  // @ts-ignore
                  icon={["fas", "circle-x"]}
                  size="xl"
                />
              )}
            </div>
          ) : null}
          <div
            className="text-instant-teams-teal-D1 font-bold text-lg cursor-pointer w-4/5"
            onClick={togglePositionModal}
          >
            <p>{positionName}</p>
            {!positionApplication.removedAt && (
              <p className="text-sm">
                Applied:{" "}
                {positionApplication?.createdAt
                  ? DisplayTimestamp(positionApplication?.createdAt)
                  : "None"}
              </p>
            )}
            {positionApplication.removedAt && (
              <p className="text-sm">
                Removed:{" "}
                {positionApplication?.removedAt
                  ? DisplayTimestamp(positionApplication?.removedAt)
                  : "None"}
              </p>
            )}
          </div>
          {userClaims?.claims?.type === "A" ? (
            <>
              {positionApplication.active ? (
                <Tooltip text="Remove from Position">
                  <div
                    className="text-instant-teams-teal-D1 font-bold text-lg cursor-pointer float-right min-h-full my-auto"
                    onClick={removePosition}
                  >
                    <FontAwesomeIcon
                      className="mr-2 align-middle text-instant-teams-orange-D2"
                      // @ts-ignore
                      icon={["fas", "user-minus"]}
                      size="xl"
                    />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip text="Add to Position">
                  <div
                    className="text-instant-teams-teal-D1 font-bold text-lg cursor-pointer float-right min-h-full my-auto"
                    onClick={addPosition}
                  >
                    <FontAwesomeIcon
                      className="mr-2 align-middle text-instant-teams-green-D2"
                      // @ts-ignore
                      icon={["fas", "user-plus"]}
                      size="xl"
                    />
                  </div>
                </Tooltip>
              )}
            </>
          ) : null}
          {positionData?.status !== "offered" && positionApplication?.active ? (
            <Tooltip text="Make an Offer">
              <div
                className="text-instant-teams-teal-D1 font-bold text-lg cursor-pointer float-right min-h-full my-auto ml-4"
                onClick={toggleOfferModal}
              >
                <FontAwesomeIcon
                  className=" align-middle text-instant-teams-tags-BLUE"
                  // @ts-ignore
                  icon={["fas", "comments-dollar"]}
                  size="xl"
                />
              </div>
            </Tooltip>
          ) : (
            <div className="text-instant-teams-teal-D1 font-bold text-lg cursor-pointer float-right min-h-full my-auto ml-4 w-[4%]"></div>
          )}
        </div>
      )}
      {showPositionModal && userClaims?.claims?.type === "A" ? (
        <DialogWrapper
          onClose={() => togglePositionModal()}
          title=""
          size=" w-1/2  mt-36"
        >
          <>
            <div className="w-full m4">
              <PositionItem position={positionData} />
            </div>
          </>
        </DialogWrapper>
      ) : null}
      {showPositionStatusDialog && (
        <DialogWrapper
          onClose={() => {
            togglePositionStatusDialog();
            // reset position action
            setPositionAction({});
          }}
          title={positionAction.title ?? ""}
          size=" w-1/2 mt-36"
        >
          <div className="w-full m4">
            <div className="my-2">
              <div className="my-2 font-bold text-instant-teams-teal-Main">
                {positionAction?.message}
              </div>
              <PositionItem position={positionData} />
            </div>
            <div className="flex space-x-4">
              <button
                className={`${
                  positionAction.action === "Add"
                    ? "bg-instant-teams-green-Main hover:bg-instant-teams-green-L1"
                    : "bg-instant-teams-red-Main hover:bg-instant-teams-red-L1"
                } w-full mb-5 min-h-12 h-12 text-white  rounded border-0  focus:outline-none`}
                onClick={() => confirmPositionAction(positionAction.isActive)}
              >
                {positionAction.action} Position
              </button>
              <button
                className="w-full mb-5 min-h-12 h-12 text-white bg-instant-teams-gray-D1 rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                onClick={togglePositionStatusDialog}
              >
                Cancel
              </button>
            </div>
          </div>
        </DialogWrapper>
      )}

      <>
        {showOfferModal && (
          <DialogWrapper
            onClose={() => toggleOfferModal()}
            title="Offer Submission Form"
            size=" w-1/2  mt-24 overflow "
          >
            <ExternalOfferForm
              position={positionData}
              toggleOfferModal={() => toggleOfferModal()}
              passedInApplicationId={applicationId}
            />
          </DialogWrapper>
        )}
      </>
    </>
  );
}

export default ApplicationPositionItem;
