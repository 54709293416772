import React, { memo, forwardRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TailwindHoverEnlarge } from "../../../util/tailwind/tailwind-styles";
import { US_state } from "../../../pages/cohorts/UtilFileForCohort/getCohortSupportData";

const CohortUserList = forwardRef(({ row, onSelect }: any, ref: any) => {
    const US_state_data :any = US_state
    const navigate = useNavigate();
    const { email, name, Skills, claims, objectID, isChecked } = row || {};
    const { address } = row || {};
    const seekerTaggedSkills = Skills?.seeker_tagged_skills?.map((skill: any) => skill.skill).join(', ');
    const tacVerifiedSkills = Skills?.tac_verified_skills?.map((skill: any) => skill.skill).join(', ');
    const tacTags = Skills?.tac_tags?.map((skill: any) => skill.skill).join(', ');
 

    const handleNavigate = (id: any) => {
        navigate(`/seekers/${id}/overview`);
    };

    return (
        <div ref={ref} className="w-full pr-2">
            <div
                className={
                    TailwindHoverEnlarge(65) +
                    " grid grid-cols-[19rem_1fr_20rem_9rem] gap-4 items-start w-full rounded-lg bg-white shadow-lg p-4 mb-4"
                }
            >
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        className="mr-2"
                        checked={isChecked}
                        data-objectid={objectID}
                        onChange={() => onSelect(row)}
                    />
                    <div onClick={() => handleNavigate(objectID)}>
                        <h2 className="text-l font-bold hover:underline cursor-pointer">{name}</h2>
                    </div>
                </div>

                <div className="flex ml-[-20px] flex-col text-[15px]">
                    <p className="text-[15] text-gray-600">
                        <span className="text-gray-400">Email:</span><br /> {email}
                    </p>
                    <p className="mt-2 text-gray-600">
                        <span className="text-gray-400">Skills:</span><br /> {seekerTaggedSkills || "None"}
                    </p>
                    <p className="mt-2 text-gray-600">
                        <span className="text-gray-400">TAC Verified Skills:</span><br /> {tacVerifiedSkills ||"None"}
                    </p>
                </div>

                <div className="flex ml-5 flex-col text-[15px]">
                    <p className="text-gray-600">
                        <span className="text-gray-400">Affiliation:</span><br />{(claims.milspouse && claims.milVerified)?"Military Verified":claims?.milspouse ? "Military Spouse" : claims?.milVerified ? "Military Verified" : "None"}
                    </p>
                    <p className="text-gray-600 mt-5">
                        <span className="text-gray-400">TAC Tags:</span><br /> {tacTags||"None"}
                    </p>
                </div>

                <div className="flex flex-col text-[15px]">
                    <p className="text-gray-600">
                        <span className="text-gray-400">Location:</span><br />{address?.country === "US" ? US_state_data[address?.state] || "None" : "None"}
                    </p>
                </div>
            </div>
        </div>
    );
});

export default memo(CohortUserList);
