import { useParams } from "react-router";
import PreScreenQADisplay from "../../application/PreScreenQADisplay";
import Chip from "../../custom-ui/chips/Chip";
import Meta from "../../custom-ui/meta/Meta";
import { useApplicationPreScreen } from "../../../data/applications";
import AnalyticsLogger from "../../../providers/analyticsLogger";
import { collectionNames } from "../../../data/dictionary/collectionNames";
const JobApplicantPreScreen = () => {
  const params = useParams();
  const { applicationId = "" } = params ?? {};
  const { data: preScreenData } = useApplicationPreScreen(applicationId);

  return (
    <div>
      <Meta title="Job Applicant PreScreen Questions" />
      <AnalyticsLogger
        type={collectionNames.applications}
        typeId={applicationId}
      />
      <div className="mb-36">
        <div className="uppercase text-xl font-bold text-instant-teams-teal-D1 border-b-2 border-instant-teams-green-D2 min-w-full">
          Pre-Screen
        </div>
        {preScreenData &&
          preScreenData.map((preScreen: any) => {
            return <PreScreenQADisplay key={preScreen.id} data={preScreen} />;
          })}
      </div>
    </div>
  );
};

export default JobApplicantPreScreen;
