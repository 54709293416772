import React, { useEffect, usestate, useRef } from "react";
import { RefinementList, Pagination, Configure, useHits, HitsPerPage, } from "react-instantsearch-hooks-web";
import isEqual from 'lodash/isEqual';
import CohortUserList from "../../../components/cohort/listing/CohortUserList";


const CustomHits = (
    {
        onSelect,
        selectedUsers,
        userList,
        setAllHits,
        currentPage,
        itemsPerPage,
        setPageIndex
    }: any) => {
    const { hits, results } = useHits();
    const previousHitsRef = useRef([]);

    useEffect(() => {
        if (!isEqual(previousHitsRef.current, hits)) {
            setAllHits(hits);
            previousHitsRef.current = hits;
        }
    }, [hits, setAllHits]);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    useEffect(()=>{
        setPageIndex({
            startIndex: startIndex,
            endIndex: endIndex
        })
    },[startIndex,endIndex])
    const paginatedUserList = userList.slice(startIndex, endIndex);

    if (userList.length === 0) {
        return (
            <div className="w-full flex justify-center items-center text-gray-700 text-xl p-32 bg-white">
                <h6>No result found</h6>
            </div>
        );
    }

    return (
        <div className="ais-InfiniteHits overflow-y-auto ">
            <ul className="ais-InfiniteHits-list grid w-full">
                {paginatedUserList.map((hit: any) => (
                    <CohortUserList
                        key={hit.objectID}
                        row={hit}
                        onSelect={onSelect}
                        isSelected={selectedUsers.some((user: any) => user.objectID === hit.objectID)}
                    />
                ))}
            </ul>
        </div>
    );
};

export default CustomHits
