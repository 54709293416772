import { useAllEmployerAccessLogs } from "../../../../data/accessLogs";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { AccessLog } from "../../../../dataTypes/AccessLogs";
import AccessLogItem from "../../../accessLog/accessLogItem";
import GreenUnderlineHeading from "../../../custom-ui/headings/heading";
const EmployerAccessLogs = () => {
  const params = useParams();
  const [employerId, setEmployerId] = useState(params.employerId ?? "");
  const { data: employerAccessLogs } = useAllEmployerAccessLogs(employerId);
  useEffect(() => {
    setEmployerId(params.employerId);
  }, [employerId]);

  return (
    <>
      <GreenUnderlineHeading title="Access Logs" />
      {employerAccessLogs &&
        employerAccessLogs.map((row: AccessLog, index: string) => {
          return <AccessLogItem entry={row} />;
        })}
    </>
  );
};

export default EmployerAccessLogs;
