import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessageSms } from "@fortawesome/pro-regular-svg-icons";

function EllipseAnimated() {
  return (
    <FontAwesomeIcon
      className="fa-beat-fade h-10 w-10 p-5 text-instant-teams-blue-Main"
      icon={faMessageSms}
      size="xl"
    />
  );
}

export default EllipseAnimated;
