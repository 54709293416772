import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { getApplicationTrendsByDayByJobId } from "../callable-cloud-functions/cloudFunctions";

function JobApplicationTrends({ jobId }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  );

  const [chartData, setChartData] = useState([]);
  const [chartKeys, setChartKeys] = useState([]);
  const [daysSelected, setDaysSelected] = useState(14);
  const [lookup, setLookup] = useState({ jobId, days: 14 });
  const [chartTitle, setChartTitle] = useState("Last 14 Days");

  useEffect(() => {
    async function fetchData() {
      const { data } = await getApplicationTrendsByDayByJobId(lookup);
      const chartData = [];
      const chartKeys = [];
      data.forEach((item) => {
        const date = Object.keys(item)[0];
        const val = item[date];
        chartKeys.push(date);
        chartData.push(val);
      });
      setChartKeys(chartKeys);
      setChartData(chartData);
    }
    fetchData();
  }, [lookup]);

  useEffect(() => {
    setLookup({ jobId, days: daysSelected });
    setChartTitle("Last " + daysSelected + " Days");
  }, [daysSelected]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Application Trends by Days",
      },
    },
  };

  const labels = chartKeys;

  const data = {
    labels,
    datasets: [
      {
        label: chartTitle,
        data: chartData,
        borderColor: "#0c3d5b",
        backgroundColor: "#0c3d5b",
      },
    ],
  };

  return (
    <>
      <Line options={options} data={data} />
      <div className="w-full float-right">
        {" "}
        <select
          value={daysSelected}
          className="w-full bg-white border border-gray-200"
          onChange={(e) => setDaysSelected(e.target.value)}
        >
          <option value={3}>Last 3 Days</option>
          <option value={7}>Last 7 Days</option>
          <option value={14}>Last 14 Days</option>
          <option value={30}>Last 30 Days</option>
        </select>
      </div>
    </>
  );
}

export default JobApplicationTrends;
