import React,{useState} from "react";
import { useParams } from "react-router-dom";
import Meta from "../../components/custom-ui/meta/Meta";
import { requireAuth } from "../../providers/auth";
import {
  useUserNotificationIds,
  updateAllIsRead,
} from "../../data/notifications";
import { useAuth } from "../../providers/auth";
import UserNotificationItem from "../../components/notifications/UserNotificationItem_NewUI";
import GreenUnderlineHeading from "../../components/custom-ui/headings/heading";


const NotificationsPage_NewUI = ({ headerTitle }) => {

  const params = useParams();
  const user = useAuth();
  const {
    data: userNotificationList,
    status: notificationsStatus,
    error: notificationsError,
  } = useUserNotificationIds(user.user.id);

  function handleAllRead() {
    updateAllIsRead(params.userId);
  }

  return (
    <div className=" mx-36 pt-40 px-36 min-h-screen">
          <Meta title="My Notifications" />
          <div className="flex justify-between items-center py-6">
              
              <div>
                  <h1 className="text-2xl text-instant-teams-blue-Main font-bold">
                      Notifications
                  </h1>
              </div>
              <div>
              <button
          className="bg-instant-teams-blue-Main px-6 rounded-full py-3 p-2 text-white"
          onClick={handleAllRead}
        >
          Mark All Read
        </button>
              </div>
              
          </div>

      <table className="w-full">
        {userNotificationList &&
          userNotificationList.length > 0 &&
          userNotificationList.map((userNotification) => (
            <UserNotificationItem
              userNotification={userNotification}
              key={userNotification.notificationId}
            />
          ))}
      </table>
    </div>
  );
};

export default requireAuth(NotificationsPage_NewUI);
