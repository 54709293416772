import { useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
  Circle,
} from "@react-google-maps/api";
import { milesToMeters } from "../../data/geoQuery";
import InfoCard from './InfoCard';

function SeekerMap({ seekers = [], center, radiusInMiles }) {
  console.log(seekers);
  const processMarkers = () => {
    if (Array.isArray(seekers)) {
      return seekers.map((record) => {
        const { id, address, name, models } = record ?? {};
        const { latitude, longitude } = address ?? {};
        return {
          id,
          name,
          models,
          position: { lat: latitude, lng: longitude },
        };
      });
    } else {
      return [];
    }
  };

  const markers = processMarkers();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new google.maps.LatLngBounds(); // eslint-disable-line
    markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  return isLoaded && markers.length ? (
    <GoogleMap
      onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ width: "50vw", height: "60vh" }}
    >
      {markers.map(({ id, name, position, models }) => {
        return (
          <Marker
            key={id}
            icon={{
              path: "m277.25,230.5h.17c-79.45,106.67-105.44,179.41-113.17,205.05-1.83,6.08-7.44,10.23-13.78,10.23s-11.95-4.14-13.78-10.23c-7.74-25.65-33.72-98.39-113.17-205.05h0C8.84,207.33.5,179.9.5,150.46.5,67.64,67.64.5,150.46.5s149.96,67.14,149.96,149.96c0,29.44-8.52,56.87-23.17,80.04Z",
              fillOpacity: 1,
              scale: 0.05,
              strokeWeight: .75,
              ...setWorkPrefColors(models),
            }}
            position={position}
            onClick={() => handleActiveMarker(id)}
          >
            {activeMarker === id ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <InfoCard name={name} models={models} id={id} />
              </InfoWindow>
            ) : null}
          </Marker>
        );
      })}

      <Circle
        center={{
          lat: center.latitude,
          lng: center.longitude,
        }}
        radius={milesToMeters(radiusInMiles)}
        options={{
          strokeColor: "#FE0002",
          strokeWeight: 2.5,
          strokeOpacity: 0.5,
          visible: true,
        }}
      />
    </GoogleMap>
  ) : null;
}

function setWorkPrefColors(models) {
  const allModels = ["WFH", "PHY", "HYB"];
  switch (models.length) {
    case 0:
      return { fillColor: "#FF2929", strokeColor: "#000000" };
    case 1:
      return modelColors[models[0]];
    case 2:
      return { fillColor: "#673AB7", strokeColor: "#000000" };
    case 3:
      return { fillColor: "#880E4F", strokeColor: "#000000" };
    default:
      return { fillColor: "#E31C26", strokeColor: "#000000" };
  }
}

const modelColors = {
  WFH: {
    fillColor: "#0288D1",
    strokeColor: "#000000",
  },
  PHY: {
    fillColor: "#F57C00",
    strokeColor: "#000000",
  },
  HYB: {
    fillColor: "#097138",
    strokeColor: "#000000",
  },
};

export default SeekerMap;
