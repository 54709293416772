import { useEffect, useRef } from "react";
import { useApplicationInterviewQuestionAnswer } from "../../../data/applications";
import { sanitizeMarkup } from "../../../util/sanitize/sanitize-html";

function ModalInterviewAnswerItem({
  application,

  question,
}: {
  application: any;
  question: any;
}) {
  const { data: appInterviewAnswer, isLoading: isInterviewAnswerLoading } =
    useApplicationInterviewQuestionAnswer(application?.objectID, question?.id);

  // useRef required to keep videos from rendering the same videos.
  const videoAnswerRef = useRef(null);
  const videoQuestionRef = useRef(null);
  useEffect(() => {
    videoQuestionRef?.current?.load();
  }, [question]);
  useEffect(() => {
    videoAnswerRef?.current?.load();
  }, [appInterviewAnswer]);
  const hasAnswer =
    appInterviewAnswer !== null && appInterviewAnswer?.answer !== null;
  return (
    <>
      {!isInterviewAnswerLoading && (
        <div className="col-span-7 my-5 shadow-input-field mr-10 px-10 py-5 rounded-lg">
          <div
            dangerouslySetInnerHTML={sanitizeMarkup(question?.question)}
            className="dangerously pt-2 px-4 mb-5 overflow-auto text-sm   "
          ></div>
          {/* Audio Prompt */}
          {question?.promptType === "VOICE" && (
            <audio controls className="w-1/2 mx-auto my-5 p-2">
              <source src={question?.audioURL} type="audio/mpeg" />
            </audio>
          )}
          {/* Video Prompt */}
          {question?.promptType === "VID" && (
            <video
              controls
              ref={videoQuestionRef}
              className="p-4 mx-auto aspect-video"
            >
              <source src={question?.videoURL} type="video/mp4" />
            </video>
          )}
          {/* Text response */}
          {hasAnswer && question?.type === "ESS" ? (
            <div className="w-full shadow-input-field py-5 rounded-lg text-start px-5 text-instant-teams-teal-Main font-semibold">
              {appInterviewAnswer?.answer}
            </div>
          ) : null}
          {/* Audio response */}
          {hasAnswer && question?.type === "VOICE" && (
            <div className="mx-auto w-1/2 my-2">
              <audio
                className="w-full p-2  "
                key={question?.id}
                src={appInterviewAnswer?.answer}
                controls
              />
            </div>
          )}
          {/* Video response */}
          {hasAnswer && question?.type === "VID" && (
            <video
              controls
              className="mx-auto aspect-video p-4"
              ref={videoAnswerRef}
            >
              <source src={appInterviewAnswer?.answer} type="video/mp4" />
            </video>
          )}
          {!appInterviewAnswer || appInterviewAnswer?.answer === null ? (
            <div className="w-full text-lg font-bold text-instant-teams-teal-Main">
              No Answer Provided
            </div>
          ) : null}
        </div>
      )}
    </>
  );
}

export default ModalInterviewAnswerItem;
