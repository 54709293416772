import ToastAlert from "../../custom-ui/toast-alert/ToastAlert";
import CatalogItem from "../parts/catalogItem";
import {
  SearchBox,
  Configure,
  RefinementList,
  CurrentRefinements,
  ClearRefinements,
  Hits,
  HitsPerPage,
  Pagination,
  RefinementListProps,
} from "react-instantsearch-hooks-web";
import "../../../styles/RefinementList.css";
import Meta from "../../custom-ui/meta/Meta";
import { requireAuth } from "../../../providers/auth";
import { getLabel } from "../../../util/search/getLabel";
import CourseCard from "../parts/CourseCard/CourseCard";

const AcademyCatalog = () => {
  const transformSkillLevel: RefinementListProps["transformItems"] = (
    items,
  ) => {
    items = items.map((item) => ({
      ...item,
      label: getLabel(item.label, "skillLevel"),
    }));
    return items;
  };

  return (
    <>
      <Meta title="Instant Teams Academy" />
      <div className="w-full inline-flex pl-15">
        <div className="pl-4 pt-36 pb-4 pr-4 w-1/5">
          <div className="mb-4 bg-white rounded-xl p-4 border-2 border-instant-teams-teal-L1 border-solid">
            <img src="/images/InstantTeamsAcademy.png" alt="Academy" />
          </div>
          <SearchBox
            placeholder="Keyword Search"
            classNames={{
              input: "w-full",
              submitIcon: "hidden",
              resetIcon: "hidden",
              submit: "hidden",
            }}
          />
          <CurrentRefinements />
          Filter by Tags
          <RefinementList
            attribute="tags"
            operator="and"
            classNames={{
              root: "capitalize",
              showMore: " text-instant-teams-blue-Main text-xs",
            }}
            limit={5}
            showMore={true}
          />
          Filter by Level
          <RefinementList
            attribute="level"
            operator="and"
            classNames={{
              root: "capitalize",
              showMore: " text-instant-teams-blue-Main text-xs",
            }}
            limit={5}
            showMore={true}
            transformItems={transformSkillLevel}
          />
          Filter by Skill Family
          <RefinementList
            attribute="skillFamily"
            operator="and"
            classNames={{
              root: "capitalize",
              showMore: " text-instant-teams-blue-Main text-xs",
            }}
            limit={5}
            showMore={true}
          />
          <ClearRefinements
            translations={{ resetButtonText: "Clear Filters" }}
            classNames={{
              button:
                " bg-instant-teams-teal-Main text-white p-2 rounded mt-5 text-center w-full",
            }}
          />
          <ToastAlert />
        </div>
        <div className="px-4  pt-36 mb-36 w-3/5">
          <div className="flex justify-between w-[100%]">
            <div></div>
            <div className="flex items-center h-10">
              <div className="pr-4 ">Results per page:</div>
              <HitsPerPage
                items={[
                  { label: "10", value: 10 },
                  { label: "25", value: 25 },
                  { label: "50", value: 50, default: true },
                  { label: "100", value: 100 },
                ]}
                classNames={{ select: "text-xs h-6 py-0" }}
              />
            </div>
          </div>
          <Configure facetFilters={`visable:active`} />
          <Hits
            hitComponent={(hit: any) => <CourseCard hit={hit?.hit} />}
            classNames={{
              root: "ais-InfiniteHits",
              list: "ais-InfiniteHits-list grid grid-cols-4 gap-4 w-full items-start relative",
            }}
          />
          <div className="flex justify-center w-full">
            <div className="mt-12">
              <Pagination
                showNext={true}
                padding={1}
                classNames={{
                  list: "flex",
                  item: "h-8 w-8 text-center rounded-m rounded mx-1 bg-white",
                  selectedItem: "scale-125 border-black",
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-1/5 fixed top-28 right-0 min-h-screen">
          <img
            className="absolute top-0 right-0 w-full min-h-screen z-10 object-cover"
            src="/images/catalog.png"
            alt="Catalog"
          />
        </div>
      </div>
    </>
  );
};

export default requireAuth(AcademyCatalog);
