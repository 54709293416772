import { useParams } from "react-router-dom";
import {
  SearchBox,
  Hits,
  Configure,
  HitsPerPage,
  Pagination,
  RefinementList,
  CurrentRefinements,
  ClearRefinements,
  RefinementListProps,
} from "react-instantsearch-hooks-web";
import { getLabel } from "../../../../util/search/getLabel";
import AdminOfferItem from "../../../offers/parts/AdminOfferItem";
import { TailwindHoverEnlarge } from "../../../../util/tailwind/tailwind-styles";
const transformApplicationStatus: RefinementListProps["transformItems"] = (
  items,
) => {
  items = items.map((item: any) => ({
    ...item,
    label: getLabel(item.label, "offerStatus")
      ? getLabel(item.label, "offerStatus")
      : "No Status",
  }));
  return items;
};

const Offers = () => {
  const params = useParams();
  const employerId = params.employerId;

  return (
    <div className="flex">
      <div className="w-1/5 pr-4">
        <div className=" mt-[17%]">
          <SearchBox
            placeholder="Keyword Search"
            classNames={{
              input: "w-full",
              submitIcon: "hidden",
              resetIcon: "hidden",
              submit: "hidden",
            }}
          />
          <CurrentRefinements />
          Filter by Status
          <RefinementList
            attribute="status"
            operator="or"
            classNames={{
              root: "capitalize",
              showMore: " text-instant-teams-blue-Main text-xs",
            }}
            limit={5}
            showMore={true}
            transformItems={transformApplicationStatus}
          />
          <ClearRefinements
            translations={{ resetButtonText: "Clear Filters" }}
            classNames={{
              button:
                " bg-instant-teams-teal-Main text-white p-2 rounded mt-5 text-center w-full",
            }}
          />
        </div>
      </div>
      <div className=" w-4/5">
        <div className="flex justify-end w-[100%]">
          <div className="flex items-center">
            <div className=" pr-4">Results per page:</div>
            <HitsPerPage
              items={[
                { label: "5", value: 5, default: true },
                { label: "10", value: 10 },
                { label: "20", value: 20 },
                { label: "50", value: 50 },
              ]}
              classNames={{ select: "text-xs h-6 py-0" }}
            />
          </div>
        </div>
        <Configure
          filters={`employerId:${employerId} AND status:pending OR status:preparation OR status:error OR status:cancelled OR status:rejected`}
        />
        <Hits
          hitComponent={({ hit }: { hit: any }) => (
            <AdminOfferItem offer={hit} />
          )}
          classNames={{ list: " mt-4" }}
        />
        <div className="mt-12 w-full flex justify-center">
          <Pagination
            showNext={true}
            padding={1}
            classNames={{
              list: "flex",
              item: "h-8 w-8 text-center rounded-m rounded mx-1 bg-white",
              selectedItem: "scale-125 border-black",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Offers;
