import { sanitizeMarkup } from "../../../util/sanitization/sanitize-html";
import { useJobInterviewQuestions } from "../../../data/jobDescriptions";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import InterviewItemAnswer from "./InterviewItemAnswer";
import "../../../styles/dangerouslySet.css";
function InterviewItem({ application }: { application: any }) {
  const { data: interviewQuestionData } = useJobInterviewQuestions(
    application?.jobId,
  );

  return (
    <>
      {interviewQuestionData &&
        interviewQuestionData.map((question: any, index: string) => {
          return (
            <div key={question.id}>
              <GreenUnderlineHeading title={"Question " + (index + 1)} />
              <div
                dangerouslySetInnerHTML={sanitizeMarkup(question?.question)}
                className="dangerously pt-2 px-4 h-full overflow-auto "
              ></div>
              <InterviewItemAnswer
                question={question}
                appId={application?.id || application?.objectID}
                type={question?.type}
              />
            </div>
          );
        })}
    </>
  );
}
export default InterviewItem;
