import { Link, useNavigate, useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { getLookupLabel } from "../../util/search/getLookupLabel";
import { toUSCurrency } from "../../util/helpers/toUSCurrency";
import { useLookup } from "../../data/lookup";
import { useAuth } from "../../providers/auth";
import { errorLogger } from "../../providers/clientLogger";
import { updateStatus } from "../../data/applications";
import useModal from "../../hooks/useModal";
import DialogWrapper from "../../components/custom-ui/dialog/Dialog";
import { useEmployerData } from "../../hooks/useApplicationEmployerData";
import { useJobDescription } from "../../data/jobDescriptions";
import { useApplication } from "../../data/applications";
import { useJobPositionDetails } from "../../data/positions";
import WedgeSpinner from "../../components/spinners/wedges-spinner";
function ApplicationHeader({ disabled, renderButtons = true }: any) {
  const { applicationId = "" } = useParams();
  const { data: applicationData, isLoading: isApplicationDataLoading } =
    useApplication(applicationId);
  const { data: jobData } = useJobDescription(applicationData?.jobId);
  const { data: positionData, isLoading: isPositionDataLoading } =
    useJobPositionDetails(applicationData?.jobId, applicationData?.positionId);

  const navigate = useNavigate();
  const { user } = useAuth();
  const { employerId, employerData } = useEmployerData({
    jobData,
    originalEmployerId: applicationData?.employer,
  });

  const [submitting, setSubmitting] = useState(false);
  const { isShowing: isWithdrawalModalShowing, toggle: toggleWithdrawalModal } =
    useModal();
  const {
    data: classification = [],
    status: classificationQueryStatus = "",
    error: classificationQueryError,
    isLoading: isClassificationLoading,
  } = useLookup("classification");
  const {
    data: model = [],
    status: modelQueryStatus = "",
    error: modelQueryError,
    isLoading: isModelLoading,
  } = useLookup("model");

  const getSalary = (payType: any) => {
    const pay = payType?.HRLY ?? payType?.SAL;
    if (!pay) return undefined;

    const type = payType?.HRLY ? "Hourly" : "Salary";
    return `${type}: ${toUSCurrency(pay[0])} - ${toUSCurrency(pay[1])}`;
  };

  const salary = useMemo(
    () => getSalary(positionData?.information?.payType),
    [positionData?.information?.payType],
  );

  const selectedWorkSetting = getLookupLabel(
    positionData?.information?.model,
    model,
    "Not Selected",
  );

  const selectedWorkType = getLookupLabel(
    positionData?.information?.classification,
    classification,
    "None Selected",
  );

  const withdrawApplication = async (application: any) => {
    if (!user) return;
    setSubmitting(true);

    try {
      await updateStatus(applicationData?.id, "closed");
      setSubmitting(false);
      navigate("/jobs");
    } catch (error: any) {
      errorLogger(error.message, {
        component: "ApplicationDetailsPage withdrawApplication()",
        flow: "Withdraw Application/ Seeker Applications",
        userId: user.uid,
      });

      setSubmitting(false);
    }
  };

  if (
    isApplicationDataLoading ||
    isPositionDataLoading ||
    isModelLoading ||
    isClassificationLoading
  ) {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <WedgeSpinner />
      </div>
    );
  }

  return (
    <>
      <div className="grid grid-flow-row mx-auto grid-cols-8 gap-y-3 w-3/4">
        <Link to={`/companies/${employerData?.id}`}>
          <img
            className="my-auto col-span-1 w-24 h-24 object-contain  "
            src={employerData?.logo}
            alt={employerData?.displayName}
          />
        </Link>
        <div className="pl-5 col-span-4 my-auto  text-instant-teams-blue-Main justify-start md:text-sm">
          <Link to={`/companies/${employerData?.id}`}>
            <div className="hover:underline">{employerData?.displayName}</div>
          </Link>
          <div className="font-semibold uppercase">{jobData?.title}</div>
          <div>{positionData?.positionName}</div>
          <div className="font-semibold">
            {salary} | {selectedWorkType} | {selectedWorkSetting}
          </div>
        </div>
        {renderButtons ? (
          <div className="col-span-3 space-y-5">
            <button
              onClick={toggleWithdrawalModal}
              disabled={submitting}
              className=" mt-5  py-3 w-full uppercase bg-white border border-instant-teams-red-Main text-instant-teams-red-Main hover:text-white hover:bg-instant-teams-red-Main rounded-lg md:py-2 md:px-8 md:text-sm lg:py-2 lg:px-16 xl:px-20"
            >
              Withdraw
            </button>
          </div>
        ) : null}
      </div>

      {isWithdrawalModalShowing && (
        <>
          <DialogWrapper
            title={`Withdraw from ${jobData.title}`}
            onClose={toggleWithdrawalModal}
          >
            <div className="text-center">
              <p>You are attempting to withdraw your application.</p>
              <p className="mb-8">
                Please confirm to finalize your withdrawal.
              </p>
              <div className="space-y-5">
                <button
                  onClick={() => withdrawApplication(applicationData)}
                  className="py-3 w-full uppercase bg-white border border-instant-teams-red-Main text-instant-teams-red-Main hover:text-white hover:bg-instant-teams-red-Main rounded-lg md:py-2 md:px-8 md:text-sm lg:py-2 lg:px-16 xl:px-20"
                >
                  Withdraw
                </button>
                <button
                  onClick={toggleWithdrawalModal}
                  className="py-3 w-full uppercase bg-white border border-instant-teams-blue-Main text-black hover:text-white hover:bg-instant-teams-blue-D1 rounded-lg md:py-2 md:px-8 md:text-sm lg:py-2 lg:px-16 xl:px-20"
                >
                  Cancel
                </button>
              </div>
            </div>
          </DialogWrapper>
        </>
      )}
    </>
  );
}
export default ApplicationHeader;
