import { useState, useEffect } from "react";
import { useJobPositionDetails } from '../../../data/positions';
import { Offer } from '../../../dataTypes/Offer';
import { DisplayDate } from '../../../util/timestamps/timestamp';
import { useUser } from '../../../data/user';
import { useFindOfferStatus } from '../../../data/status';

const OffersMadeItem = ({ offer }: { offer: Offer }) => {
  const { data: jobPositionDetails } = useJobPositionDetails(offer.jobId, offer.positionId);
  const { data: user } = useUser(offer.resumeId);
  const { data: status } = useFindOfferStatus(offer.status);
  useEffect(() => {
    console.log(user)
  }, [user]);

  const colorJobOfferVariants: any = {
    pending: "bg-instant-teams-tags-BLUE",
    preparation: "bg-instant-teams-tags-GREEN",
    hired: "bg-instant-teams-tags-TEAL",
    rejected: "bg-instant-teams-tags-ORANGE",
    cancelled: "bg-instant-teams-tags-BLUE",
  };

  return (
    <div className="w-full mb-4 mt-4 text-instant-teams-teal-Main font-bold ">
      <div className="grid grid-cols-4 gap-2">
        <div className='col-span-1 text-black pt-2'>
          {jobPositionDetails?.positionName}
        </div>
        <div className='col-span-1 text-black pt-2'>
          {user?.name}
        </div>
        <div className='col-span-1 text-black pt-2'>
          <span>Offered: </span>{DisplayDate(offer?.createdAt)}
        </div>
        <div
          className={`items-center w-full rounded-md text-white p-1 ${
            colorJobOfferVariants[offer?.status] || "bg-instant-teams-purple-Main"
          }`}
        >
          <p className="text-center">{status?.label || "Pending"}</p>
        </div>
      </div>
    </div>
  );
};

export default OffersMadeItem;
