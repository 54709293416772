import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function Chip({ data }: { data: any }) {
  return (
    <div className="text-xs p-2 rounded-full bg-white border-2 text-instant-teams-teal-D1 border-instant-teams-teal-D1">
      {data.icon && data.icon !== "none" && (
        <FontAwesomeIcon
          className="mr-2 align-middle text-instant-teams-blue-Main"
          // @ts-ignore
          icon={["fas", `${data.icon}`]}
        />
      )}
      {data.label}
    </div>
  );
}

export default Chip;
