import { sanitizeMarkup } from "../../util/sanitize/sanitize-html";
import GreenUnderlineHeading from "../custom-ui/headings/heading";
import "../../styles/dangerouslySet.css";
import TestQuestions from "../academy/parts/testQuestions";
import useModal from "../../hooks/useModal";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import AssessmentInstructions from "./AssessmentInstructions";
import { getLevel } from "../../util/assessments/level";
function SeekerAssessmentDetails({
  assessment,
  enrollment,
  application = false,
}: {
  enrollment: any;
  assessment: any;
  application?: boolean;
}) {
  const { isShowing: isShowingInstructions, toggle: toggleInstructions } =
    useModal();

  const getTier = (tier?: "one" | "two" | "three") => {
    switch (tier) {
      case "one":
        return "1";
      case "two":
        return "2";
      case "three":
        return "3";
      default:
        return "?";
    }
  };

  const getCurrentTier = (assessment: any, enrollment: any) => {
    let tier = "";
    const { average } = enrollment;
    if (+average > +assessment.tier.one) {
      tier = "1";
    }
    if (+average > +assessment.tier.two) {
      tier = "2";
    }
    if (+average > +assessment.tier.three) {
      tier = "3";
    }

    return tier;
  };

  return (
    <div key={assessment?.id} className="text-instant-teams-blue-Main">
      <div className="border-b border-b-instant-teams-gray-Main flex align-middle">
        <img
          className="object-contain w-24 h-24"
          src={assessment?.badge}
          alt={assessment?.name}
        />
        <div className="my-auto pl-6  ">
          <h2 className="font-semibold">
            {assessment?.displayName ?? assessment?.name}
          </h2>
        </div>
      </div>
      <div className="flex justify-start gap-5">
        {/* <p>Required Tier: {getTier(assessment?.tier)} </p>
        <p>Minimum Score: </p> */}
        <p>Estimated Completion Time: {assessment?.duration ?? 0} mins </p>
      </div>
      <GreenUnderlineHeading title="details" />
      <div
        dangerouslySetInnerHTML={sanitizeMarkup(assessment?.description)}
        className="dangerously"
      ></div>
      <GreenUnderlineHeading title="Skills" />
      {assessment &&
        assessment?.tests_detail?.map((item: any, index: any): any => {
          return (
            <div className={"w-full"} key={index}>
              <div className="w-full">
                <TestQuestions details={item?.test?.covered_skills} />
              </div>
            </div>
          );
        })}
      {enrollment?.invitation_link && enrollment?.personality === null && (
        <div className="my-5">
          {enrollment?.average && (
            <div className="py-2">
              <p>Current Tier {getCurrentTier(assessment, enrollment)}</p>
            </div>
          )}

          {(enrollment?.average || enrollment?.personality) && (
            <div className="py-2">
              {`Current Score
              ${
                enrollment?.average
                  ? `${enrollment?.average}%`
                  : enrollment?.personality
              }`}
            </div>
          )}

          <div className="pb-2 mt-10 flex w-1/2 mx-auto">
            <button
              onClick={toggleInstructions}
              className=" py-3 text-white bg-instant-teams-blue-Main w-full rounded-md uppercase hover:bg-instant-teams-blue-D1 "
            >
              Start Assessment
            </button>
          </div>
        </div>
      )}
      {!enrollment?.invitation_link && application && (
        <div className="pb-2 mt-10 flex w-1/2 mx-auto">
          <button
            className=" py-3 text-white bg-gray-500 w-full rounded-md uppercase hover:bg-instant-teams-blue-D1 "
            disabled
          >
            Registering Assessment - Please Wait
          </button>
        </div>
      )}
      {isShowingInstructions ? (
        <DialogWrapper
          size="mt-36 w-1/2"
          title="Assessments Instructions"
          onClose={toggleInstructions}
        >
          <AssessmentInstructions invitationLink={enrollment.invitation_link} />
        </DialogWrapper>
      ) : null}
    </div>
  );
}

export default SeekerAssessmentDetails;
