import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../../providers/auth";
import { addAccelerator } from '../../../../data/accelerators';

function AddAcceleratorModal({toggle}:{toggle: Function}) {
  const [newName, setNewName] = useState(null);
  async function handleAdd() {
    if(newName) {

      await addAccelerator({"name": newName, "status": "created"});
      toggle();
    }
  }

  return (
    <div>
      <div className="relative mb-4">
        <label className="text-instant-teams-teal-Main text-sm  absolute  duration-300 transform -translate-y-4 scale-75 top-4 origin-[0] left-2.5 peer-focus:text-instant-teams-blue-Main peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
          Accelerator Name
        </label>
        <input 
          type="text" 
          className="form-input shadow-md rounded-md w-full pt-3 pb-3" 
          onChange={(e) => {setNewName(e.target.value)}}
        />
      </div>
      <button 
        className="bg-instant-teams-teal-Main rounded-md shadow-md text-white px-1.5 py-1.5 w-full" 
        onClick={handleAdd}
      >
        Add Accelerator
      </button>
    </div>
  );
}

export default AddAcceleratorModal;
