import GeneralInfoCategoryPost_NewUI from "./GeneralInfoCategoryPost_NewUI";

const GeneralInfoPostDisplay_NewUI = ({ posts }) => {
  return (
    <>
      {posts &&
        posts?.length > 0 &&
        posts.map((post) => <GeneralInfoCategoryPost_NewUI id={post?.node?.id} />)}
    </>
  );
};

export default GeneralInfoPostDisplay_NewUI;
