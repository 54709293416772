import Meta from "../../components/custom-ui/meta/Meta";
import AcceptInvitation from "../../components/invitations/AcceptInvitation";
import HeroImageWrapper from "../../components/layout/HeroImageWrapper";
import { checkForAuth } from "../../providers/auth";
function InvitationPage() {
  return (
    <div className="pt-28">
      <Meta title="Invitation" />
      <HeroImageWrapper>
        <AcceptInvitation />
      </HeroImageWrapper>
    </div>
  );
}
export default checkForAuth(InvitationPage);
