import { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import DailyIframe from "@daily-co/daily-js";
import { useMeeting } from "../../../data/meetings";
import BgImage from './Assests/bgimg.jpg';
import { any } from "zod";

function JoinLiveMeetingPage() {
  const { meetingId = "" } = useParams();
  const { data: meetingData, status: meetingQueryStatus } =
    useMeeting(meetingId);
  console.log(meetingData);
  const CALL_OPTIONS = {
    url: "",
    iframeStyle: {
      width: "100%",
      height: "100%",
      border: "1px solid #e6eaef",
      borderRadius: "6px 6px 0 0",
    },
    showLeaveButton: true,
    showFullscreenButton: false,

  };
  const DEFAULT_HEIGHT = 400;
  const videoRef = useRef(null);

  const [callframe, setCallframe] = useState(null);
  const [callInstance, setCallInstance] = useState();
  const [hasRecordingPermission, setHasRecordingPermission] = useState(false);
  const [dataUri, setDataUri] = useState()


  // Inside the useEffect where you create the call frame


  const dailyRoomUrl = meetingData?.dailyRoom?.url;
  useEffect(() => {
    if (callframe) {
      console.log(callframe);
      callframe.destroy();
    }
  }, []);


  const convertToDataURL = (file: any, callback: any) => {
    const reader = new FileReader();
    reader.onload = (e) => callback(e?.target?.result);
    reader.readAsDataURL(file);
  };

  const handleImageConversion = () => {
    fetch(BgImage)
      .then((response) => response.blob())
      .then((blob) => {
        convertToDataURL(blob, (dataURL: any) => {
          setDataUri(dataURL)
        });
      })
      .catch((error) => console.error('Error fetching image:', error));
  };


  useEffect(() => {
    handleImageConversion();
  }, []);

  useEffect(() => {
    // if DOM element isn't available or if callframe already exist exit
    if (!videoRef || !videoRef.current || callframe) return;
    if (dailyRoomUrl) {
      try {
        CALL_OPTIONS.url = dailyRoomUrl;
        const newCallframe = DailyIframe.createFrame(
          videoRef.current,
          CALL_OPTIONS,
        );
        newCallframe.on("meeting-session-updated", (e) => {
          console.log(e);
          const numOfParticipants = newCallframe.participantCounts();
          console.log(numOfParticipants);
        });
        newCallframe.on("joined-meeting", (e) => {
          console.log(e);
          setCallInstance(DailyIframe.getCallInstance());
          newCallframe.startRecording({
            width: 1280,
            height: 720,
            fps: 25,
            backgroundColor: "#FF1F2D3D",
            layout: {
              preset: "default",
              max_cam_streams: 5,
            },
          });
        });
        // const bgImageDataURI = BgImage && URL.createObjectURL(BgImage);
        newCallframe.updateInputSettings({
          video: {
            processor: {
              type: 'background-image',
              config: {
                source: `${dataUri}`,
              },

            },
          },
        });

        newCallframe.on("recording-started", (e) => {
          console.log(e);
        });
        console.log(newCallframe);
        console.log("preparing to join meeting....");
        newCallframe.join().then(() => {
          console.log("joining meeting...");

          setCallframe(newCallframe);
        });
      } catch (error) {
        console.log("Problem joining meeting...", error);
      }
    }
    return () => {
      if (callframe) {
        console.log("leave and destroy callframe.");
        callframe.leave();
        callframe.destroy();
      }
    };
  }, [videoRef.current, dailyRoomUrl]);


  return (
    <>
      <div className="mt-28 h-screen">
        <div className="h-full">
          {/* @ts-ignore */}
          <div className="h-full" ref={videoRef}></div>
        </div>
      </div>
      {!hasRecordingPermission && (
        <DialogWrapper
          onClose={() => { }}
          title="Recording Notice"
          size=" w-1/3 sm:w-full md:w-full mt-36"
        >
          <>
            <div className="w-full text-center text-xl text-instant-teams-teal-D1 font-bold">
              <p>This meeting will be recorded.</p>
              <p>Please select 'Continue' to proceed.</p>

              <button
                className="mt-4 md:w-full w-80 py-2 px-4 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                onClick={() => setHasRecordingPermission(true)}
              >
                Continue
              </button>
            </div>
          </>
        </DialogWrapper>
      )}
    </>
  );
}

export default JoinLiveMeetingPage;
