import React, { useEffect, useState } from "react";
import CardContainer from "./CardContainer";
import { employersDashboard } from "../../../callable-cloud-functions/cloudFunctions";
import LoadingSpinner from "./LoadingIndicator/LoadingSpinner";


const EmployersCard = () => {

    const [resData, setResData] = useState()
    useEffect(() => {
        (async () => {
            const res = await employersDashboard();
            setResData(res?.data)

        })();
    }, []);

    const totalEmpCount = resData?.employers || 0
    const activeEmp = resData?.active || 0
    const hiring = resData?.hiring || 0
    const pendingInvitation = resData?.pendingInvitation || 0

    return (
        <CardContainer cardTitle={"Employers"} bgColor="#E6F1FC" showInfo={false}>

            {!resData ? (<div className="w-full h-[80%] items-center flex justify-center  ">
                <LoadingSpinner />
            </div>) : (
                <div className="w-full mt-4">
                    <p className="text-[#1E596D] text-[12px]">Total</p>
                    <h1 className="text-[#1E596D] font-extrabold text-3xl">{totalEmpCount}</h1>
                    <div className="grid grid-cols-3 gap-4 w-full mt-2">
                        <div>
                            <p className="text-[#1E596D] text-[12px]">Active</p>
                            <h1 className="text-[#1E596D] font-extrabold text-2xl">{activeEmp}</h1>
                        </div>
                        <div>
                            <p className="text-[#1E596D] text-[12px]">Hiring</p>
                            <h1 className="text-[#1E596D] font-extrabold text-2xl">{hiring}</h1>
                        </div>
                        <div>
                            <p className="text-[#1E596D] text-[12px]">Pending Invitations</p>
                            <h1 className="text-[#1E596D] font-extrabold text-2xl">{pendingInvitation}</h1>
                        </div>
                    </div>
                </div>
            )}

        </CardContainer>
    )

}

export default EmployersCard;
