import { useEffect } from "react";
import { DisplayTimestamp } from "../../util/timestamps/timestamp";
import { Note } from "../../dataTypes/Note";
import { useUser } from "../../providers/database";
import { roles } from "../../data/dictionary/role";
import { useRole } from "../../hooks/useRole";
import { useState } from "react";
import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";
function NoteDisplay({ entry }: { entry: Note }) {
  const { data: notetaker } = useUser(entry?.who);

  const getRole = useRole();
  const role = getRole(notetaker?.claims?.role, true);

  return (
    <>
      <tr
        className={
          TailwindHoverEnlarge(103) + "pl-5 border-b border-black h-24"
        }
      >
        <td className="w-1/4 p-2">
          <strong>
            by: {notetaker?.preferredName ?? notetaker?.name} - {role ?? ""}
          </strong>
          <br />
          <span className="text-xs">
            Date: {DisplayTimestamp(entry?.timestamp)}
          </span>
          <br />
          <span className="text-xs">{entry?.type}</span>
        </td>
        <td className="w-1/2">
          <br />
          {entry?.note}
        </td>
      </tr>
    </>
  );
}

export default NoteDisplay;
