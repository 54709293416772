import React from "react";
import { useEffect } from "react";
import { useAuth } from "../../../providers/auth";
import { useGlobalState } from "../../../providers/globalState/GlobalStateProvider";
import { Link } from "react-router-dom";
import { useEmployer } from "../../../data/employer";
import { useState } from "react";
import JobMetrics from "../../../charts/JobMetrics";
import EmployerApplicationTrends from "../../../charts/EmployerApplicationTrends";
import useModal from "../../../hooks/useModal";
import { useAllActiveAlerts } from "../../../data/alerts";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import AlertDisplay from "../../alerts/AlertDisplay";

function GenDashboard() {
  const { globalState, setGlobalState } = useGlobalState();
  const auth = useAuth();
  const [id, setId] = useState(auth.userClaims.claims.employerId);
  const { data: alerts } = useAllActiveAlerts();
  const { isShowing: isShowingAlerts, toggle: toggleAlerts } = useModal();
  let hideAlerts = sessionStorage.getItem("hideAlerts");
  const {
    data: employer,
    status: employerStatus,
    error: employerError,
  } = useEmployer(id ?? "");

  const handleAlertClose = () => {
    sessionStorage.setItem("hideAlerts", "true");
    toggleAlerts();
  };

  useEffect(() => {
    setId(auth.userClaims.claims.employerId);
  }, [auth]);

  return (
    <>
      <div className="w-full pb-36 min-h-screen">
        <div className="text-center grid grid-flow-col grid-cols-5 gap-4 mb-4">
          <div className="w-full col-span-2">
            <h1 className="text-3xl font-bold">{employer?.displayName}</h1>
          </div>
          <div className="w-full">
            <Link to={`/company/departments`}>
              <button className="bg-instant-teams-teal-Main text-white hover:bg-instant-teams-blue-Main w-full rounded-lg h-10">
                Departments
              </button>
            </Link>
          </div>
          <div className="w-full">
            <Link to={`/jobs`}>
              <button className="bg-instant-teams-teal-Main text-white hover:bg-instant-teams-blue-Main w-full rounded-lg h-10">
                Jobs
              </button>
            </Link>
          </div>
          <div className="w-full">
            <Link to={`/company/offers`}>
              <button className="bg-instant-teams-teal-Main text-white hover:bg-instant-teams-blue-Main w-full rounded-lg h-10">
                Offers
              </button>
            </Link>
          </div>
        </div>
        <div className="text-center grid grid-flow-col grid-cols-2 gap-4">
          <div className="col-span-1">
            <JobMetrics employerId={id} />
          </div>
          <div className="col-span-1">
            <EmployerApplicationTrends employerId={id} />
          </div>
        </div>
      </div>
      {!isShowingAlerts && !hideAlerts && alerts?.length > 0 && (
        <DialogWrapper
          title="System Alerts"
          onClose={handleAlertClose}
          size="w-1/3"
        >
          {/* @ts-ignore */}
          <AlertDisplay alerts={alerts} />
        </DialogWrapper>
      )}
    </>
  );
}

export default GenDashboard;
