import { useEffect, useState } from "react";

import { useComponent } from "../../data/academy";
import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";
import { createEnrollmentProcess } from "../../data/academy";
import { Timestamp } from "firebase/firestore";
import { useAuth } from "../../providers/auth";
import toast from "react-hot-toast";
import { DisplayDate } from "../../util/timestamps/timestamp";
import { useUser } from "../../data/user";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import useModal from "../../hooks/useModal";
import EnrollmentDetails from "./enrollmentDetails";
import DisplayBadges from "../custom-ui/badge/displayBadge";

function Badge({ enrollment, type }: { enrollment: any; type: string }) {
  const user = useAuth();
  const [allowRetry, setAllowRetry] = useState(true);
  const [manualUser, setManualUser] = useState("");
  const { data: assessment } = useComponent(enrollment.assessmentId);

  const [status, setStatus] = useState("active");
  const { data: manualUserData } = useUser(manualUser);
  const { isShowing, toggle } = useModal();

  useEffect(() => {
    if (enrollment && enrollment?.assessedBy) {
      setManualUser(enrollment.assessedBy);
    }
    if (enrollment?.average && enrollment?.average >= 80) {
      setAllowRetry(false);
    }
  }, [enrollment]);

  const removeInvite = () => {
    const request = {
      action: "remove",
      assessmentId: enrollment.assessmentId,
      email: enrollment.email,
      timestamp: Timestamp.now(),
      who: user?.user?.uid,
    };
    createEnrollmentProcess(request);
    setStatus("delete");
    toast.success("Removing Invite - 2 minute wait");
  };

  const deleteManual = () => {
    if (
      enrollment.hasOwnProperty("manuallyAssessed") &&
      enrollment.manuallyAssessed === true
    ) {
      const request = {
        action: "delete",
        assessmentId: enrollment.assessmentId,
        email: enrollment.email,
        recId: enrollment.id,
        timestamp: Timestamp.now(),
        who: user?.user?.uid,
      };
      createEnrollmentProcess(request);
      setStatus("delete");
      toast.success("Deleting Manual Assessment");
    } else {
      toast.success("Not a manual assessment");
    }
  };

  const retryAssessment = () => {
    const request = {
      action: "retry",
      assessmentId: enrollment.assessmentId,
      email: enrollment.email,
      timestamp: Timestamp.now(),
      who: user?.user?.uid,
    };
    createEnrollmentProcess(request);
    setStatus("retry");
    toast.success("Creating Retry Invite - 2 minute wait");
  };

  return (
    <div key={enrollment.id} className={TailwindHoverEnlarge(110) + "p-2 m-2"}>
      <div className="items-center rounded-b-lg relative" onClick={toggle}>
        <DisplayBadges
          size={32}
          assessmentId={assessment?.id}
          enrollment={enrollment}
        />
      </div>
      <p className="w-full text-center text-instant-teams-teal-D2 font-bold text-sm">
        {assessment?.displayName || assessment?.name}
      </p>
      <p className="text-center text-instant-teams-teal-D2 font-bold w-full text-sm">
        {enrollment?.status?.toUpperCase()}
      </p>
      <p className="text-center text-instant-teams-teal-D2 font-bold w-full text-sm">
        {enrollment?.personality || ""}
      </p>
      <p className="text-center text-instant-teams-teal-D2 font-bold w-full text-sm">
        {DisplayDate(enrollment?.createdAt || enrollment?.manuallyAssessedDate)}
      </p>
      {type === "invited" && status == "active" && (
        <p className="text-center">
          <button
            className="py-1 px-2 mt-2 text-sm text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none ml-auto"
            onClick={removeInvite}
          >
            Remove Invite
          </button>
        </p>
      )}
      {type === "earned" &&
        status == "active" &&
        !enrollment?.hasOwnProperty("manuallyAssessed") &&
        allowRetry &&
        (user?.role.id === "sadmin" || user?.role.id === "tac") && (
          <p className="text-center">
            <button
              className="py-1 px-2 mt-2 text-sm text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none ml-auto"
              onClick={retryAssessment}
            >
              Allow Retry
            </button>
          </p>
        )}
      {status === "delete" &&
        !enrollment.hasOwnProperty("manuallyAssessed") && (
          <div className="w-full text-center">
            <button
              disabled
              className="py-1 px-2 mt-2 text-sm text-white bg-red-800 rounded border-0 focus:outline-none ml-auto shadow-xl"
            >
              Removing Invite
            </button>
          </div>
        )}
      {status === "retry" && !enrollment.hasOwnProperty("manuallyAssessed") && (
        <div className="w-full text-center">
          <button
            disabled
            className="py-1 px-2 mt-2 text-sm text-white bg-green-800 rounded border-0 focus:outline-none ml-auto shadow-xl"
          >
            Creating Retry Invite
          </button>
        </div>
      )}
      {enrollment.hasOwnProperty("manuallyAssessed") && (
        <>
          <p className="text-center">
            Assessed by : {manualUserData?.name || "Unknown"}
          </p>
          <p className="text-center">
            <button
              className="py-1 px-2 mt-2 text-sm text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none ml-auto"
              onClick={deleteManual}
            >
              Remove Manual Assessment
            </button>
          </p>
        </>
      )}
      {isShowing && enrollment.status === "completed" && (
        <DialogWrapper
          onClose={() => {
            toggle();
          }}
          title="Assessment Details"
          size="w-1/2  mt-36"
        >
          <EnrollmentDetails enrollment={enrollment} />
        </DialogWrapper>
      )}
    </div>
  );
}
export default Badge;
