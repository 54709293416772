import { useState, useEffect } from "react";
import { requireAuth, useAuth } from "../../../../providers/auth";
import { useAffiliations } from "../../../../data/affiliation";
import AffiliationItem from "./AffiliationsItem";

const Affiliations = ({
  userId,
  background,
}: {
  userId: string;
  background: string;
}) => {
  const { userClaims } = useAuth();
  const [id, setId] = useState(userId);
  const { data: affiliation, error: affiliationError } = useAffiliations(id);

  type AffiliationRecord = {
    id: string;
    branch: string;
    affiliation: string;
  };

  return (
    <>
      {userClaims?.claims?.type === "S" ? (
        <>
          <div className="flex flex-col w-full space-y-3 text-lg">
            {affiliation && affiliation.length > 0
              ? affiliation.map((affiliation: AffiliationRecord) => {
                  return (
                    <AffiliationItem
                      key={affiliation.id}
                      affiliation={affiliation}
                      background={background}
                    />
                  );
                })
              : "None"}
          </div>
        </>
      ) : (
        <div className="flex w-full font-black text-instant-teams-teal-D1">
          {affiliation
            ? affiliation.map((affiliation: AffiliationRecord) => {
                return (
                  <AffiliationItem
                    key={affiliation.id}
                    affiliation={affiliation}
                    background={background}
                  />
                );
              })
            : "None"}
        </div>
      )}
    </>
  );
};

export default requireAuth(Affiliations);
