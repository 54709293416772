import {
  Hits,
  HitsPerPage,
  Pagination,
  Configure,
  SearchBox,
  Stats,
  SortBy,
  ClearRefinements,
  RefinementList,
  CurrentRefinements,
  RefinementListProps,
} from "react-instantsearch-hooks-web";
import EmployeeLineComponent from "../parts/EmployeeLineComponent";
import { getLabel } from "../../../../util/search/getLabel";

function EmployeesAlgolia({
  configureFilterString,
}: {
  configureFilterString: string;
}) {
  const transformEmploymentStatus: RefinementListProps["transformItems"] = (
    items,
  ) => {
    items = items.map((item: any) => ({
      ...item,
      label: getLabel(item.label, "employmentStatus"),
    }));
    return items;
  };

  return (
    <>
      <div className="">
        <div className=" w-full">
          <div className="flex mb-4 justify-end w-[100%]">
            <div className="flex items-center">
              <div className=" pr-4">Results per page:</div>
              <HitsPerPage
                items={[
                  { label: "10", value: 10 },
                  { label: "25", value: 25 },
                  { label: "50", value: 50, default: true },
                  { label: "100", value: 100 },
                ]}
                classNames={{ select: "text-xs h-6 py-0" }}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-2">
            <Configure filters={configureFilterString} />
            <SearchBox
              placeholder="Keyword Search"
              classNames={{
                input: "w-full",
                submitIcon: "hidden",
                resetIcon: "hidden",
                submit: "hidden",
              }}
            />
            <CurrentRefinements />
            Filter by Status
            <RefinementList
              attribute="status"
              operator="and"
              classNames={{ root: "capitalize" }}
              transformItems={transformEmploymentStatus}
            />
            Filter by Start Date
            <RefinementList attribute="formattedStartDate" operator="and" />
            <ClearRefinements
              translations={{ resetButtonText: "Clear Filters" }}
              classNames={{
                button:
                  " bg-instant-teams-teal-Main text-white p-2 rounded mt-5 text-center w-full",
              }}
            />
          </div>
          <div className="col-span-10">
            <Hits
              hitComponent={(hit: any) => <EmployeeLineComponent row={hit} />}
              classNames={{
                list: "w-full flex px-24 flex-col",
              }}
            />
          </div>
          <div className="mt-12 w-full flex justify-center col-span-12">
            <Pagination
              showNext={true}
              padding={1}
              classNames={{
                list: "flex",
                item: "h-8 w-8 text-center rounded-m rounded mx-1 bg-white",
                selectedItem: "scale-125 border-black",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployeesAlgolia;
