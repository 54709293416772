import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAllActiveApplications } from "../../../../data/seeker";
import SeekerApplicationListItem from "./seekerApplicationListItem";

function SeekerApplicationList({ id }: { id: string }) {
  const { data: applications } = useAllActiveApplications(id);
  const params = useParams();

  return (
    <>
      <div className="w-full text-base text-black font-normal mt-4">
        {applications?.length !== 0 ? (
          applications?.map((row: any, index: string) => {
            return <SeekerApplicationListItem entry={row} key={index} />;
          })
        ) : (
          <div className="text-lg text-center text-instant-teams-teal-Main w-full">
            No Applications
          </div>
        )}
      </div>
    </>
  );
}

export default SeekerApplicationList;
