import { memo } from "react";
import React from "react";
import LockedFieldIcon from "../icons/LockedFieldIcon";

const GreenUnderlineHeading = memo((props) => {
  return (
    <>
      {props.showLockIcon && (
        <div className="flex items-center justify-between mt-6 mb-6  font-bold text-instant-teams-teal-D1 border-b-2 border-instant-teams-green-D2">
          <span className="text-xl uppercase text-left">{props.title}</span>
          <LockedFieldIcon isLocked={props.isLocked} text={props.text} />
        </div>
      )}
      {props.noMargin && (
        <div className="border-b-2 border-instant-teams-green-D2 text-left">
          <span className='uppercase text-xl font-bold text-instant-teams-teal-D1 text-left'>{props.title}</span>
          <span className='float-right pr-2'>
            {props.children && React.cloneElement(props.children)}
          </span>
        </div>
      )}
      {!props.hasOwnProperty("noMargin") && !props.hasOwnProperty("showLockIcon") && (
        <div className="mt-6 mb-6 border-b-2 border-instant-teams-green-D2 text-left">
          <span className='uppercase text-xl font-bold text-instant-teams-teal-D1 text-left'>{props.title}</span>
          <span className='float-right pr-10'>
            {props.children && React.cloneElement(props.children)}
          </span>
        </div>
      )}

    </>
  );
});

export default GreenUnderlineHeading;
