import { Message } from "../../dataTypes/Messages";
import { useUser } from "../../providers/database";
import { DisplayTime } from "../../util/timestamps/timestamp";
import { useAuth } from "../../providers/auth";
import MessageItem from './MessageItem';

const MessagePanelItem = ({
  message,
  minimize,
}: {
  message: Message;
  chatId: string;
  minimize: boolean;
}) => {
  const { user } = useAuth();
  const { body, senderId, id: messageId } = message;
  const { data: userData } = useUser(senderId);

  const isCurrentUser = user?.claims?.type === userData?.claims?.type;
  const containerClassName =
    isCurrentUser && !minimize ? "justify-end" : "justify-start";
  const senderName = userData?.name;

  return (
    <>
      {userData && (
        <MessageItem
          message={message}
          userData={userData}
          minimize={minimize}
          isCurrentUser={isCurrentUser}
        />
      )}
    </>
  );
};

export default MessagePanelItem;
