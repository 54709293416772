import React, { useState, useEffect, useRef } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { US_state } from "./UtilFileForCohort/getCohortSupportData";
import { countriesList } from "./UtilFileForCohort/getCohortSupportData";

function CustomSelect({ selectedOptions, setSelectedOptions, toggleSerachResult }: any) {


    const stateArray = Object.entries(US_state).map(([code, state]) => ({ code, state }));
    const countriesListArr = Object.entries(countriesList).map(([code, country]) => ({ code, country }));
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const toggleOption = (code: string) => {
        setSelectedOptions((prevSelectedOptions: any) =>
            prevSelectedOptions.includes(code)
                ? prevSelectedOptions.filter((option: any) => option !== code)
                : [...prevSelectedOptions, code]
        );
    };

    const removeOption = (code: string) => {
        setSelectedOptions((prevSelectedOptions: any) =>
            prevSelectedOptions.filter((option: any) => option !== code)
        );
    };

    useEffect(() => {
        if (dropdownVisible) {
            toggleSerachResult(true);
        } else {
            toggleSerachResult(false);
        }
    }, [dropdownVisible]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !(dropdownRef.current as any).contains(event.target)) {
                setDropdownVisible(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="relative inline-block w-full" ref={dropdownRef}>
            {selectedOptions.length === 0 ? "" : (
                <div className="flex flex-wrap">
                    {selectedOptions?.map((code: any) => {
                        const label = stateArray.find((state: any) => state.code === code)?.state || countriesListArr.find((country)=>country.code ===code)?.country;
                        return (
                            <div key={code} className="flex items-center text-[12px] bg-gray-100 px-3 m-1 border rounded-xl">
                                {label}
                                <button
                                    className="ml-2 text-[15px] text-gray-500"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        removeOption(code);
                                    }}
                                >
                                    &times;
                                </button>
                            </div>
                        );
                    })}
                </div>
            )}
            <div
                className="border flex justify-between items-center border-gray-500 text-gray-600 px-1 py-1 mt-2 cursor-pointer rounded"
                onClick={() => setDropdownVisible(!dropdownVisible)}
            >
                Select Location
                <button
                    className="ml-2 text-sm text-gray-900"
                    onClick={(e) => {
                        e.stopPropagation();
                        setDropdownVisible(!dropdownVisible);
                    }}
                >
                    {!dropdownVisible ? (
                        <FontAwesomeIcon icon={faChevronDown} />
                    ) : (
                        <FontAwesomeIcon icon={faChevronUp} />
                    )}
                </button>
            </div>
            {dropdownVisible && (
                <div className="absolute w-full border border-gray-100 bg-white mt-3 z-10 rounded">
                   
                    {stateArray?.map((data: any) => (
                        <label key={data.code} className="flex items-center text-gray-600 cursor-pointer px-2 py-1">
                            <input
                                type="checkbox"
                                className="mr-2"
                                checked={selectedOptions.includes(data.code)}
                                onChange={() => toggleOption(data.code)}
                            />
                            {data.state}
                        </label>
                    ))}
                <div className="w-full p-1">
                    <hr className="w-full my-4 h-[2px] bg-gray-500 rounded-lg " />
                </div>
                    {countriesListArr?.map((data: any) => (
                        <label key={data.code} className="flex items-center text-gray-600 cursor-pointer px-2 py-1">
                            <input
                                type="checkbox"
                                className="mr-2"
                                checked={selectedOptions.includes(data.code)}
                                onChange={() => toggleOption(data.code)}
                            />
                            {data.country}
                        </label>
                    ))}
                </div>
            )}
        </div>
    );
}

export default CustomSelect;
