import parse from "html-react-parser";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";

const TestDescription = ({ details }) => {
  return (
    <div className="w-full mt-5 mb-10">
      <GreenUnderlineHeading title="Description" />
      {details?.trim().length > 7 ? (
        parse(details)
      ) : (
        <p>No description available</p>
      )}
    </div>
  );
};

export default TestDescription;
