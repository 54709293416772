import { useQuery } from "react-query";
import {
  doc,
  collection,
  query,
  setDoc,
  where,
  orderBy,
  getDoc,
  getDocs,
  updateDoc,
  runTransaction,
  deleteDoc,
  addDoc,
  Timestamp,
} from "firebase/firestore";
import { db, createQuery } from "../providers/database";

import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";

export function useShare(shareId: string) {
  return useQuery(
    ["share", { shareId }],
    createQuery(() => doc(db, collectionNames.share, shareId)),
  );
}

export function useShareByTypeAndId(type: string, typeId: string) {
  return useQuery(
    ["shareByTypeandID", { type, typeId }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.share}`,
        ),
        where("type", "==", type),
        where("typeId", "==", typeId),
        orderBy("createdAt"),
      );
    }),
  );
}

export function createShare(data: any) {
  return addDoc(collection(db, collectionNames.share), data);
}
