import { useContext, createContext, useEffect } from "react";
import { useInterviewQuestionsByApplication } from "../data/interviewQuestion";
import { useJobInterviewQuestions } from "../data/jobDescriptions";
import { ApplicationContext } from "../providers/ApplicationProvider";

const useApplicationRecordings = (applicationId: string, jobId: string) => {
  const { recordings, setRecordings } = useContext(ApplicationContext);

  const {
    data: answersData,
    isLoading: isApplicationInterviewLoading,
    isError: isApplicationInterviewError,
  } = useInterviewQuestionsByApplication(applicationId);

  const {
    data: questionsData,
    isLoading: isJobDescriptionsInterviewLoading,
    isError: isJobDescriptionsInterviewError,
  } = useJobInterviewQuestions(jobId);

  useEffect(() => {
    if (questionsData?.length) {
      setRecordings(
        questionsData
          .sort((a: any, b: any) => a.sortOrder - b.sortOrder)
          .map((d: any) => {
            const answer = answersData?.find(
              ({ id }: { id: string }) => d.id === id,
            );
            return {
              ...d,
              // eslint-disable-next-line no-nested-ternary
              reAttempts:
                answer?.reAttempts != null
                  ? answer?.reAttempts
                  : d.reAttempts != null
                  ? d.reAttempts
                  : 1,
              maxTime: d.maxTime ? d.maxTime / 1000 : 240,
              prepTime: d.prepTime ? d.prepTime / 1000 : 90,
              answer: answer?.answer || null,
            };
          }),
      );
    }
  }, [answersData, questionsData]);

  return {
    loading: isJobDescriptionsInterviewLoading || isApplicationInterviewLoading,
    error: isJobDescriptionsInterviewError || isApplicationInterviewError,
    recordings,
    setRecordings,
    questionsData
  };
};

export default useApplicationRecordings;
