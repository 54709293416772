import { useParams, useNavigate } from "react-router-dom";
import { requireAuth } from "../../providers/auth";
import ApplicationHeader from "./ApplicationHeader";
import { useJobDescription } from "../../data/jobDescriptions";
import { useJobPositionDetails } from "../../data/positions";
import { useApplication } from "../../data/applications";
import ClapSVG from "../../components/custom-ui/icons/ClapSVG";
import Meta from "../../components/custom-ui/meta/Meta";
import ExternalLinkIcon from "../../components/custom-ui/icons/ExternalLinkIcon";
function MissionComplete() {
  const { applicationId = "" } = useParams();
  const navigate = useNavigate();
  const { data: applicationData } = useApplication(applicationId);

  const { data: jobData, isLoading: isJobDataLoading } = useJobDescription(
    applicationData?.jobId,
  );

  const { data: positionData, isLoading: isPositionDataLoading } =
    useJobPositionDetails(applicationData?.jobId, applicationData?.positionId);
  return (
    <>
      {!isPositionDataLoading && !isJobDataLoading && (
        <Meta title={`${jobData?.title}, ${positionData?.positionName}`} />
      )}
      <div className="pt-40 w-3/4 mx-auto">
        <ApplicationHeader
          applicationData={applicationData}
          jobData={jobData}
          positionData={positionData}
          disabled={true}
          renderButtons={false}
        />
        <div className="w-3/4 mt-32 grid grid-cols-3 gap-10 mx-auto space-y-5 text-instant-teams-blue-Main">
          <div className="col-span-1">
            <ClapSVG />
          </div>
          <div className="col-span-2 mx-auto ml-5 text-center ">
            <h2 className="font-semibold text-3xl">Mission Complete!</h2>
            <p className="my-2">
              We are excited by your interest in this position and can confirm
              it&apos;s been received. You can expect to hear back from our
              Talent Team within 5-7 business days regarding next steps.
            </p>
            <div className="my-2">
              If you&apos;re a military spouse, we&apos;d love to see you over
              in our
              <span className="text-center flex justify-center">
                <ExternalLinkIcon
                  linkText="Twelve Million Plus Community!"
                  href="https://www.twelvemillionplus.com/"
                />
              </span>{" "}
              Active, Reserve, Guard, and Veteran spouses welcome.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default requireAuth(MissionComplete);
