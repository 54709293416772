import { useState } from "react";

const railStyle = {
  position: "absolute",
  width: "100%",
  height: 40,
  top: -13,
  borderRadius: 7,
  cursor: "pointer",
  opacity: 0.3,
  zIndex: 1,
};

const railCenterStyle = {
  position: "absolute",
  width: "100%",
  height: 5,
  borderRadius: 7,
  cursor: "pointer",
  pointerEvents: "none",
  // hex codes with transparency reference https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
  backgroundColor: "#19405E80",
};

export function TooltipRail({ getRailProps }) {
  const [value, setValue] = useState(null);
  const [percent, setPercent] = useState(null);

  const onMouseEnter = () => {};
  const onMouseLeave = () => {};
  const onMouseMove = () => {};

  return (
    <>
      <div
        style={railStyle}
        {...getRailProps({ onMouseEnter, onMouseLeave })}
      />
      <div style={railCenterStyle} />
    </>
  );
}

// *******************************************************
// HANDLE COMPONENT
// *******************************************************

export function Handle({
  handle,
  domain,
  disabled = false,
  isActive,
  getHandleProps,
  formatTooltip = (val) => val, // default just returns value as is.
}) {
  const [mouseOver, setMouseOver] = useState(false);

  const { id, value, percent } = handle;
  const onMouseEnter = () => setMouseOver(true);
  const onMouseLeave = () => setMouseOver(false);

  return (
    <>
      <div
        className="absolute text-sm p-1 rounded bg-instant-teams-blue-Main text-white"
        style={{
          left: `${percent}%`,
          marginLeft: "-11px",
          marginTop: "-42px",
        }}
      >
        <div className="tooltip">
          <span className="tooltiptext">{formatTooltip(value)}</span>
        </div>
      </div>

      <div
        role="slider"
        aria-valuemin={domain?.min}
        aria-valuemax={domain?.max}
        aria-valuenow={value}
        style={{
          left: `${percent}%`,
          position: "absolute",
          marginLeft: "-11px",
          marginTop: "-8px",
          zIndex: 1,
          width: 20,
          height: 20,
          cursor: "pointer",
          border: 0,
          borderRadius: "50%",
          boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.4)",
          backgroundColor: disabled ? "#707070" : "#FFFFFF",
        }}
        {...getHandleProps(id, {
          onMouseEnter,
          onMouseLeave,
        })}
      />
    </>
  );
}

// *******************************************************
// TRACK COMPONENT
// *******************************************************
export function Track({ source, target, getTrackProps, disabled = false }) {
  return (
    <div
      style={{
        position: "absolute",
        height: 5,
        backgroundColor: disabled ? "#707070" : "#19405E",
        borderRadius: 7,
        cursor: "pointer",
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
}

// *******************************************************
// TICK COMPONENT
// *******************************************************

const defaultFormat = (d) => `d`;

export function Tick({ tick, count, format = defaultFormat }) {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          marginTop: 17,
          width: 1,
          height: 5,
          backgroundColor: "rgb(200,200,200)",
          left: `${tick.percent}%`,
        }}
      />
      <div
        style={{
          position: "absolute",
          marginTop: 25,
          fontSize: 10,
          textAlign: "center",
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {format(tick.value)}
      </div>
    </div>
  );
}
