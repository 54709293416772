/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { isNil } from "lodash";
import StatusNote from "./modals/StatusNote";
import toast from "react-hot-toast";
import ToastAlert from "../toast-alert/ToastAlert";
import useModal from "../../../hooks/useModal";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../providers/database";

const AutoSaveStatusSelector = ({
  status,
  collection,
  recId,
  required,
  name,
  display,
  placeholder,
  directions,
}) => {
  const [currentStatus, setCurrentStatus] = useState("");
  const [statusID, setStatusID] = useState(status);
  const [statusCollection, setStatusCollection] = useState("");
  const [mainCollection, setMainCollection] = useState(collection);

  useEffect(() => {
    if (mainCollection === "Employer") {
      setStatusCollection("EmployerStatus");
    }
    if (mainCollection === "Users") {
      setStatusCollection("UserStatus");
    }
    if (mainCollection === "Applications") {
      setStatusCollection("ApplicationStatus");
    }
    if (mainCollection === "Invitations") {
      setStatusCollection("InvitationStatus");
    }
    if (mainCollection === "JobDescriptions") {
      setStatusCollection("JobStatus");
    }
    if (mainCollection === "AcademyAssessments") {
      setStatusCollection("AssessmentStatus");
    }
    if (mainCollection === "AcademyAccelerators") {
      setStatusCollection("AssessmentStatus");
    }
    if (mainCollection === "Employees") {
      setStatusCollection("EmploymentStatus");
    }
  }, [mainCollection, statusCollection]);

  const { isShowing: isShowingNote, toggle: toggleNote } = useModal();

  const handleDone = (message, newStatus) => {
    toggleNote();
    if (message !== undefined) {
      toast.success(message);
    }
    if (newStatus !== undefined) {
      setStatusID(newStatus);
    }
  };

  useEffect(() => {
    setStatusID(status);
  }, [status]);

  useEffect(() => {
    if (
      !isNil(statusCollection) &&
      statusCollection !== "" &&
      !isNil(statusID) &&
      statusID !== ""
    ) {
      const activeStatusCollection = doc(db, statusCollection, statusID);
      async function fetchOptions(docRef, myStatusCollection) {
        await getDoc(docRef)
          .then(async (data) => {
            setCurrentStatus(data.data().label);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      fetchOptions(activeStatusCollection, statusCollection);
    }
  }, [statusID, statusCollection]);

  return (
    <div id={name} className="relative w-full mb-4">
      <label className="text-instant-teams-teal-Main text-sm  absolute  duration-300 transform -translate-y-4 scale-75 top-4 origin-[0] left-3.5 peer-focus:text-instant-teams-blue-Main peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
        {display} {" - Click to change status"}
      </label>
      <input
        type="text"
        name={name}
        placeholder={placeholder}
        value={currentStatus}
        onClick={() => {
          toggleNote();
        }}
        className="form-input shadow-md rounded-md w-full pt-3 text-xl"
        readOnly={true}
      />
      {isShowingNote && (
        <StatusNote
          onDone={handleDone}
          collection={collection}
          id={recId}
          name={name}
          display={display}
          placeholder={placeholder}
          status={status}
          required={required}
          directions={directions}
          statusCollection={statusCollection}
          minLength={10}
        />
      )}
      <ToastAlert />
    </div>
  );
};

export default AutoSaveStatusSelector;
