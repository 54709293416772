import { useState, useEffect } from "react";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import { useAuth } from "../../../providers/auth";
import { useAcceptedJobOffers } from '../../../data/offers';
import OffersMadeItem from './OffersMadeItem';

const OffersMade = ({ jobId }: { jobId: string }) => {
  const { data: jobOffers } = useAcceptedJobOffers(jobId);

  useEffect(() => {
    console.log(jobOffers);
  }, [jobOffers]);
  

  return (
    <div className="w-full mb-4 mt-4 text-instant-teams-teal-Main font-bold ">
      <GreenUnderlineHeading title="Accepted Offers" />
      <div className="w-full">
        {jobOffers && jobOffers.length > 0
          ? jobOffers.map((offer: any) => (
              <div
                className="h-full shadow-lg rounded-lg p-1 text-sm font-semibold m-1 min-w-full"
                key={offer.id}
                id={offer.id}
              >
                <OffersMadeItem offer={offer}/>
              </div>
            ))
          : (
            <div className="h-full inline-block rounded-lg p-1 text-sm font-semibold m-1 cursor-pointer transition-colors relative">
              No offers accepted
            </div>
          )}
      </div>
    </div>
  );
};

export default OffersMade;
