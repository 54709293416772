import { Outlet } from "react-router-dom";

const UserDetailsTabbedDisplay = () => {
  return (
    <>
      <div className="md:container pb-32 w-full">
        <Outlet />
      </div>
    </>
  );
};

export default UserDetailsTabbedDisplay;
