import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
} from "@apollo/client";

const WORDPRESSURL = process.env.REACT_APP_WORDPRESS_URL;

export const wordpressClient = new ApolloClient({
  uri: WORDPRESSURL,
  cache: new InMemoryCache(),
});

export const nodeDataClient = (uri) => {
  new ApolloClient({
    uri: `${WORDPRESSURL}/${uri}`,
    cache: new InMemoryCache(),
  });
};
