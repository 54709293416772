import { Link } from "react-router-dom";
import { useFindMeetingStatus } from "../../data/status";
import { Timestamp } from "firebase/firestore";
import CopyToClipboardButton from "../custom-ui/button/CopyToClipboardButton";
import ManageMeetingActions from "./ManageMeetingActions";
import { useAuth } from "../../providers/auth";

function MeetingItem({
  meeting,
  canJoinMeeting,
}: {
  meeting: any;
  canJoinMeeting: boolean;
}) {
  const {
    id: meetingId,
    refId,
    refType,
    title,
    inviteeId,
    type,
    status,
    startTime,
    participants = [],
    extended,
    createdAt,
  } = meeting ?? {};
  const { userClaims } = useAuth();
  // used to find meeting status label and color
  const { data: meetingStatus } = useFindMeetingStatus(status);

  const toDateString = (timestamp: Timestamp) => {
    const dateObj = timestamp.toDate();
    return dateObj.toLocaleString("en-US", {
      month: "short",
      weekday: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZoneName: "short",
    });
  };

  const meetingTypeDictionary: { [key: string]: string } = {
    interview: "live interview",
  };

  const colorMeetingVariants: any = {
    cancelled: "bg-instant-teams-red-L1",
    complete: "bg-instant-teams-gray-D1",
    pending: "bg-instant-teams-gray-D1",
    reschedule: "bg-instant-teams-gray-D1",
    scheduled: "bg-instant-teams-green-Main",
    sent: "bg-instant-teams-orange-D1",
    expired: "bg-instant-teams-gray-D1",
  };

  return (
    <div className="shadow-xl rounded-lg w-full">
      {status === "scheduled" ? (
        <div className="relative h-20 p-4 flex justify-start bg-instant-teams-blue-Main rounded-t-lg">
          {canJoinMeeting && (
            <CopyToClipboardButton
              text={`${window.location.protocol}//${window.location.host}/join-meeting/${meetingId}`}
            />
          )}
          <div className="mx-auto">
            <h2 className="text-center text-white font-bold">{title}</h2>
            {createdAt && (
              <div className="w-full text-sm text-center text-white">
                <span>{toDateString(createdAt)}</span>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="h-20 p-4 bg-instant-teams-gray-D1 rounded-t-lg">
          <h2 className="text-center text-white font-bold">{title}</h2>
          {createdAt && (
            <div className="w-full text-sm text-center text-white">
              <span>{toDateString(createdAt)}</span>
            </div>
          )}
        </div>
      )}
      <div className="p-4 flex justify-between">
        <div className="flex-col justify-center">
          <div className="px-4 flex items-center justify-between">
            <div className="text-center text-instant-teams-teal-D1 uppercase text-xl font-bold">
              {meetingTypeDictionary?.[type]}
            </div>
          </div>

          {startTime && (
            <div className="px-4 my-2 text-left">
              <span className=" text-instant-teams-teal-D1 uppercase font-bold">
                Time:
              </span>{" "}
              <span className="px-2 text-sm">{toDateString(startTime)}</span>
            </div>
          )}

          {userClaims?.claims?.type === "A" ? (
            <div className="mt-1 mb-4 text-left">
              <span className="px-4 text-instant-teams-teal-D1 uppercase font-bold">
                Participants:
              </span>{" "}
              <ul className="inline">
                {participants.map((participant: any, index: number) => (
                  <li key={index} className="mr-1 text-sm inline">
                    <span>{participant.name},</span>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          <div className="px-4 mt-4">
            {canJoinMeeting && <ManageMeetingActions meetingId={meetingId} />}
          </div>
        </div>

        <div className="w-72">
          {meetingStatus && (
            <div
              className={`w-full  p-2 rounded-lg text-center text-white font-bold uppercase ${colorMeetingVariants[status]}`}
            >
              {meetingStatus?.label}
            </div>
          )}
          {status === "scheduled" && canJoinMeeting && (
            <Link to={`/join-meeting/${meetingId}`} target="_blank">
              <button
                type="button"
                disabled={status !== "scheduled"}
                className={`w-full my-2 min-h-12 h-12 text-white ${
                  status !== "scheduled"
                    ? "bg-instant-teams-gray-D1 cursor-not-allowed"
                    : "bg-instant-teams-blue-Main hover:bg-instant-teams-blue-D1"
                }  rounded border-0  focus:outline-none`}
              >
                Join Call
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default MeetingItem;
