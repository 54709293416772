const ProgressCircle = ({
  prog = 0,
  r = 100,
  strokeWidth = 8,
  containerClassName = "",
}: {
  prog: number;
  r?: number;
  strokeWidth?: number;
  containerClassName?: string;
}) => {
  const c = 2 * Math.PI * r;
  const offset = c - (prog / 100) * c;

  // Calculate the total width and height considering stroke width
  const totalSize = 2 * (r + strokeWidth);

  return (
    <div className={containerClassName}>
      <div className="spinner-circle">
        <svg
          height={totalSize}
          width={totalSize}
          viewBox={`0 0 ${totalSize} ${totalSize}`}
        >
          <circle
            className="progress-ring-circle"
            strokeWidth={strokeWidth}
            stroke="#e7ecf0"
            fill="transparent"
            r={r}
            cx={r + strokeWidth / 2} // Adjust cx for stroke width
            cy={r + strokeWidth / 2} // Adjust cy for stroke width
            strokeDasharray={c}
            strokeLinecap="round"
          />
          <circle
            className="progress-ring-circle"
            strokeWidth={strokeWidth}
            stroke="#a2c1a6"
            fill="transparent"
            r={r}
            cx={r + strokeWidth / 2} // Adjust cx for stroke width
            cy={r + strokeWidth / 2} // Adjust cy for stroke width
            strokeDasharray={c}
            strokeDashoffset={offset}
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default ProgressCircle;
