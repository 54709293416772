import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useJobPositions } from "../data/positions";
import { getAssessmentMetricsByStatus } from "../callable-cloud-functions/cloudFunctions";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function AcademyAssessmentsByStatus({}) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  );

  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(true);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Assessments Metrics",
      },
    },
    animation: {
      duration: 1000,
      onProgress: function (animation) {
        const progress = animation.currentStep / animation.numSteps;
        const rounded = Math.round((progress + Number.EPSILON) * 100) / 100;
        const converted = rounded * 100;
        setProgress(converted);
      },
      onComplete: function (animation) {
        setTimeout(() => {
          setProgress(0);
          setShowProgress(false);
        }, 2000);
      },
    },
  };

  const data = {
    labels: chartLabels,
    datasets: chartData,
  };

  useEffect(() => {
    (async () => {
      const res = await getAssessmentMetricsByStatus();
      const datasets = [
        {
          label: "Active",
          data: [res.data.active],
          backgroundColor: "#4198d7",
        },
        {
          label: "Archive",
          data: [res.data.archive],
          backgroundColor: "#2a6a99",
        },
        {
          label: "Created",
          data: [res.data.created],
          backgroundColor: "#0c3d5b",
        },
      ];
      const labels = ["Active", "Archive", "Created"];
      console.log("AcademyAssessmentsByStatus.js, res: ", res);

      setChartData(datasets);
      setChartLabels(labels);
    })();
  }, []);

  return (
    <div>
      {showProgress ? (
        <div style={{ width: 200, height: 400, margin: "auto" }}>
          <span>Assessments Metrics</span>
          <CircularProgressbar
            value={progress}
            text={`${progress}%`}
            maxValue={50}
          />
        </div>
      ) : (
        <div style={{ display: "none" }} />
      )}
      <div style={{ width: "100%", height: "100%" }}>
        <Bar
          style={showProgress ? { display: "none" } : { display: "block" }}
          options={options}
          data={data}
        />
      </div>
    </div>
  );
}

export default AcademyAssessmentsByStatus;
