import React, { useState, useEffect, useRef } from "react";
import VideoRecorder from 'react-video-recorder'
import { uploadBadge } from '../../providers/firebaseStorage';
import { updateAutoSave } from '../../data/utility';

const QuestionVideoRecorder = ({
    id,
    collection,
  }:{
    id: string
    collection: string
  }) => {
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    if(videoUrl !== ""){
      console.log(videoUrl);
      const recording = {
        videoURL: videoUrl
      }

      updateAutoSave(collection, id, recording);
    }
  },[videoUrl, collection, id])
  

  return (
    <div>
      <VideoRecorder
        onRecordingComplete={async (videoBlob: any) => {
          console.log("Video: ",videoBlob);
          const filename = id + ".mp4";
          const url = await uploadBadge(videoBlob, "videoPrompts", filename);
          console.log("URL: ", url);
          setVideoUrl(url);
        }}
      />
    </div>
  );
};

export default QuestionVideoRecorder;
