// HeadWrapper.js
import React from 'react';
import { Helmet } from 'react-helmet';

const HeadWrapper = ({ children }) => {
  const head = Helmet.peek();
    console.log(head);
  if (!head) {
    return (
      <React.Fragment>
        <Helmet>
            <link href="/favicon.ico" rel="shortcut icon" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
            <link
            href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Ubuntu:wght@500&display=swap"
            rel="stylesheet"
            />            
            <script
            src="https://kit.fontawesome.com/01b172016c.js"
            crossorigin="anonymous"
            ></script>
            <script
            type="text/javascript"
            src="https://static.signwell.com/assets/embedded.js"
            ></script>
        </Helmet>
        {children}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Helmet>
        {head.title && head.title.toString()}
        {head.meta && head.meta.toString()}
        {head.link && head.link.toString()}
        {head.styleTags}
        <script
            src="https://kit.fontawesome.com/01b172016c.js"
            crossorigin="anonymous"
        ></script>
        <script
            type="text/javascript"
            src="https://static.signwell.com/assets/embedded.js"
        ></script>
      </Helmet>  
      {children}
    </React.Fragment>
  );
};

export default HeadWrapper;
