import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../providers/auth";
import GeneralNote from "../../../custom-ui/input-fields/modals/GeneralNote";
import GreenUnderlineHeading from "../../../custom-ui/headings/heading";
import Meta from "../../../custom-ui/meta/Meta";
import SeekerFiles from "../../../seeker/details/parts/SeekerFiles";
import SeekerNotesDisplay from "../../../seeker/details/parts/seekerNotes";
import Tooltip from "../../../custom-ui/tooltip/tooltip";
import { DisplayDate } from "../../../../util/timestamps/timestamp";
import { useEmploymentByEmployee } from "../../../../data/employee";
import { useUser } from "../../../../providers/database";
import { useJobOfferByEmployee } from "../../../../data/offers";
import { useJobPositionDetails } from "../../../../data/positions";
import useModal from "../../../../hooks/useModal";
import {
  collectionNames,
  subCollectionNames,
} from "../../../../data/dictionary/collectionNames";
import MessagingCenterLite from "../../../messages/MessagingCenterLite";
import AnalyticsLogger from "../../../../providers/analyticsLogger";
import { useEmployer, useAllEmployerTrainings } from '../../../../data/employer';
import AutoSaveDate from '../../../custom-ui/input-fields/auto-save-date';
import { Timestamp } from 'firebase/firestore';
import { updateAutoSave } from '../../../../data/utility';

const EmployeeDetails = () => {
  const { userClaims } = useAuth();
  const params = useParams();
  const employeeId = params?.employeeId || "";
  const [employerId, setEmployerId] = useState(null);
  const [training, setTraining] = useState(null);
  const { data: employer } = useEmployer(employerId || "");
  const { data: employmentRecord } = useEmploymentByEmployee(employeeId || "");
  const { data: employeeData, status: userDataStatus } = useUser(
    employmentRecord?.userId || "",
  );
  
  const { data: jobOfferData } = useJobOfferByEmployee(
    employmentRecord?.jobId,
    employeeData?.id,
  );
  const { data: jobPositionData } = useJobPositionDetails(
    employmentRecord?.jobId,
    employmentRecord?.positionId,
  );
const { data:trainings} = useAllEmployerTrainings(employmentRecord?.employerId);

  const { isShowing: isShowingNote, toggle: toggleNote } = useModal();
  const handleDone = () => {
    toggleNote();
  };
  const currentOffer = jobOfferData?.[0];

  useEffect(() => {
    setEmployerId(employmentRecord?.employerId);
    setTraining(employmentRecord?.training || null);
  }, [employmentRecord]);

  async function updateTraining(id:string, training: string) {
    await updateAutoSave("Employees", id, {
      training: training,
    });
  }

  useEffect(() => {
    console.log(training)
    if(training!== null){
      if(employmentRecord?.training === undefined || training !== employmentRecord?.training){
        updateTraining(employmentRecord?.id, training);
      }
    }
  }, [training]);

  return (
    <div className="mb-36">
      <Meta title="Employee - Details" />
      {userClaims?.claims?.type === "E" ? (
        <AnalyticsLogger type={collectionNames.employees} typeId={employeeId} />
      ) : null}
      <div className="flex justify-between w-full">
        {userDataStatus === "success" && (
          <>
            <div className="w-[45%]">
              <GreenUnderlineHeading title="Employment Details" />
              <p className="text-xl font-bold text-instant-teams-teal-D2">
                Employer:{" "}
                <span className="pl-4 text-lg font-thin">
                  {employer?.displayName}
                </span>
              </p>
              <p className="text-xl font-bold text-instant-teams-teal-D2">
                Position:{" "}
                <span className="pl-4 text-lg font-thin">
                  {jobPositionData?.positionName}
                </span>
              </p>
              <p className="text-xl font-bold text-instant-teams-teal-D2">
                Offer Start Date:{" "}
                <span className="pl-4 text-lg font-thin">
                  {currentOffer && DisplayDate(currentOffer?.startDate)}
                </span>
              </p>
              <div className="text-xl font-bold text-instant-teams-teal-D2 grid grid-cols-4 gap-2">
                <div className='pt-2'>Start Date:</div>
                
                <div className="pl-4 text-lg font-thin col-span-3">
                  <AutoSaveDate
                    initialStart={
                      employmentRecord?.startDate instanceof Timestamp
                        ? employmentRecord?.startDate.toDate()
                        : null
                    }
                    name="startDate"
                    collection={"Employees"}
                    id={employeeId}
                    disabled={false}
                    timestamp = {true}
                  />
                </div>
              </div>
              {employer && employer?.training && (
                <>
                  <GreenUnderlineHeading title="Training Details" />
                  <select
                    onChange={(e) => {
                      setTraining(e.target.value);
                    }}
                    className="w-full py-1 leading-8 bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-1"
                  >
                    <option value="">Assign to Training Cycle</option>
                    {trainings.map((train: any) => (
                      <option 
                        key={train.id} 
                        value={train.id}
                        selected={train.id === training}
                      >
                        {train.title}{" - "}{DisplayDate(train.startDate)}
                      </option>
                    ))}
                  </select>
                </>
              )}              
            </div>
            <div className="w-[50%]">
              <GreenUnderlineHeading title="Files" />
              <SeekerFiles id={employeeData?.id} />
              <Tooltip text="Add note" position="-top-8 -right-16">
                <div className="flex flex-row justify-between uppercase text-xl font-bold text-instant-teams-teal-D1 border-b-2 border-instant-teams-green-D2 min-w-full mt-6">
                  Notes
                  <i
                    onClick={toggleNote}
                    className="fak cursor-pointer fa-notes-add-white text-instant-teams-teal-Main pl-4 float-right"
                  ></i>
                </div>
              </Tooltip>
              <SeekerNotesDisplay
                id={employmentRecord?.id}
                collection={collectionNames.employees}
                subCollection={subCollectionNames.notes}
              />
              {isShowingNote && (
                <GeneralNote
                  onDone={handleDone}
                  collection={collectionNames.employees}
                  id={employmentRecord.id}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EmployeeDetails;
