import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getJobOffer } from "../../../data/offers";
import PDFViewer from "../../pdf/pdfViewer";
const OfferDetails = () => {
  const params = useParams();
  const [offer, setOffer] = useState();
  const [file, setFile] = useState();

  useEffect(() => {
    setFile(offer?.offerLetterUrl);
  }, [offer?.offerLetterUrl]);

  useEffect(() => {
    async function fetchOffer() {
      let res = await getJobOffer(params?.offerId);
      setOffer(res);
    }
    if (params?.offerId) {
      fetchOffer();
    }
  }, [params?.offerId]);

  return (
    <div>
      <div>
        <div className="w-1/2 h-screen">
          <PDFViewer file={file} label="Offer Letter" />
        </div>
      </div>
    </div>
  );
};

export default OfferDetails;
