import { useState } from "react";
import { useParams } from "react-router-dom";
import { camel } from "../../../util/strings/stringManipulation";
import TestQuestions from "./testQuestions";
import { useComponent } from "../../../data/academy";

function TestDetails(props) {
  const { details } = props;
  const params = useParams();
  const [id, setId] = useState(params?.assessmentId);
  const { data: component } = useComponent(id);

  return (
    <div className="w-full">
      {component &&
        component?.tests_detail?.map((item, index) => {
          return (
            <div
              className={index > 0 ? "w-full border-t border-black" : "w-full"}
              key={index}
            >
              <div className="w-full text-bold text-instant-teams-teal-Main">
                {camel(item?.test?.status)} {camel(item?.test?.name)} (
                {item?.test?.duration / 60} mins){" "}
                {item?.test?.is_premium ? "Premium" : ""}
                {item?.test?.is_private_test ? "Private" : ""}
              </div>
              <div className="w-full">
                <p className="w-full font-bold">Skills: </p>
                <TestQuestions details={item?.test?.covered_skills} />
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default TestDetails;
