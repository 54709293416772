import React from 'react';

type ToggleButtonProps = {
    isOn: boolean;
    handleClick: () => void;
    buttonText?: string;
};

const ToggleButton = ({ isOn, handleClick, buttonText = "" }:ToggleButtonProps) => {
    return (
        <div className="flex items-center mt-3 mb-3">
            <button
                type="button"
                className={`cursor-pointer ${isOn ? "bg-instant-teams-green-Main" : "bg-gray-300"} relative inline-flex items-center h-[18px] rounded-full w-9`}
                onClick={handleClick}
            >
                <span
                    className={`${isOn ? "translate-x-5" : "translate-x-1"} inline-block w-3 h-3 transform bg-white rounded-full`}
                />
            </button>
            {buttonText && <span className="ml-2 text-[14px]">{buttonText}</span>}
        </div>
    );
};

export default ToggleButton;
