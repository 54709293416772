import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
/*
 * Wrapping / Mirroring a HTML Element
 * https://react-typescript-cheatsheet.netlify.app/docs/advanced/patterns_by_usecase/#wrappingmirroring-a-html-element
 *
 */

interface ButtonProps extends React.ComponentProps<"button"> {
  pending?: boolean;
}

function ButtonWithLoading(props: ButtonProps) {
  // omit pending from the button props;
  const { pending, ...buttonProps } = props;
  return (
    <>
      <div className="flex justify-center align-middle">
        {pending ? (
          <FontAwesomeIcon
            className="animate-spin h-10 w-10 py-2 px-4"
            //style={faDuotoneStyles}
            icon={faSpinnerThird}
            size="xl"
          />
        ) : (
          <button
            className="py-2 px-4 w-full text-xl uppercase  justify-center mx-auto text-white bg-instant-teams-blue-Main rounded-lg border-0 hover:bg-instant-teams-blue-D1 focus:outline-none"
            {...buttonProps}
          >
            {buttonProps.value}
          </button>
        )}
      </div>
    </>
  );
}

export default ButtonWithLoading;
