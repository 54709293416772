import { useState, useEffect } from "react";
import { Alert } from "../../dataTypes/Alert";
import useLocalTime from "../../hooks/useLocalTime";
import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";

interface AlertItemProps {
  alert: Alert;
}

const AlertItem = ({ alert }: AlertItemProps) => {
  const { title, createdAt, body } = alert;
  const [name, setName] = useState("");

  const options = {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  };

  const formattedDate = useLocalTime(createdAt.seconds * 1000, options);

  return (
    <tr className={TailwindHoverEnlarge(101) + "border-b border-gray"}>
      <td className="p-2">{title}</td>
      <td className="p-2">{formattedDate}</td>
      <td className="max-w-xs break-words p-2">{body}</td>
    </tr>
  );
};

export default AlertItem;
