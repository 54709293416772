import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import Meta from "../../custom-ui/meta/Meta";
import JobPreviewMobilePart from "../parts/JobPreviewMobilePart";
import {
  useJobDescription,
  useJobDescriptionSkills,
} from "../../../data/jobDescriptions";
import { useJobPositionDetails } from "../../../data/positions";
import { useParams } from "react-router-dom";
import { useEmployer, useEmployerLocation } from "../../../data/employer";
import { requireAuth } from "../../../providers/auth";
import JobPreviewWebPart from "../parts/JobPreviewWebPart";
import { useEmployerData } from "../../../hooks/useApplicationEmployerData";

function JobsPReview() {
  const params = useParams();
  const { jobId = "", positionId = "" } = params;
  const { data: jobData } = useJobDescription(jobId);
  const { data: positionData } = useJobPositionDetails(jobId, positionId);

  const { employerId, employerData } = useEmployerData({
    jobData,
    originalEmployerId: jobData?.employer,
  });

  const { data: jobSkillsData } = useJobDescriptionSkills(jobId);
  const { data: locationData } = useEmployerLocation(
    jobData?.employer,
    positionData?.information?.locationId,
  );
  // easter egg
  const KylePopover = (
    <div className="-my-8">
      <img src={"/images/you-dont-have-to-do-this-kyle.gif"} alt="" />
    </div>
  );

  return (
    <>
      {employerData && (
        <div className="overflow-y-hidden">
          <Meta title="Job Preview" />
          <div className="mt-32 ">
            <div className="grid  grid-cols-12 gap-14  ">
              <div className="col-span-4 mx-2 pb-40   ">
                <GreenUnderlineHeading title="Mobile" />
                <JobPreviewMobilePart
                  employer={employerData}
                  job={jobData}
                  position={positionData}
                  skills={jobSkillsData}
                  timezone={locationData?.timezone?.id}
                  KylePopover={KylePopover}
                />
              </div>
              <div className="col-span-8 mx-2  ">
                <GreenUnderlineHeading title="Web" />
                <JobPreviewWebPart
                  employer={employerData}
                  job={jobData}
                  position={positionData}
                  skills={jobSkillsData}
                  timezone={locationData?.timezone?.id}
                  KylePopover={KylePopover}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default requireAuth(JobsPReview);
