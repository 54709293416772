import { useEffect, useState } from "react";
import AutoSaveSelectGeneric from "../../../custom-ui/input-fields/auto-save-select-generic";
import { addUserAffiliation } from "../../../../data/affiliation";
import {
  collectionNames,
  subCollectionNames,
} from "../../../../data/dictionary/collectionNames";
import { createResume } from "../../../../data/seeker";
import { useLookup } from "../../../../data/lookup";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import ForwardArrowButton from "../../../custom-ui/button/ForwardArrowButton";

function MilitaryConnected({
  userId,
  handleDone,
  children,
}: {
  userId: string;
  handleDone: Function;
  children?: React.ReactNode;
}) {
  const [affiliationDocId, setAffiliationDocId] = useState();
  const affiliationsSubcollection = `${collectionNames.users}/${userId}/${subCollectionNames.affiliations}`;
  const { data: branchList = [] } = useLookup("branch");
  const { data: affiliationList = [] } = useLookup("affiliation");
  const { data: stateList = [] } = useLookup("state");

  const affiliationSchema = z.object({
    branch: z
      .string({ required_error: "Branch is required" })
      .nonempty({ message: "Military Branch is required" }),
    affiliation: z
      .string({ required_error: "Affiliation is required" })
      .nonempty({ message: "Military Affiliation is required" }),
    stateResidence: z
      .string({ required_error: "State of residence is required" })
      .nonempty({ message: "State of residence is required" }),
  });

  type AffiliationSchema = z.infer<typeof affiliationSchema>;

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    getValues,
    control,
  } = useForm<AffiliationSchema>({
    resolver: zodResolver(affiliationSchema),
  });

  console.log("Errors:", errors);

  const onSubmit = (data: AffiliationSchema) => {
    const { affiliation, branch, stateResidence } = data;
    addUserAffiliation(userId, branch, affiliation)
      .then((docRef) => {
        createResume({ id: userId, stateResidence })
          .then(() => {
            console.log("done!");
            handleDone();
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className="m-2 w-full">
        <select
          className={`py-2 px-3 font-bold w-full leading-8  rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 ${
            errors?.branch?.message ? "border-red-600" : ""
          }`}
          name="branch"
          ref={register({ required: "Please select your military branch." })}
        >
          <option value="" selected disabled>
            Military Branch
          </option>
          {branchList.map((branch: any) => {
            return (
              <option key={branch.id} value={branch.code}>
                {branch.label}
              </option>
            );
          })}
        </select>
        {errors.branch && (
          <p className="m-2  text-sm text-left text-red-600">
            {errors.branch.message}
          </p>
        )}
      </div>
      <div className="m-2 w-full">
        <select
          className={`py-2 px-3 font-bold w-full leading-8  rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 ${
            errors.affiliation ? "border-red-600" : ""
          }`}
          name="affiliation"
          ref={register({
            required: "Please select your military affiliation.",
          })}
        >
          <option value="" selected disabled>
            Military Affiliation
          </option>
          {affiliationList.map((affiliation: any) => {
            return (
              <option key={affiliation.id} value={affiliation.code}>
                {affiliation.label}
              </option>
            );
          })}
        </select>
        {errors.affiliation && (
          <p className="m-2  text-sm text-left text-red-600">
            {errors.affiliation.message}
          </p>
        )}
      </div>
      <div className="m-2 mb-6 w-full">
        <select
          className={`py-2 px-3 font-bold w-full leading-8  rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 ${
            errors.stateResidence ? "border-red-600" : ""
          }`}
          name="stateResidence"
          ref={register({
            required: "Please select your state of residence.",
          })}
        >
          <option value="" selected disabled>
            State of Residence
          </option>
          {stateList.map((state: any) => {
            return (
              <option key={state.id} value={state.code}>
                {state.label}
              </option>
            );
          })}
        </select>
        {errors.stateResidence && (
          <p className="m-2 text-sm text-left text-red-600">
            {errors.stateResidence.message}
          </p>
        )}
      </div>

      <div className="w-full flex justify-center">
        <p className="mb-8 text-center text-sm italic w-3/4 text-instant-teams-blue-Main">
          If you have more than one affiliation, please select your main
          affiliation here. You will have the opportunity to list additional
          affiliations during the registration process.
        </p>
      </div>
      <div className="flex flex-col justify-center items-center">
        <button
          className="cursor-pointer text-instant-teams-gray-D1 background-transparent font-bold uppercase underline px-3 py-1 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => {
            handleDone();
          }}
        >
          Skip
        </button>
        {children}
        <ForwardArrowButton pending={false} type="submit" />
      </div>
    </form>
  );
}

export default MilitaryConnected;
