const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));
export async function callWithRetry(
  fn: () => Promise<any>,
  attempts: number = 5,
): Promise<any> {
  try {
    return fn();
  } catch (error) {
    if (attempts && attempts > 0) {
      await wait(2 ** attempts * 10);
      return callWithRetry(fn, attempts - 1);
    }
    throw error;
  }
}
