import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useEmployerJobs } from "../../../../data/employer";
import EmployerJobListingLineItem from "../parts/EmployerJobListingLineItem";

const EmployerJobs = () => {
  const params = useParams();
  const [id, setId] = useState("");
  const {
    data: jobs = [],
    status: jobsStatus,
    error: jobsError,
  } = useEmployerJobs(id);

  useEffect(() => {
    console.log("JOBS: ", jobs);
  }, [jobs]);

  useEffect(() => {
    setId(params.employerId);
  }, [params.employerId]);

  return (
    <div className='min-h-screen'>
      <div className="grid grid-cols-4 gap-4 w-full">
        {id  && jobs && jobs?.length > 0
          ? jobs.map((job: any) => (
              <EmployerJobListingLineItem key={job?.id} job={job} />
            ))
          : null}
      </div>
    </div>
  );
};

export default EmployerJobs;
