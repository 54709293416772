import React from "react";
import PulseSpinner from "../../spinners/pulse-spinner";
import BlocksSpinner from "../../spinners/blocks-spinner";
import WedgeSpinner from "../../spinners/wedges-spinner";
function PageLoader(props) {
  return (
    <div>
      {/* {!props.children && <div className="p-10 text-center">Loading ...</div>} */}
      {!props.children && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <WedgeSpinner />
        </div>
      )}
      {props.children && <>{props.children}</>}
    </div>
  );
}

export default PageLoader;
