import { useEffect, useCallback, useState, useRef } from "react";

import { useDropzone } from "react-dropzone";
import { useAuth } from "../../../providers/auth";
import { upload } from "../../../providers/firebaseStorage";

import { errorLogger } from "../../../providers/clientLogger";
import { useUser } from "../../../providers/database";
import { useParams } from "react-router-dom";
function AvatarSettings(
    props,
  ) {
  const params = useParams();
  const VALID_FILE_EXTENSIONS = [".jpeg", ".jpg", ".png"];
  const DEFAULT_IMAGE = "/images/default_user.png";
  const VALID_FILE_TYPES = {
    "image/png": [],
    "image/PNG": [],
    "image/jpg": [],
    "image/JPG": [],
    "image/jpeg": [],
    "image/JPEG": [],
  };

  const { user } = useAuth();
  const { data: userData } = useUser(params?.userId || user?.uid);
  let imageExtensionType = "png";

  const [state, setState] = useState({
    image: userData.photoURL ?? "/images/default_user.png",
    previewImage: userData.photoURL ?? "/images/default_user.png",
    canEdit: false,
  });

  const [imageSize, setImageSize] = useState(0);
  const [uploading, setUploading] = useState(false);

  const onDropRejectedImage = () =>
    setState({
      ...state,
      image: DEFAULT_IMAGE,
      previewImage: DEFAULT_IMAGE,
    });

  const onErrorImage = () =>
    setState({ ...state, image: DEFAULT_IMAGE, previewImage: DEFAULT_IMAGE });

  const validImageType = (file) =>
    VALID_FILE_EXTENSIONS.some((ext) => file.name.toLowerCase().endsWith(ext));

  const validatorImage = (image) => {
    if (!validImageType(image)) {
      setState({ ...state, image: DEFAULT_IMAGE, previewImage: DEFAULT_IMAGE });
      errorLogger("Incorrect image type\nValid types: .jpg, .jpeg, .png", {
        component: "Avatar dropzone validatorImage()",
        flow: "validate image",
        userId: `${userData?.id}`,
      });

      return {
        code: "Incorrect-image-Type",
        message: "Valid image Types: .jpg, .jpeg, .png",
      };
    } else {
      setState({ ...state, image });
      return null;
    }
  };
  const onDrop = useCallback((droppedFiles) => {
    if (droppedFiles[0]) {
      const file = droppedFiles[0];
      const fileSize = Math.ceil(file.size / 1024 / 1024);
      setImageSize(fileSize);
      const fileType = file.type.split("/")[1];
      const renamedFile = new File([file], `${userData?.id}.${fileType}`, {
        type: file.type,
      });

      if (renamedFile.type.includes("png")) imageExtensionType = "png";
      setState({
        ...state,
        image: renamedFile,
        canEdit: true,
        previewImage: URL.createObjectURL(droppedFiles[0]),
      });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected: onDropRejectedImage,
    onError: onErrorImage,
    validator: validatorImage,
    accept: VALID_FILE_TYPES,
  });

  async function handleImageUpload() {
    setUploading(true);
    await upload(state.image, `resize/${state.image.name}`);
    setUploading(false);
    props.closeModal();
    window.location.reload();
  }
  return (
    <>
       <div className="flex mb-10">
          <p className="border-b w-full font-bold py-2 text-xl text-[#15415E]">Edit Profile Image</p>
         </div>
         
      <div className="flex justify-center border-b px-4 py-4" {...getRootProps()}>
        <input {...getInputProps()} />

        <img className="cursor-pointer" src={state.previewImage} alt="Logo" />
        
        
      </div>
      {!state.canEdit && (
      <div className="flex justify-center px-2 py-2">
      <p className="text-slate-500 text-sm flex justify-center text-[#15415E]">
          Tap to choose an image or drag and drop it above.
        </p>
      </div>
  )}
      {state.canEdit ? (
        <div className="flex flex-col items-center">
          <p>Image size {imageSize} MB</p>
          {uploading === false ? (
           <div className="flex items-center justify-end p-6 mt-2 border-t border-solid border-slate-200 rounded-b">
           <button
             className="py-2 bg-white border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black w-full h-12 rounded-full mr-4"
             type="button"
             onClick={() => props.closeModal()}
           >
             Close
           </button>
           <button
             className="bg-[#15415E] text-white px-4 py-2 rounded-full w-64 h-12"
             type="button"
             onClick={handleImageUpload}
           >
             Save
           </button>
         </div>
          ) : (
            <div className="flex justify-center mt-4">
              <button
                className="bg-[#15415E] text-white px-4 py-2 rounded-full w-64 h-12"
                type="button"
                disabled
              >
                Uploading.
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="flex justify-center mt-10">
          <button
            className="py-2 bg-white border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black w-60 h-12 rounded-full"
            type="button"
            onClick={() => props.closeModal()}
          >
            Close
          </button>
        </div>
      )}
    </>
  );
}

export default AvatarSettings;
