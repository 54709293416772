import React, { useState, useEffect } from "react";
import { useUser, useUserStatus } from "../../../../data/user";
import { useLookupCode } from "../../../../data/lookup";

const EmployerUserCardSmall = ({ id }: { id: string }) => {
  const [role, setRole] = useState("");
  const {
    data: userData = {},
    status: userStatus = "",
    error: userError,
  } = useUser(id);

  const {
    data: statusData = {},
    status: statusStatus = "",
    error: statusError,
  } = useUserStatus(userData.status);

  const {
    data: roleData = {},
    status: roleStatus = "",
    error: roleError,
  } = useLookupCode(role);

  useEffect(() => {
    setRole(userData.claims?.role);
  }, [userData, roleData]);

  return (
    <div className="flex w-full items-center bg-white shadow-md rounded-md py-2 p-1 mb-2">
      <div className="flex-none justify-between w-10">
        <div className="flex items-center mt-1">
          <img
            className="w-full h-auto rounded-full mx-auto"
            src={userData?.photoURL || "/images/default_user.png"}
            alt="avatar"
          />
        </div>
      </div>
      <div className="flex-none justify-between itme w-full text-sm whitespace-truncate pl-2">
        <div className="w-full">
          {userData.name} ({statusData.label}) -{" "}
          {Object.keys(roleData).length > 0 ? roleData[0].label : ""}
        </div>
        <div className="w-full">
          {userData?.address?.state
            ? `${userData.email} - ${userData.address?.state}`
            : userData.email}
        </div>
        <div className="w-full">{userData.phone}</div>
      </div>
    </div>
  );
};

export default EmployerUserCardSmall;
