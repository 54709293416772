import { db } from "../providers/database";
import {
  doc,
  collection,
  query,
  where,
  orderBy,
  getDocs,
} from "firebase/firestore";

export async function getMemberSince(userId) {
  console.log("getMemberSince", userId);
  // const _query_ = query(
  //   collection(db, "Activity"),
  //   where("userId", "==", userId),
  //   orderBy("timestamp", "ASC"),
  // );
  // const activity = await getDocs(_query_).then((querySnapshot) => {
  //   console.log("querySnapshot", querySnapshot);
  // });

  return "Test";
}
