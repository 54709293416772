/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useQuery,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import { doc, collection, query, where, orderBy } from "firebase/firestore";
import { db, createQuery } from "../providers/database";

export function useActivity(id: string) {
  return useQuery(
    ["activity", { id }],
    createQuery(() => doc(db, "Activity", id)),
    { enabled: !!id },
  );
}

// All Activity by Collection
export function useAllActivityByCollection(id: string, collectionName: string) {
  return useQuery(
    ["activity"],
    createQuery(() =>
      query(
        collection(db, "Activity"),
        where("collection", "==", collectionName),
        where("id", "==", id),
        orderBy("created_at", "desc"),
      ),
    ),
    { enabled: !!id },
  );
}
