import React, { useState, useEffect } from "react";
import CardContainer from "./CardContainer";
import {
    csvDataForSuccessfulVerification,
    csvDataForFailedConversion,
    csvDataForFailedVerification,
    sheerIdVerificationDashboardInfo
} from "../../../callable-cloud-functions/cloudFunctions";
import LoadingSpinner from "./LoadingIndicator/LoadingSpinner";
import DownloadIconWithLabel from "./DownLoadIcon";
import InfoIcon from "./InfoTextWithIcon";
import { csvDownload } from "./ExportCsv";

const SheerIdReportCards = ({ daysSelected }) => {
    const [sheerIdData, setSheerIdData] = useState();
    const [selectedMonthYear, setSelectedMonthYear] = useState('all');
    const [loadingStates, setLoadingStates] = useState({
        loading: false,
        FVloading: false,
        FCloading: false,
        SVloading:false,
    });
    
    const key = `last${daysSelected}Days`;

    useEffect(() => {
        async function fetchData() {
            setLoadingStates(prevState => ({ ...prevState, loading: true }));
            const [monthNumber, yearNumber] = getMonthYearNumbers(selectedMonthYear);
            const data = await sheerIdVerificationDashboardInfo({
                month: monthNumber,
                year: yearNumber,
                all: selectedMonthYear === "all",
            });
            setSheerIdData(data?.data);
            setLoadingStates(prevState => ({ ...prevState, loading: false }));
        }
        fetchData();
    }, [selectedMonthYear]);

    const getMonthYearNumbers = (selectedMonthYear) => {
        if (selectedMonthYear === "all") return [0, 0];
        const date = new Date(selectedMonthYear);
        return [date.getMonth() , date.getFullYear()];
    };

    const handleDownloadCSV = async (fetchDataFunc, loadingKey , fileName) => {
        setLoadingStates(prevState => ({ ...prevState, [loadingKey]: true }));
        const [monthNumber, yearNumber] = getMonthYearNumbers(selectedMonthYear);

        const data = await fetchDataFunc({
            month: monthNumber,
            year: yearNumber,
            all: selectedMonthYear === "all",
        });

        setLoadingStates(prevState => ({ ...prevState, [loadingKey]: false }));
        if (data?.data?.result?.length !=0){ csvDownload(data.data.result ,fileName+" "+selectedMonthYear )}else {console.log("no data to down load")};
    };

    const generateMonthYearOptions = () => {
        const options = [];
        const startDate = new Date(2024, 5); // June 2024
        const currentDate = new Date();
        let date = startDate;
        while (date <= currentDate) {
            const month = date.toLocaleString('default', { month: 'long' });
            const year = date.getFullYear();
            options.push(`${month} ${year}`);
            date.setMonth(date.getMonth() + 1);
        }
        return options;
    };

    const monthYearOptions = generateMonthYearOptions();
    const totalCount = sheerIdData?.[key]?.approved + sheerIdData?.[key]?.rejected || 0;
    const approvedCount = sheerIdData?.[key]?.approved || 0;
    const rejectedCount = sheerIdData?.[key]?.rejected || 0;

    return (
        <div className="bg-[#E6F1FC] p-3 w-full h-[335px] shadow-lg rounded-lg relative">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <h1 className="text-xl text-[#1E596D] font-bold">Sheer ID Reports</h1>
                    <InfoIcon tooltipText="Conversion reports in the selected time period." />
                </div>
                <div>
                    <select
                        className="border border-[#1E596D] w-36 bg-white rounded-lg p-2 text-[#1E596D]"
                        value={selectedMonthYear}
                        onChange={(e) => setSelectedMonthYear(e.target.value)}
                    >
                        <option value="" disabled>Select a Month </option>
                        <option value="all">All</option>
                        {monthYearOptions.map((option, index) => (
                            <option className="bg-white p-2" key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {loadingStates.loading ? (
                <div className="w-full h-[80%] items-center flex justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <div className="w-full mt-4">
                    <div className="flex justify-between w-full mt-6">
                        <div>
                            <p className="text-[#1E596D] text-[12px]">Total Verifications</p>
                            <h1 className="text-[#1E596D] mt-3 font-bold text-2xl">{sheerIdData?.totalVerifications}</h1>
                        </div>
                        <div>
                            <p className="text-[#1E596D] text-[12px]">Successful Verifications</p>
                            <div className="flex items-center mt-3">
                                <h1 className="text-[#1E596D] font-bold text-2xl">{sheerIdData?.success}</h1>
                                {sheerIdData?.success !=0 && <div className="ml-3" onClick={() => handleDownloadCSV(csvDataForSuccessfulVerification, 'SVloading',"successfull_verifications")}>
                                    {loadingStates.SVloading ? <p className="text-sm  text-[#15404e]">Downloading...</p> : <DownloadIconWithLabel tooltipText="Download users with successful SheerID verifications for the selected time period." />}
                                </div>}
                            </div>
                        </div>
                        <div>
                            <p className="text-[#1E596D] text-[12px]">Failed Verifications</p>
                            <div className="flex mt-3 items-center">
                                <h1 className="text-[#1E596D] font-bold text-2xl">{sheerIdData?.failure}</h1>
                                {sheerIdData?.failure != 0&&<div className="ml-3" onClick={() => handleDownloadCSV(csvDataForFailedVerification, 'FVloading',"failed_verifications")}>
                                    {loadingStates.FVloading ? <p className="text-sm  text-[#15404e]">Downloading...</p> : <DownloadIconWithLabel tooltipText="Download users with failed SheerID verifications for the selected time period." viewDown={true} />}
                                </div>}
                            </div>
                        </div>
                    </div>

                    <div className="flex mt-6">
                        <div>
                            <p className="text-[#1E596D] text-[12px]">Successful Conversions</p>
                            <h1 className="text-[#1E596D] mt-3 font-bold text-xl">{sheerIdData?.presentIn12M}</h1>
                        </div>
                        <div className="ml-[43px]">
                            <p className="text-[#1E596D] text-[12px]">Successful Conversion Rate</p>
                            <p className="text-[#1E596D] mt-3 font-bold text-xl"> { Math.round(sheerIdData?.verifiedConversion.toFixed(2) * 100) / 100 || 0}%</p>
                        </div>
                    </div>

                    <div className="flex mt-6">
                        <div>
                            <p className="text-[#1E596D] text-[12px]">Failed Conversions</p>
                            <div className="flex items-center mt-3">
                                <p className="text-[#1E596D] font-bold text-xl">{sheerIdData?.notPresentIn12M}</p>
                                {sheerIdData?.notPresentIn12M !=0 && <div className="ml-3" onClick={() => handleDownloadCSV(csvDataForFailedConversion, 'FCloading',"failed_conversion")}>
                                    {loadingStates.FCloading ? <p className="text-sm  text-[#15404e]">Downloading...</p> : <DownloadIconWithLabel tooltipText="Download users not registered with 12M+ for the selected time period."/>}
                                </div>}
                            </div>
                        </div>
                        <div className="ml-[70px]">
                            <p className="text-[#1E596D] text-[12px]">Failed Conversion Rate</p>
                            <div className="flex items-center mt-3">
                                <p className="text-[#1E596D] font-bold text-xl">{ Math.round(sheerIdData?.failureConversion.toFixed(2) * 100) / 100 || 0}%</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SheerIdReportCards;
