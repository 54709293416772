import { useState } from "react";
import { useAuth } from "../../providers/auth";
import { useForm } from "react-hook-form";
import { errorLogger } from "../../providers/clientLogger";
import { createAuthOrigin } from "../../data/authOrigin";
import { Invitation } from "../../dataTypes/Invitation";
import { AuthStatus, Origin } from "../../dataTypes/AuthOrigin";
import { Timestamp } from "firebase/firestore";
import ForwardArrowButton from "../custom-ui/button/ForwardArrowButton";

function RegistrationForm({
  invitation,
  onDone,
  processingInvitation,
}: {
  invitation: Invitation;
  onDone: Function;
  processingInvitation: Function;
}) {
  const auth = useAuth();

  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const { handleSubmit, register, errors, getValues } = useForm({
    defaultValues: {
      email: invitation.email,
      employerId: invitation.employerId,
      type: invitation.type,
      title: invitation.title,
      name: invitation.name,
      invitationId: invitation.id,
      password: "",
      confirmPass: "",
      terms: false,
    },
  });

  type EmployerRegistration = {
    email: string;
    password: string;
    employerId: string;
    type: string;
    title: string;
    name: string;
    invitationId: string;
    displayName: string;
  };

  // on successful account creation redirects to mfa enrollment.
  const onSubmit = (data: EmployerRegistration) => {
    setPending(true);
    processingInvitation(true);

    createAuthOrigin({
      email: data.email.toLowerCase(),
      origin: Origin.employerInvited,
      status: AuthStatus.start,
      lastUpdated: Timestamp.now(),
    })
      .then(() => {
        return auth
          .signupEmployer(data)
          .then((response: any) => {
            onDone();
          })
          .catch((error: Error) => {
            processingInvitation(false);
            setFormAlert(`There was a problem creating ${data.displayName}`);
            errorLogger(error.message, {
              component: "InviteOnlyRegistrationForm signUpEmployer()",
              flow: "Employer Registration",
              userId: "n/a",
            });
            throw error;
          })
          .finally(() => setPending(false));
      })
      .catch((error) => {
        processingInvitation(false);
        setFormAlert(`There was a problem creating ${data.displayName}`);
        errorLogger(error.message, {
          component: "InviteOnlyRegistrationForm createAuthOrigin()",
          flow: "Employer Registration",
          userId: "n/a",
        });
      })
      .finally(() => setPending(false));
  };

  return (
    <>
      <p className="mb-4 uppercase text-left font-semibold text-instant-teams-blue-Main">
        USER INFORMATION
      </p>
      {formAlert && (
        <div className="mb-4 text-red-600">{formAlert.message}</div>
      )}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        {/* Hidden Fields */}
        <input
          className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
          name="employerId"
          type="hidden"
          ref={register({
            required: "We cannot find a company associated with your account.",
          })}
        />
        <input
          className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
          name="invitationId"
          type="hidden"
          ref={register({
            required:
              "We cannot find an invitation associated with your record.",
          })}
        />
        <input
          className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
          name="type"
          type="hidden"
          ref={register({
            required: "There was a problem identifying your user.",
          })}
        />
        {/* Visible Fields */}
        <div className="mb-2">
          <input
            className="py-1 px-3 font-bold w-full leading-8 bg-gray-200 rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            readOnly
            name="email"
            type="email"
            ref={register({
              required: "Please enter an email",
            })}
          />
          {errors.email && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.email.message}
            </p>
          )}
        </div>
        <div className="mb-2">
          <input
            className={`py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 ${
              errors.name ? "border-red-600" : ""
            }`}
            placeholder="Full Name"
            name="name"
            type="text"
            ref={register({ required: "Please enter your full name." })}
          />
          {errors.name && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.name.message}
            </p>
          )}
        </div>

        <div className="mb-2">
          <input
            className={`py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 ${
              errors.title ? "border-red-600" : ""
            }`}
            name="title"
            type="text"
            placeholder="Job Title"
            ref={register({ required: "Please enter your job title." })}
          />
          {errors.title && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.title.message}
            </p>
          )}
        </div>
        <div className="mb-2">
          <input
            className={`py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 ${
              errors.password ? "border-red-600" : ""
            }`}
            name="password"
            type="password"
            placeholder="Password"
            ref={register({
              required: "Please enter a password",
            })}
          />

          {errors.password && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.password.message}
            </p>
          )}
        </div>
        <div className="mb-2">
          <input
            className={`py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 ${
              errors.confirmPass ? "border-red-600" : ""
            }`}
            name="confirmPass"
            type="password"
            placeholder="Confirm Password"
            ref={register({
              required: "Please enter your password again",
              validate: (value) => {
                if (value === getValues().password) {
                  return true;
                } else {
                  return "This doesn't match your password";
                }
              },
            })}
          />

          {errors.confirmPass && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.confirmPass.message}
            </p>
          )}
        </div>
        <div className="mt-4 mb-2 ">
          <div className="flex items-start ">
            <input
              className={`py-3 px-3 leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 ${
                errors.terms ? "border-red-600" : ""
              }`}
              id="terms"
              name="terms"
              type="checkbox"
              ref={register({
                required: "Please agree to our terms of service.",
              })}
            />
            <label
              htmlFor="terms"
              className="ml-2 text-left text-instant-teams-gray-D1"
            >
              <span>By creating an account, you agree to our </span>
              <br />
              <a
                className="underline"
                href={
                  "https://instantteams.com/terms-and-conditions/"
                }
                target="_blank"
                rel="noreferrer"
              >
                Terms & Conditions
              </a>{" "}
              <span>and have read our </span>
              <a
                className="underline"
                href={"https://instantteams.com/privacypolicy/"}
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </label>
          </div>
          {errors.terms && (
            <p className="mt-1 text-sm text-center text-red-600">
              {errors.terms.message}
            </p>
          )}
        </div>

        <ForwardArrowButton pending={pending} type="submit" />
      </form>
    </>
  );
}

export default RegistrationForm;
