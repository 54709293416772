import { useParams, useLocation } from "react-router-dom";
import PositionItem from "../../positions/PositionItem";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import JobPositionForm from "../parts/JobPositionForm";
import useModal from "../../../hooks/useModal";
import { useJobPositions } from "../../../data/positions";
import { useJobDescription } from "../../../data/jobDescriptions";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { useEmployer } from "../../../data/employer";
import { useAuth } from "../../../providers/auth";

function JobPositionsListPage() {
  const { userClaims } = useAuth();
  const params = useParams();
  const jobId = params.id ?? "";
  const employerId = params.employerId ?? "";
  const navigate = useNavigate();
  const location = useLocation();

  const { isShowing, toggle } = useModal();

  const { data: jobDescription = {} } = useJobDescription(jobId);

  const { data: employer = {} } = useEmployer(employerId);

  const isCreatePositionDisabled = employer?.status !== "active";

  const {
    data: positions = [],
    status: positionsQueryStatus,
    error: positionsQueryError,
  } = useJobPositions(jobId);

  const handleCreatePosition = (positionId: string) => {
    navigate(`${location.pathname}/${positionId}`);
  };

  return (
    <div className="grid gap-3 grid-cols-3 sm:grid-cols-1 place-content-center">
      {userClaims?.claims?.type === "A" ? (
        <div className="col-span-3 mb-8">
          <button
            disabled={isCreatePositionDisabled}
            className={`p-2 w-1/6 text-white mb-2 ${
              isCreatePositionDisabled
                ? "bg-instant-teams-gray-D1 cursor-not-allowed"
                : "hover:bg-instant-teams-blue-L1 bg-instant-teams-blue-Main"
            }   rounded border-0 focus:outline-none`}
            onClick={toggle}
          >
            Add New Position
          </button>
          {isCreatePositionDisabled && (
            <div>
              Adding a new position is disabled until the employer status is set
              to active.
            </div>
          )}
        </div>
      ) : null}
      {/* If no positions then display message */}
      {isEmpty(positions) && (
        <div className="col-span-3">
          <div className="flex flex-col items-center justify-items-center text-instant-teams-blue-Main">
            <p> No positions at this time.</p>
            {userClaims?.claims?.type === "A" ? (
              <p>Please click New Position to add a new position.</p>
            ) : null}
          </div>
        </div>
      )}

      {positions.map((item: any) => (
        <PositionItem key={item.id} position={item} />
      ))}

      {isShowing && (
        <DialogWrapper title="New Position" onClose={toggle}>
          <JobPositionForm
            jobId={jobId}
            departmentId={jobDescription?.department}
            employerId={employerId}
            onDone={handleCreatePosition}
          />
        </DialogWrapper>
      )}
    </div>
  );
}

export default JobPositionsListPage;
