import { useState, useEffect } from 'react';
import GreenUnderlineHeading from "../../components/custom-ui/headings/heading";
import Template from '../../components/interviews/General/EditTemplate';
import { useAllEInterviews } from '../../data/eInterview';

function EInterviewTemplates() {
  const [trigger, setTrigger] = useState(true);
  const { data: questions } = useAllEInterviews(trigger);

  useEffect(() => {
    console.log("EQs: ", questions);
  },[questions])

  return (
    <>
      <GreenUnderlineHeading title="e-interview Questions" />
      <div className="w-full">
        {questions && questions.map((question: any, index: number) => (
          <Template id={question.id} key={index} readOnly={false} /> 
        ))}
        
      </div>
    </>
  );
}

export default EInterviewTemplates;
