import React, { useState, useEffect } from "react";
import { useRotatorImagesByStatus } from "../../../data/rotatorInages";
import { useRotatorQuotesByStatus } from "../../../data/rotatorQuotes";
import { useLocation } from "react-router-dom";

function RotatorDisplay() {
  const location = useLocation();
  const [trigger, setTrigger] = useState(false);
  const [image, setImage] = useState("");
  const [quote, setQuote] = useState("");
  const [displayStatus, setDisplayStatus] = useState("active");
  const { data: rotatorImages } = useRotatorImagesByStatus(displayStatus);
  const { data: rotatorQuotes } = useRotatorQuotesByStatus(displayStatus);

  useEffect(() => {
    if (rotatorImages && rotatorImages.length > 0) {
      setImage(rotatorImages[Math.floor(Math.random() * rotatorImages.length)]);
    }
    if (rotatorQuotes && rotatorQuotes.length > 0) {
      setQuote(rotatorQuotes[Math.floor(Math.random() * rotatorQuotes.length)]);
    }
  }, [rotatorImages, rotatorQuotes, location]);

  return (
    <>
      <div className="w-full h-screen sticky top-0 overflow-hidden overscroll-contain">
        <div className="relative h-screen w-full">
          {image !== "" && (
            <img
              src={image?.image}
              className="absolute top-0 right-0 w-full min-h-screen object-cover"
              alt=""
            />
          )}
          {quote !== "" && image !== "" && image?.containQuote && (
            <p className="absolute top-3/4 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white py-2 px-4 border-b-8 border-instant-teams-teal-Main text-sm">
              <span className="w-full font-bold text-instant-teams-teal-Main float-left  whitespace-nowrap">
                {quote?.attribution ? "" : "DID YOU KNOW?"}
              </span>
              {quote?.quote}
              <span className="font-bold text-instant-teams-teal-Main float-right whitespace-nowrap">
                {quote?.attribution ? ` - ${quote.attribution}` : null}
              </span>
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default RotatorDisplay;
