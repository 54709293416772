import { useEffect, useState } from "react";
import { Application } from "../../dataTypes/Application";
import { useUser } from "../../providers/database";
import Affiliations from "../user/details/parts/Affiliations";
import SeekerPositionList from "../seeker/details/parts/seekerPositionList";
import { useJobDescription } from "../../data/jobDescriptions";
import Tooltip from "../custom-ui/tooltip/tooltip";
import { useFindApplicationStatus } from "../../data/status";
import { Link } from "react-router-dom";
import {
  useApplication,
  useApplicationStatusHistory,
} from "../../data/applications";
import PhoneNumber from "../custom-ui/input-fields/phoneNumber";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import useModal from "../../hooks/useModal";
import { isArray } from "lodash";
import { DisplayTimestamp } from "../../util/timestamps/timestamp";
import InterviewItem from "./parts/InterviewItem";
import EInterviewListing from "./parts/EInterviewListing";
import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";
import AutoSaveRadioGrade from "../custom-ui/input-fields/auto-save-radio-grade";
import { collectionNames } from "../../data/dictionary/collectionNames";
import GreenUnderlineHeading from "../custom-ui/headings/heading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useActiveMeetingsApplication } from "../../data/applications";
import FailedSkillListing from "./parts/FailedSkillItem";
import OveralProgress from "./parts/OverallProgress";
import OverallProgressInterviewAndSkillsCounter from "./parts/OverallProgressInterviewAndSkillsCounter";

function ApplicationItem({
  application,
  showJobDescription = false,
}: {
  application: Application;
  showJobDescription?: boolean;
}) {
 
  const [statusDate, setStatusDate] = useState("");
 
  const { data: appData = {} } = useApplication(
    application?.objectID || application?.id,
  );



  const { data: applicant = {}, status: applicantQueryStatus } = useUser(
    application?.resumeId,
  );
  const { data: jobDescription = {} } = useJobDescription(application?.jobId);
  const { data: statusData = {} } = useFindApplicationStatus(
    application?.status,
  );

  const { data: applicationStatusHistory } = useApplicationStatusHistory(
    application?.objectID,
  );
  const { data: appMeetings } = useActiveMeetingsApplication(
    application?.objectID || application?.id,
  );

  /* 
    can't move this to EInterviewListing 
     Because of the handleEInterview function
    If it's in there, it doesn't preventDefault from happening
  */
  const handleEInterview = (event: React.MouseEvent<Element, MouseEvent>) => {
    toggleEModal();
  };

  const { isShowing: showEModal, toggle: toggleEModal } = useModal();

  useEffect(() => {
    if (
      !isArray(applicationStatusHistory) ||
      applicationStatusHistory.length === 0
    ) {
      setStatusDate("Unchanged");
    } else {
      setStatusDate(
        DisplayTimestamp(applicationStatusHistory[0].effectiveDate),
      );
    }
  }, [applicationStatusHistory]);

  const colorJobListingVariants: any = {
    ready: "bg-instant-teams-tags-BLUE",
    bench: "bg-instant-teams-tags-ROSE",
    underReview: "bg-instant-teams-tags-GREEN",
    invited: "bg-instant-teams-tags-GOLD",
    presented: "bg-instant-teams-tags-TEAL",
    applied: "bg-instant-teams-tags-ORANGE",
    conversation: "bg-instant-teams-tags-BLUE",
    knockout: "bg-instant-teams-purple-Main",
    preOffer: "bg-instant-teams-orange-Main",
    notSelected: "bg-instant-teams-gray-D1",
    ineligible: "bg-instant-teams-purple-Main",
    serviceApproved: "bg-instant-teams-tags-LIME",
    serviceReview: "bg-instant-teams-tags-TURQUOISE",
    topCandidate: "bg-instant-teams-tags-SKYBLUE",
  };

  

  return (
    <>
      <div
        key={application?.objectID || application?.id}
        className={
          TailwindHoverEnlarge(103) +
          "shadow-lg rounded-xl bg-white h-full mt-4"
        }
      >
        {applicantQueryStatus === "success" && (
          <div className="w-full grid grid-cols-5 gap-1 h-full relative mr-4">
            {appMeetings && appMeetings.length > 0 && (
              <div className="absolute top-0 right-2 bg-instant-teams-teal-Main w-9 h-9 text-center text-white rounded-b-xl shadow-sm shadow-gray-500">
                <FontAwesomeIcon
                  className="text-white pt-2"
                  icon={["fas", "calendar-day"]}
                />
              </div>
            )}
            <div className="w-full flex mb-2 p-2 col-span-2 bg-instant-teams-teal-Main text-white rounded-l-lg h-full cursor-pointer">
              <Link
                to={`/applications/${
                  application.objectID || application.id
                }/details`}
              >
                <div className="w-full flex mb-2 p-2 col-span-2 bg-instant-teams-teal-Main text-white rounded-l-lg h-full cursor-pointer">
                  <img
                    src={
                      applicant?.photoURL
                        ? applicant?.photoURL
                        : "/images/default_user.png"
                    }
                    className={`rounded-full h-12 w-12 border-solid border-4 my-auto ${
                      applicant?.status !== "active"
                        ? " border-instant-teams-red-Main"
                        : "border-instant-teams-green-D1"
                    }`}
                    alt={`${applicant?.name?.toUpperCase()}`}
                  />
                  <div>
                    <p className="pl-4 text-sm pt-2">{`${applicant?.name?.toUpperCase()}`}</p>
                    <p className="pl-4 text-xs pt-1">{applicant?.email}</p>
                    <p className="pl-4 text-xs pt-1">
                      {applicant?.address?.standardizedAddress}
                    </p>
                    <div className="pl-4 text-xs pt-1">
                      <PhoneNumber number={applicant?.phone || ""} />
                    </div>
                    <div className="pl-4 text-xs pt-1">
                      <Affiliations
                        userId={appData?.resumeId}
                        background={"darkBackground"}
                      />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="w-full h-full col-span-1 pt-2 pl-4 pr-4 mt-2 text-sm pb-2">
              {showJobDescription && (
                <p className="text-base text-instant-teams-teal-Main pb-2 pt-1">
                  {jobDescription?.title}
                </p>
              )}
              <SeekerPositionList
                applicationId={application?.objectID || application?.id}
                jobId={application?.jobId}
              />
            </div>
            <div>
              {statusData && !statusData.hideData && (
                <>
                  {appData && appData.status === "applied" ? (
                    <>
                      <div className="uppercase w-full h-auto text-center mb-4 mt-4">
                        <OveralProgress application={appData} />
                      </div>
                      <OverallProgressInterviewAndSkillsCounter
                        application={appData}
                      />
                    </>
                  ) : (
                    <>
                      {appData && appData.progressData?.failedSkillIds && (
                        <FailedSkillListing
                          skill={appData.progressData?.failedSkillIds}
                        />
                      )}
                    </>
                  )}
                </>
              )}
              {statusData && statusData?.showEinterview && (
                <EInterviewListing
                  application={application}
                  handleEInterview={handleEInterview}
                />
              )}
            </div>
            <div className="w-full h-full col-span-1 pt-2 pl-4 pr-4 text-sm pb-2 mt-8">
              <Tooltip text={statusData?.description || "Current Status"}>
                <div
                  key={appData?.status}
                  className={`items-center w-full mt-2 rounded-md text-white mx-auto p-1 mb-3 ${
                    colorJobListingVariants[appData?.status] ||
                    "bg-instant-teams-purple-Main"
                  }`}
                >
                  <p className="text-center">{statusData?.label}</p>
                </div>
              </Tooltip>
              <Tooltip text={"Date of Last Status Change"}>
                <div className="text-sm">{statusDate} </div>
              </Tooltip>
              <Tooltip
                text={
                  "Talent Acquisitions Coordinator assigned to this applicant"
                }
              >
                <div className="my-4 text-sm">
                  TAC: {appData?.tacName ? appData?.tacName : "unassigned"}
                </div>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
      {/* can't move this to EInterviewListing 
        Because of the handleEInterview function
        If it's in there, it doesn't preventDefault from happening
              */}
      {showEModal && (
        <DialogWrapper
          onClose={() => toggleEModal()}
          title="e-interview Responses"
          size="mt-36 w-1/3"
        >
          <>
            <InterviewItem application={application} />
            <GreenUnderlineHeading title={"Overall Grade"} />
            <div className="w-full font-bold text-center mt-4 mb-2">
              Professional Appearance
            </div>
            <AutoSaveRadioGrade
              name="appearance"
              initialValue={appData?.appearance || 0}
              collection={collectionNames.applications}
              id={application?.objectID}
            />
            <hr />
            <div className="w-full font-bold text-center mt-6 mb-2">
              Quiet, Professional Workspace
            </div>
            <AutoSaveRadioGrade
              name="workspace"
              initialValue={appData?.workspace || 0}
              collection={collectionNames.applications}
              id={application?.objectID}
            />
            <hr />
            <div className="w-full font-bold text-center mt-6 mb-2">
              Working Webcam
            </div>
            <AutoSaveRadioGrade
              name="webcam"
              initialValue={appData?.webcam || 0}
              collection={collectionNames.applications}
              id={application?.objectID}
            />
            <hr />
            <div className="w-full font-bold text-center mt-6 mb-2">
              Professional conduct: Makes a relevant introduction, Makes eye
              contact, Appears confident and friendly
            </div>
            <AutoSaveRadioGrade
              name="conduct"
              initialValue={appData?.conduct || 0}
              collection={collectionNames.applications}
              id={application?.objectID}
            />
            <hr />
            <div className="w-full font-bold text-center mt-6 mb-2">
              Actively listens, responds appropriately, and uses a professional
              voice and tone
            </div>
            <AutoSaveRadioGrade
              name="tone"
              initialValue={appData?.tone || 0}
              collection={collectionNames.applications}
              id={application?.objectID}
            />
          </>
        </DialogWrapper>
      )}
    </>
  );
}

export default ApplicationItem;
