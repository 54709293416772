import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const InfoIcon = ({ tooltipText, singupFlow = false, viewDown = false }: any) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef(null);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };
    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (

        <div
            className="relative ml-2"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={tooltipRef}
        >
            <span className="text-sm cursor-pointer text-[#1E596D] border-[#1E596D] flex h-4 w-4 flex-col items-center justify-center pt-[1px]  rounded-full border bg-white tracking-wide shadow-lg">
                i
            </span>
            {showTooltip && (
                <div className={`absolute z-50 left-full mt-6 transform -translate-y-1/2 ${viewDown ? "ml-[-210px]" : "ml-2"} ${viewDown ? "mt-[40px]" : "mt-2"}  w-48 p-2 bg-gray-50 text-black z-50 text-sm rounded shadow-lg fill-zinc-100`}>
                    <span dangerouslySetInnerHTML={{ __html: tooltipText }} />
                </div>
            )}
        </div>
    );
};

export default InfoIcon;