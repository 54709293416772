const getRandomColor = () => {
  const colors = [
    "#90dcd0",
    "#4198d7",
    "#2a6a99",
    "#0c3d5b",
    "#7a61ba",
    // "#91919",
  ];
  const randomColor = colors[Math.floor(Math.random() * colors.length)];
  return randomColor;
};

const getPastelColors = () => {
  const colors = [
    "#90dcd0",
    "#4198d7",
    "#2a6a99",
    "#0c3d5b",
    "#7a61ba",
    "#90dcd0",
    "#4198d7",
    "#2a6a99",
    "#0c3d5b",
    "#7a61ba",
  ];
  return colors;
};

const sortArrayByDate = (arr) => {
  const newArray = [];
  Object.keys(arr).forEach((key) => {
    const date = key;
    const value = arr[key];
    console.log("utils/charts/utils.js, arr.forEach, key: ", key);
    console.log("utils/charts/utils.js, arr.forEach, item[key]: ", arr[key]);
    newArray.push({
      date,
      value,
    });
  });
  return newArray.sort(compareByDate);
};

const compareByDate = (a, b) => {
  const parsedDateA = Date.parse(a.date);
  const parsedDateB = Date.parse(b.date);
  if (parsedDateA < parsedDateB) {
    return -1;
  }
  if (parsedDateA > parsedDateB) {
    return 1;
  }
  return 0;
};

module.exports = { sortArrayByDate, getRandomColor, getPastelColors };
