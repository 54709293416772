import { memo, forwardRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useJobDescription } from "../../../../data/jobDescriptions";
import { useJobPositionDetails } from "../../../../data/positions";
import { useUser } from "../../../../providers/database";
import { Link } from "react-router-dom";
import { useFindEmploymentStatus } from "../../../../data/status";
import Tooltip from "../../../custom-ui/tooltip/tooltip";
import { useEmploymentByEmployee } from "../../../../data/employee";
import { DisplayDate } from "../../../../util/timestamps/timestamp";
import { TailwindHoverEnlarge } from "../../../../util/tailwind/tailwind-styles";
import { useAuth } from "../../../../providers/auth";
import { useEffect } from "react";

const EmployeeLineComponent = (row: any) => {
  const [status, setStatus] = useState("pending");
  const [jobId, setJobId] = useState(row?.row?.hit?.jobId);
  const [positionId, setPositionId] = useState(row?.row?.hit?.positionId);
  const [userId, setUserId] = useState(row?.row?.hit?.userId);
  const { data: employeeData } = useUser(userId);
  const { data: jobData } = useJobDescription(jobId);
  const { data: positionData } = useJobPositionDetails(jobId, positionId);
  const { data: employmentRecord } = useEmploymentByEmployee(
    row?.row?.hit?.objectID,
  );
  const { data: employmentStatus } = useFindEmploymentStatus(status);

  useEffect(() => {
    if (employmentRecord) {
      setStatus(employmentRecord?.status);
      console.log(employmentRecord?.status, status);
    }
    console.log(employmentRecord, employmentStatus);
  }, [employmentRecord]);

  const colorJobListingVariants: any = {
    pending: "bg-instant-teams-tags-BLUE",
    started: "bg-instant-teams-tags-GREEN",
    closed: "bg-instant-teams-gray-D1",
    cause: "bg-instant-teams-red-L1",
    prejudice: "bg-instant-teams-red-MAIN",
    withoutPrejudice: "bg-instant-teams-CREAM",
    mutual: "bg-instant-teams-GOLD",
    resignationCause: "bg-instant-teams-ROSE",
    resigned: "bg-instant-teams-LBLUE",
    voluntary: "bg-instant-teams-BLUE",
    nonStart: "bg-instant-teams-tags-ORANGE",
  };

  return (
    <Link to={`/employee/${row?.row?.hit?.objectID}/details`}>
      <div
        className={
          TailwindHoverEnlarge(103) +
          "w-full flex h-28 mb-2 rounded-r-lg shadow-input-field"
        }
      >
        <div className="w-[35%] bg-instant-teams-blue-Main rounded-l-lg text-white flex justify-center items-center">
          <div className="w-[30%] flex justify-center">
            <img
              className="h-16 w-16 rounded-full"
              src={employeeData?.photoURL || "/images/default_user.png"}
              alt={employeeData?.name}
            />
          </div>
          <div className="w-[70%]">
            <p className="text-xl">{employeeData?.name}</p>
          </div>
        </div>
        <div className="flex justify-around w-[65%]">
          <div className="w-1/2 h-full flex flex-col justify-center items-center">
            <div className="text-xl font-semibold ">{jobData?.title}</div>

            <div className="text-base text-center font-semibold">
              {positionData?.positionName}
            </div>
          </div>
          <div className="flex flex-col w-1/2 justify-center items-center h-full">
            <div
              className={`w-[60%] py-[4px] rounded-md text-white  ${
                colorJobListingVariants[employmentRecord?.status] ||
                "bg-instant-teams-purple-Main"
              }`}
            >
              <Tooltip text={employmentStatus?.description || "Current Status"}>
                <p className="text-center">{employmentStatus?.label}</p>
              </Tooltip>
            </div>
            <div className="font-semibold mt-2">
              Start Date: {DisplayDate(employmentRecord?.startDate)}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default memo(EmployeeLineComponent);
