import { firebaseApp } from "../providers/firebase";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const storage = getStorage(firebaseApp);

export async function upload(file: any, folderName: string, fileName: string) {
  const fileRef = ref(storage, `${folderName}/${fileName}`);

  await uploadBytes(fileRef, file).then((snapshot) => {
    console.log("snapshot", snapshot);
  });

  const fullPath = fileRef.fullPath;
  const donwloadURL = await getDownloadURL(fileRef);
  const extIndex = donwloadURL.lastIndexOf(".");
  const ext = donwloadURL.substring(extIndex);

  return `${donwloadURL.substring(0, extIndex)}${ext}`;
}


