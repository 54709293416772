import { memo } from "react";

const SectionHeading = memo((props) => {
  return (
    <>
      <div className="ml-2 mt-2 mb-2 uppercase font-bold text-instant-teams-teal-Main">
        {props.title}
      </div>
    </>
  );
});

export default SectionHeading;
