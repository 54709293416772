import { updateAutoSave } from "../../../data/utility";
import { useNavigate } from "react-router-dom";
import { errorLogger } from "../../../providers/clientLogger";
import { useAuth } from "../../../providers/auth";
import { useState, useEffect } from "react";
import { Timestamp } from "firebase/firestore";
import { createNote } from "../../../data/notes";

function DeclineApplication({
  collection,
  toggleRescindApplication,
  passedApplicationId = "",
  minLength = 10,
}: {
  collection: string;
  toggleRescindApplication: Function;
  passedApplicationId?: string;
  minLength?: number;
}) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [declineReason, setDeclineReason] = useState("");
  const [characterCount, setCharacterCount] = useState(0);
  const [countCheck, setCountCheck] = useState(false);

  useEffect(() => {
    setCharacterCount(declineReason.length);
  }, [declineReason]);

  useEffect(() => {
    if (characterCount >= minLength) {
      setCountCheck(true);
    } else {
      setCountCheck(false);
    }
  }, [characterCount, declineReason, minLength]);

  const handleRescinedApplication: any = async () => {
    try {
      await updateAutoSave(collection, passedApplicationId, {
        status: "declined",
      });
      await createNote(passedApplicationId, collection, {
        isPublic: true,
        note: declineReason,
        timestamp: Timestamp.now(),
        type: "StatusNote",
        who: user?.id,
      });
    } catch (error) {
      errorLogger("error while declining an applicant", {
        component: "Decline Application handleRescinedApplication()",
        flow: "Application Decline",
        userId: user?.uid,
      });
    } finally {
      // to allow algolia to catch up, without it might render the application as active.
      setTimeout(() => {
        navigate(0);
      }, 2000);
    }
  };

  return (
    <>
      <p className="py-2 mb-4 text-instant-teams-teal-D2 text-lg ">
        You are declining this Career Seeker for this position. Please provide
        any feedback to the Career Seeker so it may assist them in the future.
      </p>

      <div className="mt-6 ">
        <p className="text-gray-600 text-xs flex justify-end">
          Character Count: {characterCount}
          {!countCheck && (
            <span className="text-red-500 pl-1"> (minimum: {minLength})</span>
          )}
        </p>
        <label className="sr-only">Reason for cancel/reschedule</label>
        <textarea
          placeholder={`Explanation required `}
          className="w-full rounded-xl leading-8"
          onChange={(e) => setDeclineReason(e.target.value)}
        >
          {declineReason}
        </textarea>
      </div>
      <div className="flex justify-center space-x-4 mt-5">
        <button
          disabled={declineReason.length < minLength}
          onClick={() => handleRescinedApplication()}
          className="py-2 uppercase px-10 w-full text-white bg-instant-teams-red-Main rounded border-0 hover:border  hover:bg-instant-teams-red-L1  focus:outline-none"
        >
          {" "}
          Yes Decline
        </button>
        <button
          onClick={() => toggleRescindApplication()}
          className="py-2 uppercase px-10 w-full  rounded hover:text-white  hover:border  hover:bg-instant-teams-blue-D1 border border-instant-teams-blue-Main  text-white bg-instant-teams-blue-Main focus:outline-none"
        >
          No
        </button>
      </div>
    </>
  );
}
export default DeclineApplication;
