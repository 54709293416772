import { useCallback, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/pro-regular-svg-icons";
import { useDropzone } from "react-dropzone";

function DragAndDropVariantA({ setFile }:any) {
  const [eventState, setEventState] = useState();
  const [fileName, setFileName] = useState();
  const [showRetake, setShowRetake] = useState(false);
  const fileInputRef = useRef(null); // Create a ref for the file input

  const dragDropEvents = {
    onDragEnter: "onDragEnter",
    onDragLeave: "onDragLeave",
    onDragOver: "onDragOver",
    onDrop: "onDrop",
    onDropAccepted: "onDropAccepted",
  };

  const dropEventStyles = () => {
    if (eventState === dragDropEvents.onDragEnter) return `bg-instant-teams-green-L2`;
    if (eventState === "onDragLeave") return;
    if (eventState === "onDragOver") return `bg-instant-teams-green-L2`;
    if (eventState === "onDrop") return;
  };

  const isDropAccepted = eventState === dragDropEvents.onDropAccepted;

  const onDrop = useCallback((acceptedFiles:any) => {
    if (acceptedFiles.length < 1) return;
    setEventState(dragDropEvents.onDrop);
    acceptedFiles.forEach((file:any) => {
      const reader = new FileReader();
      setFile(file);
      setFileName(file.name);

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const binaryStr = reader.result;
      };
      reader.readAsArrayBuffer(file);
    });
  }, [setFile]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    },
    multiple: false,
    maxFiles: 1,
    onDragEnter: () => {
      if (!showRetake) {
        setEventState(dragDropEvents.onDragEnter);
        console.log("entering dropzone");
      }
    },
    onDragLeave: () => {
      if (!showRetake) {
        setEventState(dragDropEvents.onDragLeave);
        console.log("leaving dropzone");
      }
    },
    onDragOver: () => {
      if (!showRetake) {
        setEventState(dragDropEvents.onDragOver);
        console.log("over dropzone");
      }
    },
    onDropAccepted: () => {
      if (!showRetake) {
        setEventState(dragDropEvents.onDropAccepted);
      }
    },
    disabled: showRetake, // Disable drag-and-drop functionality if "Retake" button is visible
  });

  const handleRetake = () => {
    // Trigger file input click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
    setShowRetake(true); // Show Retake button
  };



  return (
    <>
      <section>
        <div {...getRootProps()}>
          {/* File input element with ref */}
          <input
            {...getInputProps()}
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }} // Hide the file input
          />
          {!isDropAccepted && !showRetake && (
            <div className="p-1 shadow-md bg-white border divide-x-4 border-instant-teams-teal-Main border-dashed rounded transition-colors">
              <div className="flex flex-col justify-center">
                <FontAwesomeIcon
                  className="p-5 rounded text-instant-teams-teal-Main"
                  icon={faUpload}
                  size="2xl"
                />
                <p className="text-instant-teams-blue-Main text-center">
                  Drag & Drop or Browse to Upload
                </p>
              </div>
            </div>
          )}
          {isDropAccepted && (
            <div
              className={`p-1 shadow-md bg-white border divide-x-4 border-instant-teams-teal-Main border-dashed rounded transition-colors ${dropEventStyles()}`}
            >
              <div>
                <div className="mt-1">
                  <p className="text-instant-teams-gray-D1 p-1 text-left">{fileName}</p>
                </div>
              </div>
              <div className="flex w-full justify-end mt-1 mb-2 mr-2">
                <button
                  className="bottom-3 mt-5 py-2 px-4  text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                  onClick={handleRetake} 
                >
                  Retake
                </button>

              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default DragAndDropVariantA;
