import { useJobDescription } from "../../../../data/jobDescriptions";
const OffersFilterCheckbox = ({
  jobId,
  filterJobIds,
}: {
  jobId: string;
  filterJobIds: any;
}) => {
  const id: number = Math.random();
  const { data: jobDescription } = useJobDescription(jobId);
  return (
    <div className=" col-span-4 w-full max-w-full">
      <div key={id} className="flex items-center  ml-1 mt-1 mb-1">
        <label
          className="select-none inline-block pl-[0.15rem] hover:cursor-pointer truncate ... "
          htmlFor={id.toString()}
        >
          <input
            className="relative bg-grey-700 text-instant-teams-teal-Main mr-4 focus:text-primary focus:ring-offset-0 focus:ring-0"
            type="checkbox"
            value=""
            id={id.toString()}
            onChange={(e) => {
              console.log("CheckBox, input, onChange: ", e.target.checked);
              if (e.target.checked) {
                filterJobIds.current.push(jobId);
                console.log(
                  "Checkbox, filterJobIds.current: ",
                  filterJobIds.current,
                );
              } else {
                const index = filterJobIds.indexOf(jobId);
                if (index > -1) {
                  filterJobIds.current.splice(index, 1);
                }
              }
            }}
          />
          <span className=" text-xs">{jobDescription?.title}</span>
        </label>
      </div>
    </div>
  );
};
export default OffersFilterCheckbox;
