import { useEffect, useState } from "react";
import AutoSaveShiftSchedule from "../../custom-ui/input-fields/auto-save-shift-schedule";
import AutoSaveNumber from "../../custom-ui/input-fields/auto-save-number";
import AutoSaveSelectGeneric from "../../custom-ui/input-fields/auto-save-select-generic";
import AutoSaveText from "../../custom-ui/input-fields/auto-save-field";
import AutoSaveExJobText from "../../custom-ui/input-fields/auto-save-external-job-field";
import AutoSaveRadioGroup from "../../custom-ui/input-fields/auto-save-radio-group";
import AutoSaveDateRange from "../../custom-ui/input-fields/auto-save-date-range";
import AutoSaveDate from "../../custom-ui/input-fields/auto-save-date";
import AutoSavePayTypeRadio from "../../custom-ui/input-fields/auto-save-pay-type-radio";
import AutoSaveToggle from "../../custom-ui/input-fields/auto-save-toggle";
import CreateEmployerLocation from "../../employer/details/parts/CreateEmployerLocation";
import AutoSaveWrapper from "../../custom-ui/input-fields/auto-save-wrapper";
import DialogWrapper from "../../custom-ui/dialog/Dialog";

import Tooltip from "../../custom-ui/tooltip/tooltip";
import FadeInFromTop from "../../custom-ui/animations/transitions/FadeInFromTop";
import { Timestamp } from "firebase/firestore";
import {
  collectionNames,
  subCollectionNames,
} from "../../../data/dictionary/collectionNames";
import { useLookup } from "../../../data/lookup";
import { LookUp } from "../../../dataTypes/LookUp";
import useModal from "../../../hooks/useModal";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useJobPositionDetails } from "../../../data/positions";
import { useJobDescription } from "../../../data/jobDescriptions";
import { useAllEmployerLocations, useEmployer } from "../../../data/employer";
import { isEqual, isNil, isString, find } from "lodash";
import { z } from "zod";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import { isPositionLocked } from "../../../util/jobs/jobUtils";
import { useAllActiveEmployerTrainings } from '../../../data/employer';
import { DisplayDate } from '../../../util/timestamps/timestamp';

// implementing conditional validations with refine https://github.com/colinhacks/zod/discussions/938

const positionSchema = z.object({
  id: z.string(),
  positionName: z.string(),
  employer: z.string(),

  shiftSchedule: z.object({
    shiftOption: z.object({
      code: z.string({
        required_error: "Please select a shift option.",
      }),
    }),
    range: z
      .number()
      .array()
      .length(2, { message: "Please select a time range." }),
    days: z
      .object({})
      .array()
      .nonempty({ message: "Please select at least one day." }),
  }),
  information: z.object({
    startDate: z.object({}),
    seasonalDateRange: z.object({}).optional(),
    payType: z.union([
      z.object({
        SAL: z.number().array().length(2, { message: "Please set a salary range" }),
        COMM: z.string().optional(),
      }),
      z.object({
        HRLY: z.number().array().length(2, { message: "Please set a salary range" }),
        COMM: z.string().optional(),
      }),
    ]),
    term: z.string({
      required_error: "Please select a work term.",
    }),
    model: z.string({
      required_error: "Please select a work setting.",
    }),
    classification: z.string({
      required_error: "Please select a work type.",
    }),
    locationId: z
      .string({
        required_error: "Please select a location.",
      })
      .min(1, { message: "Please select a location." }),
    hires: z.any({ required_error: "Number of hires is required" }),
  }),
});

// gets the zod message for a given section
const getSectionIssue = (
  path: string,
  issues: {
    code: z.ZodIssueCode;
    path: (string | number)[];
    message: string;
  }[],
) => {
  const arrayPath = isString(path) ? path.split(".") : [];
  const issue = find(issues, (issue) => isEqual(issue.path, arrayPath));

  return issue;
};

function JobPositionDetails() {
  const params = useParams();
  const { id: jobId = "", positionId = "", employerId = "" } = params ?? {};
  const [message, setMessage] = useState();
  const [issues, setIssues] = useState();
  const [validateData, setValidateData] = useState();
  const [training, setTraining] = useState(false);
  const [trainingCycles, setTrainingCycles] = useState([]);
  const [urlActive, setUrlActive] = useState(false);
  const [manageToggle, setManageToggle] = useState()
  const [disablePositionToggle, setDisablePositionToggle] = useState(false)

  const {
    data: positionDetails = {},
    status: positionDetailsQueryStatus,
    error: positionDetailsQueryError,
  } = useJobPositionDetails(jobId, positionId);

  const {
    data: employerData = {}
  } = useEmployer(employerId);

  useEffect(() => {
    if (employerData && employerData?.training) {
      setTraining(employerData.training);
    }
  }, [employerData]);

  const { data: trainings = {} } = useAllActiveEmployerTrainings(employerId);

  useEffect(() => {
    const cycles = [];
    if (trainings && trainings.length > 0) {
      for (const training of trainings) {
        const displayDate = DisplayDate(training.startDate);
        const cycle = {
          id: training.id,
          label: `${training.title}-${displayDate}`,
        };
        console.log(cycle);
        cycles.push(cycle);
      }
    }
    setTrainingCycles(cycles)
  }, [trainings]);

  useEffect(() => {
    if (validateData) {
      // validate data against schema
      // if invalid then set the issues
      const result = positionSchema.safeParse(positionDetails);
      if (!result.success) {
        const { error } = result;
        setIssues(error.issues);
        setMessage({
          type: "error",
          text: "Position cannot be made active until required selections have been made.",
        });

      } else {
        setMessage({
          type: "success",
          text: "",//Toggle Enabled. Position data requirements met.
        });
        setIssues([]);
      }
    }
  }, [validateData, positionDetails]);

  useEffect(() => {
    setUrlActive(positionDetails?.urlActive ?? false); // Initialize urlActive state
  }, [positionDetails]);



  const { isShowing: isShowingCreateLocation, toggle: toggleCreateLocation } =
    useModal();

  const positionsSubCollectionPath = `${collectionNames.jobDescriptions}/${jobId}/${subCollectionNames.positions}`;

  // using jobDescription to set intital values
  // and provide options for the position's settings
  const {
    data: job = {},
    status: jobQueryStatus,
    error: jobQueryError,
  } = useJobDescription(jobId);
  const isFieldLocked = isPositionLocked(job?.status, positionDetails?.active);
  const SECTION_LOCKED_MESSAGE =
    "This section cannot be changed while the job status is live.";
  // employer locations
  const {
    data: locations = [],
    status: locationsStatus,
    error: locationsError,
  } = useAllEmployerLocations(employerId);

  const {
    data: model = [],
    status: modelQueryStatus = "",
    error: modelQueryError,
  } = useLookup("model");

  const {
    data: payType = [],
    status: payTypeQueryStatus = "",
    error: payTypeQueryError,
  } = useLookup("payType");

  const {
    data: term = [],
    status: termQueryStatus = "",
    error: termQueryError,
  } = useLookup("term");

  const {
    data: classification = [],
    status: classificationQueryStatus = "",
    error: classificationQueryError,
  } = useLookup("classification");

  const {
    data: daysOfWeek = [],
    status: daysOfWeekQueryStatus = "",
    error: daysOfWeekQueryError,
  } = useLookup("dow");

  const {
    data: shiftOptions = [],
    status: shiftOptionsQueryStatus = "",
    error: shiftOptionsQueryError,
  } = useLookup("shiftOption");

  // provides a set of option to the position based on range of options
  // selected in jobDescription
  const availableOptions = (
    jobOptions: string[] = [],
    lookupOptions: LookUp[] = [],
  ) =>
    lookupOptions.filter((item: LookUp) =>
      jobOptions.find((option: string) => option === item?.code),
    );

  // include dates within range of the jobDescription seasonal work term
  const includeDateInterval = {
    start:
      job?.information?.seasonalDateRange?.startDate ?? {} instanceof Timestamp
        ? job?.information?.seasonalDateRange?.startDate.toDate()
        : null,
    end:
      job?.information?.seasonalDateRange?.endDate ?? {} instanceof Timestamp
        ? job?.information?.seasonalDateRange?.endDate.toDate()
        : null,
  };

  useEffect(() => {

    setDisablePositionToggle(
      positionSchema.safeParse(positionDetails).success ? positionDetails?.externalUrlName ? false  : true : true
    )

  }, [positionDetails])

  return (
    <div className="grid gap-1 grid-cols-2 sm:grid-cols-2 place-content-center">
      {positionDetailsQueryStatus === "success" && (
        <>
          <div className="md:w-full w-96">
            {/* Col 1 */}
            <div>
              <GreenUnderlineHeading
                title="Position Name"
                showLockIcon={true}
                isLocked={isFieldLocked}
                text={SECTION_LOCKED_MESSAGE}
              />
              <div className="mb-2">
                <AutoSaveText
                  value={positionDetails?.positionName}
                  collection={`${collectionNames.jobDescriptions}/${jobId}/${subCollectionNames.positions}`}
                  recId={positionId}
                  required={true}
                  name="positionName"
                  display="Position name"
                  placeholder={"Enter a position name"}
                  directions=""
                  readOnly={isFieldLocked}
                />
              </div>
              <div className="mb-2">
                <div
                  onClick={() => {
                    setValidateData(true);
                  }}
                >
                  {message && (
                    <div
                      className={`mb-2 ${message.type === "error"
                        ? "text-red-500"
                        : "text-instant-teams-green-D2"
                        } text-sm`}
                    >
                      {message.text}
                    </div>
                  )}
                  <AutoSaveToggle
                    value={positionDetails?.active ?? false}
                    collection={`${collectionNames.jobDescriptions}/${jobId}/${subCollectionNames.positions}`}
                    id={positionId}
                    name={"active"}
                    on={"Active"}
                    off={"Inactive"}
                    disabled={disablePositionToggle  }
                                    />
                </div>
              </div>
              {training && trainings && trainings.length > 0 && (
                <div>
                  <GreenUnderlineHeading
                    title="Training Cycle Assignment"
                  />
                  <div className="mb-2">
                    <AutoSaveSelectGeneric
                      value={positionDetails?.cycle}
                      options={trainingCycles}
                      optionValue={"id"}
                      collection={`${collectionNames.jobDescriptions}/${jobId}/${subCollectionNames.positions}`}
                      recId={positionId}
                      required={false}
                      name="cycle"
                      display="Training Cycle"
                      placeholder="Select a training cycle"
                      directions=""
                    />
                  </div>
                </div>
              )}


              {/* Application Flow Start here */}
              <div className="mb-2">
                <GreenUnderlineHeading
                  title="Application Flow"
                  showLockIcon={true}
                  isLocked={isFieldLocked}
                  text={SECTION_LOCKED_MESSAGE}
                />

                <div className="mb-2">
  
                    <AutoSaveExJobText
                      value={positionDetails?.externalUrlName}
                      collection={`${collectionNames.jobDescriptions}/${jobId}/${subCollectionNames.positions}`}
                      recId={positionId}
                      required={true}
                      setDisablePositionToggle={setDisablePositionToggle}
                      name="externalUrlName"
                      urlActive ={"urlActive"}
                      display="Link"
                      placeholder={"Enter url"}
                      directions=""
                      readOnly={''}
                      disabled={isFieldLocked}
                    />
               
                </div>
              </div>

              {/* Application Flow End here */}

              <div className="mb-2">
                <GreenUnderlineHeading
                  title="Work Type"
                  showLockIcon={true}
                  isLocked={isFieldLocked}
                  text={SECTION_LOCKED_MESSAGE}
                />
                <AutoSaveWrapper
                  issue={getSectionIssue("information.classification", issues)}
                >
                  <AutoSaveRadioGroup
                    name="information.classification"
                    options={availableOptions(
                      job?.information?.classification,
                      classification,
                    )}
                    initialValue={positionDetails?.information?.classification}
                    collection={`${collectionNames.jobDescriptions}/${jobId}/${subCollectionNames.positions}`}
                    id={positionId}
                    disabled={isFieldLocked}
                  />
                </AutoSaveWrapper>
              </div>

              <div className="mb-2">
                <GreenUnderlineHeading
                  title="Shift Option"
                  showLockIcon={true}
                  isLocked={isFieldLocked}
                  text={SECTION_LOCKED_MESSAGE}
                />
                <AutoSaveWrapper
                  issue={getSectionIssue(
                    "shiftSchedule.shiftOption.code",
                    issues,
                  )}
                >
                  <AutoSaveShiftSchedule
                    optionsDays={daysOfWeek}
                    optionsShift={shiftOptions}
                    name="information.shiftSchedule"
                    collection={`${collectionNames.jobDescriptions}/${jobId}/${subCollectionNames.positions}`}
                    initialValues={positionDetails?.shiftSchedule}
                    recId={positionId}
                    disabled={isFieldLocked}
                  />
                </AutoSaveWrapper>
              </div>
              <div className="mb-2">
                <GreenUnderlineHeading
                  title="Work Settings"
                  showLockIcon={true}
                  isLocked={isFieldLocked}
                  text={SECTION_LOCKED_MESSAGE}
                />
                <AutoSaveWrapper
                  issue={getSectionIssue("information.model", issues)}
                >
                  <AutoSaveRadioGroup
                    name="information.model"
                    options={availableOptions(job?.information?.model, model)}
                    initialValue={positionDetails?.information?.model}
                    collection={`${collectionNames.jobDescriptions}/${jobId}/${subCollectionNames.positions}`}
                    id={positionId}
                    disabled={isFieldLocked}
                  />
                </AutoSaveWrapper>
              </div>
              <div className="mb-2">
                <AutoSaveWrapper
                  issue={getSectionIssue("information.locationId", issues)}
                >
                  <AutoSaveSelectGeneric
                    value={positionDetails?.information?.locationId ?? locations[0]?.id}
                    options={locations}
                    optionValue={"id"} //  key used to get the value from options object. By default it is "code"
                    collection={`${collectionNames.jobDescriptions}/${jobId}/${subCollectionNames.positions}`}
                    recId={positionId}
                    required={false}
                    name="information.locationId"
                    display="Location"
                    placeholder="Select a Location"
                    directions=""
                    disabled={isFieldLocked}
                  />
                </AutoSaveWrapper>
              </div>
              <div className="mb-2">
                <button
                  disabled={isFieldLocked}
                  onClick={toggleCreateLocation}
                  className="outline-none mr-1 mb-1 px-3 py-1 bg-transprent underline text-instant-teams-blue-Main focus:outline-none"
                >
                  + Add Location
                </button>
              </div>
              <div className="mb-2">
                <GreenUnderlineHeading
                  title="Work Term"
                  showLockIcon={true}
                  isLocked={isFieldLocked}
                  text={SECTION_LOCKED_MESSAGE}
                />
                <AutoSaveWrapper
                  issue={getSectionIssue("information.term", issues)}
                >
                  <AutoSaveRadioGroup
                    name="information.term"
                    options={availableOptions(job?.information?.term, term)}
                    initialValue={positionDetails?.information?.term}
                    collection={`${collectionNames.jobDescriptions}/${jobId}/${subCollectionNames.positions}`}
                    id={positionId}
                    disabled={isFieldLocked}
                  />
                </AutoSaveWrapper>
                <FadeInFromTop
                  show={positionDetails?.information?.term === "SEA"}
                >
                  <AutoSaveDateRange
                    disabled={
                      !(positionDetails?.information?.term === "SEA") ||
                      isFieldLocked
                    }
                    initialStart={
                      positionDetails?.information?.seasonalDateRange
                        ?.startDate instanceof Timestamp
                        ? positionDetails?.information?.seasonalDateRange?.startDate.toDate()
                        : null
                    }
                    initialEnd={
                      positionDetails?.information?.seasonalDateRange
                        ?.endDate instanceof Timestamp
                        ? positionDetails?.information?.seasonalDateRange?.endDate.toDate()
                        : null
                    }
                    collection={`${collectionNames.jobDescriptions}/${jobId}/${subCollectionNames.positions}`}
                    name="information.seasonalDateRange"
                    id={positionId}
                    includeDateIntervals={[includeDateInterval]}
                  />
                </FadeInFromTop>
              </div>
            </div>
          </div>
          <div className="md:w-full w-96">
            {/* Column 2 */}
            <div className="mb-2">
              <GreenUnderlineHeading title="Number of Hires" />
              <AutoSaveWrapper
                issue={getSectionIssue("information.hires", issues)}
              >
                <AutoSaveNumber
                  value={
                    !isNil(positionDetails?.information?.hires)
                      ? String(positionDetails?.information?.hires)
                      : ""
                  }
                  collection={`${collectionNames.jobDescriptions}/${jobId}/${subCollectionNames.positions}`}
                  recId={positionId}
                  required={false}
                  name={"information.hires"}
                  display={"Number of Hires"}
                  placeholder={"Enter number of hires"}
                  directions={""}
                  readOnly={false}
                  minValue={1}
                  maxValue={256}
                />
              </AutoSaveWrapper>
            </div>
            <div className="mb-4">
              <GreenUnderlineHeading
                title="Start Date"
                showLockIcon={true}
                isLocked={isFieldLocked}
                text={SECTION_LOCKED_MESSAGE}
              />
              <AutoSaveDate
                initialStart={
                  positionDetails?.information?.startDate instanceof Timestamp
                    ? positionDetails?.information?.startDate.toDate()
                    : null
                }
                name="information.startDate"
                collection={`${collectionNames.jobDescriptions}/${jobId}/${subCollectionNames.positions}`}
                id={positionId}
                disabled={isFieldLocked}
              />
            </div>

            <div className="mb-4">
              <div className="mb-4">
                <GreenUnderlineHeading
                  title="Pay Rate"
                  showLockIcon={true}
                  isLocked={isFieldLocked}
                  text={SECTION_LOCKED_MESSAGE}
                />
                <AutoSaveWrapper
                  issue={getSectionIssue("information.payType", issues)}
                >
                  <AutoSavePayTypeRadio
                    options={availableOptions(
                      Object.keys(job?.information?.payType ?? {}),
                      payType,
                    )}
                    collection={positionsSubCollectionPath}
                    recId={positionId}
                    jobPayType={job?.information?.payType} // to set slider range limits
                    payTypeInitialValue={
                      positionDetails?.information?.payType ?? {}
                    }
                    name="information"
                    disabled={isFieldLocked}
                  />
                </AutoSaveWrapper>
              </div>
            </div>
            <div className="mb-4 w-64">
              <Link to={`/preview/${jobId}/${positionId}`} target="_blank">
                <Tooltip
                  position="-top-12 left-1/2"
                  text="See what the seeker will see."
                >
                  <button className="uppercase text-sm py-2 w-full bg-instant-teams-blue-Main text-white rounded-lg hover:bg-instant-teams-blue-L1">
                    Preview Mobile/Web
                  </button>
                </Tooltip>
              </Link>
            </div>
          </div>
        </>
      )}
      {/* Location Creator */}
      {isShowingCreateLocation && (
        <div>
          <DialogWrapper
            title="Create New Location"
            onClose={() => {
              toggleCreateLocation();
            }}
          >
            <CreateEmployerLocation
              closeModal={() => {
                toggleCreateLocation();
              }}
            />
          </DialogWrapper>
        </div>
      )}
    </div>
  );
}

export default JobPositionDetails;
