
import {
  getFirestore,

  collection,
  getDocs,
} from "firebase/firestore";

import { firebaseApp } from "../providers/firebase";
import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";

export const db = getFirestore(firebaseApp);


export const getCannedSkills = async () => {
  try {
    const cannedSkillsCollection = collection(db, collectionNames.cannedSkills);
    const querySnapshot = await getDocs(cannedSkillsCollection);
    
    if (!querySnapshot.empty) {
      const skills = querySnapshot.docs.map(doc => doc.data());
      return skills;
    } else {
      console.log('No matching documents.');
      return [];
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const getTACTagsSkills = async () => {
  try {
    const tacTagsCollection = collection(db, collectionNames.tacTags);
    const querySnapshot = await getDocs(tacTagsCollection);
    
    if (!querySnapshot.empty) {
      const skills = querySnapshot.docs.map(doc => doc.data());
      return skills;
    } else {
      console.log('No matching documents.');
      return [];
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};
