import { useEffect, useState } from "react";
import { useUser } from "../../data/user";
import validator from 'validator'
import GreenUnderlineHeading from '../custom-ui/headings/heading';
import SectionHeading from '../custom-ui/headings/SectionHeading';
import MessageImageDropzone from './messageImageDropzone';

const AddAnImage = (
  {
    dataReturn,
    toggle, 
    userId,
    chatId
  }:{
    dataReturn: Function
    toggle: Function
    userId: String
    chatId: String
  }
) => {
  const [error, setError] = useState(true)

  return (
    <div className='w-full'>
      <div className='w-full'>
          <MessageImageDropzone setImage={dataReturn} toggle={toggle} userId={userId} chatId={chatId}/>
      </div>
    </div>
  );
};

export default AddAnImage;
