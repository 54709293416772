import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SkillsCatalog from "../parts/SkillsCatalog";
import {
  useJobDescriptionSkills,
  useJobDescription,
} from "../../../data/jobDescriptions";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-hooks-web";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import SkillsList from "../parts/SkillsList";
import { isLocked } from "../../../util/jobs/jobUtils";

function JobSkills() {
  const params = useParams();
  const jobId = params.id ?? "";
  const { data: job } = useJobDescription(jobId);
  const isFieldLocked = isLocked(job.status);
  const { data: skills } = useJobDescriptionSkills(jobId);

  const [excludedIds, setExcludedIds] = useState([]);

  useEffect(() => {
    if (skills && skills?.length > 0) {
      const objectIds = skills?.map((skill: any) => skill.assessmentId);
      setExcludedIds(objectIds);
    }
  }, [skills]);

  const searchClient = (() => {
    if (!process.env.REACT_APP_ALGOLIA_APP_ID) {
      throw new Error(
        "REACT_APP_ALGOLIA_APP_ID environment variable not defined",
      );
    }
    if (!process.env.REACT_APP_ALGOLIA_API_KEY) {
      throw new Error(
        "REACT_APP_ALGOLIA_API_KEY environment variable not defined",
      );
    }

    return algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID,
      process.env.REACT_APP_ALGOLIA_API_KEY,
    );
  })();

  return (
    <div className="w-full min-h-screen">
      <SkillsList skills={skills} isLocked={isFieldLocked} />
      {!isFieldLocked && (
        <>
          <div className="mt-12">
            <GreenUnderlineHeading title="Skills Catalog" />
          </div>
          <InstantSearch
            indexName="AcademyAssessments"
            searchClient={searchClient}
          >
            <SkillsCatalog excludedIds={excludedIds} />
          </InstantSearch>
        </>
      )}
    </div>
  );
}

export default JobSkills;
