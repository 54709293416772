import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  updateEmployer,
  useEmployer,
  useEmployerBilling,
  useEmployerPrimary,
} from "../../../data/employer";
import AddressForm from "../../address/AddressForm";
import DisplayUser from "../../custom-ui/input-fields/displayUser";
import AutoSaveText from "../../custom-ui/input-fields/auto-save-field";
import AutoSaveSelectByRole from "../../custom-ui/input-fields/auto-save-select-by-role";
import EmployerUserCardSmall from "../../employer/details/parts/employerUserCardSmall";
import toast from "react-hot-toast";
import ToastAlert from "../../custom-ui/toast-alert/ToastAlert";
import { useAuth } from "../../../providers/auth";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";

const CompanyOverview = () => {
  const auth = useAuth();

  const {
    data: employer,
    status: employerStatus,
    error: employerError,
  } = useEmployer(auth.userClaims.claims.employerId);

  const [locationParts, setLocationParts] = useState([]);

  const {
    data: primaryData = {},
    status: primaryStatus = "",
    error: primaryError,
  } = useEmployerPrimary(auth.userClaims.claims.employerId);
  const { data: billingData = {} } = useEmployerBilling(
    auth.userClaims.claims.employerId,
  );

  const [mapSource, setMapSource] = useState(
    "https://maps.google.com/maps?q=35.856737, 10.606619&z=15&output=embed",
  );

  useEffect(() => {
    setLocationParts(employer?.intel?.location.split(", "));
    if (employer?.address) {
      setMapSource(
        "https://maps.google.com/maps?q=" +
          employer.address.latitude +
          ", " +
          employer.address.longitude +
          "&z=15&output=embed",
      );
    }
  }, [employer]);

  return (
    <>
      {employer && (
        <div className="flex-auto w-full pl-2 pt-1">
          <div className="flex space-x-10 w-full text-lg font-bold pr-5">
            <div className="flex-auto w-1/3">
              <div className="grid grid-rows-3 gap-2 w-full mb-4">
                <AutoSaveText
                  value={employer?.displayName}
                  collection="Employer"
                  recId={employer?.id}
                  required={true}
                  name="displayName"
                  display="DBA Name"
                  placeholder={"DBA Name"}
                  directions=""
                  readOnly={false}
                />
                <AutoSaveText
                  value={employer?.legalName}
                  collection="Employer"
                  recId={employer?.id}
                  required={true}
                  name="legalName"
                  display="Legal Name"
                  placeholder={"Legal Name"}
                  directions=""
                  readOnly={false}
                />
                <AutoSaveText
                  value={employer?.domain}
                  collection="Employer"
                  recId={employer?.id}
                  required={true}
                  name="domain"
                  display="Domain Name"
                  placeholder={"Primary or Email Domain"}
                  directions="Value after the @ in email"
                  readOnly={true}
                />
              </div>

              <div className="flex-auto w-full mt-5">
                <GreenUnderlineHeading title="Ein/Tin Check" />
                <AutoSaveText
                  value={employer?.taxId}
                  collection="Employer"
                  recId={employer?.id}
                  required={false}
                  name="taxId"
                  display="Tax ID"
                  placeholder={"EIN/TIN of the Employer"}
                  directions=""
                  readOnly={false}
                />
                {employer?.EIN?.isValid ? (
                  <div className="pr-2 pl-2">
                    <p className="text-xs">
                      Formatted: {employer?.EIN?.display}
                    </p>
                    <p className="text-xs text-green-500">
                      Valid {employer?.EIN?.format} from the{" "}
                      {employer?.EIN?.country}
                    </p>
                  </div>
                ) : (
                  <p className="text-xs text-red-500">
                    {employer?.EIN?.type
                      ? "Invalid because " +
                        employer?.EIN?.type +
                        " & No Entity Found"
                      : "Missing Entry"}
                  </p>
                )}
                {employer?.EIN?.EIN?.CONFORMED_NAME ? (
                  <div className="pr-2 pl-2">
                    <p className="text-xs">
                      Identifier: {employer?.EIN?.EIN?.CONFORMED_NAME}
                    </p>
                    <p className="text-xs">
                      Location: {employer?.EIN?.EIN?.MAIL_ADDRESS_CITY},{" "}
                      {employer?.EIN?.EIN?.MAIL_ADDRESS_STATE}
                    </p>
                    <p className="text-xs">
                      Phone: {employer?.EIN?.EIN?.BUSINESS_ADDRESS_PHONE}
                    </p>
                  </div>
                ) : (
                  <div className="pr-2 pl-2">
                    <p className="text-xs">Extended Data Unavailable</p>
                  </div>
                )}
              </div>
            </div>
            <div className="flex-auto w-1/3">
              <form className="grid grid-rows-3 gap-2 w-full">
                <DisplayUser
                  value={employer.createdBy}
                  collection="Employer"
                  recId={employer.id}
                  required={false}
                  name="createdBy"
                  display="Creator"
                  placeholder={"Employer Created By"}
                  directions=""
                  readOnly={true}
                />
                <AutoSaveSelectByRole
                  value={employer.AM}
                  role="sales"
                  collection="Employer"
                  recId={employer.id}
                  required={false}
                  name="AM"
                  display="Account Manager"
                  placeholder={"Account Manager"}
                  directions=""
                  readOnly={true}
                />
                <AutoSaveSelectByRole
                  value={employer.TAC}
                  role="tac"
                  collection="Employer"
                  recId={employer.id}
                  required={false}
                  name="TAC"
                  display="Talent Acquisition Coordinator"
                  placeholder="Talent Acquisition Coordinator"
                  directions=""
                  readOnly={true}
                />
              </form>
              <div className="flex-auto w-full">
                <GreenUnderlineHeading title="Account Owners" />
                {primaryData &&
                  Object.keys(primaryData).length > 0 &&
                  Object.keys(primaryData).map((id) => (
                    <EmployerUserCardSmall id={primaryData[id].id} />
                  ))}
              </div>
              <div className="flex-auto w-full">
                <GreenUnderlineHeading title="Billing" />
                {billingData &&
                  Object.keys(billingData).length > 0 &&
                  Object.keys(billingData).map((id) => (
                    <EmployerUserCardSmall id={billingData[id].id} />
                  ))}
              </div>
            </div>
            <div className="flex-auto w-1/3">
              {employer?.address?.latitude && (
                <div className="mx-auto center-items mb-4 w-full">
                  <iframe
                    className="w-full"
                    title="mapSource"
                    src={mapSource}
                    width="360"
                    height="270"
                    style={{ border: 0 }}
                  />
                </div>
              )}
              <AddressForm
                id={employer?.id}
                address={employer?.address}
                addressLabel="primary"
                onSubmit={updateEmployer}
                onDone={() =>
                  toast.success(
                    `${employer?.intel?.name} address has been updated!`,
                  )
                }
              />
            </div>
          </div>
        </div>
      )}
      <ToastAlert />
    </>
  );
};

export default CompanyOverview;
