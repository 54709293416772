import React, { useEffect, useMemo, useState } from "react";
import { PhoneAuthProvider, PhoneMultiFactorGenerator } from "firebase/auth";
import AuthCode from "react-auth-code-input";
import ButtonWithLoading from "../../custom-ui/button/ButtonWithLoading";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../providers/auth";
import { friendlyFirebaseError } from "../../../providers/firebase-errors/friendlyFirebaseError";
import { errorLogger } from "../../../providers/clientLogger";
import FormAlert from "../../custom-ui/form-alert/formAlert";
import EllipseAnimated from "../../custom-ui/animations/EllipseAnimated";

function MultiFactorSignIn(props) {
  const auth = useAuth();

  const { makePhoneAuthProvider, makeRecaptcha } = auth;

  const phoneAuthProvider = makePhoneAuthProvider();

  const [pending, setPending] = useState(false);
  const { handleSubmit, register, setValue, errors } = useForm();

  const [recaptchaVerifier, setRecaptchaVerifier] = useState();
  const [verificationId, setVerificationId] = useState();

  const [verificationCode, setVerificationCode] = useState();

  const [mfaFormAlert, setMfaFormAlert] = useState(null);

  const recaptchaConfig = useMemo(
    () => ({
      size: "invisible",
      callback: function (response) {},
    }),
    [],
  );

  useEffect(() => {
    setRecaptchaVerifier(makeRecaptcha("multifactor-signIn", recaptchaConfig));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recaptchaConfig]);

  useEffect(() => {
    if (props.mfaResolver && !verificationId && recaptchaVerifier) {
      getVerificationCode();
    }
  }, [props.mfaResolver, verificationId, recaptchaVerifier]);

  function getVerificationCode() {
    setPending(true);

    const phoneInfoOptions = {
      multiFactorHint: props.mfaResolver.hints[0],
      session: props.mfaResolver.session,
    };

    const maskedPhoneNumber = phoneInfoOptions.multiFactorHint.phoneNumber;

    phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
      .then((verificationId) => {
        setVerificationId(verificationId);
        setMfaFormAlert({
          type: "info",
          message: `A confirmation code has been sent to ${maskedPhoneNumber} 
          Please enter the code below.`,
        });
      })
      .catch((error) => {
        // show error in toast message and within outer component
        // because dialog will not have shown at this point
        props.onFormAlert({
          type: "error",
          message: friendlyFirebaseError(error),
        });

        errorLogger(error.message, {
          component: "MultifactorSignIn getVerificationCode()",
          flow: "MultiFactor SignIn Flow",
          userId: "N/A",
        });
      })
      .finally(() => {
        setPending(false);
      });
  }

  function sendVerificationCode({ verificationCode }) {
    setPending(true);
    const credential = PhoneAuthProvider.credential(
      verificationId,
      verificationCode,
    );
    const multiFactorAssertion =
      PhoneMultiFactorGenerator.assertion(credential);

    props.mfaResolver
      .resolveSignIn(multiFactorAssertion)
      .then((user) => {
        props.onAuth(user);
      })
      .catch((error) => {
        // message to display in mfa dialog
        setMfaFormAlert({
          type: "error",
          message: friendlyFirebaseError(error),
        });
        errorLogger(error.message, {
          component: "MultifactorSignIn sendVerificationCode()",
          flow: "MultiFactor SignIn Flow",
          userId: "N/A",
        });
      })
      .finally(() => {
        setPending(false);
      });
  }

  return (
    <>
      {/* Enter Verification Code  */}
      {pending && (
        <div className="flex items-center">
          <div className="text-instant-teams-blue-Main">
            <strong>Sending Verification Code...</strong>
          </div>
          <div className="mb-8">
            <EllipseAnimated />
          </div>
        </div>
      )}
      {verificationId && (
        <>
          <div className="my-4">
            <FormAlert alert={mfaFormAlert} />
          </div>
          <form onSubmit={handleSubmit(sendVerificationCode)}>
            <div className="h-50 my-10">
              <input
                className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                name="verificationCode"
                type="hidden"
                inputMode="numeric"
                autoComplete="one-time-code"
                ref={register({
                  required: "Please enter your verification code",
                })}
              />
              <AuthCode
                containerClassName="flex flex-row"
                inputClassName="shadow-none m-1 w-14 border-solid border-r-0 border-l-0 border-t-0 border-b-4 border-gray-300"
                alphanumeric="numeric"
                ariaLabel="mobile confirmation code"
                // sets code to hidden field "verificationCode"
                onChange={(code) => setValue("verificationCode", code)}
              />
            </div>

            <ButtonWithLoading pending={pending} value="Confirm Code" />
          </form>
          {/* Resend Confirmation Code */}
          <button
            onClick={getVerificationCode}
            className="inline-block align-baseline outline-none mr-1 mb-1 px-6 py-2 bg-transparent text-sm font-bold text-instant-teams-blue-Main hover:text-blue-600 uppercase focus:outline-none"
            type="button"
            disabled={pending}
          >
            Resend Code
          </button>
        </>
      )}
      <div className="mt-12">
        {/* DO NOT REMOVE div. id "multifactor-signIn" is a param required initialize the required reCAPTCHA */}
        <div id="multifactor-signIn"></div>
      </div>
    </>
  );
}

export default MultiFactorSignIn;
