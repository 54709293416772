import { format } from "date-fns";
import { parseISO } from "date-fns/esm";

export function DisplayDate(timestamp) {
  let formattedDate = "";

  if (timestamp !== null && timestamp !== undefined) {
    const date = timestamp.toDate();
    formattedDate = date.toLocaleDateString();
  }
  return formattedDate;
}

export function DisplayTime(timestamp) {
  let formattedDate = "";
  if (timestamp !== null && timestamp !== undefined) {
    const date = timestamp.toDate();
    formattedDate = date.toLocaleTimeString();
  }
  return formattedDate;
}

export function DisplayTimestamp(timestamp) {
  let formattedDate = "";
  if (timestamp !== null && timestamp !== undefined) {
    const date = timestamp.toDate();
    formattedDate =
      date.toLocaleDateString() + " @ " + date.toLocaleTimeString();
  }
  return formattedDate;
}

export function DiffTimestampNow(timestamp) {
  const now = Date.now();
  const date = timestamp.toDate();
  return getDateDiffInDays(date, now);
}

export function getDateDiffInDays(date1, date2) {
  const diffInMilliseconds = date2 - date1;
  const diffInDays = diffInMilliseconds / 86400000;

  return diffInDays;
}

export function DisplayDateOther(timestamp) {
  const date = new Date(timestamp);
  return date.toLocaleDateString();
}

export function DisplayISO(timestamp) {
  if (timestamp === undefined) {
    return "N/A";
  }
  const date = new Date(timestamp);
  const newDate = format(date, "MM/dd/yyyy");
  return newDate;
}
