import { useEffect, useState } from "react";
import { useJobDescription } from "../../../../data/jobDescriptions";
import { Offer } from "../../../../dataTypes/Offer";
import { DisplayTimestamp } from "../../../../util/timestamps/timestamp";
import { useFindOfferStatus } from "../../../../data/status";
import { useJobPositionDetails } from "../../../../data/positions";
import { useEmployer } from "../../../../data/employer";
import SeekerPositionList from "./seekerPositionList";
import { Link } from "react-router-dom";

function SeekerOfferListItem({ entry }: { entry: Offer }) {
  const [employer, setEmployer] = useState("");
  const { data: JobDataRecords } = useJobDescription(entry.jobId);
  const { data: statusData } = useFindOfferStatus(entry.status);
  const { data: positionsData } = useJobPositionDetails(
    entry.jobId,
    entry.positionId,
  );
  const { data: employerData } = useEmployer(employer);

  useEffect(() => {
    if (JobDataRecords && JobDataRecords.hasOwnProperty("employer")) {
      setEmployer(JobDataRecords?.employer);
    }
  }, [JobDataRecords]);

  return (
    <Link to={`/applications/${entry?.applicationId}/details`}>
      <div className="text-black font-normal p-2 mb-2 rounded-xl bg-white flex shadow-lg">
        <div className="w-[15%] flex items-center pl-4">
          <img
            src={employerData?.logo}
            alt={employerData?.displayName}
            className="h-12"
          />
        </div>
        <div className="w-[85%] pr-4">
          <div className="w-full text-base text-instant-teams-teal-Main">
            {JobDataRecords?.title}
            <span className="float-right">{statusData?.label}</span>
          </div>
          <div className="w-full text-base text-instant-teams-teal-Main">
            <SeekerPositionList
              applicationId={entry?.applicationId}
              jobId={entry?.jobId}
            />
          </div>
          <div className="w-full mt-2 mb-2 text-xs">
            Date: {DisplayTimestamp(entry?.createdAt)}
          </div>
        </div>
      </div>
    </Link>
  );
}

export default SeekerOfferListItem;
