import { useState, useEffect } from "react";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import EditCustomJob from "./EditCustomJob";
import {
  useJobInterviewQuestions,
  useJobDescription,
} from "../../../data/jobDescriptions";
import SortableListJob from "./sortable-list-job";
import {
  collectionNames,
  subCollectionNames,
} from "../../../data/dictionary/collectionNames";
import { isLocked } from "../../../util/jobs/jobUtils";

function EInterviewJobCustom({ jobId }: { jobId: string }) {
  const [trigger, setTrigger] = useState(true);
  const [questionId, setQuestionId] = useState(null);
  const [question, setQuestion] = useState(null);
  const { data: questions } = useJobInterviewQuestions(jobId);
  const { data: job } = useJobDescription(jobId);
  const isFieldLocked = isLocked(job.status);
  const SECTION_LOCKED_MESSAGE =
    "This section cannot be changed while the job status is live.";
  return (
    <div className="w-full">
      <div className="w-full">
        <GreenUnderlineHeading
          title="e-interview Custom Questions"
          showLockIcon={true}
          isLocked={isFieldLocked}
          text={SECTION_LOCKED_MESSAGE}
        />
      </div>
      <div className="grid grid-cols-3 gap-2">
        <div className="col-span-1">
          <GreenUnderlineHeading title={"Questions"} />
          <SortableListJob
            list={questions}
            updateField={"sortOrder"}
            displayField={"displayName"}
            collection={collectionNames.jobDescriptions}
            parentId={jobId}
            subcollection={subCollectionNames.interviewQuestions}
            callback={setQuestionId}
            returnItem={setQuestion}
            isLocked={isFieldLocked}
          />
        </div>
        <div className="col-span-2 ml-10">
          {questionId !== null && (
            <EditCustomJob
              key={questionId}
              type={"job"}
              typeId={jobId}
              id={questionId}
              readOnly={isFieldLocked}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default EInterviewJobCustom;
