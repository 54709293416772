import MeetingItem from "./MeetingItem";
import { useAuth } from "../../providers/auth";
import {
  useMeetingsOneOnOne,
  useMeetingsOneOnOneInactive,
} from "../../data/meetings";

function MeetingList({ meetings }: { meetings: any }) {
  const { user } = useAuth();
  const { id: userId } = user ?? {};
  const { role } = user?.claims || {};

  const isMyMeeting = (meeting: any, userId: string) => {
    const { invitedBy } = meeting ?? {};
    return !!(userId === invitedBy);
  };

  const isAdmin = () => {
    return !!(role === "sadmin" || role === "admin");
  };

  const canJoinMeeting = ({
    meeting,
    userId,
  }: {
    meeting: any;
    userId: string;
  }) => isMyMeeting(meeting, userId) || isAdmin();

  return (
    <>
      {meetings && meetings.length > 0 ? (
        <>
          {meetings.map((meeting: any) => {
            const { meetingDetails } = meeting ?? {};

            return (
              <div key={meeting.id} className="m-4 flex flex-wrap">
                <MeetingItem
                  canJoinMeeting={canJoinMeeting({ meeting, userId })}
                  meeting={{ ...meeting, ...meetingDetails }}
                />
              </div>
            );
          })}
        </>
      ) : (
        <div className="my-4 mx-auto">No Meetings Scheduled.</div>
      )}
    </>
  );
}

export default MeetingList;
