import { useEffect } from "react";
import { useState } from "react";
import { roles } from "../data/dictionary/role";

// Define a custom hook that returns a tuple containing the current useLabel state,
// a function that takes an ID and returns the label or ID depending on the value of useLabel,
// and a function to toggle the value of useLabel
export const useRole = (): ((id: string, returnLabel?: boolean) => string) => {
  // Define the getRoleIdOrLabel function
  const getRoleIdOrLabel = (id: string, returnLabel?: boolean): string => {
    if (!id) {
      return "";
    }
    // Retrieve the Option object from the roles map based on the id parameter
    const option = roles.get(id);
    // If the Option object exists, return either its id or label value based on the returnLabel parameter
    if (option) {
      return returnLabel ? option.label : option.id;
    }
    // If the Option object doesn't exist, return an empty string
    return "";
  };

  // Return the getRoleIdOrLabel function
  return getRoleIdOrLabel;
};

export const useEntireRoleObject = (id: string) => {
  const getEntireRole = (id: string) => {
    if (!id) {
      return "";
    }

    const option = roles.get(id);
    if (option) {
      return option;
    }
    return "";
  };
  return getEntireRole;
};

/* Usage example
  // Call the useRole hook to get the useLabel state and getRoleIdOrLabel function
  const getRole = useRole();
  const role = getRole(userClaims?.claims?.role)

  const getLabel = useRole();
  const roleLabel = getLabel(userClaims?.claims?.role, true)
  
  */

// Note: The useRole hook can be called multiple times within the same component, and each call will create a new instance of the useLabel state and the getRoleIdOrLabel function. This allows you to use the hook multiple times with different parameters or configurations within the same component.
