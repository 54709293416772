import { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { wordpressClient } from "../../external/wordpress/wordpressClient";
import { sanitizeMarkup } from "../../util/sanitize/sanitize-html";
import "../../styles/wordpress.css";

const GeneralInfoCategoryPost = ({ id }) => {
  const [nodeData, setNodeData] = useState();
  const [showContent, setShowContent] = useState(false);

  const node = gql`
    query getPostContent($id: ID!) {
      post(id: $id, idType: ID) {
        id
        content(format: RENDERED)
        title
      }
    }
  `;

  const { loading, error, data } = useQuery(node, {
    variables: { id },
    client: wordpressClient,
  });

  useEffect(() => {
    setNodeData(data?.post);
    console.log(data?.post);
  }, [data]);

  const { content, title } = nodeData || {};

  return (
    <div
      key={data?.post?.id}
      className={`flex flex-col justify-center cursor-pointer  border-t-2 border-gray-500 text-lg ${
        showContent ? "h-auto py-5" : "h-20"
      }`}
      onClick={() => setShowContent(!showContent)}
    >
      <div className="flex justify-between items-center">
        <span className="font-bold text-lg">{title}</span>
        <span className=" text-2xl text-instant-teams-teal-Main">+</span>
      </div>
      {showContent && (
        <div
          className="wordpress"
          dangerouslySetInnerHTML={sanitizeMarkup(content)}
        ></div>
      )}
    </div>
  );
};

export default GeneralInfoCategoryPost;
