import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/pro-duotone-svg-icons";
import Tooltip from "../tooltip/tooltip";
function AlgoliaRefreshButton({}) {
  return (
    <Tooltip text="Refresh" position="-top-10 left-1/3">
      <FontAwesomeIcon
        icon={faRefresh}
        // size="2x"
        className=" mr-5 bg-instant-teams-teal-Main  cursor-pointer hover:bg-instant-teams-teal-L1 text-white rounded-full p-2"
        onClick={() => window.location.reload()}
      />
    </Tooltip>
  );
}

export default AlgoliaRefreshButton;
