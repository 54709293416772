import { useAuth } from "../../../../providers/auth";
import { useNavigate } from "react-router-dom";
import { useEmployer } from "../../../../data/employer";
import { useJobDescription } from "../../../../data/jobDescriptions";
import { useUser } from "../../../../providers/database";
import { TailwindHoverEnlarge } from "../../../../util/tailwind/tailwind-styles";
const PreOffersItem = ({ offer }: { offer: any }) => {
  const { userClaims } = useAuth();
  const navigate = useNavigate();
  const { data: employer } = useEmployer(offer?.employerId);
  const { data: jobDescription } = useJobDescription(offer?.jobId);
  const { data: user } = useUser(offer?.userId);

  return (
    <div
      onClick={() => {
        userClaims?.claims?.type === "A"
          ? navigate(`/applications/${offer?.applicationId}/details`)
          : navigate("/");
      }}
      className={
        TailwindHoverEnlarge(103) +
        " cursor-pointer bg-white rounded-lg h-28 shadow-lg"
      }
    >
      <div className="items-center w-full rounded-lg relative">
        <div className="items-center bg-instant-teams-teal-Main p-1 rounded-t-lg w-full">
          <div className="w-full text-white pl-2 text-center">
            <p className=" text-sm">{user?.name || "N/A"}</p>
          </div>
        </div>
        <div className="grid grid-cols-5 mt-2">
          <div className="grid col-span-2">
            <img
              className="object-scale-down max-h-16 h-16 object-center resize rounded-md mx-auto px-4"
              src={employer?.logo || "/images/default-employer.jpg"}
              alt={employer?.displayName}
            />
          </div>
          <div className="grid col-span-3 items-center justify-start">
            <p className="text-sm">
              <strong>{employer?.displayName}</strong>
              <br />
              {jobDescription?.title}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PreOffersItem;
