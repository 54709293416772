import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
import { requireAuth } from "../../../providers/auth";
import { getJobOffer } from "../../../data/offers";
import SeekerInfo from "../../seeker/details/parts/seekerInfo";
import Meta from "../../custom-ui/meta/Meta";

const ExternalJobOfferDetailsPage = () => {
  const params = useParams();
  const offerId = params?.offerId || "";
  const [offer, setOffer] = useState();

  useEffect(() => {
    async function fetchData() {
      let res = await getJobOffer(offerId);
      setOffer(res);
    }
    if (offerId) {
      fetchData();
    }
  }, [offerId]);

  return (
    <div>
      <Meta title="Job Offer Details" />
      {offer && (
        <div className="flex">
          <SeekerInfo imageVisible={true} userId={offer?.resumeId} />
        </div>
      )}
      <Outlet />
    </div>
  );
};

export default requireAuth(ExternalJobOfferDetailsPage);
