import { useState } from "react";
import AutoSaveWysiwyg from "../../components/custom-ui/input-fields/auto-save-wysiwyg";
import ButtonWithLoading from "../../components/custom-ui/button/ButtonWithLoading";
import DialogWrapper from "../../components/custom-ui/dialog/Dialog";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FormAlert from "../../components/custom-ui/form-alert/formAlert";
import GreenUnderlineHeading from "../../components/custom-ui/headings/heading";
import { createSetting, useAllSettings } from "../../data/settings";
import useModal from "../../hooks/useModal";
import { useForm } from "react-hook-form";
//@ts-ignore
import draftToHtml from "draftjs-to-html";
//@ts-ignore
import { Editor } from "react-draft-wysiwyg";
//@ts-ignore
import { convertToRaw, ContentState, EditorState } from "draft-js";
function MarketplaceSettings() {
  const { data: settingsList = [] } = useAllSettings();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const resetEditorState = () => setEditorState(EditorState.createEmpty());

  const [value, setValue] = useState("");
  const [formAlert, setFormAlert] = useState();
  const { isShowing, toggle } = useModal();
  const { handleSubmit, register, errors, reset } = useForm({
    defaultValues: {
      settingName: "",
    },
  });

  const onSubmit = (data: any) => {
    const { settingName } = data;
    const sansSpaces = settingName.replace(/\s/g, "");
    setFormAlert();
    createSetting({ id: sansSpaces, value })
      .then(() => {
        toggle();
      })
      .catch((error) => {
        setFormAlert({
          type: "error",
          message:
            "Looks like there was a problem saving this setting. Please give it a moment and try again.",
        });
      });
  };

  const onEditorStateChange = (newEditorState: any) => {
    const content = draftToHtml(
      convertToRaw(newEditorState.getCurrentContent()),
    );
    setEditorState(newEditorState);
    setValue(content);
  };

  return (
    <>
      <GreenUnderlineHeading title="Marketplace Settings" />
      <div className="w-full">
        <div className="grid grid-cols-1 gap-8">
          <div className="w-1/4">
            <ButtonWithLoading value="New Setting" onClick={toggle} />
          </div>
          <div className="overflow-auto">
            {settingsList.map((setting: { id: string; value: string }) => (
              <AutoSaveWysiwyg
                key={setting.id}
                value={setting.value}
                collection={"Settings"}
                recId={setting.id}
                required={false}
                rows={10}
                name={"value"}
                display={`${setting.id}`}
                placeholder={"Enter the setting value"}
                directions={""}
                readOnly={false}
                editorStyle={{ height: "240px" }}
              />
            ))}
          </div>
        </div>
      </div>
      {isShowing && (
        <DialogWrapper
          onClose={() => {
            toggle();
            reset();
          }}
          title="Add New Setting"
          size="w-1/3 mt-36"
        >
          <>
            {formAlert && (
              <div className="my-4">
                <FormAlert alert={formAlert} />
              </div>
            )}

            <div className="mb-1 text-sm text-white bg-instant-teams-gray-D1 rounded-lg p-4">
              <p className="font-bold underline">Setting Name</p>
              <ul>
                <li>Must be valid UTF-8 characters</li>
                <li>Must be no longer than 1,500 bytes</li>
                <li>Cannot contain a forward slash (/)</li>
                <li>
                  Cannot solely consist of a single period (.) or double periods
                  (..)
                </li>
                <li>Cannot match the regular expression __.*__</li>
              </ul>
            </div>
            <form onSubmit={() => console.log("submitting...")}>
              <div>
                <input
                  ref={register({
                    required: "Please enter a valid setting name.",
                    pattern: {
                      value: /^(?!\.\.?$)(?!.*__.*__)([^/]{1,1500})$/,
                      message: "Please enter a valid setting name.",
                    },
                  })}
                  type="text"
                  name="settingName"
                  placeholder="Enter setting name"
                  className="form-input shadow-md rounded-md w-full pt-3 pb-3"
                />
                {errors.settingName && (
                  <p className="mt-1 text-sm text-left text-red-600">
                    {errors.settingName.message}
                  </p>
                )}
              </div>

              <div className="">
                {/*  @ts-ignore */}
                <Editor
                  readOnly={false}
                  spellCheck={true}
                  editorStyle={{ height: "350px" }}
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  wrapperClassName="shadow-md rounded-md bg-white pt-3"
                  editorClassName={"pl-4 pr-4 h-36"}
                  toolbarClassName="border-solid border-b-2 border-sky-500 p-2 text-xs"
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "link",
                      "list",
                      "textAlign",
                    ],
                    inline: {
                      options: ["bold", "italic", "underline"],
                    },
                    list: {
                      options: ["unordered", "ordered"],
                    },
                    textAlign: {
                      options: ["left", "center", "right", "justify"],
                    },
                    link: {
                      className: undefined,
                    },
                  }}
                />
              </div>
              <div className="flex space-x-4">
                <button
                  className="py-2 px-4 mt-9 w-full text-xl uppercase  justify-center mx-auto text-white bg-instant-teams-gray-Main rounded-lg border-0 hover:bg-instant-teams-blue-D1 focus:outline-none "
                  type="button"
                  onClick={toggle}
                >
                  Cancel
                </button>
                <button
                  className="py-2 px-4 mt-9 w-full text-xl uppercase  justify-center mx-auto text-white bg-instant-teams-blue-Main rounded-lg border-0 hover:bg-instant-teams-blue-D1 focus:outline-none "
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  Confirm
                </button>
              </div>
            </form>
          </>
        </DialogWrapper>
      )}
    </>
  );
}

export default MarketplaceSettings;
