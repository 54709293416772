import { useState } from "react";
import { useAuth } from "../../providers/auth";
import { useUser } from "../../data/user";
import { cancelMeeting, useMeeting } from "../../data/meetings";
import useModal from "../../hooks/useModal";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import SuccessCircleCheck from "../custom-ui/success/SuccessCircleCheck";
import { trim } from "lodash";

function ManageMeetingActions({ meetingId }: { meetingId: string }) {
  const { data: meeting, status: meetingQueryStatus } = useMeeting(meetingId);

  const {
    refType,
    refId,
    title,
    status: meetingStatus,
    meetingCode,
    meetingDetails,
    inviteeId,
    extended, // calendly event
  } = meeting ?? {};

  const { calendly } = extended ?? {};

  // invitee
  const { data: inviteeData } = useUser(inviteeId);

  const { event } = calendly ?? {};

  const isCalendlyEvent = !!event;

  console.log(inviteeData);
  console.log(meeting);

  const { toggle: toggleDialog, isShowing: isShowingDialog } = useModal();
  const { toggle: toggleCancelDialog, isShowing: isShowingCancelDialog } =
    useModal();

  const {
    toggle: toggleRescheduleDialog,
    isShowing: isShowingReschedulelDialog,
  } = useModal();

  const [cancelOrReschedule, setCancelOrReschedule] = useState();
  const [cancelOrRescheduleReason, setCancelOrRescheduleReason] = useState();

  const cancelScheduledMeeting = () => {
    cancelMeeting({
      meetingId: meetingId,
      status: "cancelled",
      cancelReason: trim(cancelOrRescheduleReason, "\n"),
    })
      .then(() => {
        toggleCancelDialog();
        console.log("Cancelled meeting");
      })
      .catch((error) => {
        console.log("Problem cancelling meeting: ", error);
      });
  };

  switch (meetingStatus) {
    case "pending":
      return (
        <>
          <div className="mt-28 font-bold text-instant-teams-teal-D1">
            Sending Invitation...
          </div>
        </>
      );
    case "sent":
      return (
        // <div className="w-full">
        //   <div className="w-1/2">
        //     <SuccessCircleCheck message="Invitation Sent" />
        //   </div>
        // </div>
        <></>
      );
    case "scheduled":
      return (
        <>
          <button
            className="w-full text-left font-bold underline text-instant-teams-red-Main background-transparent "
            onClick={() => {
              setCancelOrReschedule("cancel");
              toggleCancelDialog();
            }}
          >
            <i className="fa-solid fa-xmark underline"></i> Cancel Interview
          </button>
          {isShowingCancelDialog && (
            <DialogWrapper
              title="Cancel Interview"
              onClose={() => {
                toggleCancelDialog();
                // clear reason
                setCancelOrRescheduleReason("");
              }}
            >
              <>
                {cancelOrReschedule === "cancel" && (
                  <div>
                    <p className="mb-4 text-instant-teams-teal-D2 font-bold text-xl">
                      Are you sure that you want to{" "}
                      <span className="text-instant-teams-red-Main">
                        cancel
                      </span>{" "}
                      your meeting with {inviteeData?.name}?
                    </p>
                    <p className="text-instant-teams-teal-D2 font-bold text-lg">
                      {meetingDetails?.title}
                    </p>
                    <div className="mt-10 flex  space-x-2">
                      <button
                        className="w-full mb-5 min-h-12 h-12 text-white bg-instant-teams-red-Main rounded border-0 hover:bg-instant-teams-red-L1 focus:outline-none"
                        onClick={cancelScheduledMeeting}
                      >
                        Yes Cancel
                      </button>
                      <button
                        className="w-full mb-5 min-h-12 h-12 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-D1 focus:outline-none"
                        onClick={toggleCancelDialog}
                      >
                        No
                      </button>
                    </div>
                    <div className="mt-4 ">
                      <label className="sr-only">
                        Reason for cancel/reschedule
                      </label>
                      <textarea
                        placeholder={`Add an optional message `}
                        className="w-full rounded-xl"
                        onChange={(e) =>
                          setCancelOrRescheduleReason(e.target.value)
                        }
                      >
                        {cancelOrRescheduleReason}
                      </textarea>
                    </div>
                  </div>
                )}
              </>
            </DialogWrapper>
          )}
        </>
      );

    case "reschedule": {
      return (
        <>
          {/* Dont show Reschedule Live Interview when status is "reschedule" 
          if meetinging is Calendly event. Reschedule and date selection happens within Calendly
          Invitee will reschedule and select dates all at once*/}

          {/* This button only shows if an invitee request a reschedule */}
          {!isCalendlyEvent && (
            <>
              <button
                className="w-full text-left font-bold underline text-instant-teams-red-Main background-transparent "
                onClick={() => {
                  setCancelOrReschedule("cancel");
                  toggleCancelDialog();
                }}
              >
                <i className="fa-solid fa-xmark underline"></i> Cancel Interview
              </button>
              {isShowingCancelDialog && (
                <DialogWrapper
                  title="Cancel a Meeting"
                  onClose={() => {
                    toggleCancelDialog();
                    // clear reason
                    setCancelOrRescheduleReason("");
                  }}
                >
                  <>
                    {cancelOrReschedule === "cancel" && (
                      <div>
                        <p className="mb-4 text-instant-teams-teal-D2 font-bold text-xl">
                          Are you sure that you want to{" "}
                          <span className="text-instant-teams-red-Main">
                            cancel
                          </span>{" "}
                          your meeting with {inviteeData?.name}?
                        </p>
                        <p className="text-instant-teams-teal-D2 font-bold text-lg">
                          {meetingDetails?.title}
                        </p>
                        <div className="mt-10 flex  space-x-2">
                          <button
                            className="w-full mb-5 min-h-12 h-12 text-white bg-instant-teams-red-Main rounded border-0 hover:bg-instant-teams-red-L1 focus:outline-none"
                            onClick={cancelScheduledMeeting}
                          >
                            Yes Cancel
                          </button>
                          <button
                            className="w-full mb-5 min-h-12 h-12 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-D1 focus:outline-none"
                            onClick={toggleCancelDialog}
                          >
                            No
                          </button>
                        </div>
                        <div className="mt-4 ">
                          <label className="sr-only">
                            Reason for cancel/reschedule
                          </label>
                          <textarea
                            placeholder={`Add an optional message `}
                            className="w-full rounded-xl"
                            onChange={(e) =>
                              setCancelOrRescheduleReason(e.target.value)
                            }
                          >
                            {cancelOrRescheduleReason}
                          </textarea>
                        </div>
                      </div>
                    )}
                  </>
                </DialogWrapper>
              )}
            </>
          )}
        </>
      );
    }
    // No meeting data was returned for this meeting context, no meeting exists, show schedule
    default:
      return <></>;
  }
}

export default ManageMeetingActions;
