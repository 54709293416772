import { DisplayTimestamp } from "../../util/timestamps/timestamp";
import { useUser } from "../../providers/database";
import { useEmployer } from "../../data/employer";

function ActivityLogItem({ entry }: { entry: any }) {
  const { data: activeUser } = useUser(entry.id);
  const { data: updater } = useUser(entry.who);
  const { data: employer } = useEmployer(entry.who);

  const checkWhoUpdated = (id: string) => {
    if (activeUser?.id === id) {
      return "User";
    } else if (updater?.id === id) {
      return updater?.preferredName;
    } else if (employer?.id === id) {
      return employer?.legalName;
    } else {
      return entry.who;
    }
  };

  const checkForEmptyField = (fieldToCheck: string) => {
    return fieldToCheck === "" || fieldToCheck === null
      ? "empty"
      : fieldToCheck;
  };
  const entryText = () => {
    if (entry.activityLabel === "Edited") {
      return (
        <div className="flex items-center">
          {entry.activityField.toUpperCase()} {entry.activityLabel} in{" "}
          {entry.collection} from {checkForEmptyField(entry.beforeField)} to{" "}
          {checkForEmptyField(entry.afterField)} by {checkWhoUpdated(entry.who)}
        </div>
      );
    } else if (entry.activityLabel === "Added") {
      return (
        <div className="flex items-center">
          {entry.activityField.toUpperCase()} {entry.activityLabel} in{" "}
          {entry.collection} to {checkForEmptyField(entry.afterField)} by{" "}
          {checkWhoUpdated(entry.who)}
        </div>
      );
    } else {
      return;
    }
  };
  return (
    <>
      {entry.activityLabel !== "Deleted" &&
      entry.activityField !== "searchTags" ? (
        <tr className="pl-5 border-b border-black h-24">
          <td className="w-1/4 p-2">{DisplayTimestamp(entry.created_at)}</td>
          <td className="w-1/2">{entryText()}</td>
        </tr>
      ) : null}
    </>
  );
}
export default ActivityLogItem;
