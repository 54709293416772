import { useState } from "react";
import toast from "react-hot-toast";
import GreenUnderlineHeading from '../custom-ui/headings/heading';
import { commCheckUser, commCreateUser, csvUsers } from "../../callable-cloud-functions/cloudFunctions";
import { uploadCommunityCSV } from "../../providers/firebaseStorage";
import ToastAlert from "../custom-ui/toast-alert/ToastAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";

const CommunityControlPanel = () => {
  const [unMatchedFormat, setUnmatchedFormat] = useState(true);
  const formRegex = /(?<=[\W\w\d]) (?=[\W\w\d])/;
  const [userErrors,setUserErrors] = useState([])

  const handleSubmitCSV = (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const file = formData.get("file");
      uploadCommunityCSV(file)
      .then((res) => {
        console.log(res)
        csvUsers({bucket: res.bucket, fullPath: res.fullPath})
        .then((res:any) => {
          console.log("CSV USERS: ", res)
          if (res?.data?.errorUsers?.length > 0) {
          setUserErrors([...res?.data?.errorUsers])
          }
        })
      })
    toast.success("Community created successfully");
  };
  
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const value = Object.fromEntries(data.entries());
    console.log(value);
    const userExists = await commCheckUser({ email: value.email })
    console.log("user exists: ", userExists)
    if (userExists.data) {
      toast.error('User already exists');
      return;
    } else {
      commCreateUser({ email: value.email, displayName: value.displayName })
    }
    toast.success("Community created successfully");
  }

  return (
    <div className="ml-4 w-full flex flex-row gap-8 mt-24">
      
      <form onSubmit={handleSubmit} className="w-1/2 flex flex-col gap-4">
      <GreenUnderlineHeading title="Create Community User"/>
        <input 
        type="email" 
        name="email" 
        placeholder="Email"
        required={true}
        className="w-full h-10 px-4 mb-4 text-sm text-gray-700 placeholder-gray-500 border rounded-lg focus:shadow-outline" />
        <input 
        type="text" 
        name="displayName" 
        placeholder="Full Name"
        required={true}
        onChange={e => {setUnmatchedFormat(formRegex.test(e.target.value))}} 
        className="w-full h-10 px-4 mb-4 text-sm text-gray-700 placeholder-gray-500 border rounded-lg focus:shadow-outline" />
        <button 
        className="py-2 px-4 w-1/2 text-xl uppercase justify-center mx-auto text-white bg-instant-teams-blue-Main rounded-lg border-0 hover:bg-instant-teams-blue-D1 focus:outline-none disabled:bg-gray-500"
        type="submit"
        disabled={!unMatchedFormat}
        >Create User</button>
      </form>
      <span className="border-2 border-instant-teams-teal-D2"></span>
      <form onSubmit={handleSubmitCSV}>
      <GreenUnderlineHeading title="Upload a list of community users" />
      <div>
      <div className="py-2 px-4 w-max justify-center text-white bg-instant-teams-blue-Main rounded-lg border-0 hover:bg-instant-teams-blue-D1 focus:outline-none">
      <a href="https://firebasestorage.googleapis.com/v0/b/stage-firebase-project.appspot.com/o/community%2Ftemplate.csv?alt=media&token=cc6be350-829f-413a-8dfa-8ab13833439b&_gl=1*zna795*_ga*MTYwODA3NjYwNC4xNjk1MjI5MzIw*_ga_CW55HF8NVT*MTY5NzczNDY3NS43MS4xLjE2OTc3MzQ2NzguNTcuMC4w" target="_blank" rel="noreferrer" ><div className="my-4">
      <FontAwesomeIcon icon={["fas", "download"]} size="2xl"/>
      <span className="ml-2">Download CSV Template</span>
      </div></a>
      </div>
        <div className="my-4">
          Please download the CSV template or use the following format for the csv file:
          <div className="flex flex-col gap-2 border-2 border-black">
            <div className="flex flex-row odd:border-2 p-2">
              <span className="flex-1">email</span>
              <span className="border-2 border-instant-teams-teal-D2"></span>
              <span className="flex-1 pl-2">displayName</span>
            </div>
            <div className="flex flex-row odd:border-2 p-2">
              <span className="flex-1">jondoe@email.com</span>
              <span className="border-2 border-instant-teams-teal-D2"></span>
              <span className="flex-1 pl-2">Jon Doe</span>
            </div>
          </div>
        </div>
      </div>
        <input type="file" accept=".csv" name="file" required className="w-full h-14 p-4 mb-4 text-sm text-gray-700 placeholder-gray-500 border rounded-lg focus:shadow-outline" />
        <button type="submit" className="py-2 px-4 w-full text-xl uppercase  justify-center mx-auto text-white bg-instant-teams-blue-Main rounded-lg border-0 hover:bg-instant-teams-blue-D1 focus:outline-none">Upload</button>
        <div className="mt-12">
        {userErrors &&
        <>
        {userErrors.map((error:any) => 
        <div key={error.email} className="text-red-500 flex flex-col border-b-2 border-b-instant-teams-teal-D2">
          <div>{`Email: ${error.email}- ${error.badEmail? "There is a problem with email":"No issues with email"}`}</div>
          <div>{`Name: ${error.fullName}- ${error.badName ? "Problem with name":"No errors in name"}`}</div>
          </div>)}
        </>
        }
      </div>
      </form>
      
      <ToastAlert />
    </div>
  );
};

export default CommunityControlPanel;
