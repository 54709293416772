import { useQuery } from "react-query";
import {
  doc,
  collection,
  query,
  setDoc,
  where,
  orderBy,
  getDoc,
  getDocs,
  updateDoc,
  runTransaction,
  deleteDoc,
} from "firebase/firestore";
import { db, createQuery } from "../providers/database";

import { Contact, Employer } from "../dataTypes/Employer";
import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";
import { Department, Locations } from "../dataTypes/Company";
import { deptDashboard } from "../callable-cloud-functions/cloudFunctions";

const getSettingsDocRef = (settingId: string) =>
  doc(db, collectionNames.settings, settingId);

export function useSetting(settingId: string) {
  return useQuery(
    ["marketplace-setting", { settingId }],
    createQuery(() => getSettingsDocRef(settingId)),
    { enabled: !!settingId },
  );
}

export function useAllSettings() {
  return useQuery(
    ["marketplace-settings"],
    createQuery(() => query(collection(db, collectionNames.settings))),
    { enabled: true },
  );
}

export function createSetting(setting: { id: string; value: string }) {
  return setDoc(doc(db, collectionNames.settings, setting.id), {
    value: setting.value,
  });
}
