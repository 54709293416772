export const getHrsPerWeek = ({
  range = [540, 1020],
  days = [],
}: {
  range: number[];
  days: any[];
}) => {
  if (Array.isArray(range) && Array.isArray(days)) {
    if (range.length === 2) {
      const start = range[0];
      const end = range[1];
      const noOfdays = days.length;
      return `${((end - start) / 60) * noOfdays} hrs`;
    }
  }
  return `- hrs`;
};