import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useListing } from "../../data/listings";
import Meta from "../../components/custom-ui/meta/Meta";
import GreenUnderlineHeading from "../../components/custom-ui/headings/heading";

import { useLookup } from "../../data/lookup";
import { LookUp } from "../../dataTypes/LookUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTimeZone, getTimeFromMinute } from "../../util/timezone/timezone";
import {
  useJobPositionDetails,
  useActiveJobPositions,
} from "../../data/positions";
import { useJobDescription } from "../../data/jobDescriptions";
import { sanitizeMarkup } from "../../util/sanitize/sanitize-html";
import "../../styles/dangerouslySet.css";
import { Link } from "react-router-dom";
import { requireAuth, useAuth } from "../../providers/auth";
import { useState } from "react";
import useModal from "../../hooks/useModal";
import DialogWrapper from "../../components/custom-ui/dialog/Dialog";
import { externalJobsStats } from "../../callable-cloud-functions/cloudFunctions";
//import DialogWrapper from "../../components/custom-ui/dialog";
import {
  applicationDocExists,
  checkIfInvitedAndGetId,
  createSeekerApplication,
  preScreenSubcollectionExists,
  updateStatus,
  useApplicationsByUser,
} from "../../data/applications";
import { useEmployer } from "../../data/employer";
import JobSkillListing from "./JobSkillListings";

import ButtonWithLoading from "../../components/custom-ui/button/ButtonWithLoading";
import { errorLogger } from "../../providers/clientLogger";

function SeekerListingDetails({ }) {
  const INSTANTTEAMS_ID = process.env.REACT_APP_INSTANT_TEAMS_ID || "";
  const { data: InstantTeamsData } = useEmployer(INSTANTTEAMS_ID);
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const { listingId = "" } = useParams();

  const { data: listingData } = useListing(listingId);
  const { data: positionData } = useJobPositionDetails(
    listingData?.jobId || location?.state?.jobIdfromApplication || "",
    listingData?.positionId || location?.state?.postionIdfromApplication || "",
  );
  const { data: applicationsData = [] } = useApplicationsByUser(user?.uid);
  const jobid = location?.state?.jobIdfromApplication || ""
  const alreadyAppliedToThisJob = applicationsData?.find(
    (a: any) => (a.jobId === listingData?.jobId) || (a.jobId === jobid)
  );
  const { isShowing, toggle } = useModal();


  const modifyBPOListing = (listing: any) => {
    return {
      ...listing,
      companyName: InstantTeamsData?.displayName,
      companyLogo: InstantTeamsData?.logo,
      employerId: INSTANTTEAMS_ID,
    };
  };
  const isBPOListing = (listingData: any) => {
    return listingData?.solutionType === "BPO";
  };

  const updateListingDataForBPO = (listingData: any) => {
    if (isBPOListing(listingData)) {
      return modifyBPOListing(listingData);
    }
    return listingData;
  };

  const updatedListingData = updateListingDataForBPO(listingData);
  const { data: jobData } = useJobDescription(updatedListingData?.jobId || location?.state?.jobIdfromApplication || "",);
  const { data: hardwares = [] } = useLookup("hardware");
  const { data: payType = [] } = useLookup("payType");

  const { data: positionsData } = useActiveJobPositions(
    updatedListingData?.jobId,
  );



  const [showApply, setShowApply] = useState(true);
  const [applying, setApplying] = useState(false);

  const jobHardwares = hardwares.filter((hw: any) =>
    jobData?.technology?.hardware?.includes(hw.code),
  );

  // render matched hardware from job
  const hardware = (hw: LookUp, id: number) => (
    <div key={id} className="flex content-center items-start mt-2 space-x-3">
      {/* @ts-ignore */}
      <FontAwesomeIcon icon={["fas", hw.icon]} size="2x" />
      <p>{hw.label}</p>
    </div>
  );

  const [shiftScheduleStart, shiftScheduleEnd] = positionData?.shiftSchedule
    ?.range ?? [undefined, undefined];

  // render matched schedule
  const day = (hours: LookUp) => (
    <div className="flex" key={hours?.id}>
      <p className="w-12">{hours?.code}</p>
      <div className="w-36">
        {`${getTimeFromMinute(shiftScheduleStart)} - ${getTimeFromMinute(
          shiftScheduleEnd,
        )}`}
      </div>
      <p>{getTimeZone(updatedListingData?.location?.timezone?.id)}</p>
    </div>
  );
  const navigateTo = (applicationId: string) => {
    if (positionsData && positionsData?.length > 1) {
      navigate(`/other-positions/${applicationId}`);
    } else {
      navigate(`/pre-screen-questions/${applicationId}`);
    }
  };
  const checkIfPreScreenQuestionsAreGenerated = (applicationId: string) => {
    const intervalId = setInterval(async () => {
      const applicationExists = await applicationDocExists(applicationId);
      const preScreenExists = await preScreenSubcollectionExists(applicationId);
      if (preScreenExists && applicationExists) {
        clearInterval(intervalId);
        setApplying(false);
        navigateTo(applicationId);
      } else if (!applicationExists) {
        clearInterval(intervalId);
        setApplying(false);
      }
    }, 1500);
  };

  const apply = async () => {
    setApplying(true);
    if (positionData?.urlActive && positionData?.externalUrlName) {
      await externalJobsStats({
        userId: user.uid,
        jobId: updatedListingData.jobId,
        positionId: updatedListingData.positionId,
        email: user.email,
        platform: "web"
      });
      window.open(positionData.externalUrlName, '_blank');
      setApplying(false);
    } else {
      try {
        const applicationId = await checkIfInvitedAndGetId(
          user.uid,
          updatedListingData?.jobId,
          updatedListingData?.positionId,
        );

        if (applicationId) {
          await updateStatus(applicationId, "applied");
          navigateTo(applicationId);
        } else {
          const { id } = await createSeekerApplication({
            jobId: updatedListingData?.jobId,
            positionId: updatedListingData?.positionId,
            resumeId: user?.uid,
          });
          checkIfPreScreenQuestionsAreGenerated(id);
        }
      } catch (err) {
        setApplying(false);
      }
    }

  };


  const applicationApply = (status: any, preScreened: any, itemId: any) => {

    if (status === 'applied') {
      if (
        (preScreened === undefined || preScreened === false) &&
        status === "applied"
      ) {
        navigate(`/pre-screen-questions/${itemId}`);
      } else if (
        preScreened &&
        (status === "applied" || status === "invited")
      ) {
        navigate(`/application/${itemId}`);
      }
    } else {
      toggle();
    }

  };

  const colorJobListingVariants: any = {
    applied: "bg-instant-teams-tags-ORANGE",
    decline: "bg-instant-teams-gray-D1",
    declined: "bg-instant-teams-gray-D1",
    knockout: "bg-instant-teams-purple-Main",
    preOffer: "bg-instant-teams-orange-Main",
    presented: "bg-instant-teams-tags-TEAL",
    ready: "bg-instant-teams-tags-BLUE",
    underReview: "bg-instant-teams-tags-GREEN",
    closed: "bg-instant-teams-tags-ROSE",
    invited: "bg-instant-teams-tags-GOLD",
  };


  const undoWithdrawal = async () => {

    const applicationId = location?.state?.applicationId

    if (!user) return;


    try {
      await updateStatus(applicationId, "applied");
      navigate(`/applications`);
    } catch (error: any) {

      errorLogger(error.message, {
        component: "JoblistingDetailsPage Undo-withdrawApplication()",
        flow: "Withdraw Application/ Seeker Applications",
        userId: user.uid,
      });
    }
  }

  return (
    <>
      <Meta title="Jobs" />
      <section className="pt-40 pb-16">
        {(updatedListingData || jobData) && <div className="grid grid-flow-row mx-auto grid-cols-8 gap-y-3 w-3/4">
          <Link to={`/companies/${updatedListingData?.employerId || jobData?.employer}`}>
            <img
              className="my-auto col-span-1 w-24 h-24 object-contain  "

              src={updatedListingData?.companyLogo || jobData?.employerSummary?.logo}
              alt={updatedListingData?.companyName || jobData?.employerSummary?.displayName}
            />
          </Link>
          <div className="pl-5 col-span-4 my-auto  text-instant-teams-blue-Main justify-start md:text-sm">
            <Link to={`/companies/${updatedListingData?.employerId || jobData?.employer}`}>
              <div className="hover:underline">

                {updatedListingData?.companyName || jobData?.employerSummary?.displayName}

              </div>
            </Link>
            <div className="font-semibold uppercase">
              {updatedListingData?.title || jobData?.title}
            </div>
            <div>{updatedListingData?.subTitle || jobData?.subTitle}</div>
            <div className="font-semibold">
              {updatedListingData?.positionPayType || "Salaray: " +
                jobData?.information.
                  payType?.
                  SAL?.join("-")
              }
              {updatedListingData?.classification || " " + jobData?.information.
                classification?.join("-")} | {updatedListingData?.model || jobData?.information.

                  model?.join("-")}
            </div>
          </div>
          <div className="col-span-3 space-y-5">

            {!alreadyAppliedToThisJob ||
              alreadyAppliedToThisJob?.status === "invited" || !location?.state?.label ||
              applying ? (
              <ButtonWithLoading
                disabled={applying}
                onClick={apply}
                pending={applying}
                value={positionData?.urlActive && positionData?.externalUrlName ? "VIEW POSTING" : "Apply Now"}
              />
            ) : <div>
              <div onClick={() =>
                applicationApply(
                  alreadyAppliedToThisJob?.status,
                  location?.state?.preScreened,
                  location?.state?.itemId

                )
              }
                className={`py-2 w-5/6 text-white rounded-lg uppercase text-center ${colorJobListingVariants[alreadyAppliedToThisJob?.status] ||
                  "bg-instant-teams-purple-Main"
                  }`}
              >
                {location?.state?.label}
              </div>
              {location?.state?.label == "Withdrawn" && <div onClick={undoWithdrawal}
                className={`py-2 w-5/6 text-white mt-3 rounded-lg uppercase text-center bg-instant-teams-blue-Main  `}
              >
              Undo Withdrawal
              </div>}
            </div>}
            {/* Dismiss is hidden, no functionality atm */}
            <button className=" hidden py-3 w-full uppercase bg-white border border-instant-teams-blue-Main text-instant-teams-blue-Main hover:text-white hover:bg-instant-teams-blue-D1 rounded-lg md:py-2 md:px-8 md:text-sm lg:py-2 lg:px-16 xl:px-20">
              Dismiss
            </button>
          </div>
          {updatedListingData?.skills &&
            updatedListingData?.skills.length > 0 && !positionData?.urlActive && (
              <div className="col-span-8">
                <GreenUnderlineHeading title="required assessments" />
                <div className="flex space-x-5">
                  {updatedListingData?.skills?.map((skill: any) => {
                    return (
                      <JobSkillListing
                        skill={skill}
                        key={skill?.assessmentId}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          {jobData && jobHardwares?.length > 0 && (
            <div className="col-span-4 md:col-span-8 mr-2">
              <GreenUnderlineHeading title="Required Equipment" />
              <div className="text-instant-teams-blue-Main">
                {jobHardwares?.map(hardware)}
              </div>
            </div>
          )}
          {/* render standard and non-standard shift options */}
          {positionData?.shiftSchedule?.shiftOption?.code !== "CUSTOM" &&
            positionData?.shiftSchedule?.days &&
            positionData?.shiftSchedule?.days.length > 0 && (
              <div className="col-span-4 md:col-span-8 ml-2">
                <GreenUnderlineHeading title="Required Hours" />
                {positionData?.shiftSchedule?.days?.map(day)}
              </div>
            )}
          {/* render CUSTOM shift options */}

          {positionData?.shiftSchedule?.shiftOption?.code === "CUSTOM" && (
            <div className="col-span-4 md:col-span-8 ml-2">
              <GreenUnderlineHeading title="Required Hours" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  positionData?.shiftSchedule?.shiftOption?.description,
                )}
              ></div>
            </div>
          )}
          {jobData?.description?.dayInTheLife && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="Day in the life" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  jobData?.description?.dayInTheLife,
                )}
              ></div>
            </div>
          )}
          {jobData?.description?.mustHaves && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="must have" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  jobData?.description?.mustHaves,
                )}
              ></div>
            </div>
          )}
          {jobData?.description?.training && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="Training" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  jobData?.description?.training,
                )}
              ></div>
            </div>
          )}
          {jobData?.description?.benefits && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="Benefits" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  jobData?.description?.benefits,
                )}
              ></div>
            </div>
          )}
        </div>}
      </section>
      {isShowing && (
        <DialogWrapper
          title="Application Details"
          onClose={toggle}
          size="w-1/2"
        >
          <>
            <div className="w-full flex">
              <Link to={`/companies/${updatedListingData?.employer || jobData?.employer}`}>
                <img
                  className="my-auto w-24 h-24 object-contain  "
                  src={updatedListingData?.companyLogo || jobData?.employerSummary?.logo}
                  alt={updatedListingData?.companyName || jobData?.employerSummary?.displayName}

                />
              </Link>
              <div className="pl-5  my-auto  text-instant-teams-blue-Main justify-start md:text-sm">
                <Link to={`/companies/${updatedListingData?.employer || jobData?.employer}`}>
                  <div className="hover:underline">

                    {updatedListingData?.companyName || jobData?.employerSummary?.displayName}

                  </div>
                </Link>
                <div className="font-semibold uppercase">{updatedListingData?.title || jobData?.title}</div>
              {  <div>{updatedListingData?.positionPayType || updatedListingData?.positionPayType || "Salaray: " +
                    jobData?.information?.
                    payType?.
                    SAL?.join("-")||""
                }</div>}
                <div className="font-semibold">

                  {updatedListingData?.classification || " " + jobData?.information.
                    classification?.join("-")} | {updatedListingData?.model || jobData?.information.
                      model?.join("-")}||""
                </div>

              </div>
            </div>
            <div className="my-5 border border-instant-teams-gray-Main"></div>
            <div className=" flex justify-center mt-6 ">
              <div
                className={`py-2 w-2/3 text-white text-center uppercase rounded-lg ${colorJobListingVariants[alreadyAppliedToThisJob?.status] ||
                  "bg-instant-teams-purple-Main"
                  }`}
              >
                {location?.state?.label}
              </div>
            </div>
            {user?.claims?.role != "SKR" && <div>
              <h3 className=" uppercase mt-5 mb-2 font-semibold text-instant-teams-blue-Main">
                Notes
              </h3>
              <div className="w-full overflow-y-auto">
                {updatedListingData && updatedListingData?.length !== 0 ? (
                  <div className="my-1">{updatedListingData[0]?.note}</div>
                ) : (
                  <div>No note was shared.</div>
                )}
              </div>
            </div>}

          </>
        </DialogWrapper>
      )}
    </>
  );
}

export default requireAuth(SeekerListingDetails);
