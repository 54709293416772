import { useState, useEffect } from 'react';
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import Template from '../General/EditTemplate';
import { useAllEInterviews } from '../../../data/eInterview';
import { addJobInterviewQuestion } from '../../../data/jobDescriptions';
import { useEmployerInterviewQuestions } from '../../../data/employer';

function EInterviewJobDefault({jobId, employerId, callback}:{jobId: string, employerId:string, callback: Function}) {
  const [trigger, setTrigger] = useState(true);
  const { data: questions } = useAllEInterviews(trigger);
  const { data: employerInterviewQuestion } = useEmployerInterviewQuestions(employerId)

  function customize() {
    questions.forEach(async (question: object) => {
      await addJobInterviewQuestion(jobId, question);
    })
    callback("job")
  }
  function syncEmployerQuestions() {
    employerInterviewQuestion.forEach(async (question: object) => {
      await addJobInterviewQuestion(jobId, question);
    })
    callback("job")
  }

  return (
    <>
      <GreenUnderlineHeading title="e-interview Default Questions" />
      <button
        className="bg-instant-teams-green-Main hover:bg-instant-teams-green-L1 w-1/5 mb-5 min-h-12 h-12 text-white  rounded border-0  focus:outline-none"
        onClick={() => customize()}
      >
        Customize from Default
      </button>
      {employerInterviewQuestion && employerInterviewQuestion.length > 0 && (
        <button
          className="bg-instant-teams-green-Main hover:bg-instant-teams-green-L1 w-1/5 mb-5 min-h-12 h-12 text-white  rounded border-0  focus:outline-none ml-4"
          onClick={() => syncEmployerQuestions()}
        >
          Customize From Employer
        </button>
      )}
      
      <div className="w-full">
        {questions && questions.map((question: any, index: number) => (
          <Template id={question.id} key={index} readOnly={true} /> 
        ))}
        
      </div>
    </>
  );
}

export default EInterviewJobDefault;
