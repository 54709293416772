import Meta from "../../components/custom-ui/meta/Meta";
import EmployerList from "../../components/employer/listing/EmployerList";
import { requireAuth } from "../../providers/auth";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-hooks-web";

const searchClient = (() => {
  if (!process.env.REACT_APP_ALGOLIA_APP_ID) {
    throw new Error(
      "REACT_APP_ALGOLIA_APP_ID environment variable not defined",
    );
  }
  if (!process.env.REACT_APP_ALGOLIA_API_KEY) {
    throw new Error(
      "REACT_APP_ALGOLIA_API_KEY environment variable not defined",
    );
  }

  return algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY,
  );
})();

function EmployersPage() {
  return (
    <div className="pt-36 min-h-screen">
      <InstantSearch searchClient={searchClient} indexName={"Employer"}>
        <Meta title="Employers" />
        <EmployerList />
      </InstantSearch>
    </div>
  );
}
export default requireAuth(EmployersPage);
