import { camel } from "../../../util/strings/stringManipulation";

function TestQuestions(details) {
  return (
    <ul className="w-full ml-5 mr-5">
      {details?.details &&
        details?.details.map((item, index) => {
          return (
            <>
              {item.preview ? (
                <></>
              ) : (
                <li className="w-full" key={index}>
                  <div className="w-full flex">
                    {item?.question_count && item?.question_count > 0 ? (
                      <>
                        <span className="float-left w-5/6 font-bold">
                          {camel(item.description)}
                        </span>
                        <span className="float-right w-1/46">
                          {item.question_count} questions
                        </span>
                      </>
                    ) : null}
                  </div>
                </li>
              )}
            </>
          );
        })}
    </ul>
  );
}

export default TestQuestions;
