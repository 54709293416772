import React, { useEffect, useState } from "react";
import {
    getNotificationData,
    updateAllIsRead,
    updateIsRead,
} from "../../data/notifications";
import { DisplayTimestamp } from "../../util/timestamps/timestamp";
import { UserNotification } from "../../dataTypes/Notifications";
import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";
const UserNotificationItem_NewUI = ({
    userNotification,
}) => {
    const [notification, setNotification] = useState({});
    const { isRead, notificationId, userId, readAt } = userNotification;

    useEffect(() => {
        handleNotification(notificationId);
    }, [notificationId]); 

    async function handleNotification(notificationId) {
        const notification = await getNotificationData(notificationId);
        setNotification(notification);
    }

    function handleClick() {
        updateIsRead(notificationId, userId);
    }

    return (
        <>
            <tr
                className={
                    TailwindHoverEnlarge(103) + "pl-5 border-b pb-3  border-instant-teams-teal-L3 h-auto"
                }
            >
                <td className="w-1/2 py-6 text-instant-teams-blue-Main px-4  ">
                    <strong>{notification?.title}</strong>
                    <br />
                    <p className="text-gray-500 text-[16px] mt-1">
                        {notification?.body}
                    </p>


                </td>

                <td className="w-1/4 px-4 text-right">
                    {!isRead ? (
                        <>
                            <button
                                className="border-[1px] border-instant-teams-blue-Main rounded-full p-2 px-5 text-instant-teams-blue-Main"
                                onClick={handleClick}
                            >
                                Mark Read
                            </button>
                        </>
                    ) : (
                        <p className="text-gray-500 text-sm">
                            {DisplayTimestamp(readAt)}
                        </p>

                    )}
                </td>
            </tr>
        </>
    );
};

export default UserNotificationItem_NewUI;
