import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/pro-duotone-svg-icons";
import { useEffect, useState } from "react";
import useModal from "../../hooks/useModal";

const environmentRoute = (pathname: string) => {
  if (pathname.includes("stage.")) {
    return {
      color: "bg-instant-teams-blue-L2",
      text: "Stage",
    };
  } else if (pathname.includes("demo.")) {
    return {
      color: "bg-yellow-600",
      text: "Demo",
    };
  } else if (pathname.includes("preprod.")) {
    return {
      color: "bg-instant-teams-teal-D2",
      text: "Preprod",
    };
  } else if (pathname.includes("preprod")) {
    return {
      color: "hazard-background border-4 border-black",
      text: "QA/QC",
    };
  } else if (pathname.includes("localhost")) {
    return {
      color: "bg-slate-500",
      text: "Local",
    };
  } else {
    return null;
  }
};

export const EnvAlert = () => {
  const [environment, setEnvironment] = useState("");
  const { isShowing, toggle } = useModal();
  const handleClick = () => {
    toggle();
  };
  useEffect(() => {
    setEnvironment(environmentRoute(window.location.href));
  }, []);

  return (
    <>
      {environment !== null ? (
        <>
          <div>
            {!isShowing ? (
              <div
                className={`w-full h-12 p-3 text-white  text-center uppercase ${environment?.color}`}
              >
                <>
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    className="fixed left-0 text-black cursor-pointer px-3"
                    onClick={handleClick}
                  />
                  <strong className="">
                    Current Environment: {environment?.text}
                  </strong>
                </>
              </div>
            ) : (
              <FontAwesomeIcon
                icon={faArrowDown}
                className="fixed left-0 text-black cursor-pointer px-3 py-3"
                onClick={handleClick}
              />
            )}
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};
