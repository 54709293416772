import { useParams } from "react-router";
import Meta from "../../custom-ui/meta/Meta";
import {
  useApplication,
  useApplicationPositions,
} from "../../../data/applications";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import SkillBadge from '../../enrollments/skillBadge';

const JobApplicantSkills = () => {
  const params = useParams();
  const { applicationId = "" } = params ?? {};
  const { data: jobApplicationData, status: jobApplicationDataStatus } =
    useApplication(applicationId);
  const { requiredSkills } = jobApplicationData ?? {};

  return (
    <div className="mb-36">
      <Meta title="Job Applicant Skills" />
      <GreenUnderlineHeading title="Skills" />
      <div key={applicationId} className="grid grid-cols-6 gap-4">    
        {requiredSkills &&
          requiredSkills.map((skill: any) => (
              <SkillBadge
                skill={skill}
                userId={jobApplicationData?.resumeId}
              />
          ))}
      </div>
    </div>
  );
};

export default JobApplicantSkills;
