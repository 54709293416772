import { useEffect, useState, forwardRef } from "react";
import { useLookup } from "../../data/lookup";
import { LookUp } from "../../dataTypes/LookUp";

//@ts-ignore
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/pro-regular-svg-icons";

function ScheduleDatePicker({
  handleChange = () => {},
  optionLength = 3,
  bookedDates = [],
  ...props
}: {
  optionLength?: number;
  handleChange: Function;
  bookedDates?: string[]; // Array of already booked dates
}) {
  const initializeState = () =>
    Array.from({ length: optionLength }, () => new Date());
  const [dateOptions, setDateOptions] = useState(initializeState());

  const { data: meetingDurations = [] } = useLookup("meeting-duration");
  // set default duration
  const defaultDuration = meetingDurations.find(
    (item: LookUp) => item.label === "1hr",
  );
  const [meetingDuration, setMeetingDuration] = useState(defaultDuration?.code);

  useEffect(() => {
    handleChange({ dateTimeOptions: dateOptions, meetingDuration });
  }, [dateOptions, meetingDuration, meetingDurations]);

  const CustomDateInput = forwardRef(
    ({ value, onClick }: { value: any; onClick: any }, ref: any) => (
      <div className="custom-date-input w-full">
        <input
          ref={ref}
          className="px-8 w-full"
          type="text"
          value={value}
          onClick={onClick}
          onChange={(e) => {
            e.preventDefault();
          }}
          placeholder="Select date"
        />
        <span className="absolute left-2 top-1.5" onClick={onClick}>
          <FontAwesomeIcon
            icon={faCalendarDays}
            className="text-instant-teams-gray-D1"
          />
        </span>
      </div>
    ),
  );

  const isDateBooked = (date: Date) => {
    const selectedDateString = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    return bookedDates.some(bookedDate => {
      const bookedDateParts = bookedDate.split(" @ ");
      const bookedDateString = new Date(bookedDateParts[0]).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      return bookedDateString === selectedDateString;
    });
  };

  return (
    <>
      <div className="w-full flex flex-wrap">
        {Array.from(Array(optionLength)).map((item, index) => (
          <div key={index} className="w-full mb-4">
            <DatePicker
              showIcon
              minDate={new Date()}
              selected={dateOptions[index]}
              onChange={(date: any) =>
                setDateOptions((prevState: any[]) => {
                  if (Array.isArray(prevState)) {
                    const newState = [...prevState];
                    newState.splice(index, 1, date);
                    return newState;
                  }
                  return prevState;
                })
              }
              selectsStart
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
              startDate={dateOptions[index]}
              isClearable={true}
              customInput={<CustomDateInput />}
              filterDate={(date:any) => {
                const isBooked = isDateBooked(date);
                return !isBooked;
              }}
              {...props}
            />
          </div>
        ))}
      </div>
      <div className="h-12 flex space-x-10 justify-center items-center">
        <label className="ml-2 text-instant-teams-gray-D1 w-1/2">
          Meeting Duration
        </label>

        <select
          className="w-1/2"
          value={meetingDuration}
          onChange={(e) => {
            setMeetingDuration(e.target.value);
          }}
        >
          {meetingDurations.map((item: LookUp, index: number) => (
            <option key={item.id} value={item.code}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

export default ScheduleDatePicker;
