import toast from "react-hot-toast";
import ToastAlert from "../../custom-ui/toast-alert/ToastAlert";
import { Timestamp } from "firebase/firestore";
import { requireAuth, useAuth } from "../../../providers/auth";
import { createSyncRequest } from "../../../data/academy";
import AssessmentItem from "../parts/assessmentItem";
import {
  SearchBox,
  Configure,
  RefinementList,
  CurrentRefinements,
  ClearRefinements,
  Hits,
  HitsPerPage,
  Pagination,
  RefinementListProps,
} from "react-instantsearch-hooks-web";
import Meta from "../../custom-ui/meta/Meta";
import { getLabel } from "../../../util/search/getLabel";

const AcademyAssessments = () => {
  const { user } = useAuth();

  const transformSkillLevel: RefinementListProps["transformItems"] = (
    items,
  ) => {
    items = items.map((item) => ({
      ...item,
      label: getLabel(item.label, "skillLevel"),
    }));
    return items;
  };

  const startFullSync = () => {
    const request = {
      source: "tg",
      type: "full",
      section: "courses",
      id: "",
      timestamp: Timestamp.now(),
      who: user.uid,
      cycle: {
        limit: 25,
        offset: 0,
      },
    };
    createSyncRequest(request);
    toast.success("Sync Started");
  };

  return (
    <div className="min-h-screen">
      <Meta title="Learning & Development - Assessments" />
      <div className="w-full pt-6 inline-flex min-h-screen">
        <div className="pb-4 mt-8  pr-4 w-1/5">
          <button
            className="w-full mb-5 min-h-12 h-12 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
            onClick={startFullSync}
          >
            Sync Assessments
          </button>
          <SearchBox
            placeholder="Keyword Search"
            classNames={{
              input: "w-full",
              submitIcon: "hidden",
              resetIcon: "hidden",
              submit: "hidden",
            }}
          />
          <CurrentRefinements />
          Filter by Tags
          <RefinementList
            attribute="tags"
            operator="and"
            classNames={{
              root: "capitalize",
              showMore: " text-instant-teams-blue-Main text-xs",
            }}
            limit={5}
            showMore={true}
          />
          Filter by Test Gorilla Status
          <RefinementList
            attribute="status"
            operator="and"
            classNames={{
              root: "capitalize",
              showMore: " text-instant-teams-blue-Main text-xs",
            }}
            limit={5}
            showMore={true}
          />
          Filter by Visibility
          <RefinementList
            attribute="visable"
            operator="and"
            classNames={{
              root: "capitalize",
              showMore: " text-instant-teams-blue-Main text-xs",
            }}
            limit={5}
            showMore={true}
          />
          Filter by Level
          <RefinementList
            attribute="level"
            operator="and"
            classNames={{
              root: "capitalize",
              showMore: " text-instant-teams-blue-Main text-xs",
            }}
            limit={5}
            showMore={true}
            transformItems={transformSkillLevel}
          />
          Filter by Skill Family
          <RefinementList
            attribute="skillFamily"
            operator="and"
            classNames={{
              root: "capitalize",
              showMore: " text-instant-teams-blue-Main text-xs",
            }}
            limit={5}
            showMore={true}
          />
          <ClearRefinements
            translations={{ resetButtonText: "Clear Filters" }}
            classNames={{
              button:
                " bg-instant-teams-teal-Main text-white p-2 rounded mt-5 text-center w-full",
            }}
          />
          <ToastAlert />
        </div>
        <div className="pl-4 w-4/5 pb-36">
          <div className="flex justify-between w-[100%] mb-2">
            <div></div>
            <div className="flex items-center">
              <div className=" pr-4">Results per page:</div>
              <HitsPerPage
                items={[
                  { label: "10", value: 10 },
                  { label: "25", value: 25 },
                  { label: "50", value: 50, default: true },
                  { label: "100", value: 100 },
                ]}
                classNames={{ select: "text-xs h-6 py-0" }}
              />
            </div>
          </div>
          <Configure />
          <Hits
            hitComponent={(hit: any) => <AssessmentItem hit={hit} />}
            classNames={{
              root: "ais-InfiniteHits",
              item: "mb-2",
            }}
          />
          <div className=" w-full flex justify-center">
            <div className="mt-12">
              <Pagination
                showNext={true}
                padding={1}
                classNames={{
                  list: "flex",
                  item: "h-8 w-8 text-center rounded-m rounded mx-1 bg-white",
                  selectedItem: "scale-125 border-black",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default requireAuth(AcademyAssessments);
