import { useEffect, useCallback, useState, useRef } from "react";
import AvatarEditor from "react-avatar-editor";
import { useDropzone } from "react-dropzone";
import { useAuth } from "../../../providers/auth";
import { upload } from "../../../providers/firebaseStorage";

import { errorLogger } from "../../../providers/clientLogger";
import { useUser } from "../../../providers/database";
import { useParams } from "react-router-dom";
function AvatarDropzone(props) {
  const params = useParams();
  const VALID_FILE_EXTENSIONS = [".jpeg", ".jpg", ".png"];
  const DEFAULT_IMAGE = "/images/default_user.png";
  const VALID_FILE_TYPES = {
    "image/png": [],
    "image/PNG": [],
    "image/jpg": [],
    "image/JPG": [],
    "image/jpeg": [],
    "image/JPEG": [],
  };

  const { user } = useAuth();
  const { data: userData } = useUser(params?.userId || user?.uid);
  let imageExtensionType = "png";

  const [state, setState] = useState({
    image: userData.photoURL ?? "/images/default_user.png",
    previewImage: userData.photoURL ?? "/images/default_user.png",
    canEdit: false,
  });

  const [imageSize, setImageSize] = useState(0);
  const [uploading, setUploading] = useState(false);

  const onDropRejectedImage = () =>
    setState({
      ...state,
      image: DEFAULT_IMAGE,
      previewImage: DEFAULT_IMAGE,
    });

  const onErrorImage = () =>
    setState({ ...state, image: DEFAULT_IMAGE, previewImage: DEFAULT_IMAGE });

  const validImageType = (file) =>
    VALID_FILE_EXTENSIONS.some((ext) => file.name.toLowerCase().endsWith(ext));

  const validatorImage = (image) => {
    if (!validImageType(image)) {
      setState({ ...state, image: DEFAULT_IMAGE, previewImage: DEFAULT_IMAGE });
      errorLogger("Incorrect image type\nValid types: .jpg, .jpeg, .png", {
        component: "Avatar dropzone validatorImage()",
        flow: "validate image",
        userId: `${userData?.id}`,
      });

      return {
        code: "Incorrect-image-Type",
        message: "Valid image Types: .jpg, .jpeg, .png",
      };
    } else {
      setState({ ...state, image });
      return null;
    }
  };
  const onDrop = useCallback((droppedFiles) => {
    if (droppedFiles[0]) {
      const file = droppedFiles[0];
      const fileSize = Math.ceil(file.size / 1024 / 1024);
      setImageSize(fileSize);
      const fileType = file.type.split("/")[1];
      const renamedFile = new File([file], `${userData?.id}.${fileType}`, {
        type: file.type,
      });

      if (renamedFile.type.includes("png")) imageExtensionType = "png";
      setState({
        ...state,
        image: renamedFile,
        canEdit: true,
        previewImage: URL.createObjectURL(droppedFiles[0]),
      });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected: onDropRejectedImage,
    onError: onErrorImage,
    validator: validatorImage,
    accept: VALID_FILE_TYPES,
  });

  async function handleImageUpload() {
    setUploading(true);
    await upload(state.image, `resize/${state.image.name}`);
    setUploading(false);
    props.closeModal();
  }
  return (
    <>
      <div className="flex justify-center" {...getRootProps()}>
        <input {...getInputProps()} />

        <img className="cursor-pointer" src={state.previewImage} alt="Logo" />
      </div>
      {state.canEdit ? (
        <div className="flex flex-col items-center">
          <p>Image size {imageSize} MB</p>
          {uploading === false ? (
            <div className="flex items-center justify-end p-6 mt-2 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => props.closeModal()}
              >
                Close
              </button>
              <button
                className="bg-instant-teams-blue-Main text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg hover:bg-instant-teams-blue-D1 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleImageUpload}
              >
                Save Changes
              </button>
            </div>
          ) : (
            <div className="flex justify-center mt-4">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                disabled
              >
                Uploading...
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="flex justify-center mt-4">
          <button
            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => props.closeModal()}
          >
            Close
          </button>
        </div>
      )}
    </>
  );
}

export default AvatarDropzone;
