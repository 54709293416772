import { useState } from "react";
import { useAuth } from "../../../providers/auth";
import { useForm } from "react-hook-form";
import { upload } from "../../../storage/storage";
import folderNames from "../../../storage/dictionary/folderNames";
import {
  createDescription,
  writeFileLocation,
} from "../../../data/jobDescriptions";
import DragAndDropVariantA from "../../custom-ui/drag-and-drop/DragAndDropFile";
import ForwardArrowButton from "../../custom-ui/button/ForwardArrowButton";
import { errorLogger } from "../../../providers/clientLogger";
import { Employer } from "../../../dataTypes/Employer";
import { friendlyFirebaseError } from "../../../providers/firebase-errors/friendlyFirebaseError";
import { JobDescription } from "../../../dataTypes/JobDescription";

function CreateJobDescriptionForm({
  employer,
  jobListingId,
  onDone,
}: {
  employer: Employer;
  jobListingId: string;
  onDone: Function;
}) {
  const { user } = useAuth();
  const [file, setFile] = useState();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const { handleSubmit, register, errors, getValues } = useForm({
    defaultValues: {
      description: "",
    },
  });

  const onSubmit = (data: { description: string }) => {
    const { description } = data;

    // save file and description or just the description
    if (file) {
      upload(file, folderNames.jobDescriptions, `${jobListingId}_${file.name}`)
        .then((fileUrl) => {
          writeFileLocation(jobListingId, {
            label: file.name,
            type: file.type,
            link: fileUrl,
          })
            .then((docRef) => {})
            .catch((error: Error) => {
              setFormAlert({
                type: "error",
                message: friendlyFirebaseError(error),
              });
              errorLogger(error.message, {
                component: "CreateJobDescriptionForm - updateJobDescription()",
                flow: "Employer Onboarding",
                userId: user.id,
              });
            });
        })
        .catch((error: Error) => {
          setFormAlert({
            type: "error",
            message: friendlyFirebaseError(error),
          });
          errorLogger(error.message, {
            component: "CreateJobDescriptionForm - uploading document upload()",
            flow: "Employer Onboarding",
            userId: user.id,
          });
        });

      createDescription(jobListingId, description)
        .then(() => {
          onDone();
        })
        .catch((error) => {
          setFormAlert({
            type: "error",
            message: friendlyFirebaseError(error),
          });
          errorLogger(error.message, {
            component: "CreateJobDescriptionForm - uploading document upload()",
            flow: "Employer Onboarding",
            userId: user.id,
          });
        });
    } else {
      createDescription(jobListingId, description)
        .then(() => {
          onDone();
        })
        .catch((error) => {
          setFormAlert({
            type: "error",
            message: friendlyFirebaseError(error),
          });
          errorLogger(error.message, {
            component: "CreateJobDescriptionForm - uploading document upload()",
            flow: "Employer Onboarding",
            userId: user.id,
          });
        });
    }
  };
  return (
    <>
      {formAlert && (
        <div className="mb-4 text-red-600">{formAlert.message}</div>
      )}{" "}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <label
            className="py-1 px-3 mb-1 uppercase block text-left font-semibold text-instant-teams-blue-Main"
            htmlFor="title"
          >
            Upload Job Description
          </label>
          <DragAndDropVariantA setFile={setFile} />
        </div>
        <div className="mb-4">
          <label
            className="py-1 px-3 mb-1 uppercase block text-left font-semibold text-instant-teams-blue-Main"
            htmlFor="title"
          >
            Enter Job Description
          </label>

          <textarea
            className="h-72 py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            id="description"
            name="description"
            rows={4}
            placeholder="Enter Job Description"
            ref={register({})}
          />
          {errors.description && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.description.message}
            </p>
          )}
        </div>
        <ForwardArrowButton type="submit" pending={pending} />
      </form>
    </>
  );
}

export default CreateJobDescriptionForm;
