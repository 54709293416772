import { useState } from "react";
import { useAuth } from "../../providers/auth";
import LookupSelector from "../custom-ui/input-fields/lookupSelector";
import { addUserAffiliation } from "../../data/affiliation";
function AddAffiliationModal({ onDone }: { onDone: Function }) {
  const { user } = useAuth();
  const [branch, setBranch] = useState("");
  const [affiliation, setAffiliation] = useState("");

  const handleClick = async () => {
    await addUserAffiliation(user.uid, branch, affiliation).then(() => {
      onDone();
    });
  };

  return (
    <div>
      <div className=" flex  justify-center space-x-5">
        <div className="w-1/2">
          <LookupSelector
            value={branch}
            setValue={setBranch}
            group="branch"
            display="Branch"
            placeholder="Select Branch"
            directions=""
          />
        </div>
        <div className="w-1/2">
          <LookupSelector
            value={affiliation}
            setValue={setAffiliation}
            group="affiliation"
            display="Affiliation"
            placeholder="Select Affiliation"
            directions=""
          />
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <button
          onClick={handleClick}
          className="w-1/2 py-2 uppercase bg-instant-teams-blue-Main text-white rounded-lg hover:bg-instant-teams-blue-D1"
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default AddAffiliationModal;
