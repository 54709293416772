export const isLocked = (status: string) =>
  status === "live" || status === "cancelled";

export const isLockedJobNew = (status: string) =>
  ["live", "on_hold", "closed_filled", "closed_cancelled"].includes(status);

export const isPositionLocked = (status: string, positionStatus: string) => {
  const jobStatus = status === "live" || status === "cancelled";
  if (jobStatus) {
    if (positionStatus) {
      return true;
    }
  }
  return false;
};
