import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import useModal from "../../../hooks/useModal";
import { useEmployer } from "../../../data/employer";
import AutoSaveStatusSelector from "../../custom-ui/input-fields/statusSelectorNew";
import { collectionNames } from "../../../data/dictionary/collectionNames";
import HeaderLogItems from "./HeaderLogItems";
import { useAuth } from "../../../providers/auth";
import ToastAlert from "../../custom-ui/toast-alert/ToastAlert";
import { DisplayDate } from "../../../util/timestamps/timestamp";
import { useJobDetailsNewCollection } from "../../../data/jobDetailsNewCollection";

function JobNewSidebarHeader({}) {
  const { userClaims } = useAuth();
  const params = useParams();
  const [employerId, setEmployerId] = useState(params.employerId);
  const [jobId, setJobId] = useState(params.id);
  const [collection, setCollection] = useState(collectionNames.jobDescriptions);
  const { data: jobData, status: jobStatus } = useJobDetailsNewCollection(
    params.id ?? "",
  );
  const { data: employer, status: employerStatus } = useEmployer(employerId);

  const [logo, setLogo] = useState(employer?.logo);
  const [displayName, setDisplayName] = useState(employer?.displayName);
  const [status, setStatus] = useState(jobData?.status);
  const [title, setTitle] = useState(jobData?.title);
  const [createdAt, setCreatedAt] = useState(jobData?.createdAt);

  useEffect(() => {
    if (jobStatus === "success") {
      setEmployerId(jobData?.employer);
    }
  }, [jobData, jobStatus]);

  useEffect(() => {
    if (jobStatus === "success") {
      setTitle(jobData?.jobName);
      setStatus(jobData?.jobState);
      setCreatedAt(jobData?.creationDate);

      setEmployerId(jobData?.companyId);
      setDisplayName(jobData?.companyFullName);
    }
  }, [jobData, jobStatus]);

  useEffect(() => {
    if (logo === undefined || logo === null) {
      setLogo("/images/default-employer.jpg");
    } else {
      setLogo(employer?.logo);
    }
  }, [employer]);

  useEffect(() => {
    setJobId(params.id);
  }, [params.id]);

  return (
    <div className="">
      <div className="items-center pt-4  pb-3">
        <img
          src={logo}
          alt={displayName}
          className="mx-auto  max-w-75 h-auto"
        />
        <div className="text-center text-xl font-bold pb-1 pt-3">
          {displayName}
        </div>
        <div className="text-center text-xl font-bold">{title}</div>
        <p className="text-center text-sm pb-5">{DisplayDate(createdAt)}</p>

        {status &&
          userClaims?.claims?.type === "A" &&
          employer?.status === "active" && (
            <div className="px-2">
              <AutoSaveStatusSelector
                status={status}
                collection="JobDetailsNewCollection"
                recId={jobData.id}
                jobData={jobData}
                required={false}
                name="jobState"
                display="Status"
                placeholder=""
                directions=""
              />
            </div>
          )}
      </div>

      <ToastAlert />
    </div>
  );
}

export default JobNewSidebarHeader;
