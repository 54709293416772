import { useState } from "react";
import { memo } from "react";
import React from "react";

const Popover = memo(
  ({
    content,
    size = "w-64 h-64",
    bgColor = "bg-white",
    textColor = "text-black",
    children,
  }) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
      setIsHovered(true);
    };
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
    return (
      <span
        className="relative group"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isHovered && (
          <span className="absolute -top-5 left-64 -translate-x-1/2 z-40">
            <div
              className={`${size} ${bgColor} ${textColor} pointer-events-none shadow-input-field border border-instant-teams-blue-Main rounded-lg whitespace-normal px-6 py-4 opacity-0 group-hover:opacity-100`}
            >
              {content}
            </div>
          </span>
        )}

        {React.cloneElement(children)}
      </span>
    );
  },
);

export default Popover;
