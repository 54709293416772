/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useQuery,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  doc,
  getDocs,
  collection,
  query,
  where,
  orderBy,
  addDoc,
  limit,
  deleteDoc,
} from "firebase/firestore";

import { db, createQuery } from "../providers/database";
import { collectionNames } from "./dictionary/collectionNames";


export function addRotatorQuote(
  quote: object,  
) {
  return addDoc(
    collection(
      db,
      `${collectionNames.rotatorQuotes}`,
    ),
    {...quote},
  );
}

// Temporary List of all Components
export function useRotatorQuotes(trigger: string){
  return useQuery(
    ["Quotes", !!trigger],
    createQuery(() =>
      query(
        collection(db, collectionNames.rotatorQuotes),
      ),
    ),
    { enabled: !!trigger },
  );
}

export function useRotatorQuotesByStatus(status: string){
  return useQuery(
    ["quotes", !!status],
    createQuery(() =>
      query(
        collection(db, collectionNames.rotatorQuotes),
        where("status", "==", status),
      ),
    ),
    { enabled: !!status },
  );
}

export function useRotatorQuote(id: string) {
  return useQuery(
    ["quote", { id }],
    createQuery(() => doc(db, collectionNames.rotatorQuotes, id)),
    { enabled: !!id },
  );
}

export function deleteQuote(id: string) {
  return deleteDoc(doc(db,`${collectionNames.rotatorQuotes}/`,id,),);
}