import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
import { useEmployer } from "../../../data/employer";
import { useFindJobStatus } from "../../../data/status";
import { useLookupCode } from "../../../data/lookup";
import DisplayArrayLookup from "../../custom-ui/display/displayArrayLookups";
import Tooltip from "../../custom-ui/tooltip/tooltip";
import { TailwindHoverEnlarge } from "../../../util/tailwind/tailwind-styles";

const JobListingLineComponent = forwardRef(
  ({ row }: { row: any }, ref: any) => {
    const { data: solution } = useLookupCode(row?.hit?.solutionType);
    const { data: statusData } = useFindJobStatus(row?.hit?.status);

    //tailwindcss.com/docs/content-configuration#dynamic-class-names
    // Always map props to static class names
    const colorJobListingVariants: any = {
      approvedByCustomer: "bg-instant-teams-tags-LIME",
      archived: "bg-instant-teams-gray-D1",
      awaiting: "bg-instant-teams-tags-GOLD",
      draft_approval: "bg-instant-teams-tags-ORANGE",
      cancelled: "bg-instant-teams-gray-D1",
      draft: "bg-instant-teams-tags-CREAM text-black",
      working: "bg-instant-teams-tags-BLUE",
      on_hold: "bg-instant-teams-tags-ROSE",
      live: "bg-instant-teams-tags-GREEN",
      tac_review: "bg-instant-teams-tags-TEAL",
      filled: "bg-instant-teams-tags-LBLUE",
    };

    return (
      <Link
        to={`/jobs/${row?.hit?.objectID}/${row?.hit?.employer}/metrics`}
        className="cursor-pointer bg-white h-full"
      >
        <div
          ref={ref}
          className={
            TailwindHoverEnlarge(105) +
            "items-center w-full rounded-lg relative shadow-lg pb-2"
          }
        >
          <div className="items-center bg-instant-teams-teal-Main p-5 rounded-t-lg w-full min-h-max h-42">
            <div className="m-auto">
              <img
                className="object-scale-down max-h-12 min-h-12 w-full resize rounded-md mx-auto mb-2 my-auto"
                src={
                  row?.hit?.employerSummary?.logo ||
                  "/images/default-employer.jpg"
                }
                alt={row?.hit?.employerSummary?.displayName}
              />
            </div>
            <div className="w-full text-white pl-2 text-center mb-2">
              <p className=" text-sm">
                {row?.hit?.employerSummary?.displayName || row?.hit?.employer}
              </p>
              <p className=" text-xl whitespace-nowrap text-ellipsis overflow-hidden">
                {row?.hit?.title}
              </p>
              <p className=" text-sm">{solution?.[0]?.label || "Not Set"}</p>
            </div>
            <span className="inline-block px-2 py-1 text-xs font-bold leading-none text-instant-teams-teal-Main bg-white rounded-full absolute top-4 left-4">
              <Tooltip text="Positions Created">
                <p>{row?.hit?.summaryCount?.positions}</p>
              </Tooltip>
            </span>
            <span className="inline-block px-2 py-1 text-xs font-bold leading-none text-instant-teams-teal-Main bg-white rounded-full absolute top-4 right-4">
              <Tooltip text="Number of Seats Available">
                <p>{row?.hit?.seats || 1}</p>
              </Tooltip>
            </span>
          </div>
          <Tooltip text={statusData?.description || "Current Status"}>
            <div
              className={`items-center w-3/4 mt-5 rounded-md text-white mx-auto p-1 ${
                colorJobListingVariants[statusData?.id] ||
                "bg-instant-teams-purple-Main"
              }`}
            >
              <p className="text-center">{statusData?.label}</p>
            </div>
          </Tooltip>
          <div className="items-center w-full px-4 mt-5">
            <div className="grid grid-cols-4 space-x-2 items-center mx-auto text-center">
              <Tooltip text="Applicants">
                <div className="rounded-md bg-instant-teams-tags-A1 text-white px-1">
                  <span className="text-instant-teams-tags-A2">
                    {row?.hit?.summaryCount?.applied || 0}
                  </span>
                </div>
              </Tooltip>
              <Tooltip text="TAC Working">
                <div className="rounded-md bg-instant-teams-tags-B1 text-white px-1">
                  <span className="text-instant-teams-tags-B2">
                    {row?.hit?.summaryCount?.underReview || 0}
                  </span>
                </div>
              </Tooltip>
              <Tooltip text="Offers">
                <div className="rounded-md bg-instant-teams-tags-C1 text-white px-1">
                  <span className="text-instant-teams-tags-C2">
                    {row?.hit?.summaryCount?.offers || 0}
                  </span>
                </div>
              </Tooltip>
              <Tooltip text="Employed">
                <div className="rounded-md bg-instant-teams-tags-D1 text-white px-1">
                  <span className="text-instant-teams-tags-D2">
                    {row?.hit?.summaryCount?.employed || 0}
                  </span>
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="text-center text-sm mt-4">
            <DisplayArrayLookup
              value={row?.hit?.information?.term}
              group={"term"}
            />
          </div>
          <div className="text-center text-sm mt-1">
            <DisplayArrayLookup
              value={row?.hit?.information?.classification}
              group={"classification"}
            />
          </div>

          <div className="text-center text-sm mt-1">
            <DisplayArrayLookup
              value={row?.hit?.information?.model}
              group={"model"}
            />
          </div>
          <div className="text-center text-sm mt-1">
            Hours Per Week:{" "}
            {row?.hit?.information?.hoursPerWeek ? (
              <>
                {row?.hit?.information?.hoursPerWeek?.[0]} -{" "}
                {row?.hit?.information?.hoursPerWeek?.[1]}
              </>
            ) : (
              <>Not Set</>
            )}
          </div>
          <div className="text-center text-sm mt-1 mb-2">
            {row?.hit?.information?.payType?.HRLY && <span>Hourly</span>}
            {row?.hit?.information?.payType?.HRLY &&
              row?.hit?.information?.payType?.SAL && <span>/</span>}
            {row?.hit?.information?.payType?.SAL && <span>Salary</span>}
            {!row?.hit?.information?.payType?.HRLY &&
              !row?.hit?.information?.payType?.SAL && <span>Not Set</span>}
          </div>
        </div>
      </Link>
    );
  },
);

export default JobListingLineComponent;
