import { useQuery } from "react-query";
import {
  doc,
  addDoc,
  collection,
  query,
  orderBy,
  where,
  getDoc,
  getDocs,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { createQuery, format, db } from "../providers/database";
import { collectionNames } from "./dictionary/collectionNames";
import { Timestamp } from "firebase/firestore";

export function createAlert(data: any) {
  const { title, body, url } = data;
  return addDoc(collection(db, collectionNames.alerts), {
    title: title,
    body: body,
    url: url ? url : "",
    createdAt: Timestamp.now(),
    archived: false,
  });
}

export function useAllAlertsForAdmin(numberOfDays: number) {
  const days = numberOfDays > 0 ? numberOfDays : 1000;
  const today = Timestamp.now();
  const timeFrame = new Date();
  timeFrame.setDate(today.toDate().getDate() - days);
  const timeFrameTimestamp = Timestamp.fromDate(timeFrame);

  return useQuery(
    ["allAlertsForAdmins", { numberOfDays }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.alerts),
        where("createdAt", ">", timeFrameTimestamp),
        orderBy("createdAt", "desc"),
      );
    }),
  );
}

export function useAllActiveAlerts() {
  return useQuery(
    ["allActiveAlerts"],
    createQuery(() => {
      return query(
        collection(db, collectionNames.alerts),
        where("archived", "==", false),
        orderBy("createdAt", "asc"),
      );
    }),
  );
}
