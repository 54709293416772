import { Link } from "react-router-dom";
import { useEmployer } from "../../../../data/employer";
import { useJobDescription } from "../../../../data/jobDescriptions";
import { useJobOfferByEmployee } from "../../../../data/offers";
import { useJobPositionDetails } from "../../../../data/positions";
import { useFindEmploymentStatus } from "../../../../data/status";
import { DisplayDate } from "../../../../util/timestamps/timestamp";
import Tooltip from "../../../custom-ui/tooltip/tooltip";

function SeekerStandingItem({ employment }: { employment: any }) {
  const { data: employerData } = useEmployer(employment?.employerId);
  const { data: jobData } = useJobDescription(employment?.jobId);
  const { data: positionData } = useJobPositionDetails(
    employment?.jobId,
    employment?.positionId,
  );
  const { data: jobOfferData } = useJobOfferByEmployee(
    employment?.jobId,
    employment?.userId,
  );
  const { data: statusData } = useFindEmploymentStatus(employment?.status);

  const currentOffer = jobOfferData?.[0];

  const colorJobListingVariants: any = {
    pending: "bg-instant-teams-tags-BLUE",
    error: "bg-instant-teams-tags-ROSE",
    hired: "bg-instant-teams-tags-GREEN",
    preparation: "bg-instant-teams-tags-TEAL",
    rejected: "bg-instant-teams-tags-ORANGE",
    cancelled: "bg-instant-teams-gray-D1",
  };

  return (
    <>
      <Link
        to={`/employee/${employment?.id}/details`}
      >
        <div key={employment?.id} className="w-full grid grid-cols-5 mt-4 h-48 rounded-r-lg shadow-input-field">
          <div className="flex-col col-span-2 bg-instant-teams-blue-Main text-white w-full flex flex-wrap justify-center items-center rounded-l-lg ">
            <img
              className="h-20 w-20 mt-6 rounded-lg"
              src={employerData?.logo}
              alt={employerData?.displayName}
            />
            <p className="mt-2">{employerData?.displayName}</p>
          </div>
          <div className="col-span-3 text-center mt-5 mx-2 ">
            <div className=" font-semibold text-xl">{jobData?.title}</div>
            <div className="font-semibold">{positionData?.positionName}</div>
            <div className="mx-10">
              <Tooltip text={statusData?.description || "Current Status"}>
                <div
                  className={`items-center w-full mt-2 mb-3 rounded-md text-white mx-auto py-2 ${
                    colorJobListingVariants[employment?.status] ||
                    "bg-instant-teams-purple-Main"
                  }`}
                >
                  <p className="text-center">{statusData?.label}</p>
                </div>
              </Tooltip>
            </div>
            <div className="uppercase text-instant-teams-blue-Main font-semibold">
              Hired{" "}
              <span className="pl-4 text-lg font-thin">
                {currentOffer
                  ? DisplayDate(currentOffer?.startDate)
                  : "Not yet set."}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}
export default SeekerStandingItem;
