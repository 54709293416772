import { Outlet } from "react-router-dom";

import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { useState } from "react";
import { requireAuth } from "../../providers/auth";
function LearningPage() {
  const params = useParams();
  const navigate = useNavigate();
  const [id, setId] = useState(params?.assessmentId);

  useEffect(() => {
    if (
      window.location.pathname.endsWith(`${id}`) ||
      window.location.pathname.endsWith(`${id}/`)
    )
      navigate(`/learning/assessment/${id}/dashboard`, { replace: true });
  }, [params, navigate]);
  return (
    <div>
      <div className="md:container w-full">
        <Outlet />
      </div>
    </div>
  );
}

export default requireAuth(LearningPage);
