import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import useModal from "../../../hooks/useModal";
import { useEmployer } from "../../../data/employer";
import { useEmploymentByEmployee } from "../../../data/employee";
import { useJobDescription } from "../../../data/jobDescriptions";
import GeneralNote from "../../custom-ui/input-fields/modals/GeneralNote";
import AutoSaveStatusSelector from "../../custom-ui/input-fields/statusSelector";
import { collectionNames } from "../../../data/dictionary/collectionNames";
import { useAuth } from "../../../providers/auth";

function EmployeeSidebarHeader({}) {
  const { userClaims } = useAuth();

  const { employers } = collectionNames;
  const params = useParams();
  const employeeId = params?.employeeId;
  const { data: employmentRecord } = useEmploymentByEmployee(employeeId || "");
  const { data: employer } = useEmployer(employmentRecord?.employerId);
  const { data: jobRecord } = useJobDescription(employmentRecord?.jobId);

  const { displayName } = employer ?? {};
  const { isShowing: isShowingNote, toggle: toggleNote } = useModal();
  const handleDone = () => {
    toggleNote();
  };

  const [logo, setLogo] = useState(employer?.logo);
  const [id, setId] = useState(params.userId ?? "");

  useEffect(() => {
    if (logo === undefined || logo === null) {
      setLogo("/images/default-employer.jpg");
    } else {
      setLogo(employer?.logo);
    }
  }, [employer]);
  useEffect(() => {
    setId(params.employerId ?? "");
  }, [params.employerId]);

  return (
    <div className="">
      <div className="items-center pt-4 pr-3 pb-3">
        <img
          id="employer-logo"
          src={logo}
          alt={displayName}
          className="mx-auto  max-w-75 h-auto"
        />
        <div className="text-center text-xl font-bold pt-3 pb-2">
          {displayName}
        </div>
        <div className="text-center text-lg font-bold pb-5">
          {jobRecord?.title}
        </div>
        {userClaims?.claims?.type === "A" &&
          employmentRecord &&
          employmentRecord.status && (
            <div className="pl-3">
              <AutoSaveStatusSelector
                status={employmentRecord.status}
                collection="Employees"
                recId={employmentRecord.id}
                required={false}
                name="status"
                display="Status"
                placeholder=""
                directions=""
              />
            </div>
          )}
      </div>
      {isShowingNote && (
        <GeneralNote onDone={handleDone} collection={employers} id={id} />
      )}
    </div>
  );
}

export default EmployeeSidebarHeader;
