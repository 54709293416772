import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  runTransaction,
  onSnapshot,
  doc,
  collection,
  collectionGroup,
  getCountFromServer,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  limit,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { db, client, createQuery, format } from "../providers/database";
import { JobDescription } from "../dataTypes/JobDescription";
import { File } from "../dataTypes/Utilities";
import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";

export function createJobPosition({
  jobId,
  employerId,
  departmentId,
  positionName,
}: {
  jobId: string;
  positionName: string;
  employerId: string;
  departmentId: string;
}) {
  return addDoc(
    collection(
      db,
      `${collectionNames.jobDescriptions}/${jobId}/${subCollectionNames.positions}`,
    ),
    {
      positionName,
      employer: employerId,
      department: departmentId,
      jobDescription: jobId,
      information: { hires: 1 },
    },
  );
}

// Grab all the positions from subcollection
export function useJobPositions(id: string) {
  return useQuery(
    ["allJobPositions", { id }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.jobDescriptions}/${id}/${subCollectionNames.positions}`,
        ),
        //orderBy("createdAt"),
      );
    }),
    { enabled: !!id },
  );
}

// use all active jobPositions
export function useActiveJobPositions(id: string) {
  return useQuery(
    ["allActiveJobPositions", { id }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.jobDescriptions}/${id}/${subCollectionNames.positions}`,
        ),
        where("active", "==", true),
      );
    }),
    { enabled: !!id },
  );
}

export function useJobPositionDetails(jobId: string, positionId: string) {
  let subCollectionRef: any;

  if (jobId && positionId) {
    subCollectionRef = collection(
      db,
      collectionNames.jobDescriptions,
      jobId,
      subCollectionNames.positions,
    );
  }
  return useQuery(
    ["position", { jobId, positionId }],
    createQuery(() => {
      return doc(subCollectionRef, positionId);
    }),
    { enabled: !!jobId && !!positionId },
  );
}

export function usePositionsByDepartment(deptId: string) {
  return useQuery(
    ["positionByDept", { deptId }],
    createQuery(() => {
      console.log("running query");
      return query(
        collectionGroup(db, "Positions"),
        where("department", "==", deptId),
      );
    }),
    { enabled: !!deptId },
  );
}
export function usePositionsByEmployer(employerId: string) {
  return useQuery(
    ["positionsByEmployer", { employerId }],
    createQuery(() => {
      console.log("running query with id: ", employerId);
      return query(
        collectionGroup(db, "Positions"),
        where("employer", "==", employerId),
      );
    }),
    { enabled: !!employerId },
  );
}

// usePositionsCountByDepartment needs debugging
// export function usePositionsCountByDepartment(deptId: string) {
//   return useQuery(
//     ["positionCountByDept", { deptId }],
//     createQuery(() => {
//       console.log("running query");
//       return getCountFromServer(
//         query(
//           collectionGroup(db, "Positions"),
//           where("department", "==", deptId),
//         ),
//       );
//     }),
//     { enabled: !!deptId },
//   );
// }
