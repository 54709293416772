import FileSaver from "file-saver";
import Tooltip from "../custom-ui/tooltip/tooltip";
import { errorLogger } from "../../providers/clientLogger";
import { useAuth } from "../../providers/auth";
import { useState } from "react";

function CSVDownload({
  data,
  tooltip = "Download CSV file",
  fileName = "data.csv",
  size = "fa-3x",
  hasDataAggregation = false,
  // data aggregator can be a Cloud function or an async function that returns a promise
  dataAggregator,
}: {
  data: any;
  tooltip?: string;
  fileName?: string;
  size?: string;
  hasDataAggregation?: Boolean;
  dataAggregator?: Function;
}) {
  console.log(hasDataAggregation);
  const [pending, setPending] = useState(false);
  const { user } = useAuth();
  const callDataAggregator = async (csvData: any) => {
    if (dataAggregator) {
      try {
        const res = await dataAggregator(csvData);
        return res;
      } catch (error) {
        console.error("Error aggregating data:", error);
      }
    } else {
      console.warn("Pass in an aggregator, please");
      return null;
    }
  };
  const convertToCSV = async () => {
    setPending(true);
    let csvData = { data: [] };
    if (hasDataAggregation) {
      try {
        const aggregatedData = await callDataAggregator(data);
        csvData = aggregatedData;
      } catch (error) {
        errorLogger("Something went wrong with the convertToCSV.", {
          component: "CSVDownload convertToCSV()",
          flow: "convert aggregated data",
          userId: user.uid,
        });
      }
    } else {
      csvData.data = data;
    }
    const csvRows = [];

    const headers = Object.keys(csvData.data[0]);
    csvRows.push(headers.join(","));
    for (const row of csvData.data) {
      const values = headers.map((header) => {
        const val = row[header];
        return `"${val}"`;
      });
      csvRows.push(values.join(","));
    }

    return csvRows.join("\n");
  };

  const handleExport = async () => {
    try {
      const csv = (await convertToCSV()) as any;
      const csvFile = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      FileSaver.saveAs(csvFile, fileName);
      setPending(false);
    } catch (error) {
      errorLogger("Something went wrong with the handleExport.", {
        component: "CSVDownload handleExport()",
        flow: "export csv data and download it",
        userId: user.uid,
      });
    }
  };

  return (
    <>
      {data.length ? (
        <div>
          <button
            title="Download csv file"
            disabled={pending}
            onClick={handleExport}
            className="w-24 px-4 text-instant-teams-blue-Main"
          >
            <Tooltip text={pending ? "Preparing file, please wait." : tooltip}>
              <i
                title="download csv file"
                className={
                  pending
                    ? `fak fa-csv-download animate-pulse ${size} text-instant-teams-blue-Main`
                    : `fak fa-csv-download ${size} text-instant-teams-blue-Main`
                }
              ></i>
            </Tooltip>
          </button>
        </div>
      ) : null}
    </>
  );
}

export default CSVDownload;
