import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../providers/auth";
import GeneralNote from "../../../custom-ui/input-fields/modals/GeneralNote";
import GreenUnderlineHeading from "../../../custom-ui/headings/heading";
import Meta from "../../../custom-ui/meta/Meta";
import SeekerFiles from "../../../seeker/details/parts/SeekerFiles";
import SeekerNotesDisplay from "../../../seeker/details/parts/seekerNotes";
import Tooltip from "../../../custom-ui/tooltip/tooltip";
import { DisplayDate } from "../../../../util/timestamps/timestamp";
import { useEmploymentByEmployee } from "../../../../data/employee";
import { useUser } from "../../../../providers/database";
import { useJobOfferByEmployee } from "../../../../data/offers";
import { useJobPositionDetails } from "../../../../data/positions";
import useModal from "../../../../hooks/useModal";
import {
  collectionNames,
  subCollectionNames,
} from "../../../../data/dictionary/collectionNames";
import MessagingCenterLite from "../../../messages/MessagingCenterLite";
import AnalyticsLogger from "../../../../providers/analyticsLogger";
import { useEmployer } from '../../../../data/employer';

const EmployeeMessages = () => {
  const { userClaims } = useAuth();
  const params = useParams();
  const employeeId = params?.employeeId || "";
  const [employerId, setEmployerId] = useState(null);
  const { data: employer } = useEmployer(employerId || "");
  const { data: employmentRecord } = useEmploymentByEmployee(employeeId || "");
  const { data: employeeData, status: userDataStatus } = useUser(
    employmentRecord?.userId || "",
  );

  return (
    <div className="mb-36">
      <Meta title="Employee - Messages" />
      <div className="w-full h-screen">
        <MessagingCenterLite recipientId={employeeData?.id} />
      </div>
    </div>
  );
};

export default EmployeeMessages;
