/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useQuery,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  doc,
  deleteField,
  getDoc,
  getDocs,
  setDoc,
  collection,
  query,
  where,
  orderBy,
  addDoc,
  Timestamp,
  updateDoc,
  QueryDocumentSnapshot,
  limit,
} from "firebase/firestore";
import { db, createQuery } from "../providers/database";
import { SyncRequest } from "../dataTypes/academy";
import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";
import { AuthOrigin } from "../dataTypes/AuthOrigin";

export function useAuthOrigin(email: string, origin: string, trigger: boolean) {
  return useQuery(
    ["useAuthOrigin", email],
    createQuery(() =>
      query(
        collection(db, collectionNames.authOrigin),
        where("email", "==", email),
        where("origin", "==", origin),
        orderBy("lastUpdated"),
        limit(1),
      ),
    ),
    { enabled: !!email && !!origin && trigger },
  );
}

export function updateAuthOrigin(id: string, authOrigin: AuthOrigin) {
  return updateDoc(doc(db, collectionNames.authOrigin, id), authOrigin);
}

export const createAuthOrigin = (authOrigin: AuthOrigin) => {
  return addDoc(collection(db, collectionNames.authOrigin), authOrigin);
};
