/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useQuery,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  doc,
  getDocs,
  collection,
  query,
  where,
  orderBy,
  addDoc,
  limit,
  deleteDoc,
} from "firebase/firestore";

import { db, createQuery } from "../providers/database";
import { SyncRequest, EnrollmentProcess } from "../dataTypes/academy";
import { collectionNames } from "./dictionary/collectionNames";

export function createSyncRequest(request: SyncRequest) {
  return addDoc(collection(db, "AcademySync"), request);
}

export function createEnrollmentProcess(request: EnrollmentProcess) {
  return addDoc(collection(db, "EnrollmentProcess"), request);
}

// Temporary List of all Components
export function useComponents(name: string) {
  return useQuery(
    ["components"],
    createQuery(() =>
      query(
        collection(db, "AcademyAssessments"),
        where("status", "!=", "archived"),
        orderBy("status"),
        orderBy(name),
      ),
    ),
    { enabled: !!name },
  );
}

export function useComponent(id: string) {
  return useQuery(
    ["component", { id }],
    createQuery(() => doc(db, collectionNames.academyComponents, id)),
    { enabled: !!id },
  );
}

export function useSkills() {
  return useQuery(
    ["skills"],
    createQuery(() =>
      query(
        collection(db, "AcademyAssessments"),
        where("visable", "==", "active"),
        orderBy("name"),
      ),
    ),
  );
}

export async function assignBadgeAssessment(badgeAssessment: {
  assessmentId: string;
  average: number;
  content_type_name: string;
  email: string;
  full_name: string;
  status: string;
  manuallyAssessed: boolean;
  manuallyAssessedDate: object;
  assessedBy: string;
}) {
  const { assessmentId, email } = badgeAssessment ?? {};
  const enrollmentSnapshot = await getDocs(
    query(
      collection(db, collectionNames.enrollments),
      where("email", "==", email),
      where("assessmentId", "==", assessmentId),
      limit(1),
    ),
  );

  const isNotAssigned = enrollmentSnapshot.empty;
  if (isNotAssigned) {
    const enrollment = {
      ...badgeAssessment,
      created: new Date().toISOString(),
    };
    console.log(enrollment);
    return addDoc(collection(db, collectionNames.enrollments), enrollment);
  }
}

export function useImports(assessmentId: string) {
  return useQuery(
    ["imports listing", assessmentId],
    createQuery(() =>
      query(
        collection(db, "EnrollmentsSync"),
        where("assessmentId", "==", assessmentId),
        orderBy("timestamp", "desc"),
        limit(20),
      ),
    ),
    { enabled: !!assessmentId },
  );
}

export function useEnrollmentSyncFailures() {
  return useQuery(
    ["Enrollment Sync Failures"],
    createQuery(() =>
      query(
        collection(db, "EnrollmentsSync"),
        where("err", "==", true),
        orderBy("timestamp", "desc"),
      ),
    ),
  );
}