import { useEffect, useState } from "react";
import { getLookupLabel } from '../../util/search/getLookupLabel';

function InfoCard({
  name,
  models,
  id
}:{
  name: string;
  models: string[];
  id: string;
}) { 
  function getModels(model: string) {
    switch (model) {
      case "WFH":
        return "Remote";
      case "PHY":
        return "On-Site";
      case "HYB":
        return "Hybrid";
      default:
        return model;
  }}
  return (
    <div className="">
      <p className='mb-2 font-bold uppercase'>{name}</p>
      {models.length > 0 && models.map((model, index) => {
        return <p className='font-bold' key={index}>{getModels(model)}</p>
      })}
      <p className='mt-2 w-full text-center font-medium text-instant-teams-blue-Main'><a href={`/seekers/${id}/overview`} target={"_blank"}>View Seeker</a></p>
    </div>
  );
}

export default InfoCard;
