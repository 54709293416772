import React, { useState, Fragment, useEffect } from "react";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import useModal from "../../../hooks/useModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import DisplaySkillNames from "../../custom-ui/DisplaySkill/DisplaySkillNames";
import { getCannedSkills, getTACTagsSkills } from "../../../data/getSkills";
import { updateSkillByUser, fetchSkillsByUserId } from "../../../data/user";

type SkillManagementProps = {
    userId: string;
    tacView?: boolean;
};

const SkillManagement = ({ userId, tacView = false }: SkillManagementProps) => {
    const [userInputSkillValue, setUserInputSkillValue] = useState("");
    const [skillSuggestionList, setSkillSuggestionList] = useState([]);
    const [tacSkillSuggestionList, setTacSkillSuggestionList] = useState([]);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [skills, setSkills] = useState([]);
    const [tacVerifiedSkills, setTacVerifiedSkills] = useState([]);
    const [tacTags, setTacTags] = useState([]);
    const { isShowing: showSkillManageDialog, toggle: toggleSkillManageDialog } = useModal();
    const [activeSkillList, setActiveSkillList] = useState("skills");
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e: any) => {
        const value = e.target.value;
        const suggestionList = activeSkillList === "tacTags"?tacSkillSuggestionList:skillSuggestionList;
        if (value.includes(',')) {
            const skill = value.split(',')[0].trim();
            if (skill && !suggestionList.includes(skill)) {
                addSkill(skill);
            }
            setUserInputSkillValue("");
        } else {
            setUserInputSkillValue(value);
            if (value) {
                const filtered = suggestionList.filter((skill: string) => {
                    const lowerCaseSkill = skill.toLowerCase();
                    const lowerCaseValue = value.toLowerCase();
                    return lowerCaseSkill.split(' ').some((word: string) => word.startsWith(lowerCaseValue)) || lowerCaseSkill == lowerCaseValue;
                });
                setFilteredSuggestions(filtered);
            } else {
                setFilteredSuggestions([]);
            }
        }
    };


    const handleSuggestionClick = (suggestion: string) => {
        const currentSkills = getCurrentSkills();
        const setCurrentSkills = getCurrentSkillsSetter();

        if (!currentSkills.some((skill: any) => skill.skill === suggestion)) {
            setCurrentSkills([...currentSkills, { skill: suggestion, customSkill: false }]);
        }
        setUserInputSkillValue("");
        setFilteredSuggestions([]);
    };

    const removeSkill = (skillToRemove: any) => {
        const currentSkills = getCurrentSkills();
        const setCurrentSkills = getCurrentSkillsSetter();

        setCurrentSkills(currentSkills.filter((skill: any) => skill.skill !== skillToRemove.skill));
    };

    const addSkill = (skill: string) => {
        const currentSkills = getCurrentSkills();
        const setCurrentSkills = getCurrentSkillsSetter();

        if (skill && !currentSkills.some((s: any) => s.skill === skill)) {
            setCurrentSkills([...currentSkills, { skill: skill, customSkill: true }]);
        }
    };

    const getCurrentSkills = () => {
        switch (activeSkillList) {
            case "skills":
                return skills;
            case "tacVerifiedSkills":
                return tacVerifiedSkills;
            case "tacTags":
                return tacTags;
            default:
                return [];
        }
    };

    const getCurrentSkillsSetter = () => {
        switch (activeSkillList) {
            case "skills":
                return setSkills;
            case "tacVerifiedSkills":
                return setTacVerifiedSkills;
            case "tacTags":
                return setTacTags;
            default:
                return () => { };
        }
    };

    useEffect(() => {
        const fetchSkills = async () => {
            const skillData = await getCannedSkills();
            setSkillSuggestionList(skillData?.map((data: any) => data?.skill_name)??[]);
        };
        fetchSkills();
    }, []);

    useEffect(() => {
        const fetchSkills = async () => {
            const skillData = await getTACTagsSkills();
            setTacSkillSuggestionList(skillData?.map((data: any) => data?.skill_name)??[]);
        };
        fetchSkills();
    }, []);

    useEffect(() => {
        const userSkillList = async () => {
            const skillList = await fetchSkillsByUserId(userId);
            if (skillList) {
                setSkills(skillList?.seeker_tagged_skills ?? []);
                setTacVerifiedSkills(skillList?.tac_verified_skills ?? []);
                setTacTags(skillList?.tac_tags ?? [])
            }
        };
        userSkillList();
    }, []);

    const saveSkills = async () => {
        setLoading(true);
        const data = {
            Skills: {
                seeker_tagged_skills: skills,
                tac_verified_skills: tacVerifiedSkills,
                tac_tags: tacTags
            },
        };
        try {
            await updateSkillByUser(userId, data);
            setLoading(false);
            toggleSkillManageDialog();
        } catch (error) {
            setLoading(false);
        }
    };


    return (
        <div className="w-full">
            <GreenUnderlineHeading title={tacView ? "Seeker Tagged Skills" : "Tagged Skills"} />
            <div>
                {skills?.sort((a: any, b: any) => a.skill.localeCompare(b.skill)).map((data: any, index: number) => (
                    <Fragment key={index}>
                        <DisplaySkillNames
                            skillname={data.skill}
                            displayCloseIcon={false}
                            customSkill={data.customSkill}
                            removeSkill={() => removeSkill(data)}
                        />
                    </Fragment>
                ))}
            </div>
            <button
                className="py-1 px-4 text-l mt-7 uppercase justify-center mx-auto text-white bg-instant-teams-blue-Main rounded-lg border-0 hover:bg-instant-teams-blue-D1 focus:outline-none"
                onClick={() => { setActiveSkillList("skills"); toggleSkillManageDialog(); }}
            >
                Manage Skills
            </button>
            <GreenUnderlineHeading title="TAC Verified Skills" />
            <div>
                {tacVerifiedSkills?.sort((a: any, b: any) => a.skill.localeCompare(b.skill))?.map((data: any, index: number) => (
                    <Fragment key={index}>
                        <DisplaySkillNames
                            skillname={data.skill}
                            displayCloseIcon={false}
                            customSkill={data.customSkill}
                            removeSkill={() => removeSkill(data)}
                        />
                    </Fragment>
                ))}
            </div>
            {tacView && <button
                className="py-1 px-4 text-l mt-7 uppercase justify-center mx-auto text-white bg-instant-teams-blue-Main rounded-lg border-0 hover:bg-instant-teams-blue-D1 focus:outline-none"
                onClick={() => { setActiveSkillList("tacVerifiedSkills"); toggleSkillManageDialog(); }}
            >
                Manage Skills
            </button>}

            {tacView && <>
                <GreenUnderlineHeading title="TAC Tags" />
                <div>
                    {tacTags?.sort((a: any, b: any) => a.skill.localeCompare(b.skill))?.map((data: any, index: number) => (
                        <Fragment key={index}>
                            <DisplaySkillNames
                                skillname={data.skill}
                                displayCloseIcon={false}
                                customSkill={data.customSkill}
                                removeSkill={() => removeSkill(data)}
                            />
                        </Fragment>
                    ))}
                </div>
                <button
                    className="py-1 px-4 text-l mt-7 uppercase justify-center mx-auto text-white bg-instant-teams-blue-Main rounded-lg border-0 hover:bg-instant-teams-blue-D1 focus:outline-none"
                    onClick={() => { setActiveSkillList("tacTags"); toggleSkillManageDialog(); }}
                >
                    Manage Tags
                </button>
            </>}

            {/* Manage Skill-----> delete or add skill */}
            {showSkillManageDialog && (
                <DialogWrapper title="Manage Skills" onClose={() => toggleSkillManageDialog()} displayCloseIcon={true}>
                    <>
                        <p className="mt-[-10px] text-[13px]">Add Relevant Skills</p>
                        <div className="w-full">
                            {getCurrentSkills()?.map((data: any, index: number) => (
                                <Fragment key={index}>
                                    <DisplaySkillNames
                                        skillname={data.skill}
                                        displayCloseIcon={true}
                                        customSkill={data.customSkill}
                                        removeSkill={() => removeSkill(data)}
                                    />
                                </Fragment>
                            ))}
                        </div>
                        <p className="mt-10 text-[12px]">Type skill followed by comma</p>
                        <div className="border-[1px] w-full flex justify-between mt-3 p-2 rounded-[6px] border-[#1A405C] relative">
                            <input
                                className="w-full border-transparent focus:border-transparent focus:ring-0 h-7"
                                placeholder="Add Skill"
                                value={userInputSkillValue}
                                onChange={handleInputChange}
                            />
                            {userInputSkillValue && (
                                <div className="flex items-center justify-center w-7 h-7 px-1 bg-gray-100 rounded-full cursor-pointer"
                                    onClick={() => { setUserInputSkillValue(""); setFilteredSuggestions("") }}
                                >
                                    <FontAwesomeIcon icon={faTimes} color="#565656" />
                                </div>
                            )}

                        </div>
                        {filteredSuggestions.length > 0 && (
                            <div className="bg-white border border-gray-300 w-full left-0 mt-1 max-h-40  overflow-y-auto rounded-md shadow-lg z-50">
                                {filteredSuggestions?.map((suggestion: any, index: number) => (
                                    <div
                                        key={index}
                                        className="p-2 hover:bg-gray-200 cursor-pointer"
                                        onClick={() => handleSuggestionClick(suggestion)}
                                    >
                                        {suggestion}
                                    </div>
                                ))}
                            </div>
                        )}
                        {filteredSuggestions.length === 0 && (<div className="mt-5">
                            <button
                                onClick={saveSkills}
                                className="py-2 w-full px-4 text-md mt-7 justify-center mx-auto text-white bg-instant-teams-blue-Main rounded-lg border-0 hover:bg-instant-teams-blue-D1 focus:outline-none"
                            >
                                {loading ? 'Saving...' : 'Save'}
                            </button>
                        </div>)}
                    </>
                </DialogWrapper>

            )}
        </div>
    );
};

export default SkillManagement;
