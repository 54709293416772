//  this might be best used as an npm package to be shared between cloud functions and client apps.
type FolderNames = {
  images: string;
  jobDescriptions: string;
  resume: string;
  offerLetters: string;
  accelerators: string;
  acceleratorFiles: string;
};

const folderNames: FolderNames = {
  images: "images",
  jobDescriptions: "jobDescriptions",
  resume: "resumes",
  offerLetters: "offerLetters",
  accelerators: "accelerator",
  acceleratorFiles: "files",
};

export default folderNames;
