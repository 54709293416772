import useApplicationRecordings from "../../hooks/useApplicationRecordings";
import WedgeSpinner from "../spinners/wedges-spinner";
import SeekerEInterviewItem from "./SeekerEInterviewItem";
function SeekerEInterview({
  applicationId,
  jobId,
}: {
  applicationId: string;
  jobId: string;
}) {
  const { recordings, loading, error } = useApplicationRecordings(
    applicationId,
    jobId,
  );

  if (loading) {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <WedgeSpinner />
      </div>
    );
  }
  return (
    <div key={applicationId}>
      <div className="mb-5 m-4">
        <p className="">
          E-interviews are the perfect opportunity to showcase your personality,
          professionalism, and preparedness! Please complete the required set of
          questions below.
        </p>
      </div>
      <div className="">
        {recordings &&
          recordings.map((recording: any, index: number) => {
            return <SeekerEInterviewItem recording={recording} index={index} />;
          })}
      </div>
    </div>
  );
}

export default SeekerEInterview;
