import { useState, useEffect } from "react";
import { useUser } from "../../../../providers/database";
import PhoneNumber from "../../../custom-ui/input-fields/phoneNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { requireAuth, useAuth } from "../../../../providers/auth";
import { faLocationDot } from "@fortawesome/pro-duotone-svg-icons";
import { faPhone, faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import Affiliations from "../../../user/details/parts/Affiliations";
import { useLookupCode } from "../../../../data/lookup";

const SeekerInfo = ({
  userId,
  imageVisible,
}: {
  userId: string;
  imageVisible: boolean;
}) => {
  const { userClaims } = useAuth();
  const [id, setId] = useState(userId);
  const { data: seekerData, error: seekerError } = useUser(id);
  const [referredBy, setRefer] = useState("");
  const [referredGroup, setReferGroup] = useState("");
  const { data: referral } = useLookupCode(referredBy);

  useEffect(() => {
    setRefer(seekerData?.referredBy || "");
    setReferGroup("partner");
  }, [seekerData]);

  return (
    <>
      <div className="w-full flex h-full">
        {imageVisible && (
          <div className="w-1/8 pt-2 pr-16">
            <img
              src={seekerData?.photoURL || "/images/default_user.png"}
              alt={seekerData?.preferredName}
              className={`rounded-full w-36 max-h-36 border-solid border-4 mx-auto ${
                seekerData?.status !== "active"
                  ? " border-instant-teams-red-Main"
                  : "border-instant-teams-green-D1"
              }`}
            />
          </div>
        )}
        <div className="w-7/8 pt-2">
          <div className=" text-2xl font-black text-instant-teams-teal-D1 uppercase">
            {seekerData?.name}
          </div>
          <div className="flex mt-4 w-full font-black text-instant-teams-teal-D1">
            <Affiliations userId={id} background={"lightBackground"} />
          </div>
          <div className="flex mt-4">
            {seekerData?.phone && (
              <div className="flex items-center text-instant-teams-teal-D1 mr-4">
                <FontAwesomeIcon
                  icon={["fas", "phone"]}
                  className="h-5 w-5 pr-2 "
                  swapOpacity
                />
                <PhoneNumber number={seekerData?.phone} />
              </div>
            )}
            {seekerData?.email && (
              <div className="flex items-center text-instant-teams-teal-D1 mr-4">
                <FontAwesomeIcon
                  icon={["fas", "envelope"]}
                  className="h-5 w-5 pr-2 "
                  swapOpacity
                />
                {seekerData?.email}
              </div>
            )}
          </div>
          <div className="flex mt-4">
            {seekerData?.address && (
              <div className="flex items-center text-instant-teams-teal-D1 mr-4">
                <FontAwesomeIcon
                  icon={["fas", "location-dot"]}
                  className="h-5 w-5 pr-2 "
                  swapOpacity
                />
                {seekerData?.address?.standardizedAddress}
              </div>
            )}
          </div>
          <div className="flex mt-4">
            {seekerData?.referredBy && (
              <div className="flex items-center text-instant-teams-teal-D1 mr-4">
                <FontAwesomeIcon
                  icon={["fas", "bullhorn"]}
                  className="h-5 w-5 pr-2 "
                  swapOpacity
                />
                {referral?.[0]?.label}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default requireAuth(SeekerInfo);
