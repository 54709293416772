import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useEmployer, useEmployerDepartments } from "../../../data/employer";
import { useJobDescription } from "../../../data/jobDescriptions";
import { upload } from "../../../storage/storage";
import { writeFileLocation } from "../../../data/jobDescriptions";
import { friendlyFirebaseError } from "../../../providers/firebase-errors/friendlyFirebaseError";
import AutoSaveText from "../../custom-ui/input-fields/auto-save-field";
import AutoSaveWysiwyg from "../../custom-ui/input-fields/auto-save-wysiwyg";
import AutoSaveSelectGeneric from "../../custom-ui/input-fields/auto-save-select-generic";
import DragAndDropVariantA from "../../custom-ui/drag-and-drop/DragAndDropFile";
import { collectionNames } from "../../../data/dictionary/collectionNames";
import folderNames from "../../../storage/dictionary/folderNames";
import { useGlobalState } from "../../../providers/globalState/GlobalStateProvider";
import JobListingFiles from "../parts/JobListingFiles";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import { isLocked } from "../../../util/jobs/jobUtils";

function JobDescriptionPage() {
  const params = useParams();
  const jobId = params.id ?? "";

  const { globalState, setGlobalState } = useGlobalState();
  const [file, setFile] = useState();
  const [formAlert, setFormAlert] = useState(null);
  


  const confirmUpload = () => {
    upload(file, folderNames.jobDescriptions, `${jobId}_${file?.name}`).then(
      (fileUrl) => {
        writeFileLocation(jobId, {
          label: file.name,
          type: file.type,
          link: fileUrl,
        })
          .then((docRef) => {
            setFile();
          })
          .catch((error: Error) => {
            setFormAlert({
              type: "error",
              message: friendlyFirebaseError(error),
            });
          })
          .catch((error: Error) => {});
      },
    );
  };

  const { data: job = {}, isLoading  } = useJobDescription(jobId);

  const { data: employer = {} } = useEmployer(job?.employer);
  const { data: dept = {} } = useEmployerDepartments(job?.employer);

  const isFieldLocked = isLocked(job.status);
  useEffect(() => {
    const title = job?.title ?? "";
    const employerName = employer?.displayName ?? "";
    if (title && employerName) {
      setGlobalState({
        ...globalState,
        pageTitle: employerName + " - " + title,
      });
    }
  }, [job?.title, employer?.displayName]);

  return (
    <>{
      !isLoading&&(

         <div>
      <div className="w-full place-content-center">
        <div className="mb-2 w-1/2 float-left pr-5">
          <GreenUnderlineHeading
            title="Job Title"
            showLockIcon={true}
            isLocked={isFieldLocked}
          />
          <AutoSaveText
            value={job?.title}
            collection={collectionNames.jobDescriptions}
            recId={jobId}
            required={true}
            name="title"
            display="Job Title"
            placeholder={"Enter Job Title"}
            directions=""
            readOnly={isFieldLocked}
          />
        </div>
        <div className="mb-2 w-1/2 float-right pl-5">
          <GreenUnderlineHeading title="Job Department" />
          <AutoSaveSelectGeneric
            value={job?.department??dept[0]?.id}
            options={dept}
            optionValue={"id"} //  key used to get the value from options object. By default it is "code"
            collection={collectionNames.jobDescriptions}
            recId={jobId}
            required={false}
            name="department"
            display="Department"
            placeholder="Select a Department"
            directions=""
          />
        </div>
        <div className="w-full clear-both">
          <div className="mb-8 w-1/2 float-left pr-4">
            {formAlert && (
              <div className="mb-4 text-red-600">{formAlert.message}</div>
            )}
            <GreenUnderlineHeading title="Job Description Upload" />
            <div className="text-center mb-2">
              <DragAndDropVariantA setFile={setFile} />
              <div className="relative flex justify-center">
                {file && (
                  <button
                    className="absolute bottom-3 mt-4  py-2 px-4 ml-[50%]  text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                    onClick={confirmUpload}
                  >
                    Upload File
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="mb-8 w-1/2 float-right pl-4">
            <GreenUnderlineHeading title="File List" />
            <div className="text-center mb-2">
              <JobListingFiles id={jobId} />
            </div>
          </div>
        </div>
        <div className="w-full clear-both">
          {job?.clientDescription && (
            <div className="mb-2">
              <AutoSaveWysiwyg
                value={job?.clientDescription}
                rows={10}
                collection={collectionNames.jobDescriptions}
                recId={jobId}
                required={false}
                name="clientDescription"
                display="Client Provided Job Description"
                placeholder={"Client Provided Job Description"}
                directions=""
                readOnly={false}
                editorStyle={{ height: "240px" }}
              />
            </div>
          )}
          <div className="mb-2">
            <AutoSaveWysiwyg
              value={job?.description?.dayInTheLife}
              rows={10}
              collection={collectionNames.jobDescriptions}
              recId={jobId}
              required={false}
              name="description.dayInTheLife"
              display="Day in the Life"
              placeholder={"Day in the Life"}
              directions=""
              readOnly={false}
              editorStyle={{ height: "240px" }}
            />
          </div>

          <div className="mb-2">
            <AutoSaveWysiwyg
              value={job?.description?.training}
              rows={10}
              collection={collectionNames.jobDescriptions}
              recId={jobId}
              required={false}
              name="description.training"
              display="Training"
              placeholder={"Training"}
              directions=""
              readOnly={false}
              editorStyle={{ height: "240px" }}
            />
          </div>
          <div className="mb-2">
            <AutoSaveWysiwyg
              value={job?.description?.mustHaves}
              rows={10}
              collection={collectionNames.jobDescriptions}
              recId={jobId}
              required={false}
              name="description.mustHaves"
              display="Must Haves"
              placeholder={"Muct Haves"}
              directions=""
              readOnly={false}
              editorStyle={{ height: "240px" }}
            />
          </div>
          <div className="mb-2">
            <AutoSaveWysiwyg
              value={job?.description?.benefits}
              rows={10}
              collection={collectionNames.jobDescriptions}
              recId={jobId}
              required={false}
              name="description.benefits"
              display="Job Benefits"
              placeholder={"Job Benefits"}
              directions=""
              readOnly={false}
              editorStyle={{ height: "240px" }}
            />
          </div>
        </div>
        <div></div>
      </div>
    </div>
      ) 
    }
    </>
   
  );
}

export default JobDescriptionPage;
