import DisplayBadges from "../../custom-ui/badge/displayBadge";
import { useComponent } from "../../../data/academy";
import { useEnrollmentsByEmailAndAssessment } from "../../../data/enrollments";
function ExternalBadgesModalView({
  skill,
  applicant,
}: {
  skill: any;
  applicant: any;
}) {
  const { data: assessment } = useComponent(skill.assessmentId);
  const { data: enrollments } = useEnrollmentsByEmailAndAssessment(
    applicant?.email,
    assessment?.id?.toString(),
  );

  const enrollment = enrollments?.[0];

  return (
    <div className=" col-span-1">
      <DisplayBadges
        key={skill?.id}
        enrollment={enrollment}
        assessmentId={skill?.assessmentId}
      />
      <p className="w-full text-center text-instant-teams-teal-D2 font-bold text-sm">
        {assessment?.displayName || assessment?.name}
      </p>
    </div>
  );
}
export default ExternalBadgesModalView;
