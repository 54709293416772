import { useApplicationInterviewQuestionAnswer } from "../../../data/applications";
import AutoSaveRadioGrade from "../../custom-ui/input-fields/auto-save-radio-grade";
import { useEffect, useRef } from "react";
import {
  collectionNames,
  subCollectionNames,
} from "../../../data/dictionary/collectionNames";
import { useAuth } from "../../../providers/auth";
import { deleteInterviewQuestion } from "../../../data/applications";
import { updateAutoSave } from "../../../data/utility";
import { updateStatusHistory } from "../../../data/utility";
import { Timestamp } from "firebase/firestore";

function InterviewItemAnswer({
  question,
  appId,
  type,
}: {
  question: any;
  appId: string;
  type: string;
}) {
  const { userClaims, user } = useAuth();
  const { data: appInterviewAnswer } = useApplicationInterviewQuestionAnswer(
    appId,
    question?.id,
  );

  const collection =
    collectionNames.applications +
    "/" +
    appId +
    "/" +
    subCollectionNames.interviewQuestions;

  const videoAnswerRef = useRef(null);
  const videoQuestionRef = useRef(null);
  useEffect(() => {
    videoQuestionRef?.current?.load();
  }, [question?.id]);
  useEffect(() => {
    videoAnswerRef?.current?.load();
  }, [appInterviewAnswer]);

  const hasAnswer =
    appInterviewAnswer !== null && appInterviewAnswer?.answer !== null;

  async function handleDelete() {
    alert("Are you sure you want to delete this interview question?");
    await deleteInterviewQuestion(appId, question?.id);
    const history = {
      alteredBy: user.id,
      effectiveDate: Timestamp.now(),
      from: "underReview",
      status: "applied",
    };
    await updateStatusHistory(collectionNames.applications, appId, history);
    await updateAutoSave(collectionNames.applications, appId, {
      status: "applied",
    });
  }

  return (
    <div className="p-4 mx-auto w-full h-full text-center">
      {/* Audio Prompt */}
      {question?.promptType === "VOICE" && (
        <audio controls className="w-1/2 mx-auto my-5 p-2">
          <source src={question?.audioURL} type="audio/mpeg" />
        </audio>
      )}
      {/* Video Prompt */}
      {question?.promptType === "VID" && (
        <video controls ref={videoQuestionRef} className="w-full p-4">
          <source src={question?.videoURL} type="video/mp4" />
        </video>
      )}
      <p className="flex uppercase justify-center text-instant-teams-teal-Main font-semibold">
        Seeker Response
      </p>
      {/* Text response */}
      {hasAnswer && question?.type === "ESS" ? (
        <div className="w-full shadow-input-field py-5 rounded-lg text-start px-5 text-instant-teams-teal-Main font-semibold">
          {appInterviewAnswer?.answer}
        </div>
      ) : null}
      {/* Audio response */}
      {hasAnswer && question?.type === "VOICE" && (
        <div className="mx-auto w-1/2 my-2">
          <audio
            className="w-full h-96 aspect-video p-2  "
            key={appInterviewAnswer?.id}
            src={appInterviewAnswer?.answer}
            controls
          />
        </div>
      )}

      {/* Video response */}
      {hasAnswer && question?.type === "VID" && (
        <video
          controls
          className="w-full h-96 aspect-video p-4"
          ref={videoAnswerRef}
        >
          <source src={appInterviewAnswer?.answer} type="video/mp4" />
        </video>
      )}
      {!appInterviewAnswer || appInterviewAnswer?.answer === null ? (
        <div className="w-full text-lg font-bold text-instant-teams-teal-Main">
          No Answer Provided
        </div>
      ) : null}

      <div className="w-full mt-4">
        {appInterviewAnswer?.answer !== null &&
        appInterviewAnswer?.answer !== undefined &&
        userClaims?.claims?.type === "A" ? (
          <AutoSaveRadioGrade
            name="grade"
            initialValue={appInterviewAnswer?.grade || 0}
            collection={collection}
            id={question?.id}
          />
        ) : null}
        <hr />
      </div>

      {(userClaims?.claims?.role === "sadmin" ||
        userClaims?.claims?.role === "tac") &&
      appInterviewAnswer?.answer !== null &&
      appInterviewAnswer?.answer !== undefined ? (
        <div className="w-full mt-4">
          <button
            className={`w-full cursor-pointer text-white bg-instant-teams-red-Main rounded mb-4 uppercase font-semibold text-sm py-2`}
            onClick={handleDelete}
          >
            Reset - removes answer and resets to applied
          </button>
        </div>
      ) : null}
    </div>
  );
}
export default InterviewItemAnswer;
