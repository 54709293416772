import React, { useEffect, useState } from "react";
import Meta from "../components/custom-ui/meta/Meta";
import PageLoader from "../components/custom-ui/pageLoader/PageLoader";
import { handleRecoverEmail, handleVerifyEmail } from "../providers/auth";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

function FirebaseActionPage(props) {
  // const router = useRouter();
  const params = useParams();
  const navigate = useNavigate();
  const [formAlert, setFormAlert] = useState();

  useEffect(() => {
    const { mode, oobCode } = useSearchParams;

    // Do nothing if mode param is undefined.
    // Page may have been pre-rendered by server so won't have query values
    // until it's actually run on client (as is the case if using Next.js)
    if (!mode) return;

    // Take action based on Firebase "mode" query param
    switch (mode) {
      case "resetPassword":
        // Redirect to change password page
        navigate(`/auth/changepass?oobCode=${oobCode}`, { replace: true });
        break;
      case "recoverEmail":
        // Reset to original email
        handleRecoverEmail(oobCode)
          .then((originalEmail) => {
            setFormAlert({
              type: "success",
              message: `Your email has been set back to ${originalEmail}. We've also sent you a password reset email so that you can change your password if you think someone may have access to your account.`,
            });
          })
          .catch((error) => {
            setFormAlert({
              type: "error",
              message: error.message,
            });
          });
        break;
      case "verifyEmail":
        // Verify email in Firebase
        handleVerifyEmail(oobCode)
          .then(() => {
            setFormAlert({
              type: "success",
              message: `Your email has been verified. You may now close this window.`,
            });
          })
          .catch((error) => {
            setFormAlert({
              type: "error",
              message: error.message,
            });
          });
        break;
      default:
        setFormAlert({
          type: "error",
          message: "Invalid mode parameter",
        });
    }
  }, [navigate, params]);

  return (
    <div className="pt-36">
      <Meta title="Firebase Action" />
      <PageLoader>
        {formAlert && (
          <div
            className={
              "p-10 text-center" +
              (formAlert.type === "error" ? " text-red-600" : "") +
              (formAlert.type === "success" ? " text-green-600" : "")
            }
          >
            {formAlert.message}
          </div>
        )}
      </PageLoader>
    </div>
  );
}

export default FirebaseActionPage;
