import Top10Courses from "../../../charts/Top10Courses";
import AcademyRegistrationTrends from "../../../charts/AcademyRegistrationTrends";
import AcademyAssessmentsByStatus from "../../../charts/AcademyAssessmentsByStatus";
import Meta from '../../custom-ui/meta/Meta';

function AssessementPageDashboard() {
  return (
    <>
      <div className="flex flex-wrap">
        <Meta title="Learning & Development - Dashboard" />
        <div className="flex justify-between p-4 w-full pt-4">
          <div className="w-1/3 pr-10">
            <Top10Courses />
          </div>
          <div className="w-1/3 pr-10">
            <AcademyRegistrationTrends />
          </div>
          <div className="w-1/3 pr-10">
            <AcademyAssessmentsByStatus />
          </div>
        </div>
      </div>
    </>
  );
}
export default AssessementPageDashboard;
