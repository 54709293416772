import { User } from "../../../dataTypes/User";
import Tooltip from "../../custom-ui/tooltip/tooltip";
import SendEmail from "../../custom-ui/emails/send-email";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faShield,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import useModal from "../../../hooks/useModal";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import NewConversationModal from "../../messages/NewConversationModal";
import { updateAutoSave } from "../../../data/utility";
import { TailwindHoverEnlarge } from "../../../util/tailwind/tailwind-styles";
import { Link } from "react-router-dom";
function DashboardUserListing({ user }: { user: User }) {
  const { isShowing: showConversationModal, toggle: toggleConversationModal } =
    useModal();
  const { isShowing: showRemoveUserModal, toggle: toggleRemoveUserModal } =
    useModal();

  const handleActivateUser = async () => {
    // There is no way to have a user but not have a user.id
    await updateAutoSave("Users", user.id!, {
      status: "active",
    });
  };

  const handleRemoveUser = async () => {
    // There is no way to have a user but not have a user.id
    await updateAutoSave("Users", user.id!, {
      status: "removed",
    });
    toggleRemoveUserModal();
  };

  return (
    <>
      <div
        className={
          TailwindHoverEnlarge(104) +
          "w-[24%] mx-1 my-5 shadow-lg w-76 bg-white rounded-lg min-h-full"
        }
      >
        <div className="h-44 w-full   bg-instant-teams-teal-Main pt-2 pb-4  rounded-t-lg ">
          <Link to={`/users/${user?.id}/user-info`}>
            <div className="flex  flex-col relative text-center">
              <span className="inline-block px-2 py-1  font-bold leading-none text-instant-teams-teal-Main bg-instant-teams-tags-CREAM rounded-full absolute top-2 right-4 ">
                <p className="uppercase font-semibold">{user?.claims?.type}</p>
              </span>
              <p className="uppercase text-white py-1">
                {user?.name ? user?.name : "No Name"}
              </p>
              <p className="uppercase text-white py-1">
                {user?.phone ? user?.phone : "Missing Number"}
              </p>
              <p className="uppercase text-white py-1 text-xs">
                {user?.email ? <span>{user?.email}</span> : "Missing Number"}
              </p>
              <p className="uppercase text-white py-1 text-xs">
                {user?.address?.standardizedAddress
                  ? user?.address?.standardizedAddress
                  : "Missing Address"}
              </p>
              <div className="flex items-center justify-center space-x-5 py-2">
                <Tooltip text="Verified Email?">
                  <div className="space-x-1">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="h-5 w-5  text-instant-teams-tags-CREAM"
                    />
                    {user.emailVerified ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="h-5 w-5 text-instant-teams-green-D1"
                        swapOpacity
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faXmark}
                        className="h-5 w-5 text-instant-teams-red-Main"
                        swapOpacity
                      />
                    )}
                  </div>
                </Tooltip>

                <Tooltip text="MFA Active?">
                  <div className="space-x-1">
                    <FontAwesomeIcon
                      icon={faShield}
                      className="h-5 w-5 mx-auto text-instant-teams-tags-CREAM"
                    />
                    {user?.mfa ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="h-5 w-5 text-instant-teams-green-D1"
                        swapOpacity
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faXmark}
                        className="h-5 w-5 text-instant-teams-red-Main"
                        swapOpacity
                      />
                    )}
                  </div>
                </Tooltip>
              </div>
            </div>
          </Link>
        </div>
        <div className="flex flex-col py-4 ">
          <button
            onClick={toggleConversationModal}
            className=" mx-auto px-12 py-2 text-instant-teams-blue-Main font-bold border border-instant-teams-blue-Main rounded  hover:text-instant-teams-green-D2 focus:outline-none"
          >
            Message User
          </button>
          <div className="py-4 flex items-center justify-center space-x-4">
            <button
              onClick={handleActivateUser}
              className="py-2 px-4 text-white bg-instant-teams-blue-Main rounded border-0 hover:border  hover:bg-instant-teams-teal-Main  focus:outline-none "
            >
              Activate
            </button>
            <button
              onClick={toggleRemoveUserModal}
              className="py-2 px-4  rounded hover:text-white  hover:border  hover:bg-instant-teams-teal-Main border border-instant-teams-blue-Main bg-white text-instant-teams-blue-Main focus:outline-none"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
      {showConversationModal && (
        <DialogWrapper
          title="New Conversation"
          onClose={toggleConversationModal}
        >
          <NewConversationModal
            toggle={toggleConversationModal}
            recipientId={user?.id}
          />
        </DialogWrapper>
      )}
      {showRemoveUserModal && (
        <DialogWrapper title="Account Removal" onClose={toggleRemoveUserModal}>
          <div className="">
            <p className="py-4 mb-4">
              Are you certain you want to remove{" "}
              {user?.name ? user?.name : "No Name"}?
            </p>
            <div className="py-2 flex justify-center space-x-5">
              <button
                onClick={handleRemoveUser}
                className="py-2 px-10 text-white bg-instant-teams-blue-Main rounded border-0 hover:border  hover:bg-instant-teams-teal-Main  focus:outline-none"
              >
                Yes
              </button>
              <button
                onClick={toggleRemoveUserModal}
                className="py-2 px-10  rounded hover:text-white  hover:border  hover:bg-instant-teams-teal-Main border border-instant-teams-blue-Main bg-white text-instant-teams-blue-Main focus:outline-none"
              >
                No
              </button>
            </div>
          </div>
        </DialogWrapper>
      )}
    </>
  );
}
export default DashboardUserListing;
