import { useEffect, useState } from "react";
import { sanitizeMarkup } from "../../util/sanitization/sanitize-html";
import { useComponent } from "../../data/academy";
import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";
import { createEnrollmentProcess } from "../../data/academy";
import { Timestamp } from "firebase/firestore";
import { useAuth } from "../../providers/auth";
import toast from "react-hot-toast";
import ToastAlert from "../custom-ui/toast-alert/ToastAlert";
import { DisplayDate } from "../../util/timestamps/timestamp";
import { useUser } from "../../data/user";
import GreenUnderlineHeading from "../custom-ui/headings/heading";
import { DisplayISO } from "../../util/timestamps/timestamp";
import "../../styles/dangerouslySet.css";
import EnrollmentResults from "./enrollmentResults";
import { getLevel } from '../../util/assessments/level';

function EnrollmentDetails({ enrollment }: { enrollment: any }) {
  const [manualUser, setManualUser] = useState("");
  const { data: assessment } = useComponent(enrollment.assessmentId);
  const [overlayImage, setOverlayImage] = useState(null);
  const [status, setStatus] = useState("active");
  const { data: manualUserData } = useUser(manualUser);

  useEffect(() => {
    if (assessment?.tier) {
      if (
        assessment.tier.one <= enrollment.average &&
        assessment.tier.two > enrollment.average
      ) {
        setOverlayImage("/images/overlays/one.png");
      } else if (
        assessment.tier.two <= enrollment.average &&
        assessment.tier.three > enrollment.average
      ) {
        setOverlayImage("/images/overlays/two.png");
      } else if (assessment.tier.three <= enrollment.average) {
        setOverlayImage("/images/overlays/three.png");
      }
    }
  }, [assessment]);

  useEffect(() => {
    if (enrollment && enrollment?.assessedBy) {
      setManualUser(enrollment.assessedBy);
    }
  }, [enrollment]);

  console.log("enrollment", enrollment);

  return (
    <div className="w-full">
      <GreenUnderlineHeading title={assessment?.name} />
      <div className="flex flex-col">
        <p>Last Modified: {DisplayISO(enrollment.modified)}</p>
        <p>Last Activity: {DisplayISO(enrollment.last_activity)}</p>
        <p>
          Invitation Link:{" "}
          <a
            className="text-green-800"
            href={enrollment?.invitation_link}
            target={"_blank"}
          >
            LINK
          </a>
        </p>
        {enrollment?.average !== null && enrollment?.average !== undefined && (
          <>
            <p>
              Score:{" "}
              <span className="font-bold text-instant-teams-teal-Main">
                {enrollment?.average}
              </span>
            </p>
            <EnrollmentResults
              results={enrollment?.results[0]}
              name={assessment?.name}
            />
          </>
        )}
        {enrollment?.personality !== null &&
          enrollment?.personality !== undefined && (
            <>
              <p>
                Score:{" "}
                <span className="font-bold text-instant-teams-teal-Main">
                  {" "}
                  {enrollment?.personality}
                </span>
              </p>
              <p>
                Algorithm:{" "}
                <span className="font-bold text-instant-teams-teal-Main uppercase">
                  {" "}
                  {enrollment?.personality_algorithm}
                </span>
              </p>
              <div
                className="dangerouslyLT w-full px-2 text-lg mt-4"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  enrollment?.results[0].score_description,
                )}
              ></div>
            </>
          )}
      </div>
    </div>
  );
}
export default EnrollmentDetails;
