import { useAllActiveOffers } from "../../../../data/seeker";
import SeekerOfferListItem from "./seekerOfferListItem";

function SeekerOfferList({ id }: { id: string }) {
  const { data: offers } = useAllActiveOffers(id);

  return (
    <>
      <div className="w-full text-base text-black font-normal mt-4">
        {offers?.length !== 0 ? (
          offers?.map((row: any, index: string) => {
            return <SeekerOfferListItem entry={row} key={index} />;
          })
        ) : (
          <div className="text-lg text-center text-instant-teams-teal-Main w-full">
            No Offers
          </div>
        )}
      </div>
    </>
  );
}

export default SeekerOfferList;
