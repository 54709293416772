import { useParams } from "react-router-dom";
import { useLookup } from "../../../../data/lookup";
import {
  useJobDescription,
  useJobDescriptionSkills,
} from "../../../../data/jobDescriptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GreenUnderlineHeading from "../../../custom-ui/headings/heading";
import {
  getAllLookupLabels,
  getLookupLabel,
} from "../../../../util/search/getLookupLabel";

import { sanitizeMarkup } from "../../../../util/sanitization/sanitize-html";
import { toUSCurrency } from "../../../../util/helpers/toUSCurrency";
import { sortedCommaSeparatedString } from "../../../../util/strings/stringManipulation";
import SkillsList from "../../parts/SkillsList";
import { useFindPreScreenInformation } from "../../../../data/prescreen";
import "../../../../styles/dangerouslySet.css";
function ExternalJobInformation() {
  const NONE_SELECTED = "None Selected";
  const params = useParams();
  const jobId = params.id ?? "";
  const { data: job, status: jobStatus } = useJobDescription(jobId);
  const { data: model = [] } = useLookup("model");
  const { data: term = [] } = useLookup("term");
  const { data: classification = [] } = useLookup("classification");
  const { data: payType = [] } = useLookup("payType");

  const { data: hardware = [] } = useLookup("hardware");
  const { data: solutionTypes = [] } = useLookup("solutionType");
  const { data: network = [] } = useLookup("network");
  const { data: skills } = useJobDescriptionSkills(jobId);
  const { data: preeScreen } = useFindPreScreenInformation("compliance/gdpr");

  const allowedState = sortedCommaSeparatedString(job?.states?.allowed);
  const disallowedStates = sortedCommaSeparatedString(job?.states?.disallowed);
  const selectedSolutionType = getLookupLabel(
    job.solutionType,
    solutionTypes,
    NONE_SELECTED,
  );

  const selectedHardwareLabels = getAllLookupLabels(
    job?.technology?.hardware,
    hardware,
    "hardware",
    NONE_SELECTED,
  );
  const selectedNetworkLabels = getAllLookupLabels(
    job?.technology?.network,
    network,
    "network",
    NONE_SELECTED,
  );
  const workSettings = getAllLookupLabels(
    job?.information?.model,
    model,
    "model",
    NONE_SELECTED,
  );
  const workTerms = getAllLookupLabels(
    job?.information?.term,
    term,
    "term",
    NONE_SELECTED,
  );

  const selectedWorkTypes = getAllLookupLabels(
    job?.information?.classification,
    classification,
    "classification",
    NONE_SELECTED,
  );

  const selectedHours = job?.information?.hoursPerWeek
    ? `${job.information?.hoursPerWeek[0]} to ${job.information?.hoursPerWeek[1]} hours`
    : NONE_SELECTED;

  const payTypes = Object.keys(job?.information?.payType) ?? [];

  return (
    <div className="w-full min-h-screen">
      <div className="grid gap-8 grid-cols-3 md:grid-cols-3 mb-4 mt-4">
        {/* Left Col */}
        <div className="mb-2">
          <GreenUnderlineHeading title="number of seats" />
          <p className="-mt-5">{job?.seats ?? 1}</p>

          <GreenUnderlineHeading title="Solution Type" />
          <p className="-mt-5">{selectedSolutionType}</p>

          <GreenUnderlineHeading title="Work Settings" />
          <p className="-mt-5">{workSettings}</p>

          <GreenUnderlineHeading title="Work Term" />
          <p className="-mt-5">{workTerms}</p>

          <GreenUnderlineHeading title="Work Type" />
          <p className="-mt-5">{selectedWorkTypes}</p>

          <GreenUnderlineHeading title="Phone Support" />
          <p className="-mt-5">
            {job?.information?.phoneSupport ? "Yes" : "No"}
          </p>
        </div>
        {/* Middle Col */}
        <div>
          <GreenUnderlineHeading title="Hours Per Week" />
          <p className="-mt-5">{selectedHours}</p>

          <GreenUnderlineHeading title="Pay Details" />
          <div className="-mt-5">
            {payTypes.map((payTypeKey: string) => {
              const payTypeValue = job?.information?.payType[payTypeKey];
              if (Array.isArray(payTypeValue)) {
                return (
                  <p>
                    {getLookupLabel(payTypeKey, payType, "Not Found")}:{" "}
                    {toUSCurrency(payTypeValue[0])} -{" "}
                    {toUSCurrency(payTypeValue[1])}
                  </p>
                );
              }
            })}
          </div>
          <div>
            {payTypes.map((payTypeKey: string) => {
              if (payTypeKey === "COMM") {
                return (
                  <>
                    <p className="mt-2 font-semibold">Commission Details:</p>

                    <div
                      className="dangerously"
                      dangerouslySetInnerHTML={sanitizeMarkup(
                        job?.information?.payType["COMM"],
                      )}
                    ></div>
                  </>
                );
              }
            })}
          </div>
        </div>
        {/* Right Col */}
        <div>
          {job?.states?.allowed?.length > 0 ? (
            <>
              <GreenUnderlineHeading title="Allowed States" />
              <p className="-mt-5">{allowedState}</p>
            </>
          ) : null}
          {job?.states?.disallowed?.length > 0 ? (
            <>
              <GreenUnderlineHeading title="Disallowed States" />
              <p className="-mt-5">{disallowedStates}</p>
            </>
          ) : null}
          {job?.information?.gdprCompliance ? (
            <>
              <GreenUnderlineHeading title="EXCLUDE EU RESIDENTS" />
              {preeScreen && <p className="-mt-5">{preeScreen[0]?.question}</p>}
            </>
          ) : null}
        </div>
      </div>
      {/* Second Grid */}
      <div className="grid gap-8 grid-cols-3 md:grid-cols-3 mb-4 mt-4">
        <div className="mb-2 ">
          <GreenUnderlineHeading title="Hardware" />

          <p className="-mt-5">{selectedHardwareLabels}</p>
        </div>
        <div className="mb-2">
          <GreenUnderlineHeading title="Network" />

          <p className="-mt-5">{selectedNetworkLabels}</p>
        </div>
        <div className="mb-2.5 ">
          <GreenUnderlineHeading title="Minimum Connection Speed" />
          <div className="w-full flex">
            <div className="w-1/8 ">
              <FontAwesomeIcon
                className="mr-2   text-instant-teams-blue-Main"
                icon={"download"}
                size={"2x"}
              />
            </div>
            <div className="">
              <p className="font-semibold pt-3 text-instant-teams-blue-Main">
                {job?.technology?.networkSpeed?.download}
              </p>
            </div>
            <div className="w-1/8 pl-5 ">
              <FontAwesomeIcon
                className="mr-2  text-instant-teams-blue-Main"
                icon={"upload"}
                size={"2x"}
              />
            </div>
            <div className="">
              <p className="font-semibold pt-3 text-instant-teams-blue-Main">
                {job?.technology?.networkSpeed?.upload}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <SkillsList skills={skills} />
      </div>
    </div>
  );
}

export default ExternalJobInformation;
