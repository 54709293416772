import AutoSaveSelectByLookup from "../../custom-ui/input-fields/auto-save-select-by-lookup";
import AutoSaveNumber from "../../custom-ui/input-fields/auto-save-number";
import AutoSaveWysiwyg from "../../custom-ui/input-fields/auto-save-wysiwyg";
import { useInterviewQuestion } from "../../../data/interviewQuestion";
import { collectionNames } from "../../../data/dictionary/collectionNames";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";

function Template({ id, readOnly }: { id: string; readOnly: boolean }) {
  const { data: question = {}, status, error } = useInterviewQuestion(id);

  const editorStyle = {
    height: `150px`,
    overflow: "hidden",
    border: "1px solid #ccc",
    padding: "8px",
  };

  return (
    <>
      {status === "success" && (
        <>
          <GreenUnderlineHeading title={question?.displayName} />
          <div className="grid gap-1 md:grid-cols-1 grid-cols-2 my-12">
            <div className="">
              <AutoSaveSelectByLookup
                value={question?.type}
                group="einterviewQuestionType"
                collection={collectionNames.interviewQuestions}
                recId={question?.id}
                required={true}
                name="type"
                display="Type of Question"
                placeholder="Select question type"
                directions=""
                readOnly={readOnly}
              />
            </div>
            <div className="">
              <AutoSaveNumber
                value={question.reAttempts}
                collection={collectionNames.interviewQuestions}
                recId={question?.id}
                required={false}
                name={"duration"}
                display={"Number of Attemps"}
                placeholder={"How many attempts are allowed?"}
                directions={""}
                readOnly={readOnly}
                minValue={0}
                maxValue={3}
              />
            </div>
            <div>
              {/* Prep Time  */}
              <AutoSaveSelectByLookup
                group={"duration"}
                value={question.prepTime}
                collection={collectionNames.interviewQuestions}
                recId={question?.id}
                required={false}
                name={"prepTime"}
                display={"Prep Time"}
                placeholder={"Enter time"}
                directions={""}
                readOnly={readOnly}
                saveNumeric={true}
              />
            </div>
            <div>
              {/* Max Recording Time  */}
              <AutoSaveSelectByLookup
                group={"duration"}
                value={question.maxTime}
                collection={collectionNames.interviewQuestions}
                recId={question?.id}
                required={false}
                name={"maxTime"}
                display={"Max Recording Time"}
                placeholder={"Enter time"}
                directions={""}
                readOnly={readOnly}
                saveNumeric={true}
              />
            </div>
            <div className="col-span-2">
              <AutoSaveWysiwyg
                value={question?.question ?? ""}
                collection={collectionNames.interviewQuestions}
                recId={question?.id}
                required={false}
                rows={10}
                name={"question"}
                display={"Interview Question"}
                placeholder={"Enter an interview question."}
                directions={""}
                readOnly={readOnly}
                editorStyle={editorStyle}
                maxLength={120}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Template;
