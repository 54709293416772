import AutoSaveWysiwyg from '../custom-ui/input-fields/auto-save-wysiwyg';


function EssayPrompt(
    {
        question,
        collection,
        id,
        readOnly = false,
        editorStyle,
    }:{
        question:string,
        collection:string,
        id:string,
        readOnly:boolean,
        editorStyle:object,
    }
    ){
    return (
    <div className="col-span-2 mt-4">
        <AutoSaveWysiwyg
            value={question}
            collection={collection}
            recId={id}
            required={false}
            rows={10}
            name={"question"}
            display={"PROMPT"}
            placeholder={"Enter an interview question."}
            directions={""}
            readOnly={readOnly}
            editorStyle={editorStyle}
            maxLength={500}
        />
    </div>
    )
}

export default EssayPrompt;