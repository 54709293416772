import { useEffect, useState } from "react";
import { ZodError, ZodIssue } from "zod";

type PwdReq = {
  [key: string]: string;
};

function PwdCheck({
  schema,
  value,
  pwdReq,
  listStyles = "",
}: {
  schema: any;
  value: string;
  pwdReq: PwdReq;
  listStyles?: string;
}) {
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (schema.parse) {
      const result = schema.safeParse(value);
      if (!result.success) {
        const issues = result.error.issues;
        const errorPaths = issues.reduce(
          (accumulator: string[], zodIssue: ZodIssue) => {
            return [...accumulator, ...zodIssue.path];
          },
          []
        );
        setErrors(errorPaths);
      } else {
        setErrors([]);
      }
    }
  }, [schema, value]);

  const allValid = errors.length === 0 && value !== "";

  return (
    <div className={`${allValid?"hidden" :""} absolute mt-1 left-0 bg-white p-2 shadow-lg border border-gray-200 rounded-md z-10 w-80`}>
      {!allValid && (
        <div className="password-req-container">
          <ul className={`list-none ${listStyles}`}>
            {Object.keys(pwdReq).map((key, index) => {
              const isError = !errors.includes(key);
              const showPassFail = () => {
                return isError ? (
                  value ? (
                    <i className="mx-2 fa-solid fa-check text-instant-teams-green-D1"></i>
                  ) : (
                    ""
                  )
                ) : (
                  <i className="mx-2 fa-solid fa-xmark text-instant-teams-red-Main"></i>
                );
              };
              return (
                <li key={index}>
                  {showPassFail()}
                  {pwdReq[key]}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

export default PwdCheck;

