import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// topRoute = route to stay active, company, assessemets etc
// landingPage = the page you want to be rendered first
function useKeepNavbarActiveTab(landingPage, topRoute) {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      window.location.pathname.endsWith(topRoute) ||
      window.location.pathname.endsWith(`${topRoute}/`)
    ) {
      navigate(`/${topRoute}/${landingPage}`, { replace: true });
    }
    return () => {};
  }, [navigate, landingPage, topRoute]);
}

export default useKeepNavbarActiveTab;
