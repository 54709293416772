import React, { useEffect, useState } from "react";
import { useAuth } from "../providers/auth";
// import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { CreateInternalRoutes } from "./internal/internalRoutes";
import { CreateEmployerRoutes } from "./external/employerRoutes";
import { CreateSeekerRoutes } from "./seeker/seekerRoutes";
import { CreatePublicRoutes } from "./publicRoutes";
import { useAllActiveAlerts } from "../data/alerts";
// confused, checkout ReadMe.md
// still confused, reach out to Amil
function ConditionalRouter() {
  // we don't have access to useAuth in _app.js
  const auth = useAuth();


  const [routes, setRoutes] = useState(<CreateInternalRoutes />);
  const { data: alerts } = useAllActiveAlerts();
  useEffect(() => {
    sessionStorage.removeItem("hideAlerts");
  }, [alerts?.length]);

  useEffect(() => {

    if (!auth.user) {
      // set to public routes that have no auth HOC
      setRoutes(<CreatePublicRoutes />);
    }
    if (auth.userClaims?.claims?.type === "E") {
      // set to employer routes
      setRoutes(<CreateEmployerRoutes />)
    } else if (auth.userClaims?.claims?.type === "S") {
      if (auth?.user?.emailVerified != false) {
        setRoutes(<CreateSeekerRoutes />)
      }
    
    } else {
      // set to internal routes
      setRoutes(<CreateInternalRoutes />)
    }
  }, [auth]);

  return routes;
}

export default ConditionalRouter;
