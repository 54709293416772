import React, { useRef, useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import { useForm } from "react-hook-form";
import AuthSocial from "./AuthSocial";
import MultiFactorSignIn from "./multi-factor/MultiFactorSignIn";
import { useAuth } from "../../providers/auth";
import { friendlyFirebaseError } from "../../providers/firebase-errors/friendlyFirebaseError";

function ReauthModal(props) {
  const auth = useAuth();
  const { handleMultiFactorSignIn } = auth;
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [shouldMfaStart, setShouldMfaStart] = useState(false);
  const [mfaResolver, setMfaResolver] = useState();
  const cancelButtonRef = useRef(null);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    const { pass } = data;
    setPending(true);
    // TODO: use signIn method based on user type - admins use google workspace provider / customers use email and password
    auth
      .signin(auth.user.email, pass)
      .then(() => {
        // Call failed action that originally required reauth
        props.callback();
        // Let parent know we're done so they can hide modal
        props.onDone();
      })
      .catch((error) => {
        if (error.code === "auth/multi-factor-auth-required") {
          handleMultiFactorSignIn(
            error,
            setShouldMfaStart,
            setMfaResolver,
            setFormAlert,
          );
        } else {
          // Show error alert message

          setFormAlert({
            type: "error",
            message: friendlyFirebaseError(error),
          });
        }
      })
      .finally(() => setPending(false));
  };

  return (
    <DialogWrapper onClose={() => props.onDone()} title="Reauthenticate">
      <div className="mt-4">
        {formAlert && (
          <div className="mb-4 text-red-600">{formAlert.message}</div>
        )}

        {props.provider === "password" && !shouldMfaStart && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
              name="pass"
              type="password"
              placeholder="Password"
              ref={register({
                required: "Please enter your password",
              })}
            />

            {errors.pass && (
              <p className="mt-1 text-sm text-left text-red-600">
                {errors.pass.message}
              </p>
            )}

            <div className="mt-4">
              <button
                className="inline-flex justify-center py-2 px-4 text-sm font-medium rounded-md border border-gray-300 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                type="button"
                onClick={() => props.onDone()}
                ref={cancelButtonRef}
              >
                Cancel
              </button>
              <button
                className="inline-flex justify-center py-2 px-4 ml-3 text-sm font-medium text-white bg-blue-500 rounded-md border border-transparent hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                type="submit"
                disabled={pending}
              >
                {pending ? "..." : "Sign in"}
              </button>
            </div>
          </form>
        )}

        {shouldMfaStart && (
          <MultiFactorSignIn
            mfaResolver={mfaResolver}
            onAuth={() => {
              props.callback();
              props.onDone();
            }}
            onFormAlert={setFormAlert}
          />
        )}

        {props.provider !== "password" && (
          <AuthSocial
            buttonAction="Sign in"
            providers={[props.provider]}
            showLastUsed={false}
            onAuth={() => {
              props.callback();
              props.onDone();
            }}
            onError={(message) => {
              setFormAlert({
                type: "error",
                message: message,
              });
            }}
          />
        )}
      </div>
    </DialogWrapper>
  );
}

export default ReauthModal;
