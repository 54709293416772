import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
  useRef
} from "react";
import queryString from "query-string";
import {
  getAuth,
  connectAuthEmulator,
  onAuthStateChanged,
  signOut as authSignOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  SAMLAuthProvider,
  PhoneAuthProvider,
  RecaptchaVerifier,
  multiFactor,
  sendEmailVerification,
  checkActionCode,
  applyActionCode,
  getAdditionalUserInfo,
  getMultiFactorResolver,
  PhoneMultiFactorGenerator,
  updateEmail as authUpdateEmail,
  updateProfile as authUpdateProfile,
  updatePassword as authUpdatePassword,
  sendPasswordResetEmail as authSendPasswordResetEmail,
  confirmPasswordReset as authConfirmPasswordReset,
} from "firebase/auth";
import { firebaseApp } from "./firebase";
import { useUser, createUser, updateUser } from "./database";
import { addEmployerContacts } from "../data/employer";
import { useNavigate } from "react-router-dom";
import { errorLogger, warnLogger } from "./clientLogger";
import PageLoader from "../components/custom-ui/pageLoader/PageLoader";
import {
  getRemoteConfig,
  fetchAndActivate,
  getValue,
} from "firebase/remote-config";

import analytics from "../util/analytics";
import { useEntireRoleObject } from "../hooks/useRole";
import { updateLastActivityByUser } from "../data/user";

// import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// Whether to merge extra user data from database into `auth.user`
const MERGE_DB_USER = true;
// Whether to send email verification on signup
const EMAIL_VERIFICATION = true;
// Whether to connect analytics session to `user.uid`
const ANALYTICS_IDENTIFY = true;

// If localhost, initialize AppCheck with debug token
// if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
//   // window.FIREBASE_APPCHECK_DEBUG_TOKEN = "db8cbe1b-2e5a-4b82-b009-24d4a83e5da6";
//   window.FIREBASE_APPCHECK_DEBUG_TOKEN =
//     process.env.REACT_APP_FIREBASE_APP_CHECK_DEBUG_TOKEN;
// }

// Initialize Firebase appcheck
// const appCheck = initializeAppCheck(firebaseApp, {
//   provider: new ReCaptchaV3Provider(
//     process.env.REACT_APP_FIREBASE_APP_CHECK_KEY,
//   ),
//   isTokenAutoRefreshEnabled: true,
// });

// Initialize Firebase auth
const auth = getAuth(firebaseApp);

if (process.env.REACT_APP_FIREBASE_USE_EMULATOR === "true") {
  connectAuthEmulator(auth, "http://localhost:9099");
}

// Create a `useAuth` hook and `AuthProvider` that enables
// any component to subscribe to auth and re-render when it changes.
const authContext = createContext();
export const useAuth = () => useContext(authContext);

// This should wrap the app in `src/pages/_app.js`
export function AuthProvider({ children }) {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook that creates the `auth` object and handles state
// This is called from `AuthProvider` above (extracted out for readability)
function useAuthProvider() {
  // Store auth user in state
  // `user` will be object, `null` (loading) or `false` (logged out)
  const [user, setUser] = useState(null);

  // Merge extra user data from the database
  // This means extra user data (such as payment plan) is available as part
  // of `auth.user` and doesn't need to be fetched separately. Convenient!
  let finalUser = useMergeExtraData(user, { enabled: MERGE_DB_USER });

  // Add custom fields and formatting to the `user` object
  finalUser = useFormatUser(finalUser);

  // Connect analytics session to user
  useIdentifyUser(finalUser, { enabled: ANALYTICS_IDENTIFY });

  // for convenience making userClaims available here, otherwise it's nested
  // in the return user object
  const [userClaims, setUserClaims] = useState({});
  const getEntireRole = useEntireRoleObject();

  const [entireRole, setEntireRole] = useState({});

  const getUserClaims = (user) => {
    user?.auth.currentUser.getIdTokenResult().then((res) => {
      setUserClaims(res);
      // setEntireRole(getEntireRole(res.claims.role));
      if (process.env.REACT_APP_PENDO_NODE_ENV === "production") {
        // initialize Pendo
        // only for Production
        const visitor = {
          id: user?.uid,
          email: user?.email,
          displayName: user?.displayName,
        };
        const account = {
          id: res?.claims?.employerId,
        };
        const params = {
          visitor,
          account,
        };
        // eslint-disable-next-line no-undef
        pendo.initialize(params);
      }
    });
  };
  const getUserRole = (user) => {
    user?.auth.currentUser.getIdTokenResult().then((res) => {
      setEntireRole(getEntireRole(res.claims.role));
    });
  };

  // Handle response from auth functions (`signup`, `signin`, and `signinWithProvider`)
  const handleAuth = async (response) => {
    // response will return with a title if called handleAuth is called from signupEmployer
    // if title is present save user with title, otherwise don't.
    const {
      user,
      title,
      name,
      preferredName,
      phone,
      referredBy = "",
    } = response;
    const { isNewUser } = getAdditionalUserInfo(response);
    // Ensure Firebase user is ready before we continue
    await waitForFirebase(user.uid);

    // Create the user in the database if they are new
    if (isNewUser) {
      if (!title) {
        await createUser(user.uid, {
          email: user.email,
          name,
          preferredName: preferredName ?? name,
          phone,
          referredBy,
        });
      } else {
        await createUser(user.uid, {
          email: user.email,
          name,
          preferredName: preferredName ?? name,
          phone,
          title,
        });
      }
      // Send email verification if enabled
      if (EMAIL_VERIFICATION) {
        sendEmailVerification(auth.currentUser);
      }
    }
    getUserClaims();
    getUserRole();

    // below is a convenience function to make claims available on the user after authentication
    // if we stick the claims on the user here then we don't need
    // to call the above function getUserClaims() - which is a convenence function
    // that gets claims, stores them in state, and returns them from the userAuth hook
    return user.auth.currentUser.getIdTokenResult().then((claims) => {
      setUserClaims(claims);
      const userWithClaims = { ...user, claims };
      // Update user in state
      setUser(userWithClaims);
      return userWithClaims;
    });
  };

  const signup = (email, password, seekerInfo) => {
    return createUserWithEmailAndPassword(auth, email, password).then(
      (response) => {
        const responseWithSeekerInfo = { ...response, ...seekerInfo };
        return handleAuth(responseWithSeekerInfo);
      },
    );
  };

  const signupEmployer = ({ email, password, title }) => {
    return createUserWithEmailAndPassword(auth, email, password).then(
      (response) => {
        // don't mutate response just create new object with title
        const responseWithTitle = { ...response, title };
        handleAuth(responseWithTitle);
      },
    );
  };

  const isMultiFactorUser = () => {
    return multiFactor(auth.currentUser).enrolledFactors[0];
  };

  const reloadUser = () => {
    return auth.currentUser.reload();
  };

  const signin = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password).then(handleAuth);
  };

  const signinWithProvider = (name) => {
    // Get provider by name ("google", "facebook", etc)
    const provider = authProviders.find((p) => p.name === name).get();
    return signInWithPopup(auth, provider).then(handleAuth);
  };

  const signout = () => {
    return authSignOut(auth);
  };

  const sendPasswordResetEmail = (email) => {
    return authSendPasswordResetEmail(auth, email);
  };

  const sendVerificationEmail = () => {
    return sendEmailVerification(auth.currentUser);
  };

  const confirmPasswordReset = (password, code) => {
    // Get code from query string object
    const resetCode = code || getFromQueryString("oobCode");
    return authConfirmPasswordReset(auth, resetCode, password);
  };

  const updatePassword = (password) => {
    return authUpdatePassword(auth.currentUser, password);
  };

  // Update auth user and persist data to database
  // Call this function instead of multiple auth/db update functions
  const updateProfile = async (data) => {
    const { email, name, picture } = data;

    // Update auth email
    if (email) {
      await authUpdateEmail(auth.currentUser, email);
    }

    // Update built-in auth profile fields
    // These fields are renamed in `useFormatUser`, so when updating we
    // need to make sure to use their original names (`displayName`, `photoURL`, etc)
    if (name || picture) {
      let fields = {};
      if (name) fields.displayName = name;
      if (picture) fields.photoURL = picture;
      await authUpdateProfile(auth.currentUser, fields);
    }

    // Persist all data to the database
    await updateUser(user.uid, data);

    // Update user in state
    setUser(auth.currentUser);
  };

  const makeRecaptcha = (uiElementId, config) => {
    return new RecaptchaVerifier(
      uiElementId,
      { size: "invisible", ...config },
      auth,
    );
  };

  const getMultifactorSession = (user) => {
    return multiFactor(user)
      .getSession()
      .then((multifactorSession) => multifactorSession);
  };

  const makePhoneAuthProvider = () => {
    return new PhoneAuthProvider(auth);
  };

  const handleMultiFactorSignIn = (
    error,
    shouldMfaStart,
    setMfaResolver,
    setFormAlert,
  ) => {
    // User is a multi-factor user. Second factor challenge is required.
    const resolver = getMultiFactorResolver(auth, error);
    // TODO: when multiple mfa phone numbers are present, allow user to select which to use
    // by displaying 'hints' and allowing them to select. Pass the index of the selection here
    if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
      setMfaResolver(resolver);
      shouldMfaStart(true);
    } else {
      setFormAlert({
        type: "error",
        message: "Unsupported second factor.",
      });
      errorLogger(
        `Unsupported second factor, factorId must be "phone" but instead it is: ${resolver.hints[0].factorId} 
        Note: only "phone" second factors are currently supported`,
        {
          component: "AuthSocial",
          flow: "MFA Login Challenge",
          userId: "N/A",
        },
      );
    }
  };

  // fetching version number from remote config
  // https://www.youtube.com/watch?v=0DBRiMWy28Y
  const remoteConfig = getRemoteConfig();
  const fetchVersionNumber = async () => {
    // for dev testing
    // remoteConfig.settings.minimumFetchIntervalMillis = 35000;
    const isFetched = await fetchAndActivate(remoteConfig);
  };

  useEffect(() => {
    // Subscribe to user on mount
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        getUserClaims(user);
        getUserRole(user);
        setUser(user);
      } else {
        setUser(false);
        setUserClaims({});
        setEntireRole({});
      }
    });

    // Unsubscribe on cleanup
    return () => unsubscribe();
  }, []);

  return {
    user: finalUser,
    role: entireRole,
    userClaims,
    fetchVersionNumber,
    remoteConfig,
    reloadUser,
    signup,
    signupEmployer,
    signin,
    signinWithProvider,
    signout,
    sendPasswordResetEmail,
    sendVerificationEmail,
    confirmPasswordReset,
    updatePassword,
    updateProfile,
    getMultifactorSession,
    handleAuth,
    isMultiFactorUser,
    makeRecaptcha,
    makePhoneAuthProvider,
    handleMultiFactorSignIn,
  };
}

function useFormatUser(user) {
  // Memoize so returned object has a stable identity
  return useMemo(() => {
    // Return if auth user is `null` (loading) or `false` (not authenticated)
    if (!user) return user;

    // Create an array of user's auth providers by id (["password", "google", etc])
    // Components can read this to prompt user to re-auth with the correct provider
    const providers = user.providerData.map(({ providerId }) => {
      return authProviders.find((p) => p.id === providerId).name;
    });

    return {
      // Include full auth user data
      ...user,
      // Alter the names of some fields
      name: user.displayName,
      picture: user.photoURL,
      // User's auth providers
      providers: providers,
    };
  }, [user]);
}

function useMergeExtraData(user, { enabled }) {
  // Get extra user data from database
  const { data, status, error } = useUser(enabled && user && user.uid);
  // Memoize so returned object has a stable identity
  return useMemo(() => {
    // If disabled or no auth user (yet) then just return
    if (!enabled || !user) return user;

    switch (status) {
      case "success":
        // If successful, but `data` is `null`, that means user just signed up and the `createUser`
        // function hasn't populated the db yet. Return `null` to indicate auth is still loading.
        // The above call to `useUser` will re-render things once the data comes in.
        if (data === null) return null;
        // Return auth `user` merged with extra user `data`
        return { ...user, ...data };
      case "error":
        // Uh oh.. Let's at least show a helpful error.
        throw new Error(`
          Error: ${error.message}
          This happened while attempting to fetch extra user data from the database
          to include with the authenticated user. Make sure the database is setup or
          disable merging extra user data by setting MERGE_DB_USER to false.
        `);
      default:
        // We have an `idle` or `loading` status so return `null`
        // to indicate that auth is still loading.
        return null;
    }
  }, [user, enabled, data, status, error]);
}

// Connect analytics session to current user
function useIdentifyUser(user, { enabled }) {
  useEffect(() => {
    if (user && enabled) {
      analytics.identify(user.uid);
    }
  }, [user, enabled]);
}

// Yes the following 3 HOCs are very similar, however they will probably get more
// logic change in the future, seperating now makes more sense

// A Higher Order Component for requiring Internal authentication
export const requireInternalAuth = (Component) => {
  // TODO: add customClaims logic
  return function RequireAuthHOC(props) {
    // Get authenticated user
    const auth = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
      // Redirect if not signed in
      if (auth.user === false || auth?.userClaims?.claims?.type !== "A") {
        navigate("/auth/signin");
      }
    }, [auth]);
    // Show loading indicator
    // We're either loading (user is `null`) or about to redirect from above `useEffect` (user is `false`)
    if (!auth.user) {
      return <PageLoader />;
    }

    // Render component now that we have user
    return <Component {...props} />;
  };
};
// use for employer only routes
export const requireEmployerAuth = (Component) => {
  return function RequireAuthHOC(props) {
    const auth = useAuth();

    const navigate = useNavigate();
    useEffect(() => {
      if (auth.user === false || auth?.userClaims?.claims?.type !== "E") {
        navigate("/auth/signin");
      }
    }, [auth]);
    if (!auth.user) {
      return <PageLoader />;
    }
    return <Component {...props} />;
  };
};
// for shared routes settings, messaging etc.
export const requireAuth = (Component) => {
  return function RequireAuthHOC(props) {
    const auth = useAuth();
    const navigate = useNavigate();
    const hasUpdatedLastActivity = useRef(false);
    useEffect(() => {
      const getDeviceType = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {          
          return 'IOS_Web';
        }
        if (/Android/.test(userAgent)) {
          return 'Android_Web';
        }
        return 'Web';
      };
      if (auth.user === false) {
        navigate("/auth/signin");
      } else if (!hasUpdatedLastActivity.current && auth?.user?.id) {
        updateLastActivityByUser(auth?.user?.id, getDeviceType(), new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }));
        hasUpdatedLastActivity.current = true; 
      }
    }, [auth?.user?.user?.email]);

    if (!auth.user) {
      return <PageLoader />;
    }

    return <Component {...props} />;
  };
};

export const checkForAuth = (Component) => {
  // Get authenticated user if exists
  return function AlreadyAuthenticatedHOC(props) {
    const auth = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
      if (auth.user?.claims?.type === "A") {
        navigate("/dashboard/home", { replace: true });
      } else if (auth.user?.claims?.type === "E") {
        navigate("/dashboard/home", { replace: true });
      } else if (auth.user?.claims?.type === "S") {
        if (!auth?.user?.emailVerified) {
          navigate("/email-verification");
          return;
        }

        if (!multiFactor(auth?.user?.auth?.currentUser).enrolledFactors[0]) {
          navigate("/registration/multifactor-enrollment");
          return;
        }

        if (!auth?.user?.claims?.onboarded) {
          navigate("/onboarding", { replace: true });
          return;
        }
        navigate("/jobs", { replace: true });
      }
    }, [auth, navigate]);

    return <Component {...props} />;
  };
};

// Handle Firebase email link for reverting to original email after an email change
export const handleRecoverEmail = async (code) => {
  // Check that action code is valid
  const info = await checkActionCode(auth, code);
  // Revert to original email by applying action code
  await applyActionCode(auth, code);
  // Send password reset email so user can change their password in the case
  // that someone else got into their account and changed their email.
  await authSendPasswordResetEmail(auth, info.data.email);
  // Return original email so it can be displayed by calling component
  return info.data.email;
};

// Handle Firebase email link for verifying email
export const handleVerifyEmail = (code) => {
  return applyActionCode(auth, code);
};

const authProviders = [
  {
    id: "password",
    name: "password",
  },
  {
    id: "saml.google-workspaces-instant-teams",
    name: "instantTeamsWorkspace",
    get: () => new SAMLAuthProvider("saml.google-workspaces-instant-teams"),
  },
];
// use to determine if we are on jobs domain for seeker
export const isSeekerDomain = () => {
  const { hostname } = window.location;
  const hostKey = process.env.REACT_APP_JOBS_URL;
  return !!hostname.match(hostKey);
};

export const isEmailVerified = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      // Ensure we have a user with expected `uid`
      if (user.emailVerified) {
        resolve(user); // Resolve promise
        unsubscribe(); // Prevent from firing again
      }
      reject(false);
    });
  });
};

// Wait for Firebase user to be initialized before resolving promise
// and taking any further action (such as writing to the database)
const waitForFirebase = (uid) => {
  return new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      // Ensure we have a user with expected `uid`
      if (user && user.uid === uid) {
        resolve(user); // Resolve promise
        unsubscribe(); // Prevent from firing again
      }
    });
  });
};

const getFromQueryString = (key) => {
  return queryString.parse(window.location.search)[key];
};
