import { useState } from "react";
import DialogWrapper from "../../components/custom-ui/dialog/Dialog"
import { useListing } from "../../data/listings";
import { createApplication } from "../../data/applications";
import toast from "react-hot-toast";

const InviteCohortList = ({ toggleModal, jobListingData, inviteUserList }: any) => {
    const [invite, setInvite] = useState("");
    const { data: inviteData } = useListing(invite);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [loading, setLoading] = useState(false);

    console.log(inviteUserList)

    async function handleInvite() {
        if (inviteData && invite && inviteUserList.length !== 0) {
            setLoading(true);    

            try {
                const promises = inviteUserList?.map((inviteUser: any) => {
                    return createApplication({
                        jobId: inviteData?.jobId,
                        positionId: inviteData?.positionId,
                        resumeId: inviteUser?.objectID,
                        invited: true,
                    });
                });
                await Promise.all(promises);
                toast.success("All users invited successfully");
                toggleModal();
            } catch (error) {
                console.log("Error in inviting users for job", error);
                toast.error("Errors occurred while inviting users");
            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <>
            <DialogWrapper
                title="Invite Seeker to Listing"
                onClose={() => toggleModal()}
                size="w-1/2"
            >
                <>
                    <div className="transition-max-height ease-out duration-300 flex overflow-hidden p-5">
                        <select
                            className="w-full font-bold text-instant-teams-teal-Main"
                            onChange={(event: any) => {
                                setInvite(event.target.value);
                            }}
                        >
                            {jobListingData &&
                                jobListingData?.data?.map((item: any) => {
                                    return (
                                        <option key={item.id} value={item.id}>
                                            {item.companyName} - {item.title} - {item.subTitle} -{" "}
                                            {item.solutionType}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                    <div className="w-full mt-4 px-20">
                        {dataLoaded && (
                            <div className="grid grid-cols-3 gap-4 w-full items-center">
                                <div>{inviteData?.classification || null}</div>
                                <div>{inviteData?.model || null}</div>
                                <div>{inviteData?.term || null}</div>
                                <div>{inviteData?.positionPayType || null}</div>
                                <div>Hours Per Week: {inviteData?.hoursPerWeek || null}</div>
                            </div>
                        )}
                    </div>
                    <div className="w-full mt-10 px-20">
                        {dataLoaded ? (
                            <div className="w-full items-center">
                                <button
                                    className={`text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none w-full h-10 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    onClick={() => !loading && handleInvite()}
                                    disabled={loading}
                                >
                                    {loading ? 'Inviting...' : 'Invite'}
                                </button>
                            </div>
                        ) : (
                            <div className="w-full items-center">
                                <button
                                    className="text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none w-full h-10"
                                    onClick={() => setDataLoaded(!dataLoaded)}
                                >
                                    Search
                                </button>
                            </div>
                        )}
                    </div>
                </>
            </DialogWrapper>
        </>
    );
}

export default InviteCohortList;
