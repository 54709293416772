import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { useAuth } from "../../providers/auth";
import Notification from "./notification";
import Notification_NewUI from "./Notification_NewUI";

import {
  useAllUnreadNotifications,
  updateAllIsRead,
} from "../../data/notifications";
import { useState } from "react";
import { useEffect } from "react";

const NotificationList_NewUI = ({ userId }) => {
  const [notification, setNotification] = useState([]);
  const isRead = false;
  const user = useAuth();
  const employerId = user?.claims?.employerId;


  const { data: unreadNotificationList } = useAllUnreadNotifications(
    userId,
    isRead,
    employerId
  );

  
  useEffect(() => {
    if (unreadNotificationList && unreadNotificationList !== notification) {
      setNotification(unreadNotificationList);
    }
  }, [unreadNotificationList]); 

  const handleMarkAllRead = () => {
    updateAllIsRead(userId);
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="flex relative h-full w-12 mt-2 pb-2 hover:pb-1 overflow-hidden justify-center items-center">
        <i className="far fa-bell text-2xl text-[#1E596D]"></i>
        {notification && notification?.length > 0 && (
          <div className="h-5 w-5 absolute top-0 left-1 p-[1] rounded-full bg-instant-teams-blue-Main text-xs text-white flex justify-center items-center">
            {unreadNotificationList?.length}
          </div>
        )}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-[500px] origin-top-right bg-white divide-y divide-gray-100 shadow-lg z-50 rounded-lg focus:outline-none">
          {/* Arrow pointing to the bell icon */}
          <div className="absolute top-0 right-8 translate-x-4  transform -translate-y-2 w-4 h-4 bg-white rotate-45 "></div>
          <div className="px-4 py-3">
            <h3 className="font-bold text-xl text-instant-teams-blue-Main">Notifications</h3>
          </div>

          <div className="flex flex-col px-3 divide-y divide-gray-100 max-h-44 overflow-y-auto">
            {/* Render notifications */}
            {unreadNotificationList &&
              unreadNotificationList.slice(0, 3).map((userNotification) => (
                <Notification_NewUI
                  userNotification={userNotification}
                  key={userNotification.notificationId}
                />
              ))}
            {unreadNotificationList && unreadNotificationList.length === 0 && (
              <div className="py-4 text-gray-500 ml-2">No Unread notifications</div>
            )}
          </div>

          <div className="px-4 py-6 text-center">
            <Link to={`/notifications/${user?.user?.id}`}>
              <span className="text-instant-teams-teal-L1 underline cursor-pointer">
                Show All Notifications
              </span>
            </Link>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default NotificationList_NewUI;
