import { useState, useEffect } from "react";
import { useAuth } from "../../providers/auth";
import {
  useUserGroupChat,
  useEmployerGroupChat,
  useUserPersonalChat,
} from "../../data/messages";
import { useUser } from "../../providers/database";
import { useUsersByRole } from "../../data/user";
import useModal from "../../hooks/useModal";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import GreenUnderlineHeading from "../custom-ui/headings/heading";
import MessagePanelLite from "./MessagePanelLite";
import NewConversationModal from "./NewConversationModal";
import { createNewConversation } from "../../data/messages";
import { getLabel } from "../../util/search/getLabel";

const MessagingCenterLite = ({
  recipientId,
  employerId,
}: {
  recipientId?: any;
  employerId?: any;
}) => {
  const [messageData, setMessageData] = useState({});
  const [unreadIds, setUnreadIds] = useState([]);
  const userId = useAuth()?.user?.id;
  const { data: loggedInUser } = useUser(userId);
  const { data: recipientData } = useUser(recipientId);
  const { data: userGroupChat } = useUserGroupChat(
    loggedInUser?.claims?.role,
    recipientId,
  );
  const { data: userPersonalChat } = useUserPersonalChat(userId, recipientId);
  const { data: usersByRole } = useUsersByRole(loggedInUser?.claims?.role);
  const {
    isShowing: isShowingConversationModal,
    toggle: toggleConversationModal,
  } = useModal();

  const handleCreateConversation = () => {
    const newData = {
      ...messageData,
      body: `${getLabel(
        loggedInUser?.claims?.role,
        "userRoles",
      )} has started a new conversation with ${recipientData?.preferredName}`,
      messageType: "group",
      group: loggedInUser?.claims?.role,
      unreadUsers: loggedInUser?.claims?.type === "E" ? unreadIds : recipientId,
      createdByName: loggedInUser?.name,
    };
    createNewConversation(newData);
  };

  useEffect(() => {
    const userIds = usersByRole
      ?.map((item: any) => item.id)
      .filter((id: any) => id !== loggedInUser?.id);
    if (userIds && recipientData?.id) {
      setUnreadIds([...userIds, recipientData?.id]);
    }
  }, [loggedInUser, usersByRole, recipientData]);

  useEffect(() => {
    setMessageData({
      recipientEmail: recipientData?.email,
      recipientId: recipientData?.id,
      recipientName: recipientData?.name,
      status: "inbox",
      userId: userId,
    });
  }, [recipientData, userId]);

  return (
    <div className="h-[30%]">
      <GreenUnderlineHeading title="Messages" />
      {recipientId &&
        userGroupChat?.length > 0 &&
        recipientData?.claims?.type === "S" && (
          <MessagePanelLite chat={userGroupChat?.[0]} />
        )}
      {recipientId &&
        userPersonalChat?.length > 0 &&
        recipientData?.claims?.type !== "S" && (
          <MessagePanelLite chat={userPersonalChat?.[0]} />
        )}
      {(recipientId &&
        userGroupChat?.length === 0 &&
        recipientData?.claims?.type === "S") ||
      (recipientId &&
        userPersonalChat?.length === 0 &&
        recipientData?.claims?.type !== "S") ? (
        <div className="w-1/4 mb-2 flex flex-col justify-around">
          <button
            className="w-3/4 mb-5 min-h-12 h-12 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
            onClick={
              recipientData?.claims?.type !== "S"
                ? toggleConversationModal
                : handleCreateConversation
            }
          >
            START CHAT
          </button>
        </div>
      ) : (
        ""
      )}
      {isShowingConversationModal && employerId && (
        <DialogWrapper
          title="New Conversation"
          onClose={toggleConversationModal}
        >
          <NewConversationModal
            toggle={toggleConversationModal}
            employerId={employerId}
          />
        </DialogWrapper>
      )}
      {isShowingConversationModal && recipientId && (
        <DialogWrapper
          title="New Conversation"
          onClose={toggleConversationModal}
        >
          <NewConversationModal
            toggle={toggleConversationModal}
            recipientId={recipientId}
          />
        </DialogWrapper>
      )}
    </div>
  );
};

export default MessagingCenterLite;
