import { useState, useEffect } from "react";
import { useAuth } from "../../providers/auth";
import { Tab } from "@headlessui/react";
import {
  useAllChatsByGroup,
  useAllChatsByRecipientId,
  useAllPersonalChats,
  useAllChatsByEmployerId,
} from "../../data/messages";
import { Chat } from "../../dataTypes/Messages";
import useModal from "../../hooks/useModal";
import MessageTabContents from "./MessageTabContents";
import MessagePanel from "./MessagePanel";
import NewConversationModal from "./NewConversationModal";
import DialogWrapper from "../custom-ui/dialog/Dialog";

const MessagingCenterEmployerView = ({
  employerId,
}: {
  employerId?: string;
}) => {
  const auth = useAuth();
  const { userClaims, user } = auth;
  const [value, setValue] = useState("inbox");
  const [allChats, setAllChats] = useState([]);
  const { isShowing, toggle } = useModal();

  const { data: chats = [] } = useAllChatsByGroup(
    userClaims?.claims?.role,
    value,
  );
  const { data: employerChats = [] } = useAllChatsByEmployerId(
    userClaims?.claims?.employerId,
  );
  const { data: allEmployerChats } = useAllChatsByEmployerId(employerId);
  const { data: personalChats = [] } = useAllPersonalChats(user?.id, value);
  const { data: receivedChats = [] } = useAllChatsByRecipientId(
    user?.id,
    value,
  );

  useEffect(() => {
    if (
      chats?.length === 0 &&
      receivedChats?.length === 0 &&
      personalChats?.length === 0 &&
      employerChats?.length === 0
    ) {
      setAllChats("");
    }
    if (
      (chats && chats?.length > 0) ||
      (personalChats && personalChats?.length > 0) ||
      (receivedChats && receivedChats?.length > 0) ||
      (employerChats && employerChats?.length > 0)
    ) {
      const newChats = [
        ...chats,
        ...receivedChats,
        ...personalChats,
        ...employerChats,
      ];
      const uniqueChats = Array.from(
        new Set(newChats.map((chat) => chat.id)),
      ).map((id) => {
        return newChats.find((chat) => chat.id === id);
      });

      // Sort the uniqueChats array by the lastUpdated timestamp
      const sortedChats = uniqueChats.sort(
        (a, b) => b.lastUpdated - a.lastUpdated,
      );

      setAllChats(sortedChats);
    }
  }, [chats, receivedChats, value, personalChats, employerChats]);

  const handleChange = (event: any) => {
    setValue(event.target.value);
    event.preventDefault();
  };

  return (
    <>
      <Tab.Group>
        <div className="flex h-full bg-white">
          <div className="flex flex-col z-10 w-1/4 text-black border-r-2 border-gray-300">
            <div className=" h-1/4 flex flex-col justify-center items-center border-b-2 border-gray-300">
              <button
                className=" bg-instant-teams-teal-Main text-white py-2 px-4 rounded mt-4 text-sm mb-2 uppercase"
                onClick={toggle}
              >
                New Conversation
              </button>
              <div className=" w-5/6 mb-2 border-slate-400 border-2 rounded-md text-xs">
                <select
                  name="messageType"
                  value={value}
                  onChange={handleChange}
                  className="form-input shadow-lg border-2  w-full"
                >
                  <option key="0" value="inbox">
                    Inbox
                  </option>
                  <option key="1" value="archive">
                    Archive
                  </option>
                </select>
              </div>
            </div>
            <div className="border-instant-teams-teal-Main overflow-y-scroll">
              <style>
                {`
                /* Hide scrollbar for Chrome, Safari and Opera */
                .border-instant-teams-teal-Main::-webkit-scrollbar {
                  display: none;
                }

                /* Hide scrollbar for IE, Edge and Firefox */
                .border-instant-teams-teal-Main {
                  -ms-overflow-style: none;  /* IE and Edge */
                  scrollbar-width: none;  /* Firefox */
                }
              `}
              </style>
              <Tab.List>
                {allEmployerChats &&
                  allEmployerChats?.length > 0 &&
                  allEmployerChats.map((chat: Chat) => (
                    <Tab
                      key={chat?.id}
                      className={({ selected }) =>
                        `${
                          selected
                            ? "bg-slate-200 border-0 border-r-4 border-green-400"
                            : "text-black border-b-2"
                        } text-left hover:bg-slate-200 h-24 w-full`
                      }
                    >
                      <MessageTabContents chat={chat} />
                    </Tab>
                  ))}
              </Tab.List>
            </div>
          </div>
          {allEmployerChats ? (
            <Tab.Panels className="w-full h-full">
              {allEmployerChats &&
                allEmployerChats?.length > 0 &&
                allEmployerChats.map((chat: any) => {
                  return (
                    <Tab.Panel
                      key={`${chat?.id}-panel`}
                      className="flex h-full flex-col"
                    >
                      <MessagePanel
                        chat={chat}
                        key={chat?.id}
                        minimize={false}
                      />
                    </Tab.Panel>
                  );
                })}
            </Tab.Panels>
          ) : (
            <div className="flex justify-center items-center w-full">
              <div className=" w-1/2 h-1/5 text-2xl">{`There are no messages in the ${value} tab`}</div>
            </div>
          )}
        </div>
      </Tab.Group>
      {isShowing && (
        <DialogWrapper title="New Conversation" onClose={toggle}>
          <NewConversationModal toggle={toggle} employerId={employerId} />
        </DialogWrapper>
      )}
    </>
  );
};

export default MessagingCenterEmployerView;
