import React, { useState } from "react";
import { orderBy } from "lodash";
import { useAuth } from "../../providers/auth";
import { useForm } from "react-hook-form";
import { createInvitation } from "../../data/invitation";
import { errorLogger } from "../../providers/clientLogger";
import { Transition, Dialog } from "@headlessui/react";
import { LookUp } from "../../dataTypes/LookUp";
import { useLookup } from "../../data/lookup";
import { Employer } from "../../dataTypes/Employer";

function InvitationForm({
  onDone,
  employer,
  userType,
}: {
  employer: Employer;
  userType: string;
  onDone: Function;
}) {
  // For use in testing
  const substitueInternalDomain =
    process.env.REACT_APP_INVITATION_USE_INSTANT_TEAMS_DOMAIN === "true";

  const { user } = useAuth();

  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const {
    data: employerRoles = [],
    status: employerRolesQueryStatus = "",
    error: employerRolesQueryError,
  } = useLookup("employerRole");

  const sortedEmployerRoles = orderBy(employerRoles, ["sortOrder"], ["asc"]);

  // when an employers is created with status of "lead" or
  // invited with status of "invited" invitations should be limited to primary contacts
  // otherwise an invitation can be set with any role
  // shouldDisableRole regulates which employer roles can be selected
  const shouldDisableRole = (role: LookUp) =>
    (employer.status === "invited" && role.code !== "PRI") ||
    (employer.status === "lead" && role.code !== "PRI");

  const { handleSubmit, register, errors, getValues, setValue } = useForm({
    defaultValues: {
      company: employer.displayName,
      domain: substitueInternalDomain ? "instantteams.com" : employer.domain,
      employerId: employer.id,
      invitedBy: user.id,
      employerRole: "",
      name: "",
      type: userType,
      prefix: "",
      email: "",
      title: "",
    },
  });

  const concatEmail = () => {
    const prefix = getValues("prefix");
    const domain = getValues("domain");
    setValue("email", `${prefix}@${domain}`);
  };

  const onSubmit = (data: any) => {
    // exclude uncessary prefix form data
    const { prefix, ...invitationData } = data;

    setPending(true);
    createInvitation(invitationData)
      .then(() => {
        onDone(`Sent invitation to ${data.email}`);
      })
      .catch((error) => {
        setFormAlert(error);
        errorLogger(error.toString(), {
          component: "InvitationForm createInvitation()",
          flow: "Create Employer Invitation",
          userId: user.id,
        });
      })
      .finally(() => setPending(false));
  };

  return (
    <>
      <Transition appear={true} show={true}>
        <Dialog
          className="overflow-y-auto fixed inset-0"
          onClose={() => onDone()}
        >
          <div className="px-4 min-h-screen text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="inline-block overflow-hidden p-6 my-8 w-full max-w-md text-left align-middle bg-white rounded-2xl shadow-xl transition-all transform">
                <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
                  Send Invitation
                </Dialog.Title>

                <div className="mt-4">
                  {formAlert && (
                    <div className="mb-4 text-red-600">{formAlert.message}</div>
                  )}
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <input
                      className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                      name="employerId"
                      type="hidden"
                      ref={register()}
                    />
                    <input
                      className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                      name="type"
                      type="hidden"
                      ref={register()}
                    />
                    <input
                      className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                      name="invitedBy"
                      type="hidden"
                      ref={register()}
                    />
                    <div className="mb-2">
                      <input
                        className="py-1 px-3 font-bold w-full leading-8 bg-gray-200 rounded border border-gray-300 outline-none"
                        readOnly
                        name="company"
                        type="text"
                        ref={register()}
                      />
                    </div>

                    <div className="mb-2">
                      <div className="flex space-x-1">
                        <div className="w-full">
                          <input
                            className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                            name="prefix"
                            type="text"
                            onBlur={() => concatEmail()}
                            ref={register({
                              required: "Please complete the email address",
                              pattern: /^[^@]+$/,
                            })}
                          />
                          {errors.prefix && (
                            <p className="text-xs text-instant-teams-red-Main">
                              Please remove "@" character.
                            </p>
                          )}
                        </div>

                        <span className="py-1 font-bold text-lg">@</span>
                        <input
                          className="py-1 px-3 w-full leading-8 font-bold bg-gray-200 rounded border border-gray-300 outline-none"
                          readOnly
                          name="domain"
                          type="text"
                          ref={register({})}
                        />
                        <input
                          className="py-1 px-3 leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                          name="email"
                          type="hidden"
                          ref={register({})}
                        />
                      </div>
                      {errors.prefix && (
                        <p className="mt-1 text-sm text-left text-red-600">
                          {errors.prefix.message}
                        </p>
                      )}
                    </div>
                    <div className="mb-2">
                      <input
                        className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                        name="name"
                        type="text"
                        placeholder="Full Name"
                        ref={register({
                          required: "Please enter the invitees full name.",
                        })}
                      />
                      {errors.name && (
                        <p className="mt-1 text-sm text-left text-red-600">
                          {errors.name.message}
                        </p>
                      )}
                    </div>

                    <div className="mb-2">
                      <input
                        className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                        name="title"
                        type="text"
                        placeholder="Title"
                        ref={register({
                          required: "Please enter the invitees title.",
                        })}
                      />
                      {errors.title && (
                        <p className="mt-1 text-sm text-left text-red-600">
                          {errors.title.message}
                        </p>
                      )}
                    </div>

                    <div className="mb-2">
                      <select
                        name="employerRole"
                        className="w-full py-1 leading-8 bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-1"
                        ref={register({
                          required: "Please select a contact type.",
                        })}
                      >
                        <option value="" selected disabled hidden>
                          Select a contact type
                        </option>
                        {/* if employer status is 'lead' then set 'primary' as selected value */}
                        {sortedEmployerRoles.map((role: LookUp) => (
                          <option
                            disabled={shouldDisableRole(role)}
                            key={role.id}
                            value={role.code}
                          >
                            {role.label}
                          </option>
                        ))}
                      </select>
                      {errors.employerRole && (
                        <p className="mt-1 text-sm text-left text-red-600">
                          {errors.employerRole.message}
                        </p>
                      )}
                    </div>

                    <button
                      className="py-2 px-4 w-full text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                      type="submit"
                      disabled={pending}
                    >
                      {pending ? "..." : "Send Invitation"}
                    </button>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default InvitationForm;
