import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk, faCheck } from "@fortawesome/free-solid-svg-icons";
import FadeInFromTop from "../animations/transitions/FadeInFromTop";

// wrapper that contains title and message for equired field
import Tooltip from "../tooltip/tooltip";
function AutoSaveWrapper({ children, issue }) {
  return (
    <>
      {/* <Tooltip text="testing"> */}
      <div className="flex items-center mb-1 text-red-500">
        {issue ? (
          <>
            <FontAwesomeIcon icon={faAsterisk}  className=" text-[6px] " />
            <div className="ml-1 block text-left text-xs">{issue.message}</div>
          </>
        ) : null}
      </div>
      {/* </Tooltip> */}
      <div className="mb-2">{children}</div>
    </>
  );
}

export default AutoSaveWrapper;
