import { requireAuth } from "../../providers/auth";
import Meta from "../../components/custom-ui/meta/Meta";
import MessagingCenter from "../../components/messages/MessagingCenter";
import SeekerMessagingCenter from '../../components/messages/SeekerMessagingCenter';
import { useAuth } from '../../providers/auth';

const MessagesPage = () => {
  const { user } = useAuth();
  return (
    <div className="bg-white w-full h-full">
      <Meta title="Messages" />
      {user?.claims?.type !== "S" ? (
        <MessagingCenter />
      ):(
        <SeekerMessagingCenter />
      )}      
    </div>
  );
};

export default requireAuth(MessagesPage);
