import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getNotificationData, updateIsRead } from "../../data/notifications";
import { DisplayTimestamp } from "../../util/timestamps/timestamp";
import { UserNotification } from "../../dataTypes/Notifications";

const Notification = ({
  userNotification,
}: {
  userNotification: UserNotification;
}) => {
  const [notification, setNotification] = useState({});
  const { isRead, notificationId, userId } = userNotification;
  const [expandNotification, setExpandNotification] = useState(false);

  useEffect(() => {
    handleNotification(notificationId);
  }, [notificationId]);

  async function handleNotification(notificationId: string) {
    const notification = await getNotificationData(notificationId);
    setNotification(notification);
  }

  function handleClick() {
    updateIsRead(notificationId, userId);
    setExpandNotification(!expandNotification);
  }

  return (
    <div
      className={`flex flex-col justify-center mouse-pointer relative mt-1 pl-2 pr-8 w-full bg-white text-black mb-2 overflow-hidden hover:overflow-visible hover:justify-start h-15 transition-all duration-500 ease-in-out hover:pt-2`}
      onClick={handleClick}
      onMouseEnter={() => setExpandNotification(true)}
      onMouseLeave={() => setExpandNotification(false)}
    >
      <div className="flex justify-between items-center">
        {/* If there is a notification url make the title clickable */}
        {notification?.url ? (
          <div className="font-bold underline">
            <Link to={notification?.url} target="_blank">
              {notification?.title}
            </Link>
          </div>
        ) : (
          <div className="font-bold">{notification?.title}</div>
        )}
      </div>
      <p className="text-xxs pb-1">
        {notification?.createdAt
          ? DisplayTimestamp(notification?.createdAt)
          : ""}
      </p>
      <div
        className={`text-sm ${
          expandNotification ? "whitespace-normal overflow-clip" : "truncate"
        }`}
      >
        {notification?.body}
      </div>

      {!isRead ? (
        <div className="bg-instant-teams-green-D1 rounded-full h-3 w-3 absolute top-2 right-2"></div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Notification;
