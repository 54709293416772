import { useEmployer } from "../../data/employer";
import { useJobDescription } from "../../data/jobDescriptions";
import { useMemo, useCallback } from "react";
import { useListing } from "../../data/listings";
import { useJobPositionDetails } from "../../data/positions";
import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";
import { toUSCurrency } from "../../util/helpers/toUSCurrency";
import { getLookupLabel } from "../../util/search/getLookupLabel";
import { useLookup } from "../../data/lookup";
import { useFindApplicationStatus } from "../../data/status";
import { Link, useNavigate } from "react-router-dom";
import useModal from "../../hooks/useModal";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import { useAllSharedNotesByCollectionAndId } from "../../data/notes";
import {
  collectionNames,
  subCollectionNames,
} from "../../data/dictionary/collectionNames";
import { useEmployerData } from "../../hooks/useApplicationEmployerData";
import FailedSkillListing from "../application/parts/FailedSkillItem";
import OveralProgress from "../application/parts/OverallProgress";

import OverallProgressInterviewAndSkillsCounter from "../application/parts/OverallProgressInterviewAndSkillsCounter";
import { useAuth } from "../../providers/auth";

function SeekerApplicationItem({ application }: { application: any }) {
  const { data: jobData } = useJobDescription(application?.jobId);
  const auth = useAuth();
  const { user } = auth; 
  const { employerId, employerData } = useEmployerData({
    jobData,
    originalEmployerId: application?.employer,
  });
  const { data: positionData } = useJobPositionDetails(
    application?.jobId,
    application.positionId,
  );

  const {
    data: classification = [],
    status: classificationQueryStatus = "",
    error: classificationQueryError,
  } = useLookup("classification");
  const {
    data: model = [],
    status: modelQueryStatus = "",
    error: modelQueryError,
  } = useLookup("model");

  const { data: statusData = {} } = useFindApplicationStatus(
    application?.status,
  );

  const { data: sharedNotesData } = useAllSharedNotesByCollectionAndId(
    application?.id,
    collectionNames.applications,
    subCollectionNames.notes,
  );

  const { isShowing, toggle } = useModal();

  const getSalary = (payType: any) => {
    const pay = payType?.HRLY ?? payType?.SAL;
    if (!pay) return undefined;

    const type = payType?.HRLY ? "Hourly" : "Salary";
    return `${type}: ${toUSCurrency(pay[0])} - ${toUSCurrency(pay[1])}`;
  };

  const salary = useMemo(
    () => getSalary(positionData?.information?.payType),
    [positionData?.information?.payType],
  );

  const selectedWorkSetting = getLookupLabel(
    positionData?.information?.model,
    model,
    "Not Selected",
  );

  const selectedWorkType = getLookupLabel(
    positionData?.information?.classification,
    classification,
    "None Selected",
  );

  const navigate = useNavigate();

  const onClick = useCallback(
    (item: any) => {
      if (
        (item.preScreened === undefined || item.preScreened === false) &&
        item.status === "applied"
      ) {
       
        navigate(`/jobs/${item.jobId}${item.positionId}`, {
          state: {
            label: statusData?.label,
            preScreened:item?.preScreened,
            itemId: item?.id,
            jobIdfromApplication: item.jobId,
            postionIdfromApplication: item.positionId,
            applicationId:application?.id
          },
        });
      } else if (
        item.preScreened &&
        (item.status === "applied" || item.status === "invited")
      ) {
       
        navigate(`/jobs/${item.jobId}${item.positionId}`, {
          state: {
            label: statusData?.label,
            preScreened:item?.preScreened,
            itemId: item?.id,
            jobIdfromApplication: item.jobId,
            postionIdfromApplication: item.positionId,
               applicationId: application?.id
          },
        });
      } else if (item.status === "invited" && item.preScreened === undefined) {
       
        navigate(`/jobs/${item.jobId}${item.positionId}`, {
          state: {
            label: statusData?.label,
            preScreened:item?.preScreened,
            itemId: item?.id,
            jobIdfromApplication: item.jobId,
            postionIdfromApplication : item.positionId,
            applicationId: application?.id
          },
        });
      }
    },
    [application],
  );


  const onApplied = useCallback(
    (item: any) => {

     if (item?.status === "closed" || item?.status==='ready' || item?.status==='notSelected') {
        // listingId is made by combining jobId & positionId.
        navigate(`/jobs/${item.jobId}${item.positionId}`, {
          state: {
            label: statusData?.label,
            preScreened:item?.preScreened,
            itemId: item?.jobId,
            jobIdfromApplication: item.jobId,
            postionIdfromApplication: item.positionId,
            applicationId: application?.id
          },
        });
        //navigate(`/jobs/${item.jobId}${item.positionId}`);
      }
    },
    [application],
  );

  const colorJobListingVariants: any = {
    applied: "bg-instant-teams-tags-ORANGE",
    decline: "bg-instant-teams-gray-D1",
    declined: "bg-instant-teams-gray-D1",
    knockout: "bg-instant-teams-purple-Main",
    preOffer: "bg-instant-teams-orange-Main",
    presented: "bg-instant-teams-tags-TEAL",
    ready: "bg-instant-teams-tags-BLUE",
    underReview: "bg-instant-teams-tags-GREEN",
    closed: "bg-instant-teams-tags-ROSE",
    invited: "bg-instant-teams-tags-GOLD",
  };

  return (
    <>
      {application.status === "applied" || application.status === "invited" ? (
        <div
          onClick={() => onClick(application)}
          className={
            TailwindHoverEnlarge(103) +
            " grid grid-flow-row grid-cols-5 mx-auto h-56 w-3/5 lg:w-3/4 md:w-full cursor-pointer  text-instant-teams-blue-Main bg-white rounded-lg shadow-input-field"
          }
        >
          <img
            src={employerData?.logo}
            alt={employerData?.displayName}
            className=" object-contain my-auto pl-8 col-span-1"
          />
          <div className="col-span-4 grid grid-cols-4 my-auto  space-x-5">
            <div className="col-span-2 flex flex-col justify-items-start pl-12">
              <div>{employerData?.displayName}</div>
              <div className="font-semibold uppercase">{jobData?.title}</div>
              <div>{positionData?.positionName}</div>
              <div className="font-semibold">{salary}</div>
              <div>
                Work Type: <span className="pl-2">{selectedWorkType}</span>
              </div>
              <div>
                Work Setting:{" "}
                <span className="pl-2">{selectedWorkSetting}</span>
              </div>
            </div>

            <div className="col-span-2 mx-auto">
              <div
                className={`py-2 w-5/6 text-white rounded-lg uppercase text-center ${colorJobListingVariants[application?.status] ||
                  "bg-instant-teams-purple-Main"
                  }`}
              >
                {statusData?.label}
              </div>
              {statusData && !statusData.hideData && (
                <>
                  {application && application.status === "applied" ? (
                    <>
                      <div className="uppercase h-auto text-center mb-4 mt-4 w-5/6">
                        <OveralProgress application={application} />
                      </div>
                      <OverallProgressInterviewAndSkillsCounter
                        application={application}
                        width="w-5/6"
                      />
                    </>
                  ) : (
                    <>
                      {application &&
                        application.progressData?.failedSkillIds && (
                          <FailedSkillListing
                            skill={application.progressData?.failedSkillIds}
                          />
                        )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
        onClick={() => onApplied(application)}
          className={
            TailwindHoverEnlarge(103) +
            " grid grid-flow-row grid-cols-5 mx-auto h-56 w-3/5 lg:w-3/4 md:w-full cursor-pointer  text-instant-teams-blue-Main bg-white rounded-lg shadow-input-field"
          }
        >
          <img
            src={employerData?.logo}
            alt={employerData?.displayName}
            className=" object-contain my-auto pl-8 col-span-1"
          />
          <div className="col-span-4 grid grid-cols-4 my-auto  space-x-5">
            <div className="col-span-2 flex flex-col justify-items-start pl-12">
              <div>{employerData?.displayName}</div>
              <div className="font-semibold uppercase">{jobData?.title}</div>
              <div>{positionData?.positionName}</div>
              <div className="font-semibold">{salary}</div>
              <div>
                Work Type: <span className="pl-2">{selectedWorkType}</span>
              </div>
              <div>
                Work Setting:{" "}
                <span className="pl-2">{selectedWorkSetting}</span>
              </div>
            </div>

            <div className="col-span-2 mx-auto my-auto">
              <div
                className={`py-2 w-5/6 text-white rounded-lg uppercase text-center ${colorJobListingVariants[application?.status] ||
                  "bg-instant-teams-purple-Main"
                  }`}
              >
                {statusData?.label}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {isShowing && (
        <DialogWrapper
          title="Application Details"
          onClose={toggle}
          size="w-1/2"
        >
          <>
            <div className="w-full flex">
              <Link to={`/companies/${application?.employer}`}>
                <img
                  className="my-auto w-24 h-24 object-contain  "
                  src={employerData?.logo}
                  alt={employerData?.displayName}
                />
              </Link>
              <div className="pl-5  my-auto  text-instant-teams-blue-Main justify-start md:text-sm">
                <Link to={`/companies/${application?.employer}`}>
                  <div className="hover:underline">
                    {employerData?.displayName}
                  </div>
                </Link>
                <div className="font-semibold uppercase">{jobData?.title}</div>
                <div>{positionData?.positionName}</div>
                <div className="font-semibold">
                  {salary} | {selectedWorkSetting} | {selectedWorkType}
                </div>
              </div>
            </div>
            <div className="my-5 border border-instant-teams-gray-Main"></div>
            <div className=" flex justify-center mt-6 ">
              <div
                className={`py-2 w-2/3 text-white text-center uppercase rounded-lg ${colorJobListingVariants[application?.status] ||
                  "bg-instant-teams-purple-Main"
                  }`}
              >
                {statusData?.label}
              </div>
            </div>
            {user?.claims?.role != "SKR" && <div>
              <h3 className=" uppercase mt-5 mb-2 font-semibold text-instant-teams-blue-Main">
                Notes
              </h3>
              <div className="w-full overflow-y-auto">
                {sharedNotesData && sharedNotesData?.length !== 0 ? (
                  <div className="my-1">{sharedNotesData[0]?.note}</div>
                ) : (
                  <div>No note was shared.</div>
                )}
              </div>
            </div>}
          </>
        </DialogWrapper>
      )} */}
    </>
  );
}
export default SeekerApplicationItem;
