import SeekerViewCardWrapper from "../custom-ui/cards/seekerViewCard";
function SeekerCompanyItem({ employer }: { employer: any }) {
  const employerAddressDisplay = (employer: any) => {
    if (!employer?.address?.country && !employer?.address?.state) {
      return "Not Listed";
    } else if (!employer?.address?.country && employer?.address?.state) {
      return employer?.address?.state;
    } else if (employer?.address?.country && !employer?.address?.state) {
      return employer?.address?.country;
    } else {
      return `${employer?.address?.state}, ${employer?.address?.country}`;
    }
  };
  return (
    <SeekerViewCardWrapper link={`/companies/${employer.id}`}>
      <>
        <img
          src={employer?.logo}
          className="w-20 h-20 object-contain my-auto mx-auto col-span-1"
          alt={employer?.displayName}
        ></img>
        <div className="my-auto text-xl font-semibold pl-1 col-span-2">
          {employer?.displayName}
        </div>
        <div className="col-span-2 my-auto">
          <div className=" font-semibold">
            Location:
            <span className="text-sm pl-2 font-normal">
              {employerAddressDisplay(employer)}
            </span>
          </div>
          <div className=" font-semibold">
            Industry:
            <span className=" text-sm pl-2 font-normal">
              {employer?.intel?.category?.industry}
            </span>
          </div>
        </div>
      </>
    </SeekerViewCardWrapper>
  );
}

export default SeekerCompanyItem;
