import { useState, useEffect } from 'react';
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import Template from '../General/EditTemplate';
import { useAllEInterviews } from '../../../data/eInterview';
import { addEmployerInterviewQuestion } from '../../../data/employer';

function EInterviewDefault({employerId, callback}:{employerId: string, callback: Function}) {
  const [trigger, setTrigger] = useState(true);
  const { data: questions } = useAllEInterviews(trigger);

  function customize() {
    questions.forEach(async (question: object) => {
      await addEmployerInterviewQuestion(employerId, question);
    })
    callback("employer")
  }

  return (
    <>
      <GreenUnderlineHeading title="e-interview Default Questions" />
      <button
        className="bg-instant-teams-green-Main hover:bg-instant-teams-green-L1 w-1/5 mb-5 min-h-12 h-12 text-white  rounded border-0  focus:outline-none"
        onClick={() => customize()}
      >
        Customize
      </button>
      <div className="w-full">
        {questions && questions.map((question: any, index: number) => (
          <Template id={question.id} key={index} readOnly={true} /> 
        ))}
        
      </div>
    </>
  );
}

export default EInterviewDefault;
