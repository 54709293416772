function OverallProgressInterviewAndSkillsCounter({
  application,
  width = "w-full",
}: {
  application: any;
  width?: string;
}) {
  return (
    <div
      className={`${width} col-span-1 my-auto py-2 px-2 text-sm rounded-lg shadow-xl bg-instant-teams-teal-Main`}
    >
      <div className="uppercase font-semibold text-white">
        <span>e-interview</span>
        <span className="float-right text-right text-bold">
          {application?.progressData?.completedInterviewQuestions}/
          {application?.progressData?.requiredInterviewQuestions}
        </span>
      </div>
      <div className="uppercase font-semibold text-white mt-2">
        <span>Assessments</span>
        <span className="float-right text-right text-bold">
          {application?.progressData?.skillsAcquired}/
          {application?.progressData?.requiredSkills}
        </span>
      </div>
    </div>
  );
}
export default OverallProgressInterviewAndSkillsCounter;
