import React from 'react';

const LoadingSpinner = ({ size = '8', color = '#183853', borderSize = '4' }) => {
  return (
    <div
      className={`inline-block w-${size} h-${size} border-${borderSize} border-t-transparent border-[#183853] rounded-full animate-spin`}
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default LoadingSpinner;
