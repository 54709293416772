// use for external links

function ExternalLinkIcon({
  linkText = "Text to render",
  href = "https://time.is/",
}) {
  return (
    <a
      href={href}
      className="flex items-center cursor-pointer"
      target="_blank"
      rel="noreferrer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-4 h-4 pl-1"
        viewBox="0 0 448 512"
      >
        <path
          className="fa-primary"
          d="M256 64C256 46.33 270.3 32 288 32H416C433.7 32 448 46.33 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64z"
        />
        <path
          className="fa-secondary "
          d="M0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z"
        />
      </svg>
      <span className="flex ml-1 ">{linkText}</span>
    </a>
  );
}

export default ExternalLinkIcon;
