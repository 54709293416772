import { useEffect, useRef, useState } from "react";
import InvitationForm from "../../../invitations/InvitationForm";
import useModal from "../../../../hooks/useModal";
import { useEmployer, useEmployerUsers } from "../../../../data/employer";
import ToastAlert from "../../../custom-ui/toast-alert/ToastAlert";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useActiveInvitationByEmployer } from "../../../../data/invitation";
import InvitationRecord from "../parts/invitationRecord";
import EmployerUserCardFull from "../parts/employerUserCardFull";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../providers/auth";
import GreenUnderlineHeading from "../../../custom-ui/headings/heading";

const EmployerUserManagement = () => {
  const { userClaims } = useAuth();

  const params = useParams();
  const [employerId, setEmployerId] = useState("");

  const {
    data: employer,
    status: employerStatus,
    error: employerError,
  } = useEmployer(employerId);

  const {
    data: invitationsData,
    status: invitationStatus,
    error: invitationsError,
  } = useActiveInvitationByEmployer(employerId);

  const {
    data: usersData,
    status: usersStatus,
    error: usersError,
  } = useEmployerUsers(employerId);

  const { isShowing: isShowingInvitation, toggle: toggleInvitation } =
    useModal();

  const handleDone = (message: string | undefined) => {
    toggleInvitation();
    if (message !== undefined) {
      toast.success(message);
    }
  };

  useEffect(() => {
    setEmployerId(params.employerId ?? userClaims.claims.employerId);
  }, [params]);

  return (
    <div>
      <div className="flex w-full h-12 pr-5 clear-right">
        <button
          className="py-2 px-4 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none ml-auto"
          onClick={toggleInvitation}
        >
          Invite New User
        </button>
      </div>
      <div className="flex-auto w-full pr-10">
        <GreenUnderlineHeading title="Invitation Status" />
        <div className="flex-auto w-full mx-auto">
          <div className="flex-auto min-w-full bg-white shadow-md rounded-md mb-4">
            <div className="oveflow-hidden">
              <table className="min-w-full">
                <thead className="border-b">
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                    Invitee
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                    Invited By
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                    Role
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                    Email
                  </th>
                </thead>
                <tbody>
                  {invitationsData &&
                  Object.keys(invitationsData || {}).length > 0 ? (
                    Object.keys(invitationsData).map((value) => (
                      <InvitationRecord invitation={invitationsData[value]} />
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5} className="text-center">
                        No Invitations Sent
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex-auto w-full pr-10"></div>

        <GreenUnderlineHeading title="Current Users" />

        <div className="grid grid-cols-4 gap-4">
          {usersData && Object.keys(usersData || {}).length > 0 ? (
            Object.keys(usersData).map((value) => (
              <Link to={`/users/${usersData[value].id}/user-info`}>
                <EmployerUserCardFull user={usersData[value]} />
              </Link>
            ))
          ) : (
            <>No Users Found</>
          )}
        </div>
      </div>
      {isShowingInvitation && (
        <InvitationForm employer={employer} userType="E" onDone={handleDone} />
      )}
      <ToastAlert />
    </div>
  );
};

export default EmployerUserManagement;
