import { useState, useEffect } from "react";
interface BaseProps {
  deps?: React.DependencyList;
}
interface MapProps<T, K> extends BaseProps {
  items?: T[];
  map?: (item: T) => Promise<K>;
  filter?: (item: Awaited<K>) => boolean;
}

export const useMap = <T, K>({
  items = [],
  map,
  filter,
  deps = [],
}: MapProps<T, K>): [K[] | undefined, boolean, boolean] => {
  // @ts-ignore
  // it doesn't make any sense that I have to add this here, but not in Athena-mobile
  const [result, setResult] = useState<K[]>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const load = async () => {
    try {
      if (!map || items.length === 0) return;

      const mapped = await Promise.all(items.map(async (item) => map(item)));
      setResult(filter ? mapped.filter(filter) : mapped);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, [items, ...deps]);

  return [result, loading, error];
};
