import { useEffect, useState } from "react";
import { Chat } from "../../dataTypes/Messages";
import {
  newestMessage,
  useAllMessagesByChatId,
  markChatRead,
} from "../../data/messages";
import { useUser } from "../../data/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { DisplayTime, DisplayTimestamp } from "../../util/timestamps/timestamp";
import TechnicalSupportSvg from "../custom-ui/icons/TechnicalSupportSvg";
import ConnectWithRecruitmentSvg from "../custom-ui/icons/ConnectWithRecruitmentSvg";
import { cn } from "../../util/cn";

const SeekerMessageTabContents = ({
  chat,
  isSelected,
  onClick,
}: {
  chat: Chat;
  isSelected: boolean;
  onClick: () => void;
}) => {
  const userId: string =
    localStorage.getItem("__user_id")?.replace(/"/g, "") || "";
  const [title, setTitle] = useState("");
  const [avatar, setAvatar] = useState("");
  const [lastMessage, setLastMessage] = useState({});
  const [messageIsUnread, setMessageIsUnread] = useState(
    chat?.unreadUsers?.includes(userId),
  );
  const { recipientId, id: chatId } = chat;
  const { data: recipient } = useUser(recipientId);
  const { data: messageData } = useAllMessagesByChatId(chatId);
  const { data: senderData } = useUser(lastMessage?.senderId);
  const { data: userData } = useUser(userId);

  const handleMessageData = async () => {
    const message = await newestMessage(chatId);
    setLastMessage(message?.data);
  };

  const handleClick = () => {
    markChatRead(userId, chatId);
    onClick();
  };

  useEffect(() => {
    setMessageIsUnread(chat?.unreadUsers?.includes(userId));
  }, [chat, userId]);

  useEffect(() => {
    handleMessageData();
  }, [recipientId, chatId, messageData]);

  const getTitle = () => {
    switch (chat?.group) {
      case "tac":
        setTitle(`Connect with Recruitment`);
        setAvatar("tac");
        return;
      // case 'csss':
      //   setTitle(`Career Seeker Support Specialist`);
      //   setAvatar("/images/message/csss.png");
      //   return;
      case "sadmin":
        setTitle(`Technical Support`);
        setAvatar("helpdesk");
        return;
      default:
        return "";
    }
  };

  useEffect(() => {
    getTitle();
  }, []);

  return (
    <>
      <div
        className={cn(
          "bg-white shadow-md p-6 flex space-x-4 rounded-[20px] text-left w-full",
          {
            "bg-[#15415E] text-white hover:bg-[#0e517d]": isSelected,
            "text-[#15415E] border-b-2 hover:bg-slate-200": !isSelected,
          },
        )}
        style={{ boxShadow: "0px 0px 34px 0px rgba(30, 89, 109, 0.04)" }}
        onClick={handleClick}
      >
        <div
          className={cn("rounded-[20px] p-6", {
            "bg-[white]": isSelected,
            "bg-[#15415E]": !isSelected,
          })}
        >
          {avatar === "helpdesk" && (
            <TechnicalSupportSvg
              className={cn("w-[30px] h-[30px]", {
                "text-[#15415E]": isSelected,
                "text-[white]": !isSelected,
              })}
            />
          )}
          {avatar === "tac" && (
            <ConnectWithRecruitmentSvg
              className={cn("w-[30px] h-[30px]", {
                "text-[#15415E]": isSelected,
                "text-[white]": !isSelected,
              })}
            />
          )}
        </div>
        <div className="w-4/5 pr-4 h-[55px]">
          {/* <div className="h-[55px] w-4/5"> */}
          <h2 className="text-[20px] leading-[29.71px]  font-bold">{title}</h2>
          {/* <p className=" text-[12px] leading-[14.56px]">
            {defaultText}
          </p> */}
          <div className="text-xs truncate text-ellipsis ">
            {lastMessage?.body !== "" && lastMessage?.body}
            {lastMessage &&
              lastMessage?.image !== undefined &&
              lastMessage?.image !== null && (
                <img src={lastMessage.image} className="w-8" />
              )}
            {lastMessage?.link !== null &&
              lastMessage?.link !== undefined &&
              "Web Link"}
            {lastMessage?.file !== null &&
              lastMessage?.file !== undefined &&
              "File Download"}
          </div>
          <div className="flex-1 text-xxs ">
            {DisplayTimestamp(lastMessage?.createdAt)}
          </div>
        </div>
      </div>
    </>
  );
};

export default SeekerMessageTabContents;
