import Meta from "../../components/custom-ui/meta/Meta";
import GreenUnderlineHeading from "../../components/custom-ui/headings/heading";

import { sanitizeMarkup } from "../../util/sanitize/sanitize-html";
import "../../styles/dangerouslySet.css";

import { useEffect, useState } from "react";

import JobListingShareSkill from "./JobSocialSkills";
import JobListingSharePosition from "./JobListingDetailsPosition";
import { getLevel } from '../../util/assessments/level';
import CustomMeta from "./CustomMeta";

function JobListingShare({ id, share }: { id: string; share: any }) {
  const [jobTitle, setJobTitle] = useState(null);

  const [columns, setColumns] = useState(4);
  const [url, setUrl] = useState(window.location.href);

  useEffect(() => {
    if (share) {
      setJobTitle(
        `${share?.data?.employer?.displayName} - ${share?.data?.job?.title}`,
      );
    }
  }, [share]);

  useEffect(() => {
    if (share?.data?.jobPositions && share?.data?.jobPositions.length > 0) {
      const count = share?.data?.jobPositions.length;
      if (count < 5) {
        setColumns(count);
      } else {
        if (count % 2 === 0) {
          setColumns(count / 2);
        } else {
          setColumns(count / 2 + 1);
        }
      }
    }
  }, [share?.data?.jobPositions]);

  const hostKey = process.env.REACT_APP_JOBS_URL;

  return (
    <>
      {jobTitle && (
        <CustomMeta
          title={jobTitle}
          postTitle={share.title}
          shareDescription={share.description}
          twitterImage={share.square?.imageUrl}
          images={share.wide?.imageUrl}
          shareUrl={url}
        />
      )}
      <section className="pb-16">
        <div className="grid grid-flow-row mx-auto xs:grid-cols-1 grid-cols-8 gap-y-3 w-3/4">
          <img
            className="my-auto xs:hidden col-span-1 w-full h-24 object-contain object-center"
            src={share?.data?.employer?.logo}
            alt={share?.data?.employer?.displayName}
          />
          <div className="pl-5 xs:col-span-8 xs:[-30px] col-span-5 my-auto  text-instant-teams-blue-Main justify-start md:text-sm">
            <div className="text-xl">{share?.data?.employer?.displayName}</div>
            <div className="font-semibold uppercase text-2xl">
              {share?.data?.job?.title}
            </div>
          </div>
          <div className=" xs:col-span-8 col-span-2 space-y-5">
            <a href={`https://${hostKey}`}>
              <button className="mt-4 py-3 w-full uppercase bg-instant-teams-green-Main hover:bg-instant-teams-blue-Main text-white rounded-lg md:py-2 md:px-8 md:text-sm lg:py-2 lg:px-16 xl:px-20">
                Join Now to Apply
              </button>
            </a>
            {/* <div className="grid grid-cols-5 gap-2">
              <div className="flex flex-col col-span-2 uppercase text-instant-teams-blue-Main font-bold h-42 justify-center align-middle">
                Share to:
              </div>
              <div className='w-full'>
                <FacebookShareButton
                  url={window.location.href}
                  quote={share?.title || "New Job Announcement"}
                  hashtag={share?.hashtags || "#instantteams"}
                >
                  <FacebookIcon size={42} round />
                </FacebookShareButton>
              </div>
              <div className='w-full'>
                <LinkedinShareButton
                  url={window.location.href}
                  title={share?.title || "New Job Announcement"} 
                  summary={share?.description || "Look at this great Job on Instant Teams Marketplace"}
                  source="Instant Teams Marketplace"
                >
                  <LinkedinIcon size={42} round />
                </LinkedinShareButton>
              </div>
              <div className='w-full'>
                <TwitterShareButton 
                  url={window.location.href}
                  title={share?.title || "New Job Announcement"}
                  hashtags={[share?.hashtags] || ["#instantteams"]}
                >
                  <TwitterIcon size={42} round />
                </TwitterShareButton>
              </div>                
            </div> */}
          </div>
          <div className=" xs:hidden col-span-8 h-34 bg-instant-teams-teal-Main w-full p-2 mt-4 rounded-xl text-center">
            <div className="grid  grid-cols-3 gap-2">
              <div className="text-xl font-bold uppercase text-white pr-4 h-16 pb-[8px] min-h-16 pt-8 text-right">
                Join now to apply:
              </div>
              <div className="text-center pt-4">
                <a
                  className="h-16 inline-block"
                  href={`https://apps.apple.com/us/app/instant-teams-marketplace/id1666030415?itsct=apps_box_badge&amp;itscg=30200`}
                  target="_blank"
                >
                  <img
                    className="w-full h-16"
                    src={`https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1685577600`}
                    alt="Download on the App Store"
                  />
                </a>
              </div>
              <div className="text-center">
                <a
                  className="h-20 inline-block"
                  href="https://play.google.com/store/apps/details?id=com.instantteams.athena&utm_source=landingPage&utm_campaign=launch&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&utm_source=build.instantteams.com&utm_campaign=Build&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  target="_blank"
                >
                  <img
                    className="w-full h-24"
                    alt="Get it on Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="hidden xs:flex col-span-8 h-34 bg-instant-teams-teal-Main w-full p-2 mt-4 rounded-xl text-center">
            <div className="grid grid-cols-2 gap-2">
              <div className="text-xl font-bold uppercase text-white pr-4 h-16 pb-[8px] min-h-16 pt-8 text-right">
                Join now to apply:
              </div>
              <div className="text-center pt-4">
                <a
                  className="h-16  w-32  inline-block"
                  href={`https://apps.apple.com/us/app/instant-teams-marketplace/id1666030415?itsct=apps_box_badge&amp;itscg=30200`}
                  target="_blank"
                >
                  <img
                    className=" h-16"
                    src={`https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1685577600`}
                    alt="Download on the App Store"
                  />
                </a>

                <a
                  className="h-18 w-36 ml-[-8px] mt-[-8px] inline-block"
                  href="https://play.google.com/store/apps/details?id=com.instantteams.athena&utm_source=landingPage&utm_campaign=launch&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&utm_source=build.instantteams.com&utm_campaign=Build&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  target="_blank"
                >
                  <img
                    className=" h-16"
                    alt="Get it on Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="col-span-8">
            <GreenUnderlineHeading
              title={
                share?.data?.jobPositions?.length > 1
                  ? "Available Positions"
                  : "Available Position"
              }
            />
            {share?.data?.jobPositions && share?.data?.jobPositions.length > 0 && (
              <div className={`grid grid-cols-1 sm:grid-cols-${columns} gap-6`}>
                {share?.data?.jobPositions?.map((position: any) => {
                  return (
                    <JobListingSharePosition
                      key={position.id}
                      position={position}
                    />
                  );
                })}
              </div>
            )}
          </div>

          {share?.data?.jobDescriptionSkills &&
            share?.data?.jobDescriptionSkills.length > 0 && (
              <div className="col-span-8">
                <GreenUnderlineHeading title="required assessments" />
                <div className="flex space-x-5">
                  {share?.data?.jobDescriptionSkills?.map((skill: any) => {
                    return (
                      <JobListingShareSkill key={skill.id} skill={skill} />
                    );
                  })}
                </div>
              </div>
            )}
          {share?.data?.job?.description?.dayInTheLife && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="Day in the life" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  share?.data?.job?.description?.dayInTheLife,
                )}
              ></div>
            </div>
          )}
          {share?.data?.job?.description?.mustHaves && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="must have" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  share?.data?.job?.description?.mustHaves,
                )}
              ></div>
            </div>
          )}
          {share?.data?.job?.description?.training && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="Training" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  share?.data?.job?.description?.training,
                )}
              ></div>
            </div>
          )}
          {share?.data?.job?.description?.benefits && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="Benefits" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  share?.data?.job?.description?.benefits,
                )}
              ></div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default JobListingShare;