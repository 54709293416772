import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEmployerDeptDashboard } from "../../../../data/employer";
import useModal from "../../../../hooks/useModal";
import { Department, DepartmentsItem } from "../../../../dataTypes/Company";
import DialogWrapper from "../../../custom-ui/dialog/Dialog";
import CreateEmployerDepartment from "../parts/CreateEmployerDepartment";
import DepartmentItemShow from "../parts/DepartmentItem";
import DepartmentNotAssigned from "../parts/DepartmentNotAssigned";
import { useAuth } from "../../../../providers/auth";
import { useCurrentUserEmployerId } from "../../../../hooks/useCurrentUserEmployerId";
import { deptDashboard } from "../../../../callable-cloud-functions/cloudFunctions";
import PulseSpinner from "../../../../components/spinners/pulse-spinner";
import BlocksSpinner from "../../../spinners/blocks-spinner";
import WedgeSpinner from "../../../spinners/wedges-spinner";
import { useEmployerDepartments } from "../../../../data/employer";

const DepartmentListing = () => {
  const userId = useCurrentUserEmployerId();
  const params = useParams();
  const [employerId, setEmployerId] = useState("");
  const { toggle: createDeptToggle, isShowing: createDept } = useModal();
  useEffect(() => {
    if (params.employerId) {
      setEmployerId(params.employerId);
    } else {
      setEmployerId(userId);
    }
    return () => setEmployerId("");
  }, [params, userId]);
  const { data: deptData, isLoading } = useEmployerDepartments(employerId);

  return (
    <div>
      <div className="flex flex-row justify-end px-10 mb-5">
        <button
          className="bg-instant-teams-teal-Main rounded-md shadow-md text-white p-2 w-1/5"
          onClick={() => {
            createDeptToggle();
          }}
        >
          Add Department
        </button>
      </div>

      {isLoading ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <WedgeSpinner />
        </div>
      ) : (
        <div className="grid grid-cols-4 gap-4 ml-5 pr-20">
          <DepartmentNotAssigned employerId={employerId} />
          {deptData &&
            deptData?.map((department: DepartmentsItem) => (
              <DepartmentItemShow
                key={department.id}
                employerId={employerId}
                department={department}
              />
            ))}
        </div>
      )}

      {createDept && (
        <DialogWrapper
          title="Create New Dept"
          onClose={() => {
            createDeptToggle();
          }}
        >
          <CreateEmployerDepartment
            closeModal={() => {
              createDeptToggle();
            }}
          />
        </DialogWrapper>
      )}
    </div>
  );
};

export default DepartmentListing;
