import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../providers/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import toast from "react-hot-toast";
import ToastAlert from "../../../components/custom-ui/toast-alert/ToastAlert";

function PasswordSettings({
  closeModal,


}: {
  closeModal: Function;


}) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showConfirmTooltip, setShowConfirmTooltip] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleTooltipVisibility = () => {
    setShowTooltip(!showTooltip);
  };


  const toggleConfirmTooltipVisibility = () => {
    setShowConfirmTooltip(!showConfirmTooltip);
  };


  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };



  const { register, handleSubmit, errors, reset, getValues } = useForm();

  const onSubmit = (data: any) => {
    // Show pending indicator
    setPending(true);

    auth
      .updatePassword(data.pass)
      .then((data: any) => {
        // Clear form
        console.log("Data", data);
        reset();
        // Set success status
        closeModal()
      })
      .catch((error: any) => {
        console.log("error", error);

        if (error.code === "auth/requires-recent-login") {
          // Handle re-authentication when credentials are too old
          toast.error(`Login is too old. Please login again.`);


        } else {
          toast.error(`${error.message}`);
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
        //closeModal()
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-6">

        <div className="flex mb-10">

          <p className="border-b w-full font-bold py-2 text-xl text-[#15415E]">Update Password</p>
        </div>

        <div className="relative mb-10">
          <input
            type={showPassword ? "text" : "password"} // Toggle input type
            name="pass"
            placeholder=" "
            ref={register({
              required: "Please enter a password",
            })}



            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
            style={{ boxShadow: 'none' }}
          />
          <label
            htmlFor="password"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Password <span className="text-red-600">*</span>
          </label>

          {errors.pass && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.pass.message}
            </p>
          )}

          <div className="absolute inset-y-0 right-3 flex items-center cursor-pointer" onClick={togglePasswordVisibility}>
            {showPassword ? <FontAwesomeIcon icon={faEye} color="#15415E" className="w-4" style={{ marginTop: '-5px' }} /> : <FontAwesomeIcon icon={faEyeSlash} color="#15415E" className="w-4" style={{ marginTop: '-5px' }} />}
          </div>

          <div className="absolute inset-y-0 right-10 flex items-center cursor-pointer"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            style={{ marginRight: '-60px', marginTop: '-5px' }}>
            <img src="/images/info.png" alt="Info Icon" className="w-4" />
          </div>

          {showTooltip && (
            <div className="absolute right-0 mt-4 w-64 rounded-lg shadow-lg bg-white p-4 text-sm text-gray-700 z-10" style={{ top: '20px' }}>
              <ul>
                <li>At least 8 characters</li>
                <li>At least one capital letter</li>
                <li>At least one lowercase letter</li>
                <li>At least one number or symbol</li>
              </ul>
            </div>
          )}

          {/* {dirtyFields.password && errors.password && <p className="mt-1 text-sm text-left text-red-600">{errors.password.message}</p>} */}
        </div>


        <div className="relative mb-10">
          <input
            type={showConfirmPassword ? "text" : "password"} // Toggle input type
            name="confirmPass"
            placeholder=" "


            ref={register({
              required: "Please enter your new password again",
              validate: (value) => {
                if (value === getValues().pass) {
                  return true;
                } else {
                  return "This doesn't match your password";
                }
              },
            })}

            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
            style={{ boxShadow: 'none' }}
          />
          <label
            htmlFor="confirmPass"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Confirm Password <span className="text-red-600">*</span>
          </label>

          {errors.confirmPass && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.confirmPass.message}
            </p>
          )}

          <div className="absolute inset-y-0 right-3 flex items-center cursor-pointer" onClick={toggleConfirmPasswordVisibility}>
            {showConfirmPassword ? <FontAwesomeIcon icon={faEye} color="#15415E" className="w-4" style={{ marginTop: '-5px' }} /> : <FontAwesomeIcon icon={faEyeSlash} color="#15415E" className="w-4" style={{ marginTop: '-5px' }} />}
          </div>

          <div className="absolute inset-y-0 right-10 flex items-center cursor-pointer"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            style={{ marginRight: '-60px', marginTop: '-5px' }}>
            <img src="/images/info.png" alt="Info Icon" className="w-4" />
          </div>

          {showConfirmTooltip && (
            <div className="absolute right-0 mt-4 w-64 rounded-lg shadow-lg bg-white p-4 text-sm text-gray-700 z-10" style={{ top: '20px' }}>
              <ul>
                <li>At least 8 characters</li>
                <li>At least one capital letter</li>
                <li>At least one lowercase letter</li>
                <li>At least one number or symbol</li>
              </ul>
            </div>
          )}

          {/* {dirtyFields.password && errors.password && <p className="mt-1 text-sm text-left text-red-600">{errors.password.message}</p>} */}
        </div>

        <div className="flex justify-around space-x-10 mt-10 mb-4 mx-5">

          <button
            className="py-2 bg-white border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-[#15415E] w-full h-12 rounded-full"
            onClick={() => closeModal()}
          >
            Cancel
          </button>
          <button
            className="py-2 bg-[#15415E] text-white w-full rounded-full h-12 "
            type="submit"
            disabled={pending}
          >
            {pending ? (
              <div className="flex items-center justify-center space-x-2">
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                </svg>

              </div>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </form>
      <ToastAlert />
    </>
  );
}

export default PasswordSettings;
