import GreenUnderlineHeading from "../../components/custom-ui/headings/heading";
import DashboardUserListing from "../../components/user/listing/dashboard-listing";
import { useUsersByStatusWithExcludedRole } from "../../data/user";
import { User } from "../../dataTypes/User";

function PendingSeekers() {
  const { data: pendingSeekers, status: pendingSeekersStatus } =
    useUsersByStatusWithExcludedRole("pending", "COMM");

  return (
    <>
      <GreenUnderlineHeading title="Pending Seekers" />
      <div className="flex flex-wrap">
        {pendingSeekersStatus === "success" && pendingSeekers.length > 0 ? (
          <>
            {pendingSeekers?.map((pendingSeeker: User) => {
              return (
                <DashboardUserListing
                  user={pendingSeeker}
                  key={pendingSeeker?.id}
                />
              );
            })}
          </>
        ) : (
          <p>No pending seekers right now.</p>
        )}
      </div>
    </>
  );
}

export default PendingSeekers;
