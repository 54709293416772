/**
 * GCP Cloud Logging library sev levels
 * DEFAULT	(0) The log entry has no assigned severity level.
 * DEBUG	(100) Debug or trace information.
 * INFO	(200) Routine information, such as ongoing status or performance.
 * NOTICE	(300) Normal but significant events, such as start up, shut down, or a configuration change.
 * WARNING	(400) Warning events might cause problems.
 * ERROR	(500) Error events are likely to cause problems.
 * CRITICAL	(600) Critical events cause more severe problems or outages.
 * ALERT	(700) A person must take an action immediately.
 * EMERGENCY	(800) One or more systems are unusable.
 */
import { sliLogger } from "../callable-cloud-functions/cloudFunctions";
type messageContext = {
  userId: string;
  component: string;
  flow: string;
};

type sliPayload = {
  message: string;
  sevLevel: string;
  env: string;
  messageContext: messageContext;
  resourceType: string;
  action: string;
  resultStatus: string;
  cuj: string;
};

const env: string = process.env.NODE_ENV ?? "";

async function sendSliEvent(payload: sliPayload) {
  try {
    sliLogger(payload);
  } catch (error) {
    console.error(error);
  }
}

async function errorLogger(message: string, messageContext: messageContext) {
  console.error(
    "Env: ",
    env,
    "Error: ",
    message,
    "Log Context: ",
    messageContext,
  );
}

async function infoLogger(message: string, messageContext: messageContext) {
  console.info(
    "Env: ",
    env,
    "Info: ",
    message,
    "Log Context: ",
    messageContext,
  );
}

async function warnLogger(message: string, messageContext: messageContext) {
  console.warn(
    "Env: ",
    env,
    "Warn: ",
    message,
    "Log Context: ",
    messageContext,
  );
}

async function debugLogger(message: string, messageContext: messageContext) {
  console.debug(
    "Env: ",
    env,
    "Debug: ",
    message,
    "Log Context: ",
    messageContext,
  );
}

export { errorLogger, infoLogger, warnLogger, debugLogger, sendSliEvent };
