function HeroImageWrapper({ children }: { children: JSX.Element }) {
  return (
    <div className="flex flex-col md:flex-row items-left justify-left -mt-2">
      <div className="w-1/2 md:w-1/2 flex flex-col items-center justify-center pb-40 ">
        {children}
      </div>

      <div className="w-1/2 fixed top-28 right-0 min-h-screen">
        <img
          className="absolute top-0 right-0 w-full min-h-screen z-10 object-cover"
          src="/images/login_hero.jpg"
          alt="Welcome to Instant Teams"
        />
      </div>
    </div>
  );
}

export default HeroImageWrapper;
