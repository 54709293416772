import { useEffect, useState } from "react";
import { useUser } from "../../data/user";
import validator from 'validator'
import GreenUnderlineHeading from '../custom-ui/headings/heading';
import SectionHeading from '../custom-ui/headings/SectionHeading';
import DragAndDropVariantB from '../custom-ui/drag-and-drop/DragAndDropFileSmall';
import { upload } from '../../storage/storage';

const AddAFile = (
  {
    dataReturn,
    setName,
    toggle, 
    userId,
    chatId
  }:{
    dataReturn: Function
    toggle: Function
    setName: Function
    userId: String
    chatId: String
  }
) => {
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (file!== null) {
      console.log(file);
    }},[file])

  const confirmUpload = () => {
    var seconds = new Date().getTime() / 1000;
    upload(file, "messageFiles", `${seconds}_${file?.name}`).then(
      (fileUrl) => {
        dataReturn(fileUrl);
        setName(file?.name)
        toggle();
      },
    );
  };

  return (
    <div className='w-full'>
      <div className='w-full'>
        <DragAndDropVariantB setFile={setFile} />
      </div>
      <div className="mt-4 grid grid-cols-2 gap-2">
        <div>
          <button
            className="w-full px-4 py-2 h-auto text-white bg-instant-teams-blue-Main border-2 border-instant-teams-blue-Main rounded hover:bg-white hover:text-instant-teams-blue-Main focus:outline-none text-sm"
            onClick={() => confirmUpload()}
          >
            Upload and Send
          </button>
        </div>
        <div>
          <button
            className="w-full px-4 py-2 h-auto bg-white text-instant-teams-blue-Main rounded border-2 border-instant-teams-blue-Main hover:bg-instant-teams-blue-Main hover:text-white focus:outline-none text-sm"
            onClick={() => toggle()}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAFile;
