import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAllActiveApplications } from "../../../../data/seeker";
import SeekerEmploymentListItem from './seekerEmploymentListItem';
import {useEmploymentByEmployee} from "../../../../data/employee";

function SeekerEmploymentList({ id }: { id: string }) {
  const { data: applications } = useEmploymentByEmployee(id);
  const params = useParams();
  useEffect(() => {
    console.log(applications);
  }, [applications]);

  return (
    <>
      <div className="w-full text-base text-black font-normal mt-4">
        {applications?.length !== 0 && applications !== null ? (
          applications?.map((row: any, index: string) => {
            return <SeekerEmploymentListItem entry={row} key={index} />;
          })
        ) : (
          <div className="text-lg text-center text-instant-teams-teal-Main w-full">
            No Employment Records
          </div>
        )}
      </div>
    </>
  );
}

export default SeekerEmploymentList;
