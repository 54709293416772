import { forwardRef, useEffect, useState } from "react";
import { isDate } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faCircleXmark,
} from "@fortawesome/pro-regular-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../styles/DatePickerOverrides.css";
import { updateAutoSave } from "../../../data/utility";
import { isNil } from "lodash";
// react-datePicker examples: https://reactdatepicker.com/
// react-datePicker docs: https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md

function AutoSaveDateRange({
  id,
  collection,
  name,
  initialStart,
  initialEnd,
  disabled = false,
  ...props
}) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    if (isDate(initialStart)) {
      setStartDate(initialStart);
    }
    if (isDate(initialEnd)) {
      setEndDate(initialEnd);
    }
    console.log(startDate, endDate);
  }, []);

  useEffect(() => {
    if (!isNil(startDate) && !isNil(endDate)) {
      updateAutoSave(collection, id, { [name]: { startDate, endDate } })
        .then(() => console.log("Saved range"))
        .catch((error) => console.log("Error saving range: ", error));
    }
  }, [collection, id, startDate, endDate]);

  const CustomDateInput = forwardRef(({ value, onClick, disabled }, ref) => (
    <div id={name} className="custom-date-input w-full">
      <input
        disabled={disabled}
        ref={ref}
        className="px-8 w-full"
        type="text"
        value={value}
        onClick={onClick}
        onChange={(e) => {
          e.preventDefault();
        }}
        placeholder="Select date"
      />
      <span className="absolute left-2 top-1.5" onClick={onClick}>
        <FontAwesomeIcon
          icon={faCalendarDays}
          className="text-instant-teams-gray-D1"
        />
      </span>
    </div>
  ));

  return (
    <div className="grid gap-2 sm:grid-cols-1 md:grid-cols-1  grid-cols-2">
      <div className="col-auto">
        <DatePicker
          showIcon
          minDate={new Date()}
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          isClearable={!disabled} // prevent user from clearing input when component is disabled
          customInput={<CustomDateInput />}
          disabled={disabled}
          {...props}
        />
      </div>
      <div className="col-auto">
        <DatePicker
          showIcon
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          isClearable={!disabled} // prevent user from clearing input when component is disabled
          customInput={<CustomDateInput />}
          disabled={disabled}
          {...props}
        />
      </div>
    </div>
  );
}

export default AutoSaveDateRange;
