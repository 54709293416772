import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../providers/auth";

// usage:
// useRoleGuard() - navigates to dashboard if not sadmin
// useRoleGuard('admin', '/jobs') - navigates to jobs if not admin

// role: sadmin, admin etc
// route: /dashboard, /signin etc
function useRoleGuard(role = "sadmin", route = "/dashboard") {
  const { userClaims } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (userClaims?.claims?.role !== role) {
      navigate(route, { replace: true });
    }
    return () => {};
  }, [userClaims, navigate, role, route]);
}

export default useRoleGuard;
