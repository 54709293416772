import React, { useState, useEffect } from "react";
import { useUser, useUserStatus } from "../../../../data/user";
import { useLookupCode } from "../../../../data/lookup";
import { isString } from "lodash";
import { Invitation } from "../../../../dataTypes/Invitation";
import { Timestamp } from "firebase/firestore";
import { TailwindHoverEnlarge } from "../../../../util/tailwind/tailwind-styles";
const InvitationRecord = ({ invitation }: { invitation: Invitation }) => {
  const [invitedBy, setInvitedBy] = useState(invitation?.invitedBy);
  const [role, setRole] = useState("");

  const {
    data: userData = {},
    status: userStatus = "",
    error: userError,
  } = useUser(invitedBy);

  const {
    data: statusData = {},
    status: statusStatus = "",
    error: statusError,
  } = useUserStatus(userData?.status);

  const {
    data: roleData = {},
    status: roleStatus = "",
    error: roleError,
  } = useLookupCode(role);

  useEffect(() => {
    setRole(invitation?.employerRole);
  }, [invitation]);

  return (
    <tr key={invitation?.id} className={TailwindHoverEnlarge(103) + "border-b"}>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {invitation?.name}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {userData?.name}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {isString(invitation?.status) ? invitation?.status.toUpperCase() : ""}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {Object.keys(roleData)?.length > 0 ? roleData[0]?.label : ""}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {invitation?.email}
      </td>
    </tr>
  );
};

export default InvitationRecord;
