import React, { useEffect, useState, useCallback } from "react";

const generateFilters = (skills, tacVerified, exactMatch, selectedOptions, affiliation) => {

    let filters = 'claims.type:"S"';

    if (skills && skills.length > 0) {
        const skillsFilter = skills
            .map((skill) => {
                const skillQuery = `"${skill.skill}"`;
                if (tacVerified) {
                    return `(Skills.tac_verified_skills.skill:${skillQuery} OR Skills.tac_tags.skill:${skillQuery})`;
                } else {
                    return `(Skills.tac_verified_skills.skill:${skillQuery} OR Skills.tac_tags.skill:${skillQuery} OR Skills.seeker_tagged_skills.skill:${skillQuery})`;
                }
            })
            .join(exactMatch ? " AND " : " OR ");
        filters += ` AND (${skillsFilter})`;
    }

    if (selectedOptions && selectedOptions.length > 0) {
        const countryFilters = selectedOptions
            .map((country) => `(address.state:${country} OR address.country:${country})`)
            .join(" OR ");
        filters += ` AND (${countryFilters})`;
    }

    if (affiliation.milVerified) {
        filters += ` AND claims.milVerified:true`;
    }

    if (affiliation.milSpouse) {
        filters += ` AND claims.milspouse:true`;
    }

    return filters;
}

export { generateFilters };
