import { useFindLookup } from "../../../data/lookup";

const DisplayItemLookup = ({
  value: initialValue,
  group,
}: {
  value: string;
  group: string;
}) => {
  const { data: displayData } = useFindLookup(group, initialValue);

  return (
    <div className="flex-auto text-center">
      {displayData ? (
        displayData.map((key: any, index: number) => (
          <span key={key.id}>
            {key.label}
            {index !== displayData.length - 1 && "/"}
          </span>
        ))
      ) : (
        <span>Not Set</span>
      )}
    </div>
  );
};

export default DisplayItemLookup;
