/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useComponent } from "../../../data/academy";
import { useGlobalState } from "../../../providers/globalState/GlobalStateProvider";
import { useAuth } from "../../../providers/auth";
import { Timestamp } from "firebase/firestore";
import { createSyncRequest } from "../../../data/academy";
import toast from "react-hot-toast";
import ToastAlert from "../../custom-ui/toast-alert/ToastAlert";
import CustomTestDetails from "../parts/customTestDetails";
import { splitCapitalize } from "../../../util/strings/stringManipulation";
import AutoSaveSelectEmployer from "../../custom-ui/input-fields/auto-save-select-employer";
import AutoSaveWysiwyg from "../../custom-ui/input-fields/auto-save-wysiwyg";
import AutoSaveNumber from "../../custom-ui/input-fields/auto-save-number";
import useModal from "../../../hooks/useModal";
import AssessmentBadge from "../parts/assessment-logo";
import AutoSaveStatusSelector from "../../custom-ui/input-fields/statusSelector";
import TagListing from "../../tags/tagList";
import AutoSaveText from "../../custom-ui/input-fields/auto-save-field";
import TestResults from "../parts/testResults";
import AutoSaveSelectByLookup from "../../custom-ui/input-fields/auto-save-select-by-lookup";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import SkillFamily from "../../tags/SkillFamily";
import AutoSaveSelectSkillFamily from "../../custom-ui/input-fields/auto-save-select-skill-family";

function Assessment() {
  const params = useParams();
  const { user } = useAuth();
  const { globalState, setGlobalState } = useGlobalState();
  const [componentId, setComponentId] = useState(params.assessmentId || "");
  const [name, setName] = useState("Instant Teams Academy");
  const [autoDuration, setAutoDuration] = useState(0);
  const [badge, setBadge] = useState("/images/badge.png");
  const [tags, setTags] = useState([]);
  const [family, setFamily] = useState([]);

  const { isShowing: showBadgeEditModal, toggle: toggleBadgeEditModal } =
    useModal();

  const editorStyle = {
    height: `150px`,
    overflow: "hidden",
    border: "1px solid #ccc",
    padding: "8px",
  };

  const startSync = () => {
    const request = {
      source: "tg",
      type: "extended",
      section: "courses",
      id: componentId,
      timestamp: Timestamp.now(),
      who: user.uid,
    };

    const update = {
      source: "tg",
      type: "candidate",
      section: "courses",
      id: componentId,
      timestamp: Timestamp.now(),
      who: user.uid,
      cycle: {
        limit: 25,
        offset: 0,
      },
    };
    createSyncRequest(request);
    createSyncRequest(update);
    toast.success("Sync Started");
  };

  useEffect(() => {
    setComponentId(params.assessmentId);
  }, [params]);

  const { data: component } = useComponent(componentId);

  const [visability, setVisability] = useState(component?.visable || "created");

  useEffect(() => {
    setName(component?.name || "Academy");
  }, [component?.name]);

  useEffect(() => {
    setGlobalState({ ...globalState, pageTitle: name });
  }, [name]);

  useEffect(() => {
    let duration = 0;
    if (component?.custom_tests_detail) {
      for (const detail of component.tests_detail) {
        duration += detail?.test?.duration;
      }
    }
    if (autoDuration === 0) {
      setAutoDuration(Math.ceil(duration / 60));
    }
    if (component?.badge !== undefined && component?.badge !== null) {
      setBadge(component.badge);
    }
    if (component?.visable !== undefined && component?.visable !== null) {
      setVisability(component?.visable);
    }
    if (component?.tags !== undefined && component?.tags !== null) {
      setTags(component?.tags);
    }
    if (
      component?.skillFamily !== undefined &&
      component?.skillFamily !== null
    ) {
      setFamily(component?.skillFamily);
    }
  }, [component]);

  return (
    <div>
      <div className="flex justify-between w-full">
        <div className="w-1/2 items-center">
          <h1 className="text-2xl font-bold">{name}</h1>
        </div>
        <button
          className="py-2 px-4 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none ml-auto"
          onClick={startSync}
        >
          Sync Assessment
        </button>
      </div>
      <div className="w-full mt-5 flex">
        <div className="w-1/3 mt-10">
          <AutoSaveSelectEmployer
            value={component?.employerId || "0"}
            collection={"AcademyAssessments"}
            recId={componentId}
            required={false}
            name={"employerId"}
            display={"For Employer"}
            placeholder={"Select Employer"}
            directions={"Training to be used by this employer only"}
            readOnly={false}
          />
          <AutoSaveNumber
            value={
              component?.duration !== undefined
                ? Number(component.duration)
                : ""
            }
            collection={"AcademyAssessments"}
            recId={componentId}
            required={false}
            name={"duration"}
            display={"Assessment Duration"}
            placeholder={"How long does this course take?"}
            directions={`EST: ${autoDuration} minutes`}
            readOnly={false}
            minValue={0}
            maxValue={1440}
          />
          <AutoSaveText
            value={component?.displayName}
            collection="AcademyAssessments"
            recId={componentId}
            required={false}
            name="displayName"
            display="Display Name"
            placeholder={"Display Name"}
            directions=""
            readOnly={false}
            maxlength={40}
          />
          <AutoSaveSelectSkillFamily
            value={component?.skillFamily}
            collection={"AcademyAssessments"}
            recId={componentId}
            required={false}
            name="skillFamily"
            display="Skill Family"
            placeholder="Skill Family"
            directions=""
            readOnly={false}
          />
        </div>
        <div className="w-1/3 pr-5 pl-8">
          <div className="flex justify-between w-full">
            <div className="w-1/2 items-center text-center text-instant-teams-teal-Main font-bold pr-10 ">
              <GreenUnderlineHeading title="Badge" />
              <img
                src={badge}
                alt={name}
                className="mx-auto max-w-50 h-auto cursor-pointer"
                onClick={() => toggleBadgeEditModal()}
              />
              {showBadgeEditModal ? (
                <AssessmentBadge toggleModal={toggleBadgeEditModal} />
              ) : null}
            </div>
            <div className="w-1/2 items-center text-center text-instant-teams-teal-Main font-bold">
              <GreenUnderlineHeading title="Tiers" />
              <AutoSaveNumber
                value={
                  component?.tier?.three !== undefined
                    ? component?.tier?.three
                    : "0"
                }
                collection={"AcademyAssessments"}
                recId={componentId}
                required={false}
                name={"tier.three"}
                display={"Tier 3 - Minimum Score"}
                placeholder={"Tier 3"}
                directions={``}
                readOnly={false}
                minValue={0}
                maxValue={99}
              />
              <AutoSaveNumber
                value={
                  component?.tier?.two !== undefined
                    ? component?.tier?.two
                    : "0"
                }
                collection={"AcademyAssessments"}
                recId={componentId}
                required={false}
                name={"tier.two"}
                display={"Tier 2 - Minimum Score"}
                placeholder={"Tier 2"}
                directions={``}
                readOnly={false}
                minValue={0}
                maxValue={99}
              />
              <AutoSaveNumber
                value={
                  component?.tier?.one !== undefined
                    ? component?.tier?.one
                    : "0"
                }
                collection={"AcademyAssessments"}
                recId={componentId}
                required={false}
                name={"tier.one"}
                display={"Tier 1 - Minimum Score"}
                placeholder={"Tier 1"}
                directions={``}
                readOnly={false}
                minValue={0}
                maxValue={99}
              />
            </div>
          </div>
        </div>
        <div className="w-1/3 ml-10">
          <GreenUnderlineHeading title="Metrics" />
          <div className="w-full flex">
            <span className="float-left w-1/2">Candidates: </span>
            <span className="float-right w-1/2">{component?.candidates}</span>
          </div>
          <div className="w-full flex">
            <span className="float-left w-1/2">Invited: </span>
            <span className="float-right w-1/2">{component?.invited}</span>
          </div>
          <div className="w-full flex">
            <span className="float-left w-1/2">Started: </span>
            <span className="float-right w-1/2">{component?.started}</span>
          </div>
          <div className="w-full flex">
            <span className="float-left w-1/2">Completed: </span>
            <span className="float-right w-1/2">
              {component?.finished || 0}
            </span>
          </div>
          <div className="w-full flex">
            <span className="float-left w-1/2">Completion: </span>
            <span className="float-right w-1/2">
              {component?.finished_percentage || 0}%
            </span>
          </div>
          <div className="w-full flex">
            <span className="float-left w-1/2">Benchmark: </span>
            <span className="float-right w-1/2">
              {component?.benchmark_name}
            </span>
          </div>
          <div className="w-full flex">
            <span className="float-left w-1/2">Candidate Source: </span>
            <span className="float-right w-1/2">
              {component?.candidates_source}
            </span>
          </div>
          <div className="w-full flex">
            <span className="float-left w-1/2">Type: </span>
            <span className="float-right w-1/2">
              {component?.content_type_name
                ? splitCapitalize(component?.content_type_name, ".")
                : "Unknown"}
            </span>
          </div>
          <div className="w-full flex">
            <span className="float-left w-1/2">Email Edited: </span>
            <span className="float-right w-1/2">
              {component?.has_user_edited_invitation_email === false
                ? "No"
                : "Yes"}
            </span>
          </div>
          <div className="w-full flex">
            <span className="float-left w-1/2">Highlighted: </span>
            <span className="float-right w-1/2">
              {component?.is_highlighted === false ? "No" : "Yes"}
            </span>
          </div>
          <div className="w-full flex">
            <span className="float-left w-1/2">Snapshots: </span>
            <span className="float-right w-1/2">
              {component?.use_snapshots === false ? "No" : "Yes"}
            </span>
          </div>
          <div className="w-full flex">
            <span className="float-left w-1/2">Feedback Survey: </span>
            <span className="float-right w-1/2">
              {component?.is_showing_hiring_feedback_survey === false
                ? "No"
                : "Yes"}
            </span>
          </div>
          <div className="w-full flex">
            <span className="float-left w-1/2 italic">Extra Time: </span>
          </div>
          <div className="w-full flex">
            <span className="float-left w-1/2 ml-5">Non-Native Speaker:</span>
            <span className="float-right w-1/2">
              {component?.permitted_extra_time_non_native_speakers === false
                ? "No"
                : "Yes"}
            </span>
          </div>
          <div className="w-full flex">
            <span className="float-left w-1/2 ml-5">Capacity Limitation:</span>
            <span className="float-right w-1/2">
              {component?.permitted_extra_time_person_for_other_capacities ===
              false
                ? "No"
                : "Yes"}
            </span>
          </div>
        </div>
      </div>
      <div className="w-full mt-5 flex">
        <div className="w-full mr-20">
          <div className="flex justify-between w-full">
            <TagListing
              currentTags={tags}
              collection={"AcademyAssessments"}
              recId={componentId}
              name={"tags"}
            />
          </div>
          <div className="grid grid-cols-2 gap-8">
            <AutoSaveWysiwyg
              value={component?.shortDescription}
              collection={"AcademyAssessments"}
              recId={componentId}
              required={false}
              rows={10}
              name={"shortDescription"}
              display={"Assessment Short Description"}
              placeholder={"Add a Description"}
              directions={"Short description of the Assessment"}
              readOnly={false}
              maxLength={250}
            />
            <AutoSaveWysiwyg
              value={component?.description || ""}
              collection={"AcademyAssessments"}
              recId={componentId}
              required={false}
              rows={10}
              name={"description"}
              display={"Assessment Description"}
              placeholder={"Add a Description"}
              directions={"Full description of the Assessment"}
              readOnly={false}
              maxLength={750}
            />
          </div>
        </div>
      </div>
      <ToastAlert />
    </div>
  );
}

export default Assessment;
