import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "./auth";
import { analyticsLog } from "../data/utility";

function AnalyticsLogger({ type, typeId }) {
  const location = useLocation();
  const auth = useAuth();

  useEffect(() => {
    analyticsLog(
      type,
      typeId,
      auth?.userClaims?.claims.user_id,
      location.pathname,
    ).catch((error) => console.log(error));
  }, [auth?.userClaims?.claims.user_id, location, type, typeId]);

  return null;
}

export default AnalyticsLogger;
