import React, { useState, useEffect } from "react";
import { useAuth } from "../../providers/auth";
import { errorLogger } from "../../providers/clientLogger";
import MultiFactorSignIn from "./multi-factor/MultiFactorSignIn";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import { friendlyFirebaseError } from "../../providers/firebase-errors/friendlyFirebaseError";
import { sliLogger } from "../../callable-cloud-functions/cloudFunctions";

function AuthSocial(props) {
  const auth = useAuth();
  const { handleMultiFactorSignIn } = auth;

  const [pending, setPending] = useState(null);

  const [shouldMfaStart, setShouldMfaStart] = useState(false);
  const [mfaResolver, setMfaResolver] = useState();

  const onSigninWithProvider = (provider) => {
    setPending(provider);
    auth
      .signinWithProvider(provider)
      .then((user) => {
        // Remember this provider was last used
        // so we can indicate for next login.
        localStorage.setItem("lastUsedAuthProvider", provider);
        props.onAuth(user);
      })
      .catch((error) => {
        setPending(null);
        console.log("onSigninWithProvider errored", error);
        if (error.code === "auth/multi-factor-auth-required") {
          try {
            // User is a multi-factor user. Second factor challenge is required.
            handleMultiFactorSignIn(
              error,
              setShouldMfaStart,
              setMfaResolver,
              props.onFormAlert,
            );
            sliLogger({
              message: "MFA user login successful",
              sevLevel: "INFO",
              messageContext: { details: "Frontend SAML login" },
              resourceType: "Firebase Authentication",
              action: "Login",
              resultStatus: "success",
              cuj: "User Login",
            });
          } catch (error) {
            sliLogger({
              message: "MFA user login unsuccessful",
              sevLevel: "INFO",
              messageContext: { details: "Frontend SAML login" },
              resourceType: "Firebase Authentication",
              action: "Login",
              resultStatus: "success",
              cuj: "User Login",
            });
          }
        } else {
          errorLogger(error.message, {
            component: "AuthSocial onSigninWithProvider",
            flow: "Internal Sign in ",
            userId: "N/A",
          });
          props.onFormAlert({
            type: "error",
            message: friendlyFirebaseError(error),
          });
          // SLI Logger
          if (
            friendlyFirebaseError(error) ===
            "An error occurred. Please try again later."
          ) {
            sliLogger({
              message: "Authentication failed",
              sevLevel: "ERROR",
              messageContext: {
                details: "Frontend SAML login",
                error: error.message,
              },
              resourceType: "Firebase Authentication",
              action: "Login",
              resultStatus: "success",
              cuj: "User Login",
            });
          } else {
            sliLogger({
              message:
                "Failed to authenticate user, but not an error on our part",
              sevLevel: "INFO",
              messageContext: { details: "Frontend SAML login" },
              resourceType: "Firebase Authentication",
              action: "Login",
              resultStatus: "success",
              cuj: "User Login",
            });
          }
        }
      });
  };

  return (
    <div>
      {!shouldMfaStart && (
        <>
          {props.providers.map((provider) => (
            <div className="mb-2" key={provider}>
              <button
                className="py-2 px-4 w-full font-bold bg-instant-teams-gray-Main text-instant-teams-teal-Main rounded border-0 hover:bg-white focus:outline-none"
                onClick={() => {
                  onSigninWithProvider(provider);
                }}
                disabled={pending === provider}
              >
                {pending === provider && <>...</>}

                {pending !== provider && <>Admin Login</>}
              </button>
            </div>
          ))}
        </>
      )}

      {shouldMfaStart && (
        <DialogWrapper
          title="MFA Authentication"
          onClose={() => setShouldMfaStart(false)}
        >
          <MultiFactorSignIn
            mfaResolver={mfaResolver}
            onFormAlert={props.onFormAlert}
            onAuth={props.onAuth}
          />
        </DialogWrapper>
      )}
    </div>
  );
}

export default AuthSocial;
