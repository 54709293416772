import { Outlet } from "react-router-dom";
import Meta from "../../components/custom-ui/meta/Meta";
import { requireAuth } from "../../providers/auth";

function JobPage() {
  return (
    <div>
      <div className="md:container pb-32 w-full">
        <Meta title="Job Management" />
        <Outlet />
      </div>
    </div>
  );
}

export default requireAuth(JobPage);
