import { useEffect, useState } from "react";
import {
  useUsersByGeo,
  getUsersByLocationReferences,
  configureGeoFalsePositiveFilter,
} from "../../data/geoQuery";

import AddressForm from "../address/AddressForm";
import GreenUnderlineHeading from "../custom-ui/headings/heading";
import SeekerMap from "./SeekerMap";
import { useLookup } from "../../data/lookup";
import { LookUp } from "../../dataTypes/LookUp";
import { getDocs } from "firebase/firestore";

import CSVDownload from "../csvDownload/CSVDownload";
import { formatFileName } from "../../util/strings/stringManipulation";

function GeoSeeker() {
  const [centerLatLong, setCenterLatLong] = useState();
  const [centerAddress, setCenterAddress] = useState("");
  const [distance, setDistance] = useState(25);

  const [references, setReferences] = useState([]);

  const [data, setData] = useState([]);

  useEffect(() => {
    const { latitude, longitude } = centerLatLong ?? {};
    if (latitude && longitude && distance) {
      getUsersByLocationReferences(
        "SKR",
        latitude,
        longitude,
        distance,
        setReferences,
      );
    }
  }, [centerLatLong, distance]);

  useEffect(() => {
    aggregateGeoData();
  }, [references]);

  async function aggregateGeoData() {
    const geoDocs = [];
    for (const ref of references) {
      const snapshot = await getDocs(ref);
      for (const doc of snapshot.docs) {
        const data = doc.data() ?? {};
        geoDocs.push({ ...data, id: doc.id });
      }
    }

    setData(geoDocs);
  }

  const findWorkModelCount = (modelCode: string) => {
    return data.filter((seeker: any) => {
      return seeker.models.find((model: string) => model === modelCode);
    }).length;
  };

  // async because onSubmit prop expects a promise
  const getCentralPoint = async (values: any, addressResult: any) => {
    setCenterAddress(addressResult.address.standardizedAddress);
    const { latitude, longitude } = addressResult?.address ?? {};
    setCenterLatLong({ latitude, longitude });
  };

  const filterCsvData = (data: any) => {
    return data.map((doc: any) => {
      const { name, preferredName, email, phone, models, address } = doc;
      return {
        name,
        preferredName,
        email,
        phone,
        models,
        address: address.standardizedAddress,
      };
    });
  };

  const {
    data: mileRadiusList = [],
    status: mileRadiusListStatus = "",
    error: mileRadiusListError,
  } = useLookup("mile_radius");

  return (
    <div className="grid gap-4 grid-cols-3">
      <div className="col-span-1">
        <GreenUnderlineHeading title="Address" />
        <AddressForm
          id={"test"}
          addressLabel="primary"
          onSubmit={getCentralPoint}
          onDone={() => console.log("done")}
        />

        <GreenUnderlineHeading title="Location Radius">
          <span className="px-1">{data.length} Seekers</span>
        </GreenUnderlineHeading>
        <div className="flex justify-between my-4">
          <select
            onChange={(e) => {
              setDistance(e.target.value);
            }}
            className="py-1 leading-8 bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-1"
          >
            <option value="" disabled>
              Select a distance
            </option>
            {mileRadiusList.map((miles: LookUp) => (
              <option
                key={miles.id}
                value={miles.code}
                selected={miles.code === distance}
              >
                {miles.label}
              </option>
            ))}
          </select>

          <CSVDownload
            data={filterCsvData(data)}
            fileName={`OutreachByLocation_${formatFileName(centerAddress)}.csv`}
          />
        </div>

        <div>
          <GreenUnderlineHeading title="Work Preferences" />
          <div className="text-instant-teams-teal-D1 font-bold grid grid-cols-4 gap-2">
            <div className="text-lg uppercase text-left col-span-2">Remote</div>{" "}
            <div className="col-span-2 text-right">
              {findWorkModelCount("WFH")} Seekers
            </div>
          </div>
          <div className="text-instant-teams-teal-D1 font-bold grid grid-cols-4 gap-2">
            <span className="text-lg uppercase text-left col-span-2">
              Hybrid{" "}
            </span>{" "}
            <span className="col-span-2 text-right">
              {findWorkModelCount("HYB")} Seekers
            </span>
          </div>
          <div className="text-instant-teams-teal-D1 font-bold grid grid-cols-4 gap-2">
            <span className="text-lg uppercase text-left col-span-2">
              In-Person{" "}
            </span>{" "}
            <span className="col-span-2 text-right">
              {findWorkModelCount("PHY")} Seekers
            </span>
          </div>
          <div className="text-instant-teams-teal-D1 font-bold grid grid-cols-6 gap-2 mt-4">
            <div className="text-xs w-full text-center">
              <div className="w-8 h-2 bg-[#FF2929] mx-auto"></div>None
            </div>
            <div className="text-xs w-full text-center">
              <div className="w-8 h-2 bg-[#0288D1] mx-auto"></div>Remote
            </div>
            <div className="text-xs w-full text-center">
              <div className="w-8 h-2 bg-[#F57C00] mx-auto"></div>On-Site
            </div>
            <div className="text-xs w-full text-center">
              <div className="w-8 h-2 bg-[#097138] mx-auto"></div>Hybrid
            </div>
            <div className="text-xs w-full text-center">
              <div className="w-8 h-2 bg-[#673AB7] mx-auto"></div>Multiple
            </div>
            <div className="text-xs w-full text-center">
              <div className="w-8 h-2 bg-[#880E4F] mx-auto"></div>All
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-2 p-4">
        {data && centerLatLong && (
          <SeekerMap
            seekers={data}
            center={centerLatLong}
            radiusInMiles={distance}
          />
        )}
      </div>
    </div>
  );
}

export default GeoSeeker;
