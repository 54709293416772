import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import DialogWrapper from "../../../custom-ui/dialog/Dialog";
import Tooltip from "../../../custom-ui/tooltip/tooltip";
import { useSkills } from "../../../../data/academy";
import useModal from "../../../../hooks/useModal";
import { useUser } from "../../../../data/user";
import {
  assignBadgeAssessment,
  createEnrollmentProcess,
} from "../../../../data/academy";
import ToastAlert from "../../../custom-ui/toast-alert/ToastAlert";
import toast from "react-hot-toast";
import { useEnrollmentsByEmail } from "../../../../data/enrollments";
import { useAuth } from "../../../../providers/auth";
import { Timestamp } from "firebase/firestore";
import { getLevel } from "../../../../util/assessments/level";

function AssignBadge({ seekerId }: { seekerId: string }) {
  const { data: seekerData } = useUser(seekerId);
  const { data: assessmentData = [] } = useSkills();
  const { data: enrollments = [] } = useEnrollmentsByEmail(seekerData?.email);

  const [filteredAssessmentList, setFilteredAssessmentList] = useState([]);

  useEffect(() => {
    // filter existing enrollments
    if (!isEmpty(assessmentData)) {
      const filteredAssessmentList = assessmentData?.filter((item: any) => {
        return !enrollments.find(
          (enrollment: any) => enrollment.assessmentId === item.id,
        );
      });

      setFilteredAssessmentList(filteredAssessmentList);
    }
  }, [assessmentData, enrollments]);

  const { isShowing, toggle } = useModal();

  return (
    <>
      <div className="flex justify-end">
        <button
          className="w-48 mb-5 min-h-12 h-12 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
          onClick={toggle}
        >
          Assign Badge
        </button>
      </div>
      {isShowing && !isEmpty(filteredAssessmentList) && (
        <div className="h-screen">
          <DialogWrapper
            size="w-3/4 mt-36"
            title="Assign Badge"
            onClose={toggle}
          >
            {filteredAssessmentList.map((assessment: any) => (
              <AssessmentItemBasic
                assessment={assessment}
                seekerId={seekerId}
              />
            ))}
          </DialogWrapper>
        </div>
      )}
    </>
  );
}

function AssessmentItemBasic({
  assessment,
  seekerId,
}: {
  assessment: any;
  seekerId: string;
}) {
  const { data: seekerData } = useUser(seekerId);
  const auth = useAuth();
  const user = auth?.user;
  const { name: seekerName, email } = seekerData ?? {};
  const { isShowing: isShowingConfirmModal, toggle: toggleConfirmModal } =
    useModal();

  const {
    id: assessmentId,
    displayName,
    name,
    level,
    badge,
    visable,
    status,
    tier,
    duration,
    finished_percentage,
  } = assessment ?? {};

  return (
    <div
      key={assessment.id}
      onClick={toggleConfirmModal}
      className="my-2 h-20 cursor-pointer"
    >
      <div className="w-full h-full grid grid-cols-8 grid-flow-row bg-white shadow-md rounded-lg">
        <div className="flex items-center col-span-2 rounded-l-lg bg-instant-teams-teal-Main text-white">
          <p className="text-center text-lg font-bold w-full">
            {displayName || name}
          </p>
        </div>
        <div className="items-center align-middle">
          <img
            src={badge || "/images/badge.png"}
            alt={name}
            className="mx-auto w-20 h-20 rounded-xl "
          />
        </div>
        <div className="w-full flex items-center text-center text-sm">
          <span className="text-instant-teams-teal-Main font-bold">
            {visable === "active" ? "VISIBLE" : "HIDDEN"}
          </span>
        </div>
        <div className="w-full flex items-center text-center text-sm">
          <span className="text-instant-teams-teal-Main font-bold">
            {status.toUpperCase() || null}
          </span>
        </div>
        <div className="w-full flex items-center text-center text-sm">
          <span className="text-instant-teams-teal-Main font-bold">
            {tier
              ? tier?.one + "/" + tier?.two + "/" + tier?.three
              : "NO TIERS"}
          </span>
        </div>
        <div className="w-full flex items-center text-center text-sm">
          <Tooltip text="Completion Time">
            <div className="  text-instant-teams-teal-Main font-bold">
              {duration || "??"} mins
            </div>
          </Tooltip>
        </div>
        <div className="w-full flex items-center text-center text-sm">
          <Tooltip text="Completion Rate">
            <div className="  text-instant-teams-teal-Main font-bold">
              {finished_percentage || 0}%
            </div>
          </Tooltip>
        </div>
      </div>
      {/* Confirmation Dialog */}
      {isShowingConfirmModal && (
        <DialogWrapper
          title="Confirm Badge Assignment for: "
          onClose={toggleConfirmModal}
          size="mt-36 w-3/4 h-full"
        >
          <div>
            <div>
              <strong>{seekerName ?? ""}</strong>
            </div>
            <div>
              <img
                src={badge || "/images/badge.png"}
                alt={name}
                className="mx-auto my-auto w-20 h-20 rounded-xl "
              />
              <p className="text-center text-lg font-bold w-full">
                {displayName || name}
              </p>
            </div>
            <div className="flex space-x-4 my-4">
              <button
                className="w-full min-h-12 h-12  text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                type="button"
                onClick={() => {
                  assignBadgeAssessment({
                    assessmentId: assessmentId?.toString(),
                    average: 100,
                    content_type_name: "test_taker.assessmentcandidature",
                    email,
                    full_name: seekerName,
                    status: "completed",
                    assessedBy: user?.id,
                    manuallyAssessed: true,
                    manuallyAssessedDate: Timestamp.now(),
                  })
                    .then(() => {
                      toast.success(
                        `Assigned ${displayName || name}  to: ${seekerName}`,
                      );
                    })
                    .catch((error) => console.log(error));
                }}
              >
                Assign Badge
              </button>
              <button
                className="w-full min-h-12 h-12  text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                type="button"
                onClick={async () => {
                  const request = {
                    action: "add",
                    assessmentId,
                    email: seekerData?.email,
                    name: seekerName,
                    timestamp: Timestamp.now(),
                    who: "Assign to Seeker",
                  };
                  await createEnrollmentProcess(request)
                    .then(() => {
                      toast.success(
                        `Enrolled ${seekerName} in ${displayName || name} `,
                      );
                    })
                    .catch((error) => console.log(error));
                }}
              >
                Enroll
              </button>
              <button
                className="w-full min-h-12 h-12  text-white bg-instant-teams-gray-D1 rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                type="button"
                onClick={toggleConfirmModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </DialogWrapper>
      )}
      <ToastAlert />
    </div>
  );
}

export default AssignBadge;
