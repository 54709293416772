import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useAuth } from "../../providers/auth";
import {
  useAllChatsByRecipientId,
  useAllChatsByGroup,
} from "../../data/messages";

const MessageList = () => {
  const { user } = useAuth();
  const [chatIds, setChatIds] = useState([]);
  const [userId, setUserId] = useState(user?.id);
  const [role, setRole] = useState(user?.claims?.role);
  const [type, setType] = useState(user?.claims?.type);
  const [count, setCount] = useState(0);
  const { data: recipientChats } = useAllChatsByRecipientId(userId, "inbox");
  const { data: groupChats } = useAllChatsByGroup(role, "inbox");

  useEffect(() => {
    setUserId(user?.id);
    setRole(user?.claims?.role);
    setType(user?.claims?.type);
  }, [user]);

  useEffect(() => {
    if (type === "A") {
      if (groupChats) {
        setChatIds([...groupChats]);
      }
    } else {
      if (recipientChats) {
        setChatIds([...recipientChats]);
      }
    }
  }, [recipientChats, groupChats, type]);

  useEffect(() => {
    let count = 0;
    if (chatIds?.length > 0 && userId) {
      chatIds.forEach((chat: any) => {
        const unreadUsers = chat.unreadUsers;
        if (unreadUsers && unreadUsers.includes(userId)) {
          count++;
        }
      });
    }
    setCount(count);
  }, [chatIds, userId]);

  return (
    <>
      <Link
        to={`/messages/${user?.uid}`}
        className="flex relative h-full w-12 mt-2 pb-2 hover:pb-1 hover:border-b-4 hover:border-green-400 overflow-hidden justify-center items-center"
      >
        <FontAwesomeIcon icon={faMessage} className="h-6 w-6" />
        {count > 0 && (
          <div className="h-5 w-5 absolute top-4 left-2 rounded-full bg-red-700 flex justify-center items-center text-xs text-center">
            {count}
          </div>
        )}
      </Link>
    </>
  );
};
export default MessageList;
