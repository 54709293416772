import {
  usePositionsByEmployer,
  usePositionsByDepartment,
} from "../../../../data/positions";
import { TailwindHoverEnlarge } from "../../../../util/tailwind/tailwind-styles";
import { useEmployerJobs } from '../../../../data/employer';
import { useEffect, useState } from 'react';
import { useApplicationsByJobId } from '../../../../data/applications';
import { useEmployeesByJobId } from '../../../../data/employee';

const DepartmentNotAssigned = (employerId: any) => {
  const {data: employerJobs } = useEmployerJobs(employerId);
  const[jobList, setJobList] = useState([]);
  const { data: applications = [] } = useApplicationsByJobId(jobList);
  const { data: employees = [] } = useEmployeesByJobId(jobList);

  useEffect(() => {
    if(employerJobs) {
      const jobListing = [];
      for(const job of employerJobs) {
        if(!job.hasOwnProperty('department')) {
          jobListing.push(job.id);
        }
      }
      console.log(jobListing);
      setJobList(jobListing);
    }
  },[employerJobs]);

  return (
    <div
      className={
        TailwindHoverEnlarge(103) +
        "rounded-md border border-black border-solid shadow-md bg-white p-3"
      }
    >
      <div className="text-xl flex items-center justify-center w-100">
        <label className="text-instant-teams-teal-Main text-sm text-left">
          No Department Assigned
        </label>
      </div>

      <div className="text-xs w-100 pr-5 pl-5 pt-2 mt-5">
        Jobs <span className="float-right text-rose-600">{jobList && jobList.length}</span>
      </div>
      <div className="text-xs w-100 pr-5 pl-5 pt-2">
        Applicants <span className="float-right text-orange-600">{applications && applications.length}</span>
      </div>
      <div className="text-xs w-100 pr-5 pl-5 pt-2">
        Hired <span className="float-right text-green-600">{ employees && employees.length }</span>
      </div>
    </div>
  );
};

export default DepartmentNotAssigned;
