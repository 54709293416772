/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AddressForm from "../../../address/AddressForm";
import toast from "react-hot-toast";
import ToastAlert from "../../../custom-ui/toast-alert/ToastAlert";
import { useUser } from "../../../../providers/database";
import { updateUser } from "../../../../data/user";
import MessagingCenterLite from "../../../messages/MessagingCenterLite";
import AutoSaveText from "../../../custom-ui/input-fields/auto-save-field";
import AutoSaveSelectByLookup from "../../../custom-ui/input-fields/auto-save-select-by-lookup";
import { useAuth } from "../../../../providers/auth";
import ClaimsSection from "../parts/claimsSection";

function UserInfo() {
  const auth = useAuth();
  const { userClaims } = useAuth();
  const params = useParams();
  const [id, setId] = useState(params.userId);
  const [name, setName] = useState("");

  const { data: userData, status: userStatus, error: userError } = useUser(id);

  const [mapSource, setMapSource] = useState(
    "https://maps.google.com/maps?q=35.856737, 10.606619&z=15&output=embed",
  );
  console.log("userData", userData);
  useEffect(() => {
    if (userData?.address) {
      setMapSource(
        "https://maps.google.com/maps?q=" +
          userData.address.latitude +
          ", " +
          userData.address.longitude +
          "&z=15&output=embed",
      );
    }
  }, [userData]);

  const handleResetPassword = () => {
    auth.sendPasswordResetEmail(userData.email).then(() => {
      toast.success("Email sent");
    });
  };

  useEffect(() => {
    setName(userData?.name || "");
  }, [userData]);

  useEffect(() => {
    if (userError) {
      console.log(userError);
    }
  }, [userError]);

  return (
    <div>
      <div className="flex justify-evenly space-x-2">
        <div className="w-96">
          {/* 2nd Column */}
          <h1 className="text-2xl font-semibold text-instant-teams-blue-Main py-3 uppercase">
            Basic Information
          </h1>

          <AutoSaveSelectByLookup
            value={userData?.pronouns}
            group="pronoun"
            collection="Users"
            recId={id}
            required={false}
            name="pronouns"
            display="Pronouns"
            placeholder="Select Pronouns"
            directions=""
            readOnly={true}
          />
          <div className="mt-3">
            <AutoSaveText
              value={name}
              collection="Users"
              recId={id}
              required={true}
              name="name"
              display="Full Legal Name"
              placeholder="John Doe"
              directions="Name used on Driver's License or ID"
              readOnly={false}
            />
          </div>

          <AutoSaveText
            value={userData?.preferredName}
            collection="Users"
            recId={id}
            required={false}
            name="preferredName"
            display="Preferred Name"
            placeholder="Johnny Doe"
            directions="This will be your displayed name"
            readOnly={false}
          />

          <AutoSaveText
            value={userData?.title}
            collection="Users"
            recId={id}
            required={false}
            name="title"
            display="Title"
            placeholder=""
            directions=""
            readOnly={false}
          />
          <AutoSaveText
            value={userData?.linkedin}
            collection="Users"
            recId={id}
            required={false}
            name="linkedin"
            display="LinkedIn"
            placeholder=""
            directions="Full URL"
            readOnly={false}
          />
          <h1 className="text-2xl font-semibold text-instant-teams-blue-Main py-3 uppercase">
            Home Address
          </h1>
          {userData?.address?.latitude && (
            <div className="mx-auto center-items mb-4 w-full">
              <iframe
                className="w-full"
                src={mapSource}
                width="360"
                height="270"
                frameBorder="0"
                style={{ border: 0 }}
                title="address Map"
              ></iframe>
            </div>
          )}
          <AddressForm
            id={id}
            address={userData?.address}
            addressLabel="primary"
            onSubmit={updateUser}
            onDone={() => toast.success("Your address has been updated!")}
          />
        </div>

        {/* 3rd Column */}
        <div className="w-[55%] h-[80%]">
          {userClaims?.claims?.type === "A" ? (
            <>
              <ClaimsSection user={userData} />
              <button
                onClick={handleResetPassword}
                className="bg-instant-teams-blue-Main text-white uppercase py-3 px-12 rounded-lg hover:bg-instant-teams-blue-L1"
              >
                Reset password
              </button>
              <MessagingCenterLite recipientId={id} />
            </>
          ) : null}
        </div>
      </div>
      <ToastAlert />
    </div>
  );
}

export default UserInfo;
