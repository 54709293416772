import React from "react";
import "./../styles/global.css";
import "./../util/analytics";
import { AuthProvider } from "../providers/auth";
import { QueryClientProvider } from "../providers/database";
import GlobalStateProvider from "../providers/globalState/GlobalStateProvider";
import ConditionalRouter from "../react-router_routes/conditional-router";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import HeadWrapper from "./HeadWrapper";
library.add(fas);

function App(props) {
  return (
    <HeadWrapper>
      <GlobalStateProvider>
        <QueryClientProvider>
          <AuthProvider>
            <ConditionalRouter />
          </AuthProvider>
        </QueryClientProvider>
      </GlobalStateProvider>
    </HeadWrapper>
  );
}

export default App;
