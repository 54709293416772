import { sanitizeMarkup } from "../../../util/sanitize/sanitize-html";
import "../../../styles/dangerouslySet.css";
function CampaignCardDetails({ data }: { data: any }) {
  return (
    <>
      <p className="text-center">{data?.title || "salutation"}</p>
      <div
        className="dangerously"
        dangerouslySetInnerHTML={sanitizeMarkup(data?.body || "<p>Body</p>")}
      ></div>
      {data?.button?.showButton ? (
        <div className="w-full text-center">
          <a
            href={data?.button?.link || "https://instantteams.com"}
            target="_new"
          >
            <button
              style={{
                backgroundColor: data?.button?.bgColor,
                color: data?.button?.textColor,
              }}
              className="rounded-lg py-2 px-12"
            >
              {data?.button?.text || "button text"}
            </button>
          </a>
        </div>
      ) : null}
    </>
  );
}

export default CampaignCardDetails;
