import React, { useState, useEffect } from "react";
import { useEnrollments } from "../../../data/enrollments";
import { useParams } from "react-router-dom";
import { useComponent, useImports } from "../../../data/academy";
import GreenUnderlineHeading from '../../custom-ui/headings/heading';
import { DisplayTimestamp } from '../../../util/timestamps/timestamp';

function TestResults(props) {
  const params = useParams();
  const [id, setId] = useState(params?.assessmentId);
  const { data: component } = useComponent(id);
  const { data: syncList } = useImports(params?.assessmentId);
  const [tier, setTier] = useState(component?.tier || []);

  const { data: enrollments } = useEnrollments(id);

  function getTier(score) {
    if (score !== null) {
      if (score < tier.one) {
        return "Failed";
      } else if (score >= tier.one && score < tier.two) {
        return "Tier 1";
      } else if (score >= tier.two && score < tier.three) {
        return "Tier 2";
      } else if (score >= tier.three) {
        return "Tier 3";
      }
    } else {
      return "--";
    }
  }

useEffect(() => {
  console.log(syncList);
}, [syncList]);

  return (
    <div className="w-full pb-10 grid grid-cols-4 gap-2">
      <div className="col-span-3">
      <GreenUnderlineHeading title={`Assessment Enrollments - (Records: ${enrollments?.length || 0})`}/>
        <table className="w-full table-auto">
          <thead className="text-left border-b border-black">
            <th>Status</th>
            <th>Stage</th>
            <th>Name</th>
            <th>Email</th>
            <th>Score</th>
            <th>Tier</th>
          </thead>
          <tbody>
            {enrollments &&
              enrollments.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-gray-100" : "bg-white"
                    } hover:bg-gray-300 border-b border-black`}
                  >
                    <td>{item.status.toUpperCase()}</td>
                    <td>{item.stage}</td>
                    <td>{item.full_name}</td>
                    <td>{item.email}</td>
                    <td>{item.average || "No Score"}</td>
                    <td>{getTier(item.average)}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="px-4">
        <GreenUnderlineHeading title={"Sync History"}/>
          <div className="w-full">
            <table className="w-full table-auto">
              <thead className="text-left border-b border-black">
                <th>Time</th>
                <th>Count</th>
                <th>Offset</th>
                <th></th>
              </thead>
              <tbody>
                {syncList ? syncList.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        index % 2 === 0? "bg-gray-100" : "bg-white"
                      } hover:bg-gray-300 border-b border-black`}
                    >
                      <td>{DisplayTimestamp(item?.timestamp) }</td>
                      <td>{item?.count}</td>
                      <td>{item?.offset}</td>
                      <td className={item?.err ? "text-red-600 font-bold" : "text-green-600"}>{item?.err ? "Fail" : "Pass"}</td>
                    </tr>
                  );
                }):(
                  <tr>
                    <td className='cols-4'>Loading...</td> 
                  </tr>
                )}
              </tbody>
            </table>
          </div>
      </div>
    </div>
  );
}

export default TestResults;
