import React, { useState, useEffect } from "react";
import { updateAutoSave, logActivity } from "../../../data/utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../providers/auth";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { Switch } from "@headlessui/react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}

const DEFAULT_MAX_LENGTH = -999;

const AutoSaveWysiwygWithSwitch = ({
  value: initialValue,
  rows,
  collection,
  recId,
  required,
  name,
  display,
  placeholder,
  directions,
  readOnly,
  switchValue = false,
  editorStyle = {},
  maxLength = DEFAULT_MAX_LENGTH,
}) => {
  const auth = useAuth();
  const [value, setValue] = useState(initialValue);
  const [check, setCheck] = useState(initialValue);
  const [switchBoolean, setSwitchBoolean] = useState(switchValue);
  const [isValid, setIsValid] = useState(true);
  const [saved, setSaved] = useState(false);
  const [height, setHeight] = useState("pl-4 pr-4 h-36 ");
  const [editorState, setEditorState] = useState(() =>
    initialValue ? prepareDraft(initialValue) : EditorState.createEmpty(),
  );

  function prepareDraft(value) {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }

  const onEditorStateChange = (newEditorState) => {
    const content = draftToHtml(
      convertToRaw(newEditorState.getCurrentContent()),
    );

    setEditorState(newEditorState);
    setValue(content);
  };

  useEffect(() => {
    setTimeout(() => {
      setSaved(false);
    }, 3000);
  }, [saved]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const styleHeight = `pl-4 pr-4 h-${
      rows * 12
    } w-full [&>span]:text-blue-500 [&_.rdw-link-decorator-wrapper]:text-instant-teams-blue-Main [&_.rdw-link-decorator-wrapper]:underline  `;
    setHeight(styleHeight);
  }, [rows]);

  useEffect(() => {
    if (initialValue !== null) {
      setSwitchBoolean(true);
    }
    return () => setSwitchBoolean(false);
  }, [initialValue]);

  const handleSwitch = async () => {
    if (switchBoolean === true) {
      setSwitchBoolean(false);
      setEditorState(null);
      await updateAutoSave(collection, recId, { [name]: null });
    } else {
      setSwitchBoolean(true);
    }
  };

  const handleBlur = async () => {
    if (!value && required) {
      setIsValid(false);
      return;
    }
    if (value !== check) {
      const draft = convertToRaw(editorState.getCurrentContent()).blocks[0]
        .text;
      if (draft === "") {
        await updateAutoSave(collection, recId, { [name]: null });
        await logActivity(
          collection,
          recId,
          name,
          check,
          value,
          auth.user.uid,
          check === "" ? "Added" : "Edited",
        );
      } else {
        setIsValid(true);
        setCheck(value);
        await updateAutoSave(collection, recId, { [name]: value });
        await logActivity(
          collection,
          recId,
          name,
          check,
          value,
          auth.user.uid,
          check === "" ? "Added" : "Edited",
        );
      }
      setSaved(true);
    }
  };

  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      console.log(currentSelection);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = getLengthOfSelectedText();

    if (
      maxLength !== DEFAULT_MAX_LENGTH &&
      currentContentLength - selectedTextLength > maxLength - 1
    ) {
      return "handled";
    }
  };

  const handlePastedText = (pastedText) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = getLengthOfSelectedText();

    if (
      maxLength !== DEFAULT_MAX_LENGTH &&
      currentContentLength + pastedText.length - selectedTextLength > maxLength
    ) {
      return "handled";
    }
  };

  return (
    <div id={name} className="relative mb-4 ">
      <div className=" flex flex-row items-center">
        <div className=" text-left text-lg font-bold mr-2 uppercase">
          {display}
        </div>
        <Switch
          checked={switchBoolean}
          onChange={() => {
            handleSwitch();
          }}
          className={`${
            switchBoolean ? "bg-green-500" : "bg-zinc-500"
          } h-[1rem] w-[2rem] cursor-pointer rounded-full relative inline-flex`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${switchBoolean ? "translate-x-4" : "translate-x-0"}
                pointer-events-none inline-block h-[1rem] w-[1rem] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>

      {switchBoolean && (
        <>
          <div className="text-instant-teams-teal-Main font-bold pl-1 ">
            {display} {directions ? " - " + directions : null}{" "}
            {readOnly ? " - LOCKED" : null}
          </div>
          <Editor
            readOnly={readOnly}
            spellCheck={true}
            editorStyle={editorStyle}
            editorState={editorState}
            handleBeforeInput={
              maxLength !== DEFAULT_MAX_LENGTH ? handleBeforeInput : () => {}
            }
            handlePastedText={
              maxLength !== DEFAULT_MAX_LENGTH ? handlePastedText : () => {}
            }
            onEditorStateChange={onEditorStateChange}
            wrapperClassName="shadow-md rounded-md bg-white pt-3"
            editorClassName={height}
            toolbarClassName="border-solid border-b-2 border-sky-500 p-2 text-xs"
            onBlur={handleBlur}
            toolbar={{
              options: ["inline", "blockType", "link", "list", "textAlign"],
              inline: {
                options: ["bold", "italic", "underline"],
              },
              list: {
                options: ["unordered", "ordered"],
              },
              textAlign: {
                options: ["left", "center", "right", "justify"],
              },
              link: {
                className: undefined,
              },
            }}
          />
        </>
      )}

      {saved && (
        <span className="absolute right-2 top-2 text-xs">
          {display} saved!{" "}
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "green" }} />
        </span>
      )}
      {!isValid && <div className="error">This field is required.</div>}
    </div>
  );
};

export default AutoSaveWysiwygWithSwitch;
