import React, { useState, useEffect } from "react";
import Meta from "../../components/custom-ui/meta/Meta";
import { requireAuth } from "../../providers/auth";
import { useNavigate, useParams } from "react-router";
import { useUser } from "../../providers/database";
import UserDetailsTabbedDisplay from "../../components/user/details/user-details-tabbed-display";
import { useGlobalState } from "../../providers/globalState/GlobalStateProvider";
import NotFoundPage from "../404";
import useRoleGuard from "../../hooks/useRoleGuard";

function ExternalUserDetailsPage() {
  const params = useParams();
  useRoleGuard("PRI");
  const [name, setName] = useState("User Details");
  const { globalState, setGlobalState } = useGlobalState();
  const [id, setId] = useState(params.userId || "");
  const navigate = useNavigate();

  useEffect(() => {
    setId(params.userId);
  }, [params.userId]);

  useEffect(() => {
    if (
      window.location.pathname.endsWith(`${id}`) ||
      window.location.pathname.endsWith(`${id}/`)
    )
      navigate(`/users/${id}/user-info`, { replace: true });
  }, [params, navigate]);

  const { data: user, status: userStatus, error: userError } = useUser(id);

  useEffect(() => {
    const prefName = user?.preferredName || "User";
    setName(prefName + " Details");
    setGlobalState({ ...globalState, pageTitle: name });
  }, [user?.preferredName, name]);

  return (
    <div>
      <Meta title={name} />
      {userStatus !== "loading" && user && <UserDetailsTabbedDisplay />}
      {user === null && <NotFoundPage />}
    </div>
  );
}

export default requireAuth(ExternalUserDetailsPage);
