import { useEffect, useState } from "react";
import {
  useAllMessagesByChatId,
  sendMessage,
  handleTypingMessage,
  handleDeleteMessage,
} from "../../data/messages";
import MessagePanelItem from "./MessagePanelItem";
import { DisplayDate } from "../../util/timestamps/timestamp";
import { useUser } from "../../providers/database";
import { useUsersByRole } from "../../data/user";
import MessageSend from './MessageSend';

const MessagePanelLite = ({ chat }) => {
  const [allMessages, setAllMessages] = useState([]);
  const [messagesByDay, setMessagesByDay] = useState([]);
  const [docId, setDocId] = useState("");
  const [newMessageStarted, setNewMessageStarted] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [unreadIds, setUnreadIds] = useState([]);
  const { id: chatId, recipientId } = chat;
  const { data } = useAllMessagesByChatId(chatId);
  const senderId = localStorage.getItem("__user_id")?.replace(/"/g, "");

  const { data: userData } = useUser(senderId);
  const { data: recipientData } = useUser(recipientId);
  const { data: usersByRole } = useUsersByRole(chat?.group);

  const handleSendClick = () => {
    if (messageText.trim() && chat?.group === "oneOnOne") {
      const unreadUserIds = chat?.users?.filter((id) => id !== senderId);
      sendMessage(
        senderId,
        docId,
        chatId,
        messageText,
        unreadUserIds,
        recipientData?.email,
      );
      setMessageText("");
      setDocId("");
    } else if (messageText.trim() && userData?.claims?.type === "A") {
      const unreadUserIds = unreadIds;
      sendMessage(
        senderId,
        docId,
        chatId,
        messageText,
        unreadUserIds,
        recipientData?.email,
      );
      setMessageText("");
      setDocId("");
    } else if (messageText.trim() && userData?.claims?.type !== "A") {
      const unreadUserIds = usersByRole?.map((item) => item.id);
      sendMessage(
        senderId,
        docId,
        chatId,
        messageText,
        unreadUserIds,
        recipientData?.email,
      );
      setMessageText("");
      setDocId("");
    }
    setNewMessageStarted(false);
  };

  const handleCleanup = () => {
    if (messageText === "" && docId) {
      handleDeleteMessage(chatId, docId).then(() => {
        setDocId("");
        setNewMessageStarted(false);
      });
    }
  };

  const handleMessageChange = async (e) => {
    setMessageText(e.target.value);
    setNewMessageStarted(true);
    if (!newMessageStarted) {
      const messageId = await handleTypingMessage(chatId, senderId);
      setDocId(messageId);
    }
  };

  useEffect(() => {
    const userIds = usersByRole
      ?.map((item) => item.id)
      .filter((id) => id !== userData?.id);
    if (
      userIds?.length > 0 &&
      recipientId !== null &&
      recipientId !== undefined
    ) {
      setUnreadIds([...userIds, recipientId]);
    }
  }, [usersByRole, data, recipientId, userData?.id]);

  useEffect(() => {
    const hasTypingMessage = allMessages?.some((message) => message?.isTyping);
    setIsTyping(hasTypingMessage);
  }, [allMessages]);

  useEffect(() => {
    handleCleanup();
  }, [messageText]);

  useEffect(() => {
    setAllMessages(data);
  }, [chatId, data]);

  useEffect(() => {
    // Group messages by day
    const groups = allMessages?.reduce((groups, message) => {
      const date = DisplayDate(message?.createdAt);
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});

    // Convert groups object to array of objects
    const groupsArray = groups
      ? Object.entries(groups).map(([date, messages]) => ({
          date,
          messages,
        }))
      : [];

    setMessagesByDay(groupsArray);
  }, [allMessages]);
  const el = document.getElementById("chat-feed");
  const { scrollHeight } = el || "";

  useEffect(() => {
    if (scrollHeight) {
      el.scrollTop = scrollHeight + 2000;
    }
  }, [messagesByDay, scrollHeight, el]);

  return (
    <div className="h-full min-h-[500px] flex w-full mb-20">
      <div className={`"h-full w-full bg-white border-r-2 border-gray-300"`}>
        <div
          className=" h-[90%] px-4 overflow-y-scroll no-scrollbar pb-10"
          id="chat-feed"
        >
          {messagesByDay &&
            messagesByDay?.length > 0 &&
            messagesByDay.map((group, index) => (
              <div key={`${group.date}${index}`}>
                <div className="relative flex flex-col items-center">
                  <div className="text-center z-10 bg-white px-4">
                    <p>{group.date}</p>
                  </div>
                  <div className="absolute left-0 right-0 border-t-2 border-instant-teams-teal-Main top-1/2"></div>
                </div>
                {group.messages.map((message, index) => (
                  <MessagePanelItem
                    chatId={chatId}
                    message={message}
                    key={`${message?.id}${index}`}
                    minimize={false}
                  />
                ))}
              </div>
            ))}
        </div>
        <div className="flex justify-around mt-16 w-full relative">
          <div className="w-full absolute bottom-0 right-0 h-1/8 border-t-2 border-gray-300">
            <MessageSend chat={chat} key={chatId}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagePanelLite;
