import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  useApplication,
  useApplicationInterviewQuestions,
} from "../../../data/applications";
import useModal from "../../../hooks/useModal";
import { updateAutoSave } from "../../../data/utility";
import {
  collectionNames,
  subCollectionNames,
} from "../../../data/dictionary/collectionNames";
import { useAuth } from "../../../providers/auth";
import InterviewItem from "../../application/parts/InterviewItem";
import AutoSaveRadioGrade from "../../custom-ui/input-fields/auto-save-radio-grade";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import Meta from "../../custom-ui/meta/Meta";
import AnalyticsLogger from "../../../providers/analyticsLogger";
const JobApplicantInterview = () => {
  const params = useParams();
  const { applicationId = "" } = params ?? {};
  const { data: application } = useApplication(applicationId);

  return (
    <div className="pb-16 w-1/2 mx-auto h-auto">
      <Meta title="Job Applicant Details" />
      <AnalyticsLogger
        type={collectionNames.applications}
        typeId={applicationId}
      />
      <InterviewItem application={application} />
      <GreenUnderlineHeading title={"Overall Grade"} />
      <div className="w-full font-bold text-center mt-4 mb-2">
        Professional Appearance
      </div>
      <AutoSaveRadioGrade
        name="appearance"
        initialValue={application?.appearance || 0}
        collection={collectionNames.applications}
        id={applicationId}
      />
      <hr />
      <div className="w-full font-bold text-center mt-6 mb-2">
        Quiet, Professional Workspace
      </div>
      <AutoSaveRadioGrade
        name="workspace"
        initialValue={application?.workspace || 0}
        collection={collectionNames.applications}
        id={applicationId}
      />
      <hr />
      <div className="w-full font-bold text-center mt-6 mb-2">
        Working Webcam
      </div>
      <AutoSaveRadioGrade
        name="webcam"
        initialValue={application?.webcam || 0}
        collection={collectionNames.applications}
        id={applicationId}
      />
      <hr />
      <div className="w-full font-bold text-center mt-6 mb-2">
        Professional conduct: Makes a relevant introduction, Makes eye contact,
        Appears confident and friendly
      </div>
      <AutoSaveRadioGrade
        name="conduct"
        initialValue={application?.conduct || 0}
        collection={collectionNames.applications}
        id={applicationId}
      />
      <hr />
      <div className="w-full font-bold text-center mt-6 mb-2">
        Actively listens, responds appropriately, and uses a professional voice
        and tone
      </div>
      <AutoSaveRadioGrade
        name="tone"
        initialValue={application?.tone || 0}
        collection={collectionNames.applications}
        id={applicationId}
      />
    </div>
  );
};

export default JobApplicantInterview;
