import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useEmployer } from "../../data/employer";
import { useUser } from "../../data/user";
import { useInvitation } from "../../data/invitation";
import InviteOnlyRegistrationForm from "./InviteOnlyRegistrationForm";
import InstantTeamsSpinner from "../../components/custom-ui/animations/spinner/InstantTeamsSpinner";

const InvalidInvitation = () => {
  return (
    <>
      <div className="mt-1 text-lg font-bold ">
        This invitation is no longer valid.{" "}
      </div>
      <div>
        Please reach out to your Instant Teams point of contact for next steps.
      </div>
    </>
  );
};

const ProcessingInvitation = () => {
  return (
    <>
      <div className="mt-1 text-lg font-bold ">
        Please stand by while we process your invitation...{" "}
      </div>
      <div>
        <InstantTeamsSpinner />
      </div>
    </>
  );
};

function AcceptInvitation() {
  const params = useParams();
  const navigate = useNavigate();

  const [processingInvitation, setProcessingInvitation] = useState(false);

  const {
    data: invitation,
    status: invitationStatus,
    error: invitationsError,
  } = useInvitation(params.invitationId ?? "");

  const { status, employerId, invitedBy } = invitation ?? {};

  // 'sent' is the only status that should allow registration to continue
  const isInviteValid = status === "sent";

  const {
    data: employer,
    status: employerQueryStatus,
    error: employerQueryError,
  } = useEmployer(employerId);

  const { displayName, contact = {} } = employer ?? {};

  // get the information about user that created invitation for use in welcome message
  const {
    data: invitedByInfo,
    status: invitedByInfoQueryStatus,
    error: invitedByInfoQueryError,
  } = useUser(invitedBy);

  const { name: invitedByName, email: invitedByEmail } = invitedByInfo ?? {};

  const handleDone = () => {
    navigate("/registration/multifactor-enrollment");
  };

  return (
    <section className="mt-20 px-4">
      {invitationStatus !== "loading" && (
        <>
          <div className="container mx-auto max-w-sm text-center">
            {invitation && isInviteValid && !processingInvitation && (
              <>
                <h1 className="header-title text-instant-teams-teal-Main mb-3 text-3xl font-medium">
                  User Registration
                </h1>
                <p className="text-instant-teams-gray-D1">
                  {contact.primary
                    ? ""
                    : "Please enter your Point of Contact information."}
                </p>
                <p className="text-instant-teams-gray-D1 mb-10">
                  You have been invited to create a user account for{" "}
                  <strong>{displayName}</strong> by{" "}
                  <strong>{invitedByName}</strong> {`(${invitedByEmail})`}
                </p>

                <InviteOnlyRegistrationForm
                  invitation={invitation}
                  onDone={handleDone}
                  processingInvitation={setProcessingInvitation}
                />
              </>
            )}
            {/* Recently registered and waiting on app to navigate */}
            {invitation && processingInvitation && <ProcessingInvitation />}

            {/* any existing invitation with a status other than "sent" */}
            {invitation && !isInviteValid && !processingInvitation && (
              <InvalidInvitation />
            )}

            {/* If no invitation is returned  */}
            {invitation == null && <InvalidInvitation />}
          </div>
        </>
      )}
    </section>
  );
}

export default AcceptInvitation;
