import { useState, useEffect } from "react";
import { useAuth } from "../../providers/auth";
import { useParams } from "react-router-dom";
import { Tab } from "@headlessui/react";
import { useUser } from "../../providers/database";
import {
  useAllChatsByGroup,
  useAllChatsByRecipientId,
  useAllPersonalChats,
  useAllChatsByEmployerId,
} from "../../data/messages";
import { Chat } from "../../dataTypes/Messages";
import useModal from "../../hooks/useModal";
import SeekerMessageTabContents from './SeekerMessageTabContents';
import SeekerMessageTabContentsEmpty from './SeekerMessageTabContentsEmpty';
import MessagePanel from "./MessagePanel";
import NewConversationModal from "./NewConversationModal";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import { getLabel } from "../../util/search/getLabel";
import UserInfo from "./UserInfo";
import MessageSend from './MessageSend';
import GreenUnderlineHeading from '../custom-ui/headings/heading';
import CampaignCardDetails from '../../pages/marketing/parts/campaign-card-details';
import { useAllActiveMarketings } from '../../data/marketing';
import { Timestamp } from 'firebase/firestore';

const SeekerMessagingCenter = () => {
  const auth = useAuth();
  const employerId = useParams()?.employerId;
  const { userClaims, user } = auth;
  const [value, setValue] = useState("inbox");
  const [allChats, setAllChats] = useState([]);
  const [tacChats, setTACChats] = useState([]);
  const [csssChats, setCSSSChats] = useState([]);
  const [techChats, setTechChats] = useState([]);
  const [selected, setSelected] = useState(null);
  const [random, setRandom] = useState(null);
  const [curatedAds, setCuratedAds] = useState([]);
  const { data: recipientData } = useUser(selected?.recipientId);
  const { data: chats = [] } = useAllChatsByGroup(
    userClaims?.claims?.role,
    value,
  );
  const { data: receivedChats = [] } = useAllChatsByRecipientId(
    user?.id,
    value,
  );
  const {data: marketings } = useAllActiveMarketings();

  function randomIntFromInterval(min:number , max:number) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  useEffect(() => {
    setCuratedAds([]);
    if(marketings && marketings?.length > 0){
      const today = Timestamp.fromDate(new Date());
      for(const ad of marketings){
        if(ad.schedule.dateRange.startDate <= today){
          if(ad.schedule.dateRange.endDate >= today || ad.schedule.dateRange.endDate === null){
            setCuratedAds([...curatedAds, ad])
          }
        }
      }
      if(curatedAds.length > 1){
        const rndInt = randomIntFromInterval(1, curatedAds.length)
        setRandom(curatedAds[rndInt-1]);
      } else{
        setRandom(curatedAds[0]);
      }
    }
  }, [marketings]);

  useEffect(() => {
    if (
      chats?.length === 0 &&
      receivedChats?.length === 0
    ) {
      setAllChats("");
    }
    if (
      (chats && chats?.length > 0) ||
      (receivedChats && receivedChats?.length > 0)
    ) {
      const newChats = [
        ...chats,
        ...receivedChats,
      ];
      const uniqueChats = Array.from(
        new Set(newChats.map((chat) => chat.id)),
      ).map((id) => {
        return newChats.find((chat) => chat.id === id);
      });

      // Sort the uniqueChats array by the lastUpdated timestamp
      const sortedChats = uniqueChats.sort(
        (a, b) => b.lastUpdated - a.lastUpdated,
      );
      setAllChats(sortedChats);
    }
  }, [chats, receivedChats, value]);

  useEffect(() => {
      for(const chat of allChats){
        if(chat.group === "tac"){
          setTACChats(chat);
        }
        if(chat.group === "csss"){
          setCSSSChats(chat);
        }
        if(chat.group === "sadmin"){
          setTechChats(chat);
        }
      }
    }, [allChats]);

  return (
    <div className="grid grid-cols-10 w-full pt-24 overscroll-contain overflow-y-scroll no-scrollbar h-screen">
      <div className="w-full col-span-3 overflow-y-scroll overscroll-contain no-scrollbar border-gray-300 border-r-2">
        <div className='pt-12 pb-10'>


        {techChats && techChats.length === undefined ? (
          <div
            key={techChats?.id}
            className={`${
              selected?.id === techChats?.id
                ? "bg-slate-200 border-2 border-instant-teams-teal-Main"
                : "text-black border-b-2"
            } text-left hover:bg-slate-200 h-24 w-full`}
            onClick={() => setSelected(techChats)}
          >
            <SeekerMessageTabContents chat={techChats} />
          </div>
          ) :(
            <div className="text-black border-b-2 text-left hover:bg-slate-200 h-42 w-full">
              <SeekerMessageTabContentsEmpty type="sadmin" />
            </div>
          )}


          {tacChats && tacChats.length === undefined ? (
            <div
              key={tacChats?.id}
              className={`${
                selected?.id === tacChats?.id
                  ? "bg-slate-200 border-2 border-instant-teams-teal-Main"
                  : "text-black border-b-2"
              } text-left hover:bg-slate-200 h-24 w-full`}
              onClick={() => setSelected(tacChats)}
            >
              <SeekerMessageTabContents chat={tacChats} />
            </div>
          ) :(
            <div className="text-black border-b-2 text-left hover:bg-slate-200 h-42 w-full">
              <SeekerMessageTabContentsEmpty type="tac" />
            </div>
          )}

{/* 
          {csssChats && csssChats.length === undefined ? (
          <div
            key={csssChats?.id}
            className={`${
              selected?.id === csssChats?.id
                ? "bg-slate-200 border-2 border-instant-teams-teal-Main"
                : "text-black border-b-2"
            } text-left hover:bg-slate-200 h-24 w-full`}
            onClick={() => setSelected(csssChats)}
          >
            <SeekerMessageTabContents chat={csssChats} />
          </div>
          ) :(
            <div className="text-black border-b-2 text-left hover:bg-slate-200 h-42 w-full">
              <SeekerMessageTabContentsEmpty type="csss" />
            </div>
          )} */}


         


        </div>
      </div>
      <div className="col-span-7 w-full no-scrollbar relative border-r-2 border-gray-300 pr-4 h-5/6 pt-8">
        {selected ? (
            <>
              <div className="sticky w-full bg-instant-teams-teal-Main flex justify-center items-center text-white rounded h-12">
              { selected?.group == "sadmin" && <p>{`${recipientData?.name}, Technical Support`}</p>}

{ selected?.group == "tac" && <p>{`${recipientData?.name}, Recruitment Team`}</p>}
              </div>
              <div className="overflow-y-scroll overscroll-contain no-scrollbar" style={{maxHeight: "84%"}}>
                <MessagePanel chat={selected} key={selected?.id} minimize={false} />
              </div>
            </>
        ) : (
          <div className="grid grid-cols-10 col-span-8 justify-center items-center w-full">
            <div className="flex col-span-10 justify-center items-center w-full text-2xl mt-[30%]" style={{maxHeight: "780px"}}>{`Please select a message to begin.`}</div>
          </div>
        )}
        {selected && (
          <div className="w-full absolute bottom-0 right-0 border-t-2 border-gray-300" style={{maxHeight: "15%"}}>
            <MessageSend chat={selected} key={selected?.id}/>
          </div>
        )}
      </div>
    </div>
  );
};

export default SeekerMessagingCenter;
