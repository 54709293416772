import {
  collectionNames,
  subCollectionNames,
} from "../../../../data/dictionary/collectionNames";
import { useEffect, useState } from "react";
import GreenUnderlineHeading from "../../../custom-ui/headings/heading";
import {
  faScreenUsers,
  faCircleInfo,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AutoSaveText from '../../../custom-ui/input-fields/auto-save-field';
import AutoSaveDate from '../../../custom-ui/input-fields/auto-save-date';
import { Timestamp } from "firebase/firestore";
import { useEmployeesByTraining } from '../../../../data/employee';
import TraineeDetailsItem from './TraineeDetailsItem';

function TrainingDetailsItem({
  id,
  readOnly,
  employerId,
  data,
}) {
  const collection = `${collectionNames.employers}/${employerId}/${subCollectionNames.training}`;
  const {data: trainees} = useEmployeesByTraining(data.id);
  console.log(data.id);
  console.log("TRNS: ",trainees);
  return (
    <div className="w-full">
      <GreenUnderlineHeading title={"Training"}>
        <FontAwesomeIcon icon={faCircleInfo}/>
      </GreenUnderlineHeading>
      <div className="w-full grid grid-cols-2 gap-2 mt-4">
        <AutoSaveText
          value={data.title}
          collection={collection}
          recId={id}
          required={true}
          name="title"
          display="Cycle Title"
          placeholder={"Cycle Title"}
          directions=""
          readOnly={readOnly}
        />
        <div className="grid grid-cols-2 gap-2">
          <AutoSaveDate
            initialStart={
              data?.startDate instanceof Timestamp
                ? data?.startDate.toDate()
                : null
            }
            name="startDate"
            collection={collection}
            id={id}
            disabled={readOnly}
            timestamp = {true}
          />
        </div>
      </div>
      <div className="col-span-2 mt-4">
        <GreenUnderlineHeading title={"Participants"}>
          <FontAwesomeIcon icon={faScreenUsers}/>
        </GreenUnderlineHeading>
        {trainees && trainees.length > 0 && (
          trainees.map((trainee) => (
            <TraineeDetailsItem trainee={trainee}/>
          ))
        )}
      </div>      
    </div>
  );
}

export default TrainingDetailsItem;
