import React, { useState } from "react";
import { Chat } from "../../dataTypes/Messages";
import { useUser } from "../../providers/database";
import useLocalTime from "../../hooks/useLocalTime";
import MessagePanel from "./MessagePanel";
import { getLabel } from "../../util/search/getLabel";
import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";
import useModal from "../../hooks/useModal";
import DialogWrapper from "../custom-ui/dialog/Dialog";

const AdminChatLineItem = ({ chat }: { chat: Chat }) => {
  const [showMessages, setShowMessages] = useState(false);
  const { createdAt, createdBy, group, status, createdByName, recipientName } =
    chat;
  const { data: createdByUserData } = useUser(createdBy);
  const { toggle, isShowing } = useModal();

  const options = {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  };
  // @ts-ignore
  const formattedDate = useLocalTime(chat.createdAt, options);

  return (
    <>
      <div
        className={
          TailwindHoverEnlarge(101) +
          "border-b border-gray cursor-pointer flex justify-evenly"
        }
        onClick={toggle}
      >
        <div className="p-2 w-1/5">{getLabel(group, "userRoles")}</div>
        <div className="p-2 w-1/5">{formattedDate}</div>
        <div className="p-2 w-1/5">
          {recipientName ? recipientName : getLabel(group, "userRoles")}
        </div>
        <div className="p-2 w-1/5">{createdByUserData?.name}</div>
        <div className="p-2 w-1/5">{status}</div>
      </div>
      {isShowing && (
        <DialogWrapper
          title="Messages"
          onClose={toggle}
          size="w-1/2 mt-36 mb-12"
        >
          <MessagePanel chat={chat} key={chat?.id} minimize={true} />
        </DialogWrapper>
      )}
    </>
  );
};

export default AdminChatLineItem;
