const ConnectWithRecruitmentSvg = ({
  className = "",
}: {
  className?: string;
}) => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M29.8135 17.318C28.6451 11.4526 22.6083 7.70639 16.8203 9.2512C11.3055 10.7232 7.92007 16.1207 9.1722 21.6738C10.134 25.9389 12.862 28.6137 17.1052 29.7219C17.6303 29.8588 18.1781 29.9112 18.7149 30.0036H20.241C20.7105 29.9269 21.1848 29.8705 21.6496 29.7688C22.1058 29.6694 22.5605 29.5473 22.9996 29.3916C23.2758 29.2938 23.5341 29.2547 23.822 29.3024C24.409 29.3994 25.0006 29.4691 25.5891 29.5599C27.922 29.9191 29.9481 27.8961 29.5959 25.5601C29.4926 24.8761 29.3979 24.1914 29.304 23.5066C29.2938 23.4331 29.3016 23.3501 29.3275 23.2805C29.6851 22.3265 29.9262 21.3467 30.0021 20.3294C30.0052 20.2926 30.0287 20.2574 30.0427 20.2214V18.6985C29.9668 18.2383 29.9042 17.7758 29.8127 17.3188L29.8135 17.318ZM26.9524 23.5786C27.0212 24.3252 27.1527 25.0663 27.2662 25.809C27.4031 26.7089 26.7614 27.3647 25.8607 27.2356C25.1156 27.1292 24.3738 26.993 23.6256 26.9218C23.2985 26.8905 22.9315 26.9202 22.6294 27.0407C17.7344 28.9941 12.4473 26.151 11.4057 20.9914C10.5057 16.53 13.6235 12.0724 18.1343 11.3696C22.7139 10.6567 26.8764 13.6759 27.6121 18.2524C27.6755 18.646 27.6872 19.0483 27.7232 19.4458C27.706 20.536 27.4642 21.5713 27.0838 22.5871C26.9696 22.8931 26.9218 23.2531 26.9516 23.5786H26.9524Z" />
    <path d="M5.90848 18.1094C5.34424 18.1423 4.78313 18.2393 4.22359 18.3239C3.26963 18.4679 2.62635 17.8175 2.77739 16.8636C2.89243 16.1319 3.01763 15.3994 3.08572 14.6622C3.11546 14.346 3.07633 13.9923 2.96051 13.6981C2.37514 12.2182 2.16541 10.7008 2.46044 9.13562C3.63744 2.89927 10.8489 0.297196 15.7134 4.16704C16.5179 4.80719 17.1643 5.58194 17.6878 6.46547C17.9703 6.94206 18.4462 7.16353 18.9188 7.07197C19.7687 6.90841 20.1436 6.03427 19.6975 5.25717C17.6166 1.63149 13.4447 -0.443904 9.28921 0.0804224C4.85121 0.639965 1.3077 3.8094 0.28018 8.15271C0.15262 8.69112 0.0868794 9.2444 -0.0078125 9.79064V11.3135C0.00705647 11.3597 0.0321028 11.4051 0.0352331 11.4521C0.111143 12.4616 0.35374 13.4328 0.708248 14.3781C0.734073 14.447 0.740336 14.5307 0.730162 14.6043C0.636253 15.2906 0.542344 15.9761 0.437479 16.6609C0.171403 18.4021 1.27953 20.0988 2.97851 20.5699C3.73134 20.7788 4.47166 20.6559 5.21667 20.5472C5.54536 20.4994 5.87796 20.4658 6.1996 20.3875C6.77557 20.2467 7.11991 19.6957 7.04087 19.0884C6.96887 18.5328 6.47898 18.0766 5.90691 18.1102L5.90848 18.1094Z" />
    <path d="M14.788 18.2891C14.1384 18.2859 13.6125 18.804 13.6094 19.4512C13.6063 20.0984 14.1275 20.6259 14.7731 20.6298C15.4171 20.6337 15.9509 20.1078 15.9548 19.4661C15.9587 18.822 15.4336 18.293 14.788 18.2891Z" />
    <path d="M19.475 18.2891C18.8263 18.2898 18.3059 18.8126 18.3066 19.4614C18.3074 20.107 18.8341 20.6313 19.4805 20.6298C20.1238 20.6282 20.6536 20.0976 20.652 19.4559C20.6505 18.8118 20.1222 18.2883 19.475 18.2891Z" />
    <path d="M24.1629 18.2891C23.515 18.2922 22.9961 18.8173 23 19.4669C23.0032 20.1125 23.533 20.6345 24.1794 20.6298C24.8226 20.6259 25.3501 20.0921 25.3454 19.4504C25.3407 18.8064 24.8109 18.2859 24.1629 18.2891Z" />
  </svg>
);

export default ConnectWithRecruitmentSvg;
