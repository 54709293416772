import React from 'react';
import {
  useJobPositionDetails
} from "../../data/positions";

function SeekerJobItem({ listing }: { listing: any }) {
  
  const { data: positionData } = useJobPositionDetails(
    listing?.jobId,
    listing?.positionId,
  );
  
  return (
    <>
      
      <img
        className="w-20  h-20 object-contain my-auto mx-auto col-span-1"
        src={listing?.companyLogo}
        alt={listing?.companyName}
      />


       
      <div className="my-auto col-span-2"> {/* Make this div relative */}
      
        <div>{listing?.companyName}</div>
        <div className="font-semibold uppercase">{listing?.title}</div>
        <div>{listing?.subTitle}</div>

        {/* Conditionally render the icon */}
       
      </div>
      <div className="my-auto  col-span-2 relative">
      {/* {positionData?.urlActive && positionData?.externalUrlName!=='' && (
          <div className="absolute top-[-25px] right-[10px] p-1">
            <img
              src="images/fa937efb-0cdf-46ad-9344-93dd3e06eaf0.png"
              alt="Icon"
              className="w-5 h-5"
            />
           
          </div>
        )} */}
      
        <div className="uppercase font-semibold text-sm">
          {listing.positionPayType}
        </div>
        <div className="">
       
          <div className="uppercase text-sm font-semibold">
            Work Type:
            <span className="pl-2 capitalize font-normal">
              {listing?.classification}
            </span>
          </div>
        </div>
        <div className="">
      
          <div className="uppercase text-sm font-semibold">
            Work Setting:
            <span className="pl-2 capitalize font-normal">
              {listing?.model}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default SeekerJobItem;
