import Meta from "../../components/custom-ui/meta/Meta";
import { requireAuth } from "../../providers/auth";
import { Outlet } from "react-router-dom";
import useKeepNavbarActiveTab from "../../hooks/useKeepNavbarActiveTab";

function DashboardPage() {
  useKeepNavbarActiveTab("home", "dashboard");
  return (
    <>
      <Meta title="Dashboard" />
      <div className="md:container pb-32 w-full bg-gray-50">
        <Outlet />
      </div>
    </>
  );
}

export default requireAuth(DashboardPage);
