export function getLevel(level) {
    switch (level) {
      case "level1":
        return "Proficient";
      case "level2":
        return "Advanced";
      case "level3":
        return "Experienced";
      default:
        return "Unknown";
    }
  }

  export function getLevelColor(level) {
    switch (level) {
      case "level1":
        return "bg-instant-teams-green-D1";
      case "level2":
        return "bg-instant-teams-red-L1";
      case "level3":
        return "bg-instant-teams-tags-LBLUE";
      default:
        return "bg-instant-teams-teal-Main";
    }
  }

  export function getTierColor(level) {
    switch (level) {
      case "Proficient":
        return "bg-instant-teams-green-D1";
      case "Advanced":
        return "bg-instant-teams-red-L1";
      case "Experienced":
        return "bg-instant-teams-tags-LBLUE";
      case "Basic":
        return "bg-instant-teams-orange-D2";
      default:
        return "bg-instant-teams-teal-Main";
    }
  }