import { useState } from "react";
import { useParams } from "react-router-dom";
import SeekerInfo from "../parts/seekerInfo";
import SeekerEmploymentList  from "../parts/seekerEmploymentList";
import Meta from "../../../custom-ui/meta/Meta";

const SeekerEmployment = () => {
  const params = useParams();
  const [id, setId] = useState(params?.seekerId || "");
  

  return (
    <>
      <Meta title="Seeker - Employment History" />
      <div className="mt-10">
        <SeekerEmploymentList id={id} />
      </div>
    </>
  );
};

export default SeekerEmployment;
