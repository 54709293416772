import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { camel } from "../../../util/strings/stringManipulation";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";

function ComponentLinks(details) {
  const [copySuccess, setCopySuccess] = useState("");
  const [url, setUrl] = useState("");

  const copyToClipboard = () => {
    if (navigator.clipboard && url !== "") {
      navigator.clipboard.writeText(url);
      setCopySuccess("Copied to clipboard!");
    } else {
      setCopySuccess("Copy to clipboard is not supported in this browser.");
    }
  };

  useEffect(() => {
    if (copySuccess) {
      toast.success(copySuccess);
    }
  }, [copySuccess]);

  return (
    <div className="w-full mt-4 mb-4">
      <GreenUnderlineHeading title="Public Links" />
      {details?.links &&
        details?.links.map((item, index) => {
          return (
            <div
              className={index > 0 ? "w-full border-t border-black" : "w-full"}
              key={index}
            >
              <div className="w-full flex">
                <span className="float-left w-1/2">{camel(item.label)}</span>
                <span className="float-right w-1/2">
                  {item.active ? "Active" : "Inactive"}
                </span>
              </div>
              <div className="w-full flex">
                <span className="float-left w-1/2">
                  {item.candidates_count} of{" "}
                  {item.candidates_limit === 0
                    ? "Unlimited"
                    : item.candidates_limit}
                </span>
                {item.candidates_count > 0 && (
                  <span className="float-right w-1/2">
                    <button
                      onClick={() => {
                        setUrl(item.short_invitation_link);
                        copyToClipboard();
                      }}
                    >
                      Copy Link
                    </button>
                  </span>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default ComponentLinks;
