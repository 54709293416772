import GreenUnderlineHeading from "../custom-ui/headings/heading";
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useComponent } from '../../data/academy';
import { getLevel } from '../../util/assessments/level';

function JobListingShareSkill({skill}:{skill: any}) {
  const {data: skillData} = useComponent(skill.assessmentId);
  return (
    <div className="items-center">
      <img
        src={skillData?.badge}
        alt={skillData?.displayName}
        className="object-contain h-32 w-32 md:h-16 md:w-16 mx-auto"
      />
      <div className="w-full text-center text-xs">
        {skillData?.displayName || skillData?.name}
      </div>
      <div className="text-center text-xs">
        {getLevel(skill?.tier)}
      </div>
    </div>
  );
}

export default JobListingShareSkill;
