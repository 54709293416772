import { useEffect, useState } from "react";
import { getJobsNewMetricsChartData } from "../callable-cloud-functions/cloudFunctions";
import ApexCharts from "react-apexcharts";
import InfoIcon from "../components/dashboard/AdminMetricDashboard/InfoTextWithIcon";
import LoadingSpinner from "../components/dashboard/AdminMetricDashboard/LoadingIndicator/LoadingSpinner";

function JobNewViewApplyMetricsChart({ jobId }) {
  const [daysSelected, setDaysSelected] = useState(7);
  const [chartData, setChartData] = useState([]);
  const [chartKeys, setChartKeys] = useState([]);
  const [chartTitle, setChartTitle] = useState("Last 14 Days");
  const [graphData, setGraphData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await getJobsNewMetricsChartData({ jobId });
        setGraphData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const filteredData = graphData?.slice(-daysSelected);
    const viewTotalData = filteredData?.map((item) => item.view_total_count);
    const viewIosCountData = filteredData?.map((item) => item.view_ios_count);
    const viewAndroidCountData = filteredData?.map(
      (item) => item.view_android_count,
    );
    const viewWebCountData = filteredData?.map((item) => item.view_web_count);

    const applyTotalData = filteredData?.map((item) => item.apply_total_count);
    const applyIosCountData = filteredData?.map((item) => item.apply_ios_count);
    const applyAndroidCountData = filteredData?.map(
      (item) => item.apply_android_count,
    );
    const applyWebCountData = filteredData?.map((item) => item.apply_web_count);

    const chartKeys = filteredData?.map((item) =>
      item.date.split("-").slice(-2).join("/"),
    );

    setChartData({
      viewTotal: viewTotalData,
      viewIosCount: viewIosCountData,
      viewAndroidCount: viewAndroidCountData,
      viewWebCount: viewWebCountData,

      applyTotal: applyTotalData,
      applyIosCount: applyIosCountData,
      applyAndroidCount: applyAndroidCountData,
      applyWebCount: applyWebCountData,
    });
    setChartKeys(chartKeys);
    setChartTitle(`Last ${daysSelected} Days`);
  }, [daysSelected, graphData]);

  const options = {
    chart: {
      type: "line",
      height: "100%",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: chartKeys,
      reversed: true, // Reverse the x-axis labels
      labels: {
        rotate: -45,
        rotateAlways: daysSelected === "30" ? true : false, // Ensure rotation always applies
        style: {
          fontSize: "12px",
          colors: "#333", // Default color for labels
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "14px",
          fontFamily: "Inter, Helvetica, Arial, sans-serif",
        },
      },
    },
    tooltip: {
      enabled: true,
      custom({ series, seriesIndex, dataPointIndex, w }) {
        const xValue = w.globals.categoryLabels[dataPointIndex];

        // Data for views
        const viewTotal = chartData.viewTotal[dataPointIndex];
        const viewIosCount = chartData.viewIosCount[dataPointIndex];
        const viewAndroidCount = chartData.viewAndroidCount[dataPointIndex];
        const viewWebCount = chartData.viewWebCount[dataPointIndex];

        // Data for applications
        const applyTotal = chartData.applyTotal[dataPointIndex];
        const applyIosCount = chartData.applyIosCount[dataPointIndex];
        const applyAndroidCount = chartData.applyAndroidCount[dataPointIndex];
        const applyWebCount = chartData.applyWebCount[dataPointIndex];

        // Series colors
        const viewColor = w.config.series[0].color;
        const applyColor = w.config.series[1].color;

        // Check if "View Count" and "Apply Count" are disabled
        const isViewSeriesVisible = w.globals.series[0].some(
          (value) => value !== null,
        );
        const isApplySeriesVisible = w.globals.series[1].some(
          (value) => value !== null,
        );

        // Custom tooltip structure
        return `
          <div class="p-2 bg-white rounded shadow-md">
            <!-- Date with background -->
            <div class="text-sm text-white bg-gray-600 px-2 py-1 rounded">${xValue}</div>
            
            <!-- Conditionally show Views section if the series is visible -->
            ${
              isViewSeriesVisible
                ? `
            <div class="mt-2">
              <div class="flex items-center">
                <!-- Circle with series color -->
                <span style="background-color: ${viewColor}; width: 10px; height: 10px; border-radius: 50%; display: inline-block; margin-right: 5px;"></span>
                <span class="text-sm font-semibold text-gray-600">View Count Total: ${viewTotal}</span>
              </div>
              <div class="text-xs text-gray-500 pl-4">iOS: ${viewIosCount}, Android: ${viewAndroidCount}, Web: ${viewWebCount}</div>
            </div>
            `
                : ""
            }
    
            <!-- Conditionally show Applications section if the series is visible -->
            ${
              isApplySeriesVisible
                ? `
            <div class="mt-2">
              <div class="flex items-center">
                <!-- Circle with series color -->
                <span style="background-color: ${applyColor}; width: 10px; height: 10px; border-radius: 50%; display: inline-block; margin-right: 5px;"></span>
                <span class="text-sm font-semibold text-gray-600">Apply Count Total: ${applyTotal}</span>
              </div>
              <div class="text-xs text-gray-500 pl-4">iOS: ${applyIosCount}, Android: ${applyAndroidCount}, Web: ${applyWebCount}</div>
            </div>
            `
                : ""
            }
          </div>
        `;
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
        states: {
          hover: {
            filter: {
              type: "none", // Disable the lighten effect
            },
            color: "#000000", // Light gray color on hover
          },
        },
      },
    },
    yaxis: [
      {
        title: {
          text: "Total Apply / View Metrics", // Left side axis
        },
        min: 0,
        opposite: false, // Left side only
      },
    ],
    stroke: {
      curve: "straight",
      width: 2,
    },
    markers: {
      size: 5,
      strokeWidth: 0,
      hover: {
        size: 7,
      },
    },
    colors: ["#CAE5FD", "#205A6E", "#183853", "#D3D3D3"], // Colors for lines and bars
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    series: [
      {
        name: "View Count",
        type: "bar",
        data: chartData.viewTotal,
        color: "#187b96",
      },
      {
        name: "Apply Count",
        type: "bar",
        data: chartData.applyTotal,
        color: "#183853",
      },
    ],
  };

  return (
    <>
      <div className="bg-white shadow-lg rounded-lg p-4 pb-8">
        <div className="w-full text-[#c4e6f3] pb-3  h-80">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <h1 className="text-xl text-[#1E596D] font-bold">
              Metrics
              </h1>
              <p className="text-10px ml-[3px] text-[#3b7f96]">
                {`(Last ${daysSelected} Days)`}
              </p>
              <InfoIcon
                tooltipText={
                  "Number of unique clicks on the ‘View Posting’ button on the job application page. Clicks on different platforms count as new interactions (Android/ iOS/ Web)."
                }
              />
            </div>
          </div>
          {isLoading || graphData?.length === 0 ? (
            <div className="flex justify-center items-center h-full">
              <LoadingSpinner />
            </div>
          ) : (
            <ApexCharts
              options={options}
              series={options.series}
              type="line"
              height="100%"
            />
          )}
        </div>
      </div>
      <div className="w-full float-right">
        {" "}
        <select
          value={daysSelected}
          className="w-full bg-white border border-gray-200"
          onChange={(e) => setDaysSelected(e.target.value)}
        >
          <option value={3}>Last 3 Days</option>
          <option value={7}>Last 7 Days</option>
          <option value={14}>Last 14 Days</option>
          <option value={30}>Last 30 Days</option>
        </select>
      </div>
    </>
  );
}

export default JobNewViewApplyMetricsChart;
