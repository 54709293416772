import { useEffect } from "react"
import { useState } from "react"
import { LoaderIcon, toast } from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { updateResetPassword } from "../../callable-cloud-functions/cloudFunctions"
import { useResetEmail } from "../../data/resetPassword"
import InstantTeamsSpinner from "../custom-ui/animations/spinner/InstantTeamsSpinner"
import ToastAlert from "../custom-ui/toast-alert/ToastAlert"

const ResetPasswordForm = () => {
  const params = useParams()
  const [viewPassword, setViewPassword] = useState(false)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [passwordsMatch, setPasswordsMatch] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const { 
    data: resetEmail,
    status: resetEmailStatus,
    error: resetEmailError
  } = useResetEmail(params.resetId || "")

  const navigateSignin = () => {
    navigate("/auth/signin")
  }

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setPassword(e.target.value)
  }

  const handleConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setConfirmPassword(e.target.value)
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setLoading(!loading)
    const result: any = await updateResetPassword({ user: resetEmail.email, newPassword: password, resetEmail: params.resetId })
    if (result.data.success) {
      toast.success(result.data.message)
    } else {
      toast.error(result.data.message)
    }
    setTimeout(navigateSignin, 6000) 
  }

  useEffect(() => {
    if (password === confirmPassword && password.length !== "" && confirmPassword !== "") {
      setPasswordsMatch(true)
    } else {
      setPasswordsMatch(false)
    }
  },[password, confirmPassword])
  
  return (
    <div className="pt-36 mx-auto w-1/2 grid grid-cols-1 h-full">
      {loading ?
        <div className="flex flex-col items-center justify-center text-xl font-bold">
          <InstantTeamsSpinner height="25vh" width="30vw" />
          Please wait while we update your password.
        </div> :
      <>
      {resetEmail && resetEmail.expires.toDate() >= new Date(Date.now()) ? (
            <div className="h-[30vh] flex flex-col justify-center items-center text-center">
            <p className="text-xl font-bold pb-10">Reset Your Password</p>
          <form className=" grid grid-cols-1 gap-3 mb-2 relative w-full" onSubmit={handleSubmit}>
          {
        viewPassword ? 
          <i className="fa-solid fa-eye absolute p-2.5 w-10 right-4" onClick={() => { setViewPassword(!viewPassword) }}/> :
          <i className="fa-solid fa-eye-slash  absolute p-2.5 w-10 right-4" onClick={() => { setViewPassword(!viewPassword) }}/>
      }
            <input type={viewPassword ? "text" : "password"} placeholder="Password" className="w-full p-2" onChange={handlePassword} value={password} />
            {
        viewPassword ? 
          <i className="fa-solid fa-eye absolute p-2.5 w-10 right-4 top-14" onClick={() => { setViewPassword(!viewPassword) }}/> :
          <i className="fa-solid fa-eye-slash  absolute p-2.5 w-10 right-4 top-14" onClick={() => { setViewPassword(!viewPassword) }}/>
      }
            <input type={viewPassword ? "text" : "password"} placeholder="Confirm Password" className="w-full p-2" onChange={handleConfirmPassword} />
            <div className="grid grid-cols-2 gap-4">
            <button className=" w-full mx-auto text-2xl text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none" type="button" onClick={navigateSignin}>Back to Sign In</button>
            {passwordsMatch ? <button className=" w-full mx-auto text-2xl text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none" type="submit">Change Password</button> :
            <button className="w-full mx-auto text-2xl text-white bg-gray-500 rounded border-0" disabled>Change Password</button>
            }
            </div>
          </form>
        </div>)
        :
        (<div className=" h-[50vh] flex flex-col items-center justify-center text-4xl text-center">
          <p  className="pb-10">Your email reset is invalid or had expired</p>
          <button onClick={navigateSignin} className=" w-1/2 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none">Return to login</button>
        </div>
      )}
          <ToastAlert />
          </>
    }
    </div>
  )
}

export default ResetPasswordForm