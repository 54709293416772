import { useEffect, useState } from "react";
import { updateAutoSave, setAutoSave } from "../../../data/utility";

function AutoSaveCheckboxGroup({
  options,
  initialValues = [],
  collection,
  id,
  name,
  disabled = false,
  gridLayoutClass = "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
  ...props
}) {
  const [selections, setSelections] = useState(initialValues ?? []);
  const [trigger, setTrigger] = useState();
  useEffect(() => {
    setTrigger(true);
  }, []);

  useEffect(() => {
    if (trigger) {
      updateAutoSave(collection, id, { [name]: selections }).catch((error) => {
        console.log("autosavecheckboxgroup, updateAutoSave error: ", error);
        setAutoSave(collection, id, { [name]: selections })
          .then(() => {
            console.log("autosavecheckboxgroup, set autosave success");
          })
          .catch((error) => {
            console.log("autosavecheckboxgroup, set autosave error: ", error);
          });
      });
    }
  }, [selections]);

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelections((prevSelections) => [...prevSelections, value]);
    } else {
      const omittedUnchecked = selections.filter((item) => item !== value);
      setSelections(omittedUnchecked);
    }
  };

  const isSelected = (item) => !!initialValues.find((val) => val === item.code);

  return (
    <div id={name} className={gridLayoutClass}>
      {options.map((item) => (
        <div key={item.id} className="flex items-start ">
          <>
            <input
              className="h-5 w-5 shadow text-instant-teams-blue-L1 leading-8 bg-white rounded border-instant-teams-blue-Main outline-none focus:border-instant-teams-blue-Main focus:ring-1"
              id={item.id}
              name={name ?? "checkboxGroup"}
              type="checkbox"
              value={item.code}
              onChange={handleChange}
              checked={isSelected(item)}
              disabled={disabled}
            />
            <label
              className="mb-1 px-2 text-sm font-semibold text-instant-teams-blue-Main"
              htmlFor={item.id}
            >
              {item.label}
            </label>
          </>
        </div>
      ))}
    </div>
  );
}

export default AutoSaveCheckboxGroup;
