import React, { useRef, useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import { useForm } from "react-hook-form";
import AuthSocial from "./AuthSocial";
import MultiFactorSignInNew from "./multi-factor/MultiFactorSignInNew";
import { useAuth } from "../../providers/auth";
import { friendlyFirebaseError } from "../../providers/firebase-errors/friendlyFirebaseError";

function ReauthModalNew(props) {
  const auth = useAuth();
  const { handleMultiFactorSignIn } = auth;
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [shouldMfaStart, setShouldMfaStart] = useState(false);
  const [mfaResolver, setMfaResolver] = useState();
  const cancelButtonRef = useRef(null);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    const { pass } = data;
    setPending(true);
    // TODO: use signIn method based on user type - admins use google workspace provider / customers use email and password
    auth
      .signin(auth.user.email, pass)
      .then(() => {
        // Call failed action that originally required reauth
        props.callback();
        // Let parent know we're done so they can hide modal
        props.onDone();
      })
      .catch((error) => {
        if (error.code === "auth/multi-factor-auth-required") {
          handleMultiFactorSignIn(
            error,
            setShouldMfaStart,
            setMfaResolver,
            setFormAlert,
          );
        } else {
          // Show error alert message

          setFormAlert({
            type: "error",
            message: friendlyFirebaseError(error),
          });
        }
      })
      .finally(() => setPending(false));
  };

  return (
    <DialogWrapper onClose={() => props.onDone()}>
      <div className="mt-4">
      <div className="flex mb-6">
                    
                    <p className="border-b w-full py-2 font-bold text-xl text-[#15415E]">Reauthenticate</p>
                </div>

        {props.provider === "password" && !shouldMfaStart && (
          <form onSubmit={handleSubmit(onSubmit)}>
            

        <div className="relative mb-7">
          <input
            type="password" // Toggle input type
            name="pass"
            placeholder=" "
            ref={register({
                required: "Please enter your password",
              })}
            
            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
            style={{ boxShadow: 'none' }}
          />
          <label
            htmlFor="password"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Password <span className="text-red-600">*</span>
          </label>

         
          {errors.pass && (
              <p className="mt-1 text-sm text-left text-red-600">
                {errors.pass.message}
              </p>
            )}


         
        </div>

            <div className="flex justify-around space-x-10 mt-10 mb-4 mx-5">
              <button
                className="py-2 bg-whit border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black w-full h-12 rounded-full"
                type="button"
                onClick={() => props.onDone()}
                ref={cancelButtonRef}
              >
                Cancel
              </button>
              <button
                className="py-2 px-8 text-white font-medium rounded-lg focus:outline-none focus:ring-2 rounded-full bg-[#15415E] w-full"
                type="submit"
                disabled={pending}
              >
                {pending ? "..." : "Sign in"}
              </button>
            </div>
          </form>
        )}

        {shouldMfaStart && (
          <MultiFactorSignInNew
            mfaResolver={mfaResolver}
            onAuth={() => {
              props.callback();
              props.onDone();
            }}
            onFormAlert={setFormAlert}
          />
        )}

        {props.provider !== "password" && (
          <AuthSocial
            buttonAction="Sign in"
            providers={[props.provider]}
            showLastUsed={false}
            onAuth={() => {
              props.callback();
              props.onDone();
            }}
            onError={(message) => {
              setFormAlert({
                type: "error",
                message: message,
              });
            }}
          />
        )}
      </div>
    </DialogWrapper>
  );
}

export default ReauthModalNew;
