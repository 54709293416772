import React, { useState, useContext, useEffect, Fragment } from "react";
import DisplaySkillNames from "../../../components/custom-ui/DisplaySkill/DisplaySkillNames";
import Meta from "../../../components/custom-ui/meta/Meta";
import { requireAuth } from "../../../providers/auth";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToggleButton from "../../../components/custom-ui/ToggleButton/ToggleButton";
import { getCannedSkills, getTACTagsSkills } from "../../../data/getSkills";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useSkillState } from "../../../providers/FilterSkillsProvider/FilterSkillsProvider";
import useRoleGuard from "../../../hooks/useRoleGuard";

function GenerateChorts() {
    useRoleGuard();
    const navigate = useNavigate();
    const [isTACVerifedOn, setIsTACVerifedOn] = useState(false);
    const [isExactMatch, setIsExactmatch] = useState(false);
    const [error, setError] = useState()
    const [userInputSkillValue, setUserInputSkillValue] = useState("");
    const [skillSuggestionList, setSkillSuggestionList] = useState([]);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [skills, setSkills] = useState([]);
    const { skillsToFilter, setSkillsToFilter } = useSkillState();


    const handleInputChange = (e: any) => {
        setError("")
        const value = e.target.value;
        const suggestionList = skillSuggestionList;
        if (value.includes(',')) {
            const skill = value.split(',')[0].trim();
            if (skill && !suggestionList.includes(skill)) {
                addSkill(skill);
            }
            setUserInputSkillValue("");
        } else {
            setUserInputSkillValue(value);
            if (value) {
                const filtered = suggestionList.filter((skill: string) => {
                    const lowerCaseSkill = skill.toLowerCase();
                    const lowerCaseValue = value.toLowerCase();
                    return lowerCaseSkill.split(' ').some((word: string) => word.startsWith(lowerCaseValue)) || lowerCaseSkill == lowerCaseValue;
                });
                setFilteredSuggestions(filtered);
            } else {
                setFilteredSuggestions([]);
            }
        }
    };

    const handleSuggestionClick = (suggestion: string) => {
        const currentSkills = skills;
        const setCurrentSkills = setSkills;
        if (!currentSkills.some((skill: any) => skill.skill === suggestion)) {
            setCurrentSkills([...currentSkills, { skill: suggestion, customSkill: false }]);
        }
        setUserInputSkillValue("");
        setFilteredSuggestions([]);
    };
    const removeSkill = (skillToRemove: any) => {
        const currentSkills = skills;
        const setCurrentSkills = setSkills;
        setCurrentSkills(currentSkills.filter((skill: any) => skill.skill !== skillToRemove.skill));

    };

    const addSkill = (skill: string) => {
        const currentSkills = skills;
        const setCurrentSkills = setSkills;

        if (skill && !currentSkills.some((s: any) => s.skill === skill)) {
            setCurrentSkills([...currentSkills, { skill: skill, customSkill: true }]);
            setFilteredSuggestions([])
        }
    };

    useEffect(() => {
        const fetchSkills = async () => {
            const skillData = await getCannedSkills();
            setSkillSuggestionList(skillData?.map((data: any) => data?.skill_name) ?? []);
        };
        fetchSkills();
    }, []);

    useEffect(() => {
        setSkillsToFilter(["DATA"]);
    }, [skills, setSkillsToFilter]);

    const generateChort = () => {

        if (skills.length != 0) {
            navigate("/cohort/listing", {
                state: {
                    skills: skills,
                    tacVerified: isTACVerifedOn,
                    exactMatch: isExactMatch,
                },
            });
        }
        else {
            setError("Add atleast one skill to generate cohort")
        }
    }

    return (
        <>
            <div className="bg-gray-200 h-[100vh] mt-5 flex justify-center  p-5 items-center w-full ">
                <Meta title="Cohort Management" />
                <div className="flex w-full h-full justify-center border-4 rounded-xl bg-white items-center">
                    <div >
                        <div className="py-5 text-center">
                            <p className="items-center text-[#6E6E6E] text-[20px]" >Please select skill(s) to generate cohort.</p>
                        </div>
                        <div className="w-[430px] h-full bg-gray-100 rounded-xl p-5">
                            {error ? (<p className=" text-[14px] text-red-500">{error}</p>) : (<p className=" text-[14px] text-[#040404]">Add Relevant Skills</p>)}
                            <div className="w-full">
                                {skills?.map((data: any, index: number) => (
                                    <Fragment key={index}>
                                        <DisplaySkillNames
                                            skillname={data.skill}
                                            displayCloseIcon={true}
                                            customSkill={data.customSkill}
                                            removeSkill={() => removeSkill(data)}
                                        />
                                    </Fragment>
                                ))}
                            </div>
                            <p className="mt-10 text-[#8D8D8D] text-[12px]">Type skill followed by comma</p>
                            <div className="border-[1px] w-full flex justify-between mt-3 p-2 rounded-[6px] bg-white border-[#1A405C] relative">
                                <input
                                    className="w-full border-transparent focus:border-transparent focus:ring-0 h-7"
                                    placeholder="Add Skill"
                                    value={userInputSkillValue}
                                    onChange={handleInputChange}
                                />
                                {userInputSkillValue && (
                                    <div className="flex items-center justify-center w-7 h-7 px-1 bg-gray-100 rounded-full cursor-pointer"
                                        onClick={() => { setUserInputSkillValue(""); setFilteredSuggestions("") }}
                                    >
                                        <FontAwesomeIcon icon={faTimes} color="#565656" />
                                    </div>
                                )}
                            </div>
                            {filteredSuggestions.length > 0 && (
                                <div className="bg-white border border-gray-300 w-full left-0 mt-1 max-h-40  overflow-y-auto rounded-md shadow-lg z-50">
                                    {filteredSuggestions?.map((suggestion: any, index: number) => (
                                        <div
                                            key={index}
                                            className="p-2 hover:bg-gray-200 cursor-pointer"
                                            onClick={() => handleSuggestionClick(suggestion)}
                                        >
                                            {suggestion}
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div className="flex mt-1  w-full justify-between items-center" >
                                <ToggleButton isOn={isTACVerifedOn} handleClick={() => setIsTACVerifedOn(!isTACVerifedOn)} buttonText="TAC verified" />
                                <ToggleButton isOn={isExactMatch} handleClick={() => setIsExactmatch(!isExactMatch)} buttonText="Exact match" />
                            </div>
                            <div className="">
                                <button
                                    onClick={generateChort}
                                    className="py-2 w-full px-4 text-md mt-7 justify-center mx-auto text-white bg-instant-teams-blue-Main rounded-lg border-0 hover:bg-instant-teams-blue-D1 focus:outline-none"
                                >
                                    Generate Cohort
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default requireAuth(GenerateChorts);