/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useQuery,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  orderBy,
  addDoc,
  Timestamp,
} from "firebase/firestore";
import { db, createQuery } from "../providers/database";
import { SyncRequest } from "../dataTypes/academy";
import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";

export function useRecordings(trigger: string) {
  return useQuery(
    ["allRecordings", trigger],
    createQuery(() => query(collection(db, collectionNames.videos))),
    { enabled: !!trigger },
  );
}

export function createRecording(recording: { video: string }) {
  // add employer status "lead"
  return addDoc(collection(db, collectionNames.videos), {
    ...recording,
    timestamp: Timestamp.now(),
    active: true,
    slug: "",
  });
}

export function useRecording(id: string) {
  return useQuery(
    ["recording", { id }],
    createQuery(() => doc(db, collectionNames.videos, id)),
    { enabled: !!id },
  );
}
