import React from "react";
import { useParams } from "react-router-dom";
import Meta from "../../components/custom-ui/meta/Meta";
import { requireAuth } from "../../providers/auth";
import {
  useUserNotificationIds,
  updateAllIsRead,
} from "../../data/notifications";
import { useAuth } from "../../providers/auth";
import UserNotificationItem from "../../components/notifications/UserNotificationItem";
import GreenUnderlineHeading from "../../components/custom-ui/headings/heading";

const NotificationsPage = ({ headerTitle }) => {
  const params = useParams();
  const user = useAuth();
  const {
    data: userNotificationList,
    status: notificationsStatus,
    error: notificationsError,
  } = useUserNotificationIds(user.user.id);

  function handleAllRead() {
    updateAllIsRead(params.userId);
  }

  return (
    <div className=" mx-36 pt-36 min-h-screen">
      <Meta title="My Notifications" />
      <GreenUnderlineHeading title="My Notifications" />
      <div className="flex w- justify-end">
        <button
          className="bg-instant-teams-green-D1 rounded-full p-2 text-white"
          onClick={handleAllRead}
        >
          Mark All Read
        </button>
      </div>
      <table className="w-full">
        {userNotificationList &&
          userNotificationList.length > 0 &&
          userNotificationList.map((userNotification) => (
            <UserNotificationItem
              userNotification={userNotification}
              key={userNotification.notificationId}
            />
          ))}
      </table>
    </div>
  );
};

export default requireAuth(NotificationsPage);
