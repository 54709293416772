const PaginationControls = ({ currentPage, totalPages, setCurrentPage }: any) => {
    const handlePrevPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    return (
        <div className="flex w-full justify-start items-center mb-5 ">
            <button onClick={handlePrevPage} disabled={currentPage === 1} className="px-2 py-1 mx-2 bg-white rounded   hover:[#19405E] ">
                Previous
            </button>
            <span>{totalPages === 0 ? 0 : currentPage} of {totalPages}</span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages} className="px-2 py-1 mx-2 bg-white rounded hover:[#19405E] ">
                Next
            </button>
        </div>
    );
};

export default PaginationControls
