import { useEffect, forwardRef, useState } from "react";
import { cn } from "../../../util/cn";

// Helper function to parse currency strings
const parseCurrencyValue = (value: string | number | undefined): string => {
  if (typeof value === "undefined" || value === null || value === "") return "";

  // If it's a number, just return it
  if (typeof value === "number") return String(value);

  // Remove currency symbols and commas, then parse as float
  const cleanedValue = value.toString().replace(/[^0-9.-]+/g, "");
  const parsedValue = parseFloat(cleanedValue);

  return isNaN(parsedValue) ? "" : String(parsedValue);
};

// Function to format the input as a currency
const formatCurrency = (value: string, currencyCode: string) => {
  const numberValue = parseFloat(value.replace(/[^0-9.]/g, "")); // Remove non-numeric characters
  if (isNaN(numberValue)) return ""; // Handle invalid number case
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 2, // Keep two decimal places
  })
    .format(numberValue)
    .slice(1); // Remove currency symbol for display
};

interface InputCurrencyProps {
  name: string;
  display: string;
  value?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  readOnly?: boolean;
  maxLength?: number;
  required?: boolean;
  error?: string;
  touched?: boolean;
  currency: string; // Currency code like "USD", "EUR", etc.
}

const InputCurrency = forwardRef(
  (
    {
      name,
      display,
      value = "",
      placeholder = "",
      onChange,
      readOnly = false,
      maxLength = 50,
      required = false,
      error,
      touched,
      currency = "USD", // Default to USD
      ...props
    }: InputCurrencyProps,
    ref: any,
  ) => {
    const [rawInput, setRawInput] = useState(value);

    // Update raw input when value prop changes
    useEffect(() => {
      setRawInput(value);
    }, [value]);

    // Function to get the currency symbol based on currency code
    const getCurrencySymbol = (currencyCode: string) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currencyCode,
      })
        .formatToParts(1)
        .find((part) => part.type === "currency")?.value;
    };

    // Handle input change and let users type freely
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const rawValue = e.target.value;

      // Allow input of numbers and decimal point, limit to two decimal places
      if (/^\d*\.?\d{0,2}$/.test(rawValue)) {
        setRawInput(rawValue); // Update raw input without formatting
        onChange?.(parseCurrencyValue(rawValue)); // Send parsed value to the parent
      }
    };

    // Format the input value when the input loses focus (on blur)
    const handleBlur = () => {
      const formattedValue = formatCurrency(rawInput, currency);
      setRawInput(formattedValue); // Apply formatting when user leaves the input
    };

    const currencySymbol = getCurrencySymbol(currency); // Retrieve the currency symbol based on the provided currency code

    return (
      <div id={name} className="flex-auto">
        <div className="relative mb-4">
          <label
            className={`absolute text-sm text-instant-teams-teal-Main left-3 top-2 transition-all duration-300 z-10
            ${
              rawInput || placeholder
                ? "-translate-y-2 scale-75" // Label stays up if there's a value or placeholder
                : "translate-y-0 scale-100"
            } // Label is down if no value
              peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-instant-teams-blue-Main`}
          >
            {display}
            {required && " *"}
            {readOnly ? " - LOCKED" : null}
          </label>
          <div className="flex items-center relative">
            {/* Dynamic currency symbol based on currency code */}
            <span className="absolute left-3 text-gray-500 text-lg">
              {currencySymbol}
            </span>
            <input
              type="text"
              name={name}
              placeholder={placeholder}
              readOnly={readOnly}
              maxLength={maxLength}
              ref={ref} // forwarding the ref
              className={cn(
                `form-input shadow-md rounded-md w-full pt-4 pb-3 pl-8 text-[1rem] text-gray-800 placeholder-gray-400 peer`,
                {
                  "cursor-not-allowed": readOnly,
                },
              )}
              value={rawInput} // Bind to the unformatted raw input
              onChange={handleChange} // On change handler
              onBlur={handleBlur} // On blur handler to format the input
              {...props}
            />
          </div>
          {error && (
            <p className={"text-[0.8rem] font-medium text-[red] mt-1"}>
              {error}
            </p>
          )}
        </div>
      </div>
    );
  },
);

InputCurrency.displayName = "InputCurrency";

export default InputCurrency;
