import GreenUnderlineHeading from "../../components/custom-ui/headings/heading";
import Meta from "../../components/custom-ui/meta/Meta";
import { useOffersByStatuses } from "../../data/seeker";
import { historyStatuses, openStatuses } from "../../dataTypes/Offer";
import { requireAuth, useAuth } from "../../providers/auth";
import WedgeSpinner from "../../components/spinners/wedges-spinner";
import SeekerOffersCard from "../../components/seekerView/SeekerOffersCard";
function SeekerOffersPage() {
  const { user } = useAuth();
  const { data: activeOffersData, isLoading: activeOffersLoading } =
    useOffersByStatuses(user?.uid, openStatuses);
  const { data: historyOffersData, isLoading: historyOffersLoading } =
    useOffersByStatuses(user?.uid, historyStatuses);

  if (activeOffersLoading || historyOffersLoading) {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <WedgeSpinner />
      </div>
    );
  }
  return (
    <>
      <Meta title="Offers" />
      <section className="pt-40 pb-16 w-3/5 mx-auto flex flex-col place-item-center">
        <GreenUnderlineHeading title="Open" />
        {activeOffersData && activeOffersData?.length > 0 ? (
          activeOffersData?.map((offer: any) => {
            return (
              <SeekerOffersCard
                key={offer?.id}
                offer={offer}
                renderButtons={true}
              />
            );
          })
        ) : (
          <div className="mx-auto text-instant-teams-blue-Main text-xl">
            You don't have new offers.
          </div>
        )}
        <GreenUnderlineHeading title="History" />
        {historyOffersData && historyOffersData.length > 0 ? (
          historyOffersData?.map((offer: any) => {
            return (
              <SeekerOffersCard
                key={offer?.id}
                offer={offer}
                renderButtons={false}
              />
            );
          })
        ) : (
          <div className="mx-auto text-instant-teams-blue-Main text-xl">
            You don't have offer history.
          </div>
        )}
      </section>
    </>
  );
}
export default requireAuth(SeekerOffersPage);
