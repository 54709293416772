import React, { useState, useEffect } from "react";
import { useUser } from "../../../../data/user";
import { DepartmentsItem } from "../../../../dataTypes/Company";
import { DisplayDate } from "../../../../util/timestamps/timestamp";
import AutoSaveText from "../../../custom-ui/input-fields/auto-save-field";
import AutoSaveSelectEmployerUsers from "../../../custom-ui/input-fields/auto-save-select-by-employer-role";
import { TailwindHoverEnlarge } from "../../../../util/tailwind/tailwind-styles";
import { usePositionsByDepartment } from "../../../../data/positions";
import { useJobsByDepartment } from '../../../../data/jobDescriptions';
import { useApplicationsByJobId } from '../../../../data/applications';
import { useEmployeesByJobId } from '../../../../data/employee';

const DepartmentItemShow = ({
  key,
  employerId,
  department,
}: {
  key: string;
  employerId: string;
  department: any;
}) => {
  const [createdBy, setCreatedBy] = useState(department.createdBy);
  const [creatorName, setCreatorName] = useState("Unknown");
  const [jobList, setJobList] = useState([]);
  const [collectionName, setCollectionName] = useState("");

  const {
    data: userData = {},
    status: userStatus = "",
    error: userError,
  } = useUser(createdBy);

  const {data: jobsData = {}} = useJobsByDepartment(department.id);
  const { data: positions = [] } = usePositionsByDepartment(department?.id);
  const { data: applications = [] } = useApplicationsByJobId(jobList);
  const { data: employees = [] } = useEmployeesByJobId(jobList);


  useEffect(() => {
    setCreatedBy(department.createdBy);
  }, [department]);

  useEffect(() => {
    if(userData){
      setCreatorName(userData.name);
    }
  }, [userData]);
  
  useEffect(() => {
    const jobListCollection = [];
    if(jobsData && jobsData.length > 0){
      for(const job of jobsData){
        jobListCollection.push(job.id);
      }
    }
    setJobList(jobListCollection);
  }, [jobsData]);

  useEffect(() => {
    const collectionUpdate = "Employer/" + employerId + "/Departments";
    setCollectionName(collectionUpdate);
  }, [employerId, collectionName]);

  return (
    <div
      key={key}
      className={
        TailwindHoverEnlarge(103) +
        "rounded-md border border-black border-solid shadow-md bg-white p-3"
      }
    >
      <div className="text-xl flex items-center justify-center w-100">
        <AutoSaveText
          value={department.label}
          collection={collectionName}
          recId={department.id}
          required={true}
          name="label"
          display="Department Name"
          placeholder={"Department Name"}
          directions=""
          readOnly={false}
        />
      </div>

      <AutoSaveSelectEmployerUsers
        value={department.manager}
        employerId={employerId}
        collection={collectionName}
        recId={department.id}
        required={false}
        name="manager"
        display="Department Manager"
        placeholder={"Department Manager"}
        directions=""
      />
      <div className="text-xs w-100 pr-5 pl-5 pt-2 mt-5">
        Jobs{" "}
        <span className="float-right text-rose-600">
          {jobsData && jobsData?.length }
        </span>
      </div>
      <div className="text-xs w-100 pr-5 pl-5 pt-2">
        Applicants <span className="float-right text-orange-600">{applications && applications.length}</span>
      </div>
      <div className="text-xs w-100 pr-5 pl-5 pt-2">
        Hired <span className="float-right text-green-600">{employees && employees.length}</span>
      </div>
      <div className="text-xs flex items-center justify-center w-100 pt-5">
        {/* (Created: {creatorName} on {DisplayDate(department.createdAt)}) */}
      </div>
    </div>
  );
};

export default DepartmentItemShow;
