import { useEffect, useState } from "react";
import GreenUnderlineHeading from '../custom-ui/headings/heading';
import { sanitizeMarkup } from "../../util/sanitization/sanitize-html";
import "../../styles/dangerouslySet.css";


function EnrollmentResultsItem({ question }: { question: any }) {
    
  return (
    <div key={question.id} className='w-full'>
      <div className='flex flex-col'>
        <GreenUnderlineHeading title={"Question " + question?.order_answer}/>
        <p>Question:</p>
        <div
          className="dangerouslyLT w-full"
          dangerouslySetInnerHTML={sanitizeMarkup(
            question?.question?.text,
          )}
        ></div>
        <p>Answer:</p>
        <div
          className="dangerouslyLT w-full text-instant-teams-teal-Main font-bold"
          dangerouslySetInnerHTML={sanitizeMarkup(
            question?.question?.answers[0].text,
          )}
        ></div>
      </div>
    </div>
  );
}
export default EnrollmentResultsItem;
