import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useCommunityAttempts,
  useCommunityAuthByToken,
  useCommunityAuth,
  useCommunityFailures,
  useCommunityDeclines,
  getVerifiedUserData
} from '../../../data/community';
import { useUser } from '../../../data/user';
import GreenUnderlineHeading from "../../custom-ui/headings/heading";

const CommunityDashboard = () => {
  const params = useParams();
  const [id, setId] = useState(params.userId);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [milSpouse, setMilSpouse] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [getVerifiedData, setGetVerifiedData] = useState()

  const {
    data: userData,
    status: userStatus,
    error: userError,
  } = useUser(id ?? params.userId);

  const {
    data: commAuth,
    error: authErrors,
    status: authStatus,
  } = useCommunityAuth(id ?? params.userId)

  useEffect(() => {
    setEmail(userData?.email);
    setRole(userData?.claims.role);
    setMilSpouse(userData?.claims.milspouse);
    if (commAuth) {
        setAuthToken(commAuth[0]?.id);
    }
  }, [params, userData]);

  const {
    data: attempts,
    error: attemptsErrors,
    status: attemptsStatus,
  } = useCommunityAttempts(email);
  const {
    data: commErrors,
    error: errorsErrors,
    status: errorsStatus,
  } = useCommunityFailures(email);

  const {
    data: commDeclines,
    error: declinesErrors,
    status: declinesStatus,
  } = useCommunityDeclines(email);

  const {
    data: commTokens,
    error: tokensErrors,
    status: tokensStatus,
  } = useCommunityAuthByToken(authToken);

  useEffect(() => {
    const fetchUserData = async () => {
      const data = await getVerifiedUserData(email);
      setGetVerifiedData(data)
      console.log("Dynamic value fethced", data);
    };
    fetchUserData();
  }, [email]);

  function formatString(str: any) {
    let formattedString = str.replace(/_/g, ' ');
    formattedString = formattedString.toLowerCase();
    formattedString = formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
    return formattedString;
  }


  return (
    <div>
      {role === "SKR" || milSpouse ? (
        <div>
          <GreenUnderlineHeading title="Community Member Tokens" />
          <div>
            <p className="font-bold">Currently Active Token: {commTokens ? "true" : "false"}</p>
          </div>
          <GreenUnderlineHeading title="ID.me / Sheer Id Declines" />
          {getVerifiedData && getVerifiedData?.length > 0 && !getVerifiedData[0]?.verified ? (
            <div>
              {getVerifiedData.map((decline: any) =>
                <div key={decline.id} className="border border-instant-teams-teal-D2 rounded-lg p-2">
                  <p>{`Rejected Reason: ${formatString(decline.rejectReasons)}`}</p>
                  <p>{`Verification Id : ${decline.verificationId}`}</p>
                  <p>{"Date: " + new Date(decline.creationTime.seconds * 1000).toDateString()}</p>
                </div>
              )}
            </div>
          ) : (
            commDeclines && commDeclines.length > 0 ? (
              <div>
                {commDeclines.map((decline: any) =>
                  <div key={decline.id} className="border border-instant-teams-teal-D2 rounded-lg p-2">
                    <p>{`Rejected Reason: ${decline.rejectReasons}`}</p>
                    <p>{"Date: " + new Date(decline.date.seconds * 1000).toDateString()}</p>
                  </div>
                )}
              </div>
            ) : (
              <p>No Disagreements with Verification Declines</p>
            )
          )}
          <GreenUnderlineHeading title="ID.me / Sheer Id Verification" />
          {getVerifiedData && getVerifiedData[0]?.verified ? (
            <div>
              <div className="flex">
                {getVerifiedData.map((data: any) => (
                  <div key={data.verificationId} className="border border-instant-teams-teal-D2 rounded-lg p-2">
                    {data.verificationId ? <p>Verified with Sheer Id</p> : <p>Verified with ID.Me</p>}
                    <p>{`Verification Id: ${data.verificationId ? data.verificationId : data.idmeUUID}`}</p>
                    <p>{"Date: " + new Date(data.creationTime.seconds * 1000).toDateString()}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : (<p>No data</p>)}
          <GreenUnderlineHeading title="Oauth 2 Errors" />
          {commErrors && (
            <div>
              <p className="font-bold">Total Errors: {commErrors?.length}</p>
              <div className="flex">
                {commErrors.map((error: any) =>
                  <div key={error.id} className="border border-instant-teams-teal-D2 rounded-lg p-2">
                    <p>{`Error: ${error.action}`}</p>
                    <p>{`Message: ${error.description}`}</p>
                    <p>{`Date: ${new Date(error.occurence.seconds * 1000).toDateString()}`}</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (<h1>This user is not a community member</h1>)}

    </div>
  );
}

export default CommunityDashboard;