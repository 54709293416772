import Tooltip from "../tooltip/tooltip";
function LockedFieldIcon({
  isLocked = false,
  text,
}: {
  isLocked?: boolean;
  text?: string;
}) {
  return (
    <>
      {isLocked ? (
        <Tooltip text={text}>
          <i className="mx-2 fa-solid fa-lock text-instant-teams-teal-D1"></i>
        </Tooltip>
      ) : (
        <i className="mx-2 fa-regular fa-lock-open text-instant-teams-teal-D1 opacity-50"></i>
      )}
    </>
  );
}

export default LockedFieldIcon;
