import gif from "./gifs/Wedges-2.5s-200px.gif";

const WedgeSpinner = () => {
  return (
    <>
      <img src={gif} alt="loading..." />
    </>
  );
};

export default WedgeSpinner;
