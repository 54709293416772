import React from 'react';

export const csvDownload = (data,fileName) => {


  if(data){  const csvHeaders = Object.keys(data[0]).join(',') + '\n';
    const csvRows = data.map(row =>
      Object.values(row).map(value => `"${value}"`).join(',')
    ).join('\n');

    const csvContent = 'data:text/csv;charset=utf-8,' + csvHeaders + csvRows;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}



};


