import { useLocation, useParams } from "react-router-dom";
import EmployeesAlgolia from "./EmployeesAlgolia";
import { useAuth } from "../../../../providers/auth";
const Employees = () => {
  const location = useLocation();
  const { userClaims } = useAuth();
  const params = useParams();

  const adminCompanyFilters = `employerId:${params?.employerId}`;
  const adminJobFilters = `employerId:${params?.employerId} AND jobId:${params?.id}`;
  const externalCompanyFilters = `employerId:${userClaims?.claims?.employerId}`;
  const externalJobFilters = `employerId:${userClaims?.claims?.employerId} AND jobId:${params?.id}`;

  return (
    <>
      {userClaims?.claims?.type === "A" &&
      location.pathname.includes("employers") ? (
        <EmployeesAlgolia configureFilterString={adminCompanyFilters} />
      ) : null}
      {userClaims?.claims?.type === "A" &&
      location.pathname.includes("jobs") ? (
        <EmployeesAlgolia configureFilterString={adminJobFilters} />
      ) : null}
      {userClaims?.claims?.type === "E" &&
      location.pathname.includes("company") ? (
        <EmployeesAlgolia configureFilterString={externalCompanyFilters} />
      ) : null}
      {userClaims?.claims?.type === "E" &&
      location.pathname.includes("jobs") ? (
        <EmployeesAlgolia configureFilterString={externalJobFilters} />
      ) : null}
    </>
  );
};

export default Employees;
