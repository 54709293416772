import { useEffect, useState } from "react";
import { useEmployer } from "../data/employer";

export const useEmployerData = ({
  jobData,
  originalEmployerId,
}: {
  jobData: any;
  originalEmployerId: string;
}) => {
  const INSTANTTEAMS_ID = process.env.REACT_APP_INSTANT_TEAMS_ID;
  const initialEmployerId =
    jobData?.solutionType === "BPO" ? INSTANTTEAMS_ID : originalEmployerId;
  const [employerId, setEmployerId] = useState(initialEmployerId);

  useEffect(() => {
    const newEmployerId =
      jobData?.solutionType === "BPO" ? INSTANTTEAMS_ID : originalEmployerId;

    setEmployerId(newEmployerId);
    return () => {
      setEmployerId(null);
    };
  }, [jobData?.solutionType, originalEmployerId, INSTANTTEAMS_ID]);

  const { data: employerData } = useEmployer(employerId);
  return { employerId, employerData };
};
