/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAllActivityByCollection } from "../../data/activity";
import ActivityLogItem from "./activityLogItem";
import GreenUnderlineHeading from "../custom-ui/headings/heading";
import { DisplayTimestamp } from "../../util/timestamps/timestamp";
const getDateByDaysAgo = (daysAgo: number) => {
  const now = new Date();
  const xDaysAgo = new Date(now.getTime() - daysAgo * 24 * 60 * 60 * 1000);
  return xDaysAgo;
};

const xDaysAgoIsGTE = (
  xDaysAgo: string | number | Date,
  comparate: string | number | Date,
) => {
  let date1 = new Date(xDaysAgo).getTime();
  let date2 = new Date(comparate).getTime();
  // console.log("ActivityLogs, date1: ", date1);
  // console.log("ActivityLogs, date2: ", date2);
  if (date1 < date2) {
    return false;
  } else if (date1 > date2) {
    return true;
  } else {
    return true;
  }
};

const ActivityLogs = ({
  collection,
  paramId,
}: {
  collection: string;
  paramId: string;
}) => {
  const params = useParams();
  const [id, setId] = useState("");
  const [daysSelected, setDaysSelected] = useState(14);
  const [activityMetadata, setActivityMetadata] = useState([]);
  useEffect(() => {
    if (paramId === "userId") {
      setId(params.userId);
    } else if (paramId === "employerId") {
      setId(params.employerId);
    } else if (paramId === "jobId") {
      setId(params.id);
    }
  }, [params, paramId]);
  let {
    data: activity,
    status: activityStatus,
    error: activityError,
  } = useAllActivityByCollection(id, collection);
  useEffect(() => {
    const xDaysAgo = getDateByDaysAgo(daysSelected);
    let ret: any[] = [];
    activity?.forEach((element: any) => {
      const activityCreatedAt = element?.created_at?.toDate();
      // if activityCreatedAt is NOT LTE xDaysAgo,
      // then it is legit and we display it
      if (!xDaysAgoIsGTE(xDaysAgo, activityCreatedAt)) {
        ret.push(element);
      }
    });
    setActivityMetadata(ret);
  }, [daysSelected, activity]);

  useEffect(() => {
    setDaysSelected(14);
  }, []);

  return (
    <>
      <GreenUnderlineHeading title="Audit Logs" />
      <div className="w-full float-right shadow-lg mb-10">
        {" "}
        <select
          value={daysSelected}
          className="w-full bg-white border border-gray-200"
          onChange={(e) => setDaysSelected(e.target.value)}
        >
          <option value={3}>Last 3 Days</option>
          <option value={7}>Last 7 Days</option>
          <option value={14}>Last 14 Days</option>
          <option value={30}>Last 30 Days</option>
        </select>
      </div>
      <table className="w-10/12">
        {activityMetadata &&
          activityMetadata.map((row: any, index: string) => {
            return <ActivityLogItem entry={row} key={index} />;
          })}
      </table>
    </>
  );
};

export default ActivityLogs;
