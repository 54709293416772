import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import { trim } from "lodash";
import {
  useMeeting,
  createMeetingEvent,
  scheduleMeeting,
  rescheduleMeetingRequest,
} from "../../../data/meetings";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import SuccessCircleCheck from "../../custom-ui/success/SuccessCircleCheck";

function SeekerScheduleInterviewPage() {
  const { meetingId = "", manageMeeting } = useParams();
  const { data: interviewData, status: interviewQueryStatus } =
    useMeeting(meetingId);
  const [isLoading, setIsLoading] = useState(false);

  const {
    calendarLink,
    inAppScheduleOptions = [],
    inAppScheduleDuration,
    status,
    meetingDetails = {},
    invitee,
    extended,
  } = interviewData ?? {};

  const { reschedule_url } = extended?.calendly?.invitee ?? {};

  console.log(calendarLink);

  const [dateTimeSelection, setDateTimeSelection] = useState();
  const [showReschedule, setShowReschedule] = useState(false);

  const [cancelOrRescheduleReason, setCancelOrRescheduleReason] = useState();

  const toggleShowReschedule = () =>
    setShowReschedule((prevState: boolean) => !prevState);

  console.log(meetingDetails);
  console.log(dateTimeSelection);

  useCalendlyEventListener({
    onProfilePageViewed: (e: any) =>
      console.log("onProfilePageViewed", e.data.payload),
    onDateAndTimeSelected: (e: any) =>
      console.log("onDateAndTimeSelected", e.data.payload),
    onEventTypeViewed: (e: any) =>
      console.log("onEventTypeViewed", e.data.payload),
    onEventScheduled: (e: any) => {
      const payload = e.data.payload;
      const eventDetails = {
        meetingId,
        eventUri: payload.event.uri,
        eventInviteeUri: payload.invitee.uri,
      };
      // Calendly creates a meeting event on the meeting document
      // update cloud function takes over to set the meeting details
      createMeetingEvent(eventDetails)
        .then(() => {
          console.log("created interview event");
        })
        .catch((error) => {
          console.log(error);
        });
      console.log(e.data.payload);
    },
  });

  const handleChange = (index: number) => {
    // had to pass index to get the value of selection
    // problems getting value from input
    console.log("Guess: ", inAppScheduleOptions[index].toDate());
    setDateTimeSelection({
      startTime: inAppScheduleOptions[index],
      endTime: inAppScheduleOptions[index],
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    // Calculate Meeting End time for inAppSchedule
    // convert Timestamp object representation to a new Timestamp
    const duration = inAppScheduleDuration || 1800000;
    const startDateTimestamp = new Timestamp(
      dateTimeSelection.startTime.seconds,
      dateTimeSelection.startTime.nanoseconds,
    );
    // convert to date
    const endTime = startDateTimestamp.toDate();
    // add meeting duration to start time to get end time
    endTime.setMilliseconds(endTime.getMilliseconds() + duration);

    scheduleMeeting({
      meetingId,
      startTime: dateTimeSelection.startTime,
      endTime,
    })
      .then(() => {})
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  };

  const rescheduleRequest = () => {
    rescheduleMeetingRequest({
      meetingId,
      status: "reschedule",
      rescheduleReason: trim(cancelOrRescheduleReason, "\n"),
    })
      .then(() => {
        console.log("Reschedule request sent");
      })
      .catch((error) => {
        console.log("Problem rescheduliing meeting: ", error);
      });
  };

  switch (status) {
    case "scheduled":
      return (
        <div className="h-screen flex items-center justify-center mt-32">
          {meetingDetails && (
            <>
              {!showReschedule ? (
                // Reschedule options not shown
                <div className="w-80 bg-white shadow-lg p-6 rounded-xl">
                  <div>
                    <p className="mb-4 text-xl text-instant-teams-teal-D1 font-bold text-center">
                      {meetingDetails.title}
                    </p>
                    <SuccessCircleCheck
                      message={`You're all set for ${meetingDetails?.startTime
                        .toDate()
                        .toLocaleString("en-US", {
                          month: "short",
                          weekday: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                          timeZoneName: "short",
                        })}. You can find a listing of your meetings with links to join in the Instant Teams mobile app.`}
                    />
                    <div className="w-full flex justify-center">
                      {manageMeeting ? (
                        <button
                          className="w-72 mt-4 mb-2 min-h-12 h-12 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                          onClick={toggleShowReschedule}
                        >
                          Reschedule Meeting
                        </button>
                      ) : (
                        <button
                          className="w-72 mt-4 mb-2 min-h-12 h-12 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                          onClick={() => window.close()}
                        >
                          Close Window
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                // reschedule options
                <div className="flex flex-col md:flex-row overflow-hidden  items-center justify-center min-h-screen bg-white mt-32">
                  <div className="h-screen sm:w-full md:w-1/2 bg-white z-10">
                    {reschedule_url ? (
                      <div className="items-center justify-center">
                        {/* @ts-ignore */}
                        <InlineWidget
                          styles={{ height: "100vh" }}
                          url={reschedule_url}
                        />
                      </div>
                    ) : (
                      <div className="h-screen flex items-center  bg-white z-10">
                        <div className="h-96  w-full flex items-center justify-center">
                          <div className="w-80 p-4 rounded-lg shadow-lg">
                            <h2 className="mb-4 text-center text-instant-teams-teal-D2 font-bold">
                              Are you sure that you want to request to{" "}
                              <span className="text-instant-teams-red-Main">
                                reschedule
                              </span>{" "}
                              the following meeting?
                            </h2>
                            <p className="mb-4 text-xl text-instant-teams-teal-D1 font-bold text-center">
                              {meetingDetails.title}
                            </p>
                            <p className="text-instant-teams-gray-D1 mb-10 text-center">
                              {`Scheduled for ${meetingDetails?.startTime
                                .toDate()
                                .toLocaleString("en-US", {
                                  month: "short",
                                  weekday: "long",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                  timeZoneName: "short",
                                })}.`}
                            </p>
                            <label
                              className="sr-only"
                              htmlFor="rescheduleReason"
                            >
                              Reschedule Reason
                            </label>
                            <textarea
                              className="w-full"
                              placeholder="Reschedule Reason"
                              onChange={(e) =>
                                setCancelOrRescheduleReason(e.target.value)
                              }
                              name="rescheduleReason"
                            ></textarea>
                            <button
                              className="w-72 mt-4 mb-2 min-h-12 h-12 text-white bg-instant-teams-red-Main rounded border-0 hover:bg-instant-teams-red-L1 focus:outline-none"
                              onClick={rescheduleRequest}
                            >
                              Yes Reschedule
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mt-32 md:w-1/2 items-center justify-enter">
                    <button
                      className="w-72 mt-4 mb-2 min-h-12 h-12 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                      onClick={toggleShowReschedule}
                    >
                      Go Back
                    </button>
                    <img
                      className="hidden md:block bg-white h-screen scale-125 object-contain md:object-scale-down "
                      src="/images/schedule-hero.png"
                      alt="Welcome to Instant Teams"
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      );
    case "sent":
      return (
        <>
          {interviewQueryStatus === "success" && (
            <div className="flex flex-col md:flex-row overflow-hidden  items-center justify-center min-h-screen bg-white mt-32">
              <div className="h-screen sm:w-full md:w-1/2 bg-white z-10">
                {/* If there is a calendarLink then the meeting was scheduled with Calendly
                    Otherwise it was scheduled inApp using datepicker */}
                {calendarLink ? (
                  <>
                    {/* @ts-ignore */}
                    <InlineWidget
                      styles={{ height: "100vh" }}
                      url={calendarLink}
                    />
                  </>
                ) : (
                  // Renders if meeting was scheduled inApp using datepicker
                  <div className="h-full flex flex-col flex-wrap items-center justify-center">
                    <>
                      <h1 className="text-center p-4 mb-10 text-instant-teams-teal-D1 text-xl font-bold">
                        Please choose a date and time to schedule your live
                        interview.
                      </h1>
                      <p className="mb-4 text-xl text-instant-teams-teal-D1 font-bold text-center">
                        {meetingDetails.title}
                      </p>
                      <fieldset>
                        <legend className="sr-only">
                          Choose a date for your interview
                        </legend>
                        {inAppScheduleOptions.map(
                          (timestamp: any, index: number) => {
                            const dateTime = timestamp
                              .toDate()
                              .toLocaleString("en-US", {
                                month: "short",
                                weekday: "long",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                                timeZoneName: "short",
                              });
                            return (
                              <div
                                key={index}
                                className="p-8 px-8 m-2 rounded-xl"
                              >
                                <input
                                  onChange={() => handleChange(index)}
                                  className={`sr-only shadow-lg peer`}
                                  id={`${index}`}
                                  type="radio"
                                  value={timestamp}
                                  name="scheduleOption"
                                />
                                <label
                                  htmlFor={`${index}`}
                                  className={`py-9 px-12 whitespace-nowrap shadow-lg border-gray-300 rounded-xl peer-checked:ring-instant-teams-green-Main peer-hover:ring-instant-teams-blue-Main peer-focus:ring-instant-teams-green-Main peer-checked:ring-2 peer-hover:ring-2 peer-focus:ring-2 peer-checked:border-transparent cursor-pointer text-instant-teams-teal-D1 font-semibold`}
                                >
                                  {dateTime}
                                </label>
                              </div>
                            );
                          },
                        )}
                      </fieldset>
                      {isLoading ? (
                        <i className="fa-duotone fa-spinner-third fa-spin"></i>
                      ) : (
                        <button
                          className="w-80 mt-4 mb-2 min-h-12 h-12 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                          onClick={handleSubmit}
                        >
                          Schedule Interview
                        </button>
                      )}
                    </>
                  </div>
                )}
              </div>
              <div className="mt-32 md:w-1/2 items-center justify-enter">
                <img
                  className="hidden md:block bg-white h-screen scale-125 object-contain md:object-scale-down "
                  src="/images/schedule-hero.png"
                  alt="Welcome to Instant Teams"
                />
              </div>
            </div>
          )}
        </>
      );
    case "reschedule":
      return (
        <>
          <div className="flex flex-col md:flex-row overflow-hidden  items-center justify-center min-h-screen bg-white">
            <div className="h-screen sm:w-full md:w-1/2 bg-white z-10">
              <div className="h-full flex flex-col flex-wrap items-center justify-center">
                <div className="p-4 h-96 w-80 rounded-lg shadow-lg">
                  <SuccessCircleCheck
                    message={`A request to reschedule the following meeting has been has been sent:`}
                  />
                  <p className="font-bold text-instant-teams-gray-D1 mb-10 text-center">
                    {meetingDetails?.title}
                  </p>
                  <p className="text-instant-teams-gray-D1 mb-10 text-center">
                    You will be notified when new meeting times are available.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    default:
      return <></>;
  }
}

export default SeekerScheduleInterviewPage;
