import { requireAuth, useAuth } from "../../providers/auth";
import Meta from "../../components/custom-ui/meta/Meta";
import { useEnrollmentsByEmail } from "../../data/enrollments";
import GreenUnderlineHeading from "../../components/custom-ui/headings/heading";
import SeekerAcademyItem from "../../components/seekerView/AcademyItem";
import WedgeSpinner from "../../components/spinners/wedges-spinner";
function SeekerAcademyPage() {
  const { user } = useAuth();

  const {
    data: enrollments = [],
    error: enrollmentsErrors,
    status: enrollmentStatus,
    isLoading,
  } = useEnrollmentsByEmail(user?.email);

  const completedEnrollments = enrollments.filter(
    (item: any) => item.status === "completed",
  );

  const invitedEnrollments = enrollments.filter(
    (item: any) => item.status === "invited",
  );

  if (isLoading) {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <WedgeSpinner />
      </div>
    );
  }
  return (
    <>
      <Meta title="Academy" />
      <section className="pt-40 pb-16 flex flex-col place-items-center space-y-10">
        <div className="w-3/4 md:w-full lg:w-4/5 space-y-3">
          <GreenUnderlineHeading title="In Progress" />
          {invitedEnrollments && invitedEnrollments?.length > 0
            ? invitedEnrollments?.map((enrollment: any) => {
                return (
                  <SeekerAcademyItem
                    enrollment={enrollment}
                    key={enrollment?.id}
                  />
                );
              })
            : null}
        </div>
        <div className="w-3/4 md:w-full lg:w-4/5 space-y-3">
          <GreenUnderlineHeading title="Completed" />
          {completedEnrollments && completedEnrollments?.length > 0
            ? completedEnrollments?.map((enrollment: any) => {
                return (
                  <SeekerAcademyItem
                    enrollment={enrollment}
                    key={enrollment?.id}
                    completedAssessment={true}
                  />
                );
              })
            : null}
        </div>
      </section>
    </>
  );
}
export default requireAuth(SeekerAcademyPage);
