import { useState, useEffect } from "react";
import parsePhoneNumber from "libphonenumber-js";

export default function PhoneNumber({ number }: { number: string }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const formattedNumber = parsePhoneNumber(number);

  useEffect(() => {
    if (number) {
      const display = `${
        formattedNumber?.country ?? "N/A"
      }: ${formattedNumber?.formatNational()}`;
      setPhoneNumber(display);
    } else {
      setPhoneNumber("No Number Listed");
    }
  }, [formattedNumber, number]);

  return (
    <div id="phoneNumber" className="center-items w-full mx-auto">
      <b>{phoneNumber}</b>
    </div>
  );
}
