function ApplicationCardWrapper({
  children,
  title,
}: {
  children: JSX.Element;
  title: string;
}) {
  return (
    <div className="shadow-input-field w-full rounded-lg my-5">
      <div className="h-12 text-white uppercase flex justify-center items-center bg-instant-teams-teal-Main rounded-t-lg">
        {title}
      </div>
      <div className="p-4">{children}</div>
    </div>
  );
}

export default ApplicationCardWrapper;
