import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLookup } from "../../../data/lookup";
import useModal from "../../../hooks/useModal";
import { useJobDescription } from "../../../data/jobDescriptions";
import { collectionNames } from "../../../data/dictionary/collectionNames";
import AutoSaveCheckboxGroup from "../../custom-ui/input-fields/auto-save-checkbox-group";
import AutoSaveSelectGeneric from "../../custom-ui/input-fields/auto-save-select-generic";
import AutoSaveDateRange from "../../custom-ui/input-fields/auto-save-date-range";
import AutoSavePayTypeTextArea from "../../custom-ui/input-fields/auto-save-pay-type-textarea";
import AutoSaveWysiwyg from "../../custom-ui/input-fields/auto-save-wysiwyg";
import AutoSaveToggle from "../../custom-ui/input-fields/auto-save-toggle";
import AutoSaveSlider from "../../custom-ui/input-fields/auto-save-slider";
import AutoSaveCheckboxIconsGroup from "../../custom-ui/input-fields/auto-save-checkbox-icons-group";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import { Timestamp } from "firebase/firestore";
import StateListing from "../../custom-ui/input-fields/stateSelector";
import AutoSaveNumber from "../../custom-ui/input-fields/auto-save-number";
import TagListing from "../../tags/tagList";
import { isNil } from "lodash";
import { updateAutoSave } from "../../../data/utility";
import { deleteField } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import LockedFieldIcon from "../../custom-ui/icons/LockedFieldIcon";
import { isLocked } from "../../../util/jobs/jobUtils";
import Tooltip from "../../custom-ui/tooltip/tooltip";

function JobInformation() {
  const params = useParams();
  const jobId = params.id ?? "";
  const { data: job, status: jobStatus } = useJobDescription(jobId);
  const jobInfo: any = job?.information;
  const { data: model = [] } = useLookup("model");
  const { data: term = [] } = useLookup("term");
  const { data: classification = [] } = useLookup("classification");
  const { data: payType = [] } = useLookup("payType");
  const [textAreaEditorState] = useState({});
  const { isShowing: isShowingTextArea, toggle: toggleTextArea } = useModal();
  const { data: hardware = [] } = useLookup("hardware");
  const { data: solutionTypes = [] } = useLookup("solutionType");
  const { data: network = [] } = useLookup("network");

  const isFieldLocked = isLocked(job?.status);
  const SECTION_LOCKED_MESSAGE = "This section cannot be changed while the job status is live.";
// Has Seasonal Schedule
  const hasSeasonal = (term: any) => {
    if (term.includes("SEA")) {
      return true;
    }
    return false;
  };

  // if workterm is no longer seasonal then remove seasonalDateRange
  useEffect(() => {
    if (jobStatus === "success" && !hasSeasonal(jobInfo?.term)) {
      updateAutoSave(collectionNames.jobDescriptions, jobId, {
        "information.seasonalDateRange": deleteField(),
      }).catch((error) => console.log(error));
    }
  }, [jobStatus, jobId, jobInfo?.term]);

  return (
    <div className="w-full min-h-screen">
      <div className="grid gap-1 grid-cols-3 md:grid-cols-3 mb-4 mt-4">
        <div className="mb-2">
          <div className="flex items-center justify-between">
            <div className="py-1  block text-left font-semibold text-instant-teams-blue-Main">
              Number of Seats
            </div>
          </div>
          <AutoSaveNumber
            value={!isNil(job?.seats) ? Number(job.seats) : 1}
            collection={collectionNames.jobDescriptions}
            recId={jobId}
            required={false}
            name={"seats"}
            display={"Number of Seats"}
            placeholder={"Enter the number of seats."}
            directions={""}
            readOnly={false}
            minValue={1}
            maxValue={300}
          />
          <Tooltip text={SECTION_LOCKED_MESSAGE}>
            <div className="flex items-center justify-between">
              <div className="py-1  block text-left font-semibold text-instant-teams-blue-Main">
                Solution Type
              </div>
              <LockedFieldIcon isLocked={isFieldLocked} />
            </div>
          </Tooltip>
          <AutoSaveSelectGeneric
            value={job?.solutionType??solutionTypes[0]?.code}
            options={solutionTypes}
            collection={collectionNames.jobDescriptions}
            recId={jobId}
            required={false}
            name="solutionType"
            display="Solution Type"
            placeholder="Select a solution"
            directions=""
            disabled={isFieldLocked}
          />
        </div>
        <div className="mb-2 col-span-2 pl-10">
          <TagListing
            currentTags={job?.tags || []}
            collection={"JobDescriptions"}
            recId={jobId}
            name={"tags"}
          />
        </div>
      </div>
      <div className="grid gap-1 grid-cols-3 md:grid-cols-3 mt-12">
        <div className="mb-2">
          <GreenUnderlineHeading
            title="Work Settings"
            showLockIcon={true}
            isLocked={isFieldLocked}
            text={SECTION_LOCKED_MESSAGE}
          />
          <AutoSaveCheckboxGroup
            name="information.model"
            options={model}
            initialValues={jobInfo?.model}
            collection={collectionNames.jobDescriptions}
            id={jobId}
            disabled={isFieldLocked}
          />

          <GreenUnderlineHeading
            title="Work Type"
            showLockIcon={true}
            isLocked={isFieldLocked}
            text={SECTION_LOCKED_MESSAGE}
          />

          <AutoSaveCheckboxGroup
            name="information.classification"
            options={classification}
            initialValues={jobInfo?.classification}
            collection={collectionNames.jobDescriptions}
            id={jobId}
            disabled={isFieldLocked}
          />

          <GreenUnderlineHeading
            title="Work Term"
            showLockIcon={true}
            isLocked={isFieldLocked}
            text={SECTION_LOCKED_MESSAGE}
          />

          <div className="mb-2">
            <AutoSaveCheckboxGroup
              name="information.term"
              options={term}
              initialValues={jobInfo?.term}
              collection={collectionNames.jobDescriptions}
              id={jobId}
              disabled={isFieldLocked}
            />
          </div>
          {hasSeasonal(jobInfo?.term) && (
            <div className="mt-4 pr-12">
              <AutoSaveDateRange
                initialStart={
                  jobInfo?.seasonalDateRange?.startDate instanceof
                  Timestamp
                    ? jobInfo?.seasonalDateRange?.startDate.toDate()
                    : null
                }
                initialEnd={
                  jobInfo?.seasonalDateRange?.endDate instanceof
                  Timestamp
                    ? jobInfo?.seasonalDateRange?.endDate.toDate()
                    : null
                }
                name="information.seasonalDateRange"
                collection={collectionNames.jobDescriptions}
                id={jobId}
                disabled={isFieldLocked}
              />
            </div>
          )}

          <GreenUnderlineHeading
            title="Phone Support"
            showLockIcon={true}
            isLocked={isFieldLocked}
            text={SECTION_LOCKED_MESSAGE}
          />

          <div className="center-items mb-4 w-full">
            {/* AutoSave Toggle tries to save immediately on mount, then subsequent changes by user would like to save only on user action */}
            <AutoSaveToggle
              value={jobInfo?.phoneSupport ?? false}
              collection={collectionNames.jobDescriptions}
              id={jobId}
              name={"information.phoneSupport"}
              on={"Required"}
              off={"Not Required"}
              disabled={isFieldLocked}
            />
          </div>
        </div>
        <div className="mb-24 pl-12">
          <GreenUnderlineHeading
            title="Require Resume"
            showLockIcon={true}
            isLocked={isFieldLocked}
            text={SECTION_LOCKED_MESSAGE}
          />
          <div className="w-full flex mb-4">
            <AutoSaveToggle
              value={job?.requireResume || false}
              collection={collectionNames.jobDescriptions}
              id={jobId}
              name={"requireResume"}
              on={"Requires Resume"}
              off={"No Resume Required"}
              disabled={isFieldLocked}
            />
          </div>
          <GreenUnderlineHeading
            title="Hours Per Week"
            showLockIcon={true}
            isLocked={isFieldLocked}
            text={SECTION_LOCKED_MESSAGE}
          />

          <div className="mt-12 pl-2">
            <AutoSaveSlider
              initialValues={jobInfo?.hoursPerWeek ?? []}
              collection={collectionNames.jobDescriptions}
              field={"information.hoursPerWeek"}
              id={jobId}
              min={10}
              max={60}
              step={1}
              numberOfHandles={5}
              disabled={isFieldLocked}
            />
          </div>

          <div className="mt-20">
            <GreenUnderlineHeading
              title="Pay Rate"
              showLockIcon={true}
              isLocked={isFieldLocked}
              text={SECTION_LOCKED_MESSAGE}
            />
          </div>

          <div className="mt-4 pl-2">
            <AutoSavePayTypeTextArea
              options={payType}
              collection={collectionNames.jobDescriptions}
              recId={jobId}
              payTypeInitialValue={jobInfo?.payType ?? {}}
              name="information.payType"
              disabled={isFieldLocked}
            />
          </div>
        </div>
        <div className="pl-12 mb-24">
          <div className="md:w-full w-96">
            {/* Col 2 */}
            {!job?.states?.allowed || job.states?.allowed.length === 0 ? (
              <div className="mb-2">
                <StateListing
                  name="states.disallowed"
                  title="Disallowed States"
                  currentStates={job?.states?.disallowed || []}
                  collection={collectionNames.jobDescriptions}
                  recId={jobId}
                  disabled={isFieldLocked}
                  text={SECTION_LOCKED_MESSAGE}
                />
              </div>
            ) : null}
            {!job?.states?.disallowed || job.states?.disallowed.length === 0 ? (
              <div className="mb-2">
                <StateListing
                  name="states.allowed"
                  title="Allowed States"
                  currentStates={job?.states?.allowed || []}
                  collection={collectionNames.jobDescriptions}
                  recId={jobId}
                  disabled={isFieldLocked}
                  text={SECTION_LOCKED_MESSAGE}
                />
              </div>
            ) : null}
            <GreenUnderlineHeading
              title="Exclude EU Residents"
              showLockIcon={true}
              isLocked={isFieldLocked}
              text={SECTION_LOCKED_MESSAGE}
            />
            <AutoSaveToggle
              value={jobInfo?.gdprCompliance ?? false}
              collection={collectionNames.jobDescriptions}
              id={jobId}
              name={"information.gdprCompliance"}
              on={"Excluded"}
              off={"Not Excluded"}
              disabled={isFieldLocked}
            />
          </div>
        </div>
      </div>
      <div className="grid gap-1 grid-cols-3 md:grid-cols-3">
        <div className="mb-2 ">
          <GreenUnderlineHeading
            title="Hardware"
            showLockIcon={true}
            isLocked={isFieldLocked}
            text={SECTION_LOCKED_MESSAGE}
          />
          <AutoSaveCheckboxIconsGroup
            name="technology.hardware"
            options={hardware}
            initialValues={job?.technology?.hardware}
            collection={collectionNames.jobDescriptions}
            id={jobId}
            disabled={isFieldLocked}
          />
        </div>
        <div className="mb-2 pl-12">
          <GreenUnderlineHeading
            title="Network"
            showLockIcon={true}
            isLocked={isFieldLocked}
            text={SECTION_LOCKED_MESSAGE}
          />

          <AutoSaveCheckboxIconsGroup
            name="technology.network"
            options={network}
            initialValues={job?.technology?.network}
            collection={collectionNames.jobDescriptions}
            id={jobId}
            disabled={isFieldLocked}
          />
        </div>
        <div className="mb-2.5 pl-12">
          <GreenUnderlineHeading
            title="Speed Test"
            showLockIcon={true}
            isLocked={isFieldLocked}
            text={SECTION_LOCKED_MESSAGE}
          />
          <div className="w-full flex mb-4">
            <AutoSaveToggle
              value={job?.speedTest || false}
              collection={collectionNames.jobDescriptions}
              id={jobId}
              name={"speedTest"}
              on={"Requires Speed Test"}
              off={"No Speed Test Required"}
              disabled={isFieldLocked}
            />
          </div>
          {job && job?.speedTest && (
            <>
              <div className="w-full flex">
                <div className="w-1/8 inline-block">
                  <FontAwesomeIcon
                    className="mr-2 pt-2 align-baseline text-instant-teams-teal-Main"
                    icon={"download"}
                    size={"2x"}
                  />
                </div>
                <div className="w-7/8 inline-block">
                  <AutoSaveNumber
                    value={
                      !isNil(job?.technology?.networkSpeed?.download)
                        ? Number(job?.technology?.networkSpeed?.download)
                        : ""
                    }
                    collection={collectionNames.jobDescriptions}
                    recId={jobId}
                    required={false}
                    name={"technology.networkSpeed.download"}
                    display={"Download Speed Mbps"}
                    placeholder={"Enter download Speed"}
                    directions={""}
                    readOnly={isFieldLocked}
                    minValue={0}
                    maxValue={300}
                  />
                </div>
              </div>
              <div className="w-full flex">
                <div className="w-1/8 inline-block">
                  <FontAwesomeIcon
                    className="mr-2 pt-2 align-baseline text-instant-teams-teal-Main"
                    icon={"upload"}
                    size={"2x"}
                  />
                </div>
                <div className="w-7/8 inline-block">
                  <AutoSaveNumber
                    value={
                      !isNil(job?.technology?.networkSpeed?.upload)
                        ? Number(job?.technology?.networkSpeed?.upload)
                        : ""
                    }
                    collection={collectionNames.jobDescriptions}
                    recId={jobId}
                    required={false}
                    name={"technology.networkSpeed.upload"}
                    display={"Upload Speed Mbps"}
                    placeholder={"Enter upload Speed"}
                    directions={""}
                    readOnly={isFieldLocked}
                    minValue={0}
                    maxValue={300}
                  />
                </div>
              </div>
              <div className="w-full flex">
                <div className="w-1/8 inline-block">
                  <FontAwesomeIcon
                    className="mr-2 pt-2 align-baseline text-instant-teams-teal-Main"
                    icon={"gauge"}
                    size={"2x"}
                  />
                </div>
                <div className="w-7/8 inline-block">
                  <AutoSaveNumber
                    value={
                      !isNil(job?.technology?.networkSpeed?.ping)
                        ? Number(job?.technology?.networkSpeed?.ping)
                        : ""
                    }
                    collection={collectionNames.jobDescriptions}
                    recId={jobId}
                    required={false}
                    name={"technology.networkSpeed.ping"}
                    display={"Ping Speed Mbps"}
                    placeholder={"Enter minimum ping"}
                    directions={""}
                    readOnly={isFieldLocked}
                    minValue={0}
                    maxValue={300}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div>
        {/* Document Editor Modal */}
        {/* Training */}
        {isShowingTextArea && (
          <DialogWrapper
            onClose={() => {
              toggleTextArea();
            }}
            title="Editor"
          >
            <>
              <AutoSaveWysiwyg
                editorStyle={{ height: "65vh" }}
                {...textAreaEditorState}
              />
            </>
          </DialogWrapper>
        )}
      </div>
    </div>
  );
}

export default JobInformation;
