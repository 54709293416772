export const optionsByType = {
  signin: {
    title: "Login",
    buttonAction: "Login",
    showFooter: false,
    forgotPassAction: "Forgot Password?",
    forgotPassPath: "/auth/forgotpass",
  },
  internalsignin: {
    title: "Welcome back!",
    buttonAction: "Sign in",
    showfooter: false,
  },
  forgotpass: {
    title: "Request password reset link",
    buttonAction: "Submit",
    showFooter: true,
    signinAction: "Sign in",
    signinPath: "/auth/signin",
  },
  changepass: {
    title: "Choose a new password.",
    buttonAction: "Change password",
  },
};
