import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useComponent } from "../../../data/academy";
import { camel } from "../../../util/strings/stringManipulation";
import CustomTestQuestions from "./customTestQuestions";

function CustomTestDetails(props) {
  const { details } = props;
  const params = useParams();
  const [id, setId] = useState(params?.assessmentId);
  const { data: component } = useComponent(id);

  return (
    <div className="w-full">
      {component &&
        component?.custom_tests_detail?.map((item, index) => {
          return (
            <div
              className={index > 0 ? "w-full border-t border-black" : "w-full"}
              key={index}
            >
              <div className="w-full flex">
                <div className="w-full text-bold text-instant-teams-teal-Main">
                  {camel(item.status)} {camel(item.name)}{" "}
                  {item.is_premium ? "Premium" : ""}{" "}
                  {item.is_private_test ? "Premium" : ""}
                </div>
              </div>
              <div className="w-full">
                <p className="w-full font-bold">Questions: </p>
                <CustomTestQuestions details={item?.custom_questions} />
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default CustomTestDetails;
