import { useEffect, useState } from "react";
import useModal from "../../../hooks/useModal";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import SkillDetails from "./skillDetails";
import { useParams } from "react-router-dom";
import {
  SearchBox,
  Hits,
  Configure,
  HitsPerPage,
  Pagination,
  RefinementList,
  CurrentRefinements,
  ClearRefinements,
  RefinementListProps,
} from "react-instantsearch-hooks-web";
import SkillsCard from "./SkillsCard";
import { getLabel } from "../../../util/search/getLabel";

const SkillsCatalog = ({ excludedIds }: { excludedIds: Array<string> }) => {
  const params = useParams();
  const [jobId, setJobId] = useState(params.id);
  const [assessmentId, setAssessmentId] = useState("");
  const { toggle: assessmentDetailsToggle, isShowing: assessmentDetails } =
    useModal();

  useEffect(() => {
    setJobId(params.id);
  }, [params]);

  useEffect(() => {
    if (assessmentId !== "") {
      assessmentDetailsToggle();
    }
  }, [assessmentId]);

  const transformSkillLevel: RefinementListProps["transformItems"] = (
    items,
  ) => {
    items = items.map((item) => ({
      ...item,
      label: getLabel(item.label, "skillLevel"),
    }));
    return items;
  };

  return (
    <div className="flex">
      <div className="w-1/5 pr-4">
        <div className=" mt-10">
          <SearchBox
            placeholder="Keyword Search"
            classNames={{
              input: "w-full",
              submitIcon: "hidden",
              resetIcon: "hidden",
              submit: "hidden",
            }}
          />
          <CurrentRefinements />
          Filter by Tags
          <RefinementList
            attribute="tags"
            operator="and"
            classNames={{
              root: "capitalize",
              showMore: " text-instant-teams-blue-Main text-xs",
            }}
            limit={5}
            showMore={true}
          />
          Filter by Level
          <RefinementList
            attribute="level"
            operator="and"
            classNames={{
              root: "capitalize",
              showMore: " text-instant-teams-blue-Main text-xs",
            }}
            limit={5}
            showMore={true}
            transformItems={transformSkillLevel}
          />
          <ClearRefinements
            translations={{ resetButtonText: "Clear Filters" }}
            classNames={{
              button:
                " bg-instant-teams-teal-Main text-white p-2 rounded mt-5 text-center w-full",
            }}
          />
        </div>
      </div>
      <div className=" w-4/5">
        <div className="flex justify-end w-[100%]">
          <div className="flex items-center">
            <div className=" pr-4">Results per page:</div>
            <HitsPerPage
              items={[
                { label: "10", value: 10 },
                { label: "25", value: 25 },
                { label: "50", value: 50, default: true },
                { label: "100", value: 100 },
              ]}
              classNames={{ select: "text-xs h-6 py-0" }}
            />
          </div>
        </div>
        {/* @ts-ignore */}
        <Configure filters={"visable:active"} />
        <Hits
          hitComponent={({ hit }: { hit: any }) => (
            <SkillsCard
              row={hit}
              assessmentDetails={assessmentDetails}
              setAssessmentId={setAssessmentId}
            />
          )}
          classNames={{ list: "grid grid-cols-4 gap-1 mt-4" }}
          transformItems={(items) =>
            items.filter((item) => !excludedIds.includes(item.objectID))
          }
        />
        <div className="mt-12">
          <Pagination
            showNext={true}
            padding={1}
            classNames={{
              list: "flex",
              item: "h-8 w-8 text-center rounded-m rounded mx-1 bg-white",
              selectedItem: "scale-125 border-black",
            }}
          />
        </div>
        {assessmentDetails && (
          <DialogWrapper
            title="Assessment Details"
            onClose={() => {
              assessmentDetailsToggle();
            }}
            size={"w-1/2  mt-36"}
          >
            <SkillDetails
              assessmentId={assessmentId}
              jobId={jobId}
              onDone={assessmentDetailsToggle}
            />
          </DialogWrapper>
        )}
      </div>
    </div>
  );
};

export default SkillsCatalog;
