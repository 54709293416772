import { useQuery } from "react-query";
import {
  doc,
  updateDoc,
  DocumentReference,
  query,
  collection,
} from "firebase/firestore";
import { db, client, createQuery, format } from "../providers/database";

import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";
import { InterviewQuestion } from "../dataTypes/InterviewQuestion";

const getInterviewQuestionsDocRef = (questionId: string) =>
  doc(db, collectionNames.interviewQuestions, questionId);

export function useInterviewQuestion(questionId: string) {
  return useQuery(
    ["interviewQuestion", { questionId }],
    createQuery(() => getInterviewQuestionsDocRef(questionId)),
    { enabled: !!questionId },
  );
}

export function updateInterviewQuestionSort(
  sortOrder: number,
  parentId: string,
  parentCollection: string,
  childCollection: string,
  childId: string,
) {
  const docRef = doc(db, parentCollection, parentId, childCollection, childId);
  return updateDoc(docRef, { sortOrder: sortOrder });
}

export function useInterviewQuestionsByApplication(id: string) {
  return useQuery(
    ["ApplicationInterviewQuestions", { id }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.applications}/${id}/${subCollectionNames.interviewQuestions}`,
        ),
      );
    }),
    { enabled: !!id },
  );
}
