import React from 'react';

const JobsPagination = (props) => {
    const {
        currentPage,
        handleResultsPerPageChange,
        paginate,
        jobsPerPage,
        totalPages

    } = props
    return (
        <div>
            <div className="mb-1 mt-4 flex justify-between">
                <div className='mt-[-10px] flex items-center'>
                    <label htmlFor="resultsPerPage" className="mr-1 text-gray-600 ">Results per Page:</label>
                    <select
                        id="resultsPerPage"
                        
                        value={jobsPerPage}
                        onChange={handleResultsPerPageChange}
                        className=" text-sm  bg-gray-100 text-gray-600 focus:outline-none focus:ring-0 w-14  shadow-none"
                    >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                    </select>
                </div>
                <nav>
                    <ul className="inline-flex items-center">
                        {/* Previous Button */}
                        <li>
                            <button
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                                className={` text-gray-600   ${currentPage === 1 ? ' cursor-not-allowed' : 'text-gray-600'}`}
                            >
                                &lt;
                            </button>
                        </li>
                        {/* Page Numbers */}
                        {Array.from({ length: totalPages }, (_, index) => (
                            <li key={index}>
                                <button
                                    onClick={() => paginate(index + 1)}
                                    className={` text-text-black px-2  ${currentPage === index + 1 ?  ' text-black': ' text-gray-400 '}`}
                                >
                                    {index + 1}
                                </button>
                            </li>
                        ))}
                        {/* Next Button */}
                        <li>
                            <button
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className={` text-gray-600  rounded-r-md ${currentPage === totalPages ? '  cursor-not-allowed' : 'text-gray-600'}`}
                            >
                                &gt;
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default JobsPagination;
