import { Link, useNavigate, useParams } from "react-router-dom";
import { useApplication } from "../../data/applications";
import { useJobDescription } from "../../data/jobDescriptions";
import {
  useJobPositionDetails,
  useActiveJobPositions,
} from "../../data/positions";
import { useEmployer } from "../../data/employer";
import { toUSCurrency } from "../../util/helpers/toUSCurrency";
import { useMemo, useState,useEffect } from "react";
import { getLookupLabel } from "../../util/search/getLookupLabel";
import { useLookup } from "../../data/lookup";
import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";
import { requireAuth } from "../../providers/auth";
import { addApplicationPosition } from "../../data/applications";
import Meta from "../../components/custom-ui/meta/Meta";
import { useEmployerData } from "../../hooks/useApplicationEmployerData";
import { add } from "lodash";
function OtherPositions() {
  const { applicationId = "" } = useParams();
  console.log(applicationId)
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [additionalPositionData, setAdditionalPositionData] = useState([]);

  const { data: applicationData, isLoading: isApplicationDataLoading } =
    useApplication(applicationId);
  const { data: jobData, isLoading: isJobDataLoading } = useJobDescription(
    applicationData?.jobId,
  );
  const { data: positionData, isLoading: isPositionDataLoading } =
    useJobPositionDetails(applicationData?.jobId, applicationData?.positionId);
  const { employerId, employerData } = useEmployerData({
    jobData,
    originalEmployerId: applicationData?.employer,
  });

  const { data: allPositionsData } = useActiveJobPositions(
    applicationData?.jobId,
  );


  console.log(allPositionsData,"all positionData")
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const {
    data: classification = [],
    status: classificationQueryStatus = "",
    error: classificationQueryError,
  } = useLookup("classification");
  const {
    data: model = [],
    status: modelQueryStatus = "",
    error: modelQueryError,
  } = useLookup("model");

  const getSalary = (payType: any) => {
    const pay = payType?.HRLY ?? payType?.SAL;
    if (!pay) return undefined;

    const type = payType?.HRLY ? "Hourly" : "Salary";
    return `${type}: ${toUSCurrency(pay[0])} - ${toUSCurrency(pay[1])}`;
  };

  const salary = useMemo(
    () => getSalary(positionData?.information?.payType),
    [positionData?.information?.payType],
  );

  const selectedWorkSetting = (posModel: string) =>
    getLookupLabel(posModel, model, "Not Selected");

  const selectedWorkType = (posClassification: string) =>
    getLookupLabel(posClassification, classification, "None Selected");

  const onSubmit = async () => {
    if (!applicationId) return;
    setSubmitting(true);
    try {
      await Promise.all(
        selectedPositions.map(async (posId: string) => {
          await addApplicationPosition(applicationId, posId);
        }),
      );
      setSubmitting(false);
      navigate(`/pre-screen-questions/${applicationId}`);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const selectPosition = (posId: string) => {
    const isSelected = selectedPositions.includes(posId);

    if (isSelected) {
      setSelectedPositions(
        selectedPositions.filter((id: string) => id !== posId),
      );
    } else {
      setSelectedPositions([...selectedPositions, posId]);
    }
  };


  useEffect(() => {
    const filteredData = allPositionsData &&
      allPositionsData?.filter(
        ({ id, urlActive }:any) => id !== applicationData?.positionId && !urlActive
      );

    setAdditionalPositionData(filteredData);

    if (filteredData?.length === 0) {
      onSubmit()
    }
  }, [allPositionsData, applicationData]);
  return (
    <>
      {!isPositionDataLoading &&
        !isJobDataLoading &&
        !isApplicationDataLoading && (
          <Meta title={`${jobData?.title}, ${positionData?.positionName}`} />
        )}
      <div className="w-3/4 mx-auto pt-40 pb-16 grid col-span-8">
        <Link to={`/companies/${employerData?.id}`}>
          <img
            className="my-auto col-span-1 w-24 h-24 object-contain  "
            src={employerData?.logo}
            alt={employerData?.displayName}
          />
        </Link>
        <div className="pl-5 col-span-4 my-auto  text-instant-teams-blue-Main justify-start md:text-sm">
          <Link to={`/companies/${employerData?.id}`}>
            <div className="hover:underline">{employerData?.displayName}</div>
          </Link>
          <div className="font-semibold uppercase">{jobData?.title}</div>
          <div>{positionData?.positionName}</div>
          <div className="font-semibold">
            {salary} |{" "}
            {selectedWorkType(positionData?.information?.classification)} |{" "}
            {selectedWorkSetting(positionData?.information?.model)}
          </div>
        </div>
        <div className="col-span-3 space-y-5">
          <button
            onClick={onSubmit}
            disabled={submitting}
            className="py-3 w-full uppercase bg-instant-teams-blue-Main  hover:bg-instant-teams-blue-D1 text-white rounded-lg md:py-2 md:px-8 md:text-sm lg:py-2 lg:px-16 xl:px-20"
          >
            Submit
          </button>
        </div>
        <div className="col-span-8 mt-5">
          <p>
            {" "}
            There are more positions similar to the one you chose, please take a
            look and select the ones you are interested in to apply
            additionally.
          </p>
          {additionalPositionData?.map((pos: any) => {
                const selected = !!selectedPositions.find(
                  (id: string) => id === pos.id,
                );

                return (
                  <div
                    className={`${TailwindHoverEnlarge(103)} + flex my-5`}
                    key={pos?.id}
                  >
                    <input
                      type="checkbox"
                      onChange={() => selectPosition(pos.id)}
                      className="my-auto mr-3"
                    />
                    <div className="h-32 w-full px-6 pt-2 rounded-lg shadow-input-field grid col-span-4 text-instant-teams-blue-Main">
                      <div className="col-span-4 font-semibold text-xl ">
                        {pos.positionName}
                      </div>
                      <div className="col-span-2 font-semibold">
                        Start Date:
                        <span className="pl-2 text-black font-normal">
                          {pos?.information?.startDate
                            ?.toDate()
                            ?.toLocaleDateString()
                            ?.replace(/\s/g, "")}
                        </span>
                      </div>
                      <div className="col-span-2 font-semibold">
                        Shift Option:
                        <span className="pl-2 text-black font-normal">
                          {pos.shiftSchedule.shiftOption.label}
                        </span>
                      </div>
                      <div className="col-span-2 font-semibold">
                        Work Setting:
                        <span className="pl-2 text-black font-normal">
                          {selectedWorkSetting(pos.information.model)}
                        </span>
                      </div>
                      <div className="col-span-2 font-semibold">
                        Work Term:
                        <span className="pl-2 text-black font-normal">
                          {selectedWorkType(pos.information.classification)}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </>
  );
}

export default requireAuth(OtherPositions);
