import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import { Handle, Track, TooltipRail } from "./range-slider/RangeSlider";
import { updateAutoSave } from "../../../data/utility";
/**
 * react-component-slider docs
 * https://react-compound-slider.netlify.app/docs
 */
const sliderStyle = {
  position: "relative",
  width: "100%",
};

function AutoSaveSlider({
  initialValues,
  id,
  collection,
  field,
  min,
  max,
  step,
  // defaults to range slider with 2 handles (handles represent the data points that get saved to db)
  // two handles -> [10, 20] | one handle -> [50] | 5 handles -> [5, 10, 15, 20, 25]
  numberOfHandles = 2,
  disabled = false,
}) {
  // ensure handles can at least be converted to a number if passed as prop
  // if no prop is not valid then default to two handles
  const handles = !isNaN(numberOfHandles) ? Number(numberOfHandles) : 2;

  function validateInitialValues(initialValues) {
    // check if initialValues from db contain valid numbers, if so then
    // initialize the "values" array with those numbers
    // we dont want to override db if data is valid where valid data
    // we consider valid data an array of numbers
    if (
      Array.isArray(initialValues) &&
      initialValues.length > 0 &&
      !initialValues.some(isNaN)
    ) {
      return [...initialValues];
    } else {
      // number of handles only drives the number of data points saved
      // when there is no data coming from the db
      // if no initialValues are available from db then create a new array of initialized
      // values from the number of handles (where handles represent each point of data that we want to save)
      // index * (max / handles) - gives the space between handles)

      return Array.from(
        { length: handles },
        (el, index) => index * (max / handles),
      );
    }
  }
  return (
    <>
      <div id={field}>
        <Slider
          mode={3}
          step={step}
          domain={[min, max]}
          rootStyle={sliderStyle}
          onSlideEnd={(data) =>
            updateAutoSave(collection, id, { [field]: data })
          }
          values={validateInitialValues(initialValues)}
          disabled={disabled}
        >
          <Rail>{(railProps) => <TooltipRail {...railProps} />}</Rail>
          <Handles>
            {({ handles, activeHandleID, getHandleProps }) => {
              return (
                <div className="slider-handles">
                  {handles.map((handle) => (
                    <Handle
                      key={handle.id}
                      handle={handle}
                      domain={[min, max]}
                      isActive={handle.id === activeHandleID}
                      getHandleProps={getHandleProps}
                    />
                  ))}
                </div>
              );
            }}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
        </Slider>
      </div>
    </>
  );
}

export default AutoSaveSlider;
