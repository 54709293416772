import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../../../providers/database";
import ToastAlert from "../../../custom-ui/toast-alert/ToastAlert";
import AutoSaveCheckboxGroup from "../../../custom-ui/input-fields/auto-save-checkbox-group";
import { useLookup } from "../../../../data/lookup";
import SeekerInfo from "../parts/seekerInfo";
import { useResume } from "../../../../data/seeker";
import SeekerFiles from "../parts/SeekerFiles";
import DragAndDropVariantB from "../../../custom-ui/drag-and-drop/DragAndDropFileSmall";
import folderNames from "../../../../storage/dictionary/folderNames";
import { upload } from "../../../../storage/storage";
import { writeResume } from "../../../../data/seeker";
import { friendlyFirebaseError } from "../../../../providers/firebase-errors/friendlyFirebaseError";
import SeekerBadgeListing from "../parts/badgeListing";
import useModal from "../../../../hooks/useModal";
import Tooltip from "../../../custom-ui/tooltip/tooltip";
import GeneralNote from "../../../custom-ui/input-fields/modals/GeneralNote";
import SeekerNotesDisplay from "../parts/seekerNotes";
import SeekerApplicationList from "../parts/seekerApplicationList";
import Meta from "../../../custom-ui/meta/Meta";
import { useAllListings, useListing } from "../../../../data/listings";
import { createApplication } from "../../../../data/applications";
import { useApplicationsByUser } from "../../../../data/applications";
import {
  collectionNames,
  subCollectionNames,
} from "../../../../data/dictionary/collectionNames";

const SeekerNotesPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [id, setId] = useState(params?.seekerId || "");
  const { data: seekerData, error: seekerError } = useResume(id);
  const { data: listingData, error: listingError } = useAllListings(id);
  const { data: userData, error: userError } = useUser(id);
  const { data: model = [] } = useLookup("model");
  const { data: term = [] } = useLookup("term");
  const [viewNote, setViewNote] = useState(true);
  const [viewApplications, setViewApplications] = useState(false);
  const [viewOffers, setViewOffers] = useState(false);
  const [viewEmployment, setViewEmployment] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [invite, setInvite] = useState("");
  const { data: classification = [] } = useLookup("classification");
  const { data: currentApplications } = useApplicationsByUser(id);

  const { isShowing: showModal, toggle: toggleModal } = useModal();

  const { data: inviteData, error: inviteError } = useListing(invite);

  useEffect(() => {
    if (inviteData) {
      setDataLoaded(true);
    }
  }, [inviteData]);

  useEffect(() => {
    if (seekerError) {
      console.log(seekerError);
    }
  }, [seekerError]);
  useEffect(() => {
    if (userError) {
      console.log(userError);
    }
  }, [userError]);

  useEffect(() => {
    setId(params.seekerId ?? "");
  }, [params.seekerId]);

  const [file, setFile] = useState();
  const [formAlert, setFormAlert] = useState(null);
  const active =
    "w-full flex justify-center py-2 px-4 text-white bg-instant-teams-tags-GREEN rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none";
  const inactive =
    "w-full flex justify-center py-2 px-4 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none";

  const { isShowing: isShowingNote, toggle: toggleNote } = useModal();
  const handleDone = () => {
    toggleNote();
  };

  const handleViewNote = () => {
    setViewApplications(false);
    setViewOffers(false);
    setViewEmployment(false);
    setViewNote(true);
  };
  const handleViewApplications = () => {
    setViewNote(false);
    setViewOffers(false);
    setViewEmployment(false);
    setViewApplications(true);
  };
  const handleViewOffers = () => {
    setViewNote(false);
    setViewOffers(true);
    setViewEmployment(false);
    setViewApplications(false);
  };
  const handleViewEmployment = () => {
    setViewNote(false);
    setViewOffers(false);
    setViewEmployment(true);
    setViewApplications(false);
  };

  useEffect(() => {
    console.log(currentApplications);
    console.log(listingData);
  }, [currentApplications, listingData]);

  useEffect(() => {
    if (invite !== "") {
      console.log(invite);
    }
  }, [invite]);

  function handleInvite() {
    if (inviteData && invite !== "") {
      const application = {
        jobId: inviteData.jobId,
        positionId: inviteData.positionId,
        resumeId: id,
        invited: true,
      };
      createApplication(application);
      toggleModal();
      setInvite("");
      setDataLoaded(false);
    }
  }

  const confirmUpload = () => {
    upload(file, folderNames.resume, `${id}_${file?.name}`).then((fileUrl) => {
      writeResume(id, {
        label: file?.name,
        type: file?.type,
        link: fileUrl,
      })
        .then((docRef) => {
          setFile();
        })
        .catch((error: Error) => {
          setFormAlert({
            type: "error",
            message: friendlyFirebaseError(error),
          });
        })
        .catch((error: Error) => {});
    });
  };

  return (
    <div className="w-full pr-12">
      <Meta title="Seeker - Notes" />
      <div className="w-full mt-6">
       
          <div className="w-full uppercase text-xl text-instant-teams-teal-Main mb-4 flex flex-row">
            <div className="flex-[3_3_90%]">
            Notes
            </div>
            <Tooltip text="Add note">
             <div className="justify-self-end block p-4">
             
            <i
              onClick={toggleNote}
              id={`resume-add-note`}
              className="fak fa-notes-add-white text-instant-teams-teal-Main text-xl pl-4 float-right"
            ></i>
            
             </div>
             </Tooltip>
          </div>
        
        <SeekerNotesDisplay
          id={id}
          collection={collectionNames.resume}
          subCollection={subCollectionNames.notes}
        />
        {isShowingNote && (
          <GeneralNote onDone={handleDone} collection={"Resumes"} id={id} />
        )}
      </div>
      <ToastAlert />
    </div>
  );
};

export default SeekerNotesPage;
