import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useComponent } from '../../../data/academy';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { useEffect } from 'react';
import { getLevel } from '../../../util/assessments/level';

function FailedSkillListing({
  skill,
}: {
  skill: string;
}) {
  const { data: assessment } = useComponent(skill[0]);

  return (
    <div
        key={skill[0]}
        className="bg-instant-teams-teal-Main shadow-md rounded-lg p-2 m-2 max-h-30 mt-6"
      >
        <p className='text-white font-bold text-center text-sm'>Failed Skill</p>
        <div className="items-center rounded-b-lg relative">
          <img
            src={assessment?.badge || "/images/badge.png"}
            alt={assessment?.name}
            className="mx-auto w-12 max-h-12 rounded-xl"
          />
        </div>
        <p className="text-center text-white font-bold text-xs">
            {assessment?.displayName || assessment?.name}
          </p>
      </div>
  );
}

export default FailedSkillListing;
