import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { requireAuth } from "../../../../providers/auth";
import SeekerInfo from "../../../seeker/details/parts/seekerInfo";
import { useApplication } from "../../../../data/applications";

function ExternalJobApplicantPage() {
  const params = useParams();
  const { applicationId = "" } = params ?? {};
  const { data: jobApplicationData, status: jobApplicationDataStatus } =
    useApplication(applicationId);

  return (
    <div>
      <div className="">
        <Outlet />
      </div>
    </div>
  );
}

export default requireAuth(ExternalJobApplicantPage);
