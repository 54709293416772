type DescriptiveRole = {
  id: string;
  label: string;
};
export const roles = new Map<string, DescriptiveRole>([
  ["tac", { id: "tac", label: "Talent Acquisition Consultant" }],
  ["csss", { id: "csss", label: "Careeer Seeker Support Specialist" }],
  ["sales", { id: "sales", label: "Sales" }],
  ["pops", { id: "pops", label: "People Operations" }],
  ["learn", { id: "learn", label: "Learning and Development" }],
  ["fin", { id: "fin", label: "Finance" }],
  ["admin", { id: "admin", label: "Admin" }],
  ["sadmin", { id: "sadmin", label: "Super Admin" }],
  ["marketing", { id: "marketing", label: "Marketing" }],
  ["account", { id: "account", label: "Account Management" }],
  ["general", { id: "general", label: "General" }],
  ["owner", { id: "owner", label: "Owner" }],
  ["BILL", { id: "BILL", label: "Employer Billing Contact" }],
  ["GEN", { id: "GEN", label: "Employer" }],
  ["PRI", { id: "PRI", label: "Account Owner" }],
  ["COMM", { id: "COMM", label: "Community Member" }],
  ["SKR", { id: "SKR", label: "Career Seeker" }],
  ["IP", { id: "IP", label: "Instant Pro" }],
  ["RTM", { id: "RTM", label: "Remote Team Member" }],
]);
