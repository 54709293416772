import { useEffect, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { upload, uploadMessageImage } from "../../providers/firebaseStorage";
import { errorLogger } from "../../providers/clientLogger";
function MessageImageDropzone({userId, setImage, toggle, chatId}) {
  const VALID_FILE_EXTENSIONS = [".jpeg", ".jpg", ".png", ".JPG", ".PNG"];
  const DEFAULT_IMAGE = "/images/default-image-upload.png";
  const VALID_FILE_TYPES = {
    "image/png": [],
    "image/jpg": [],
    "image/jpeg": [],
  };

  let imageExtensionType = "png";

  const [state, setState] = useState({
    image: DEFAULT_IMAGE,
    previewImage: DEFAULT_IMAGE,
    canEdit: false,
  });

  const [imageSize, setImageSize] = useState(0);
  const [uploading, setUploading] = useState(false);

  const onDropRejectedImage = () =>
    setState({
      ...state,
      image: DEFAULT_IMAGE,
      previewImage: DEFAULT_IMAGE,
    });

  const onErrorImage = () =>
    setState({ ...state, image: DEFAULT_IMAGE, previewImage: DEFAULT_IMAGE });

  const validImageType = (file) =>
    VALID_FILE_EXTENSIONS.some((ext) => file.name.endsWith(ext));

  const validatorImage = (image) => {
    if (!validImageType(image)) {
      setState({ ...state, image: DEFAULT_IMAGE, previewImage: DEFAULT_IMAGE });
      errorLogger("Incorrect image type\nValid types: .jpeg, .jpeg, .png", {
        component: "Message dropzone validatorImage()",
        flow: "validate image",
        userId: `${userId}`,
      });

      return {
        code: "Incorrect-image-Type",
        message: "Valid image Types: .jpeg, .jpeg, .png",
      };
    } else {
      setState({ ...state, image });
      return null;
    }
  };
  const onDrop = useCallback((droppedFiles) => {
    if (droppedFiles[0]) {
      const file = droppedFiles[0];
      const fileSize = Math.ceil(file.size / 1024 / 1024);
      setImageSize(fileSize);
      if (file.type.includes("png")) imageExtensionType = "png";
      setState({
        ...state,
        image: file,
        canEdit: true,
        previewImage: URL.createObjectURL(droppedFiles[0]),
      });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected: onDropRejectedImage,
    onError: onErrorImage,
    validator: validatorImage,
    accept: VALID_FILE_TYPES,
  });

  async function handleImageUpload() {
    var seconds = new Date().getTime() / 1000;
    setUploading(true);
    const fileURL = await uploadMessageImage(state.image, `${seconds}_${state.image.name}`);
    setImage(fileURL);
    toggle();
  }

  return (
    <>
      <div className="flex justify-center" {...getRootProps()}>
        <input {...getInputProps()} id="logo-dropzone-input-field" />
        <img
          id="logo-upload-preview-area"
          className="cursor-pointer"
          src={state.previewImage || "/images/default-employer.jpg"}
          alt="Logo"
        />
      </div>
      {state.canEdit ? (
        <div className="flex flex-col items-center text-black">
          <p>Image size {imageSize} MB</p>

          {uploading === false ? (
            <div className="flex items-center justify-end p-6 mt-2 border-t border-solid border-slate-200 rounded-b">
              <button
                id="logo-close-button"
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => toggle()}
              >
                Close
              </button>
              <button
                id="logo-upload-save-changes-button"
                className="bg-instant-teams-blue-Main text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg hover:bg-instant-teams-blue-D1 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleImageUpload}
              >
                Save & Send
              </button>
            </div>
          ) : (
            <div className="flex justify-center mt-4">
              <button
                id="logo-uploading-button"
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                disabled
              >
                Uploading...
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="flex justify-center mt-4">
          <button
            id="close-logo-upload-button"
            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => toggle()}
          >
            Close
          </button>
        </div>
      )}
    </>
  );
}

export default MessageImageDropzone;
