import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useEmployer } from "/../../data/employer";
import { useEmployer } from "../../../data/employer";
import { useFindJobStatus } from "../../../data/status";
import { useLookupCode } from "../../../data/lookup";
import DisplayArrayLookup from "../../custom-ui/display/displayArrayLookups";
import Tooltip from "../../custom-ui/tooltip/tooltip";
import { useAuth } from "../../../providers/auth";
import { useJobPositions } from "../../../data/positions";
import { useApplicationsByJob } from "../../../data/applications";
import { useJobOffers } from "../../../data/offers";
import { TailwindHoverEnlarge } from "../../../util/tailwind/tailwind-styles";

const ExternalEmployerJobListingLineComponent = ({
  key,
  job,
}: {
  key: string;
  job: any;
}) => {
  const { userClaims } = useAuth();
  const navigate = useNavigate();
  const [status, setStatus] = useState(job.status);
  const { data: employer } = useEmployer(job.employer);
  const { data: solution } = useLookupCode(job.solutionType);
  const {
    data: statusData,
    status: statusStatus = "",
    error: statusError,
  } = useFindJobStatus(status);
  const { data: positionData } = useJobPositions(job?.id);

  const { data: applications = [] } = useApplicationsByJob(job.id);

  const underReview = applications.filter(
    (applicant: any) => applicant.status === "underReview",
  );

  const { data: jobOffers = [] } = useJobOffers(job.id);
  // removed "hired" because hired status has an employee record
  // and we display hired under employees (even when they are pending)
  const filteredJobOffers = jobOffers.filter(
    (offer: any) => offer.status !== "hired",
  );

  const presented = applications.filter(
    (applicant: any) => applicant.status === "presented",
  );

  const conversation = applications.filter(
    (applicant: any) => applicant.status === "conversation",
  );

  const totalPresented = presented.length + conversation.length;

  const [jobLink, setJobLink] = useState(`/jobs/new/${job.id}`);
  const [jobPosition, setJobPosition] = useState(0);

  const options = {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  };

  useEffect(() => {
    if (statusData?.id !== "working" && statusData !== undefined) {
      setJobLink(`/jobs/${job.id}/metrics`);
    }
  }, [statusData]);
  useEffect(() => {
    if (positionData?.length > 0) {
      setJobPosition(positionData.length);
    }
  }, [positionData]);

  //tailwindcss.com/docs/content-configuration#dynamic-class-names
  // Always map props to static class names
  const colorJobListingVariants: any = {
    approvedByCustomer: "bg-instant-teams-tags-LIME",
    archived: "bg-instant-teams-gray-D1",
    awaiting: "bg-instant-teams-tags-GOLD",
    draft_approval: "bg-instant-teams-tags-ORANGE",
    cancelled: "bg-instant-teams-gray-D1",
    draft: "bg-instant-teams-tags-CREAM text-black",
    working: "bg-instant-teams-tags-BLUE",
    on_hold: "bg-instant-teams-tags-ROSE",
    live: "bg-instant-teams-tags-GREEN",
    tac_review: "bg-instant-teams-tags-TEAL",
    filled: "bg-instant-teams-tags-LBLUE",
  };

  return (
    <div
      onClick={() => {
        userClaims?.claims?.type === "A"
          ? navigate(`/jobs/${job.id}/${job.employer}/description`)
          : navigate(jobLink);
      }}
      className={
        TailwindHoverEnlarge(103) +
        "cursor-pointer bg-white rounded-lg h-full shadow-lg"
      }
    >
      <div className="items-center w-full rounded-lg relative">
        <div className="items-center bg-instant-teams-teal-Main p-5 rounded-t-lg w-full min-h-max h-42">
          <div className="w-full text-white pl-2 text-center mb-2">
            <p className=" text-sm">{employer?.displayName || job.employer}</p>
            <p className=" text-xl">{job.title}</p>
            <p className=" text-sm">{solution?.[0]?.label || "Not Set"}</p>
          </div>
          <span className="inline-block px-2 py-1 text-xs font-bold leading-none text-instant-teams-teal-Main bg-white rounded-full absolute top-4 left-4">
            <Tooltip text="Positions Created">
              <p>{jobPosition}</p>
            </Tooltip>
          </span>
          <span className="inline-block px-2 py-1 text-xs font-bold leading-none text-instant-teams-teal-Main bg-white rounded-full absolute top-4 right-4">
            <Tooltip text="Number of Seats Available">
              <p>{job.seats || 1}</p>
            </Tooltip>
          </span>
        </div>
        <Tooltip text={statusData?.description || "Current Status"}>
          <div
            className={`items-center w-3/4 mt-5 rounded-full text-white mx-auto p-1 ${
              colorJobListingVariants[statusData?.id] ||
              "bg-instant-teams-purple-Main"
            }`}
          >
            <p className="text-center">{statusData?.label}</p>
          </div>
        </Tooltip>
        <div className="items-center bg-white w-full px-10 mt-5">
          <div className="grid grid-cols-3 space-x-4 items-center mx-auto text-center ">
            <Tooltip text="Applicants">
              <div className="rounded-full bg-instant-teams-tags-A1 text-instant-teams-tags-A2 px-1">
                {totalPresented || 0}
              </div>
            </Tooltip>
            <Tooltip text="Offers">
              <div className="rounded-full bg-instant-teams-tags-C1 text-instant-teams-tags-C2 px-1">
                {filteredJobOffers.length || 0}
              </div>
            </Tooltip>
            <Tooltip text="Employed">
              <div className="rounded-full bg-instant-teams-tags-D1 text-instant-teams-tags-D2 px-1">
                {0}
              </div>
            </Tooltip>
          </div>
        </div>

        <div className="text-center text-sm mt-4">
          <DisplayArrayLookup value={job.information?.term} group={"term"} />
        </div>
        <div className="text-center text-sm mt-1">
          <DisplayArrayLookup
            value={job?.information?.classification}
            group={"classification"}
          />
        </div>

        <div className="text-center text-sm mt-1">
          <DisplayArrayLookup value={job?.information?.model} group={"model"} />
        </div>
        <div className="text-center text-sm mt-1">
          Hours Per Week:{" "}
          {job?.information?.hoursPerWeek ? (
            <>
              {job?.information?.hoursPerWeek?.[0]} -{" "}
              {job?.information?.hoursPerWeek?.[1]}
            </>
          ) : (
            <>Not Set</>
          )}
        </div>
        <div className="text-center text-sm mt-1 mb-2">
          {job?.information?.payType?.HRLY && <span>Hourly</span>}
          {job?.information?.payType?.HRLY &&
            job?.information?.payType?.SAL && <span>/</span>}
          {job?.information?.payType?.SAL && <span>Salary</span>}
          {!job?.information?.payType?.HRLY &&
            !job?.information?.payType?.SAL && <span>Not Set</span>}
        </div>
      </div>
    </div>
  );
};

export default ExternalEmployerJobListingLineComponent;
