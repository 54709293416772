import React, { useState, useEffect } from "react";
import { updateAutoSave, logActivity } from "../../../data/utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../providers/auth";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { upload } from "../../../storage/storage";
import moment from "moment";
let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}

const DEFAULT_MAX_LENGTH = -999;

const AutoSaveWysiwyg = ({
  value: initialValue,
  rows,
  collection,
  recId,
  required,
  name,
  display,
  placeholder,
  directions,
  readOnly,
  editorStyle = {},
  maxLength = DEFAULT_MAX_LENGTH,
  imageFolderLocation = "images/wysiwyg/defaultFolder",
}) => {
  const auth = useAuth();
  const [value, setValue] = useState(initialValue ?? "");
  const [check, setCheck] = useState(initialValue);
  const [isValid, setIsValid] = useState(true);
  const [saved, setSaved] = useState(false);
  const [height, setHeight] = useState("pl-4 pr-4 h-36 ");
  const [editorState, setEditorState] = useState(() =>
    initialValue ? prepareDraft(initialValue) : EditorState.createEmpty(),
  );
  const [cleanHtml, setCleanHtml] = useState("");
  const [length, setLength] = useState(0);

  function prepareDraft(value) {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }

  const uploadCallback = (file) => {
    const fileName = file.name;

    const timestamp = moment().format("YYYYMMDD_HHmmss");
    const newFileName = `${timestamp}_${fileName}`;
    return new Promise((resolve, reject) =>
      upload(file, imageFolderLocation, newFileName)
        .then((link) => {
          resolve({ data: { link } });
        })
        .catch((error) => {
          reject(error);
        }),
    );
  };

  const onEditorStateChange = (newEditorState) => {
    const content = draftToHtml(
      convertToRaw(newEditorState.getCurrentContent()),
    );
    setEditorState(newEditorState);
    setValue(content);
    const stripedHtml = content.replace(/<[^>]+>/g, "");
    setCleanHtml(stripedHtml);
  };

  useEffect(() => {
    setTimeout(() => {
      setSaved(false);
    }, 3000);
  }, [saved]);

  useEffect(() => {
    setValue(initialValue);
    const stripedHtml = initialValue?.replace(/<[^>]+>/g, "") ?? "";
    setCleanHtml(stripedHtml);
  }, [initialValue]);

  useEffect(() => {
    const styleHeight = `pl-4 pr-4 h-${
      rows * 12
    } w-full [&_.rdw-link-decorator-wrapper]:text-instant-teams-blue-Main [&_.rdw-link-decorator-wrapper]:underline   `;
    setHeight(styleHeight);
  }, [rows]);

  const handleBlur = async () => {
    if (!value && required) {
      setIsValid(false);
      return;
    }
    if (value !== check) {
      console.log("value", value);
      const stripedHtml = value.replace(/<[^>]+>/g, "");
      setCleanHtml(stripedHtml);
      setIsValid(true);
      setCheck(value);
      await updateAutoSave(collection, recId, { [name]: value });
      await logActivity(
        collection,
        recId,
        name,
        check,
        value,
        auth.user.uid,
        check === "" ? "Added" : "Edited",
      );
      setSaved(true);
    }
  };

  useEffect(() => {
    setLength(cleanHtml?.length);
  }, [cleanHtml]);

  useEffect(() => {
    console.log("length: ", length);
  }, [length]);

  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      console.log(currentSelection);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = getLengthOfSelectedText();

    if (
      maxLength !== DEFAULT_MAX_LENGTH &&
      currentContentLength - selectedTextLength > maxLength - 1
    ) {
      return "handled";
    }
  };

  const handlePastedText = (pastedText) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = getLengthOfSelectedText();

    if (
      maxLength !== DEFAULT_MAX_LENGTH &&
      currentContentLength + pastedText.length - selectedTextLength > maxLength
    ) {
      return "handled";
    }
  };

  return (
    <div id={name} className="relative mb-4 ">
      <div className="text-instant-teams-teal-Main font-bold pl-1 ">
        {display} {directions ? " - " + directions : null}{" "}
        {readOnly ? " - LOCKED" : null}
      </div>
      <Editor
        readOnly={readOnly}
        spellCheck={true}
        editorStyle={editorStyle}
        editorState={editorState}
        handleBeforeInput={
          maxLength !== DEFAULT_MAX_LENGTH ? handleBeforeInput : () => {}
        }
        handlePastedText={
          maxLength !== DEFAULT_MAX_LENGTH ? handlePastedText : () => {}
        }
        onEditorStateChange={onEditorStateChange}
        wrapperClassName="shadow-md rounded-md bg-white pt-3"
        editorClassName={height}
        toolbarClassName="border-solid border-b-2 border-sky-500 p-2 text-xs"
        onBlur={handleBlur}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "link",
            "image",
            "list",
            "textAlign",
          ],
          inline: {
            options: ["bold", "italic", "underline"],
          },
          list: {
            options: ["unordered", "ordered"],
          },
          textAlign: {
            options: ["left", "center", "right", "justify"],
          },
          link: {
            className: undefined,
          },
          image: {
            uploadCallback: uploadCallback,
          },
        }}
      />
      {saved && (
        <span className="absolute right-2 bottom-2 text-xs">
          {display} saved!{" "}
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "green" }} />
        </span>
      )}
      {maxLength !== DEFAULT_MAX_LENGTH && (
        <div className="text-instant-teams-teal-Main pl-4 mt-2">
          <p className="text-right text-sm font-bold">
            {length} of Suggested Max Length {maxLength}{" "}
          </p>
        </div>
      )}
      {!isValid && <div className="error">This field is required.</div>}
    </div>
  );
};

export default AutoSaveWysiwyg;
