import { useAuth } from "../../../providers/auth";
import { useEmployer } from "../../../data/employer";
import { useState, useEffect } from "react";

function DashboardHeader() {
  const { user, userClaims } = useAuth();
  const joinDate = new Date(user?.metadata?.creationTime).toLocaleDateString();
  const lastLogIn =
    new Date(user?.metadata?.lastSignInTime).toLocaleDateString() +
    " at " +
    new Date(user?.metadata?.lastSignInTime).toLocaleTimeString();

  const { data: employer, status: employerStatus } = useEmployer(
    userClaims?.claims?.employerId,
  );
  const [logo, setLogo] = useState(employer?.logo);
  const [displayName, setDisplayName] = useState(employer?.displayName);

  useEffect(() => {
    setLogo(employer?.logo);
    setDisplayName(employer?.displayName);

    return () => {
      setLogo("/images/default-employer.jpg");
      setDisplayName("");
    };
  }, [employer]);

  return (
    <div className="  w-full">
      {userClaims?.claims?.type === "E" ? (
        <div className="items-center pt-4  pb-3">
          <img
            src={logo}
            alt={displayName}
            className="mx-auto  max-w-75 h-auto"
          />
          <div className="text-center text-xl font-bold pb-1 pt-3">
            {displayName}
          </div>
        </div>
      ) : null}
      <div className="px-2 pb-2">
        <h1>Welcome</h1>
        <h1 className="text-2xl">{user?.displayName}</h1>
        <p className="text-xs">Member since : {joinDate}</p>
        <p className="text-xs">Last Logged In : {lastLogIn}</p>
      </div>
    </div>
  );
}

export default DashboardHeader;
