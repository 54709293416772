// DASHBOARD CHILDREN
import DashboardSection from "../../components/dashboard/DashboardSection";
import GeoSeeker from "../../components/geo-seeker/GeoSeeker";
import OffersDash from "../../components/dashboard/popsDashSections/OffersDash";
import NotificationsSection from "../../components/dashboard/notificationsSection/NotificationsSection";
import AlertsSection from "../../components/alerts/AlertsSection";
import AdminMessages from "../../components/messages/AdminMessages";
import MarketplaceSettings from "../../pages/settings/MarketplaceSettings";
import InterviewInstructions from "../../components/interviews/Employer/InterviewInstructions";
import { customDataRouteObject } from "../../dataTypes/ReactRouter";
// EMPLOYER DETAILS CHILDREN
import EmployerOverview from "../../components/employer/details/pages/EmployerOverview";
import EditEmployerProfile from "../../components/employer/details/pages/EmployerProfile";
import DepartmentListing from "../../components/employer/details/pages/EmployerDepartments";
import EmployerLocationListing from "../../components/employer/details/pages/EmployerLocations";
import EmployerJobs from "../../components/employer/details/pages/EmployerJobs";
import Applicants from "../../components/employer/details/pages/Applicants";
import EmployerUserManagement from "../../components/employer/details/pages/EmployerUserManagement";
import NotesDisplay from "../../components/notes/notes";
import EmployerAccessLogs from "../../components/employer/details/pages/EmployerAccessLogs";
import ActivityLogs from "../../components/activityLog/activityLogListing";
import EmployerDetails from "../../components/employer/details/pages/EmployerDetails";
import Offers from "../../components/employer/details/pages/Offers";
import Employees from "../../components/employee/details/pages/Employees";
import EmployerMessaging from "../../components/messages/EmployerMessaging";
// SEEKER DETAILS CHILDREN
import SeekerOverview from "../../components/seeker/details/pages/seekerOverview";
//  USER DETAILS CHILDREN
import UserInfo from "../../components/user/details/pages/userInfo";
import AccessLogListing from "../../components/user/details/pages/accessLogListing";
import UserEnrollments from "../../components/user/details/pages/enrollments";
import { collectionNames } from "../../data/dictionary/collectionNames";

//  ACADEMY CHILD ROUTES
import Assessment from "../../components/academy/pages/Assessment";
//Openings
import JobDescriptionPage from "../../components/jobs/pages/JobDescriptionPage";
import JobInformation from "../../components/jobs/pages/JobInformation";
import JobPositionDetails from "../../components/jobs/pages/JobPositionDetails";
import JobPositionsListPage from "../../components/jobs/pages/JobPositionsListPage";
import JobInterviewQuestions from "../../components/jobs/pages/JobInterviewQuestions";
import JobSkills from "../../components/jobs/pages/JobSkills";
// Job (New)
import JobNewOverview from "../../components/jobs/pages/JobNewOverview";
import JobNewInformation from "../../components/jobs/pages/JobNewInformation";
// Employer view Company Details
import CompanyOverview from "../../components/employerView/pages/CompanyOverview";
import EditCompanyProfile from "../../components/employerView/pages/CompanyProfile";
import { ErrorBoundary } from "react-error-boundary";
import Fallback from "../../components/fallback/Fallback";
import JobOverview from "../../components/jobs/pages/JobOverview_NewUI";
import JobApplicants from "../../components/jobs/pages/JobApplicants";
import JobApplicantDetails from "../../components/jobs/pages/JobApplicantDetails";
import OfferDetails from "../../components/offers/parts/OfferDetails";
import JobApplicantSkills from "../../components/jobs/pages/JobApplicantSkills";
import PendingOffers from "../../components/jobs/pages/PendingOffers";
import JobOutreach from "../../components/jobs/pages/JobOutreach";
import SeekerApplications from "../../components/seeker/details/pages/SeekerApplications";
import SeekerOffers from "../../components/seeker/details/pages/SeekerOffers";
import SeekerEmployment from "../../components/seeker/details/pages/SeekerEmployment";
import SeekerNotesPage from "../../components/seeker/details/pages/SeekerNotes";
import AssessmentOverview from "../../components/academy/pages/AssessementOverview";
import TestDetails from "../../components/academy/parts/testDetails";
import CustomTestDetails from "../../components/academy/parts/customTestDetails";
import TestResults from "../../components/academy/parts/testResults";
import ExternalJobOverview from "../../components/jobs/externalView/ExternalJobOverview";
import ExternalJobApplicantDetails from "../../components/jobs/externalView/parts/ExternalJobApplicantsDetails";
import JobApplicantsPage from "../../components/jobs/pages/JobApplicantsPage";
import JobApplicantPreScreen from "../../components/jobs/pages/JobApplicantPreScreen";
import JobApplicantInterview from "../../components/jobs/pages/JobApplicantInterview";
import VideoDetails from "../../components/video/VideoDetails";
import ExternalJobDescriptionPage from "../../components/jobs/externalView/pages/ExternalJobDescription";
import ExternalJobPositionDetails from "../../components/jobs/externalView/parts/ExternalPositionDetails";
import { InstantSearch } from "react-instantsearch-hooks-web";
import algoliasearch from "algoliasearch/lite";
import ExternalJobInformation from "../../components/jobs/externalView/pages/ExternalJobInformation";
import ExternalApplicants from "../../components/employerView/pages/ExternalApplicants";
import ExternalNotesListing from "../../components/employerView/pages/ExternalNotesListing";
import { ALL_ADMIN } from "../navbarRoutes";
import PendingSeekers from "../../pages/seekers/pending-seekers";
import LimitedUsers from "../../pages/users/limited-users";
// employee routes children
import EmployeeDetails from "../../components/employee/details/pages/EmployeeDetails";
import EmployeeSkills from "../../components/employee/details/pages/EmployeeSkills";
import ExternalJobSkills from "../../components/jobs/externalView/parts/ExternalJobSkills";
import ExternalOffers from "../../components/employerView/ExternalOffers";
import EInterviewTemplates from "../../pages/eInterview/einterview-templates";
import QuestionListEmployer from "../../components/interviews/Employer/QuestionListEmployer";
import SeekerPreferences from "../../components/seeker/details/pages/seekerPreferences";
import AssessementPageDashboard from "../../components/academy/pages/AssessmentPageDashboard";
import AcademyAssessments from "../../components/academy/pages/AcademyAssessments";
import MarketingDashboard from "../../pages/marketing/pages/marketing-dashboard";
import CampaignDetails from "../../pages/marketing/pages/campaign-details";
import CommunityDashboard from "../../components/user/community/communityDashboard";
import AcceleratorListing from "../../components/academy/pages/Accelerators/AcceleratorListing";
import RotatorDashboard from "../../pages/seekerRotator/pages/rotator-dashboard";
import Accelerator from "../../components/academy/pages/Accelerators/Accelerator";
import EmployerTraining from "../../components/employer/details/pages/EmployerTraining";
import EmployeeMessages from "../../components/employee/details/pages/EmployeeMessages";
import TacJobApplications from "../../components/dashboard/tacDashSections/TacJobApplications";
import TacMeetings from "../../components/dashboard/tacDashSections/TacMeetings";
import CommunityControlPanel from "../../components/community/Dashboard";
import ChangeLogs from "../../components/changeLogs/change-logs";

const { employers, users } = collectionNames;

const searchClient = (() => {
  if (!process.env.REACT_APP_ALGOLIA_APP_ID) {
    throw new Error(
      "REACT_APP_ALGOLIA_APP_ID environment variable not defined",
    );
  }
  if (!process.env.REACT_APP_ALGOLIA_API_KEY) {
    throw new Error(
      "REACT_APP_ALGOLIA_API_KEY environment variable not defined",
    );
  }

  return algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY,
  );
})();

export const AssessementChildren: customDataRouteObject[] = [
  {
    id: "Assessement Dashboard",
    name: "Dashboard",
    path: "dashboard",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <AssessementPageDashboard />
      </ErrorBoundary>
    ),
    viewer: ["sadmin", "admin", "learn"],
    menuDisplay: true,
  },
  {
    id: "Assessement Learning and Development",
    name: "Assessments",
    path: "assessments",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch
          searchClient={searchClient}
          indexName="AcademyAssessments"
        >
          <AcademyAssessments />
        </InstantSearch>
      </ErrorBoundary>
    ),
    viewer: ["sadmin", "admin", "learn"],
    menuDisplay: true,
  },
  {
    id: "Accelerators Overview",
    name: "Accelerators",
    path: "accelerators",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch
          searchClient={searchClient}
          indexName="AcademyAccelerators"
        >
          <AcceleratorListing />
        </InstantSearch>
      </ErrorBoundary>
    ),
    viewer: ["sadmin", "admin", "learn"],
    menuDisplay: true,
  },
];
export const AssessmentDetailsChildren: customDataRouteObject[] = [
  {
    id: "Assessement Details Dashboard",
    name: "Dashboard",
    path: "dashboard",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <Assessment />
      </ErrorBoundary>
    ),
    viewer: ["sadmin", "admin", "learn"],
    menuDisplay: true,
  },
  {
    id: "Assessement Overview",
    name: "Overview",
    path: "overview",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <AssessmentOverview />
      </ErrorBoundary>
    ),
    viewer: ["sadmin", "admin", "learn"],
    menuDisplay: true,
  },
  {
    id: "Test Details",
    name: "Test Details",
    path: "test-details",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <TestDetails />
      </ErrorBoundary>
    ),
    viewer: ["sadmin", "admin", "learn"],
    menuDisplay: true,
  },
  {
    id: "Custom Test Details",
    name: "Custom Details",
    path: "custom-details",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <CustomTestDetails />
      </ErrorBoundary>
    ),
    viewer: ["sadmin", "admin", "learn"],
    menuDisplay: true,
  },
  {
    id: "Test Results",
    name: "Results",
    path: "test-results",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <TestResults />
      </ErrorBoundary>
    ),
    viewer: ["sadmin", "admin", "learn"],
    menuDisplay: true,
  },
];
export const AcceleratorDetailsChildren: customDataRouteObject[] = [
  {
    id: "Accelerator Details Dashboard",
    name: "Dashboard",
    path: "dashboard",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <Accelerator />
      </ErrorBoundary>
    ),
    viewer: ["sadmin", "admin", "learn"],
    menuDisplay: true,
  },
];
export const ApplicantChildRoutes: customDataRouteObject[] = [
  {
    id: "Application View Details ",
    name: "Details ",
    path: "details",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobApplicantDetails />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Job Applicant Skills",
    name: "Skills",
    path: "applicant-skills",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobApplicantSkills />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Application View PreScreen",
    name: "PreScreen",
    path: "prescreen",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobApplicantPreScreen />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Application View eInterview",
    name: "e-interview",
    path: "einterview",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobApplicantInterview />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
];

export const DashboardChildRoutes: customDataRouteObject[] = [
  {
    id: "Home",
    name: "Home",
    path: "home",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <DashboardSection />
      </ErrorBoundary>
    ),
    viewer: [
      "sadmin",
      "admin",
      "sales",
      "tac",
      "csss",
      "pops",
      "learn",
      "finance",
      "marketing",
      "general",
      "GEN",
      "PRI",
      "BILL",
    ],
    menuDisplay: true,
  },
  {
    id: "tacApplicationDashboard",
    name: "TAC Dashboard",
    path: "application-dash",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <TacJobApplications />
      </ErrorBoundary>
    ),
    viewer: ["sadmin", "tac"],
    menuDisplay: true,
  },
  {
    id: "Offers Dash",
    name: "POPS Dashboard",
    path: "offers-dash",
    element: (
      // @ts-ignorezp
      <ErrorBoundary FallbackComponent={Fallback}>
        <OffersDash />
      </ErrorBoundary>
    ),
    viewer: ["sadmin", "pops", "tac", "admin"],
    menuDisplay: true,
  },
  {
    id: "GeoSeeker",
    name: "Outreach by Location",
    path: "geoseeker",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <GeoSeeker />
      </ErrorBoundary>
    ),
    viewer: [
      "sadmin",
      "admin",
      "sales",
      "tac",
      "csss",
      "pops",
      "learn",
      "finance",
      "marketing",
    ],
    menuDisplay: true,
  },
  {
    id: "tacMeetingList",
    name: "Meetings",
    path: "meetings",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <TacMeetings />
      </ErrorBoundary>
    ),
    viewer: ["tac", "sadmin", "pops", "csss", "admin"],
    menuDisplay: true,
  },
  {
    id: "Community Dashboard",
    name: "Twelve Million Control Panel",
    path: "twelvemillion",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <CommunityControlPanel />
      </ErrorBoundary>
    ),
    viewer: ["sadmin", "admin", "marketing"],
    menuDisplay: true,
  },
  {
    id: "Notifications Admin",
    name: "Notifications Admin",
    path: "notifications",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <NotificationsSection />
      </ErrorBoundary>
    ),
    viewer: ["sadmin"],
    menuDisplay: true,
  },
  {
    id: "AlertsAdmin",
    name: "Alerts Admin",
    path: "alerts",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <AlertsSection />
      </ErrorBoundary>
    ),
    viewer: ["sadmin"],
    menuDisplay: true,
  },
  {
    id: "Messages Admin",
    name: "Messages Admin",
    path: "messages",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="Chats">
          <AdminMessages />
        </InstantSearch>
      </ErrorBoundary>
    ),
    viewer: ["sadmin"],
    menuDisplay: true,
  },
  {
    id: "RecordingDetails",
    name: "Recording Details",
    path: "recording/:recordingId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <VideoDetails />
      </ErrorBoundary>
    ),
    viewer: ["sadmin"],
  },
  {
    id: "limitedUsers",
    name: "Limited Users Admin",
    path: "limited-users",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <LimitedUsers />
      </ErrorBoundary>
    ),
    viewer: ["sadmin", "admin"],
    menuDisplay: true,
  },
  {
    id: "pendingSeekers",
    name: "Pending Seekers Admin",
    path: "pending-seekers",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <PendingSeekers />
      </ErrorBoundary>
    ),
    viewer: ["sadmin", "admin", "csss"],
    menuDisplay: true,
  },
  {
    id: "eInterviewAdmin",
    name: "e-interview Questions Admin",
    path: "einterview-admin",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EInterviewTemplates />
      </ErrorBoundary>
    ),
    viewer: ["sadmin"],
    menuDisplay: true,
  },
  {
    id: "artemisSettings",
    name: "Marketplace Settings",
    path: "marketplace-settings",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <MarketplaceSettings />
      </ErrorBoundary>
    ),
    viewer: ["sadmin"],
    menuDisplay: true,
  },
  {
    id: "marketingInterface",
    name: "Seeker Marketing",
    path: "marketing",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <MarketingDashboard />
      </ErrorBoundary>
    ),
    viewer: ["sadmin", "admin", "marketing", "pops"],
    menuDisplay: true,
  },
  {
    id: "rotatorInterface",
    name: "Seeker Rotator",
    path: "rotator-interface",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <RotatorDashboard />
      </ErrorBoundary>
    ),
    viewer: ["sadmin", "admin", "marketing"],
    menuDisplay: true,
  },
  {
    id: "marketingCampaign",
    name: "Campaign Details",
    path: "marketing/:campaignId",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <CampaignDetails />
      </ErrorBoundary>
    ),
    viewer: ["sadmin", "admin", "marketing"],
    menuDisplay: false,
  },
];

export const SeekerDetailsChildRoutes: customDataRouteObject[] = [
  {
    id: "Seeker Overview",
    name: "Overview",
    path: "overview",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SeekerOverview />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Seeker Preferences",
    name: "Preferences",
    path: "preferences",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SeekerPreferences />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Seeker Skills",
    name: "Skills",
    path: "user-academy",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <UserEnrollments />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Pending Applications",
    name: "Applications",
    path: "seeker-applications",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SeekerApplications />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },

  {
    id: "Pending Offers",
    name: "Offers",
    path: "seeker-offers",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SeekerOffers />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },

  {
    id: "Employment History",
    name: "Employment History",
    path: "seeker-employment",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SeekerEmployment />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Seeker Notes",
    name: "Notes",
    path: "seeker-notes",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SeekerNotesPage />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
];
export const UserDetailsChildRoutes: customDataRouteObject[] = [
  {
    id: "User Info",
    name: "User Info",
    path: "user-info",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <UserInfo />
      </ErrorBoundary>
    ),
    viewer: ["sadmin"],
    menuDisplay: true,
  },
  {
    id: "User Academy",
    name: "Academy",
    path: "user-academy",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <UserEnrollments />
      </ErrorBoundary>
    ),
    viewer: ["sadmin"],
    menuDisplay: true,
  },
  {
    id: "User Notes",
    name: "Notes",
    path: "user-notes",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <NotesDisplay collection={users} paramId="userId" />
      </ErrorBoundary>
    ),
    viewer: ["sadmin"],
    menuDisplay: true,
  },
  {
    id: "Community",
    name: "Community",
    path: "community",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <CommunityDashboard />
      </ErrorBoundary>
    ),
    viewer: ["sadmin"],
    menuDisplay: true,
  },
  {
    id: "User Access Logs",
    name: "Access Logs",
    path: "access-logs",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <AccessLogListing />
      </ErrorBoundary>
    ),
    viewer: ["sadmin"],
    menuDisplay: false,
  },
  {
    id: "User Audit Logs",
    name: "Audit Logs",
    path: "audit-logs",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <ActivityLogs collection={users} paramId="userId" />
      </ErrorBoundary>
    ),
    viewer: ["sadmin"],
    menuDisplay: false,
  },
];

export const EmployerDetailsChildRoutes: customDataRouteObject[] = [
  {
    id: "Overview",
    name: "Overview",
    path: "overview",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EmployerOverview />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Details",
    name: "Details",
    path: "details",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EmployerDetails />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Profile",
    name: "Profile",
    path: "profile",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EditEmployerProfile />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Departments",
    name: "Departments",
    path: "departments",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <DepartmentListing />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Locations",
    name: "Locations",
    path: "locations",
    // @ts-ignore
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EmployerLocationListing />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "interviewInstructions",
    name: "Interview Instructions",
    path: "interview-instructions",
    // @ts-ignore
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InterviewInstructions />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "eInterview",
    name: "e-interview Template",
    path: "einterview-employer",
    // @ts-ignore
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <QuestionListEmployer />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Job Listings",
    name: "Jobs",
    path: "openings",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EmployerJobs />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Applicants",
    name: "Applicants",
    path: "applicants",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="Applications">
          <Applicants />
        </InstantSearch>
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Employer Application Details ",
    name: "Details ",
    path: "applicants/:applicationId/:resumeId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobApplicantDetails />
      </ErrorBoundary>
    ),
  },
  {
    id: "Offers",
    name: "Offers",
    path: "offers",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="Offers">
          <Offers />
        </InstantSearch>
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Employees",
    name: "Employees",
    path: "employees",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="Employees">
          <Employees />
        </InstantSearch>
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Training",
    name: "Training",
    path: "training",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EmployerTraining />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "User Management",
    name: "User Management",
    path: "user-management",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EmployerUserManagement />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Notes",
    name: "Notes",
    path: "notes",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <NotesDisplay collection={employers} paramId="employerId" />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Messaging",
    name: "Messaging",
    path: "messaging",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EmployerMessaging />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Employer Access Logs",
    name: "Access Logs",
    path: "access-logs",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EmployerAccessLogs />
      </ErrorBoundary>
    ),
    viewer: ["sadmin"],
    menuDisplay: false,
  },
  {
    id: "Employer Audit Logs",
    name: "Audit Logs",
    path: "audit-logs",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <ActivityLogs collection={employers} paramId="employerId" />
      </ErrorBoundary>
    ),
    viewer: ["sadmin"],
    menuDisplay: false,
  },
];

export const EmployeeChildRoutes: customDataRouteObject[] = [
  {
    id: "Employee View Details",
    name: "Details",
    path: "details",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EmployeeDetails />
      </ErrorBoundary>
    ),
    viewer: [...ALL_ADMIN, "GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
  {
    id: "Employee Messages",
    name: "Messages",
    path: "employee-messages",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EmployeeMessages />
      </ErrorBoundary>
    ),
    viewer: [...ALL_ADMIN, "GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
  {
    id: "Employee View Skills",
    name: "Skills",
    path: "employee-skills",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EmployeeSkills />
      </ErrorBoundary>
    ),
    viewer: [...ALL_ADMIN, "GEN", "PRI", "BILL"],
    menuDisplay: true,
  },
];

export const OfferDetailsChildRoutes: customDataRouteObject[] = [
  {
    id: "Offer Details",
    name: "Details",
    path: "details",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <OfferDetails />
      </ErrorBoundary>
    ),
    viewer: [...ALL_ADMIN],
    menuDisplay: true,
  },
];

export const JobListingChildRoutes: customDataRouteObject[] = [
  {
    id: "Metrics",
    name: "Metrics",
    path: "metrics",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobOverview />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Outreach",
    name: "Outreach",
    path: "outreach",
    element: (
      // @ts-ignore
      <ErrorBoundary Fallback={Fallback}>
        <JobOutreach key="jobOutreach" />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Applications",
    name: "Applicants",
    path: "applications",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobApplicants />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Applications ",
    name: "Applicant ",
    path: "applications/:applicationId/",
    element: (
      // @ts-ignore
      <ErrorBoundary Fallback={Fallback}>
        <JobApplicantsPage />
      </ErrorBoundary>
    ),
  },
  {
    id: "Internal Offer Details ",
    name: "Internal Offer Details ",
    path: "offer/:offerId/",
    element: (
      // @ts-ignore
      <ErrorBoundary Fallback={Fallback}>
        <OfferDetails />
      </ErrorBoundary>
    ),
  },
  {
    id: "Employment Offers",
    name: "Offers",
    path: "offers",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <PendingOffers />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Hired",
    name: "Hired",
    path: "hired",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="Employees">
          <Employees />
        </InstantSearch>
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Description",
    name: "Description",
    path: "description",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobDescriptionPage />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Information",
    name: "Information",
    path: "information",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobInformation />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Skills",
    name: "Skills",
    path: "skills",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobSkills />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "E-Interview",
    name: "e-interview",
    path: "e-interview",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobInterviewQuestions />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Positions",
    name: "Positions",
    path: "positions",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobPositionsListPage />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Position Details",
    name: "Position Details",
    path: "positions/:positionId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobPositionDetails />
      </ErrorBoundary>
    ),
  },
  {
    id: "Job Audit History",
    name: "Audit Logs",
    path: "audit-logs",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <ActivityLogs collection={"JobDescriptions"} paramId={"jobId"} />
      </ErrorBoundary>
    ),
    viewer: ["sadmin"],
    menuDisplay: false,
  },
  {
    id: "Note History",
    name: "Notes",
    path: "notes",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <NotesDisplay collection={"JobDescriptions"} paramId="jobId" />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
];

export const JobNewListingChildRoutes: customDataRouteObject[] = [
  {
    id: "Metrics for Job (New)",
    name: "Metrics",
    path: "metrics",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobNewOverview />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Information for Job (New)",
    name: "Information",
    path: "information",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobNewInformation />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
  {
    id: "Change Log for Job (New)",
    name: "Change Logs",
    path: "change-logs",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <ChangeLogs collection={"JobDetailsNewCollection"} paramId="jobId" />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
    menuDisplay: true,
  },
];
