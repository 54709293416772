import { firebaseApp } from "./firebase";

import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
  getMetadata
} from "firebase/storage";
import { callWithRetry } from "../util/retryExpFalloff/callWithRetry";

const storage = getStorage(firebaseApp);

export async function upload(file, path, setLoading) {
  const fileRef = ref(storage, `images/${path}`);
  await uploadBytes(fileRef, file);
}

export async function uploadLogo(file) {
  const fileRef = ref(storage, "images/logo");

  await uploadBytes(fileRef, file);
  const fullPath = fileRef.fullPath;
  const downloadURL = await getDownloadURL(fileRef);
  const extIndex = downloadURL.lastIndexOf(".");
  const ext = downloadURL.substring(extIndex);

  return `${downloadURL.substring(0, extIndex)}${ext}`;
}

export async function uploadBadge(file, folderName, fileName) {
  const fileRef = ref(storage, `${folderName}/${fileName}`);
  await uploadBytes(fileRef, file);
  const donwloadURL = await getDownloadURL(fileRef);
  return donwloadURL;
}

export async function uploadBytesWithRetry(
  file,
  folderName,
  fileName,
  setUploadProgress,
) {
  const fileRef = ref(storage, `${folderName}/${fileName}`);
  const uploadTask = uploadBytesResumable(fileRef, file);
  const handleUpload = () => {
    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setUploadProgress(progress);
        },
        (error) => {
          console.log(error);
          reject({ isError: true, error, downloadURL: null });
        },
        async () => {
          try {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve({ downloadURL, isError: false });
            });
          } catch (error) {
            console.log(error);
            reject({ isError: true, error, downloadURL: null });
          }
        },
      );
    });
  };
  try {
    return callWithRetry(async () => handleUpload());
  } catch (error) {}
}

export async function uploadGeneric(file, folderName, fileName) {
  const fileRef = ref(storage, `${folderName}/${fileName}`);
  await uploadBytes(fileRef, file);
  const donwloadURL = await getDownloadURL(fileRef);
  return donwloadURL;
}

export async function uploadMessageImage(file, fileName) {
  const fileRef = ref(storage, `messageImages/${fileName}`);
  await uploadBytes(fileRef, file);
  const donwloadURL = await getDownloadURL(fileRef);
  return donwloadURL;
}

export async function uploadAudio(file, folderName, fileName) {
  const fileRef = ref(storage, `${folderName}/${fileName}`);
  await uploadBytes(fileRef, file, { contentType: "audio/mpeg" });
  const donwloadURL = await getDownloadURL(fileRef);
  return donwloadURL;
}

export async function uploadCommunityCSV(file) {
  const fileRef = ref(storage, `community/memberCSVs/${file.name}`);
  await uploadBytes(fileRef, file);
  const donwloadURL = await getMetadata(fileRef);
  return donwloadURL;
}
