import React, { useState, useEffect } from "react";
import { uploadAudio } from "../../providers/firebaseStorage";
import { updateAutoSave } from "../../data/utility";
import { useReactMediaRecorder } from "react-media-recorder";
import { getAudioFromBlob } from "../../util/audioEncoder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophoneLines,
  faWaveformLines,
} from "@fortawesome/pro-solid-svg-icons";
import { useAuth } from "../../providers/auth";

const QuestionAudioRecorder = ({
  id,
  applicationId = "",
  collection,
  closeMe,
  returnFile = false,
  folderName = "audio",
}: {
  id: string;
  applicationId?: string;
  collection: string;
  closeMe: Function;
  returnFile?: Boolean;
  folderName?: string;
}) => {
  const { userClaims, user } = useAuth();
  const [audioURL, setAudioURL] = useState("");
  const seekerAnswersFileName = `${user.uid}_${applicationId}_${id}.wav`;
  const fileName =
    userClaims.claims.type === "S" ? seekerAnswersFileName : `${id}.wav`;
  useEffect(() => {
    if (!window.MediaRecorder) {
      alert("Unsupported Browser");
    } else if (!window.navigator.mediaDevices) {
      alert("This browser doesn't support audio recording");
    }
  });

  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({
      video: false,
      audio: true,
    });

  async function finalizeAudio() {
    const audioFile = await getAudioFromBlob(mediaBlobUrl, fileName);
    const url = await uploadAudio(audioFile, folderName, fileName);
    setAudioURL(url);
  }
  useEffect(() => {
    if (audioURL !== "") {
      const recording = {
        audioURL: audioURL,
      };

      updateAutoSave(collection, id, recording);
    }
  }, [audioURL, collection, id]);

  useEffect(() => {
    if (mediaBlobUrl !== undefined) {
      finalizeAudio();
    }
  }, [mediaBlobUrl]);

  return (
    <div className="w-full pt-4 pb-4">
      <div className="flex flex-col items-center justify-center mt-4">
        {status === "recording" && (
          <>
            <FontAwesomeIcon icon={["fas", "waveform-lines"]} size="2xl" />
            <button
              className="py-2 mt-4 mb-4 px-4 w-full text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none ml-auto"
              onClick={stopRecording}
            >
              Stop Recording
            </button>
          </>
        )}
        {status === "idle" && (
          <>
            <FontAwesomeIcon icon={["fas", "microphone-lines"]} size="2xl" />
            <button
              className="py-2 mt-4 mb-4 px-4 w-full text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none ml-auto"
              onClick={startRecording}
            >
              Start Recording
            </button>
          </>
        )}
        {status === "stopped" && (
          <>
            <p className="text-instant-teams-blue-Main mb-4">All done!</p>
            <FontAwesomeIcon icon={["fas", "badge-check"]} size="2xl" />
            <button
              className="py-2 mt-4 mb-4 px-4 w-full text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none ml-auto"
              onClick={() => (returnFile ? closeMe(audioURL) : closeMe())}
            >
              Close Window
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default QuestionAudioRecorder;
