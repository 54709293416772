import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../providers/auth";
import { TailwindHoverEnlarge } from "../../../../util/tailwind/tailwind-styles";
import Tooltip from "../../../custom-ui/tooltip/tooltip";
import { useEmployer } from "../../../../data/employer";
import { useJobDescription } from "../../../../data/jobDescriptions";
import { useJobPositionDetails } from "../../../../data/positions";
import { useJobOffer } from "../../../../data/offers";
import { useUserByEmail2 } from "../../../../data/user";
// import { useUser } from "../../../providers/database";
import { useUser } from "../../../../data/user";

import { useEmploymentByUserIdAndJobId } from "../../../../data/employee";
import { formatMoney } from "../../../../util/currency/currency";

import JustTheCheckbox from "../../../custom-ui/checkbox/JustTheCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-duotone-svg-icons";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import FileSaver from "file-saver";
import toast from "react-hot-toast";
import ToastAlert from "../../../custom-ui/toast-alert/ToastAlert";

const SignedOffersItem = ({
  offer,

  selectedOffers,
  setSelectedOffers,
  signedOffersProcessed,
}: {
  offer: any;
  signedOffersProcessed: any;
  selectedOffers: any;
  setSelectedOffers: Function;
}) => {
  const { userClaims } = useAuth();

  const navigate = useNavigate();
  const { data: employerData, isLoading: isEmployerLoading } = useEmployer(
    offer?.employerId,
  );
  const { data: jobDescription, isLoading: isJobLoading } = useJobDescription(
    offer?.jobId,
  );

  const { data: user, isLoading: isUserLoading } = useUser(offer?.resumeId);

  const { data: positionDetails, isLoading: isPositionDataLoading } =
    useJobPositionDetails(offer?.jobId, offer?.positionId);

  const { data: employeeData, isLoading: isEmployeeDataLodaing } =
    useEmploymentByUserIdAndJobId(offer?.resumeId, offer?.jobId);

  const handleCheckboxChange = (offer: any) => {
    const offerExists = selectedOffers.some(
      (selectedOffer: any) => selectedOffer.id === offer.id,
    );

    if (offerExists) {
      setSelectedOffers(
        selectedOffers.filter(
          (selectedOffer: any) => selectedOffer.id !== offer.id,
        ),
      );
    } else {
      setSelectedOffers([...selectedOffers, offer]);
    }
  };

  const handleDownloadOfferFile = () => {
    const storage = getStorage();
    const signedOfferLetterRef = ref(storage, offer?.signedOfferLetterPath);
    if (!!offer?.signedOfferLetterPath === false) {
      return toast.error("Offer letter not found!");
    }
    getDownloadURL(signedOfferLetterRef)
      .then((url) => {
        FileSaver.saveAs(url, offer.signedOfferLetterPath);
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            console.error("File not found. Please check the file path.");
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            console.error("You don't have permission to access this file.");
            break;
          case "storage/canceled":
            // User canceled the download
            console.error("Download was canceled.");
            break;
          case "storage/quota-exceeded":
            // The user's storage quota has been exceeded
            console.error("Your storage quota has been exceeded.");
            break;
          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            console.error("An unknown error occurred. Please try again later.");
            console.error("Server response:", error.serverResponse);
            break;
          default:
            // Handle other errors
            console.error(
              "An error occurred while downloading the file:",
              error,
            );
        }
      });
  };

  return (
    <div className="grid grid-cols-8">
      {/* Justify end works better for centering this vertically, Fontawesome causing it?? */}
      <div className="col-span-1 flex flex-col justify-end items-center ">
        <input
          className="h-5 w-5 shadow text-instant-teams-blue-L1 leading-8 bg-white rounded border-instant-teams-blue-Main outline-none  focus:ring-1"
          id={offer.id}
          name={"checkbox"}
          type="checkbox"
          checked={selectedOffers.some(
            (selectedOffer: any) => selectedOffer.id === offer.id,
          )}
          onChange={() => handleCheckboxChange(offer)}
        />

        <Tooltip position="-top-5 left-1/3" text="Download Offer">
          <FontAwesomeIcon
            onClick={() => {
              handleDownloadOfferFile();
            }}
            className="my-5 text-instant-teams-blue-Main cursor-pointer "
            size="lg"
            icon={faDownload}
          />
        </Tooltip>
      </div>
      <div
        onClick={() => {
          navigate(`/employee/${employeeData?.[0]?.id}/details`);
        }}
        className={
          TailwindHoverEnlarge(103) +
          " cursor-pointer bg-white rounded-lg h-28 shadow-lg col-span-7"
        }
      >
        <div className="items-center w-full rounded-lg relative">
          <div className="items-center bg-instant-teams-teal-Main p-1 rounded-t-lg w-full">
            <div className="w-full text-white pl-2 text-center">
              <p className=" text-sm">{user?.name ?? "N/A"}</p>
            </div>
          </div>
          <div className="grid grid-cols-8  mt-2">
            <div className="grid col-span-2">
              <img
                className="object-scale-down max-h-16 h-16 object-center resize rounded-md mx-auto px-4"
                src={employerData?.logo || "/images/default-employer.jpg"}
                alt={employerData?.displayName}
              />
            </div>
            <div className="grid col-span-3 flex items-center justify-start">
              <p className="text-sm">
                <strong>{employerData?.displayName}</strong>
                <br />
                {jobDescription?.title}
              </p>
            </div>
            <div className="grid col-span-3 flex items-center justify-start">
              <p className="text-sm">
                <strong>Position: </strong>
                {positionDetails?.positionName?.split("|")[0]}
                <br />
                <strong className="pr-1">Pay Rate: </strong>$
                {formatMoney(offer?.payRate)}
                <br />
                <strong>Start Date: </strong>
                {offer?.startDate}
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastAlert />
    </div>
  );
};

export default SignedOffersItem;
