import React, { useState, useEffect } from "react";
import { useUserStatus } from "../../../../data/user";
import { useLookupCode } from "../../../../data/lookup";
import { User } from "../../../../dataTypes/User";
import { TailwindHoverEnlarge } from "../../../../util/tailwind/tailwind-styles";
import useLocalTime from "../../../../hooks/useLocalTime";

const EmployerUserCardFull = ({ user }: { user: User }) => {
  const [role, setRole] = useState("");
  const options = {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  };

  const {
    data: statusData = {},
    status: statusStatus = "",
    error: statusError,
  } = useUserStatus(user.status ?? "");

  const {
    data: roleData = {},
    status: roleStatus = "",
    error: roleError,
  } = useLookupCode(role);

  useEffect(() => {
    setRole(user.claims?.role);
  }, [user, roleData]);

  const colorVariants: any = {
    PRI: "bg-instant-teams-teal-D1",
    GEN: "bg-instant-teams-green-D2",
    BILL: "bg-instant-teams-blue-L2",
  };

  const timestamp = user?.lastLogIn?.toDate();
  const formattedDate = useLocalTime(timestamp, options);
  return (
    <div
      className={
        TailwindHoverEnlarge(103) +
        "flex flex-col w-full max-w-xs align-middle rounded-lg bg-instant-teams-teal-Main  shadow-md mb-2"
      }
    >
      <div className="flex-none w-full pb-4  text-white">
        <div className="flex items-center">
          <img
            className="w-28 h-28  object-cover mx-auto p-4 "
            src={user.photoURL || "/images/default_user.png"}
            alt="avatar"
          />
        </div>
        <div className="w-full text-center uppercase">{user.name}</div>
        <div className="w-full text-center">{user.email}</div>
        <div className="w-full text-center">{statusData.label}</div>
      </div>
      <div className="text-center bg-white rounded-b-lg">
        <div className="w-full text-center pt-4">
          <div className="w-full overflow-hidden whitespace-no-wrap items-center mt-4  pr-4 pl-4">
            <section
              className={`float-left h-8 text-white rounded-md w-full py-1 text-center ${colorVariants[role]} uppercase`}
            >
              {Object.keys(roleData).length > 0 ? roleData[0].label : ""}
            </section>
          </div>
          <div className="py-4">
            {user.address?.state} {user.phone || "No Phone Listed"}
          </div>
          <div className="w-full min-w-full">
            <p className="w-full text-instant-teams-blue-Main font-bold mb-4 text-center">
              LAST LOGIN: {formattedDate ? formattedDate : "Hasn't Logged In"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployerUserCardFull;
