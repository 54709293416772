import { useLookup } from "../../../../data/lookup";
import { useParams } from "react-router";
import { useJobPositionDetails } from "../../../../data/positions";
import GreenUnderlineHeading from "../../../custom-ui/headings/heading";
import { useEmployerLocation } from "../../../../data/employer";
import { getLookupLabel } from "../../../../util/search/getLookupLabel";
import { DisplayDate } from "../../../../util/timestamps/timestamp";
import { isEmpty } from "lodash";
import { getHrsPerWeek } from "../../../../util/helpers/getHours";

function ExternalJobPositionDetails() {
  const notYetSet = "Not Assigned";
  const params = useParams();

  const { id: jobId = "", positionId = "" } = params ?? {};

  const {
    data: positionDetails = {},
    status: positionDetailsQueryStatus,
    error: positionDetailsQueryError,
  } = useJobPositionDetails(jobId, positionId);

  const {
    data: model = [],
    status: modelQueryStatus = "",
    error: modelQueryError,
  } = useLookup("model");

  const {
    data: payType = [],
    status: payTypeQueryStatus = "",
    error: payTypeQueryError,
  } = useLookup("payType");

  const {
    data: term = [],
    status: termQueryStatus = "",
    error: termQueryError,
  } = useLookup("term");

  const {
    data: classification = [],
    status: classificationQueryStatus = "",
    error: classificationQueryError,
  } = useLookup("classification");

  const { data: positionLocation } = useEmployerLocation(
    positionDetails?.employer,
    positionDetails?.information?.locationId,
  );

  const selectedWorkTerm = getLookupLabel(
    positionDetails?.information?.term,
    term,
    "Not Selected",
  );

  const selectedWorkSetting = getLookupLabel(
    positionDetails?.information?.model,
    model,
    "Not Selected",
  );

  const selectedWorkType = getLookupLabel(
    positionDetails?.information?.classification,
    classification,
    "None Selected",
  );

  const payTypeCode = positionDetails?.information?.payType
    ? Object.keys(positionDetails?.information?.payType)[0]
    : "not assigned";

  const selectedPayType = getLookupLabel(payTypeCode, payType, "Not Assigned");

  const payTypeRange =
    positionDetails?.information?.payType?.[
      Object.keys(positionDetails?.information?.payType ?? {})[0]
    ] ?? [];

  const startDate = DisplayDate(positionDetails?.information?.startDate);

  const shiftScheduleDays = positionDetails.shiftSchedule?.days;
  const shiftScheduleRange = positionDetails.shiftSchedule?.range;

  return (
    <>
      <p className="text-2xl text-instant-teams-blue-Main uppercase font-semibold">
        {positionDetails?.positionName}
      </p>
      <p className="text-xl text-instant-teams-blue-Main font-semibold -mt-2 mb-4">
        {positionLocation?.label}
      </p>

      <div className="grid gap-5 md:grid-cols-1 grid-cols-2 place-content-center">
        {positionDetailsQueryStatus === "success" && (
          <>
            <div>
              <GreenUnderlineHeading title="Position Number of hire" />
              <p className="-mt-5">
                {positionDetails.information.hires ?? notYetSet}
              </p>
              <GreenUnderlineHeading title="Work Term" />
              <p className="-mt-5">{selectedWorkTerm}</p>
              <GreenUnderlineHeading title="Shift Option" />
              <p className="-mt-5">
                {positionDetails?.shiftSchedule?.shiftOption?.label ??
                  notYetSet}
              </p>
              <GreenUnderlineHeading title="Work Setting" />
              <p className="-mt-5">{selectedWorkSetting}</p>
              <GreenUnderlineHeading title="Work Type" />

              <p className="-mt-5">{selectedWorkType}</p>
            </div>
            <div>
              <GreenUnderlineHeading title="Start Date" />
              <p className="-mt-5">{startDate ?? "not yet set"}</p>
              <GreenUnderlineHeading title="Hours per week" />
              <p className="-mt-5">
                {" "}
                {getHrsPerWeek({
                  days: shiftScheduleDays,
                  range: shiftScheduleRange,
                })}
              </p>
              <GreenUnderlineHeading title="Pay Type" />
              <p className="-mt-5">{selectedPayType}</p>
              <GreenUnderlineHeading title="Pay Rate" />
              <p className="-mt-5">
                {!isEmpty(payTypeRange) && payTypeRange.length === 2
                  ? `$${payTypeRange[0]} - $${payTypeRange[1]}`
                  : "Not Assigned"}
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ExternalJobPositionDetails;
