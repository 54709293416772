import {
  addApplicationSync,
  getPreScreenedValue,
  updateAnswer,
  useApplication,
  useApplicationPreScreen,
} from "../../data/applications";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import WedgeSpinner from "../../components/spinners/wedges-spinner";
import { useLookup } from "../../data/lookup";
import { toUSCurrency } from "../../util/helpers/toUSCurrency";
import { getLookupLabel } from "../../util/search/getLookupLabel";
import { useEmployer } from "../../data/employer";
import { useJobPositionDetails } from "../../data/positions";
import { useJobDescription } from "../../data/jobDescriptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Meta from "../../components/custom-ui/meta/Meta";
import { requireAuth } from "../../providers/auth";
import { useEmployerData } from "../../hooks/useApplicationEmployerData";
import ButtonWithLoading from "../../components/custom-ui/button/ButtonWithLoading";
interface PreScreenData {
  question: string;
  order: number;
  data?: { code: string; icon: any; label: string }[] | null;
  id: string;
}

function SeekerPreScreenPage() {
  const { applicationId = "" } = useParams();
  const navigate = useNavigate();
  const {
    data: preScreenData,
    isLoading: preScreenIsLoading,
    isError: preScreenIsError,
  } = useApplicationPreScreen(applicationId);

  const {
    data: applicationData,
    isLoading: applicationIsLoading,
    isError: applicationIsError,
  } = useApplication(applicationId);

  const { data: jobData, isLoading: isJobDataLoading } = useJobDescription(
    applicationData?.jobId,
  );
  const { data: positionData, isLoading: isPositionDataLoading } =
    useJobPositionDetails(applicationData?.jobId, applicationData?.positionId);
  const { employerId, employerData } = useEmployerData({
    jobData,
    originalEmployerId: applicationData?.employer,
  });

  const [answers, setAnswers] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    if (!preScreenData || answers.length === preScreenData.length) return;
    const initialAnswers = preScreenData.map(
      ({ order, id }: { order: any; id: any }) => {
        return {
          id,
          order,
        };
      },
    );

    setAnswers(initialAnswers);
  }, [preScreenData]);

  const onSelect = (answerValue: "true" | "false", order: number) => {
    setAnswers((prevAnswers: any) => {
      const answerIndex = prevAnswers.findIndex(
        (answer: any) => answer.order === order,
      );
      if (answerIndex === -1) return prevAnswers;
      const updatedAnswer = {
        ...prevAnswers[answerIndex],
        answer: answerValue,
      };
      const newAnswers = [...prevAnswers];
      newAnswers[answerIndex] = updatedAnswer;
      return newAnswers;
    });
  };

  const checkIfPreScreenedAnswersAreValidated = () => {
    if (!applicationId) return;
    const intervalId = setInterval(async () => {
      const preScreened = await getPreScreenedValue(applicationId);
      if (preScreened != null) {
        clearInterval(intervalId);
        setSubmitting(false);
        await addApplicationSync(applicationId);
        if (preScreened === true) {
          navigate(`/application/${applicationId}`);
        } else if (preScreened === false) {
          setFailed(true);
        }
      }
    }, 500);
  };
  useEffect(() => {
    checkIfPreScreenedAnswersAreValidated();
    return () => {};
  }, []);
  const onAnswer = async () => {
    if (!applicationId) return;
    setSubmitting(true);
    await Promise.all(
      answers.map(async ({ answer, id }: { answer: any; id: any }) => {
        await updateAnswer(applicationId, id, answer === "true");
      }),
    );
    checkIfPreScreenedAnswersAreValidated();
  };

  const {
    data: classification = [],
    status: classificationQueryStatus = "",
    error: classificationQueryError,
  } = useLookup("classification");
  const {
    data: model = [],
    status: modelQueryStatus = "",
    error: modelQueryError,
  } = useLookup("model");

  const getSalary = (payType: any) => {
    const pay = payType?.HRLY ?? payType?.SAL;
    if (!pay) return undefined;

    const type = payType?.HRLY ? "Hourly" : "Salary";
    return `${type}: ${toUSCurrency(pay[0])} - ${toUSCurrency(pay[1])}`;
  };

  const salary = useMemo(
    () => getSalary(positionData?.information?.payType),
    [positionData?.information?.payType],
  );

  const selectedWorkSetting = (posModel: string) =>
    getLookupLabel(posModel, model, "Not Selected");

  const selectedWorkType = (posClassification: string) =>
    getLookupLabel(posClassification, classification, "None Selected");

  if (preScreenIsLoading || applicationIsLoading) return <WedgeSpinner />;
  if (
    preScreenIsError ||
    applicationIsError ||
    !preScreenData ||
    !applicationData
  )
    return <>Error</>;
  if (applicationData.status === "knockout") {
    return (
      <>
        {!isPositionDataLoading && !isJobDataLoading && (
          <Meta title={`${jobData.title}, ${positionData.positionName}`} />
        )}
        <div className="w-3/4 mx-auto pt-40 pb-16 grid grid-cols-8">
          <Link to={`/companies/${employerData?.id}`}>
            <img
              className="my-auto col-span-1 w-24 h-24 object-contain  "
              src={employerData?.logo}
              alt={employerData?.displayName}
            />
          </Link>
          <div className="pl-5 col-span-4 my-auto  text-instant-teams-blue-Main justify-start md:text-sm">
            <Link to={`/companies/${employerData?.id}`}>
              <div className="hover:underline">{employerData?.displayName}</div>
            </Link>
            <div className="font-semibold uppercase">{jobData?.title}</div>
            <div>{positionData?.positionName ?? "empty"}</div>
            <div className="font-semibold">
              {salary} |
              {selectedWorkType(positionData?.information?.classification)} |{" "}
              {selectedWorkSetting(positionData?.information?.model)}
            </div>
          </div>
        </div>
        <div className="w-1/2 mx-auto space-y-5 text-instant-teams-blue-Main">
          <p className="mx-auto text-center">
            Thank you for your interest in this position.{" "}
          </p>
          <p className="mx-auto text-center">
            {" "}
            Unfortunately at this time you do not meet the requirements to
            continue forward with the application.
          </p>
          <div className=" w-72 mx-auto">
            <button
              className="w-full py-3 text-white rounded-md uppercase bg-instant-teams-blue-Main hover:bg-instant-teams-blue-D1"
              onClick={() => navigate("/jobs")}
            >
              Return To Opportunities
            </button>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      {!isPositionDataLoading && !isJobDataLoading && (
        <Meta title={`${jobData.title}, ${positionData.positionName}`} />
      )}
      <div className="w-3/4 mx-auto pt-40 pb-16 grid grid-cols-8">
        <Link to={`/companies/${employerData?.id}`}>
          <img
            className="my-auto col-span-1 w-24 h-24 object-contain  "
            src={employerData?.logo}
            alt={employerData?.displayName}
          />
        </Link>
        <div className="pl-5 col-span-4 my-auto  text-instant-teams-blue-Main justify-start md:text-sm">
          <Link to={`/companies/${employerData?.id}`}>
            <div className="hover:underline">{employerData?.displayName}</div>
          </Link>
          <div className="font-semibold uppercase">{jobData?.title}</div>
          <div>{positionData?.positionName ?? "empty"}</div>
          <div className="font-semibold">
            {salary} |
            {selectedWorkType(positionData?.information?.classification)} |{" "}
            {selectedWorkSetting(positionData?.information?.model)}
          </div>
        </div>
      </div>

      <div className="w-3/4 mx-auto pb-16">
        {preScreenData &&
          preScreenData
            .sort((a: PreScreenData, b: PreScreenData) => a.order - b.order)
            .map(({ question, order, data: extraData, id }: PreScreenData) => {
              const answer = answers.find((a: any) => a.id === id)?.answer;
              return (
                <div key={id} className="my-5">
                  <p>{question}</p>
                  {extraData && extraData.length > 0 && (
                    <div className="flex self-start flex-wrap mt-2 ">
                      {extraData.map(({ code, icon, label }) => (
                        <div
                          key={`${code}-${label}`}
                          className="border-instant-teams-blue-Main mx-1 my-2 border h-8 text-sm rounded-xl px-1 flex items-center"
                        >
                          <FontAwesomeIcon
                            icon={icon}
                            className="text-instant-teams-blue-Main pr-2"
                          />
                          <p
                            className=" text-instant-teams-blue-Main"
                            key={code}
                          >
                            {label}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}

                  <>
                    <input
                      className="h-5 w-5 border-gray-300 focus:ring-2 focus:ring-blue-300"
                      id={`yes_${id}`}
                      name={question ?? "radioGroup"}
                      type="radio"
                      value="true"
                      onChange={(e) =>
                        onSelect(e.target.value as "true" | "false", order)
                      }
                      checked={answer === "true"}
                    />
                    <label
                      className="mb-1 px-2 text-sm font-semibold text-instant-teams-blue-Main"
                      htmlFor={`yes_${id}`}
                    >
                      Yes
                    </label>

                    <input
                      className="h-5 w-5 border-gray-300 focus:ring-2 focus:ring-blue-300"
                      id={`no_${id}`}
                      name={question ?? "radioGroup"}
                      type="radio"
                      value="false"
                      onChange={(e) =>
                        onSelect(e.target.value as "true" | "false", order)
                      }
                      checked={answer === "false"}
                    />
                    <label
                      className="mb-1 px-2 text-sm font-semibold text-instant-teams-blue-Main"
                      htmlFor={`no_${id}`}
                    >
                      No
                    </label>
                  </>
                </div>
              );
            })}
        <div className="w-60 mx-auto my-5">
          <ButtonWithLoading
            pending={submitting}
            onClick={onAnswer}
            value="Submit"
            disabled={
              submitting ||
              answers.some(({ answer }: any) => answer === undefined)
            }
          />
        </div>
      </div>
    </>
  );
}
export default requireAuth(SeekerPreScreenPage);
