import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  getFirestore,
  connectFirestoreEmulator,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  limit,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { db, client, createQuery, format } from "../providers/database";
import { Invitation } from "../dataTypes/Invitation";
import { collectionNames } from "./dictionary/collectionNames";
import { errorLogger } from "../providers/clientLogger";
/**** INVITATIONS ****/
// Employer User Invitation expires in days
const daysToExpire =
  process.env.REACT_APP_EMPLOYER_INVITATION_EXPIRATION_IN_DAYS ?? "7";

const expirationDate = new Date();
expirationDate.setDate(expirationDate.getDate() + parseInt(daysToExpire));

// Check if email exists
export async function doesEmailExist(email: string) {
  const _query = query(
    collection(db, collectionNames.users),
    where(collectionNames.emails, "==", email.toLowerCase()),
    limit(1),
  );
  const foundEmail = await getDocs(_query);
  return !!format(foundEmail).length;
}

// check for unexpired invitations
export async function doesUnexpiredInvitationExist(email: string) {
  const _query = query(
    collection(db, collectionNames.invitations),
    where(collectionNames.emails, "==", email.toLowerCase()),
    where("status", "!=", "expired"),
    limit(1),
  );
  const foundUnexpiredInvitation = await getDocs(_query);
  return !!format(foundUnexpiredInvitation).length;
}

export function useInvitation(invitationId: string) {
  return useQuery(
    ["invitation", { invitationId }],
    createQuery(() => doc(db, collectionNames.invitations, invitationId)),
    { enabled: !!invitationId },
  );
}

export function useInvitationByEmployer(employerId: string) {
  return useQuery(
    ["invitationByEmployer", { employerId }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.invitations),
        where("employerId", "==", employerId),
      );
    }),
    { enabled: !!employerId },
  );
}

export function useActiveInvitationByEmployer(employerId: string) {
  return useQuery(
    ["invitationByEmployer", { employerId }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.invitations),
        where("employerId", "==", employerId),
        where("status", "!=", "complete"),
      );
    }),
    { enabled: !!employerId },
  );
}

// Create a new invitation
export async function createInvitation(data: Invitation) {
  const { email } = data;
  try {
    const emailExists = await doesEmailExist(email);
    const unexpiredInviteExists = await doesUnexpiredInvitationExist(email);
    if (!emailExists && !unexpiredInviteExists) {
      return addDoc(collection(db, collectionNames.invitations), {
        ...data,
        email: email.toLowerCase(),
        createdAt: serverTimestamp(),
        expires: Timestamp.fromDate(expirationDate),
      });
    } else if (emailExists) {
      const error = new Error("This user may already exist.");
      errorLogger(error.message, {
        component: "InvitationForm createInvitation()",
        flow: "Create Employer Invitation",
        userId: "n/a",
      });
      throw error;
    } else if (unexpiredInviteExists) {
      const error = new Error(
        `There is an unexpired invitation that has already been sent to ${email}.`,
      );
      errorLogger(error.message, {
        component: "InvitationForm createInvitation()",
        flow: "Create Employer Invitation",
        userId: "n/a",
      });
      throw error;
    }
  } catch (error: any) {
    errorLogger(error.message, {
      component: "InvitationForm createInvitation()",
      flow: "Create Employer Invitation",
      userId: "n/a",
    });
    throw error;
  }
}
