import { useEffect, useState } from "react";
import { useApplicationPositions } from '../../../../data/applications';
import SeekerPositionListItem from './seekerPositionListItem';

function SeekerPositionList({ applicationId, jobId }:{ applicationId: string, jobId: string }) {
  const { data: positions } = useApplicationPositions(applicationId);
  return (
    <>
    {positions && positions.length > 0 && positions.map((position: any) => {
        if(position.active === true){
         return <SeekerPositionListItem key={position.id} positionId={position.id} jobId={jobId}/>
        }
      })
    }
    </>
  )
}

export default SeekerPositionList;
