import { useState, useRef } from "react";
import { useQueryClient } from "react-query";
import { usePreOfferApplications } from "../../../../data/applications";
import {
  usePendingJobOffers,
  useSignedOfferJobOffers,
  processFilters,
} from "../../../../data/offers";

import OffersFilterCheckbox from "./OffersFilterCheckbox";

const OffersFilter = ({ processOffers }: { processOffers: any }) => {
  const [show, toggleShow] = useState(false);
  const filterJobIds = useRef([]);
  const queryClient = useQueryClient();

  const { data: preOffers = [] } =
    usePreOfferApplications("preOffersForFilter");
  const { data: pendingOffers = [] } = usePendingJobOffers(
    "pendingOffersForFilter",
  );
  const { data: signedOffers = [] } = useSignedOfferJobOffers(
    "signedOffersForFilter",
  );

  const toArray = (object: any) => {
    console.log("toArray: ", object);
    const ret = [];
    for (const key in object) {
      ret.push(key);
    }

    return ret;
  };

  const getUniqueJobIds = (offers: [any, any, any]) => {
    const ret: any = {};
    offers.forEach((offersList: []) => {
      offersList.forEach((offer: any) => {
        const jobId = offer?.jobId;
        if (!Object.hasOwn(ret, jobId)) {
          ret[jobId] = "";
        }
      });
    });
    const res: string[] = toArray(ret);
    return res;
  };

  const preOffersProcessed = processOffers(preOffers, "preOffer");
  const pendingOffersProcessed = processOffers(pendingOffers, "pending");
  const signedOffersProcessed = processOffers(signedOffers, "signed");
  const offersForJobIdsExtraction: [any, any, any] = [
    preOffersProcessed,
    pendingOffersProcessed,
    signedOffersProcessed,
  ];
  const uniqueJobIds = getUniqueJobIds(offersForJobIdsExtraction);

  return (
    <div>
      <span
        onClick={() => {
          console.log("OffersDash, Filter, click");
          filterJobIds.current = [];
          toggleShow(!show);
        }}
        className={`cursor-pointer flex justify-around px-2 py-1 text-xs font-bold leading-none text-white ${
          show ? "bg-instant-teams-teal-Main" : " bg-instant-teams-blue-Main"
        }  rounded-full w-24`}
      >
        <p className="w-fit select-none">{"Filter"}</p>
        <i className="fa-solid fa-filter text-white"></i>
      </span>
      <div className={`group relative grid ${show ? "" : "hidden"}`}>
        <div
          className={`absolute -translate-x-1/2 -left-24 whitespace-nowrap drop-shadow-[0_0px_10px_rgba(0,0,0,0.25)] rounded bg-white px-2 py-1 text-black transition before:absolute before:-top-4 before:right-6 before:-translate-x-1/2 before:border-8 before:border-transparent before:border-b-white before:content-[''] w-80 z-40 mt-2`}
        >
          <div className="grid grid-cols-4 gap-x-4 text-sm">
            <>
              {uniqueJobIds?.map((jobId: string) => {
                return (
                  <OffersFilterCheckbox
                    jobId={jobId}
                    filterJobIds={filterJobIds}
                    key={jobId}
                  />
                );
              })}
            </>
          </div>
          <div className="grid grid-rows-1 grid-flow-col gap-4 justify-center">
            <button
              onClick={async () => {
                console.log("OffersDash, Filter, filters: ", filterJobIds);
                filterJobIds.current = [];
                await processFilters(queryClient, filterJobIds);
                toggleShow(!show);
              }}
              className="bg-instant-teams-blue-Main text-white hover:bg-instant-teams-teal-Main w-16 rounded-lg h-6 mt-3 mb-3 select-none"
            >
              Clear
            </button>
            <button
              onClick={async () => {
                console.log("OffersDash, Filter, filters: ", filterJobIds);

                await processFilters(queryClient, filterJobIds);
                toggleShow(!show);
              }}
              className="bg-instant-teams-blue-Main text-white hover:bg-instant-teams-teal-Main w-16 rounded-lg h-6 mt-3 mb-3 select-none"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OffersFilter;
