import { memo, forwardRef, useEffect } from "react";
import { useUser } from "../../providers/database";
import { Link } from "react-router-dom";
import {
  useResume,
  useAllActiveApplications,
  useAllActiveOffers,
  useAllActiveSkills,
} from "../../data/seeker";
import Tooltip from "../custom-ui/tooltip/tooltip";
import { DisplayDate } from "../../util/timestamps/timestamp";
import PhoneNumber from "../custom-ui/input-fields/phoneNumber";
import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";

const SeekerCardComponent = forwardRef(({ hit }: { hit: any }, ref: any) => {
  const { data: userData } = useUser(hit?.hit?.objectID);
  const { data: seekerData } = useResume(hit?.hit?.objectID);
  const { data: applicationsData } = useAllActiveApplications(
    hit?.hit?.objectID,
  );
  const { data: offersData } = useAllActiveOffers(hit?.hit?.objectID);
  const { data: skillsData } = useAllActiveSkills(hit?.hit?.email);
  const emailParts = hit?.hit?.email?.split("@");

  return (
    <Link
      to={`/seekers/${userData?.id}`}
      className="cursor-pointer rounded-lg h-full shadow-lg bg-white"
    >
      <div
        ref={ref}
        className={
          TailwindHoverEnlarge() +
          "items-center text-instant-teams-teal-Main p-5 rounded-lg w-full border-2 shadow-lg"
        }
      >
        <div className="w-full flex mb-2 items-center">
          <img
            src={
              userData?.photoURL
                ? userData?.photoURL
                : "/images/default_user.png"
            }
            className={`rounded-full h-12 w-12 border-solid border-4 mx-auto ${
              userData?.status !== "active"
                ? " border-instant-teams-red-Main"
                : "border-instant-teams-green-D1"
            }`}
            alt={`${hit?.hit?.name?.toUpperCase()}`}
          />
        </div>
        <div className="w-full mb-2 text-instant-teams-teal-Main pl-2 text-center">
          <p>{`${userData?.name?.toUpperCase()}`}</p>
          {emailParts && (
            <>
              <p className=" text-sm break-all">{emailParts[0]}</p>
              <p className=" text-sm">@{emailParts[1]}</p>
            </>
          )}
          <PhoneNumber number={userData?.phone || ""} />
          <p className="text-sm">
            Since{" "}
            {userData?.createdAt ? DisplayDate(userData.createdAt) : "Unknown"}
          </p>
        </div>
        <div className="items-center w-full px-4 mt-5">
          <div className="grid grid-cols-3 space-x-2 items-center mx-auto text-center">
            <Tooltip text="Applications">
              <div className="rounded-full bg-instant-teams-tags-A1 text-white px-1">
                <span className="text-instant-teams-tags-A2">
                  {applicationsData ? applicationsData.length : 0}
                </span>
              </div>
            </Tooltip>
            <Tooltip text="Offers">
              <div className="rounded-full bg-instant-teams-tags-B1 text-white px-1">
                <span className="text-instant-teams-tags-B2">
                  {offersData ? offersData.length : 0}
                </span>
              </div>
            </Tooltip>
            <Tooltip text="Skills">
              <div className="rounded-full bg-instant-teams-tags-C1 text-white px-1">
                <span className="text-instant-teams-tags-C2">
                  {skillsData ? skillsData.length : 0}
                </span>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </Link>
  );
});

export default memo(SeekerCardComponent);
