import { useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/pro-regular-svg-icons";
import {
  useDropzone,
  DropzoneOptions,
  FileRejection,
  DropEvent,
} from "react-dropzone";
import SuccessCircleCheck from "../success/SuccessCircleCheck";
import { upload } from "../../../storage/storage";
import { writeResume } from "../../../data/seeker";
import { friendlyFirebaseError } from "../../../providers/firebase-errors/friendlyFirebaseError";
import { useAuth } from "../../../providers/auth";
import folderNames from "../../../storage/dictionary/folderNames";
import ToastAlert from "../toast-alert/ToastAlert";
import toast from "react-hot-toast";
import WedgeSpinner from "../../spinners/wedges-spinner";

function DragAndDropFileAutoUpload({}: {}) {
  const auth = useAuth();

  const [formAlert, setFormAlert] = useState(null);

  // onDragEnter, onDragLeave, onDragOver, onDrop
  const [eventState, setEventState] = useState();
  const [fileName, setFileName] = useState();
  const [isUploading, setIsUploading] = useState(false);

  const dragDropEvents = {
    onDragEnter: "onDragEnter",
    onDragLeave: "onDragLeave",
    onDragOver: "onDragOver",
    onDrop: "onDrop",
    onDropAccepted: "onDropAccepted",
  };

  const confirmUpload = (file: any) => {
    upload(file, folderNames.resume, `${auth?.user?.uid}_${file?.name}`).then(
      (fileUrl) => {
        writeResume(auth?.user?.uid, {
          label: file?.name,
          type: file?.type,
          link: fileUrl,
        })
          .then((docRef) => {
            setIsUploading(false);
            toast.success("Resume uploaded successfully!");
          })
          .catch((error) => {
            setIsUploading(false);
            setFormAlert({
              type: "error",
              message: friendlyFirebaseError(error),
            });
          })
          .catch((error) => {});
      },
    );
  };

  const dropEventStyles = () => {
    if (eventState === dragDropEvents.onDragEnter)
      return `bg-instant-teams-green-L2`;
    if (eventState === "onDragLeave") return;
    if (eventState === "onDragOver") return `bg-instant-teams-green-L2`;
    if (eventState === "onDrop") return;
  };

  const isDropAccepted = eventState === dragDropEvents.onDropAccepted;

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length < 1) {
      return;
    }
    setIsUploading(true);
    setEventState(dragDropEvents.onDrop);
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      const { size } = file;
      // add a files size limit
      // filename used in the ready message only
      setFileName(file.name);

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        confirmUpload(file);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      onDrop,
      accept: {
        "application/pdf": [".pdf"],
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
      },
      multiple: false,
      maxFiles: 1,
      onDragEnter: () => {
        setEventState(dragDropEvents.onDragEnter);
        console.log("entering dropzone");
      },
      onDragLeave: () => {
        setEventState(dragDropEvents.onDragLeave);
        console.log("leaving dropzone");
      },
      onDragOver: () => {
        setEventState(dragDropEvents.onDragOver);
        console.log("over dropzone");
      },
      onDropAccepted: () => {
        setEventState(dragDropEvents.onDropAccepted);
      },
    });
  if (isUploading) {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <WedgeSpinner />
      </div>
    );
  }
  return (
    <>
      <section>
        <div {...getRootProps()}>
          <input {...getInputProps()} type="file" />
          <div
            className={`p-1 cursor-pointer shadow-md bg-white border divide-x-4 border-instant-teams-teal-Main border-dashed rounded transition-colors ${dropEventStyles()}`}
          >
            <>
              {" "}
              <FontAwesomeIcon
                className="p-5 rounded text-instant-teams-teal-Main text-base"
                icon={faUpload}
                size="xl"
              />
              <p className="text-instant-teams-blue-Main text-base">
                Drag & Drop or Browse to Upload
              </p>
            </>
          </div>
        </div>
      </section>
      <ToastAlert />
    </>
  );
}

export default DragAndDropFileAutoUpload;
