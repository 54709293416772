export function splitCapitalize(text, splitKey) {
  const parts = text.split(splitKey);
  const capitalizedParts = parts.map(
    (part) => part.charAt(0).toUpperCase() + part.slice(1),
  );
  return capitalizedParts.join(" ");
}

export function capitalize(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function camel(text) {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function firstNameOnly(name) {
  if (!name) return ""; // return empty string if input is empty
  const nameParts = name.split(" ");
  const firstName = nameParts[0];

  return firstName;
}



export const sortedCommaSeparatedString = (array) => {
  if (Array.isArray(array)) {
    const sortedArray = array.sort();

    return sortedArray.length > 0 ? sortedArray.join(", ") : "None";
  } else {
    return "None";
  }
};

export const formatFileName = (fileName) => {
  // Remove characters that are not alphanumeric, underscores, or hyphens
  const sanitizedString = fileName.replace(/[^\w\-]+/g, "");

  return sanitizedString;
};
