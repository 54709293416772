import Meta from "../../../components/custom-ui/meta/Meta";
import EmployerUserManagement from "../../../components/employer/details/pages/EmployerUserManagement";

function ExternalUserManagemet() {
  return (
    <>
      <Meta title="Users" />
      <div className="pt-36 px-12">
        <EmployerUserManagement />
      </div>
    </>
  );
}

export default ExternalUserManagemet;
