import React, { useState } from "react";
import { useAuth } from "../../../../providers/auth";
import { Employer } from "../../../../dataTypes/Employer";
import { useForm } from "react-hook-form";
import { updateEmployer } from "../../../../data/employer";
import ForwardArrowButton from "../../../../components/custom-ui/button/ForwardArrowButton";
import { errorLogger } from "../../../../providers/clientLogger";

// Company/Employer
function CompanyInfoForm({
  employer,
  onDone,
}: {
  employer: Employer;
  onDone: Function;
}) {
  const { user } = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const { id, legalName, displayName, taxId, domain } = employer ?? {};

  const { handleSubmit, register, errors } = useForm({
    defaultValues: {
      legalName,
      displayName,
      taxId,
      domain,
    },
  });

  const onSubmit = (data: Employer) => {
    setPending(true);
    updateEmployer(id, data)
      .then(() => {
        onDone();
      })
      .catch((error) => {
        setFormAlert(error.message);
        errorLogger(error.message, {
          component: "CompanyInfoForm updateEmployer()",
          flow: "Update Employer",
          userId: user.id,
        });
      })
      .finally(() => setPending(false));
  };
  return (
    <>
      <h2 className="mb-4 uppercase text-left font-semibold text-instant-teams-blue-Main">
        COMPANY INFORMATION
      </h2>
      {formAlert && (
        <div className="mb-4 text-red-600">{formAlert.message}</div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2">
          <label
            htmlFor="legalName"
            className="py-1 px-3 uppercase block text-left font-semibold text-instant-teams-blue-Main"
          >
            Legal Name
          </label>
          <input
            id="legalName"
            className="py-1 px-3 w-full leading-8  rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            name="legalName"
            type="text"
            placeholder="Legal Name"
            ref={register({
              required: "Please enter your company's legal name.",
            })}
          />

          {errors.legalName && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.legalName.message}
            </p>
          )}
        </div>
        <div className="mb-2">
          <label
            htmlFor="displayName"
            className="py-1 px-3 uppercase block text-left font-semibold text-instant-teams-blue-Main"
          >
            DBA Name
          </label>
          <input
            id="displayName"
            className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            name="displayName"
            type="text"
            placeholder="DBA Name"
            ref={register({
              required: "Please enter your company's dba name.",
            })}
          />

          {errors.displayName && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.displayName.message}
            </p>
          )}
        </div>
        <div className="mb-2">
          <label
            htmlFor="taxId"
            className="py-1 px-3 uppercase block text-left font-semibold text-instant-teams-blue-Main"
          >
            Tax ID
          </label>
          <input
            id="taxId"
            className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            name="taxId"
            type="text"
            placeholder="Enter Tax ID"
            ref={register()}
          />
          {errors.taxId && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.taxId.message}
            </p>
          )}
        </div>
        <div className="mb-2">
          <label
            htmlFor="domain"
            className="py-1 px-3 uppercase block text-left font-semibold text-instant-teams-blue-Main"
          >
            Domain
          </label>
          <input
            id="domain"
            className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            name="domain"
            type="text"
            placeholder="domain"
            ref={register({
              required: "Please enter a company domain.",
            })}
          />
          {errors.domain && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.domain.message}
            </p>
          )}
        </div>
        <ForwardArrowButton pending={pending} type={"submit"} />
      </form>
    </>
  );
}

export default CompanyInfoForm;
