import { TailwindHoverEnlarge } from "../../../util/tailwind/tailwind-styles";
import { getLevel } from '../../../util/assessments/level';
import DisplayBadges from '../../custom-ui/badge/displayBadge';
const SkillsCard = ({
  row,
  assessmentDetails,
  setAssessmentId,
}: {
  row: any;
  assessmentDetails: any;
  setAssessmentId: Function;
}) => {
  return (
    <div
      id={row?.objectID}
      key={row?.objectID}
      className={
        TailwindHoverEnlarge(103) +
        " bg-white shadow-lg text-instant-teams-teal-Main rounded-lg py-2 px-2 h-full cursor-pointer"
      }
      onClick={() => {
        if (!assessmentDetails) {
          setAssessmentId(row?.objectID);
        }
      }}
    >
      <DisplayBadges assessmentId={row?.objectID}/>
      <p className="text-center font-bold w-full text-sm">
        {row?.displayName || row?.name}
      </p>
      <p className="text-center font-bold w-full text-xs">
        {row?.duration || "??"} minutes
      </p>
    </div>
  );
};

export default SkillsCard;
