import { useEffect, useState } from "react";
import { multiFactor } from "firebase/auth";
import { useAuth } from "../../../providers/auth";
import PhoneNumber from "../../custom-ui/input-fields/phoneNumber";
import EnrolledFactors from "./EnrolledFactors";
import MultiFactorEnrollment from "./MultiFactorEnrollment";
import { friendlyFirebaseError } from "../../../providers/firebase-errors/friendlyFirebaseError";
import { errorLogger } from "../../../providers/clientLogger";

function SettingsMFA({
  onStatus,
  buttonVariant,
  showChangeNumberButton = true,
}: {
  onStatus: Function;
  buttonVariant?: string;
  showChangeNumberButton?: boolean;
}) {
  const auth = useAuth();
  const { user } = auth;

  const [triggerRefreshUser, setTriggerRefreshUser] = useState(true);
  const toggleTrigger = () =>
    setTriggerRefreshUser((prevState: boolean) => !prevState);

  const [multiFactorUser, setMultiFactorUser] = useState();

  useEffect(() => {
    setMultiFactorUser(multiFactor(user?.auth?.currentUser));
  }, [triggerRefreshUser, user]);

  const { enrolledFactors = [] } = multiFactorUser ?? {};
  const enrolledPhoneNumber = enrolledFactors?.[0]?.phoneNumber ?? "";

  const unenroll = () => {
    multiFactorUser
      .unenroll(enrolledFactors[0])
      .then(() => {
        toggleTrigger();
      })
      .catch((error: any) => {
        onStatus({
          type: "error",
          code: error.code,
          callback: () => {},
          message: `${friendlyFirebaseError(error)}`,
        });
      });
  };

  return (
    <>
      {enrolledFactors[0] && (
        <>
          <div className="uppercase mx-auto center-items mb-4">
            {enrolledFactors?.[0]?.displayName}
            {/* Formats and styles phone number */}
            <PhoneNumber number={enrolledPhoneNumber} />
          </div>
          {showChangeNumberButton && (
            <button
              onClick={unenroll}
              className="py-2 px-4 w-full text-xl uppercase  justify-center mx-auto text-white bg-instant-teams-blue-Main rounded-lg border-0 hover:bg-instant-teams-blue-D1 focus:outline-none"
            >
              Change Number
            </button>
          )}
        </>
      )}
      {!enrolledFactors[0] && (
        <>
          {/* <span className="flex justify-center text-xl">
              Enroll A Mobile Number
            </span> */}
          <div className="mt-4 flex items-center">
            <MultiFactorEnrollment
              onStatus={onStatus}
              callback={toggleTrigger}
              buttonVariant={buttonVariant}
            />
          </div>
        </>
      )}
    </>
  );
}

export default SettingsMFA;
