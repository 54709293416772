import Tooltip from "../../custom-ui/tooltip/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDoorClosed,
  faCabinetFiling,
} from "@fortawesome/pro-duotone-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useComponent } from "../../../data/academy";
import { useAccelerator } from '../../../data/accelerators';
import AutoSaveStatusSelector from "../../custom-ui/input-fields/statusSelector";
import { collectionNames } from '../../../data/dictionary/collectionNames';
import { getLevel } from '../../../util/assessments/level';

function AcceleratorSidebarHeader({}) {
  const navigate = useNavigate();
  const params = useParams();
  const [acceleratorId, setAcceleratorId] = useState(params.acceleratorId || "");
  const { data: accel } = useAccelerator(acceleratorId);

  return (
    <div className="mb-4">
      <div className="flex items-center">
        <div className="flex object-contain mx-auto">
          <img
            className="h-32 w-32 mb-6 rounded-full object-contain"
            src={accel?.badge || "/images/badge.png"}
            alt="logo"
          />
        </div>
      </div>
      <div className="text-center text-lg text-white pb-1 -mt-6">
        {accel?.name}
      </div>
      <div className="pt-2 pl-2 pr-2">
        <AutoSaveStatusSelector
          status={accel?.status}
          collection={collectionNames.accelerators}
          recId={acceleratorId}
          required={false}
          name="status"
          display="Status"
          placeholder=""
          directions=""
        />
      </div>
    </div>
  );
}

export default AcceleratorSidebarHeader;
