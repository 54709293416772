import React from "react";
import Auth from "./Auth";
import { Link } from "react-router-dom";
import { optionsByType } from "./signInOptions";
import NotFoundPage from "../../pages/404";

function AuthSection(props) {
  // Ensure we have a valid auth type
  const type = optionsByType[props.type] ? props.type : null;

  // Get options object for current auth type
  const options = optionsByType[type];

  return (
    <div className="pt-28">
      {type !== null ? (
        <div className="flex min-h-screen bg-white overflow-hidden">
          <div className="w-1/2 xs:w-full sm:w-full flex flex-col items-center md:w-1/2  justify-center pb-80">
            <h1 className="mt-12 text-2xl  font-bold pb-8 text-instant-teams-teal-Main">
              Employer {options.title}
            </h1>
            <Auth
              type={type}
              buttonAction={options.buttonAction}
              providers={props.providers}
              afterAuthPath={props.afterAuthPath}
              key={type}
            />
            {options.title === "Login" && (
              <Link to="/auth/forgotpass" className="underline text-gray-500">
                {"Forgot Password?"}
              </Link>
            )}
          </div>
          <div className="w-1/2 min-h-screen right-0 fixed overflow-hidden sm:hidden xs:hidden">
            <img
              className="absolute top-34 -right-10 object-cover w-full min-h-screen"
              src="/images/login_hero.jpg"
              alt="Welcome to Instant Teams"
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col md:flex-row items-center justify-center min-h-screen bg-white overflow-hidden">
          <NotFoundPage />
        </div>
      )}
    </div>
  );
}

export default AuthSection;
