import { useState, useEffect } from "react";

// for details on the options param that gets passed in refer to documentation here https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat

function useLocalTime(timestamp: number | string  , options: any): string {
  const [formattedDate, setFormattedDate] = useState("");

  // Call `useEffect` to handle side effects and update the `formattedDate` state when the `timestamp` or `options` change
  useEffect(() => {
    // If the `timestamp` is `null`, do nothing and return early
    if (timestamp === null || timestamp === undefined ) {
      return;
    } else {
      let timestampAsNumber: number;

      // Check the type of the `timestamp` argument
      if (typeof timestamp === "string") {
        // If the `timestamp` is a string, parse it as a number
        timestampAsNumber = parseInt(timestamp, 10);
      } else {
        // If the `timestamp` is a number, assign it directly
        timestampAsNumber = timestamp;
      }
      // Create a new `Date` object from the `timestamp`
      const date = new Date(timestampAsNumber);
      // Use the `Intl.DateTimeFormat` object to format the `date` into a string using the specified `options`
      const formatted = new Intl.DateTimeFormat(undefined, options).format(
        date,
      );
      // Update the `formattedDate` state with the formatted date string
      setFormattedDate(formatted);
    }
  }, [timestamp, options]);

  // Return the `formattedDate` state
  return formattedDate;
}

export default useLocalTime;
