/* eslint-disable react-hooks/exhaustive-deps */
import AutoSaveSelectByLookup from "../../custom-ui/input-fields/auto-save-select-by-lookup";
import AutoSaveNumber from "../../custom-ui/input-fields/auto-save-number";
import AutoSaveText from "../../custom-ui/input-fields/auto-save-field";
import {
  collectionNames,
  subCollectionNames,
} from "../../../data/dictionary/collectionNames";
import { useEffect, useState } from "react";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import SectionHeading from "../../custom-ui/headings/SectionHeading";
import EssayPrompt from "../EssayPrompt";
import VideoPrompt from "../VideoPrompt";
import AudioPrompt from "../AudioPrompt";
import { useJobInterviewQuestion } from "../../../data/jobDescriptions";
import PageLoader from "../../custom-ui/pageLoader/PageLoader";

function EditCustomJob({
  type,
  typeId,
  id,
  readOnly,
}: {
  type: string;
  typeId: string;
  id: string;
  readOnly: boolean;
}) {
  const [questionType, setQuestionType] = useState(null);
  const { data: question, status: questionStatus } = useJobInterviewQuestion(
    typeId,
    id,
  );
  const [collection, setCollection] = useState(
    collectionNames.jobDescriptions +
    "/" +
    typeId +
    "/" +
    subCollectionNames.interviewQuestions,
  );

  const editorStyle = {
    height: `150px`,
    overflow: "hidden",
    border: "1px solid #ccc",
    padding: "8px",
  };

  const SECTION_LOCKED_MESSAGE =
    "This section cannot be changed while the job status is live.";

  useEffect(() => {
    setQuestionType(question?.promptType);
  }, [question]);


  return (
    <>
      {questionStatus === "success" ? (
        <>
          <div className="w-full">
            <GreenUnderlineHeading
              title={"Prompt"}
              showLockIcon={true}
              isLocked={readOnly}
              text={SECTION_LOCKED_MESSAGE}
            />
            <div className="">
              <SectionHeading title={"Title"} />
              <AutoSaveText
                value={question?.displayName}
                collection={collection}
                recId={id}
                required={true}
                name={"displayName"}
                display={"Question Title"}
                placeholder={"Question Title"}
                directions={"Question Title"}
                readOnly={readOnly}
              />
            </div>
          </div>
          <div className="grid gap-1 sm:grid-cols-1 md:grid-cols-1 grid-cols-2">
            <div className="col-span-2">
              <SectionHeading title={"Prompt Type"} />
              <AutoSaveSelectByLookup
                value={question?.promptType || "ESS"}
                group="einterviewQuestionType"
                collection={collection}
                recId={id}
                required={true}
                name="promptType"
                display="Type of Prompt"
                placeholder="Select Prompt type"
                directions=""
                readOnly={readOnly}
              />
            </div>
            <div className="col-span-2">
              {questionType === "ESS" && (
                <EssayPrompt
                  key={question?.id}
                  question={question?.question}
                  collection={collection}
                  id={id}
                  readOnly={readOnly}
                  editorStyle={editorStyle}
                />
              )}
              {questionType === "VID" && (
                <>
                  <VideoPrompt
                    type={"job"}
                    key={id}
                    typeId={typeId}
                    collection={collection}
                    id={id}
                    disabled={readOnly}
                  />
                  <EssayPrompt
                    key={"essay" + question?.id}
                    question={question?.question}
                    collection={collection}
                    id={id}
                    readOnly={readOnly}
                    editorStyle={editorStyle}
                  />
                </>
              )}
              {questionType === "VOICE" && (
                <>
                  <AudioPrompt
                    type={"job"}
                    key={id}
                    typeId={typeId}
                    collection={collection}
                    id={id}
                    disabled={readOnly}
                  />
                  <EssayPrompt
                    key={"essay_" + question?.id}
                    question={question?.question}
                    collection={collection}
                    id={id}
                    readOnly={readOnly}
                    editorStyle={editorStyle}
                  />
                </>
              )}
            </div>
            <div className="col-span-2 mt-4">
              <GreenUnderlineHeading
                title={"Response"}
                showLockIcon={true}
                isLocked={readOnly}
                text={SECTION_LOCKED_MESSAGE}
              />
            </div>
            <div className="">
              <SectionHeading title={"Type"} />
              <AutoSaveSelectByLookup
                value={question?.type || "VID"}
                group="einterviewQuestionType"
                collection={collection}
                recId={id}
                required={true}
                name="type"
                display="Type of Response"
                placeholder="Select Response type"
                directions=""
                readOnly={readOnly}
              />
            </div>
            <div className="">
              <SectionHeading title={"Number of Re-attempts"} />
              <AutoSaveNumber
                value={question?.reAttempts || 1}
                collection={collection}
                recId={id}
                required={false}
                name={"duration"}
                display={"Number of Attemps"}
                placeholder={"How many attempts are allowed?"}
                directions={""}
                readOnly={readOnly}
                minValue={0}
                maxValue={3}
              />
            </div>
            <div>
              {/* Prep Time  */}
              <SectionHeading title={"Prep Time"} />
              <AutoSaveSelectByLookup
                group={"duration"}
                value={!isNaN(question?.prepTime) ? question?.prepTime : 900000}
                collection={collection}
                recId={id}
                required={false}
                name={"prepTime"}
                display={"Prep Time"}
                placeholder={"Enter time"}
                directions={""}
                readOnly={readOnly}
                saveNumeric={true}
              />
            </div>
            <div>
              {/* Max Recording Time  */}
              <SectionHeading title={"Max Response Time"} />
              <AutoSaveSelectByLookup
                group={"duration"}
                value={!isNaN(question?.maxTime) ? question?.maxTime : 900000}
                collection={collection}
                recId={id}
                required={false}
                name={"maxTime"}
                display={"Max Recording Time"}
                placeholder={"Enter time"}
                directions={""}
                readOnly={readOnly}
                saveNumeric={true}
              />
            </div>
          </div>
        </>
      ) : (
        <PageLoader />
      )}
    </>
  );
}

export default EditCustomJob;
