import React, { useState, useEffect } from "react";
import { useUser } from "../../../data/user";

const DisplayUser = ({
  value: initailValue,
  collection,
  recId,
  required,
  name,
  display,
  placeholder,
  directions,
  readOnly,
}) => {
  const [value, setValue] = useState(initailValue);
  const [userName, setUserName] = useState("Loading...");

  const {
    data: creatorData = {},
    status: creatorStatus = "",
    error: creatorError,
  } = useUser(value);

  useEffect(() => {
    setUserName(creatorData?.name);
  }, [creatorData]);

  return (
    <div key={name} id={name} className="relative mb-4">
      <label className="text-instant-teams-teal-Main text-sm  absolute  duration-300 transform -translate-y-4 scale-75 top-4 origin-[0] left-2.5 peer-focus:text-instant-teams-blue-Main peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
        {display} {directions ? " - " + directions : null}
        {readOnly ? " - LOCKED" : null}
      </label>
      <input
        type="text"
        name={name}
        placeholder={placeholder}
        value={userName}
        className="form-input shadow-md rounded-md w-full pt-3"
        readOnly={readOnly}
      />
    </div>
  );
};

export default DisplayUser;
