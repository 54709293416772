import { collectionNames } from "./dictionary/collectionNames";
import {
  addDoc,
  collection,
  updateDoc,
  doc,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  limit,
  startAt,
} from "firebase/firestore";
import { db } from "../providers/database";
import { upload } from "../storage/storage";
import folderNames from "../storage/dictionary/folderNames";
import { QueryClient, useQuery } from "react-query";
import { createQuery } from "../providers/database";
import { usePreOfferApplications } from "./applications";
import { filterPreOffers } from "./applications";

export async function createOffer({
  positionId,
  jobId,
  resumeId,
  applicationId,
  employerId,
  createdBy,
  payType,
  payRate,
  file,
  startDate,
  isCommission,
  commissionDetails,
  createdAt,
  recipientEmail,
  solutionType,
  offerLetterIsSigned,
  employerUserId,
  employerUserEmail,
  awaitingEmployerSignature,
}: {
  positionId: string;
  jobId: string;
  resumeId: string;
  applicationId: string;
  employerId: string;
  createdBy: string;
  payType: string;
  payRate: number;
  file: {
    path: string;
    lastModified: number;
    name: string;
    size: number;
    type: string;
  };
  startDate: object;
  isCommission: boolean;
  commissionDetails: string;
  createdAt: object;
  recipientEmail: string;
  solutionType: string;
  offerLetterIsSigned: boolean;
  employerUserId: string;
  employerUserEmail: string;
  awaitingEmployerSignature: boolean;
}) {
  const docRef = await addDoc(collection(db, collectionNames.offers), {
    positionId,
    jobId,
    resumeId,
    applicationId,
    employerId,
    createdBy,
    payType,
    payRate,
    startDate,
    status: offerLetterIsSigned ? "hired" : "preparation",
    isCommission,
    commissionDetails,
    createdAt,
    recipientEmail,
    solutionType,
    offerLetterIsSigned,
    employerUserId,
    employerUserEmail,
    awaitingEmployerSignature,
  });

  upload(file, folderNames.offerLetters, `${docRef.id}_${file?.name}`)
    .then((fileUrl) => {
      updateDoc(doc(db, collectionNames.offers, docRef.id), {
        offerLetterUrl: fileUrl,
        offerLetterFileName: file?.name || "",
      });
      updateDoc(doc(db, collectionNames.applications, applicationId), {
        jobOfferId: docRef.id,
      });
      return fileUrl;
    })
    .catch((error: Error) => {
      console.log("Error uploading file", error, docRef.id, file?.name);
      return null;
    });
}

export const getJobOffer = async (jobOfferId: string) => {
  const docRef = doc(db, collectionNames.offers, jobOfferId);
  const jobDoc = await getDoc(docRef);
  return jobDoc.data();
};

export function useJobOfferByEmployee(jobOfferId: string, userId: string) {
  return useQuery(
    ["jobOfferByEmployee", { jobOfferId, userId }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.offers),
        where("jobId", "==", jobOfferId),
        where("resumeId", "==", userId),
        where("status", "==", "hired"),
      );
    }),
    { enabled: !!jobOfferId && !!userId },
  );
}

export function useOfferStatus(id: string) {
  return useQuery(
    ["OfferStatus", { id }],
    createQuery(() => doc(db, "OfferStatus", id)),
    { enabled: !!id },
  );
}

export function useEmployerReciepient(uid: string) {
  return useQuery(
    ["employerReciepient", { uid }],

    createQuery(() => doc(db, collectionNames.users, uid)),

    { enabled: !!uid },
  );
}

export function useJobOffers(id: string = "") {
  return useQuery(
    ["allJobOffers", { id }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.offers),
        where("jobId", "==", id),
        orderBy("createdAt", "desc"),
      );
    }),
    { enabled: !!id },
  );
}

export function useJobOffer(id: string = "") {
  return useQuery(
    ["oneOffer", { id }],
    createQuery(() => {
      return doc(db, collectionNames.offers, id);
    }),
    { enabled: !!id },
  );
}

export function useAcceptedJobOffers(id: string) {
  return useQuery(
    ["allJobOffers", { id }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.offers),
        where("jobId", "==", id),
        where("status", "==", "hired"),
        orderBy("createdAt", "desc"),
      );
    }),
    { enabled: !!id },
  );
}

export function usePreOfferJobOffers() {
  return useQuery(
    ["allPreOffers"],
    createQuery(() => {
      return query(
        collection(db, collectionNames.offers),
        where("status", "==", "hired"),
        orderBy("createdAt", "desc"),
      );
    }),
    { enabled: true },
  );
}
export function useSignedOfferJobOffers(keyId: string) {
  const date14DaysAgo = new Date();
  date14DaysAgo.setDate(date14DaysAgo.getDate() - 14);
  return useQuery(
    [keyId],
    createQuery(() => {
      return query(
        collection(db, collectionNames.offers),
        where("status", "==", "hired"),
        orderBy("startDate", "asc"),
        startAt(date14DaysAgo),
      );
    }),
    { enabled: !!keyId },
  );
}

export function usePendingJobOffers(keyId: string) {
  return useQuery(
    [keyId],
    createQuery(() => {
      return query(
        collection(db, collectionNames.offers),
        where("status", "==", "pending"),
        orderBy("startDate", "asc"),
      );
    }),
    { enabled: !!keyId },
  );
}

const toArray = (object: any) => {
  console.log("toArray: ", object);
  const ret = [];
  for (const key in object) {
    ret.push(key);
  }

  return ret;
};

const getUniqueJobIds = (offers: [any, any, any]) => {
  const ret: any = {};
  offers.forEach((offersList: []) => {
    offersList.forEach((offer: any) => {
      const jobId = offer?.jobId;
      if (!Object.hasOwn(ret, jobId)) {
        ret[jobId] = "";
      }
    });
  });

  return toArray(ret);
};

export function useUniqueJobIds() {
  const { data: preOffers = [] } =
    usePreOfferApplications("preOffersForFilter");
  const { data: pendingOffers = [] } = usePendingJobOffers(
    "pendingOffersForFilter",
  );
  const { data: signedOffers = [] } = useSignedOfferJobOffers(
    "signedOffersForFilter",
  );
  const offersForJobIdsExtraction: [any, any, any] = [
    preOffers,
    pendingOffers,
    signedOffers,
  ];
  const uniqueJobIds = getUniqueJobIds(offersForJobIdsExtraction);
  return useQuery(
    ["uniqueJobIds"],
    () => {
      console.log("useUniqueJobIds: ", uniqueJobIds);
      return uniqueJobIds;
    },
    { enabled: true },
  );
}

export const updateOfferLetter = async (offerId: string, file: any) => {
  try {
    const offerRef = doc(db, collectionNames.offers, offerId);
    upload(file, folderNames.offerLetters, `${offerRef.id}_${file?.name}`)
      .then((fileUrl) => {
        updateDoc(doc(db, collectionNames.offers, offerRef.id), {
          offerLetterUrl: fileUrl,
          offerLetterFileName: file?.name || "",
        });
        return fileUrl;
      })
      .catch((error: Error) => {
        console.log("Error uploading file", error, offerRef.id, file?.name);
        return null;
      });
  } catch (error) {
    console.log("Error updating offer ", error);
  }
};

const getOfferDocRef = (offerId: string) => {
  return doc(db, collectionNames.offers, offerId);
};
export const updateOfferStatus = (id: string, status: string) => {
  return updateDoc(getOfferDocRef(id), { status });
};
// where("status", "==", "preOffer"),
export const getOffersData = async (filters: any, type: string) => {
  let collectionName = "";
  let status = "";
  switch (type) {
    case "preOffers":
      collectionName = collectionNames.applications;
      status = "preOffer";
      break;
    case "signed":
      collectionName = collectionNames.offers;
      status = "hired";
      break;
    case "pending":
      collectionName = collectionNames.offers;
      status = "pending";
      break;
  }
  console.log("getOffersData type: ", type, ", filters: ", filters);
  const ret: any[] = [];
  const q = query(
    collection(db, collectionName),
    where("status", "==", status),
  );
  const querySnapshot = await getDocs(q);
  console.log(
    "getOffersData type: ",
    type,
    "querySnapshot.docs.length: ",
    querySnapshot.docs.length,
  );
  for await (const doc of querySnapshot.docs) {
    const jobId: string = doc.data()?.jobId;
    const id: string = doc.id;
    console.log("   getOffersData type: ", type, ", jobId: ", jobId);
    if (filters.current.length === 0) {
      const offer = {
        ...doc.data(),
        id,
      };
      // ret.push(doc.data());
      ret.push(offer);
    } else {
      if (filters.current.includes(jobId)) {
        const offer = {
          ...doc.data(),
          id,
        };
        // ret.push(doc.data());
        ret.push(offer);
      }
    }
  }

  return ret;
};

export async function filterPendingOffers(
  queryClient: QueryClient,
  filters: [],
  keyId: string,
) {
  console.log("filterPendingOffers1, filters: ", filters);
  const ret = await getOffersData(filters, "pending");
  console.log("filterPendingOffers, ret: ", ret);
  queryClient.setQueryData([keyId], ret);
}

export async function filterSignedOffers(
  queryClient: QueryClient,
  filters: [],
  keyId: string,
) {
  const ret = await getOffersData(filters, "signed");
  console.log("filterSignedOffers, ret: ", ret);
  queryClient.setQueryData([keyId], ret);
}

export async function processFilters(
  queryClient: QueryClient,
  filters: [],
  // queryStatuses: any,
) {
  // console.log("processFilters, queryStatuses: ", queryStatuses);
  // restore the offers before trimming
  // refetchOffers(queryClient, ["pendingOffers", "signedOffers", "preOffers"]);
  // if (queriesCompletedSuccessfully(queryStatuses)) {
  console.log("processFilters, filters: ", filters);
  return new Promise<void>(async (resolve, reject) => {
    try {
      await filterPendingOffers(queryClient, filters, "pendingOffers");
      await filterSignedOffers(queryClient, filters, "signedOffers");
      await filterPreOffers(queryClient, filters, "preOffers");
      resolve();
    } catch (error) {
      reject(error);
    }
  });
  //   return true;
  // } else {
  //   return false;
  // }
}

const queriesCompletedSuccessfully = (queryStatuses: any) => {
  let ret: boolean = true;
  for (const property in queryStatuses) {
    const status: string = queryStatuses[property];
    console.log("     queriesCompletedSuccessfully, status: ", status);
    if (status !== "success") {
      ret = false;
    }
  }

  return ret;
};

const filterOffers = (offers: any[], filters: any) => {
  // 1. given a list of offers and a list of jobIds
  // 2. iterate list of offers and for each offer, check its jobId
  //    against the list of filter jobIds
  // 3. if it does not exist within filters, do not add to result
  //    if it does exist, add to result
  // 4. finally, return the result
  console.log("filterOffers, filters: ", filters);
  console.log("filterOffers, offers: ", offers);
  const ret: any[] = [];
  offers.forEach((offer: any) => {
    const jobId: string = offer?.jobId;
    if (filters.current.includes(jobId)) {
      ret.push(offer);
    }
  });
  return ret;
};

export function refetchOffers(queryClient: QueryClient, listOfKeys: string[]) {
  queryClient.invalidateQueries(listOfKeys);
}
