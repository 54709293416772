import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMeeting } from "../../data/meetings";
import { useUser } from "../../data/user";
import { DisplayTimestamp } from "../../util/timestamps/timestamp";
import { useAuth } from '../../providers/auth';

const Meeting = ({ meetingId }: { meetingId: string }) => {
  const auth = useAuth();
  const { data: meeting } = useMeeting(meetingId);
  const [invitee, setInvitee] = useState(null);
  const [meetingType, setMeetingType] = useState(null);
  const { data: invitedUser } = useUser(invitee);

  useEffect(() => {
    setInvitee(meeting?.inviteeId);
    switch (meeting?.meetingCode) {
      case "interview":
        setMeetingType("Interview");
        break;
      case "interviews2":
        setMeetingType("Interview - Round 2");
        break;
      default:
        setMeetingType("1:1 Meeting");
        break;
    }
  }, [meeting]);

  useEffect(() => {
    console.log("Invited User: ", invitedUser);
  }, [invitedUser]);

  useEffect(() => {
    console.log("User: ", auth?.user);
  }, [auth]);

  return (
    <div className="flex flex-col justify-center mouse-pointer relative mt-1 pl-2 pr-8 w-full bg-white text-black">
      <div className="flex justify-between items-center text-instant-teams-teal-Main font-bold">
        {meeting?.meetingDetails?.title}
      </div>
      <div className="flex justify-between items-center">
        {DisplayTimestamp(meeting?.meetingDetails?.startTime)}
      </div>
      <div className="flex justify-between items-center text-instant-teams-teal-L1">
        {auth?.user?.claims?.type === "S" ? (
          <p className='text-black font-bold'>{meetingType}</p>
        ):(
          <Link to={`/seekers/${meeting?.resumeId}/overview`} target={"new"}>
            {invitedUser?.name}
          </Link>
        )}
        
      </div>
      <div className="justify-between items-center text-instant-teams-green-D1">
        <Link to={`/join-meeting/${meeting?.id}`} target={"new"}>
          <button
            type="button"
            disabled={meeting?.status !== "scheduled"}
            className={`w-full my-5 min-h-8 h-8 text-white text-sm ${
              meeting?.status !== "scheduled"
                ? "bg-instant-teams-gray-D1 cursor-not-allowed"
                : "bg-instant-teams-blue-Main hover:bg-instant-teams-blue-L1"
            }  rounded border-0  focus:outline-none`}
          >
            Join Call
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Meeting;
