import { useEffect, useState } from "react";
import { omit, isEmpty, isNil } from "lodash";
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import { Handle, Track, TooltipRail } from "./range-slider/RangeSlider";
import AutoSaveWysywig from "./auto-save-wysiwyg";
import { updateAutoSave } from "../../../data/utility";
import { deleteField } from "firebase/firestore";

function AutoSavePayTypeRadio({
  options,
  payTypeInitialValue,
  jobPayType, // used to set slider limits and commission option
  collection,
  recId,
  name,
  disabled = false,
}) {
  const [paySelections, setPaySelections] = useState(
    !isEmpty(payTypeInitialValue) ? Object.keys(payTypeInitialValue) : [],
  );

  const [hasCommission, setHasCommission] = useState();

  // for payType filter out COMM (commision) if provided from job
  // commission is not a radio option in positions it is a checkbox
  // so we need to exclude it from radio options before passing to availableOptions

  const excludeCommissionFromRadioOptions = (jobOptions) => {
    if (Array.isArray(jobOptions)) {
      return jobOptions.filter((option) => option.code !== "COMM");
    }
    return [];
  };

  const radioOptions = excludeCommissionFromRadioOptions(options);

  // gets the range as set by the jobDescription
  const jobPayRangeSalary = jobPayType?.["SAL"];
  const jobPayRangeHourly = jobPayType?.["HRLY"];

  // is Commission an available option
  const commissionOption = options.find((item) => item.code === "COMM");
  console.log(commissionOption);

  // exclude Commission option from rate slider (commission "value" is a string, not for use with slider)
  const rateSliders = paySelections.filter((code) => code !== "COMM");

  // commission description
  const [commissionDescription, setCommissionDescription] = useState(
    payTypeInitialValue["COMM"] ?? "",
  );

  // set paySelections on changes to checkbox
  const handleChange = (e) => {
    const { value: code, checked } = e.target;

    if (checked) {
      setPaySelections(() => [code]);

      // remove the previously selected payType
      // prevents more than one payType
      updateAutoSave(collection, recId, {
        [`${name}.payType.${code === "SAL" ? "HRLY" : "SAL"}`]: deleteField(),
      });
      // set the newly selected payType
      updateAutoSave(collection, recId, {
        [`${name}.payType.${code}`]:
          code === "SAL" ? jobPayRangeSalary : jobPayRangeHourly,
      });
    }
  };

  const handleSlideEnd = (code, range) => {
    updateAutoSave(collection, recId, { [`${name}.payType.${code}`]: range });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    console.log("Checked: ", value, checked);
    if (checked) {
      updateAutoSave(collection, recId, {
        [`${name}.payType.COMM`]: "<p></p>",
      });
    } else {
      updateAutoSave(collection, recId, {
        [`${name}.payType.COMM`]: deleteField(),
      });
    }
  };
  return (
    <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
      {/* Pay Options */}
      <div className="flex items-start ">
        {radioOptions.map((item) => (
          <div className="flex items-start" key={item.id}>
            <input
              className="h-5 w-5 shadow accent-instant-teams-blue-Main leading-8 bg-white  border-instant-teams-blue-Main outline-none focus:border-instant-teams-blue-Main focus:ring-1"
              id={item.id}
              name={"payTypeGroup"}
              type="radio"
              value={item.code}
              onChange={handleChange}
              checked={
                !!paySelections.find((pay) => {
                  return pay === item.code;
                })
              }
              disabled={disabled}
            />
            <label
              className="mb-1 px-2 text-sm  font-semibold text-instant-teams-blue-Main"
              htmlFor={item.id}
            >
              {item.label}
            </label>
          </div>
        ))}
        {commissionOption && (
          <div className="flex items-start">
            <input
              className="h-5 w-5 shadow accent-instant-teams-blue-Main leading-8 bg-white rounded border-instant-teams-blue-Main outline-none  focus:ring-1"
              id={commissionOption.id}
              name={name ?? "checkbox"}
              type="checkbox"
              value={commissionOption.code}
              onChange={handleCheckboxChange}
              checked={payTypeInitialValue["COMM"]}
              disabled={disabled}
            />
            <label
              className="mb-1 px-2 text-sm  font-semibold text-instant-teams-blue-Main"
              htmlFor={commissionOption.id}
            >
              {commissionOption.label}
            </label>
          </div>
        )}
      </div>

      <div id={name} className="mb-4 col-span-3">
        {rateSliders.map((selection) => {
          const label = options.find((opt) => opt.code === selection);
          return (
            <div key={selection} className="h-16 my-4">
              <div className="mb-10 uppercase block text-left font-semibold text-instant-teams-blue-Main">
                {label?.label}
              </div>
              <div className="col-span-2 pr-8 pl-4">
                <RateSlider
                  initialValues={payTypeInitialValue?.[selection]}
                  minMaxRange={
                    selection === "SAL" ? jobPayRangeSalary : jobPayRangeHourly
                  }
                  code={selection}
                  handleSlideEnd={handleSlideEnd}
                  step={selection === "SAL" ? 500 : 0.5}
                  disabled={disabled}
                />
              </div>
            </div>
          );
        })}
      </div>

      {payTypeInitialValue["COMM"] && (
        <div id="information.payType.COMM" className="col-span-3">
          <AutoSaveWysywig
            value={commissionDescription}
            rows={5}
            collection={collection}
            recId={recId}
            required={true}
            name={"information.payType.COMM"}
            display="Commission Details"
            placeholder="Enter commission details."
            directions=""
            readOnly={disabled}
            editorStyle={{ height: "240px" }}
          />
        </div>
      )}
    </div>
  );
}

function RateSlider({
  initialValues,
  minMaxRange,
  code,
  handleSlideEnd,
  step,
  disabled = false,
}) {
  function validateInitialValues(initialValues) {
    // check if initialValues from db contain valid numbers, if so then
    // initialize the "values" array with those numbers
    // we dont want to override db if data is valid
    if (
      Array.isArray(initialValues) &&
      initialValues.length > 0 &&
      !initialValues.some(isNaN)
    ) {
      return [...initialValues];
    } else {
      return minMaxRange;
    }
  }

  function formatTooltip(rate) {
    return Number(rate).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  const sliderStyle = {
    position: "relative",
    width: "100%",
  };

  return (
    <>
      <div>
        <Slider
          mode={3}
          step={step}
          domain={minMaxRange}
          rootStyle={sliderStyle}
          onSlideEnd={(range) => handleSlideEnd(code, range)}
          values={validateInitialValues(initialValues)}
          disabled={disabled}
        >
          <Rail>{(railProps) => <TooltipRail {...railProps} />}</Rail>
          <Handles>
            {({ handles, activeHandleID, getHandleProps }) => {
              return (
                <div className="slider-handles">
                  {handles.map((handle) => (
                    <Handle
                      key={handle.id}
                      handle={handle}
                      domain={minMaxRange}
                      isActive={handle.id === activeHandleID}
                      getHandleProps={getHandleProps}
                      formatTooltip={formatTooltip}
                    />
                  ))}
                </div>
              );
            }}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
        </Slider>
      </div>
    </>
  );
}

export default AutoSavePayTypeRadio;
