import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useEmployer, useEmployerDepartment } from "../../../../data/employer";
import { useJobDescription } from "../../../../data/jobDescriptions";
import { upload } from "../../../../storage/storage";
import { writeFileLocation } from "../../../../data/jobDescriptions";
import { friendlyFirebaseError } from "../../../../providers/firebase-errors/friendlyFirebaseError";
import useModal from "../../../../hooks/useModal";
import AutoSaveText from "../../../custom-ui/input-fields/auto-save-field";
import AutoSaveSelectGeneric from "../../../custom-ui/input-fields/auto-save-select-generic";
import DragAndDropVariantA from "../../../custom-ui/drag-and-drop/DragAndDropFile";
import DialogWrapper from "../../../custom-ui/dialog/Dialog";
import { collectionNames } from "../../../../data/dictionary/collectionNames";
import folderNames from "../../../../storage/dictionary/folderNames";
import TextAreaDisplay from "../../../custom-ui/input-fields/modals/TextAreaDisplay";
import { useGlobalState } from "../../../../providers/globalState/GlobalStateProvider";
import JobListingFiles from "../../parts/JobListingFiles";
import GreenUnderlineHeading from "../../../custom-ui/headings/heading";
import { sanitizeMarkup } from "../../../../util/sanitization/sanitize-html";
import "../../../../styles/dangerouslySet.css";

function ExternalJobDescriptionPage() {
  const params = useParams();
  const jobId = params.id ?? "";

  const { isShowing: isShowingTextArea, toggle: toggleTextArea } = useModal();
  const [textAreaEditorState, setTextAreaEditorState] = useState({});
  const { globalState, setGlobalState } = useGlobalState();
  const openAndEditTextArea = (textAreaData: {
    // auto save text area requires these fields
    value: string;
    rows: number;
    collection: string;
    recId: string;
    required: boolean;
    name: string;
    display: string;
    placeholder: string;
    directions: string;
    readOnly: boolean;
  }) => {
    setTextAreaEditorState({ ...textAreaData });
    toggleTextArea();
  };

  const [file, setFile] = useState();
  const [formAlert, setFormAlert] = useState(null);

  const confirmUpload = () => {
    upload(file, folderNames.jobDescriptions, `${jobId}_${file?.name}`).then(
      (fileUrl) => {
        writeFileLocation(jobId, {
          label: file.name,
          type: file.type,
          link: fileUrl,
        })
          .then((docRef) => {
            setFile();
          })
          .catch((error: Error) => {
            setFormAlert({
              type: "error",
              message: friendlyFirebaseError(error),
            });
          })
          .catch((error: Error) => {});
      },
    );
  };

  const { data: job = {} } = useJobDescription(jobId);
  const { data: employer = {} } = useEmployer(job?.employer);
  const { data: dept = {} } = useEmployerDepartment(
    job?.employer,
    job.department,
  );

  useEffect(() => {
    const title = job?.title ?? "";
    const employerName = employer?.displayName ?? "";
    if (title && employerName) {
      setGlobalState({
        ...globalState,
        pageTitle: employerName + " - " + title,
      });
    }
  }, [job?.title, employer?.displayName]);

  return (
    <div>
      <p className="text-2xl text-instant-teams-blue-Main uppercase font-semibold">
        {job?.title}
      </p>
      <p>{dept?.label ?? "No Department Selected"}</p>
      <div className="grid grid-cols-2 gap-5">
        <div>
          <GreenUnderlineHeading title="Day in the life" />

          <div
            className="dangerously"
            dangerouslySetInnerHTML={sanitizeMarkup(
              job?.description?.dayInTheLife,
            )}
          ></div>
        </div>
        <div>
          <GreenUnderlineHeading title="Training" />
          <div
            className="dangerously"
            dangerouslySetInnerHTML={sanitizeMarkup(job?.description?.training)}
          ></div>
        </div>
        <div>
          <GreenUnderlineHeading title="Must Haves" />
          <div
            className="dangerously"
            dangerouslySetInnerHTML={sanitizeMarkup(
              job?.description?.mustHaves,
            )}
          ></div>
        </div>
        <div>
          <GreenUnderlineHeading title="Job Benefits" />
          <div
            className="dangerously"
            dangerouslySetInnerHTML={sanitizeMarkup(job?.description?.benefits)}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default ExternalJobDescriptionPage;
