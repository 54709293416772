import { useEffect, useState } from "react";
import { useComponent } from "../../../data/academy";
import { useEnrollmentsByEmailAndAssessment } from "../../../data/enrollments";
import { useUser } from "../../../data/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLevel } from "../../../util/assessments/level";
import DisplayBadges from "../../custom-ui/badge/displayBadge";

function ReqSkillItemBasic({
  assessment,
  userId,
  statusIconSize = "h-10 w-10", // controls size of "earned, working failed overlays"
}: {
  assessment: any;
  userId?: string;
  statusIconSize?: string;
}) {
  const [userID, setUserID] = useState(userId || "");
  const [email, setEmail] = useState("");
  const [earned, setEarned] = useState(false);
  const [working, setWorking] = useState(false);
  const [failed, setFailed] = useState(false);
  const { data: assessmentData } = useComponent(assessment.assessmentId);
  const [overlayImage, setOverlayImage] = useState(null);
  const { data: user } = useUser(userID);
  const { data: enrollments } = useEnrollmentsByEmailAndAssessment(
    email,
    assessmentData?.id,
  );

  useEffect(() => {
    if (user && user.email) {
      setUserID(user.id);
      setEmail(user.email);
    }
  }, [user]);

  useEffect(() => {
    if (enrollments && enrollments.length > 0) {
      const minScore = assessmentData?.tier?.[assessment.tier];

      if (
        enrollments[0].average !== null &&
        enrollments[0].average >= minScore
      ) {
        setEarned(true);
        setWorking(false);
        setFailed(false);
      } else if (
        enrollments[0].average !== null &&
        enrollments[0].average < minScore
      ) {
        setEarned(false);
        setWorking(false);
        setFailed(true);
      } else {
        setEarned(false);
        setFailed(false);
        setWorking(true);
      }
    }
  }, [enrollments]);

  return (
    <div key={assessment.id} className="rounded-lg py-2 px-2">
      <DisplayBadges
        assessmentId={assessment?.assessmentId}
        enrollment={enrollments && enrollments[0]}
      />
      <p className="text-center text-instant-teams-teal-D1 font-bold w-full text-xs -pt-2">
        {assessmentData?.displayName ?? assessmentData?.name}
      </p>
    </div>
  );
}
export default ReqSkillItemBasic;
