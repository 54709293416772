import React, { useState } from "react";
import FileSaver from "file-saver";
import { useEffect } from "react";
import { set } from "lodash";
import { generateCohortFile } from "../../../callable-cloud-functions/cloudFunctions";

function GenCSVDownload({ data, fileName = "data.csv", tooltip = "Export All" }) {
    const [pending, setPending] = useState(false);
    const [excelData, setExcelData] = useState()

    useEffect(()=>{
        //    const data1 = generateCohortFile({data});
        
        //    console.log("userList",data1)
        const excelFormattedData = data.map((data) => {
            return {
                name: data.name,
                "Seeker Tagged Skills": data.Skills?.seeker_tagged_skills?.map((skill) => skill.skill).join(', ')||"None",
                "TAC Verified Skills": data.Skills?.tac_verified_skills?.map((skill) => skill.skill).join(', ')||"None",
                "TAC Tags": data.Skills?.tac_tags?.map((skill) => skill.skill).join(', ')||"None",
                "Affiliation": data.claims.milSpouse ? "Military Spouse" : data.claims.milVerified ? "Military Verified" : "None",
                "Location": data.address?.state || "none"
            }
        });
        setExcelData(excelFormattedData)

    },[data])

    const convertToCSV = () => {
        const csvRows = [];
        const headers = Object.keys(excelData[0]);
        csvRows.push(headers.join(","));
        for (const row of excelData) {
            const values = headers.map((header) => `"${row[header]}"`);
            csvRows.push(values.join(","));
        }
        return csvRows.join("\n");
    };

    const handleExport = () => {
        setPending(true);
        try {
            const csv = convertToCSV();
            const csvFile = new Blob([csv], { type: "text/csv;charset=utf-8;" });
            FileSaver.saveAs(csvFile, fileName);
        } catch (error) {
            console.error("Error exporting CSV", error);
        } finally {
            setPending(false);
        }
    };

    return (
        <div>
            <button
                className="bg-instant-teams-teal-Main text-white px-4 py-2 rounded"
                title="Export All"
                disabled={pending}
                onClick={handleExport}
            >
                {pending ? (
                    <span>Preparing file, please wait...</span>
                ) : (
                    <span>{tooltip}</span>
                )}
            </button>
        </div>
    );
}

export default GenCSVDownload;
