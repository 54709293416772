import { z } from "zod";

export const seekerInfoSchema = z.object({
    email: z
      .string()
      .email({ message: "Please enter a valid email address. Example: username@example.com." }),
    name: z
      .string({ required_error: "Please enter a full name." })
      .nonempty("Please enter a full name."),
   
    terms: z.literal(true, {
      errorMap: () => ({ message: "You must accept the terms & conditions." }),
    }),
    phone: z
      .string()
      .refine((value: any) => /^\+?[1-9]\d{9,14}$/.test(value), {
        message: "Please enter a valid phone number. The format should be [+][country code][number].",
      }),
    password: z
      .string()
      .min(8, "Password must be at least 8 characters long and contain at least one uppercase letter.")
      .refine((value) => /[A-Z]/.test(value), {
        message: "Password must contain at least one uppercase letter",
      })
      .refine((value) => /[a-z]/.test(value), {
        message: "Password must contain at least one lowercase letter",
      })
      .refine((value) => /[0-9!@#$%^&*]/.test(value), {
        message: "Password must contain at least one number or special character",
      }),
    confirmPassword: z.string().min(1, "Password confirmation is required"),
  }).refine((data) => data?.password === data?.confirmPassword, {
    path: ["confirmPassword"], // Highlight the confirmPassword field
    message: "Passwords do not match",
  });


  export const pwdSchema = z
  .string()
  .min(1, "Password is required")
  .refine((value) => value.length >= 8, {
    message: "Password at least 8 characters",
    path: ["minChar"],
  })
  .refine((value) => /[A-Z]/.test(value), {
    message: "Password must contain at least 1 capital letter",
    path: ["capital"],
  })
  .refine((value) => /[a-z]/.test(value), {
    message: "Password must contain at least 1 lowercase letter",
    path: ["lower"],
  })
  .refine((value) => /[0-9!@#$%^&*]/.test(value), {
    message: "Password must contain at least 1 number or special character",
    path: ["special"],
  });


  export const PASSWORD_REQ = {
    minChar: "At least 8 characters",
    capital: "At least one capital letter",
    lower: "At least one lower case letter",
    special: "At least one number or symbol",
  };