import { Outlet } from "react-router-dom";
import Meta from "../../../custom-ui/meta/Meta";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import SeekerPageOutlet from "./SeekerInfo";
import { useState } from "react";
function SeekerPage() {
  const params = useParams();
  const navigate = useNavigate();
  const [id, setId] = useState(params?.seekerId);

  useEffect(() => {
    console.log(params);
    if (
      window.location.pathname.endsWith(`${id}`) ||
      window.location.pathname.endsWith(`${id}/`)
    )
      navigate(`/seekers/${id}/overview`, { replace: true });
  }, [params, navigate]);
  return <SeekerPageOutlet />;
}

export default SeekerPage;
