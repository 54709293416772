import { useEffect } from "react";
import { useComponent } from "../../../data/academy";
import { TailwindHoverEnlarge } from "../../../util/tailwind/tailwind-styles";
import DisplayBadges from "../../custom-ui/badge/displayBadge";

function ReqSkillItem({
  assessmentId,
  tier,
  addDuration,
}: {
  assessmentId: string;
  tier: string;
  addDuration: Function;
}) {
  const { data: assessment } = useComponent(assessmentId);

  useEffect(() => {
    if (
      assessment?.duration &&
      assessment?.duration !== null &&
      assessment?.duration > 0
    )
      addDuration(assessment.duration);
  }, [assessment?.duration]);

  return (
    <div
      key={assessmentId}
      className={
        TailwindHoverEnlarge(103) +
        "h-full bg-white text-instant-teams-teal-Main shadow-lg rounded-lg py-2 px-2"
      }
    >
      <DisplayBadges size={32} assessmentId={assessment?.id} tier={tier} />

      <p className="text-center font-bold w-full text-xs">
        {assessment?.displayName || assessment?.name}
      </p>
      <p className="text-center font-bold w-full text-xs">
        {assessment?.skillFamily}
      </p>
      <p className="text-center font-bold w-full text-xs">
        {assessment?.duration || "??"} minutes
      </p>
    </div>
  );
}
export default ReqSkillItem;
