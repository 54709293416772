import { useAllChats } from "../../data/messages";
import { Chat } from "../../dataTypes/Messages";
import AdminChatLineItem from "./AdminChatLineItem";
import GreenUnderlineHeading from "../custom-ui/headings/heading";
import {
  SearchBox,
  Configure,
  RefinementList,
  CurrentRefinements,
  ClearRefinements,
  Hits,
  HitsPerPage,
  Pagination,
  RefinementListProps,
} from "react-instantsearch-hooks-web";

const AdminMessages = () => {
  const { data: allChats } = useAllChats();

  return (
    <>
      <div className="ml-4 w-full">
        <GreenUnderlineHeading title="Messages" />
        <div className=" w-full flex">
          <div className="w-1/6 pr-4">
            <SearchBox
              placeholder="Keyword Search"
              classNames={{
                input: "w-full",
                submitIcon: "hidden",
                resetIcon: "hidden",
                submit: "hidden",
              }}
            />
          </div>
          <div className="w-5/6">
            <div className="min-w-full mb-4 pr-12">
              <div className="oveflow-hidden">
                <div className="w-full mr-15 bg-white shadow-md rounded-md ">
                  <div className="border-b flex justify-evenly">
                    <div
                      // scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left w-1/5"
                    >
                      Group
                    </div>
                    <div
                      // scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left w-1/5"
                    >
                      Date Created
                    </div>
                    <div
                      // scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left w-1/5"
                    >
                      Sent To
                    </div>
                    <div
                      // scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left w-1/5"
                    >
                      Created By
                    </div>
                    <div
                      // scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left w-1/5"
                    >
                      Status
                    </div>
                  </div>
                  <Hits
                    hitComponent={({ hit }: { hit: Chat }) => (
                      <AdminChatLineItem chat={hit} />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminMessages;
