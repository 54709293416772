import { useEffect, useState } from "react";
import GreenUnderlineHeading from '../custom-ui/headings/heading';
import EnrollmentResultsItem from './enrollmentResultsItem';


function EnrollmentResults({ results, name }: { results: any, name: string }) {
    
  return (
    <div className='w-full'>
      <GreenUnderlineHeading title={name+" Results"} />
      <div className='flex flex-col'>
        <p>Percentile: {results?.percentile_score}</p>
        <p>Completed: {results?.completed ? "Yes" : "No"}</p>
        <p>Completed Questions: {results?.number_questions_completed}</p>
        {results?.questions.length > 0 && 
          results.questions.map((question:any, index:number) => (
            <EnrollmentResultsItem question={question}/>
          )
        )}        
      </div>
    </div>
  );
}
export default EnrollmentResults;
