import React, { useState, useEffect } from "react";
import useModal from "../../hooks/useModal";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import { updateAutoSave } from "../../data/utility";
import QuestionAudioRecorder from "./QuestionAudioRecorder";
import { useEmployerInterviewQuestion } from "../../data/employer";
import DragAndDropAudioVideo from "../custom-ui/drag-and-drop/DragAndDropAudoVideo";
import { uploadBadge } from "../../providers/firebaseStorage";
import AutoSaveWysiwyg from "../custom-ui/input-fields/auto-save-wysiwyg";
import { useJobInterviewQuestion } from "../../data/jobDescriptions";

function AudioPrompt({
  type,
  typeId,
  collection,
  id,
  disabled = false,
}: {
  type: string;
  typeId: string;
  collection: string;
  id: string;
  disabled?: boolean;
}) {
  const [trigger, setTrigger] = useState("load");
  const [reload, setReload] = useState(false);
  const [audio, setAudio] = useState(null);
  const [file, setFile] = useState();
  const {
    data: employerInterviewQuestion,
    isFetching,
    refetch,
  } = useEmployerInterviewQuestion(typeId, id);
  const { data: JobInterviewQuestion } = useJobInterviewQuestion(typeId, id);
  const { isShowing: showAudioRecorder, toggle: toggleAudioRecorder } =
    useModal();

  async function removeAudio() {
    await updateAutoSave(collection, id, { audioURL: null }).then(() => {
      setReload(true);
      setFile(null);
    });
  }

  const confirmUpload = () => {
    if (file) {
      uploadBadge(file, "audioPrompts", `${id}_${file?.name}`).then(
        (fileUrl: string) => {
          const recording = {
            audioURL: fileUrl,
          };
          updateAutoSave(collection, id, recording);
        },
      );
    }
  };

  useEffect(() => {
    if (type === "employer") {
      if (employerInterviewQuestion && employerInterviewQuestion.audioURL) {
        setAudio(employerInterviewQuestion.audioURL);
        setReload(false);
      }
    }
    if (type === "job") {
      if (JobInterviewQuestion && JobInterviewQuestion.audioURL) {
        setAudio(JobInterviewQuestion.audioURL);
        setReload(false);
      }
    }
  }, [employerInterviewQuestion, JobInterviewQuestion]);

  useEffect(() => {
    console.log(audio);
  }, [audio]);

  return (
    <div key={id} className="w-full">
      {audio !== undefined && audio !== null && audio !== "none" && !reload ? (
        <div className="mx-auto mt-4 min-w-full">
          <div className="mx-auto w-1/2 px-10">
            <audio className="" src={audio} controls />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-1 mt-4">
              <button
                disabled={disabled}
                className="py-2 px-4 w-full text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none ml-auto"
                onClick={toggleAudioRecorder}
              >
                Re-Record Audio
              </button>
            </div>
            <div className="col-span-1 mt-4">
              <button
                disabled={disabled}
                className="py-2 px-4 w-full text-white bg-instant-teams-orange-D2 rounded border-0 hover:bg-instant-teams-red-Main focus:outline-none ml-auto"
                onClick={() => {
                  removeAudio();
                }}
              >
                Delete Audio
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-4 gap-1 mt-2">
          <div className="col-span-1">
            <button
              disabled={disabled}
              className="py-2 px-4 w-full text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none ml-auto"
              onClick={toggleAudioRecorder}
            >
              Record Audio
            </button>
          </div>
          <div className="col-span-2 ml-2">
            <DragAndDropAudioVideo setFile={setFile} type={"Audio"} />
          </div>
          <div className="col-span-1">
            <div className="ml-2">
              {file ? (
                <button
                  disabled={disabled}
                  className="w-full py-2 px-4 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                  onClick={confirmUpload}
                >
                  Upload File
                </button>
              ) : (
                <button
                  disabled={disabled}
                  className="w-full py-2 px-4 text-white bg-instant-teams-gray-D1 rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                  onClick={confirmUpload}
                >
                  Awaiting File
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {showAudioRecorder && (
        <DialogWrapper
          title="Audio Recorder"
          onClose={toggleAudioRecorder}
          size={"w-1/4 "}
        >
          <QuestionAudioRecorder
            closeMe={toggleAudioRecorder}
            collection={collection}
            id={id}
            folderName="audioPrompts"
          />
        </DialogWrapper>
      )}
    </div>
  );
}

export default AudioPrompt;
