import { upload } from "../../storage/storage";
import { useResume, writeResume } from "../../data/seeker";
import { useAuth } from "../../providers/auth";
import { useState } from "react";
import folderNames from "../../storage/dictionary/folderNames";
import { friendlyFirebaseError } from "../../providers/firebase-errors/friendlyFirebaseError";
import ApplicationResume from "../seeker/details/parts/ApplicationResume";

import { useApplication } from "../../data/applications";
import { useParams } from "react-router-dom";
import useModal from "../../hooks/useModal";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import AutoSaveWysiwyg from "../custom-ui/input-fields/auto-save-wysiwyg";
import { useEffect } from "react";
import { updateAutoSave } from "../../data/utility";
import toast from "react-hot-toast";
import ToastAlert from "../custom-ui/toast-alert/ToastAlert";
import { addApplicationSync } from "../../data/applications";
import DragAndDropFileAutoUpload from "../custom-ui/drag-and-drop/DragAndDropFileAutoUpload";

function ApplicationResumeSection({}) {
  const { applicationId = "" } = useParams();

  const { data: applicationData, isLoading: isApplicationDataLoading } =
    useApplication(applicationId);
  const { data: resumeData } = useResume(applicationData?.resumeId);
  const auth = useAuth();
  const [manualResumeSelected, setManualResumeSelected] = useState(
    applicationData?.manualResumeSelected,
  );
  const [file, setFile] = useState();
  const { isShowing, toggle } = useModal();

  useEffect(() => {
    if (!isApplicationDataLoading) {
      setManualResumeSelected(applicationData?.manualResumeSelected);
    }
  }, [applicationData]);

  const handleManualResumeSelected = async (manualResumeChecked: boolean) => {
    if (manualResumeChecked === false) {
      await updateAutoSave("Applications", applicationId, {
        manualResumeSelected: true,
        resumeFileId: "",
        manualResume: resumeData?.resume,
      });
      await addApplicationSync(applicationId);
      toast.success("Manual Resume Selected.");
    } else {
      await updateAutoSave("Applications", applicationId, {
        manualResumeSelected: false,
        manualResume: "",
      });
      await addApplicationSync(applicationId);
      toast.error("Resume Removed. Please select one.");
    }
  };

  const updateManualResume = async () => {
    await updateAutoSave("Applications", applicationId, {
      manualResume: resumeData?.resume,
    });
    await addApplicationSync(applicationId);
    toast.success("Manual Resume Updated.");
    toggle();
  };

  return (
    <div className="">
      <p className="my-2">This position requires an up-to-date resume.</p>
      <p className="my-2">
        Please provide your resume through one of the options below:
      </p>
      <p className="my-2"> Select a file</p>
      <div className="px-2 mb-5">
        <ApplicationResume id={applicationData?.resumeId} />
      </div>
      {/* <p className="my-2">2. Upload file</p> */}
      <div className="text-center mb-5">
        <DragAndDropFileAutoUpload />
      </div>
      <p className="my-2">
        <input
          className="mr-2 h-5 w-5 shadow text-instant-teams-blue-L1 leading-8 bg-white rounded border-instant-teams-blue-Main outline-none  focus:ring-1"
          type="checkbox"
          checked={manualResumeSelected}
          value={applicationData?.manualResumeSelected}
          onChange={() => handleManualResumeSelected(manualResumeSelected)}
        ></input>
        Manually input resume
      </p>
      <div className="flex justify-center my-5">
        <button
          disabled={!manualResumeSelected}
          onClick={toggle}
          className={
            !manualResumeSelected
              ? `py-2 w-1/2 rounded-md bg-instant-teams-gray-D1  text-white`
              : `py-2 w-1/2 rounded-md bg-instant-teams-blue-Main   hover:bg-instant-teams-blue-D1 text-white`
          }
        >
          Enter Resume
        </button>
      </div>
      {isShowing ? (
        <DialogWrapper
          onClose={() => updateManualResume()}
          title="Manual Resume"
          size="w-1/2 mt-36"
        >
          <div className="w-full text-base mt-4">
            <AutoSaveWysiwyg
              value={resumeData?.resume}
              rows={4}
              collection="Resumes"
              recId={auth.user.uid}
              required={false}
              name="resume"
              display=""
              placeholder={"Tell us about yourself..."}
              directions=""
              readOnly={false}
            />
          </div>
        </DialogWrapper>
      ) : null}
      <ToastAlert />
    </div>
  );
}

export default ApplicationResumeSection;
