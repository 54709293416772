import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
/*
 * Wrapping / Mirroring a HTML Element
 * https://react-typescript-cheatsheet.netlify.app/docs/advanced/patterns_by_usecase/#wrappingmirroring-a-html-element
 *
 */

interface FormIconButtonProps extends React.ComponentProps<"button"> {
  pending?: boolean;
}

function ForwardArrowButton(props: FormIconButtonProps) {
  // omit pending from the button props;
  const { pending, ...buttonProps } = props;
  return (
    <>
      <div className="p-2">
        {pending ? (
          <FontAwesomeIcon
            className="animate-spin h-10 w-10 p-5"
            //style={faDuotoneStyles}
            icon={faSpinnerThird}
            size="sm"
          />
        ) : (
          <button {...buttonProps}>
            <i
              className={`fa-solid fa-arrow-right h-10 w-10 p-3 text-white rounded ${
                props.disabled
                  ? "bg-instant-teams-blue-Main/50"
                  : "bg-instant-teams-blue-Main"
              }  hover:bg-instant-teams-blue-D1`}
              //icon={faArrowRight}
              //size="sm"
            />
          </button>
        )}
      </div>
    </>
  );
}

export default ForwardArrowButton;
