import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";

import {
  getFirestore,
  connectFirestoreEmulator,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  startAt,
  endAt,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  limit,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";

import { geohashQueryBounds, Geopoint, distanceBetween } from "geofire-common";

import { createQuery, format } from "../providers/database";

import { firebaseApp } from "../providers/firebase";

import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";

import { errorLogger } from "../providers/clientLogger";

import { User } from "../dataTypes/User";

import { z } from "zod";

export const db = getFirestore(firebaseApp);

export function getUsersByLocationReferences(
  role: string,
  latitude: number,
  longitude: number,
  radiusInMiles: number,
  setReferences: (references: any[]) => {},
) {
  try {
    const center: Geopoint = [latitude, longitude];
    const kilometers = milesToKilometers(radiusInMiles);
    console.log("kilometers: ", kilometers);
    const radiusInMeters = kilometers * 1000;

    // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
    // a separate query for each pair. There can be up to 9 pairs of bounds
    // depending on overlap, but in most cases there are 4.

    const bounds = geohashQueryBounds(center, radiusInMeters);

    const references = [];

    for (const b of bounds) {
      const q = query(
        collection(db, collectionNames.geoSearchSeeker),
        orderBy("geoHash"),
        startAt(b[0]),
        endAt(b[1]),
      );
      console.log(b);
      references.push(q);
    }
    setReferences(references);
    //return references;
  } catch (error) {
    throw error;
  }
}

export function useUsersByGeo(usersRef: any, index: number) {
  return useQuery(
    ["usersByGeoLocation", { index }],
    createQuery(() => {
      return usersRef;
    }),
    { enabled: !!usersRef, keepPreviousData: true },
  );
}

export function milesToKilometers(miles: number) {
  const kilometersPerMile = 1.60934;
  return miles * kilometersPerMile;
}

export function milesToMeters(miles: number) {
  const kilometers = milesToKilometers(miles);
  return kilometers * 1000;
}

export function configureGeoFalsePositiveFilter(
  centerLatitude: number,
  centerLongitude: number,
  radiusInMiles: number,
) {
  console.log("Filter geo false positives");
  return (record: any) => {
    const { address } = record;
    console.log("filtering false positives", address);
    const lat = address.latitude;
    const lng = address.longitude;

    const center: Geopoint = [centerLatitude, centerLongitude];

    // We have to filter out a few false positives due to GeoHash
    // accuracy, but most will match
    const distanceInKm = distanceBetween([lat, lng], center);
    const distanceInM = distanceInKm * 1000;
    console.log("filtering false positives: ", distanceInM <= radiusInMiles);
    if (distanceInM <= radiusInMiles) {
      return true;
    }
  };
}
