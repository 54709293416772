import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { subCollectionNames } from "../../data/dictionary/collectionNames";
import {
  useAllNotesByCollectionAndId,
  useAllSharedNotesByCollectionAndId,
} from "../../data/notes";
import NoteDisplay from "./note-display";
import GreenUnderlineHeading from "../custom-ui/headings/heading";
function NotesDisplay({
  collection,
  paramId,
}: {
  collection: string;
  paramId: string;
}) {
  const { notes } = subCollectionNames;

  const params = useParams();
  const [id, setId] = useState("");
  useEffect(() => {
    if (paramId === "userId") {
      setId(params.userId);
    } else if (paramId === "employerId") {
      setId(params.employerId);
    } else if (paramId === "jobId") {
      setId(params.id);
    }
  }, [params, paramId]);
  const { data: fetchedNotes } = useAllNotesByCollectionAndId(
    id,
    collection,
    notes,
  );
  // const { data: fetchedNotes } = useAllSharedNotesByCollectionAndId(
  //   id,
  //   collection,
  //   notes,
  // );
  console.log("Notes: ", params, id);
  return (
    <div>
      <GreenUnderlineHeading title="Notes" />
      <table className="w-10/12">
        {fetchedNotes?.length !== 0 ? (
          fetchedNotes?.map((row: any, index: string) => {
            return <NoteDisplay entry={row} key={index} />;
          })
        ) : (
          <div>Nothing to display.</div>
        )}
      </table>
    </div>
  );
}

export default NotesDisplay;
