import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { newUsersByDay } from "../callable-cloud-functions/cloudFunctions";
import InfoIcon from "../components/dashboard/AdminMetricDashboard/InfoTextWithIcon";
import LoadingSpinner from "../components/dashboard/AdminMetricDashboard/LoadingIndicator/LoadingSpinner";



function NewUserChart({ daysSelected }) {
  const [userData, setUserData] = useState([])
  const [chartData, setChartData] = useState([]);
  const [chartData2, setChartData2] = useState([]);
  const [chartKeys, setChartKeys] = useState([]);

  const [chartTitle, setChartTitle] = useState("Last 14 Days");

  useEffect(() => {
    async function fetchData() {
      const { data } = await newUsersByDay(30)
      setUserData(data);
    }
    fetchData();
  }, [])


  useEffect(() => {
    const filteredData = userData.slice(-daysSelected);
    const chartData = filteredData.map(item => item.newUsers);
    const chartData2 = filteredData.map(item => item.activeUsers);
    const chartKeys = filteredData.map(item => item.date.split("-").slice(-2).join("/"));
    setChartData(chartData);
    setChartData2(chartData2);
    setChartKeys(chartKeys);
    setChartTitle(`Last ${daysSelected} Days`);
  }, [daysSelected, userData]);

  const options = {
    chart: {
      type: 'line',
      height: '100%',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: chartKeys,
      labels: {
        rotate: -45, 
        rotateAlways: daysSelected ==='30' ? true:false, // Ensure rotation always applies
        style: {
          fontSize: '12px',
          colors: '#333', // Default color for labels
        },
      },
    },
    stroke: {
      curve: 'straight',
      width: 2,
    },
    markers: {
      size: 5,
      strokeWidth: 0,
      hover: {
        size: 7,
      },
    },
    colors: ['#1E596D', '#c4e6f3'],
    dataLabels: {
      enabled: false,
    },
  };

  const series = [
    {
      name: "New User(s)",
      data: chartData,
    },
    {
      name: "Active User(s)",
      data: chartData2,
    },
  ];

  return (
    <>


      <div className="bg-white shadow-lg rounded-lg p-4 pb-8">

        <div className="w-full text-[#c4e6f3] pb-3 h-72">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <h1 className='text-xl text-[#1E596D] font-bold'>
                {`User Data by Day `}
              </h1>
              <p className="text-10px ml-[3px] text-[#3b7f96]">
                {`(Last ${daysSelected} Days)`}
              </p>
              <InfoIcon tooltipText={"Number of new users who created an account on the MP vs number of users who access the MP in the selected time period."} />
            </div>


          </div>  
                  {!userData ? (<div className="w-full h-[80%] items-center flex justify-center  ">
            <LoadingSpinner />
          </div>) : (

            <ApexCharts options={options} series={series} type="line" height="100%" />
          )}

        </div>
      </div>

    </>

  );
}

export default NewUserChart;
