/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useQuery,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import { doc, collection, query, where, orderBy } from "firebase/firestore";
import { db, createQuery } from "../providers/database";

export function useAccessLogs(id: string) {
  return useQuery(
    ["accesslogs", { id }],
    createQuery(() => doc(db, "AccessLogs", id)),
    { enabled: !!id },
  );
}

// All AccessLogs By Id
export function useAllAccessLogs(id: string) {
  return useQuery(
    ["accesslogs", { id }],
    createQuery(() =>
      query(
        collection(db, "AccessLogs"),
        where("uid", "==", id),
        orderBy("timestamp", "desc"),
      ),
    ),
    { enabled: !!id },
  );
}
// AccessLogs by Employers
export function useAllEmployerAccessLogs(employerId: string) {
  return useQuery(
    ["employeraccesslogs", { employerId }],
    createQuery(() =>
      query(
        collection(db, "AccessLogs"),
        where("employerId", "==", employerId),
        orderBy("timestamp", "desc"),
      ),
    ),
    { enabled: !!employerId },
  );
}
