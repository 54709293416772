import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { LoaderIcon, toast } from "react-hot-toast";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useAuth, handleVerifyEmail, isEmailVerified } from "../../providers/auth";
import ToastAlert from "../../components/custom-ui/toast-alert/ToastAlert";
import { usePasswordValidation } from "../../hooks/usePasswordValidation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { resetPassword } from "../../callable-cloud-functions/cloudFunctions";
import { getUser } from "../../providers/database";
import { updateUser } from "../../data/user";

const AuthActionTemplate = () => {


  const location = useLocation();
  const domainName = "https://jobs.instantteams.com/";

  const auth = useAuth();
  const isOnboarded = auth?.userClaims?.claims?.onboarded;

  const { sendVerificationEmail, user, reloadUser, isMultiFactorUser } = auth;

  const [searchParams, setSearchParams] = useSearchParams();
  const [actionMessage, setActionMessage] = useState(
    "Confirming your account..."
  );
  const [mode, setMode] = useState(searchParams.get("mode") || null);
  const [passwordReset, setPasswordReset] = useState(false);
  const [counter, setCounter] = useState(10);
  const [counterTrigger, setCounterTrigger] = useState()
  useEffect(() => {
    const intervalId = setInterval(reloadUserAndCheckEmailVerified, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const queryParams = new URLSearchParams(location.search);
  const urlMode = queryParams.get('mode');


  async function reloadUserAndCheckEmailVerified() {
    try {
      await reloadUser();
      isEmailVerified()
        .then((user) => {
          updateUser(auth?.user?.id, { emailVerified: true })
            .then(() => console.log("updated user settings."))
            .catch((error) =>
              console.error("Problem updating user record", error),
            );
          //  navigateNextStep();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) { }
  }

  function navigateNextStep() {
    // is the user mfa enrolled?
    // is the user onboardedf
    if (urlMode === 'resetPassword') {
      console.log("passwordreset")
      window.location.href = ` ${domainName}auth/signin`
    } else if (urlMode === "verifyEmail") {
      window.location.href = ` ${domainName}registration/multifactor-enrollment`;
    }
    else {
      window.location.href = ` ${domainName}auth/signin`
    }
    return;

  }
  useEffect(() => {
    if (searchParams.get("mode") === "revertSecondFactorAddition") {
      setActionMessage(
        "Thank you for enrolling in multifactor authentication."
      );
    }
    if (searchParams.get("mode") === "verifyEmail") {
      handleVerifyEmail(searchParams.get("oobCode"))
        .then(() => {
          setActionMessage("Thank you for verifying your email address.");
        })
        .catch((error) => {
          setActionMessage(
            "There was a problem attempting to verify your email."
          );
        });
    }
    if (searchParams.get("mode") === "recoverEmail") {
      setActionMessage("Thank you for updating your email address.");
    }
    if (searchParams.get("mode") === "resetPassword") {
      setActionMessage("Your password has been successfully reset.");
    }
  }, [searchParams]);

  const [viewPassword, setViewPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");


  const [validLength, hasNumber, upperCase, lowerCase, match, specialChar] =
    usePasswordValidation({
      firstPassword: password,
      secondPassword: confirmPassword,
    });

  const [reqMet, setReqMet] = useState(false);

  useEffect(() => {
    if (validLength && hasNumber && upperCase && lowerCase && specialChar) {
      setReqMet(true);
    } else setReqMet(false);
  }, [validLength, hasNumber, upperCase, lowerCase, match, specialChar]);

  const navigateSignin = () => {
    window.location.href = ` ${domainName}auth/signin`
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let passwordErr = "";
    let confirmPasswordErr = "";

    if (!password) {
      passwordErr = "Please enter your password.";
    } else if (!validLength || !hasNumber || !upperCase || !lowerCase || !specialChar) {
      passwordErr = "Password must meet all requirements.";
    }

    if (!confirmPassword) {
      confirmPasswordErr = "Please confirm your password.";
    } else if (password !== confirmPassword) {
      confirmPasswordErr = "Passwords do not match.";
    }

    if (passwordErr || confirmPasswordErr) {
      setPasswordError(passwordErr);
      setConfirmPasswordError(confirmPasswordErr);
      return;
    }

    setLoading(true);
    auth
      .confirmPasswordReset(password, searchParams.get("oobCode"))
      .then(() => {
        console.log("Password reset successfully");
        setPassword("");
        setConfirmPassword("");
        setPasswordError("");
        setConfirmPasswordError("");
        toast.success("Password reset successfully");
        setPasswordReset(true)
      })
      .catch((error) => {
        console.log("Error:", error);
        toast.error("Error resetting password");
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => setPasswordReset(true), 6000);
      });
  };

  useEffect(() => {
    if (password === confirmPassword && password.length !== "" && confirmPassword !== "") {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    const interval = setInterval(() => {

      if (mode !== "resetPassword" || passwordReset) {

        setCounter((prevCounter) => prevCounter - 1);
      }
    }, 1000);
    if (counter === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [passwordReset]);


  useEffect(() => {
    if (counter <= 0) {
      navigateNextStep()
    }
  }, [counter]);

  return (
    <div className=" bg-white xs:pt-0 sm:pt-0">
      <div
        className="flex flex-row sm:min-h-content min-h-screen w-screen bg-white"
      >
        <div className="w-2/5 overflow-hidden sm:hidden xs:hidden">
          <img src="./images/mask_group.png" alt="" className="h-full" />
        </div>
        {mode !== "resetPassword" || passwordReset ? (
          <div className="w-3/5 xs:w-full sm:w-full pt-36 flex flex-col items-center justify-start text-xl font-bold text-center">
            <h1 className=" p-4 text-instant-teams-teal-Main">{actionMessage}</h1>
            <i className="fa-rounded fa fa-check-circle text-green-500 text-5xl p-8"></i>
            <div className=" text-base">
              <p className=" text-center">
                If you did not make this request, please contact:
              </p>
              <a
                href="mailto:helpdesk@instantteams.com"
                className="text-center mx-auto"
              >
                helpdesk@instantteams.com
              </a>
              {counter >= 0 && <p>{`You will be redirect in ${counter} seconds`}</p>}
            </div>
            <div className="flex flex-row w-3/4 xs:flex-col sm:flex-col  items-center  mx-auto justify-around max-h-36">
              <img
                src="/images/instant-teams-logo/InstantTeamsLogoMain-01.png"
                className="w-42 h-3/4 mt-4 xs:hidden sm:hidden block"
                alt="12 Million Plus Logo"
              />
              <img
                src="/images/12-mil-plus/12-mil-logo.png"
                className=" w-36 h-full  mt-4"
                alt="12 Million Plus Logo"
              />
            </div>
          </div>
        ) : (
          <div className="w-3/5 xs:w-full sm:w-full flex flex-col items-center sm:justify-start justify-center  text-xl font-bold text-center">
            <div className="mt-4 flex flex-col justify-center items-center text-center">
              <p className="text-xl font-bold pb-10 text-instant-teams-teal-D2">
                Reset Your Password.
              </p>
              <form
                className="grid grid-cols-1 gap-3 mb-2 relative w-full"
                onSubmit={handleSubmit}
              >
                {viewPassword ? (
                  <i
                    className="fa-solid fa-eye absolute mt-2 w-10 right-8"
                    onClick={() => {
                      setViewPassword(!viewPassword);
                    }}
                  />
                ) : (
                  <i
                    className="fa-solid fa-eye-slash  absolute mt-2 w-10 right-8"
                    onClick={() => {
                      setViewPassword(!viewPassword);
                    }}
                  />
                )}
                <div className="w-full justify-start">
                  <div className="flex">
                    <input
                      type={viewPassword ? "text" : "password"}
                      placeholder="Enter New Password"
                      onChange={handlePassword}
                      value={password}
                      className={`shadow-sm appearance-none rounded-lg w-96 xs:w-full text-xl py-2  leading-tight ${reqMet
                        ? "focus:border-2 focus:border-instant-teams-blue-Main focus:ring-2 focus:ring-instant-teams-blue-Main"
                        : "focus:border-2 focus:border-red-500 focus:ring-2 focus:ring-red-500"
                        }`}
                    />

                    {reqMet && (
                      <div className="ml-4 right-3 top-1">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-instant-teams-blue-Main"
                        />
                      </div>
                    )}
                  </div>
                  {passwordError && (
                    <div className="text-red-400 text-sm mt-1 ">{passwordError}</div> // Adjust font size with text-sm
                  )}
                </div>

                <div className="w-full" >
                  <div className="flex">
                    <input
                      type={viewPassword ? "text" : "password"}
                      placeholder="Confirm New Password"
                      onChange={handleConfirmPassword}
                      value={confirmPassword}
                      className={`shadow-sm appearance-none rounded-lg w-96 xs:w-full text-xl py-2 mt-0.5 leading-tight "focus:border-2 focus:border-red-500 focus:ring-2 focus:ring-red-500" ${passwordsMatch
                        ? "focus:border-2  focus:border-instant-teams-blue-Main focus:ring-2 focus:ring-instant-teams-blue-Main"
                        : "focus:border-2 focus:border-red-500 focus:ring-2 focus:ring-red-500"
                        }`}
                    />

                    {reqMet && password === confirmPassword && (
                      <div className="ml-4 right-3 top-35">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-instant-teams-blue-Main"
                        />
                      </div>
                    )}
                  </div>
                  {confirmPasswordError && (
                    <div className="text-sm mt-1 text-red-400">{confirmPasswordError}</div>
                  )}
                </div>

                <ul className=" list-disc text-left text-sm flex flex-col gap-3 mt-1 pl-4">
                  <li className={`${validLength ? "text-green-500" : ""}`}>
                    At least 8 characters
                  </li>
                  <li className={`${upperCase ? "text-green-500" : ""}`}>
                    At least one capital letter
                  </li>
                  <li className={`${lowerCase ? "text-green-500" : ""}`}>
                    At least one lower case letter
                  </li>
                  <li className={`${hasNumber ? "text-green-500" : ""}`}>
                    At least one number
                  </li>
                  <li className={`${specialChar ? "text-green-500" : ""}`}>
                    At least one special characters
                  </li>
                </ul>
                <div className="flex flex-col justify-center items-center gap-4 mt-8">
                  <button
                    className="w-48 h-10 mt-8 font-semibold text-white bg-instant-teams-blue-Main rounded shadow-xl2 hover:bg-instant-teams-blue-L1"
                    type="submit"
                  >
                    {/* &#10140; */}
                    Submit
                  </button>
                  <div
                    onClick={navigateSignin}
                    className="font-normal text-base underline"
                  >
                    Back to Login
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        <ToastAlert />
      </div>
    </div>
  );
};

export default AuthActionTemplate;
