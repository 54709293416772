import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";
import { getLookupLabel } from "../../util/search/getLookupLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useLookup } from "../../data/lookup";
import useModal from "../../hooks/useModal";
import { useAuth } from "../../providers/auth";
import { useComponent } from "../../data/academy";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import SeekerAssessmentDetails from "./AssessmentDetails";
import { useEnrollmentsByEmailAndAssessment } from "../../data/enrollments";
import DisplayBadges from "../custom-ui/badge/displayBadge";
function SeekerBadgesItem({ badge }: { badge: any }) {
  const { data: levels } = useLookup("learningLevel");
  const { isShowing: isAssessmentShowing, toggle: toggleAssessment } =
    useModal();
  const { data: skillData } = useComponent(badge.skill.id);
  const { user } = useAuth();

  const { data: enrollments } = useEnrollmentsByEmailAndAssessment(
    user?.email,
    badge.skill?.id,
  );
  const enrollment = enrollments?.[0];

  return (
    <>
      {levels && (
        <div
          onClick={toggleAssessment}
          key={badge?.skill?.id}
          className={
            badge.result === "success"
              ? `${TailwindHoverEnlarge(
                  103,
                )} + w-full cursor-pointer shadow-input-field  h-28 mb-3 rounded-lg grid grid-cols-4`
              : `${TailwindHoverEnlarge(
                  103,
                )} + grayscale w-full cursor-pointer shadow-input-field  h-28 mb-3 rounded-lg grid grid-cols-4`
          }
        >
          <div>
            <DisplayBadges
              assessmentId={badge?.skill?.id}
              enrollment={enrollment}
            />
          </div>

          <div className="col-span-2 my-auto font-semibold text-instant-teams-blue-Main">
            <div>{badge?.skill?.displayName || badge?.skill?.name}</div>
          </div>
          <div className="col-span-1 mx-auto my-auto">
            {badge.result === "success" && (
              <FontAwesomeIcon
                icon={faCircleCheck}
                className=" text-instant-teams-green-Main"
                size="2x"
              />
            )}
          </div>
        </div>
      )}
      {isAssessmentShowing ? (
        <DialogWrapper
          title="Assessment Details"
          size="mt-36 w-1/2"
          onClose={toggleAssessment}
        >
          <SeekerAssessmentDetails
            key={badge.skill.id}
            assessment={badge.skill}
            enrollment={enrollment}
            application={true}
          />
        </DialogWrapper>
      ) : null}
    </>
  );
}

export default SeekerBadgesItem;
