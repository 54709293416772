import React, { useState, useEffect } from 'react';
import JobPieChart from './jobPieChart';
import { externalJobsPieChart, getActiveUsersCSVData } from "../callable-cloud-functions/cloudFunctions";
import { userPieChart } from '../callable-cloud-functions/cloudFunctions';
import { getInteractionsCSVData } from '../callable-cloud-functions/cloudFunctions';
import { csvDownload } from '../components/dashboard/AdminMetricDashboard/ExportCsv';

function JobPieChartContainer({ daysSelected }) {

  const [extJobsPieChartData, setExtJobsPieChartData] = useState();
  const [userPieChartData, setUserPieChartData] = useState();
  const [loadingStates, setLoadingStates] = useState({
    interactionsloading: false,
    activeUserloading: false,
  });

  useEffect(() => {
    async function fetchData() {
      const data = await externalJobsPieChart()
      setExtJobsPieChartData(data)
    }
    fetchData()
  }, [])

  useEffect(() => {
    async function fetchData() {
      const data = await userPieChart()
      setUserPieChartData(data)
    }
    fetchData()
  }, [])

  const handleCsvDownload = async (fetchDataFunc, loadingKey, fileName) => {
 
    setLoadingStates(prevState => ({ ...prevState, [loadingKey]: true }));
   
    const data = await fetchDataFunc({ "days": daysSelected })
    console.log(data)
    setLoadingStates(prevState => ({ ...prevState, [loadingKey]: false }));
    if (data?.data?.data) {
      
      csvDownload(data?.data?.data, `${fileName}_${daysSelected}_days`)

      console.log("Active user data --->",data)
    } else {
      console.log("no data found")
    }
  }

  return (
    <>
      <JobPieChart extdata={extJobsPieChartData}
        chartFlag='Job'
        daysSelected={daysSelected}
        infoText={"Total interactions (clicks) across various platforms in the selected time period."}
        handleCSVDowload={() => { handleCsvDownload(getInteractionsCSVData,"interactionsloading","job_interactions_by_platform") }}
        pieChartCenterText="Total Interactions"
        donwlodingState = {loadingStates}
      />
      <JobPieChart
        extdata={userPieChartData}
        chartFlag='User'
        daysSelected={daysSelected}
        infoText={"Total active career seeker across various platforms in the selected time period."}
        handleCSVDowload={() => { handleCsvDownload(getActiveUsersCSVData,"activeUserloading","active_users_by_platform") }}        pieChartCenterText="Active Users"
        donwlodingState = {loadingStates}
      />
    </>
  );
}

export default JobPieChartContainer;