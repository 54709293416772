import { useEffect, useState } from "react";
import { sanitizeMarkup } from "../../util/sanitization/sanitize-html";
import { useComponent } from "../../data/academy";
import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";
import { createEnrollmentProcess } from "../../data/academy";
import { Timestamp } from "firebase/firestore";
import { useAuth } from "../../providers/auth";
import toast from "react-hot-toast";
import ToastAlert from "../custom-ui/toast-alert/ToastAlert";
import { DisplayDate } from "../../util/timestamps/timestamp";
import { useUser } from "../../data/user";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import useModal from "../../hooks/useModal";
import EnrollmentDetails from "./enrollmentDetails";
import { useEnrollmentsByEmailAndAssessment } from "../../data/enrollments";
import { getLevel } from "../../util/assessments/level";
import DisplayBadges from "../custom-ui/badge/displayBadge";

function SkillBadge({ skill, userId }: { skill: any; userId: string }) {
  const user = useAuth();
  const [manualUser, setManualUser] = useState("");
  const [allowRetry, setAllowRetry] = useState(true);
  const { data: assessment } = useComponent(skill.assessmentId);
  const [overlayImage, setOverlayImage] = useState(null);
  const [reqImage, setReqImage] = useState(null);
  const [status, setStatus] = useState("active");
  const { data: userData } = useUser(userId);
  const { data: manualUserData } = useUser(manualUser);
  const { isShowing, toggle } = useModal();
  const [type, setType] = useState("earned");
  const [enrollment, setEnrollment] = useState({});
  const { data: enrollments } = useEnrollmentsByEmailAndAssessment(
    userData?.email,
    skill?.assessmentId,
  );

  useEffect(() => {
    if (assessment?.tier && enrollment && enrollment.status === "completed") {
      if (
        assessment.tier.one <= enrollment?.average &&
        assessment.tier.two > enrollment?.average
      ) {
        setOverlayImage("/images/overlays/one.png");
      } else if (
        assessment.tier.two <= enrollment?.average &&
        assessment.tier.three > enrollment?.average
      ) {
        setOverlayImage("/images/overlays/two.png");
      } else if (assessment.tier.three <= enrollment?.average) {
        setOverlayImage("/images/overlays/three.png");
      } else {
        if (enrollment && enrollment?.personality !== null) {
          setOverlayImage("/images/overlays/three.png");
        } else {
          setOverlayImage(null);
        }
      }
    }
  }, [assessment]);

  useEffect(() => {
    if (enrollments?.length > 0) {
      setEnrollment(enrollments[0]);
      if (enrollment?.status !== "completed") {
        setType("invited");
      } else {
        setType("earned");
      }
      if (enrollment?.average && enrollment?.average >= 80) {
        setAllowRetry(false);
      } else if (enrollment?.personality !== null) {
        setAllowRetry(false);
      } else {
        setAllowRetry(true);
      }
    }
  }, [enrollments]);

  useEffect(() => {
    if (enrollment && enrollment?.assessedBy) {
      setManualUser(enrollment.assessedBy);
    }
  }, [enrollment]);

  const removeInvite = () => {
    const request = {
      action: "remove",
      assessmentId: enrollment.assessmentId,
      email: enrollment.email,
      timestamp: Timestamp.now(),
      who: user?.user?.uid,
    };
    createEnrollmentProcess(request);
    setStatus("delete");
    toast.success("Removing Invite - 2 minute wait");
  };

  const addInvite = () => {
    const request = {
      action: "add",
      assessmentId: skill.assessmentId,
      email: userData?.email,
      name: userData?.name,
      timestamp: Timestamp.now(),
      who: user?.user?.uid,
    };
    createEnrollmentProcess(request);
    setStatus("add");
    toast.success("Adding Invite - 2 minute wait");
  };

  const deleteManual = () => {
    if (
      enrollment?.hasOwnProperty("manuallyAssessed") &&
      enrollment?.manuallyAssessed === true
    ) {
      const request = {
        action: "delete",
        assessmentId: enrollment.assessmentId,
        email: enrollment.email,
        recId: enrollment.id,
        timestamp: Timestamp.now(),
        who: user?.user?.uid,
      };
      createEnrollmentProcess(request);
      setStatus("delete");
      toast.success("Deleting Manual Assessment");
    } else {
      toast.success("Not a manual assessment");
    }
  };

  const retryAssessment = () => {
    const request = {
      action: "retry",
      assessmentId: enrollment.assessmentId,
      email: enrollment.email,
      timestamp: Timestamp.now(),
      who: user?.user?.uid,
    };
    createEnrollmentProcess(request);
    setStatus("retry");
    toast.success("Creating Retry Invite - 2 minute wait");
  };

  return (
    <div
      key={skill?.assessmentId}
      className={TailwindHoverEnlarge(110) + "p-2 m-2"}
    >
      <DisplayBadges assessmentId={assessment?.id} enrollment={enrollment} />
      <div className="flex flex-col items-center">
        <p className="w-full text-center text-instant-teams-teal-D2 font-bold text-sm">
          {assessment?.displayName || assessment?.name}
        </p>
      </div>
      <p className="text-center text-instant-teams-teal-D2 font-bold w-full text-sm">
        {enrollment?.status?.toUpperCase()}
      </p>

      <p className="text-center text-instant-teams-teal-D2 font-bold w-full text-sm">
        {DisplayDate(enrollment?.createdAt || enrollment?.manuallyAssessedDate)}
      </p>
      {type === "invited" && status == "active" && allowRetry && (
        <p className="text-center">
          <button
            className="py-1 px-2 mt-2 text-sm text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none ml-auto"
            onClick={removeInvite}
          >
            Remove Invite
          </button>
        </p>
      )}
      {!enrollment?.hasOwnProperty("id") && type === "earned" && (
        <p className="text-center">
          <button
            className="py-1 px-2 mt-2 text-sm text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none ml-auto"
            onClick={addInvite}
          >
            Enroll Seeker
          </button>
        </p>
      )}
      {enrollment?.hasOwnProperty("id") &&
        type === "earned" &&
        status == "active" &&
        !enrollment?.hasOwnProperty("manuallyAssessed") &&
        allowRetry &&
        (user?.role.id === "sadmin" || user?.role.id === "tac") && (
          <p className="text-center">
            <button
              className="py-1 px-2 mt-2 text-sm text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none ml-auto"
              onClick={retryAssessment}
            >
              Allow Retry
            </button>
          </p>
        )}
      {status === "delete" &&
        !enrollment?.hasOwnProperty("manuallyAssessed") &&
        user?.role.id === "sadmin" && (
          <div className="w-full text-center">
            <button
              disabled
              className="py-1 px-2 mt-2 text-sm text-white bg-red-800 rounded border-0 focus:outline-none ml-auto shadow-xl"
            >
              Removing Invite
            </button>
          </div>
        )}
      {status === "retry" &&
        !enrollment?.hasOwnProperty("manuallyAssessed") && (
          <div className="w-full text-center">
            <button
              disabled
              className="py-1 px-2 mt-2 text-sm text-white bg-green-800 rounded border-0 focus:outline-none ml-auto shadow-xl"
            >
              Creating Retry Invite
            </button>
          </div>
        )}
      {status === "add" && (
        <div className="w-full text-center">
          <button
            disabled
            className="py-1 px-2 mt-2 text-sm text-white bg-green-800 rounded border-0 focus:outline-none ml-auto shadow-xl"
          >
            Inviting Seeker
          </button>
        </div>
      )}
      {enrollment?.hasOwnProperty("manuallyAssessed") &&
        user?.role.id === "sadmin" && (
          <>
            <p className="text-center">
              Assessed by : {manualUserData?.name || "Unknown"}
            </p>
            <p className="text-center">
              <button
                className="py-1 px-2 mt-2 text-sm text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none ml-auto"
                onClick={deleteManual}
              >
                Remove Manual Assessment
              </button>
            </p>
          </>
        )}
      {isShowing && enrollment?.status === "completed" && (
        <DialogWrapper
          onClose={() => {
            toggle();
          }}
          title="Assessment Details"
          size="w-1/2 h-auto mt-36"
        >
          <EnrollmentDetails enrollment={enrollment || {}} />
        </DialogWrapper>
      )}
    </div>
  );
}
export default SkillBadge;
