import { useState,useRef } from "react";
import { useAsyncError, useNavigate } from "react-router-dom";
import { useAuth } from "../../../providers/auth";
import { isEmpty } from "lodash";
import { createJobDescription, useJobDescriptionSkills } from "../../../data/jobDescriptions";
import { useAllActiveEmployers } from "../../../data/employer";
import { useEmployerJobs } from "../../../data/employer";
import { useJobDescription } from "../../../data/jobDescriptions";
import { useJobDescriptionFiles } from "../../../data/jobDescriptions";
import { writeFileLocation } from "../../../data/jobDescriptions";
import { useEffect } from "react";

function CreateJobBasicForm({
  employerId,
  onDone = () => { },
  toggle,
  handleFormAlert,
}: {
  employerId?: string;
  onDone?: Function;
  toggle: Function;
  handleFormAlert: Function;
}) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [empId, setEmpId] = useState("all");
  const { data: employers } = useAllActiveEmployers(empId);
  const [pending, setPending] = useState(false);
  const [employerValue, setEmployerValue] = useState(employerId);
  const [title, setTitle] = useState("");
  const [selectedJObToClone, setSelectedJObToClone] = useState("");
  const [isOn, setIsOn] = useState(false);
  const [errorInCloneJb, setErrorInCloneJb] = useState(false);

  const { data: job = {}, isLoading } = useJobDescription(selectedJObToClone); 
  const { data: files } = useJobDescriptionFiles(selectedJObToClone);
  const handleClick = () => {
    setIsOn(!isOn);
  };
  const {
    data: jobs = [],
    status: jobsStatus,
    error: jobsError,
  } = useEmployerJobs(employerValue);


  const selectRef = useRef(null);
  const onSubmit = async () => {
    if (!isEmpty(employerValue) && !isEmpty(title)) {
      setPending(true);

      // if (
      //   isOn &&
      //   (
      //     job?.description === undefined ||
      //     job?.eInterview === undefined ||
      //     job?.employerSummary === undefined ||
      //     job?.information?.model === undefined ||
      //     job?.information?.term === undefined ||
      //     job?.information?.classification === undefined ||
      //     job?.information?.hoursPerWeek === undefined ||
      //     job?.requireResume === undefined ||
      //     job?.searchTags === undefined ||
      //     job?.speedTest === undefined ||
      //     job?.summaryCount === undefined ||
      //     job?.technology === undefined
      //   )
      // ) {
      //   setErrorInCloneJb(true)
      //   setPending(false);
      //   return;
      // }

     const jobDescription = isOn ?
        {
          employer: employerValue,
          title: title,
          createdBy: user.id,
          status: "tac_review",
          description: job?.description || {},
          eInterview: job?.eInterview || [],
          employerSummary: job?.employerSummary,
          model: job?.information?.model,
          term: job?.information?.term,
          payType: ["SAL"],
          hoursPerWeek: job?.information?.hoursPerWeek,
          requireResume: job?.requireResume || false,
          classification: job?.information?.classification,
          searchTags: job?.searchTags || [],
          seats: job?.seats || "1",
          speedTest: job?.speedTest || false,
          summaryCount: job?.summaryCount || {},
          solutionType: job?.solutionType || "BPO",
          technology: job?.technology || {}
        } : {
          employer: employerValue,
          title: title,
          createdBy: user.id,
          status: "tac_review",
        };

      await createJobDescription(jobDescription)
        .then((data) => {
          handleFormAlert("success");
          files?.map((fileData: any) => {
            writeFileLocation(data, {
              label: fileData.file.label,
              type: fileData.file.type,
              link: fileData.file.link,
            })
          })
          setTimeout(() => {
            onDone();
            toggle();
            navigate(
              !employerId
                ? `/jobs/${data}/${employerValue}/description`
                : `/jobs/new/${data}`,
            );
          }, 5000);
        })
        .catch(() => {
          handleFormAlert("error");
          setPending(false);
        });

      return;
    }
  };
  return (
    <>
      <div>
        {!employerId ? (
          <div className="mb-2">
            <select
              name="employer"
              className="w-full py-1 leading-8 bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-1"
              onBlur={(event) => setEmployerValue(event.target.value)}
              onChange={(event) => setEmployerValue(event.target.value)}
            >
              <option value=""></option>
              {employers?.map((employer: any) => (
                <option key={employer.id} value={employer.id}>
                  {employer.displayName}
                </option>
              ))}
            </select>
          </div>
        ) : null}
        <div className="mb-2">
          <input
            className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            name="title"
            type="text"
            value={title}
            placeholder="Job Title"
            onChange={(event) => setTitle(event.target.value)}
          />
        </div>
        <div>
          <div className="flex items-center mt-3 mb-3">
            <button
              type="button"

              className={`cursor-pointer ${isOn ? "bg-instant-teams-green-Main" : "bg-gray-300"
                } relative inline-flex items-center h-6 rounded-full w-11`}
              onClick={handleClick}
            >
              <span
                className={`${isOn ? "translate-x-6" : "translate-x-1"
                  } inline-block w-4 h-4 transform bg-white rounded-full`}
              />
            </button>
            <span className="ml-3">Clone Jobs</span>
          </div>
          {isOn && (
            <div className="mb-2">
              <select
                name="employer"
                ref={selectRef}
                className="w-full py-1 leading-8 bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-1"
                onClick={() => {
                  const selectedValue = selectRef.current?.value;         
                  setSelectedJObToClone(selectedValue);
                  setErrorInCloneJb(false);
                }}
              >
                <option className="bg-gray-200 mt-2 py-3" value="" disabled selected>
                  Select a job
                </option>
                {jobs?.map((job: any) => (
                  <option key={job.id} value={job.id}>
                    {job.title}
                  </option>
                ))}
              </select>
            </div>
          )}

        </div>
        <button
          className="py-2 px-4 w-full text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
          type="submit"
          onClick={onSubmit}
          disabled={pending}
        >
          {pending ? "..." : "Create New Job"}
        </button>
        {errorInCloneJb && <p className="mt-1 mb-1 text-red-500 ">Existing job doesn't have enough information to clone`</p>}
      </div>
    </>
  );
}

export default CreateJobBasicForm;
