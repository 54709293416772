import { useParams } from "react-router-dom";
import GreenUnderlineHeading from "../../../custom-ui/headings/heading";
import Meta from "../../../custom-ui/meta/Meta";
import ReqSkillItemBasic from "../../../jobs/parts/ReqSkillItemBasic";
import { useEmploymentByEmployee } from "../../../../data/employee";
import { useUser } from "../../../../providers/database";
import { useJobOfferByEmployee } from "../../../../data/offers";
import { useApplication } from "../../../../data/applications";
import { useAuth } from "../../../../providers/auth";
import AnalyticsLogger from "../../../../providers/analyticsLogger";
import { collectionNames } from "../../../../data/dictionary/collectionNames";
const EmployeeSkills = () => {
  const params = useParams();
  const employeeId = params?.employeeId || "";
  const { userClaims } = useAuth();
  const { data: employmentRecord } = useEmploymentByEmployee(employeeId || "");
  const { data: employeeData, status: userDataStatus } = useUser(
    employmentRecord?.userId || "",
  );
  const { data: jobOfferData } = useJobOfferByEmployee(
    employmentRecord?.jobId,
    employeeData?.id,
  );
  const { data: applicationData } = useApplication(
    jobOfferData?.[0]?.applicationId,
  );

  const { requiredSkills } = applicationData ?? {};

  return (
    <div className="mb-36">
      <Meta title="Employee - Skills" />
      {userClaims?.claims?.type === "E" ? (
        <AnalyticsLogger type={collectionNames.employees} typeId={employeeId} />
      ) : null}
      <GreenUnderlineHeading title="Skills" />
      <div className="grid grid-cols-2 gap-4">
        {userDataStatus === "success" && (
          <>
            <div className="w-[100%]">
              <div className="grid grid-cols-4 gap-2">
                {requiredSkills &&
                  requiredSkills.map((skill: any, index: any) => (
                    <div key={`${skill}${index}`}>
                      <ReqSkillItemBasic
                        assessment={skill}
                        userId={employeeData?.id}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EmployeeSkills;
