import React from "react";

import {
  usePendingJobOffers,
  useSignedOfferJobOffers,
} from "../../../data/offers";
import { usePreOfferApplications } from "../../../data/applications";
import { DisplayDate } from "../../../util/timestamps/timestamp";
import { useState } from "react";

import OffersDashGreenUnderlineHeading from "./parts/OffersDashHeading";
import OffersCount from "./parts/OffersCount";
import OffersFilter from "./parts/OffersFilter";
import { useEffect } from "react";

import PreOffersItem from "./parts/PreOffersItem";
import SignedOffersItem from "./parts/SignedOffersItem";
import PendingOffersItem from "./parts/PendingOffersItem";
import { offersDataAggregationCallable } from "../../../callable-cloud-functions/cloudFunctions";

const OffersDash = () => {
  const [selectedOffers, setSelectedOffers] = useState([]);
  const clearSelectedOffers = () => {
    setSelectedOffers([]);
  };

  const selectAllOffers = () => {
    setSelectedOffers(signedOffersProcessed);
  };
  const { data: preOffers = [], status: preOffersQueryStatus } =
    usePreOfferApplications("preOffers");
  const { data: pendingOffers = [], status: pendingOffersQueryStatus } =
    usePendingJobOffers("pendingOffers");
  const { data: signedOffers = [], status: signedOffersQueryStatus } =
    useSignedOfferJobOffers("signedOffers");

  const processOffers = (offers: [], type: string) => {
    const ret: any = [];
    for (const offer of offers) {
      if (type === "preOffer") {
        const processedOffer: any = {
          employerId: offer["employer"],
          jobId: offer["jobId"],
          userId: offer["resumeId"],
          applicationId: offer["id"],
        };
        ret.push(processedOffer);
      } else if (type === "pending" || type === "signed") {
        const processedOffer = {
          employerId: offer["employerId"],
          jobId: offer["jobId"],
          positionId: offer["positionId"],
          payRate: offer["payRate"],
          signedOfferLetterPath: offer["signedOfferLetterPath"],
          startDate: DisplayDate(offer["startDate"]),
          id: offer["id"],
          resumeId: offer["resumeId"],
        };
        ret.push(processedOffer);
      }
    }
    return ret;
  };

  // used for the filtering, updates the numbers in the right corner GreenUnderline Section && CSV Download
  const preOffersProcessed =
    preOffersQueryStatus === "success" && preOffers.length > 0
      ? processOffers(preOffers, "preOffer")
      : [];
  const pendingOffersProcessed =
    pendingOffersQueryStatus === "success" && pendingOffers.length > 0
      ? processOffers(pendingOffers, "pending")
      : [];
  const signedOffersProcessed =
    signedOffersQueryStatus === "success" && signedOffers.length > 0
      ? processOffers(signedOffers, "signed")
      : [];

  return (
    <div className="min-h-screen mt-8">
      <div className="grid grid-cols-8 gap-x-4">
        <div className="grid col-span-7 gap-2"></div>
        <div className="grid col-span-1 gap-2 justify-end">
          <OffersFilter processOffers={processOffers} />
        </div>
      </div>
      <div className="grid grid-cols-8 gap-x-4">
        <div className="grid col-span-2 gap-2">
          <OffersDashGreenUnderlineHeading title={"PRE-OFFERS"}>
            <OffersCount
              count={preOffersProcessed.length}
              tooltip={"Number of offers in the PRE-OFFER stage"}
            />
          </OffersDashGreenUnderlineHeading>
        </div>
        <div className="grid col-span-3 gap-2">
          <OffersDashGreenUnderlineHeading title={"PENDING OFFERS"}>
            <OffersCount
              count={pendingOffersProcessed.length}
              tooltip={"Number of offers in the PENDING OFFERS stage"}
            />
          </OffersDashGreenUnderlineHeading>
        </div>
        <div className="grid col-span-3 gap-2">
          <OffersDashGreenUnderlineHeading
            title={"SIGNED OFFERS"}
            data={selectedOffers}
            hasDataAggregation={true}
            dataAggregator={offersDataAggregationCallable}
            selectedOffers={selectedOffers}
            clearSelectedOffers={clearSelectedOffers}
            selectAllOffers={selectAllOffers}
          >
            <OffersCount
              count={signedOffersProcessed.length}
              tooltip={"Number of offers in the SIGNED stage"}
            />
          </OffersDashGreenUnderlineHeading>
        </div>
      </div>
      <div className="grid grid-cols-8 gap-x-4">
        <div className="grid col-span-2 gap-2 content-start">
          {preOffersProcessed && preOffersProcessed.length > 0
            ? preOffersProcessed?.map((offer: any, index: any) => {
                return <PreOffersItem offer={offer} key={"PreOffer" + index} />;
              })
            : null}
        </div>
        <div className="grid col-span-3 gap-2 content-start">
          {pendingOffers && pendingOffers.length > 0
            ? pendingOffers?.map((offer: any, index: any) => {
                return <PendingOffersItem offer={offer} key={"PendingOffer" + index} />;
              })
            : null}
        </div>
        <div className="grid col-span-3 gap-2 content-start">
          {signedOffersProcessed && signedOffersProcessed.length > 0
            ? signedOffersProcessed?.map((offer: any, index: any) => {
                return (
                  <SignedOffersItem
                    offer={offer}
                    key={"SingedOffer" + index}
                    selectedOffers={selectedOffers}
                    setSelectedOffers={setSelectedOffers}
                    signedOffersProcessed={signedOffersProcessed}
                  />
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default OffersDash;
