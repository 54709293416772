import { camel } from "../../../../util/strings/stringManipulation";

function CourseQuestions(details) {
  return (
    <ul className="w-full ml-5 mr-5">
      {details?.details &&
        details?.details.map((item, index) => {
          return (
            <>
              {item.preview ? (
                <></>
              ) : (
                <li className="w-full" key={index}>
                  <div className="w-full flex text-sm pr-4 mb-2">
                    {item?.question_count && item?.question_count > 0 ? (
                      <>
                        <span className="font-bold float-left w-3/4">
                          {camel(item.description)}
                        </span>
                        <span className="float-right text-right w-1/4 pr-2">
                          {item.question_count}
                        </span>
                      </>
                    ) : null}
                  </div>
                </li>
              )}
            </>
          );
        })}
    </ul>
  );
}

export default CourseQuestions;
