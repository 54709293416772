import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const DisplaySkillNames = ({ skillname, displayCloseIcon, customSkill = false, removeSkill, sizeSmall =false}) => {
    return (
        <div className={`inline-flex items-center px-2 ${sizeSmall?"":"py-1"} ${customSkill ? "bg-[#B5E3F3]" : "bg-[#F2F2F2]"} border-2 ${customSkill ? "border-[#A6D8EA]" : "border-gray-200"} rounded-3xl mt-2 mr-2`}>
            <p className={`text-center ${sizeSmall ?"text-sm px-2":""} t`}>{skillname}</p>
            {displayCloseIcon && (
                <div className="flex ml-2 items-center justify-center w-5 h-5 bg-white rounded-full cursor-pointer"
                    onClick={removeSkill}
                >
                    <FontAwesomeIcon icon={faTimes} color="#565656" size="small" />
                </div>
            )}
        </div>
    );
}

export default DisplaySkillNames;
