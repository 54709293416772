import React from "react";
import Meta from "../components/custom-ui/meta/Meta";
import AuthSection from "../components/auth/AuthSection";
import SeekerAuthSection from "../components/seeker/details/pages/SeekerAuthSection";
import { checkForAuth } from "../providers/auth";
import { useParams } from "react-router-dom";

function AuthPage(props) {
  const params = useParams();
  const { hostname } = window.location;
  const hostKey = process.env.REACT_APP_JOBS_URL;
  const showSeekerLogin = !!hostname.match(hostKey);

  return (
    <div className=" bg-white xs:pt-0 sm:pt-0">
      <Meta title="Instant Teams" />
      {showSeekerLogin ? (
        <SeekerAuthSection
          type={params.type}
          afterAuthPath={params.next || "/dashboard/home"}
        />
      ) : (
        <AuthSection
          type={params.type}
          providers={["instantTeamsWorkspace"]}
          afterAuthPath={params.next || "/dashboard/home"}
        />
      )}
    </div>
  );
}

export default checkForAuth(AuthPage);
