import React from "react";
import Navbar from "./Navbar";
import { useAuth } from "../../providers/auth";
import { useLocation } from "react-router-dom";

function Header(props) {
  const { user } = useAuth();

  return (
    <>
      <header className="fixed bg-instant-teams-teal-Main text-white left-0 right-0 top-0 h-[5.4rem] z-50 sm:hidden xs:hidden ">
        <div className=" bg-white drop-shadow-md w-40 py-6 px-10 rounded-r-xl z-10 fixed ml-0">
          <img
            src="/images/instant-teams-logo/InstantTeamsIcon-05.png"
            alt="Instant Teams Logo"
            width={76}
            height={76}
          />
        </div>
        <div className="">
          <Navbar />
        </div>
      </header>
      <div className="hidden xs:block sm:block bg-white pt-12 mx-auto text-center">
        <img
          className="mx-auto"
          src="/images/instant-teams-logo/InstantTeamsIcon-05.png"
          alt="Instant Teams Logo"
          width={152}
          height={152}
        />
      </div>
    </>
  );
}

export default Header;
