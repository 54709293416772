import React, { useState, useEffect } from "react";
import { updateAutoSave, logActivity } from "../../../data/utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useUsersByRole } from "../../../data/user";
import { useAuth } from "../../../providers/auth";

const AutoSaveSelectByRole = ({
  value: initailValue,
  role,
  collection,
  recId,
  required,
  name,
  display,
  placeholder,
  directions,
  readOnly = false,
}) => {
  const auth = useAuth();
  const [value, setValue] = useState(initailValue);
  const [check, setCheck] = useState(initailValue);
  const [isValid, setIsValid] = useState(true);
  const [saved, setSaved] = useState(false);

  const {
    data: lookupData = {},
    status: lookupStatus = "",
    error: lookupError,
  } = useUsersByRole(role);

  useEffect(() => {
    setTimeout(() => {
      setSaved(false);
    }, 3000);
  }, [saved]);

  const handleChange = (event) => {
    setValue(event.target.value);
    event.preventDefault();
  };

  const handleBlur = async () => {
    if (!value && required) {
      setIsValid(false);
      return;
    }
    if (value !== check) {
      setIsValid(true);
      setCheck(value);
      await updateAutoSave(collection, recId, { [name]: value });
      await logActivity(
        collection,
        recId,
        name,
        check,
        value,
        auth.user.uid,
        check === "" ? "Added" : "Edited",
      );
      setSaved(true);
    }
  };

  return (
    <div key={name} id={name} className="relative">
      <label className="text-instant-teams-teal-Main text-sm  absolute  duration-300 transform -translate-y-4 scale-75 top-4 origin-[0] left-2.5 peer-focus:text-instant-teams-blue-Main peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
        {display} {directions ? " - " + directions : null}
        {readOnly ? " - LOCKED" : null}
      </label>
      <select
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        className="form-input shadow-md rounded-md w-full pt-3"
        disabled={readOnly}
      >
        <option disabled={true} value="" defaultValue={""}>
          {placeholder}
        </option>
        {Object.keys(lookupData).length ? (
          lookupData.map((key) => (
            <option key={key.id} value={key.id}>
              {key.preferredName}
            </option>
          ))
        ) : (
          <option disabled={true}>N/A</option>
        )}
      </select>
      {saved && (
        <span className="absolute right-4 top-2 text-xs">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "green" }} />
        </span>
      )}
      {!isValid && <div className="error">This field is required.</div>}
    </div>
  );
};

export default AutoSaveSelectByRole;
