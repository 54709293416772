function ProgressDots({
  totalSteps = 1,
  activeStep = 1,
}: {
  totalSteps: number;
  activeStep: number;
}) {
  // make an array of incrementing numbers from totalSteps
  const range = Array.from({ length: totalSteps }, (x, index) => index + 1);

  return (
    <>
      {activeStep <= totalSteps && (
        <div>
          {Array.isArray(range) && (
            <div className="flex justify-center w-32">
              {range.map((val, idx) =>
                val <= activeStep ? (
                  <i
                    key={idx}
                    className="fa-solid fa-circle-small px-2 text-instant-teams-blue-Main"
                  ></i>
                ) : (
                  <i
                    key={idx}
                    className="fa-solid fa-circle-small px-2 text-instant-teams-blue-Main/50"
                  ></i>
                ),
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ProgressDots;
