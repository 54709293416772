import React, { useState, useEffect } from "react";
import { updateAutoSave, logActivity } from "../../../data/utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faCircleExclamation } from "@fortawesome/pro-duotone-svg-icons";
import { useAuth } from "../../../providers/auth";

const AutoSaveText = ({
  value: initialValue,
  collection,
  recId,
  required,
  name,
  display,
  placeholder,
  directions,
  readOnly,
  maxlength = 50,
}) => {
  const auth = useAuth();
  const [coreValue, setValue] = useState(initialValue || "");
  const [check, setCheck] = useState(initialValue);
  const [isLink, setIsLink] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [saved, setSaved] = useState(false);
  const [length, setLength] = useState(0);
  const [error, setError] = useState(false);
  const [maxLength, setMaxLength] = useState(maxlength);

  useEffect(() => {
    if (
      (coreValue && coreValue.toString().includes("http://")) ||
      (coreValue && coreValue.toString().includes("https://"))
    ) {
      setIsLink(true);
      setMaxLength(100);
    } else {
      setIsLink(false);
    }
    setTimeout(() => {
      setSaved(false);
    }, 3000);
  }, [saved, coreValue]);

  useEffect(() => {
    if (initialValue !== null && initialValue !== undefined) {
      setValue(initialValue);
      setLength(initialValue.length);
    } else {
      setValue("");
      setLength(0);
    }
  }, [initialValue]);

  const handleBlur = async () => {
    if (!coreValue && required) {
      setIsValid(false);
      return;
    }
    if (coreValue !== check) {
      if (length <= maxlength) {
        setIsValid(true);
        setCheck(coreValue);
        await updateAutoSave(collection, recId, { [name]: coreValue });
        await logActivity(
          collection,
          recId,
          name,
          check,
          coreValue,
          auth.user.uid,
          check === "" ? "Added" : "Edited",
        );
        setSaved(true);
        setError(false);
      } else {
        setError(true);
      }
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    setLength(e.target.value.length);
  };

  useEffect(() => {
    if (length <= maxLength) {
      setError(false);
    } else {
      setError(true);
    }
  }, [length]);

  return (
    <div id={name} className="flex-auto">
      <div className="relative mb-4">
        <label className="text-instant-teams-teal-Main text-sm  absolute  duration-300 transform -translate-y-4 scale-75 top-4 origin-[0] left-2.5 peer-focus:text-instant-teams-blue-Main peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
          {display} {directions ? " - " + directions : null}
          {readOnly ? " - LOCKED" : null}
          {isLink ? (
            <a
              href={coreValue}
              className="flex items-center cursor-pointer"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 pl-1"
                viewBox="0 0 448 512"
              >
                <path
                  className="fa-primary"
                  d="M256 64C256 46.33 270.3 32 288 32H416C433.7 32 448 46.33 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64z"
                />
                <path
                  className="fa-secondary "
                  d="M0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z"
                />
              </svg>
            </a>
          ) : null}
        </label>
        <input
          type="text"
          name={name}
          placeholder={placeholder}
          value={coreValue}
          onChange={(e) => handleChange(e)}
          onBlur={handleBlur}
          className={
            isLink
              ? "form-input shadow-md rounded-md w-full pt-3 pb-3 pl-7"
              : "form-input shadow-md rounded-md w-full pt-3 pb-3"
          }
          readOnly={readOnly}
        />
        {saved && (
          <span className="absolute right-2 top-2 text-xxs">
            {display} saved!{" "}
            <FontAwesomeIcon icon={faCircleCheck} style={{ color: "green" }} />
          </span>
        )}
        {length <= maxLength ? (
          <></>
        ) : (
          <span className="absolute right-2 top-8 text-xxs">
            Entry Exceeds Maximum Characters
            <FontAwesomeIcon
              icon={faCircleExclamation}
              style={{ color: "red" }}
            />
          </span>
        )}
        {error && (
          <div className="error text-xs">Unable to save, check field</div>
        )}
        {!isValid && (
          <div className="error text-xs">This field is required.</div>
        )}
      </div>
    </div>
  );
};

export default AutoSaveText;
