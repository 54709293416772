import PricingDetails from "../parts/pricingDetails";
import ComponentLinks from "../parts/links";
import KnockoutTestDetails from "../parts/knockoutTestDetails";
import TestDescription from "../parts/testDescription";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useComponent } from "../../../data/academy";

function AssessmentOverview() {
  const params = useParams();
  const [componentId, setComponentId] = useState(params.assessmentId || "");
  const { data: component } = useComponent(componentId);
  return (
    <>
      <div className="w-1/2 ml-10">
        <PricingDetails pricing={component?.pricing} />
        <ComponentLinks links={component?.public_links} />
      </div>
      <div className="w-1/2 ml-10">
        <KnockoutTestDetails knockout={component?.knockout_tests_detail} />
      </div>
      <div className="w-1/2 ml-10">
        <TestDescription details={component?.description} />
      </div>
    </>
  );
}

export default AssessmentOverview;
