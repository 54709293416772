import React, { useState, useEffect, useRef } from "react";
import AutoSaveText from "../custom-ui/input-fields/auto-save-field";
import { useParams } from "react-router-dom";
import { useRecording } from "../../data/recordings"


type Video= {
  id: string,
  slug: string,
  timestamp: string,
  video: string,
}

const VideoDetails = () => {  
  const params =  useParams();
  const [videoId, setVideoId] = useState(params.recordingId || "");
  const [videoURL, setVideoURL] = useState(null);
  const { data: video } = useRecording(videoId);

  useEffect(() => {
    if (video) {
      setVideoURL(video.video);
    }
  }, [video]);

  return (
    <div>
      <div className='grid grid-cols-2 gap-1'>
        <div className= "text-center p-4">
          <AutoSaveText
            value={video?.title}
            collection="Videos"
            recId={videoId}
            required={true}
            name="title"
            display="Title"
            placeholder={"Title"}
            directions=""
            readOnly={false}
          />
          <AutoSaveText
            value={video?.slug}
            collection="Videos"
            recId={videoId}
            required={true}
            name="slug"
            display="Video Slug"
            placeholder={"Slug"}
            directions=""
            readOnly={false}
          />
        </div>
        <div className= "text-center">
          <div className='mx-auto'>
            {
              videoURL? (
                <video controls>
                  <source src={videoURL} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <p>Loading...</p>
              )
            }
          </div>
        </div>     
      </div>
    </div>
  );
};

export default VideoDetails;
