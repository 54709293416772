import { Link } from "react-router-dom";
import { TailwindHoverEnlarge } from "../../../util/tailwind/tailwind-styles";
function SeekerViewCardWrapper({
  link,
  children,
}: {
  link: string;
  children: JSX.Element;
}) {
  return (
    <div className="w-full">
      <Link to={link}>
        <div
          className={
            TailwindHoverEnlarge(103) +
            " grid grid-flow-row grid-cols-5 h-32 w-full mx-auto cursor-pointer lg:w-4/5   text-instant-teams-blue-Main bg-white rounded-lg shadow-input-field"
          }
        >
          {children}
        </div>
      </Link>
    </div>
  );
}
export default SeekerViewCardWrapper;
