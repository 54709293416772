import React, { useState, useEffect } from "react";
import { AccessLog } from "../../dataTypes/AccessLogs";
import { useUser } from "../../providers/database";
import { DisplayTimestamp } from "../../util/timestamps/timestamp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faApple,
  faChrome,
  faEdge,
  faFirefox,
  faOpera,
  faSafari,
  faUbuntu,
  faWindows,
} from "@fortawesome/free-brands-svg-icons";
import { faBrowser } from "@fortawesome/pro-duotone-svg-icons";
import { faDesktop } from "@fortawesome/pro-regular-svg-icons";
import platform from "platform";
const AccessLogItem = ({ entry }: { entry: AccessLog }) => {
  const { data: userInfo } = useUser(entry.uid);
  const plaformInfo = platform.parse(entry.userAgent);
  const timestamp = DisplayTimestamp(entry?.timestamp);
  let browser: any = plaformInfo?.name;
  switch (browser) {
    case "Chrome":
      browser = (
        <div>
          <FontAwesomeIcon icon={faChrome} className="mr-1" /> v:{" "}
          {plaformInfo.version}
        </div>
      );

      break;
    case "Safari":
      browser = (
        <div>
          <FontAwesomeIcon icon={faSafari} className="mr-1" /> v:{" "}
          {plaformInfo.version}
        </div>
      );
      break;
    case "Edge":
      browser = (
        <div>
          <FontAwesomeIcon icon={faEdge} className="mr-1" /> v:{" "}
          {plaformInfo.version}
        </div>
      );
      break;
    case "Firefox":
      browser = (
        <div>
          <FontAwesomeIcon icon={faFirefox} className="mr-1" /> v:{" "}
          {plaformInfo.version}
        </div>
      );
      break;
    case "Opera":
      browser = (
        <div>
          <FontAwesomeIcon icon={faOpera} className="mr-1" /> v:{" "}
          {plaformInfo.version}
        </div>
      );
      break;
    default:
      browser = (
        <div>
          <FontAwesomeIcon icon={faBrowser} /> {plaformInfo.name} v:{" "}
          {plaformInfo.version}
        </div>
      );
      break;
  }

  let os = React.NodeChild;
  if (plaformInfo?.os) {
    os = plaformInfo.os.family;
    switch (os) {
      case "OS X":
        os = <FontAwesomeIcon icon={faApple} />;
        break;
      case "Windows":
        os = <FontAwesomeIcon icon={faWindows} />;
        break;
      case "Ubuntu":
        os = <FontAwesomeIcon icon={faUbuntu} />;
        break;
      default:
        os = (
          <div>
            <FontAwesomeIcon icon={faDesktop} /> - {plaformInfo?.os?.family}
          </div>
        );
    }
  } else {
    return (os = <div>Undetected OS</div>);
  }

  return (
    <div className="flex w-full bg-white shadow-md rounded-md p-1 m-3 space-x-5">
      <div className="flex items-center w-1/6">
        {userInfo?.preferredName ?? userInfo?.name}
      </div>
      <div className="flex items-center w-1/6">{timestamp}</div>
      <div className="flex items-center w-1/2">{entry.ip}</div>
      <div className="flex items-center space-x-3">
        <div className="flex items-center ">{os}</div>
        <div className="flex items-center">{browser}</div>
      </div>
    </div>
  );
};

export default AccessLogItem;
