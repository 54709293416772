import { useQuery } from "react-query";
import {
  doc,
  addDoc,
  collection,
  query,
  orderBy,
  where,
  getDoc,
  getDocs,
  updateDoc,
  limit,
  deleteDoc,
  arrayRemove,
  writeBatch,
} from "firebase/firestore";
import { createQuery, db } from "../providers/database";
import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";
import { Timestamp } from "firebase/firestore";

export function useAllChats() {
  return useQuery(
    ["allChats"],
    createQuery(() => {
      return query(
        collection(db, collectionNames.chats),
        orderBy("createdAt", "asc"),
      );
    }),
  );
}

export function useAllChatsByGroup(group, status) {
  return useQuery(
    ["allChatsByGroup", { group, status }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.chats),
        where("status", "==", status),
        where("group", "==", group),
      );
    }),
  );
}

export function useAllPersonalChats(id, status) {
  return useQuery(
    ["allPersonalChats", { id, status }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.chats),
        where("status", "==", status),
        where("messageType", "==", "personal"),
        where("users", "array-contains", id),
      );
    }),
  );
}

export function useAllChatsByRecipientId(id, status) {
  return useQuery(
    ["allChatsByRecipient", { id, status }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.chats),
        where("status", "==", status),
        where("recipientId", "==", id),
      );
    }),
  );
}

export async function updateMessageIsRead(chatId, messageId, userId) {
  const messageRef = doc(
    db,
    `${collectionNames.chats}/${chatId}/${subCollectionNames.messages}`,
    messageId,
  );

  const messageDoc = await getDoc(messageRef);
  if (messageDoc.exists() && messageDoc.data().recipientId === userId) {
    const data = { isRead: true, readAt: Timestamp.now() };
    await updateDoc(messageRef, data);
    console.log("Message updated successfully!");
  } else {
    console.log("Message not found or recipient ID does not match");
  }
}

// this function creates a new conversation with a specified user

export function createNewConversation(data) {
  const { userId, body } = data;

  const docData = {
    ...data,
    createdBy: userId,
    createdAt: Timestamp.now(),
    lastUpdated: Timestamp.now(),
  };

  return addDoc(collection(db, collectionNames.chats), docData).then((res) => {
    const chatId = res.id;
    const messageData = {
      chatId: chatId,
      body: body,
      senderId: userId,
      readBy: [userId],
      createdAt: Timestamp.now(),
      lastUpdated: Timestamp.now(),
    };
    return addDoc(
      collection(
        db,
        `${collectionNames.chats}/${chatId}/${subCollectionNames.messages}`,
      ),
      { ...messageData },
    );
  });
}

export function useAllMessagesByChatId(id) {
  return useQuery(
    ["allMessagesByChat", { id }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.chats}/${id}/${subCollectionNames.messages}`,
        ),
        orderBy("createdAt"),
      );
    }),
    { enabled: !!id },
  );
}

export async function markChatRead(userId, chatId) {
  try {
    const chatRef = doc(db, collectionNames.chats, chatId);
    await updateDoc(chatRef, {
      unreadUsers: arrayRemove(userId),
    });
    console.log("Chat marked as read.");
  } catch (error) {
    console.error("Error marking chat as read:", error);
  }
}

export async function markReadMessage(userId, messageId, chatId) {
  const messageRef = doc(
    db,
    collectionNames.chats,
    chatId,
    subCollectionNames.messages,
    messageId,
  );

  const messageDoc = await getDoc(messageRef);
  const readBy = messageDoc.get("readBy") || [];

  if (!readBy.includes(userId)) {
    readBy.push(userId);
    await updateDoc(messageRef, { readBy });
  }
}

export function newestMessage(chatId) {
  const subCollectionRef = collection(
    db,
    collectionNames.chats,
    chatId,
    subCollectionNames.messages,
  );

  const queryPromise = getDocs(
    query(subCollectionRef, orderBy("createdAt", "desc"), limit(1)),
  );

  return queryPromise.then((querySnapshot) => {
    if (querySnapshot.empty) {
      return null; // No messages found
    } else {
      const doc = querySnapshot.docs[0];
      return { id: doc.id, data: doc.data() };
    }
  });
}

export function sendMessage(
  senderId,
  docId,
  chatId,
  messageText,
  linkData,
  imageData,
  fileData,
  unreadUserIds,
  recipientEmail,
) {
  updateDoc(doc(db, collectionNames.chats, chatId), {
    lastUpdated: Timestamp.now(),
    unreadUsers: [...unreadUserIds],
    recipientEmail: recipientEmail,
  });

  return updateDoc(
    doc(db, collectionNames.chats, chatId, subCollectionNames.messages, docId),
    {
      body: messageText,
      link: linkData,
      image: imageData,
      file: fileData,
      isTyping: false,
      readBy: [senderId],
      createdAt: new Date(),
    },
  );
}

export function sendLinkMessage(
  senderId,
  docId,
  chatId,
  link,
  unreadUserIds,
  recipientEmail,
) {
  updateDoc(doc(db, collectionNames.chats, chatId), {
    lastUpdated: Timestamp.now(),
    unreadUsers: [...unreadUserIds],
    recipientEmail: recipientEmail,
  });

  return updateDoc(
    doc(db, collectionNames.chats, chatId, subCollectionNames.messages, docId),
    {
      body: "",
      link: link,
      isTyping: false,
      readBy: [senderId],
      createdAt: new Date(),
    },
  );
}

export function handleTypingMessage(chatId, senderId) {
  const messagesRef = collection(
    db,
    collectionNames.chats,
    chatId,
    subCollectionNames.messages,
  );
  updateDoc(doc(db, collectionNames.chats, chatId), {
    lastUpdated: Timestamp.now(),
  });
  return addDoc(messagesRef, {
    chatId,
    senderId,
    body: "Is typing...",
    isTyping: true,
    readBy: [senderId],
    createdAt: new Date(),
  }).then((docRef) => docRef.id);
}

export function handleDeleteMessage(chatId, docId) {
  return deleteDoc(
    doc(db, collectionNames.chats, chatId, subCollectionNames.messages, docId),
  );
}

export function useUserPersonalChat(senderId, recipientId) {
  return useQuery(
    ["userPersonalChat", { senderId, recipientId }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.chats),
        where("createdBy", "==", senderId),
        where("recipientId", "==", recipientId),
        where("messageType", "==", "personal"),
      );
    }),
    { enabled: !!senderId && !!recipientId },
  );
}

export function useUserGroupChat(group, recipientId) {
  return useQuery(
    ["employeeGroupChats", { group, recipientId }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.chats),
        where("group", "==", group),
        where("recipientId", "==", recipientId),
      );
    }),
    { enabled: !!group && !!recipientId },
  );
}

export function useEmployerGroupChat(group, employerId) {
  return useQuery(
    ["employeeGroupChats", { group, employerId }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.chats),
        where("group", "==", group),
        where("employerId", "==", employerId),
      );
    }),
    { enabled: !!group && !!employerId },
  );
}

export function useAllChatsByEmployerId(employerId) {
  return useQuery(
    ["allEmployerGroupChats", { employerId }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.chats),
        where("employerId", "==", employerId),
      );
    }),
    { enabled: !!employerId },
  );
}

export async function markAllMessagesRead(userId) {
  const chatsRef = collection(db, collectionNames.chats);
  const querySnapshot = await getDocs(
    query(chatsRef, where("unreadUsers", "array-contains", userId)),
  );

  querySnapshot.forEach((doc) => {
    const docRef = doc.ref;
    const unreadUsersArray = doc.data().unreadUsers;

    if (unreadUsersArray.includes(userId)) {
      const updatedUnreadUsers = unreadUsersArray.filter((id) => id !== userId);
      updateDoc(docRef, { unreadUsers: updatedUnreadUsers });
    }
  });
}
