import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { camel } from "../../../util/strings/stringManipulation";
import { sanitizeMarkup } from "../../../util/sanitization/sanitize-html";
import "../../../styles/dangerouslySet.css";
function CustomTestQuestions(props) {
  const { details } = props;
  return (
    <div className="w-full ml-5 mr-5">
      {details &&
        details.map((item, index) => {
          return (
            <div className="w-full border-t border-black mb-4" key={index}>
              <div className="w-full text=bold text-instant-teams-teal-Main">
                {camel(item.title)} ({item.identifier}) - {camel(item.type)} (
                {Math.ceil(item.duration / 60)} mins)
              </div>
              <div className="w-full">
                <div
                  className="dangerously pr-4 rounded-lg overflow-hidden bg-white italic text-bold text-instant-teams-green-D2"
                  dangerouslySetInnerHTML={sanitizeMarkup(item.text)}
                ></div>
              </div>
              <div className="w-full text-instant-teams-blue-Main text-bold">
                Reasoning:
              </div>
              <div className="w-full text-instant-teams-blue-Main text-bold pr-4">
                {item.why_is_this_question_relevant || "None"}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default CustomTestQuestions;
