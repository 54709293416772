import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import { cn } from "../../../util/cn";

interface SelectGenericProps {
  value?: string;
  options: { id: string; label: string; [key: string]: any }[];
  optionValue?: string;
  name: string;
  display: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  required?: boolean;
  error?: string;
  touched?: boolean;
}

const SelectGeneric = forwardRef(
  (
    {
      value: initialValue = "",
      options = [],
      optionValue = "code",
      name,
      display,
      placeholder = "Select an option",
      disabled = false,
      onChange,
      onBlur,
      required = false,
      error,
      touched,
    }: SelectGenericProps,
    ref: any,
  ) => {
    const [value, setValue] = useState(initialValue);
    const [isValid, setIsValid] = useState(true);

    const handleChange = (event: any) => {
      const selectedValue = event.target.value;
      setValue(selectedValue);
      if (onChange) {
        onChange(selectedValue);
      }
    };

    const handleBlur = () => {
      if (!value && required) {
        setIsValid(false);
      } else {
        setIsValid(true);
        if (onBlur) {
          onBlur();
        }
      }
    };

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <div id={name} className="relative">
        <div className="relative mb-4">
          <label className="pl-1 text-instant-teams-teal-Main text-sm absolute duration-300 transform -translate-y-4 scale-75 top-4 origin-[0] left-2.5 peer-focus:text-instant-teams-blue-Main peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
            {display}
            {required && " *"}
          </label>
          <select
            disabled={disabled}
            name={name}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            //   className="form-input shadow-md rounded-md w-full pt-3"
            className={cn(
              "form-input shadow-md rounded-md w-full pt-4 pb-3 text-[1rem] text-gray-800 placeholder-gray-400",
              {
                "cursor-pointer": !disabled,
              },
            )}
            ref={ref}
          >
            <option disabled value="">
              {placeholder}
            </option>
            {options.length ? (
              options.map((item) => (
                <option key={item.id} value={item[optionValue]}>
                  {item.label}
                </option>
              ))
            ) : (
              <option disabled>N/A</option>
            )}
          </select>
          {/* {!isValid && <div className="error">This field is required.</div>} */}
          {error && (
            <p className={"text-[0.8rem] font-medium text-[red] mt-1"}>
              {error}
            </p>
          )}
        </div>
      </div>
    );
  },
);

SelectGeneric.displayName = "SelectGeneric";

export default SelectGeneric;
