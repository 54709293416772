import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Tooltip from "../../../custom-ui/tooltip/tooltip";
import { TailwindHoverEnlarge } from "../../../../util/tailwind/tailwind-styles";
import { getLevel } from '../../../../util/assessments/level';
function AcceleratorItem({ hit }: { hit: any }) {
  const [description, setDescription] = useState(
    hit.hit.shortDescription || "",
  );

  return (
    <Link to={`/learning/accelerator/${hit.hit.objectID}`}>
      <div
        key={hit.hit.id}
        className={
          TailwindHoverEnlarge(101) +
          "w-full h-full grid grid-cols-8 grid-flow-row bg-white shadow-md rounded-lg"
        }
      >
        <div className="flex items-center col-span-2 rounded-l-lg bg-instant-teams-teal-Main text-white">
          <p className="text-center text-lg font-bold w-full">
            {hit.hit.displayName || hit.hit.name}
          </p>
        </div>
        {/* <div className="items-center align-middle">
          <img
            src={hit?.hit?.badge || "/images/badge.png"}
            alt={hit.hit.name}
            className="mx-auto my-auto w-12 h-12 rounded-xl aspect-square"
          />
        </div>
        <div className="w-full flex items-center text-center text-sm">
          <span className="text-instant-teams-teal-Main font-bold">
            {hit.hit.visable === "active" ? "VISIBLE" : "HIDDEN"}
          </span>
        </div>
        <div className="w-full flex items-center text-center text-sm">
          <span className="text-instant-teams-teal-Main font-bold">
            {hit?.hit?.status.toUpperCase() || null}
          </span>
        </div>
        <div className="w-full flex items-center text-center text-sm">
          <span className="text-instant-teams-teal-Main font-bold">
            {hit?.hit?.tier
              ? hit?.hit?.tier?.one +
                "/" +
                hit?.hit?.tier?.two +
                "/" +
                hit?.hit?.tier?.three
              : "NO TIERS"}
          </span>
        </div>
        <div className="w-full flex items-center text-center text-sm">
          <Tooltip text="Completion Time">
            <div className="  text-instant-teams-teal-Main font-bold">
              {hit?.hit?.duration || "??"} mins
            </div>
          </Tooltip>
        </div>
        <div className="w-full flex items-center text-center text-sm">
          <Tooltip text="Completion Rate">
            <div className="  text-instant-teams-teal-Main font-bold">
              {hit.hit.finished_percentage || 0}%
            </div>
          </Tooltip>
        </div> */}
      </div>
    </Link>
  );
}
export default AcceleratorItem;
