import { useState } from "react";
import ForwardArrowButton from "../../../custom-ui/button/ForwardArrowButton";
import FormAlert from "../../../custom-ui/form-alert/formAlert";
import { useAuth } from "../../../../providers/auth";
import { useForm } from "react-hook-form";
import { z } from "zod";
// @ts-ignore
import { zodResolver } from "@hookform/resolvers/zod";
import { errorLogger } from "../../../../providers/clientLogger";
import PwdCheck from "../../../custom-ui/pwd-check/PwdCheck";
import { friendlyFirebaseError } from "../../../../providers/firebase-errors/friendlyFirebaseError";
import { useNavigate } from "react-router-dom";

function SeekerPasswordConfirmation({
  seekerInfo,
  onDone,
  children,
}: {
  onDone: Function;
  children?: React.ReactNode;
  seekerInfo: any;
}) {
  // Creates firebase user with email and password

  const auth = useAuth();
  const navigate = useNavigate();

  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  const PASSWORD_REQ = {
    minChar: "At least 8 characters",
    capital: "At least one capital letter",
    lower: "At least one lower case letter",
    special: "At least one number or symbol",
  };

  const pwdSchema = z
    .string()
    .min(1, "Password is required")
    .refine((value) => value.length >= 8, {
      message: "Password at least 8 characters",
      path: ["minChar"],
    })
    .refine((value) => /[A-Z]/.test(value), {
      message: "Password must contain at least 1 capital letter",
      path: ["capital"],
    })
    .refine((value) => /[a-z]/.test(value), {
      message: "Password must contain at least 1 lowercase letter",
      path: ["lower"],
    })
    .refine((value) => /[0-9!@#$%^&*]/.test(value), {
      message: "Password must contain at least 1 number or special character",
      path: ["special"],
    });

  const pwdFormSchema = z
    .object({
      password: z
        .string()
        .min(1, "Password is required")
        .min(8, "Password at least 8 characters")
        .refine((value) => /[A-Z]/.test(value), {
          message: "Password must contain at least 1 capital letter",
        })
        .refine((value) => /[a-z]/.test(value), {
          message: "Password must contain at least 1 lowercase letter",
        })
        .refine((value) => /[0-9!@#$%^&*]/.test(value), {
          message:
            "Password must contain at least 1 number or special character",
        }),
      confirmPassword: z.string().min(1, "Password confirmation is required"),
    })
    .refine((data) => data.password === data.confirmPassword, {
      path: ["confirmPassword"],
      message: "Passwords do not match",
    });

  type PwdFormSchemaType = z.infer<typeof pwdFormSchema>;

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    getValues,
    control,
    watch,
  } = useForm<PwdFormSchemaType>({
    resolver: zodResolver(pwdFormSchema),
  });
  const watchPassword = watch("password");

  const seekerSignup = (data: any) => {
    const {
      email,
      name,
      preferredName,
      terms,
      mobilePhone: phone,
      referredBy,
    } = seekerInfo;
    const { password } = data;

    setLoading(true);
    auth
      .signup(email, password, seekerInfo)
      .then(() => {
        onDone();
      })
      .catch((error: any) => {
        // handle submit error message

        setFormAlert({
          type: "error",
          code: error.code,
          message: friendlyFirebaseError(error),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      {formAlert && (
        <div className="my-2">
          <FormAlert alert={formAlert}>
            {formAlert.code === "auth/email-already-in-use" && (
              <div className="my-2 bg-instant-teams-gray-Main rounded p-1">
                <button
                  onClick={() => {
                    navigate("/auth/signin");
                  }}
                  className="text-instant-teams-grey-D1 underline background-transparent px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                >
                  Login with <span className="font-bold">Instant Teams</span>{" "}
                  account or <span className="font-bold">12 Million Plus</span>{" "}
                  Account
                </button>
              </div>
            )}
          </FormAlert>
        </div>
      )}
      <form onSubmit={handleSubmit(seekerSignup)} autoComplete="off">
        {/* Visible Fields */}
        <div className="mb-2">
          <input
            className={`py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 ${
              errors.password ? "border-red-600" : ""
            }`}
            placeholder="Password"
            type="password"
            name="password"
            ref={register()}
          />
          {errors.password && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.password.message}
            </p>
          )}
        </div>
        <div className="mb-2">
          <input
            className={`py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 ${
              errors.confirmPassword ? "border-red-600" : ""
            }`}
            placeholder="Confirm Password"
            type="password"
            name="confirmPassword"
            ref={register()}
          />
          {errors.confirmPassword && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.confirmPassword.message}
            </p>
          )}
        </div>
        <div className="mt-8 flex justify-center">
          <PwdCheck
            schema={pwdSchema}
            listStyles="text-sm text-instant-teams-gray-D1"
            value={watchPassword}
            pwdReq={PASSWORD_REQ}
          />
        </div>
        <div className="flex flex-col justify-center items-center">
          {children}
          <ForwardArrowButton pending={loading} type="submit" />
        </div>
      </form>
    </>
  );
}

export default SeekerPasswordConfirmation;
