const ClipSVG = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2220_3075)">
      <path
        d="M7.78746 22.9995C3.89057 23.0242 0.644352 20.2173 0.0970924 16.4268C-0.283548 13.7938 0.456187 11.5056 2.29547 9.6162C4.88669 6.95337 7.49516 4.30873 10.1252 1.68519C11.6872 0.128237 13.5818 -0.368678 15.6796 0.27302C17.7681 0.911807 19.0702 2.38364 19.5155 4.54664C19.904 6.43463 19.4322 8.15019 18.1014 9.52962C15.4778 12.2492 12.8162 14.9302 10.1438 17.6003C9.20588 18.5374 8.03452 18.7891 6.77769 18.4086C5.54168 18.0347 4.76388 17.1725 4.49887 15.8884C4.26043 14.7331 4.5434 13.6868 5.36142 12.8465C7.44201 10.709 9.54415 8.59327 11.6441 6.47537C12.1224 5.99301 12.8313 6.00028 13.2866 6.463C13.7491 6.933 13.742 7.64891 13.2514 8.14801C11.2103 10.2222 9.16495 12.2936 7.11811 14.3627C6.83227 14.6516 6.67427 14.9819 6.75183 15.3922C6.82868 15.7996 7.0664 16.0805 7.46284 16.2049C7.87795 16.3351 8.24782 16.2376 8.55448 15.9335C9.15705 15.3347 9.75027 14.7265 10.3471 14.1219C12.3027 12.1408 14.2562 10.1568 16.2154 8.18002C16.7957 7.59434 17.1613 6.91045 17.2496 6.07813C17.3961 4.6987 16.6765 3.37092 15.4455 2.76124C14.2009 2.14573 12.7114 2.37345 11.7303 3.35492C10.4843 4.60121 9.25185 5.86205 8.01369 7.11634C6.64841 8.49941 5.27739 9.87594 3.92001 11.267C1.80064 13.4395 1.75755 16.8175 3.81013 19.0205C5.68245 21.03 9.02705 21.2577 11.2254 19.4658C12.0262 18.8131 12.725 18.0303 13.4554 17.2918C15.9669 14.7549 18.4727 12.2114 20.9806 9.67077C21.3835 9.26261 21.8625 9.16075 22.3301 9.37829C22.7595 9.57837 23.0231 10.0185 22.9965 10.4994C22.9772 10.8574 22.7955 11.1273 22.552 11.3732C20.5432 13.4038 18.538 15.4373 16.5314 17.4708C15.5281 18.4872 14.5277 19.5065 13.5215 20.5192C11.9034 22.1475 9.94921 22.9689 7.78602 23.0002L7.78746 22.9995Z"
        fill="#15415E"
      />
    </g>
    <defs>
      <clipPath id="clip0_2220_3075">
        <rect width="23" height="23" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ClipSVG;
