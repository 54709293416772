import { useState } from "react";
import { useEmployer } from "../../data/employer";
import { useJobDescription } from "../../data/jobDescriptions";
import { useJobPositionDetails } from "../../data/positions";
import { useLookup } from "../../data/lookup";
import { toUSCurrency } from "../../util/helpers/toUSCurrency";
import { useMemo } from "react";
import { getLookupLabel } from "../../util/search/getLookupLabel";
import { useFindOfferStatus } from "../../data/status";
import useModal from "../../hooks/useModal";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import { useAuth } from "../../providers/auth";
import { errorLogger } from "../../providers/clientLogger";
import { updateOfferStatus, useJobOfferByEmployee } from "../../data/offers";
import { useEmployerData } from "../../hooks/useApplicationEmployerData";
import { useApplication } from "../../data/applications";
import SignOfferViewer from "../pdf/signOfferViewer";
import { Document, Page, pdfjs } from "react-pdf";

function SeekerOffersCard({
  offer,
  renderButtons = true,
}: {
  offer: any;
  renderButtons: Boolean;
}) {
  const { user } = useAuth();

  const { isShowing: isWithdrawalModalShowing, toggle: toggleWithdrawalModal } =
    useModal();
  const { isShowing: isSignModalShowing, toggle: toggleSignModal } = useModal();

  const { data: jobData } = useJobDescription(offer?.jobId);
  const { data: positionData } = useJobPositionDetails(
    offer?.jobId,
    offer.positionId,
  );

  const { data: applicationData } = useApplication(offer?.applicationId);
  const { employerId, employerData } = useEmployerData({
    jobData,
    originalEmployerId: applicationData?.employer,
  });

  const { data: statusData = {} } = useFindOfferStatus(offer?.status);

  const colorJobListingVariants: any = {
    rejected: "bg-instant-teams-gray-D1",
    canceled: "bg-instant-teams-gray-D1",
    preparation: "bg-instant-teams-tags-TEAL",
    pending: "bg-instant-teams-tags-BLUE",
    hired: "bg-instant-teams-tags-GREEN",
    error: "bg-instant-teams-tags-ROSE",
  };

  const {
    data: classification = [],
    status: classificationQueryStatus = "",
    error: classificationQueryError,
  } = useLookup("classification");
  const {
    data: model = [],
    status: modelQueryStatus = "",
    error: modelQueryError,
  } = useLookup("model");

  const getSalary = (payType: any) => {
    const pay = payType?.HRLY ?? payType?.SAL;
    if (!pay) return undefined;

    const type = payType?.HRLY ? "Hourly" : "Salary";
    return `${type}: ${toUSCurrency(pay[0])} - ${toUSCurrency(pay[1])}`;
  };

  const salary = useMemo(
    () => getSalary(positionData?.information?.payType),
    [positionData?.information?.payType],
  );

  const selectedWorkSetting = getLookupLabel(
    positionData?.information?.model,
    model,
    "Not Selected",
  );

  const selectedWorkType = getLookupLabel(
    positionData?.information?.classification,
    classification,
    "None Selected",
  );

  const withdrawOffer = async () => {
    if (!user) return;
    try {
      await updateOfferStatus(offer.id, "rejected");
    } catch (error: any) {
      errorLogger(error.message, {
        component: "SeekerOffersCard withdrawOffer()",
        flow: "Withdraw Offer/ Seeker offers withdraw offer modal",
        userId: user.uid,
      });
    }
  };

  return (
    <div
      key={offer?.id}
      className="w-full bg-white shadow-input-field my-4 h-36 rounded-lg"
    >
      <div className="grid grid-flow-row p-4 mx-auto grid-cols-8 gap-y-3 ">
        <img
          className="my-auto col-span-1 w-24 h-24 object-contain  "
          src={employerData?.logo}
          alt={employerData?.displayName}
        />

        <div className="pl-5 col-span-4 my-auto  text-instant-teams-blue-Main justify-start md:text-sm">
          <div className="">{employerData?.displayName}</div>

          <div className="font-semibold uppercase">{jobData?.title}</div>
          <div>{positionData?.positionName}</div>
          <div className="font-semibold text-sm">
            {salary} | {selectedWorkType} | {selectedWorkSetting}
          </div>
        </div>
        {renderButtons ? (
          <div className="col-span-3 flex flex-col justify-center items-center space-y-5">
            <button
              onClick={toggleSignModal}
              // disabled={disabled || submitting}
              className="py-2 w-3/4  bg-instant-teams-blue-Main  hover:bg-instant-teams-blue-D1 text-white rounded-lg md:py-2 md:px-8 md:text-sm lg:py-2 lg:px-16 xl:px-20"
            >
              View Offer Letter
            </button>

            <button
              onClick={toggleWithdrawalModal}
              className="  py-2 w-3/4   bg-white border border-instant-teams-red-Main text-instant-teams-red-Main hover:text-white hover:bg-instant-teams-red-Main rounded-lg md:py-2 md:px-8 md:text-sm lg:py-2 lg:px-16 xl:px-20"
            >
              Decline Offer
            </button>
          </div>
        ) : (
          <>
            <div className="col-span-3 flex flex-col justify-center items-center space-y-5">
              <div
                className={`py-2 w-2/3 text-white rounded-lg uppercase text-center ${
                  colorJobListingVariants[offer?.status] ||
                  "bg-instant-teams-purple-Main"
                }`}
              >
                {statusData?.label}
              </div>
            </div>
          </>
        )}
      </div>
      {isWithdrawalModalShowing && (
        <DialogWrapper
          title={`Decline ${jobData.title}?`}
          size="mt-36 w-1/3"
          onClose={toggleWithdrawalModal}
        >
          <div className="text-center">
            <p>You are attempting to decline your offer.</p>
            <p className="mb-8">Please confirm to finalize your action.</p>
            <div className="space-x-5">
              <button
                onClick={withdrawOffer}
                className="py-3 w-1/3 uppercase bg-white border border-instant-teams-red-Main text-instant-teams-red-Main hover:text-white hover:bg-instant-teams-red-Main rounded-lg md:py-2 md:px-8 md:text-sm lg:py-2 lg:px-16 xl:px-20"
              >
                Withdraw
              </button>
              <button
                onClick={toggleWithdrawalModal}
                className="py-3 w-1/3 uppercase bg-white border border-instant-teams-blue-Main text-black hover:text-white hover:bg-instant-teams-blue-D1 rounded-lg md:py-2 md:px-8 md:text-sm lg:py-2 lg:px-16 xl:px-20"
              >
                Cancel
              </button>
            </div>
          </div>
        </DialogWrapper>
      )}
      {isSignModalShowing && (
        <DialogWrapper
          title="OFFER LETTER"
          onClose={toggleSignModal}
          size="w-[40%] mt-36"
        >
          <SignOfferViewer
            offer={offer}
            toggleWithdrawalModal={toggleWithdrawalModal}
          />
        </DialogWrapper>
      )}
    </div>
  );
}

export default SeekerOffersCard;
