import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Pie, Doughnut } from "react-chartjs-2";
import { useJobPositions } from "../data/positions";
import { getTop10CoursesByCompletion } from "../callable-cloud-functions/cloudFunctions";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { getRandomColor, getPastelColors } from "../util/charts/utils";
import { Chart, ArcElement } from "chart.js";
Chart.register(ArcElement);
function Top10Courses({}) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  );

  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(true);

  const options = {
    type: "pie",
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Top 10 Courses",
      },
    },
    animation: {
      duration: 5000,
      onProgress: function (animation) {
        const progress = animation.currentStep / animation.numSteps;
        const rounded = Math.round((progress + Number.EPSILON) * 100) / 100;
        const converted = rounded * 100;
        console.log(
          "top10courses, animation, converted percentage: ",
          converted,
        );
        setProgress(converted);
      },
      onComplete: function (animation) {
        setTimeout(() => {
          setProgress(0);
          setShowProgress(false);
        }, 7000);
      },
    },
  };

  const data = {
    labels: chartLabels,
    datasets: chartData,
  };

  useEffect(() => {
    (async () => {
      const res = await getTop10CoursesByCompletion();
      const labels = [];
      const pieData = {
        label: "Courses Completed",
        data: [],
        backgroundColor: getPastelColors(),
        hoverOffset: 4,
      };
      for (const item of res.data.Top10Courses) {
        // res.data.forEach((jobMetric) => {
        // console.log("Top10Courses.js, useEffect, item: ", item);
        const title = Object.keys(item)[0];
        const value = item[title];

        labels.push(title);
        pieData.data.push(value);
      }

      setChartData([pieData]);
      setChartLabels(labels);
    })();
  }, []);

  return (
    <div>
      {showProgress ? (
        <div style={{ width: 200, height: 400, margin: "auto" }}>
          <span>Top 10 Courses</span>
          <CircularProgressbar
            value={progress}
            text={`${progress}%`}
            maxValue={100}
          />
        </div>
      ) : (
        <div style={{ display: "none" }} />
      )}
      <div style={{ width: "100%", height: "100%" }}>
        <Doughnut
          style={showProgress ? { display: "none" } : { display: "block" }}
          options={options}
          data={data}
        />
      </div>
    </div>
  );
}

export default Top10Courses;
