import { useState } from "react";
import { useParams } from "react-router-dom";
import Meta from "../../../custom-ui/meta/Meta";
import SeekerOfferList from "../parts/seekerOfferList";

const SeekerOffers = () => {
  const params = useParams();
  const [id, setId] = useState(params?.seekerId || "");

  return (
    <>
      <Meta title="Seeker - Pending Offers" />
      <div className="mt-10">
        <SeekerOfferList id={id} />
      </div>
    </>
  );
};

export default SeekerOffers;
