import React from "react";
import { Link, useRouteError } from "react-router-dom";
import Meta from "../components/custom-ui/meta/Meta";
import ScrollToTop from "../providers/router";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
function NotFoundPage(props) {
  const error = useRouteError();
  return (
    <>
      <Meta title="Oops! Page Not Found - 404" />
      <ScrollToTop />
      <Header />
      <div className="flex items-center justify-center w-screen h-screen">
        <div className="px-4 py-12">
          <div className="gap-4 flex">
            <div className="flex flex-col items-center justify-center md:py-16 py-32">
              <h1 className="font-bold text-blue-600 text-9xl">404</h1>
              <p className="mb-2 font-bold text-center text-gray-800 text-3xl">
                <span className="text-red-500">Oops!</span> Page{" "}
                {error ? error?.statusText : "Not Found"}
              </p>
              <p className="mb-8 text-center text-gray-500 text-lg">
                The page you’re looking for doesn’t exist.
              </p>
              <Link
                id="go-home-button"
                to="/"
                className="px-5 py-2 rounded-md text-blue-100 bg-blue-600 hover:bg-blue-700"
              >
                Go home
              </Link>
            </div>
            <div className="mt-4">
              <img
                src="https://cdn.pixabay.com/photo/2016/11/22/23/13/black-dog-1851106__340.jpg"
                alt="img"
                className="object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default NotFoundPage;
