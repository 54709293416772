import React, { useEffect, useMemo, useState } from "react";
import { PhoneAuthProvider, PhoneMultiFactorGenerator } from "firebase/auth";
import AuthCode from "react-auth-code-input";
import ButtonWithLoading from "../../custom-ui/button/ButtonWithLoading";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../providers/auth";
import { friendlyFirebaseError } from "../../../providers/firebase-errors/friendlyFirebaseError";
import { errorLogger } from "../../../providers/clientLogger";
import FormAlert from "../../custom-ui/form-alert/formAlert";
import EllipseAnimated from "../../custom-ui/animations/EllipseAnimated";

function MultiFactorSignInNew(props) {
  const auth = useAuth();
  const [isCodeComplete, setIsCodeComplete] = useState(false);
  const { makePhoneAuthProvider, makeRecaptcha } = auth;

  const phoneAuthProvider = makePhoneAuthProvider();

  const [pending, setPending] = useState(false);
  const { handleSubmit, register, setValue, errors } = useForm();

  const [recaptchaVerifier, setRecaptchaVerifier] = useState();
  const [verificationId, setVerificationId] = useState();

  const [verificationCode, setVerificationCode] = useState();

  const [mfaFormAlert, setMfaFormAlert] = useState(null);

  const recaptchaConfig = useMemo(
    () => ({
      size: "invisible",
      callback: function (response) {},
    }),
    [],
  );

  useEffect(() => {
    setRecaptchaVerifier(makeRecaptcha("multifactor-signIn", recaptchaConfig));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recaptchaConfig]);

  useEffect(() => {
    if (props.mfaResolver && !verificationId && recaptchaVerifier) {
      getVerificationCode();
    }
  }, [props.mfaResolver, verificationId, recaptchaVerifier]);

  function getVerificationCode() {
    setPending(true);

    const phoneInfoOptions = {
      multiFactorHint: props.mfaResolver.hints[0],
      session: props.mfaResolver.session,
    };

    const maskedPhoneNumber = phoneInfoOptions.multiFactorHint.phoneNumber;

    phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
      .then((verificationId) => {
        setVerificationId(verificationId);
        setMfaFormAlert({
          type: "info",
          message: `A confirmation code has been sent to ${maskedPhoneNumber} 
          Please enter the code below.`,
        });
      })
      .catch((error) => {
        // show error in toast message and within outer component
        // because dialog will not have shown at this point
        props.onFormAlert({
          type: "error",
          message: friendlyFirebaseError(error),
        });

        errorLogger(error.message, {
          component: "MultifactorSignIn getVerificationCode()",
          flow: "MultiFactor SignIn Flow",
          userId: "N/A",
        });
      })
      .finally(() => {
        setPending(false);
      });
  }

  

  function sendVerificationCode({ verificationCode }) {
    setPending(true);
    const credential = PhoneAuthProvider.credential(
      verificationId,
      verificationCode,
    );
    const multiFactorAssertion =
      PhoneMultiFactorGenerator.assertion(credential);

    props.mfaResolver
      .resolveSignIn(multiFactorAssertion)
      .then((user) => {
        props.onAuth(user);
        window.location.reload();
      })
      .catch((error) => {
        // message to display in mfa dialog
        setMfaFormAlert({
          type: "error",
          message: friendlyFirebaseError(error),
        });
        errorLogger(error.message, {
          component: "MultifactorSignIn sendVerificationCode()",
          flow: "MultiFactor SignIn Flow",
          userId: "N/A",
        });
      })
      .finally(() => {
        setPending(false);
      });
  }

  const handleCodeChange = (code: any) => {
    setValue("verificationCode", code);
    setVerificationCode(code);
    setIsCodeComplete(code.length === 6); // Assuming the code is 6 digits long
  };

  return (
    <>
      {/* Enter Verification Code  */}
     
      {verificationId && (
        <>
        <div className="w-full text-center mb-16">
               
               
                <h2 className="text-2xl font-semibold text-[#15415E] mb-6">Verify Phone Number</h2>
                <p className="text-xl text-center text-[#A5BDC5]">A verification code sent has been sent <br/>to your phone number</p>
                {/* <p>{mfaResolver?.hints[0]}</p> */}
            </div>
            <div className="max-w-lg mx-auto bg-white">
          <form onSubmit={handleSubmit(sendVerificationCode)}>
            
            <div className="flex justify-center my-10">
              <input
                className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                name="verificationCode"
                type="hidden"
                inputMode="numeric"
                autoComplete="one-time-code"
                ref={register({
                  required: "Please enter your verification code",
                })}
              />
              <AuthCode
                containerClassName="flex flex-row"
                inputClassName="shadow-none m-1 w-14 border-solid border-r-0 border-l-0 border-t-0 border-b-3 border-[#15415E]"
                alphanumeric="numeric"
                ariaLabel="mobile confirmation code"
                // sets code to hidden field "verificationCode"
                onChange={handleCodeChange}
                //onChange={(code) => setValue("verificationCode", code)}
                
              />
            </div>

            <div className={`flex justify-center my-10}`}>
                        <button
                            type="submit"
                            className={`py-2 px-8 text-white font-medium rounded-2xl mb-5 focus:outline-none focus:ring-2 w-64 h-12 ${
                                isCodeComplete ? "bg-[#15415E]" : "bg-[#8AA0AE] text-[#fffff]"
                              }`}
                            //className={`py-2 px-8 text-white font-medium rounded-full bg-[#8AA0AE] hover:bg-[#8AA0AE] focus:outline-none focus:ring-2 focus:ring-[#8AA0AE]`}
                            disabled={pending}
                           
                        >
                            {pending ? (
                                <div className="flex items-center justify-center space-x-2">
                                    <svg
                                        className="animate-spin h-5 w-5 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                </div>
                            ) : (
                                "Confirm Verification Code"
                            )}
                        </button>
                        </div>
          </form>
          </div>
          {/* Resend Confirmation Code */}
          <div className="flex justify-center my-10">
            <button
            onClick={getVerificationCode}
            className="inline-block align-baseline outline-none mr-1 mb-1 px-6 py-2 bg-transparent text-sm font-bold text-instant-teams-blue-Main hover:text-blue-600 uppercase focus:outline-none"
            type="button"
            disabled={pending}
          >
            Resend Verification Code?
          </button>
          </div>
        </>
      )}
      <div className="mt-12">
        {/* DO NOT REMOVE div. id "multifactor-signIn" is a param required initialize the required reCAPTCHA */}
        <div id="multifactor-signIn"></div>
      </div>
    </>
  );
}

export default MultiFactorSignInNew;
