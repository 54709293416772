import Tooltip from "../../../custom-ui/tooltip/tooltip";
const OffersCount = ({
  count,
  tooltip,
}: {
  count: number;
  tooltip: string;
}) => {
  return (
    <span className="inline-block px-2 py-1 text-xs font-bold leading-none text-white bg-instant-teams-blue-Main rounded-full">
      <Tooltip text={tooltip}>
        <p>{count}</p>
      </Tooltip>
    </span>
  );
};

export default OffersCount;
