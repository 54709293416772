import { useEffect } from "react";
import { DisplayTimestamp } from "../../../../util/timestamps/timestamp";
import { Note } from "../../../../dataTypes/Note";
import { useUser } from "../../../../providers/database";
import { roles } from "../../../../data/dictionary/role";
import { useRole } from "../../../../hooks/useRole";
import { useState } from "react";
import { useAuth } from "../../../../providers/auth";
import { useNotesUser } from "../../../../data/user";
function SeekerNoteDisplay({ entry }: { entry: Note }) {
  const { userClaims, user } = useAuth();
  const [noteTakerIsSignedInUser, setNoteTakerIsSignedInUser] = useState(false);

  useEffect(() => {
    if (user.uid === entry?.who) {
      setNoteTakerIsSignedInUser(true);
    }
  }, [entry?.who]);

  const { data: notetaker = {}, isLoading: isNoteTakerLoading } = useNotesUser(
    entry?.who,
  );

  return (
    <>
      <div className="text-black font-normal bg-white rounded-lg shadow-lg mb-2 pb-2 pl-4 pr-4 pt-4">
        <div className="w-full text-instant-teams-teal-Main text-sm font-bold">
          {noteTakerIsSignedInUser ? "You" : notetaker?.preferredName}
          <span className="text-sm float-right text-instant-teams-teal-Main">
            {DisplayTimestamp(entry?.timestamp)}
          </span>
        </div>
        <div className="w-full mt-2 font-bold mb-2">{entry?.note}</div>
        {userClaims?.claims?.type === "A" ? (
          <div className="w-full text-xs text-instant-teams-teal-Main ">
            {entry?.type} Note
          </div>
        ) : null}
      </div>
    </>
  );
}

export default SeekerNoteDisplay;
