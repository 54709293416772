import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";
import { useComponent } from "../../data/academy";
import WedgeSpinner from "../spinners/wedges-spinner";
import useModal from "../../hooks/useModal";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import SeekerAssessmentDetails from "./AssessmentDetails";
import DisplayBadges from "../custom-ui/badge/displayBadge";
import { getLevel } from "../../util/assessments/level";
function SeekerAcademyItem({
  enrollment,
  completedAssessment = false,
}: {
  enrollment: any;
  completedAssessment?: Boolean;
}) {
  const { isShowing, toggle } = useModal();
  const { data: assessment, isLoading } = useComponent(
    enrollment?.assessmentId,
  );

  if (isLoading) {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <WedgeSpinner />
      </div>
    );
  }
  return (
    <div className="w-full">
      <div
        onClick={toggle}
        className={
          TailwindHoverEnlarge(103) +
          " grid grid-flow-row grid-cols-5 h-32 w-full mx-auto cursor-pointer text-instant-teams-blue-Main bg-white rounded-lg shadow-input-field"
        }
      >
        <div>
          <DisplayBadges
            assessmentId={assessment?.id}
            enrollment={enrollment}
          />
        </div>
        <div className="flex flex-col justify-center col-span-2">
          <p className="w-full text-instant-teams-blue-Main font-bold text-xl">
            {assessment?.displayName || assessment?.name}{" "}
          </p>
        </div>
        {completedAssessment ? null : (
          <div className="col-span-2 my-auto pl-10">
            <p className="text-instant-teams-blue-Main font-semibold">
              Completion Time:{" "}
              <span className="text-black font-normal">
                {assessment?.duration ?? 0} mins
              </span>
            </p>
          </div>
        )}
      </div>
      {isShowing ? (
        <DialogWrapper
          title="Assessment Details"
          size="mt-36 w-1/2"
          onClose={toggle}
        >
          <SeekerAssessmentDetails
            assessment={assessment}
            enrollment={enrollment}
          />
        </DialogWrapper>
      ) : null}
    </div>
  );
}

export default SeekerAcademyItem;
