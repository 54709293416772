import { useEffect, useState } from "react";
import { sanitizeMarkup } from "../../../util/sanitize/sanitize-html";
import { fetchEarnedBadges } from "../../../callable-cloud-functions/cloudFunctions";
import { addSkill } from "../../../data/jobDescriptions";
import { useComponent } from "../../../data/academy";
import { useAuth } from "../../../providers/auth";
import TestQuestions from "../../academy/parts/testQuestions";
import { camel } from "../../../util/strings/stringManipulation";
import "../../../styles/dangerouslySet.css";
import { getLevel } from '../../../util/assessments/level';
import DisplayBadges from '../../custom-ui/badge/displayBadge';

function SkillDetails({ assessmentId, jobId, onDone }) {
  const { user } = useAuth();
  const { data: assessment } = useComponent(assessmentId);
  const [badgesEarned, setBadgesEarned] = useState({});


  useEffect(() => {
    async function fetchData() {
      const { data } = await fetchEarnedBadges({ id: assessmentId });
      setBadgesEarned(data);
    }
    fetchData();
  }, [assessmentId]);

  function handleChosen(choice) {
    if (choice !== "none") {
      addSkill(jobId, assessmentId, choice, user.id);
      onDone();
    }
  }

  return (
    <div key={assessmentId} className="grid grid-cols-3 gap-2">
      <div className="items-center col-span-1">
        <DisplayBadges assessmentId={assessmentId} size={36}/>
      </div>
      <div className="items-center rounded-b-lg relative col-span-2 mt-4">
        <p className="text-center text-instant-teams-teal-Main font-bold w-full text-xl">
          {assessment?.displayName || assessment?.name}
        </p>
        <p className="text-center w-full">
          {assessment?.duration || "??"} minutes
        </p>
        <p className="text-center w-full">
          Career Seekers Earned: {badgesEarned.total || 0}
        </p>
      </div>
      <div className="rounded-b-lg relative col-span-1 mt-4 items-center text-center">
        <p className="w-full text-instant-teams-teal-Main mb-3 text-center">
          Select Required Tier
        </p>
        <button
          className="w-3/4 bg-instant-teams-teal-Main text-white rounded-lg mb-3 px-auto hover:scale-105 h-12"
          onClick={() => {
            handleChosen("one");
          }}
        >
          Proficient
        </button>
        <button
          className="w-3/4 bg-instant-teams-teal-Main text-white rounded-lg mb-3 hover:scale-105 h-12"
          onClick={() => {
            handleChosen("two");
          }}
        >Advanced
        </button>
        <button
          className="w-3/4 bg-instant-teams-teal-Main text-white rounded-lg hover:scale-105 h-12"
          onClick={() => {
            handleChosen("three");
          }}
        >Experienced
        </button>
      </div>
      <div className="rounded-b-lg relative col-span-1 mt-4 px-4 items-center">
        <p className="w-full text-instant-teams-teal-Main text-center">
          Tier Minimum Scores
        </p>
        <p className="w-full text-center mt-5 mb-7 text-xl">
          {assessment?.tier?.one}%
        </p>
        <p className="w-full text-center mt-3 mb-7 text-xl">
          {assessment?.tier?.two}%
        </p>
        <p className="w-full text-center mb-7 mt-3 text-xl">
          {assessment?.tier?.three}%
        </p>
      </div>
      <div className="rounded-b-lg relative col-span-1 mt-4 px-3 items-center">
        <p className="w-full text-instant-teams-teal-Main text-center ">
          Current Career Seeker Pool
        </p>
        {badgesEarned.total > 0 && (
          <>
            <p className="w-full text-center mt-5 mb-7 text-xl">
              {badgesEarned.one || 0}
            </p>
            <p className="w-full text-center mt-3 mb-7 text-xl">
              {badgesEarned.two || 0}
            </p>
            <p className="w-full text-center mt-3 mb-7 text-xl">
              {badgesEarned.three || 0}
            </p>
          </>
        )}
      </div>
      <div className="items-center col-span-3 mt-4 px-2">
        <div className="w-full">
          <p className="text-instant-teams-teal-Main font-bold w-full">
            Skills:{" "}
          </p>
          {assessment &&
            assessment?.tests_detail?.map((item, index) => {
              return (
                <div
                  className={
                    index > 0 ? "w-full border-t border-black" : "w-full"
                  }
                  key={index}
                >
                  <div className="w-full">
                    <TestQuestions details={item?.test?.covered_skills} />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="items-center col-span-3 mt-4 px-2">
        <p className="text-instant-teams-teal-Main font-bold w-full">
          Description
        </p>
      </div>
      {assessment?.description && (
        <div
          className="dangerously items-center col-span-3 px-2"
          dangerouslySetInnerHTML={sanitizeMarkup(assessment.description)}
        ></div>
      )}
    </div>
  );
}
export default SkillDetails;
