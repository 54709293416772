import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../providers/auth";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";

function SettingsPassword(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const { register, handleSubmit, errors, reset, getValues } = useForm();

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    auth
      .updatePassword(data.pass)
      .then(() => {
        // Clear form
        reset();
        // Set success status
        props.onStatus({
          type: "success",
          message: "Your password has been updated",
        });
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          // Update state to show re-authentication modal
          props.onStatus({
            type: "requires-recent-login",
            // Resubmit after reauth flow
            callback: () => onSubmit({ pass: data.pass }),
          });
        } else {
          // Set error status
          props.onStatus({
            type: "error",
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mb-6">
      <div className="">
        <GreenUnderlineHeading title="Change Password" />
        <input
          className={props.inputFieldClasses}
          name="pass"
          type="password"
          placeholder="New Password"
          ref={register({
            required: "Please enter a password",
          })}
        />
        {errors.pass && (
          <p className="mt-1 text-sm text-left text-red-600">
            {errors.pass.message}
          </p>
        )}
      </div>
      <div className="mt-3">
        <input
          className={props.inputFieldClasses}
          name="confirmPass"
          type="password"
          placeholder="Confirm New Password"
          ref={register({
            required: "Please enter your new password again",
            validate: (value) => {
              if (value === getValues().pass) {
                return true;
              } else {
                return "This doesn't match your password";
              }
            },
          })}
        />
        {errors.confirmPass && (
          <p className="mt-1 text-sm text-left text-red-600">
            {errors.confirmPass.message}
          </p>
        )}
      </div>
      <button
        className="py-2 px-4 mt-9 w-full text-xl uppercase  justify-center mx-auto text-white bg-instant-teams-blue-Main rounded-lg border-0 hover:bg-instant-teams-blue-D1 focus:outline-none "
        type="submit"
        disabled={pending}
      >
        {pending ? "..." : "Update Password"}
      </button>
    </form>
  );
}

export default SettingsPassword;
