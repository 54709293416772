import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useJobDescriptionSkills } from "../../../data/jobDescriptions";
import { useParams } from "react-router-dom";
// import { outreachList } from "../../../callable-cloud-functions/cloudFunctions";
import { createApplication } from "../../../data/applications";
import { useOutreachList, refetchOutreachList } from "../../../data/outreach";
import { useActiveJobPositions } from "../../../data/positions";
import PhoneNumber from "../../custom-ui/input-fields/phoneNumber";
import Avatar from "../../custom-ui/avatar/avatar";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import ExpandOverflowList from "../../custom-ui/expand-overflow-list/ExpandOverflowList";
import ReqSkillItemBasic from "../parts/ReqSkillItemBasic";
import useModal from "../../../hooks/useModal";
import WedgeSpinner from "../../spinners/wedges-spinner";

function JobOutreach() {
  const params = useParams();
  const jobId = params.id ?? "";
  // used for refetching outreach list
  const queryClient = useQueryClient();

  const {
    data: outreachData,
    status,
    error,
    isLoading,
  } = useOutreachList(jobId);
  // @ts-ignore
  const { outreachList = [] } = outreachData?.data ?? {};

  const [selectedPosition, setSelectedPosition] = useState();
  const [selectedSeeker, setSelectedSeeker] = useState();

  const { isShowing: showPositionModal, toggle: togglePositionModal } =
    useModal();

  const { data: activeJobPositions = [] } = useActiveJobPositions(jobId);

  const createApplicationInvitation = (
    jobId: string,
    positionId: string,
    resumeId: string,
  ) => {
    createApplication({
      jobId,
      positionId,
      resumeId,
      invited: true,
    })
      .then(() => {
        togglePositionModal();
        refetchOutreachList(queryClient, jobId);
      })
      .catch((error) => {
        console.log("problem creating application invitation: ", error);
      });
  };

  return (
    <>
      {isLoading ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <WedgeSpinner />
        </div>
      ) : (
        <>
          {outreachList.length ? (
            <>
              {outreachList.map((seeker: any) => (
                <div
                  key={seeker.resumeId}
                  className="relative shadow-lg rounded-xl bg-white h-full mt-4"
                >
                  <div className="h-full w-full grid grid-cols-5 gap-2">
                    <div className="h-full w-full flex items-center mb-2 p-2 col-span-2 bg-instant-teams-teal-Main text-white rounded-l-lg">
                      <Avatar
                        user={{
                          photoURL:
                            seeker.photoURL || "/images/default_user.png",
                          status: seeker.status,
                          name: seeker.name,
                        }}
                      />
                      <div>
                        <p className="pl-4 text-sm pt-2">{`${seeker?.name?.toUpperCase()}`}</p>
                        <p className="pl-4 text-xs pt-1">{seeker?.email}</p>
                        <p className="pl-4 text-xs pt-1">
                          {seeker?.address?.standardizedAddress}
                        </p>
                        <div className="pl-4 text-xs pt-1">
                          <PhoneNumber number={seeker?.phone || ""} />
                        </div>
                        <div className="pl-4 text-xs pt-1"></div>
                      </div>
                    </div>

                    <div className="w-full h-56  col-span-2 pt-2 pl-4 pr-4 text-sm pb-2">
                      <div className="flex items-center h-full">
                        <ExpandOverflowList
                          length={seeker.skills.length}
                          height="min-h-52"
                        >
                          {seeker.skills.map((skill: any) => (
                            <div key={skill.resumeId} className="w-28">
                              <ReqSkillItemBasic
                                assessment={skill}
                                userId={seeker.resumeId}
                                statusIconSize={"h-4 w-4"}
                              />
                            </div>
                          ))}
                        </ExpandOverflowList>
                      </div>
                    </div>
                    <div className="flex items-center w-full h-full col-auto pt-2 pl-4 pr-4 text-sm pb-2">
                      <button
                        className="w-full mb-5 min-h-12 h-12  text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                        onClick={() => {
                          setSelectedSeeker(seeker);
                          togglePositionModal();
                        }}
                      >
                        Invite
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="flex h-96">
              <div className="m-auto font-bold text-instant-teams-teal-Main">
                No Seekers meet the skills requirements or there are no skill
                requirements for this job.
              </div>
            </div>
          )}
          {showPositionModal && (
            <DialogWrapper
              onClose={togglePositionModal}
              title={""}
              size=" w-1/2 mt-36"
            >
              <div className="h-36">
                <div>
                  <select
                    className="mb-4 form-input shadow-md rounded-md w-full pt-3"
                    onChange={(e) => {
                      setSelectedPosition(e.target.value);
                    }}
                  >
                    <option selected disabled>
                      Select a Position
                    </option>
                    {activeJobPositions.map((item: any) => (
                      <option key={item.id} value={item.id}>
                        {item.positionName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex space-x-4">
                  <button
                    className="w-full min-h-12 h-12 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                    type="button"
                    onClick={() => {
                      createApplicationInvitation(
                        jobId,
                        selectedPosition,
                        selectedSeeker.resumeId,
                      );
                    }}
                  >
                    Confirm Invite
                  </button>
                  <button
                    className="w-full min-h-12 h-12  text-white bg-instant-teams-gray-D1 rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                    type="button"
                    onClick={togglePositionModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </DialogWrapper>
          )}
        </>
      )}
    </>
  );
}

export default JobOutreach;
