import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useEmployer } from "../../../../data/employer";
import JobMetrics from "../../../../charts/JobMetrics";
import EmployerApplicationTrends from "../../../../charts/EmployerApplicationTrends";
import { Link } from "react-router-dom";

const EmployerOverview = () => {
  const params = useParams();

  const {
    data: employer,
    status: employerStatus,
    error: employerError,
  } = useEmployer(params.employerId ?? "");

  return (
    <div className="w-full pb-36">
      <div className="text-center grid grid-flow-col grid-cols-2 gap-4">
        <div className="col-span-1">
          <JobMetrics employerId={params.employerId} />
        </div>
        <div className="col-span-1">
          <EmployerApplicationTrends employerId={params.employerId} />
        </div>
      </div>
    </div>
  );
};

export default EmployerOverview;
