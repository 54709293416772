import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEmployerDeptDashboard } from "../../../../data/employer";
import useModal from "../../../../hooks/useModal";
import DialogWrapper from "../../../custom-ui/dialog/Dialog";
import CreateEmployerDepartment from "../parts/CreateEmployerDepartment";
import { useAuth } from "../../../../providers/auth";
import WedgeSpinner from "../../../spinners/wedges-spinner";
import { useEmployer } from "../../../../data/employer";
import GreenUnderlineHeading from "../../../custom-ui/headings/heading";
import AutoSaveToggle from "../../../custom-ui/input-fields/auto-save-toggle";
import { collectionNames } from "../../../../data/dictionary/collectionNames";
import TrainingList from "../parts/trainingList";

const EmployerTraining = () => {
  const params = useParams();
  const [employerId, setEmployerId] = useState(params.employerId);
  const [active, setActive] = useState(false);
  const { toggle: createTrainingToggle, isShowing: createTraining } =
    useModal();

  const { data: employer = {}, isLoading } = useEmployer(employerId);

  useEffect(() => {
    setEmployerId(params.employerId);
  }, [params.employerId]);

  useEffect(() => {
    if (employer) {
      setActive(employer?.training || false);
    }
  }, [employer]);

  return (
    <div>
      <GreenUnderlineHeading title="Training">
        <AutoSaveToggle
          value={employer?.training || false}
          collection={collectionNames.employers}
          id={employerId}
          name={"training"}
          on={"Requires Live Training"}
          off={"No Training Required"}
        />
      </GreenUnderlineHeading>
      {active ? (
        <TrainingList employerId={employerId} />
      ) : (
        <div className="w-full pl-4 mb-5 mt-10">
          <p>
            Currently, this employer does not require training. If you would
            like to make training required, please update the employer.
          </p>
        </div>
      )}
    </div>
  );
};

export default EmployerTraining;
