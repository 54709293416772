import { LookUp } from "../../dataTypes/LookUp";

export const getLookupLabel = (
  code: string,
  lookupArray: LookUp[],
  notFoundMessage: string,
): string => {
  const matchingClassification = lookupArray.find(
    (item: LookUp) => item.code === code,
  );
  return matchingClassification
    ? matchingClassification.label
    : notFoundMessage;
};

// if there are multiple lookup values selected, and we need to return them all
export const getAllLookupLabels = (
  codes: Array<string> | undefined,
  lookupArray: Array<LookUp>,
  prefix: string,
  notFoundMessage: string,
): string => {
  if (!Array.isArray(codes)) {
    return notFoundMessage;
  }
  return (
    codes
      ?.map((code) => {
        const match = lookupArray.find((item) => item.id === `${prefix}_${code}`);
        return match ? match.label : null;
      })
      .filter((label) => label !== null)
      .join(", ") 
  );
}