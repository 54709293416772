const CalendarSvg = ({ className }: { className: string }) => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V4C0 3.45 0.195833 2.97917 0.5875 2.5875C0.979167 2.19583 1.45 2 2 2H3V0H5V2H13V0H15V2H16C16.55 2 17.0208 2.19583 17.4125 2.5875C17.8042 2.97917 18 3.45 18 4V18C18 18.55 17.8042 19.0208 17.4125 19.4125C17.0208 19.8042 16.55 20 16 20H2ZM2 18H16V8H2V18ZM2 6H16V4H2V6Z"
      fill="#1E596D"
    />
  </svg>

  // <svg
  //   style={{ alignSelf: "center" }}
  //   width={230}
  //   height={230}
  //   viewBox="0 0 321.4 350"
  // >
  //   <path
  //     fill="#FADCBC"
  //     d="M137.7,236.4c-2.4,2.3-4.9-0.4-4.9-0.4s-41.6-43.1-44.3-45c-3.8-2.7-11.9-6.9-19.8,1.5
  // 	c-3.3,3.5-8.7,11.2,1.6,23.4c2.2,2.6,67,69.3,70.8,72.9c0,0,29.9,28.4,47.9,39c5,3,10.6,6.1,17.2,8.2c6.5,2.1,13.9,3.6,21.6,3.5
  // 	c7.7,0,15.7-1.6,23.3-4.4c7.6-2.9,14.6-7.1,20.9-11.8c1.6-1.2,3.1-2.5,4.6-3.7l4.3-3.6c3-2.6,5.7-5.2,8.4-8
  // 	c5.3-5.5,10.1-11.2,14.4-17.2c4.2-6,7.8-12.1,10.6-18.2c2.8-6.1,4.8-12.3,5.9-18.1c1.2-5.8,1.5-11.2,1.3-16
  // 	c-0.1-4.8-1.1-8.8-1.9-12.1c-0.9-3.2-1.9-5.7-2.7-7.3c-0.8-1.6-1.2-2.5-1.2-2.5l0,0c-1.1-2.9-2-5.6-3-8c-2.5-6.9-5.4-14.7-8.5-22.7
  // 	c-1.3-3.4-1.9-4.9-1.9-4.9l0,0.1c-11.1-29.1-23.1-59.2-23.1-59.2c-1.8-5.3-8.3-7.1-13-3.6c-13.6,10-17.7,24.5-12.6,39.8l12.8,33.9
  // 	c1.8,3.8-3,8.2-6.3,5.6C249.8,189.7,228,167,228,167c-9.8-9.1-64.8-64.3-68.6-67.7c-7.4-6.7-16.7-10.2-23.7-4.9
  // 	c-7.1,5.4-9,13.6-2,22.4c1.9,2.4,57.6,59.9,57.6,59.9c3.3,3.3-0.5,8.1-4,4.9c0,0-69.3-70.6-72.4-73.9c-7.1-7.5-18.4-9-25-2.7
  // 	c-6.4,6.2-6.2,16.2,1,24c2.3,2.5,51.1,52.8,70.7,73.4c1.3,1.4,2.3,3.2,0.5,5c0,0-1.9,2.1-4.4-0.5c-5.3-5.5-58.3-59.5-61.3-62.4
  // 	c-6.8-6.7-15.8-9.8-22.9-3.1c-6.5,6.1-7.5,16.6,0.2,24l64.3,66.4C137.9,231.8,140,234.3,137.7,236.4z"
  //   />
  //   <path
  //     fill="#FADCBC"
  //     d="M119.7,226.7c-1.7,2.9-4.8,0.9-4.8,0.9S63.4,197.1,60.3,196c-4.4-1.6-13.3-3.5-18.7,6.7
  // 	c-2.3,4.2-5.4,13.1,7.7,22.2c2.8,2,82.9,49.2,87.5,51.7c0,0,36.3,19.5,56.5,25c5.6,1.5,11.9,3.1,18.7,3.4
  // 	c6.9,0.4,14.3-0.2,21.8-2.3c7.5-2,14.8-5.7,21.3-10.4c6.5-4.8,12.2-10.6,17.1-16.9c1.2-1.6,2.4-3.2,3.5-4.8l3.2-4.6
  // 	c2.2-3.3,4.2-6.6,6-9.9c3.7-6.7,6.8-13.5,9.3-20.4c2.5-6.9,4.3-13.7,5.4-20.4c1.1-6.7,1.4-13.1,1-19c-0.3-5.9-1.5-11.2-3-15.7
  // 	c-1.4-4.6-3.4-8.2-5-11.1c-1.8-2.9-3.3-5-4.5-6.3c-1.1-1.3-1.8-2-1.8-2l0,0c-1.8-2.5-3.5-4.8-5-7c-4.3-6-9.1-12.7-14.2-19.6
  // 	c-2.1-2.9-3.1-4.2-3.1-4.2l0,0.1c-18.5-25.2-37.9-51-37.9-51c-3.1-4.6-9.9-4.6-13.5,0c-10.5,13.3-10.6,28.3-1.7,41.7l21.3,29.3
  // 	c2.7,3.2-0.7,8.7-4.6,7.1c-12.1-5.2-39.1-21.3-39.1-21.3c-11.8-6.2-79.5-44.9-84-47.2c-8.9-4.6-18.8-5.4-24.1,1.5
  // 	c-5.5,7-5.2,15.5,4,22.2c2.5,1.8,71.4,42.6,71.4,42.6c4,2.3,1.7,7.9-2.6,5.8c0,0-85.5-49.8-89.3-52.2c-8.8-5.4-20.1-3.9-24.8,4
  // 	c-4.6,7.7-1.6,17.3,7.3,22.9c2.8,1.8,63.2,37.5,87.5,52.2c1.6,1,3.1,2.5,1.8,4.7c0,0-1.3,2.6-4.4,0.7c-6.6-3.9-72-42-75.6-44.1
  // 	c-8.3-4.7-17.8-5.3-22.9,3c-4.7,7.6-2.8,18,6.5,23.1l79.5,47.1C118.7,222.2,121.4,224,119.7,226.7z"
  //   />
  //   <path
  //     fill="#DBA689"
  //     d="M263.2,182.2c2.9,6.6,7.4,11.4,0.6,16.3c-9.2,6.7-28.2-16.2-35.8-24c-9.4-9.6-18.6-19.8-28.2-29.3
  // 	c-13.1-13-31-30.6-43.7-43.9c-14.8-15.4-29.6,3-22.6,17.3c0.7,1.4,1.6,2.8,2.9,4.3c3.8,4.3,7.9,8.3,11.9,12.5
  // 	c0.1,0.1,31.5,30.1,41.7,40.5c4.7,4.9,2.1,10.8-0.8,8.1s-23.1-20.7-32.3-30c-14.4-14.7-32.7-33-49.1-46.6
  // 	c-9.3-7.8-31.2,8-10.5,28.2c0.8,0.8,61.6,60.4,69.3,68c2.5,2.5-1.5,5-1.5,5c-1.7,1.5-67.2-60.7-69.6-63.1
  // 	C82,132,58.9,148.7,77.8,168c6.9,7,28.8,29.5,43,44.2c4.7,4.9,16.2,15.3,23.2,21.4c1.4,1.3-2.1,2.8-3,3.9
  // 	c-1.3,1.7-48.3-40.8-53.1-44.7c-10.8-8.6-29.5,6.6-18.5,21.2c5.6,7.5,44.8,44.5,50.5,49.9c29.4,27.9,35,34.8,70.8,60.1
  // 	c4.5,3.2,17.5,13.2,13.7,11.6c-50.2-21.5-94.4-71.4-132.6-106.2c-11.2-10.2-19.8-29.1-6.5-41.8c5.2-5,8.8-6,15.5-5.6
  // 	c0,0-0.4-0.5-6.3-6.7c-5.7-6-18.8-24.2-6.6-36c9.6-9.2,20.2-5.2,19.4-6.1c-9.1-10.8-8-22.8-0.2-30.1c9.3-8.7,21.9-9.1,38.3,7.8
  // 	c-1-3-3.4-10.5,4.5-17.6c15.2-13.8,28-3.9,56.9,27.2c24,25.7,61.2,63.7,61.2,63.7c2.1,1.9,7.7,8.5,11.1,6.4c2-1.2-1.2-5-4.5-12.6
  // 	c-12-27.3-12.2-51,14.5-60.8c1.7-1.6-10.2,8.8-13.7,20.5C251.7,149.1,256.6,166.9,263.2,182.2z"
  //   />
  //   <polygon fill="#FFE500" points="36.5,13.1 7.4,33.6 49.3,62.2" />
  //   <polygon fill="#00B8F0" points="1.5,62.5 0,88.9 36,77.7" />
  //   <polygon fill="#FF6242" points="99.5,31.1 75.6,19.6 72.2,57.1" />
  //   <polygon fill="#FFE500" points="280.5,17.4 249.5,0 241.7,50.1" />
  //   <polygon fill="#6DD627" points="220.8,7.1 196.4,17.2 222,44.8" />
  //   <polygon fill="#FF6242" points="292.2,76.5 292.3,50 257,63.1" />
  //   <polygon fill="#FF6242" points="22.4,326.1 48.7,350 67.5,302.9" />
  //   <polygon fill="#00B8F0" points="78.2,349.5 104.3,345.1 85.5,312.5" />
  //   <polygon fill="#6DD627" points="24.1,265.9 18.1,291.7 55.5,286.8" />
  // </svg>
);

export default CalendarSvg;
