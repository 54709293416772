import AcceleratorDropzone from './accelerator-dropzone';
import DialogWrapper from "../../../custom-ui/dialog/Dialog";
function AcceleratorBadge(props) {
  return (
    <>
      <DialogWrapper title="Add Accelerator Image" onClose={props.toggleModal}>
        <p className="my-6 text-slate-500  leading-relaxed text-sm flex justify-center">
          Tap the image to open file explorer or Drop image below
        </p>
        <AcceleratorDropzone closeModal={props.toggleModal} />
      </DialogWrapper>
    </>
  );
}

export default AcceleratorBadge;
