import React, { useState, useEffect } from "react";
import { useTags } from "../../data/tags";
import { updateAutoSave } from "../../data/utility";
import useModal from "../../hooks/useModal";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import { createTag, checkIfTagExistsForCollection } from "../../data/tags";

const TagListing = ({
  currentTags,
  collection,
  recId,
  name,
}: {
  currentTags: Array<string>;
  collection: string;
  recId: string;
  name: string;
}) => {
  const [type, setType] = useState(collection);
  const [options, setOptions] = useState([]);
  const [tags, setTags] = useState([]);
  const [showAdd, setShowAdd] = useState(false);

  const { data: tagList } = useTags(type);
  const { isShowing, toggle: toggleAddTag } = useModal();
  const [value, setValue] = useState("");

  const addNewTag = async () => {
    const data = {
      type: collection,
      tag: value,
    };
    const tagExists = await checkIfTagExistsForCollection(data);
    if (!tagExists) {
      await createTag(data);
    } else {
      console.log("tag already exists");
    }
    setShowAdd(false);
  };

  const handleDone = () => {
    toggleAddTag();
  };

  async function updateTags(tag: string) {
    const newTags = [...currentTags];
    newTags.push(tag);
    await updateAutoSave(collection, recId, { [name]: newTags });
    return true;
  }

  async function removeTag(tag: string) {
    const newTags = tags.filter((t: string) => t !== tag);
    await updateAutoSave(collection, recId, { [name]: newTags });
    return true;
  }

  useEffect(() => {
    setOptions([]);
    const newOptions: Array<string> = [];
    if (tagList && tagList.length > 0) {
      tagList.map((tag: any, index: number) => {
        newOptions.push(tagList[index].tag);
      });
      let finalOptions = newOptions;
      if (tags !== null && tags.length > 0) {
        finalOptions = newOptions.filter((value) => !tags.includes(value));
      }
      setOptions(finalOptions);
    }
  }, [tagList, tags]);

  useEffect(() => {
    setTags(currentTags);
  }, [currentTags]);

  return (
    <div className="w-full mb-4 mt-4 text-instant-teams-teal-Main font-bold ">
      <p className="mt-6 mb-6 uppercase text-xl font-bold text-instant-teams-teal-D1 border-b-2 border-instant-teams-green-D2">
        ASSIGNED TAGS
        <span
          className="float-right text-sm text-instant-teams-green-D2 text-bold cursor-pointer hover:text-instant-teams-orange-D1"
          onClick={() => toggleAddTag()}
        >
          + ADD
        </span>
      </p>
      <ul className="w-full -mx-1 mb-4">
        {tags && tags.length > 0
          ? tags.map((tag: string) => (
              <li
                key={tag}
                className="inline-block bg-white rounded-lg p-1 text-sm font-semibold m-1 cursor-pointer hover:bg-red-600 hover:text-white transition-colors"
                onClick={() => {
                  removeTag(tag);
                }}
              >
                {tag.toUpperCase()}
              </li>
            ))
          : null}
      </ul>

      {isShowing && (
        <DialogWrapper
          title="Assign Tag"
          size="w-3/4"
          onClose={() => toggleAddTag()}
        >
          <div className="w-full">
            <div className="w-full -mx-1">
              {options.map((tag: any) => (
                <div
                  key={tag}
                  className="inline-block bg-instant-teams-blue-Main rounded-lg p-2 text-sm font-bold m-1 cursor-pointer hover:bg-green-600 text-white transition-colors"
                  onClick={() => {
                    updateTags(tag);
                  }}
                >
                  {tag.toUpperCase()}
                </div>
              ))}
            </div>
            <div className="mt-10 w-full">
              <div className="flex justify-end space-x-4 items-center">
                <input
                  className="py-1 px-3 leading-8 bg-gray-200 rounded border border-gray-300 outline-none text-sm w-1/4"
                  name="tag"
                  onChange={(e) => setValue(e.target.value)}
                />
                <button
                  className="py-2 px-4 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none flex"
                  type="submit"
                  onClick={addNewTag}
                >
                  Add Tag
                </button>
              </div>
            </div>
          </div>
        </DialogWrapper>
      )}
    </div>
  );
};

export default TagListing;
