import { useAllUserEmployment } from "../../../../data/employee";
import SeekerStandingItem from "./seekerStandingItem";

function SeekerStandingList({ userId }: { userId: string }) {
  // const { data: seekerOffersData } = useAllActiveOffers(userId);
  const { data: employmentData } = useAllUserEmployment(userId);

  return (
    <>
      {employmentData && employmentData.length > 0 ? (
        employmentData?.map((employment: any) => {
          return <SeekerStandingItem employment={employment} />;
        })
      ) : (
        <div className="mt-4">
          <p>No outstanding employment.</p>
        </div>
      )}
    </>
  );
}

export default SeekerStandingList;
