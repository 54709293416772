import { memo } from "react";
import React from "react";

const Tooltip = memo(({ text, position = "-top-8 left-1/2", children }) => {
  return (
    <span className="group relative grid">
      <span
        className={`pointer-events-none  absolute ${position} -translate-x-1/2 whitespace-nowrap rounded bg-instant-teams-blue-L1 px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-black before:content-[''] group-hover:opacity-100 z-40`}
      >
        {text}
      </span>

      {React.cloneElement(children)}
    </span>
  );
});

export default Tooltip;
