import { useParams } from "react-router-dom";
import AutoSaveWysiwyg from "../../custom-ui/input-fields/auto-save-wysiwyg";
import { useEmployer } from "../../../data/employer";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";

const InterviewInstructions = () => {
  const params = useParams();
  const employerId = params?.employerId;

  const { data: employer } = useEmployer(employerId ?? "");

  return (
    <div>
      <GreenUnderlineHeading title="Interview Instructions" />
      <AutoSaveWysiwyg
        value={
          employer?.interviewInstructions ? employer?.interviewInstructions : ""
        }
        rows={5}
        collection="Employer"
        recId={employer?.id}
        required={false}
        name="interviewInstructions"
        display=""
        placeholder={"List specific instructions on your interview process"}
        directions=""
        readOnly={false}
      />
    </div>
  );
};

export default InterviewInstructions;
