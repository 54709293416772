function DownloadOurMobileApp() {
  return (
    <>
      <h2 className="my-4 text-instant-teams-blue-Main uppercase font-bold">
        Download Our Mobile App
      </h2>
      <div className="flex justify-center items-center w-72">
        <div className="w-full">
          <a
            className="inline-block align-middle"
            target="_blank"
            href="https://apps.apple.com/us/app/instant-teams-marketplace/id1666030415?itsct=apps_box_badge&amp;itscg=30200"
            rel="noreferrer"
          >
            <img
              //width="107"
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1685577600"
              alt="Download on the App Store"
            />
          </a>
        </div>
        <div className="w-full">
          <a
            className="inline-block align-middle"
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.instantteams.athena&utm_source=landingPage&utm_campaign=launch&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            rel="noreferrer"
          >
            <img
              width="150"
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            />
          </a>
        </div>
      </div>
    </>
  );
}

export default DownloadOurMobileApp;
