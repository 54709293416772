import {
  faArrowDown,
  faArrowUp,
  faCircleMinus,
  faCirclePlus,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  addJobInterviewQuestion,
  removeJobInterviewQuestion,
} from "../../../data/jobDescriptions";
import { updateInterviewQuestionSort } from "../../../data/interviewQuestion";
import { useState, useEffect } from "react";

function SortableListJob({
  list = [],
  updateField,
  displayField,
  collection,
  parentId,
  subcollection,
  callback,
  returnItem,
  isLocked = false,
}) {
  const [items, setItems] = useState(list);
  const [active, setActive] = useState("");
  const [deleteActive, setDeleteActive] = useState(null);

  useEffect(() => {
    setItems(list);
  }, [list]);

  console.log("list", list);

  function addItem() {
    const count = items.length ? Math.max(...items.map(item => item.sortOrder)) + 10 : 10;
    const newItem = {
      displayName: "New Item",
      employer: parentId,
      sortOrder: count,
    };
    // addJobInterviewQuestion(parentId, newItem).then(() => {
    //   setItems([...items, newItem]);
    // });
    addJobInterviewQuestion(parentId, newItem)
  }

  function removeItem(id) {
    // removeJobInterviewQuestion(parentId, id).then(() => {
    //   const updatedItems = items.filter(item => item.id !== id);
    //   const reindexedItems = updatedItems.map((item, index) => ({
    //     ...item,
    //     sortOrder: (index + 1) * 10,
    //   }));
    //   setItems(reindexedItems);
    // });
    removeJobInterviewQuestion(parentId, id)
  }

  function removeItemFn(val, x) {
    if (val === 'check') {
      setDeleteActive(x);
    }
    if (val === 'cancel') {
      setDeleteActive(null);
    }
  }

  function moveUpItem(id) {
    const currentIndex = items.findIndex(item => item?.id === id);
    if (currentIndex > 0) {
      const updatedItems = [...items];
      const temp = updatedItems[currentIndex - 1].sortOrder;
      updatedItems[currentIndex - 1].sortOrder = updatedItems[currentIndex].sortOrder;
      updatedItems[currentIndex].sortOrder = temp;
      setItems(updatedItems);
      updateInterviewQuestionSort(
        updatedItems[currentIndex]?.sortOrder,
        parentId,
        collection,
        subcollection,
        updatedItems[currentIndex]?.id,
      );
      updateInterviewQuestionSort(
        updatedItems[currentIndex - 1]?.sortOrder,
        parentId,
        collection,
        subcollection,
        updatedItems[currentIndex - 1]?.id,
      );
    }
  }

  function moveDownItem(id) {
    const currentIndex = items.findIndex(item => item?.id === id);
    if (currentIndex < items?.length - 1) {
      const updatedItems = [...items];
      const temp = updatedItems[currentIndex + 1].sortOrder;
      updatedItems[currentIndex + 1].sortOrder = updatedItems[currentIndex].sortOrder;
      updatedItems[currentIndex].sortOrder = temp;
      setItems(updatedItems);
      updateInterviewQuestionSort(
        updatedItems[currentIndex].sortOrder,
        parentId,
        collection,
        subcollection,
        updatedItems[currentIndex]?.id,
      );
      updateInterviewQuestionSort(
        updatedItems[currentIndex + 1]?.sortOrder,
        parentId,
        collection,
        subcollection,
        updatedItems[currentIndex + 1]?.id,
      );
    }
  }

  function selectItem(item) {
    setActive(item.id);
    returnItem(item);
  }

  const styling = {
    active:
      "w-full items-start grid grid-cols-8 gap-1 p-2 mb-2 rounded-lg shadow-lg col-span-9 bg-instant-teams-teal-Main text-white",
    inactive:
      "w-full items-start text-instant-teams-teal-Main grid grid-cols-8 gap-1 p-2 mb-2 rounded-lg shadow-lg col-span-9 hover:bg-instant-teams-teal-Main hover:text-white",
  };

  return (
    <div className="w-full">
      <div className="w-full text-right mb-4">
        {!isLocked && (
          <button className="" onClick={addItem}>
            <FontAwesomeIcon
              icon={faCirclePlus}
              className="mr-1 text-instant-teams-green-Main h-6 w-6"
            />
          </button>
        )}
      </div>
      {items.map((item, i) => (
        <div key={item.id} className="grid grid-cols-10 gap-1 mb-4">
          <div className="col-span-1 content-center pt-2 text-bold">
            {i + 1}
          </div>
          <div
            className={active === item.id ? styling.active : styling.inactive}
          >
            <div className="grid grid-cols-2 gap-1">
              {!isLocked && (
                <>
                  <div className="col-span-1 text-right">
                    {i !== 0 && (
                      <button className="" onClick={() => moveUpItem(item.id)}>
                        <FontAwesomeIcon
                          icon={faArrowUp}
                          className="mr-1 h-4 w-4"
                        />
                      </button>
                    )}
                  </div>
                  <div className="col-span-1 text-left">
                    {i !== items.length - 1 && (
                      <button
                        className=""
                        onClick={() => moveDownItem(item.id)}
                      >
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="mr-1 h-4 w-4"
                        />
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
            <div
              className="col-span-6 text-center font-bold"
              onClick={() => {
                callback(item.id);
                selectItem(item);
              }}
            >
              {item[displayField]}
            </div>
            <div className="col-span-1 text-right" style={{
              display: deleteActive === item.id ? '' : 'none'
            }}>
              {!isLocked && (
                
                <div>
                <div className="flex ml-[-20px]" >
                <button style={ {marginRight: 10}} className="" onClick={() => removeItem(item.id)}>
                 
                  Yes
                </button>
                 <button className="" onClick={() => removeItemFn("cancel")}>
                 
                  No
               </button>
               </div>
               </div>
              )}
            </div>
            <div className="col-span-1 text-right" style={{
              display: deleteActive === null || deleteActive !== item.id ? '' : 'none'
            }}>
              <button className="" onClick={() => removeItemFn("check", item.id)}>
                <FontAwesomeIcon
                  icon={faCircleMinus}
                  className="mr-1 text-instant-teams-orange-D2 h-4 w-4"
                />
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SortableListJob;
