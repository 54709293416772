import Meta from "../../components/custom-ui/meta/Meta";
import { requireAuth, useAuth } from "../../providers/auth";
import { useApplicationsByUser } from "../../data/applications";
import SeekerApplicationItem from "../../components/seekerView/ApplicationItem";
import WedgeSpinner from "../../components/spinners/wedges-spinner";

function SeekerApplicationsPage() {
  const { user } = useAuth();
  const { data: userApplicationData, isLoading } = useApplicationsByUser(
    user?.uid,
  );

  const filterApplications = (applications: any) =>
    applications?.filter(
      ({ status }: { status: string }) => status !== "offered",
    );
  const filteredApplications = filterApplications(userApplicationData);
  if (isLoading) {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <WedgeSpinner />
      </div>
    );
  }
  return (
    <>
      <Meta title="Applications" />
      <section className="pt-40 pb-16 flex flex-col place-item-center space-y-3">
        {filteredApplications && filteredApplications.length > 0 ? (
          filteredApplications?.map((application: any) => {
            return (
              <SeekerApplicationItem
                key={application.id}
                application={application}
              />
            );
          })
        ) : (
          <div className="mx-auto">
            You don't have any job applications currently.
          </div>
        )}
      </section>
    </>
  );
}

export default requireAuth(SeekerApplicationsPage);
