import { useRef, useState, useEffect } from "react";

function ExpandOverflowList({
  children,
  length,
  height = "h-52",
}: {
  children: JSX.Element;
  length: number;
  height?: string;
}) {
  const [expanded, setExpanded] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);
  const listRef = useRef(null);

  useEffect(() => {
    if (listRef.current.scrollHeight > listRef.current.clientHeight) {
      setHasOverflow(true);
    }
    console.log(listRef.current.clientHeight);
    console.log(listRef.current.scrollHeight);
  }, [length]);
  console.log(hasOverflow);
  console.log(expanded);
  return (
    <div
      ref={listRef}
      className={`bg-white ${
        expanded ? "h-full" : `${height} overflow-hidden`
      } transition-height duration-500 ease-in-out flex flex-wrap`}
    >
      {children}
      {hasOverflow && (
        <div
          className="cursor-pointer absolute bottom-0  w-full text-instant-teams-blue-Main py-2 text-center"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? "Close" : "Expand"}
        </div>
      )}
    </div>
  );
}

export default ExpandOverflowList;
