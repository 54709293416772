import {
  collection,
  addDoc,
  query,
  where,
  orderBy,
  doc,
} from "firebase/firestore";
import { db, createQuery } from "../providers/database";
import { useQuery } from "react-query";
/**** NOTES ****/

export async function createNote(
  employerId: string,
  collectionName: string,
  note: object,
) {
  return addDoc(collection(db, collectionName, employerId, "Notes"), {
    ...note,
  });
}

export function useAllNotesByCollectionAndId(
  id: string,
  collectionName: string,
  subcollectionName: string,
) {
  return useQuery(
    ["allNotes", { id, collectionName, subcollectionName }],
    createQuery(() => {
      return query(
        collection(db, `${collectionName}/${id}/${subcollectionName}`),
        orderBy("timestamp", "desc"),
      );
    }),
    { enabled: !!id },
  );
}
export function useAllSharedNotesByCollectionAndId(
  id: string,
  collectionName: string,
  subcollectionName: string,
) {
  return useQuery(
    ["allNotes", { id, collectionName, subcollectionName }],
    createQuery(() => {
      return query(
        collection(db, `${collectionName}/${id}/${subcollectionName}`),
        where("isPublic", "==", true),
        orderBy("timestamp", "desc"),
      );
    }),
    { enabled: !!id },
  );
}
