import SeekerViewCardWrapper from "../../components/custom-ui/cards/seekerViewCard";
import Meta from "../../components/custom-ui/meta/Meta";

import { requireAuth, useAuth } from "../../providers/auth";
import { useAllListings } from "../../data/listings";
import { useApplicationsByUser } from "../../data/applications";
import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";
import SeekerJobItem from "../../components/seekerView/SeekerJobItem";
import { Link, useSearchParams } from "react-router-dom";
import MarketingModal from "../../components/custom-ui/marketing/MarketingModal";
import { useEmployer } from "../../data/employer";
import AlertDisplay from "../../components/alerts/AlertDisplay";
import useModal from "../../hooks/useModal";
import DialogWrapper from "../../components/custom-ui/dialog/Dialog";
import { useAllActiveAlerts } from "../../data/alerts";
import { useEffect } from "react";
import { useJobPositions } from "../../data/positions";


function SeekerJobsPage() {
  const INSTANTTEAMS_ID = process.env.REACT_APP_INSTANT_TEAMS_ID || "";
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const APPLIED_TO_ALL_JOBS = "You have applied to all available jobs.";
  let externalJObArray: any = [];
  let positionList: any = [];
  const companyIdByURL = searchParams.get("company");
  const { data: listingsData = [] } = useAllListings(user?.uid);

  const { data: applicationsData = [] } = useApplicationsByUser(user?.uid);
  const { data: InstantTeamsData } = useEmployer(INSTANTTEAMS_ID);
  const { data: alerts } = useAllActiveAlerts();
  const { isShowing: isShowingAlerts, toggle: toggleAlerts } = useModal();
  let hideAlerts = sessionStorage.getItem("hideAlerts");
  externalJObArray = applicationsData
    ?.filter((a: { status: string, externalJob: any }) => a.status === "invited" && a.externalJob === true)
    ?.map((job: any) => job);



  const alreadyAppliedJobs = applicationsData?.map((a: any) => a.jobId) || [];
  // Modify a BPO listing by rendering Instant Teams (IT) info instead of the company info.
  const modifyBPOListing = (listing: any) => {
    return {
      ...listing,
      companyName: InstantTeamsData?.displayName,
      companyLogo: InstantTeamsData?.logo,
      employerId: INSTANTTEAMS_ID,
    };
  };

  // Merge the modified BPO listings back into the original listings array.
  const mergeBPOListings = (listings: any[], modifiedListings: any[]) => {
    return listings?.map((listing: any) => {
      if (listing.solutionType === "BPO") {
        const modifiedListing = modifiedListings?.find(
          (item: any) => item.id === listing.id,
        );
        return modifiedListing || listing;
      } else {
        return listing;
      }
    });
  };

  const filterAppliedJobs = (listings: any[], applicationsData: any[]) => {
    const invitedJobIds = applicationsData
      ?.filter((a: { status: string }) => a.status === "invited")
      ?.map((a: { jobId: any }) => a.jobId);

      console.log(invitedJobIds)


    console.log(externalJObArray)

    const appliedJobs = applicationsData
      ?.filter(
        (a: { jobId: any }) => !invitedJobIds?.includes(a.jobId) ?? false,
      )
      ?.map((a: { jobId: any }) => a.jobId);

    listings?.forEach((listing: { promoted: any; jobId: any }) => {
      listing.promoted = invitedJobIds?.includes(listing.jobId);
    });

    return (
      listings?.filter(
        (l: { jobId: any }) => !appliedJobs?.includes(l.jobId) ?? false,
      ) || []
    );
  };
  // Main function to filter, modify, and categorize listings into promoted and non-promoted ones.
  const itemFilter = (listings: any) => {
    const BPOListings = listings?.filter(
      (listing: any) => listing.solutionType === "BPO",
    );
    const modifiedBPOListings = BPOListings?.map(modifyBPOListing);

    const mergedListings = mergeBPOListings(listings, modifiedBPOListings);

    const filteredListings = filterAppliedJobs(
      mergedListings,
      applicationsData,
    );



    const nonPromotedListings = filteredListings?.filter(
      ({ promoted }: any) => !promoted,
    );

    const promotedListings = filteredListings?.filter(
      ({ promoted }: any) => promoted,
    );

    return { nonPromotedListings, promotedListings };
  };

  const { nonPromotedListings, promotedListings } = itemFilter(listingsData);

  const handleAlertClose = () => {
    sessionStorage.setItem("hideAlerts", "true");
    toggleAlerts();
  };



  positionList = useJobPositions(externalJObArray[0]?.jobId)?.data?.filter((data: any) => data.urlActive === true)
  console.log(positionList,"tgegeg3g")

  function filterJobsByIdAndPositionId(array: any, jobIds: any, positionIds: any) {
    return array.filter((job: any) => {

      if (jobIds?.includes(job.jobId)) {
        let posFound = false;
        for (let i = 0; i < jobIds.length; i++) {
          if (job?.positionId === positionIds[i]) {
            posFound = true; // Keep this job as both jobId and positionId match
            break;
          }
        }
        return posFound;

      } else {
        return true // keep
      }

      // if (jobIds?.includes(job.jobId)) {
      //   let jobIndex = jobIds?.indexOf(job?.jobId)
      //   if (job?.positionId === positionIds[jobIndex]) {
      //     return true; // Keep this job as both jobId and positionId match
      //   }
      //   return false; // Remove this job as jobId matches but positionId does not match
      // }

      return true;
    });
  }

  const filteredExternalJob = externalJObArray?.filter((val: any) => val?.externalJob && val.externalJob === true)


  const jobIds = positionList?.map((job: any) => job.jobDescription);
  const positionIds = positionList?.map((job: any) => job.id);

  const filteredArray = filterJobsByIdAndPositionId(promotedListings, jobIds, positionIds);

  const updatePromotedListing = filteredArray.length >= 0 ? filteredArray : promotedListings

  if (
    !listingsData ||
    (nonPromotedListings.length === 0 && promotedListings.length === 0)
  ) {
    return (
      <>
        <Meta title="Jobs" />
        <section className="pt-40 pb-16 flex flex-col place-items-center space-y-10">
          <div className="text-instant-teams-teal-Main text-xl uppercase">
            {nonPromotedListings.length === 0 && promotedListings.length === 0
              ? APPLIED_TO_ALL_JOBS
              : "No jobs available to apply to at the moment."}
          </div>
          {!isShowingAlerts && !hideAlerts && alerts?.length > 0 && (
            <DialogWrapper
              title="System Alerts"
              onClose={handleAlertClose}
              size="w-1/3"
            >
              {/* @ts-ignore */}
              <AlertDisplay alerts={alerts} />
            </DialogWrapper>
          )}
        </section>
        <MarketingModal />
      </>
    );
  }


  return (
    <>
      <Meta title="Jobs" />
      <section className="pt-40 pb-16 flex flex-col place-items-center space-y-10">
        {promotedListings && promotedListings.length > 0 && (
          <div className="pt-4 pb-12 px-5 flex flex-col place-items-center w-[63%] space-y-3 border-2 border-instant-teams-gray-Main rounded-xl">
            <div className="relative -top-8 -left-1/3 text-instant-teams-teal-Main text-xl uppercase bg-gray-50 px-4">
              Sponsored
            </div>
            {promotedListings.some(
              (promotedListing) =>
                !alreadyAppliedJobs.includes(promotedListing.id) &&
                (!companyIdByURL ||
                  promotedListing.employerId === companyIdByURL),
            ) ? (
              //upate  with updatedArray
                updatePromotedListing.filter(
                (promotedListing: any) =>
                  !alreadyAppliedJobs.includes(promotedListing.id) &&
                  (!companyIdByURL ||
                    promotedListing.employerId === companyIdByURL),
              )
                .map((promotedListing: any) => (
                  <div key={promotedListing.id} className="w-full">
                    <Link to={`/jobs/${promotedListing.id}`}>
                      <div
                        className={
                          TailwindHoverEnlarge(103) +
                          " grid grid-flow-row grid-cols-5 h-36 w-full cursor-pointer text-instant-teams-blue-Main bg-white rounded-lg shadow-input-field"
                        }
                      >
                        <SeekerJobItem listing={promotedListing} />
                      </div>
                    </Link>
                  </div>
                ))
            ) : (
              <div className="text-instant-teams-teal-Main text-xl uppercase">
                {APPLIED_TO_ALL_JOBS}
              </div>
            )}
          </div>
        )}
        <div className="flex flex-col place-items-center w-full space-y-3">
          {nonPromotedListings &&
            nonPromotedListings.some(
              (listing) =>
                !alreadyAppliedJobs.includes(listing.id) &&
                (!companyIdByURL || listing.employerId === companyIdByURL),
            ) ? (
            nonPromotedListings.filter(
              (listing: any) =>
                !alreadyAppliedJobs.includes(listing.id) &&
                (!companyIdByURL || listing.employerId === companyIdByURL),
            )
              .map((listing: any) => (
                <div
                  key={listing.id}
                  className="w-[73%] h-32  px-14 md:px-8 flex flex-col place-items-center"
                >
                  <SeekerViewCardWrapper link={`/jobs/${listing.id}`}>
                    <SeekerJobItem listing={listing} />
                  </SeekerViewCardWrapper>
                </div>
              ))
          ) : (
            <div className="text-instant-teams-teal-Main text-xl uppercase">
              {APPLIED_TO_ALL_JOBS}
            </div>
          )}
        </div>
      </section>
      {!isShowingAlerts && !hideAlerts && alerts?.length > 0 && (
        <DialogWrapper
          title="System Alerts"
          onClose={handleAlertClose}
          size="w-1/3"
        >
          {/* @ts-ignore */}
          <AlertDisplay alerts={alerts} />
        </DialogWrapper>
      )}
      <MarketingModal />
    </>
  );
}

export default requireAuth(SeekerJobsPage);
