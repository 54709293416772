import { useEffect, useState } from "react";
import { Notification } from "../../../dataTypes/Notifications";
import useLocalTime from "../../../hooks/useLocalTime";
import { useUser } from "../../../data/user";
import { TailwindHoverEnlarge } from "../../../util/tailwind/tailwind-styles";
interface NotificationItemProps {
  notification: Notification;
}

const NotificationItem = ({ notification }: NotificationItemProps) => {
  const { group, title, createdAt, body, createdBy } = notification;
  const [name, setName] = useState("");
  const { data: userInfo } = useUser(createdBy);

  const options = {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  };

  useEffect(() => {
    setName(userInfo?.name);
  }, [userInfo]);

  const formattedDate = useLocalTime(createdAt.seconds * 1000, options);

  return (
    <tr className={TailwindHoverEnlarge(101) + "border-b border-gray"}>
      <td className="p-2">{title}</td>
      <td className="p-2">{formattedDate}</td>
      <td className="p-2">{group}</td>
      <td className="max-w-xs break-words p-2">{body}</td>
      <td className="p-2">{name || "System Generated"}</td>
    </tr>
  );
};

export default NotificationItem;
