/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useQuery,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  doc,
  getDocs,
  collection,
  query,
  where,
  orderBy,
  addDoc,
  limit,
  deleteDoc,
} from "firebase/firestore";

import { db, createQuery } from "../providers/database";
import { SyncRequest, EnrollmentProcess } from "../dataTypes/academy";
import { collectionNames } from "./dictionary/collectionNames";


export function addAccelerator(
  record: object,
) {
  return addDoc(
    collection(
      db,
      `${collectionNames.accelerators}`,
    ),
    record,
  );
}

// Temporary List of all Components
export function useAccelerators(trigger: string){
  return useQuery(
    ["accelerators"],
    createQuery(() =>
      query(
        collection(db, collectionNames.accelerators),
      ),
    ),
    { enabled: !!trigger },
  );
}

export function useAccelerator(id: string) {
  return useQuery(
    ["accelerator", { id }],
    createQuery(() => doc(db, collectionNames.accelerators, id)),
    { enabled: !!id },
  );
}