import { useQuery } from "react-query";
import { createQuery, db } from "../providers/database";
import { collectionGroup, query, where } from "firebase/firestore";

export function useFindPreScreenInformation(type: string){
    return useQuery(
        ["preeScreenInformation", {type}],
        createQuery(() => {
            return query(
                collectionGroup(db, "PreScreen"),
                where('type', '==', type)
            );
        }),
        {enabled: !!type}
    )
}