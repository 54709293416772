import { useState, useEffect } from "react";
import { useUser } from "../../../../providers/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { requireAuth, useAuth } from "../../../../providers/auth";
import Affiliations from "../../../user/details/parts/Affiliations";
import { firstNameOnly } from "../../../../util/strings/stringManipulation";
import { useLookup } from "../../../../data/lookup";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const RedactedSeekerInfo = ({
  userId,
  imageVisible,
}: {
  userId: string;
  imageVisible: boolean;
}) => {
  const { userClaims } = useAuth();
  const [id, setId] = useState(userId);
  const { data: seekerData, error: seekerError } = useUser(id);
  const [name, setName] = useState("");

  const { data: countries } = useLookup("countries");

  const seekerCountry = countries?.find(
    (country: any) => country?.code === seekerData?.address?.country,
  );
  const { data: states } = useLookup("state");

  const seekerState = states?.find(
    (state: any) => state?.code === seekerData?.address?.state,
  );

  useEffect(() => {
    setName(seekerData?.name || "");
  }, [seekerData]);

  return (
    <>
      <div className="w-full flex h-full">
        {imageVisible && (
          <div className="w-1/8 pt-2 pr-16">
            <img
              src={seekerData?.photoURL || "/images/default_user.png"}
              alt={seekerData?.preferredName}
              className={`rounded-full w-36 max-h-36 border-solid border-4 mx-auto ${
                seekerData?.status !== "active"
                  ? " border-instant-teams-red-Main"
                  : "border-instant-teams-green-D1"
              }`}
            />
          </div>
        )}
        <div className="w-7/8 pt-2">
          <div className=" text-2xl font-black text-instant-teams-teal-D1 uppercase">
            {firstNameOnly(seekerData?.name)}
          </div>
          <div className="flex mt-4 w-full font-black text-instant-teams-teal-D1">
            <Affiliations userId={id} background={"lightBackground"} />
          </div>
          <div className=" space-x-1 mt-4 w-full font-black text-instant-teams-teal-D1">
            <FontAwesomeIcon size="xl" icon={faMapMarkerAlt} />
            <span className="text-lg">
              {seekerState
                ? `${seekerState?.label}, ${seekerCountry?.label}`
                : `${seekerCountry?.label}`}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default requireAuth(RedactedSeekerInfo);
