import React, { useState, useEffect } from "react";
import { useAuth } from "../../providers/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-duotone-svg-icons";
import { Transition, Dialog } from "@headlessui/react";
import { createNotification } from "../../data/notifications";
import { createAlert } from "../../data/alerts";

const CreateNotificationForm = ({ onDone }: { onDone: Function }) => {
  const user = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [group, setGroup] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [button, setButton] = useState("Create Notification");

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (group && title && body) {
      setPending(true);
      setButton("Creating Notification...");
      const data = {
        group: group,
        title: title,
        body: body,
        createdBy: user.user.id,
        isPushNotification: false,
      };
      createNotification(data)
        .then((res) => {
          onDone(`Added ${data.title}`);
        })
        .catch((error) => {
          setFormAlert(`There was a problem creating ${data.title}`);
        })
        .finally(() => {
          setPending(false);
          setButton("Create Notification");
        });
    }
  };

  return (
    <>
      <Transition appear={true} show={true}>
        <Dialog
          onClose={() => onDone()}
          className="overflow-y-auto fixed inset-0"
        >
          <div className="px-4 min-h-screen text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="inline-block overflow-hidden p-6 my-8 w-full max-w-md text-left align-middle bg-white rounded-2xl shadow-xl transition-all transform">
                <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
                  Create Notification
                </Dialog.Title>
                <div className="mt-4">
                  <div className="mb-2">
                    <select
                      className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                      name="group"
                      onChange={(e) => setGroup(e.target.value)}
                    >
                      <option value="">Select receiving group</option>
                      <option value="internalUsers">All Internal Users</option>
                      <option value="sadmin">System Admins</option>
                      <option value="tac">TAC Team</option>
                      <option value="S">All Seekers</option>
                    </select>
                    {group === "" && (
                      <span className="absolute right-7 top-30 text-xs">
                        {"Required "}
                        <FontAwesomeIcon icon={faCircleExclamation} />
                      </span>
                    )}
                  </div>
                  <div className="mb-2">
                    <input
                      className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                      name="title"
                      type="text"
                      placeholder="title"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    {title === "" && (
                      <span className="absolute right-7 top-30 text-xs">
                        {"Required "}
                        <FontAwesomeIcon icon={faCircleExclamation} />
                      </span>
                    )}
                  </div>
                  <div className="mb-2">
                    <textarea
                      className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                      name="body"
                      placeholder="notification"
                      onChange={(e) => setBody(e.target.value)}
                    />
                    {body === "" && (
                      <span className="absolute right-7 top-30 text-xs">
                        {"Required "}
                        <FontAwesomeIcon icon={faCircleExclamation} />
                      </span>
                    )}
                  </div>
                  <button
                    className="py-2 px-4 w-full text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                    type="submit"
                    onClick={(e) => onSubmit(e)}
                    disabled={pending}
                  >
                    {button}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default CreateNotificationForm;
