import NewUserChart from "../../../charts/newUsers";
import UsersByType from "../../../charts/UsersByType";
import GlobalCounts from "../dataSets/counts";
// import EnrollmentSyncFailures from "../dataSets/enrollmentSyncFailures";  --->temporarily  not rendering  this component may render it on future dont delete this  component
import { useAllActiveAlerts } from "../../../data/alerts";
import useModal from "../../../hooks/useModal";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import AlertDisplay from "../../alerts/AlertDisplay";
import { useState } from "react";
import EmployersCard from "../AdminMetricDashboard/EmployerCard";
import JobsCard from "../AdminMetricDashboard/JobsCard";
import CareerSeekerCard from "../AdminMetricDashboard/CareerSeekerCard";
import InteractionChart from "../../../charts/InteractionsChart";
import SheerIdCard from "../AdminMetricDashboard/SheerIdCard";
import SheerIdReportCards from "../AdminMetricDashboard/SheerIdReportCards";
import JobPieChartContainer from "../../../charts/jobPieChartContainer";
function SadminDashboard(props) {
  const [daysSelected, setDaysSelected] = useState(14)

  const { data: alerts } = useAllActiveAlerts();
  const { isShowing: isShowingAlerts, toggle: toggleAlerts } = useModal();
  let hideAlerts = sessionStorage.getItem("hideAlerts");

  const handleAlertClose = () => {
    sessionStorage.setItem("hideAlerts", "true");
    toggleAlerts();
  };
  function getFormattedDate() {
    const date = new Date();
    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const formattedDate = `${day} ${month}, ${year}`;
    return formattedDate;
  }

  return (
    <>
      <div className="w-[66%] mt-5 flex justify-between">
        <p className="text-xl text-[#1E596D]  font-bold">{getFormattedDate()}</p>
        <div className="p-1 mr-[-6px] ">
          <select
            value={daysSelected}
            className="w-[150px] bg-white border py-2"
            onChange={(e) => { setDaysSelected(e.target.value) }}
          >
            <option className="py-2" value={3}>Last 3 Days</option>
            <option className="py-2" value={7}>Last 7 Days</option>
            <option className="py-2" value={14}>Last 14 Days</option>
            <option className="py-2" value={30}>Last 30 Days</option>
          </select>
        </div> </div>
      <div className="w-full grid mt-[15px] grid-cols-3 gap-3 ">
        <div className="w-full h-full col-span-2">
          <NewUserChart daysSelected={daysSelected} />
        </div>
        <div className="w-full">
          <SheerIdCard daysSelected={daysSelected} />
        </div>
      </div>
      <div className="w-full grid mt-[12px] grid-cols-1 gap-4">
        <div className="w-full col-span-2">
          <InteractionChart daysSelected={daysSelected} />
        </div>
        <div className="w-full">
          {/* <SheerIdCard /> */}
        </div>
      </div>
      <div className="w-full grid mt-[-3px] grid-cols-2 gap-3">
        <JobPieChartContainer daysSelected={daysSelected} />

      </div>
      <div className="w-full grid mt-[12px]  grid-cols-3 gap-3">
        <EmployersCard />
        <JobsCard />
        <CareerSeekerCard />
      </div >
      <div className="w-full grid mt-[12px] grid-cols-3 gap-3">
        <UsersByType />
        <GlobalCounts />
        <SheerIdReportCards />
      </div>

      < div className="grid grid-rows-2 grid-cols-4 gap-10" >

        {
          !isShowingAlerts && !hideAlerts && alerts?.length > 0 && (
            <DialogWrapper
              title="System Alerts"
              onClose={handleAlertClose}
              size="w-1/3"
            >
              {/* @ts-ignore */}
              <AlertDisplay alerts={alerts} />
            </DialogWrapper>
          )
        }
      </div >
    </>
  );
}

export default SadminDashboard;
