import { useUser } from "../../../../data/user";
import PhoneNumber from '../../../custom-ui/input-fields/phoneNumber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../custom-ui/tooltip/tooltip';

function TraineeDetailsItem({
  trainee
}) {
  const {data: user} = useUser(trainee.userId);
  console.log(user);
  console.log(trainee);
  function getRing() {
    if (trainee.status === "started") {
      return "border-instant-teams-green-Main";
    } else {
      return"border-instant-teams-orange-D2";
    }
  }
  return (
    <div className="w-full bg-white rounded-xl grid grid-cols-10 gap-2 p-2 font-bold">
      <div className="flex items-center">
        <img
          className={`w-14 h-14 object-cover mx-auto rounded-full border-4 ${getRing()}`}
          src={user?.photoURL || "/images/default_user.png"}
          alt="avatar"
        />
      </div>
      <div className="flex items-center col-span-2">{user.name}</div>
      <div className="flex items-center col-span-2">{user.email}</div>
      <div className="flex items-center col-span-4"><PhoneNumber number={user?.phone || ""} /></div>
      <div className="flex items-center text-instant-teams-green-D1">
        <Tooltip text="Open Employee Record">
          <a href={`/employee/${trainee.id}/details`} target="_blank">
            <FontAwesomeIcon icon={faEye}/>
          </a>
        </Tooltip>
      </div>
    </div>
  );
}

export default TraineeDetailsItem;
