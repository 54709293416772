import { useEffect, useState } from "react";
import AutoSaveCheckboxGroup from "../../../custom-ui/input-fields/auto-save-checkbox-group";
import ForwardArrowButton from "../../../custom-ui/button/ForwardArrowButton";
import { Resume } from "../../../../dataTypes/Resume";
import { collectionNames } from "../../../../data/dictionary/collectionNames";
import { useResume } from "../../../../data/seeker";
import { useLookup } from "../../../../data/lookup";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
function WorkPreferences({
  userId,
  handleDone,
  children,
}: {
  userId: string;
  handleDone: Function;
  children?: React.ReactNode;
}) {
  const { data: resumeData = {}, status: resumeQueryStatus } =
    useResume(userId);
  const { data: model = [], status: modelQueryStatus } = useLookup("model");
  const { data: classification = [], status: classificationQueryStatus } =
    useLookup("classification");
  const { data: term = [], status: termQueryStatus } = useLookup("term");
  const gridLayoutClass = "grid grid-rows-3 gap-2";

  const [validateData, setValidateData] = useState();
  const [error, setError] = useState();

  const workPreferenceSchema = z.object({
    terms: z.string().array().nonempty({
      message: "Please select a work term.",
    }),
    models: z.string().array().nonempty({
      message: "Please select a work setting.",
    }),
    classifications: z.string().array().nonempty({
      message: "Please select a work type.",
    }),
  });

  useEffect(() => {
    if (validateData) {
      // validate data against schema
      const result = workPreferenceSchema.safeParse(resumeData);
      if (!result.success) {
        const { error } = result;
        console.log(error);
        setError(error);
      } else {
        handleDone();
      }
      setValidateData(false);
    }
  }, [validateData, resumeData, workPreferenceSchema, handleDone]);

  return (
    <>
      <p className="mb-3 text-instant-teams-gray-D1">
        Tell us what you're looking for, and click all that apply to you.
      </p>
      <p
        className={`mb-3 ${
          error ? "text-instant-teams-red-Main" : "text-instant-teams-teal-Main"
        }`}
      >
        You must select at least one option in each of these categories.
      </p>
      {resumeQueryStatus === "success" && (
        <div className="mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3  gap-14">
            <div>
              <h2 className="mb-4 uppercase text-instant-teams-blue-Main">
                Work Setting
              </h2>
              <AutoSaveCheckboxGroup
                name="models"
                gridLayoutClass={gridLayoutClass}
                options={model}
                initialValues={resumeData?.models}
                collection={collectionNames.resume}
                id={userId}
              />
            </div>

            <div className="flex flex-col items-center">
              <h2 className="mb-4 uppercase text-instant-teams-blue-Main">
                Work Term
              </h2>
              <AutoSaveCheckboxGroup
                name="classifications"
                gridLayoutClass={gridLayoutClass}
                options={classification}
                initialValues={resumeData?.classifications}
                collection={collectionNames.resume}
                id={userId}
              />
            </div>

            <div className="flex flex-col items-end">
              <h2 className="mb-4 uppercase text-instant-teams-blue-Main">
                Work Type
              </h2>
              <AutoSaveCheckboxGroup
                name="terms"
                gridLayoutClass={gridLayoutClass}
                options={term}
                initialValues={resumeData?.terms}
                collection={collectionNames.resume}
                id={userId}
              />
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col justify-center items-center">
        {children}
        <ForwardArrowButton
          pending={false}
          type="button"
          onClick={() => setValidateData(true)}
        />
      </div>
    </>
  );
}

export default WorkPreferences;
