import { useAuth } from "../providers/auth";

export function useCurrentUserEmployerId() {
  const auth = useAuth();
  // check if the logged in person is an Employer,
  // if it is, return the employerId, else return empty string
  return auth.userClaims?.claims?.type === "E"
    ? auth.userClaims?.claims?.employerId
    : "";
}
