import {
  useAllActiveMarketings,
  useUserMarketingSubCollection,
} from "../../../data/marketing";
import { useAuth } from "../../../providers/auth";
import { useState, useEffect } from "react";
import { updateMarketingViews } from "../../../data/marketing";
import useModal from "../../../hooks/useModal";
import WedgeSpinner from "../../spinners/wedges-spinner";
import DialogWrapper from "../dialog/Dialog";
import CampaignCardDetails from "../../../pages/marketing/parts/campaign-card-details";
function MarketingModal({}) {
  const { user } = useAuth();
  const [activeMarketings, setActiveMarketings] = useState([]);
  const [currentMarketingElement, setCurrentMarketingElement] = useState(0);
  const modalItem = activeMarketings[currentMarketingElement];
  const { isShowing, toggle } = useModal(true);
  const { data: marketingData, isLoading: marketingDataLoading } =
    useAllActiveMarketings();

  const {
    data: marketingViewsData,
    isLoading: marketingViewsDataLoading,
    isError: marketingViewsError,
  } = useUserMarketingSubCollection(user?.uid);

  useEffect(() => {
    if (!modalItem?.id || !user) return;
    const timestamps =
      marketingViewsData?.find(({ id }: { id: string }) => id === modalItem?.id)
        ?.timestamps || [];
    updateMarketingViews(user?.uid, modalItem?.id, [...timestamps, Date.now()]);
  }, [modalItem]);

  useEffect(() => {
    if (
      marketingData?.length &&
      !activeMarketings?.length &&
      !marketingViewsDataLoading &&
      !marketingViewsError
    ) {
      const currentDate = new Date();

      const filteredMarketings = marketingData?.filter((marketing: any) => {
        const startDate = marketing?.schedule?.dateRange?.startDate?.toDate();
        const endDate = marketing?.schedule?.dateRange?.endDate?.toDate();

        const timestamps =
          marketingViewsData?.find(({ id }: { id: any }) => id === marketing.id)
            ?.timestamps || [];
        const showedToday = timestamps?.some((timestamp: any) => {
          const timestampDate = new Date(timestamp);
          return (
            timestampDate?.getFullYear() === currentDate?.getFullYear() &&
            timestampDate?.getMonth() === currentDate?.getMonth() &&
            timestampDate?.getDate() === currentDate?.getDate()
          );
        });

        return (
          currentDate >= startDate &&
          (endDate ? currentDate <= endDate : true) &&
          (marketing?.schedule?.showOnLogIn
            ? timestamps?.length < Number(marketing?.schedule?.showCount || 0)
            : !showedToday)
        );
      });

      filteredMarketings.sort((a: any, b: any) => {
        const startDateA = a.schedule?.dateRange?.startDate
          ?.toDate()
          ?.getTime();
        const startDateB = b.schedule?.dateRange?.startDate
          ?.toDate()
          ?.getTime();

        return startDateA - startDateB;
      });

      if (filteredMarketings) {
        setActiveMarketings(filteredMarketings);
      }
    }
  }, [marketingData, marketingViewsData]);

  const onClose = () => {
    if (activeMarketings[currentMarketingElement + 1]) {
      setCurrentMarketingElement(currentMarketingElement + 1);
    } else {
      toggle();
    }
  };

  if (marketingDataLoading || !user || marketingViewsDataLoading)
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <WedgeSpinner />
      </div>
    );

  if (!activeMarketings.length || !modalItem) return null;

  return (
    <>
      {isShowing && (
        <>
          <DialogWrapper title="" size="w-1/3" onClose={onClose}>
            <CampaignCardDetails data={modalItem} key={modalItem?.id} />
          </DialogWrapper>
        </>
      )}
    </>
  );
}
export default MarketingModal;
