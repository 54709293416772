import { useParams } from "react-router-dom";
import MessagingCenterEmployerView from "./MessagingCenterEmployerView";

const EmployerMessaging = () => {
  const employerId = useParams()?.employerId;
  return (
    <div className="h-screen w-screen -ml-14">
      <div className="h-[80%] w-[80%]">
        <MessagingCenterEmployerView employerId={employerId} />
      </div>
    </div>
  );
};

export default EmployerMessaging;
