import { useEffect, useState } from "react";
import { updateAutoSave } from "../../../data/utility";

function AutoSaveRadioGrade({
  initialValue = 0,
  collection,
  id,
  name,
  ...props
}) {
  console.log(initialValue);
  console.log(collection);
  const [selection, setSelection] = useState(initialValue ?? 0);
  function saveValue() {
    updateAutoSave(collection, id, { [name]: selection })
      .then(() => console.log("AutoRadiosaved selections...", selection))
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    if (selection !== initialValue) {
      saveValue();
    }
  }, [selection]);

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelection(Number(value));
    }
  };

  return (
    <div
      key={id}
      id={name}
      className="ml-[35%] mr-[25%] grid grid-cols-3 gap-2 mb-4"
    >
      <div className="radio">
        <label className="font-bold text-lg">
          <input
            className="ring-instant-teams-teal-Main checked:bg-instant-teams-teal-Main text-instant-teams-teal-Main ring-0"
            type="radio"
            value="0"
            checked={initialValue === 0 ? true : false}
            onChange={handleChange}
          />
          {"    "}0
        </label>
      </div>
      <div className="radio">
        <label className="font-bold text-lg">
          <input
            className="ring-instant-teams-teal-Main checked:bg-instant-teams-teal-Main text-instant-teams-teal-Main ring-0"
            type="radio"
            value="1"
            checked={initialValue === 1 ? true : false}
            onChange={handleChange}
          />
          {"    "}1
        </label>
      </div>
      <div className="radio">
        <label className="font-bold text-lg accent-instant-teams-teal-Main">
          <input
            className="checked:bg-instant-teams-teal-Main text-instant-teams-teal-Main focus:ring-black-100"
            type="radio"
            value="2"
            checked={initialValue === 2 ? true : false}
            onChange={handleChange}
          />
          {"   "}2
        </label>
      </div>
    </div>
  );
}

export default AutoSaveRadioGrade;
