import { useState, useEffect } from "react";
import { deleteSkill } from "../../../data/jobDescriptions";
import ReqSkillItem from "./ReqSkillItem";
import useModal from "../../../hooks/useModal";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import ReqSkillDetails from "./ReqSkillDetails";
import { useParams } from "react-router-dom";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import { useAuth } from "../../../providers/auth";

const SkillsList = ({
  skills,
  isLocked = false,
}: {
  skills: Array<Object>;
  isLocked?: boolean;
}) => {
  const { userClaims } = useAuth();
  const params = useParams();
  const [assessmentId, setAssessmentId] = useState("");
  const { toggle: assessmentDetailsToggle, isShowing: assessmentDetails } =
    useModal();
  const [duration, setDuration] = useState(0);

  async function addDuration(time: number) {
    setDuration((previous: number) => previous + Number(time));
  }

  const SECTION_LOCKED_MESSAGE =
    "This section cannot be changed while the job status is live.";

  useEffect(() => {
    if (assessmentId !== "") {
      assessmentDetailsToggle();
    }
  }, [assessmentId]);

  useEffect(() => {
    if (!assessmentDetails) {
      setAssessmentId("");
    }
  }, [assessmentDetails]);

  function handleRemove(id: string) {
    if (params.id) {
      console.log(params.id);
      console.log(id);
      deleteSkill(id, params.id);
    }
  }

  return (
    <div className="w-full mb-4 mt-4 text-instant-teams-teal-Main font-bold ">
      <GreenUnderlineHeading
        title="Required Skills"
        showLockIcon={true}
        isLocked={isLocked}
        text={SECTION_LOCKED_MESSAGE}
      />
      {userClaims?.claims?.type === "A" ? (
        <div className="text-sm text-black -mt-6 mb-2">
          {" "}
          Total Duration: {duration} minutes
        </div>
      ) : null}
      <div className="w-full -mx-1 grid grid-cols-8 ">
        {skills && skills.length > 0 ? (
          skills.map((skill: any) => (
            <div
              className="h-full inline-block rounded-lg p-1 text-sm font-semibold m-1 cursor-pointer transition-colors relative"
              key={skill.id}
              id={skill.id}
            >
              {userClaims?.claims?.type === "A" && !isLocked ? (
                <button
                  className="rounded-full absolute top-0 right-0 h-5 w-5 bg-red-700 text-white text-xs z-10"
                  onClick={handleRemove.bind(this, skill.id)}
                >
                  X
                </button>
              ) : null}
              <div
                key={skill.assessmentId}
                onClick={() => {
                  setAssessmentId(skill.assessmentId);
                }}
                className="h-full"
              >
                <ReqSkillItem
                  assessmentId={skill.assessmentId}
                  tier={skill.tier}
                  addDuration={addDuration}
                />
              </div>
            </div>
          ))
        ) : (
          <p>No Skills Selected</p>
        )}
      </div>
      {assessmentDetails && (
        <DialogWrapper
          title="Assessment Details"
          onClose={() => {
            assessmentDetailsToggle();
          }}
          size={"w-1/2  mt-36"}
        >
          <ReqSkillDetails
            assessmentId={assessmentId}
            onDone={assessmentDetailsToggle}
          />
        </DialogWrapper>
      )}
    </div>
  );
};

export default SkillsList;
