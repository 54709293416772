import { useState } from "react";
import { updateAutoSave } from "../../../data/utility";
import { collectionNames } from "../../../data/dictionary/collectionNames";
import { Timestamp } from "firebase/firestore";
import { createExternalViewHistory } from "../../../data/externalViewsHistory";
import { useAuth } from "../../../providers/auth";
import { errorLogger } from "../../../providers/clientLogger";
import { createNotification } from "../../../data/notifications";
import { useUser } from "../../../providers/database";
function SaveForLaterApplicationModal({
  application,
  toggle,
}: {
  application: any;
  toggle: Function;
}) {
  const { user } = useAuth();
  const userName = user?.preferredName ?? user?.name;
  const { data: applicant = {}, status: applicantQueryStatus } = useUser(
    application.resumeId,
  );
  const handleSaveForLater = async () => {
    try {
      updateAutoSave(collectionNames.applications, application.objectID, {
        savedForLater: true,
      });
      await createExternalViewHistory(
        collectionNames.applications,
        application.objectID,
        {
          savedForLaterTimestamp: Timestamp.now(),
          note: saveForLaterMessage,
          who: user?.uid,
        },
      );
      await createNotification({
        group: "tac",
        title: "Seeker Saved for later",
        body: `${userName} saved ${applicant?.name} for later.`,
        createdBy: `${user?.uid}`,
        isPushNotification: true,
        url: `applications/${application?.objectID}/details`,
      });
    } catch (error: any) {
      errorLogger(error.message ?? "Error saving for later", {
        component: "SaveForLaterApplicationModal.tsx:handleSaveForLater",
        flow: "Employer Registration / User Settings",
        userId: user?.uid,
      });
    } finally {
      toggle();
    }
  };
  const [saveForLaterMessage, setSaveForLaterMessage] = useState("");
  return (
    <>
      <div>
        <p className=" mb-5 text-instant-teams-teal-D2 text-lg">
          You are saving this Career Seeker for later. Please provide an
          explanation to help further our assistance to you.
        </p>
      </div>
      <div className="mt-4 ">
        <div className="flex justify-between mb-2">
          <label className="">Explanation</label>
          <p className=" text-xs text-instant-teams-red-Main">**Optional</p>
        </div>
        <textarea
          placeholder={`Add an optional message `}
          className="w-full rounded-xl leading-8"
          onChange={(e) => setSaveForLaterMessage(e.target.value)}
        >
          {saveForLaterMessage}
        </textarea>
        <div className="mt-5 ">
          <button
            onClick={() => handleSaveForLater()}
            className="uppercase w-full min-h-12 h-12 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-D1 focus:outline-none"
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}

export default SaveForLaterApplicationModal;
