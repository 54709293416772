import { forwardRef, useEffect, useState } from "react";
import { isDate } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faCircleXmark,
} from "@fortawesome/pro-regular-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../styles/DatePickerOverrides.css";
import { updateAutoSave } from "../../../data/utility";
import { isNil } from "lodash";
import { Timestamp } from 'firebase/firestore';
// react-datePicker examples: https://reactdatepicker.com/
// react-datePicker docs: https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md

function AutoSaveDate({
  id,
  collection,
  name,
  initialStart,
  timestamp = false,
  disabled = false,
  ...props
}) {
  const [startDate, setStartDate] = useState(
    isDate(initialStart) ? initialStart : new Date(),
  );

  useEffect(() => {
    if (!isNil(startDate)) {
      if (timestamp) {
        const date = Timestamp.fromDate(startDate);
        updateAutoSave(collection, id, { [name]: date })
        .catch((error) => console.log("Error saving range: ", error));
      } else {
        updateAutoSave(collection, id, { [name]: startDate })
        .catch((error) => console.log("Error saving range: ", error));
      }
    }
  }, [collection, id, startDate]);

  const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <div className="custom-date-input w-full">
      <input
        ref={ref}
        className="px-8 w-full"
        type="text"
        value={value}
        onClick={onClick}
        onChange={(e) => {
          e.preventDefault();
        }}
        placeholder="Select date"
      />
      <span className="absolute left-2 top-1.5" onClick={onClick}>
        <FontAwesomeIcon
          icon={faCalendarDays}
          className="text-instant-teams-gray-D1"
        />
      </span>
    </div>
  ));

  return (
    <div id={name} className="grid gap-2 grid-cols-1 lg:grid-cols-2">
      <div className="col-auto">
        <DatePicker
          showIcon
          minDate={new Date()}
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          isClearable={!disabled} // prevent user from clearing input when component is disabled
          customInput={<CustomDateInput />}
          disabled={disabled}
          {...props}
        />
      </div>
    </div>
  );
}

export default AutoSaveDate;
