import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useUser } from "../../data/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PhoneNumber from "../custom-ui/input-fields/phoneNumber";
import SeekerApplicationList from "../seeker/details/parts/seekerApplicationList";
import { useEmployer } from "../../data/employer";

const UserInfo = ({ userId }: { userId: string }) => {
  const { data: seekerData } = useUser(userId);
  const [employerId, setEmployerId] = useState("");
  const { data: employerFromClaims } = useEmployer(employerId);
  const employerIdFromParams = useParams()?.employerId;

  function getType(type: string) {
    switch (type) {
      case "A":
        return "Internal User";
      case "S":
        return "Career Seeker";
      case "E":
        return "Employer";
      default:
        return "Unknown User Type";
    }
  }

  useEffect(() => {
    if (
      seekerData?.claims?.employerId &&
      seekerData?.claims?.employerId !== "111" &&
      seekerData?.claims?.employerId !== "000"
    ) {
      setEmployerId(seekerData?.claims?.employerId);
    } else if (employerIdFromParams) {
      setEmployerId(employerIdFromParams);
    }
  }, [seekerData, employerIdFromParams]);

  return (
    <>
    <div className="h-2/5 w-full px-10 pt-10 text-black overflow-y-scroll overscroll-contain no-scrollbar sticky">
      {seekerData?.claims?.type === "S" ? (
        <Link to={`/seekers/${userId}`} target="_blank">
          <div className="flex justify-center pt-2">
            <img
              src={seekerData?.photoURL || "/images/default_user.png"}
              alt={seekerData?.preferredName}
              className={`rounded-full w-12 max-h-12 border-4 border-solid ${
                seekerData?.status !== "active"
                  ? " border-instant-teams-red-Main"
                  : "border-instant-teams-green-D1"
              }`}
            />
          </div>
        </Link>
      ) : employerId && seekerData ? (
        <div className="flex justify-center pt-2">
          <img
            src={seekerData?.photoURL || "/images/default_user.png"}
            alt={seekerData?.preferredName}
            className={`rounded-full w-12 max-h-12 border-4 border-solid ${
              seekerData?.status !== "active"
                ? " border-instant-teams-red-Main"
                : "border-instant-teams-green-D1"
            }`}
          />
        </div>
      ) : (
        "Employer Data Goes here"
      )}

      <div className="flex justify-center h-full">
        <div className="flex flex-col w-full pt-2 text-center">
          <div className="font-black text-instant-teams-teal-D1">
            {seekerData?.name?.toUpperCase()}
          </div>
          <div className=" text-sm font-black text-instant-teams-teal-D1">
            {seekerData?.claims?.type
              ? getType(seekerData?.claims?.type)
              : "Unknown"}
          </div>
          {seekerData?.claims?.type === "E" && (
            <div className=" text-sm font-black text-instant-teams-teal-D1">
              {employerFromClaims?.displayName || "Unknown"}
            </div>
          )}
          <div className="flex justify-center mt-2">
            {seekerData?.phone && (
              <div className="flex items-center align-center text-instant-teams-teal-D1 mr-4 text-center">
                <FontAwesomeIcon
                  icon={["fas", "phone"]}
                  className="h-5 w-5 pr-2 "
                  swapOpacity
                />
                <PhoneNumber number={seekerData?.phone} />
              </div>
            )}
          </div>
          <div className="flex mt-2 justify-center">
            {seekerData?.email && (
              <div className="flex items-center text-instant-teams-teal-D1 mr-4">
                <FontAwesomeIcon
                  icon={["fas", "envelope"]}
                  className="h-5 w-5 pr-2 "
                  swapOpacity
                />
                {seekerData?.email}
              </div>
            )}
          </div>
          <div className="flex mt-2 justify-center">
            {seekerData?.address && (
              <div className="flex items-center text-instant-teams-teal-D1 mr-4">
                <FontAwesomeIcon
                  icon={["fas", "location-dot"]}
                  className="h-5 w-5 pr-2 "
                  swapOpacity
                />
                {seekerData?.address?.standardizedAddress}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    {seekerData?.claims?.type === "S" && (
        <div className='flex flex-col w-full h-1/2'>
          <div className="py-1 mb-1 mt-4 uppercase block font-semibold text-instant-teams-blue-Main justify-center text-center border-b-2 border-instant-teams-green-Main">
            Current Applications
          </div>
          <div className="flex justify-center pr-2 pl-2 mb-16">
            <SeekerApplicationList id={userId} />
          </div>
        </div>
      )}
    </>
  );
};

export default UserInfo;
