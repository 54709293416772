import React, { useEffect } from "react";
import { useLookupItemByArray } from "../../../data/lookup";

const DisplayArrayLookup = ({
  value: initialValue,
  group,
}: {
  value: Array<string>;
  group: string;
}) => {
  const { data: displayData } = useLookupItemByArray(group, initialValue);

  return (
    <div className="flex-auto text-center">
      {displayData
        ? displayData.map((key: any, index:number) => (
            <span key={key.id}>
              {key.label}
              {index !== displayData.length - 1 && '/'}
            </span>
          ))
        : <span>Not Set</span>}
    </div>
  );
};

export default DisplayArrayLookup;
