import { useAllNotesByCollectionAndId } from "../../../../data/notes";
import SeekerNoteDisplay from "./seekerNoteDisplay";

function SeekerNotesDisplay({
  id,
  collection,
  subCollection,
}: {
  id: string;
  collection: string;
  subCollection: string;
}) {
  const { data: fetchedNotes } = useAllNotesByCollectionAndId(
    id,
    collection,
    subCollection,
  );

  return (
    <>
      <div className="w-full text-base text-black font-normal mt-4">
        {fetchedNotes?.length !== 0 ? (
          fetchedNotes?.map((row: any, index: string) => {
            return <SeekerNoteDisplay entry={row} key={index} />;
          })
        ) : (
          <div className="text-lg text-center text-instant-teams-teal-Main w-full">
            No Notes. Create one now.
          </div>
        )}
      </div>
    </>
  );
}

export default SeekerNotesDisplay;
