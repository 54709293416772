import Meta from "../../components/custom-ui/meta/Meta";
import SeekerOnboardingIndex from "../../components/seeker/details/onboarding/SeekerOnboardingIndex";
import { requireAuth } from "../../providers/auth";
function SeekerOnboardingPage() {
  return (
    <>
      <Meta title="Job Seeker Onboarding" />
      <SeekerOnboardingIndex />
    </>
  );
}

export default requireAuth(SeekerOnboardingPage);
