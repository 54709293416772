import React, { useEffect, useRef, ReactElement } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

const status = (status: Status) => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

function Map({ center, zoom }: { center: any; zoom: number }) {
  const mapRef = useRef(null);
  useEffect(() => {
    // @ts-ignore
    new window.google.maps.Map(mapRef.current, {
      center,
      zoom,
      mapId: "7d74312fbb9c1758",
    });
  });
  return <div className="mt-2 h-full w-full rounded" ref={mapRef} id="map" />;
}

function GoogleMapEmbed({ center, zoom }: { center: any; zoom: number }) {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY ?? "";

  return (
    <>
      <Wrapper apiKey={apiKey} render={status}>
        <Map center={center} zoom={zoom} />
      </Wrapper>
    </>
  );
}

export default GoogleMapEmbed;
