import { useState, useEffect } from "react";
import { useAuth } from "../../providers/auth";
import { useParams } from "react-router-dom";
import { Tab } from "@headlessui/react";
import { useUser } from "../../providers/database";
import {
  useAllChatsByGroup,
  useAllChatsByRecipientId,
  useAllPersonalChats,
  useAllChatsByEmployerId,
} from "../../data/messages";
import { Chat } from "../../dataTypes/Messages";
import useModal from "../../hooks/useModal";
import MessageTabContents from "./MessageTabContents";
import MessagePanel from "./MessagePanel";
import NewConversationModal from "./NewConversationModal";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import { getLabel } from "../../util/search/getLabel";
import UserInfo from "./UserInfo";
import MessageSend from "./MessageSend";
import { markAllMessagesRead } from "../../data/messages";

const MessagingCenter = () => {
  const auth = useAuth();
  const employerId = useParams()?.employerId;
  const { userClaims, user } = auth;
  const [value, setValue] = useState("inbox");
  const [allChats, setAllChats] = useState([]);
  const [selected, setSelected] = useState(null);
  const { data: recipientData } = useUser(selected?.recipientId);
  const { data: chats = [] } = useAllChatsByGroup(
    userClaims?.claims?.role,
    value,
  );
  const { data: employerChats = [] } = useAllChatsByEmployerId(
    userClaims?.claims?.employerId,
  );
  const { data: personalChats = [] } = useAllPersonalChats(user?.id, value);
  const { data: receivedChats = [] } = useAllChatsByRecipientId(
    user?.id,
    value,
  );

  const handleMarkAllRead = () => {
    markAllMessagesRead(user?.id);
  };

  useEffect(() => {
    if (
      chats?.length === 0 &&
      receivedChats?.length === 0 &&
      personalChats?.length === 0 &&
      employerChats?.length === 0
    ) {
      setAllChats("");
    }
    if (
      (chats && chats?.length > 0) ||
      (personalChats && personalChats?.length > 0) ||
      (receivedChats && receivedChats?.length > 0) ||
      (employerChats && employerChats?.length > 0)
    ) {
      const newChats = [
        ...chats,
        ...receivedChats,
        ...personalChats,
        ...employerChats,
      ];
      const uniqueChats = Array.from(
        new Set(newChats.map((chat) => chat.id)),
      ).map((id) => {
        return newChats.find((chat) => chat.id === id);
      });

      // Sort the uniqueChats array by the lastUpdated timestamp
      const sortedChats = uniqueChats.sort(
        (a, b) => b.lastUpdated - a.lastUpdated,
      );
      setAllChats(sortedChats);
    }
  }, [chats, receivedChats, value, personalChats, employerChats]);

  return (
    <div className="grid grid-cols-10 w-full overscroll-contain overflow-y-scroll no-scrollbar h-screen clear-both">
      <div className="w-full col-span-2 overflow-y-scroll overscroll-contain no-scrollbar border-gray-300 border-r-2 h-screen">
        <div className="pt-36 pb-10">
          <div className="w-full flex justify-center py-4">
            <button
              className="py-2 px-4 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
              onClick={handleMarkAllRead}
            >
              Mark All Read
            </button>
          </div>
          {allChats &&
            allChats?.length > 0 &&
            allChats.map((chat: Chat) => (
              <div
                key={chat?.id}
                className={`${
                  selected?.id === chat?.id
                    ? "bg-slate-200 border-2 border-instant-teams-teal-Main"
                    : "text-black border-b-2"
                } text-left hover:bg-slate-200 h-24 w-full`}
                onClick={() => setSelected(chat)}
              >
                <MessageTabContents chat={chat} />
              </div>
            ))}
        </div>
      </div>
      <div className="col-span-5 w-full no-scrollbar relative border-r-2 border-gray-300 h-screen pt-32">
        {selected ? (
          <>
            <div className="sticky w-full bg-instant-teams-teal-Main flex justify-center items-center text-white rounded h-12">
              <p>{`${recipientData?.name}, ${getLabel(
                selected?.group,
                "userRoles",
              )} Team`}</p>
            </div>
            <div className="h-4/5 overflow-y-scroll overscroll-contain no-scrollbar">
              <MessagePanel
                chat={selected}
                key={selected?.id}
                minimize={false}
              />
            </div>
          </>
        ) : (
          <div className="grid grid-cols-10 col-span-8 justify-center items-center w-full">
            <div className="flex col-span-10 justify-center items-center w-full mt-[45%] h-4/5 text-2xl">{`Please select a message to begin.`}</div>
          </div>
        )}
        {selected && (
          <div className="w-full absolute bottom-0 right-0 h-1/8 border-t-2 border-gray-300 pb-4">
            <MessageSend chat={selected} key={selected?.id} />
          </div>
        )}
      </div>
      <div className="col-span-3 w-full overflow-y-scroll overscroll-contain no-scrollbar pt-32">
        {selected && !employerId ? (
          <UserInfo userId={selected?.recipientId} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default MessagingCenter;
