import { useRef } from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import { useAuth } from "../../../../providers/auth";
import getISOWeek from 'date-fns/getISOWeek';
import { useEmployer, createTraining } from '../../../../data/employer';


const CreateEmployerTraining = (props: any) => {
  const auth = useAuth();
  const params = useParams();
  const [employerId, setEmployerId] = useState(params.employerId);
  const [startDate, setStartDate] = useState("");
  const [title, setTitle] = useState(null);

  const { data: employer = {}, isLoading } =
    useEmployer(employerId);

  useEffect(() => {
    setEmployerId(params.employerId);
  }, [params.employerId]);

  function calculateWeekOfYear(dateInput: string) {
    const selectedDate = new Date(dateInput);
    const weekOfYear = getISOWeek(selectedDate);
    return weekOfYear;
  }
  function getTwoDigitYear(dateInput: string) {
    const selectedDate = new Date(dateInput);
    const year = selectedDate.getFullYear();
    return year.toString().slice(-2);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createTraining(employerId, {
      title: title,
      startDate: Timestamp.fromDate(new Date(startDate)),
      createdAt: Timestamp.now(),
      createdBy: auth.user.id,
    });
    setTitle(null);
    setStartDate("");
    props.closeModal();
  };
  useEffect(() => {
    if(employer && startDate !== ""){
      const week = calculateWeekOfYear(startDate);
      const year = getTwoDigitYear(startDate);
      setTitle(`${employer.displayName} Cycle ${year}-${week}`);
    }
  }, [startDate]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-2 mb-4">
        <div className="flex gap-1 items-center">
          <label htmlFor="dateInput">Select cycle start date</label>
          <input type="date" id="dateInput" onBlur={(e) => setStartDate(e.target.value)}></input>
        </div>
        {title !== null ? (
          <button
            type="submit"
            className=" bg-instant-teams-teal-Main mx-auto w-auto p-2 text-white rounded-md text-lg mt-4"
          >
            <span className='font-bold'>Create:{"  "}</span><i>{title}</i>
          </button>
        ):(
          <button
            type="submit"
            className=" bg-instant-teams-teal-Main mx-auto w-auto p-2 text-white rounded-md text-lg mt-4"
            disabled
          >
            Enter Date to Continue
          </button>
        )}        
      </div>
    </form>
  );
};

export default CreateEmployerTraining;
