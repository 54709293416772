import { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";

const SignOfferViewer = ({ offer, toggleWithdrawalModal }) => {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const [file, setFile] = useState();

  const onDocumentLoadSuccess = ({ numPages, width }) => {
    setNumPages(numPages);

    // Calculate the scale to fit the PDF file in the available space
    const containerWidth =
      document.getElementById("pdf-container")?.clientWidth;
    if (containerWidth && width) {
      const newScale = containerWidth / width;
      setScale(newScale);
    }
  };

  const props = {
    file: file,
    key: file,
    onLoadSuccess: onDocumentLoadSuccess,
    error: "Failed to load PDF file",
    loading: "Loading PDF file...",
    noData: "No PDF File Selected",
    renderMode: "canvas",
    className:
      "h-[600px] overflow-scroll no-scrollbar border-2 border-gray-400 mb-4 ",
    options: {
      cMapUrl: "cmaps/",
      cMapPacked: true,
    },
    scale: scale,
  };

  useEffect(() => {
    setFile(offer?.offerLetterUrl);
  }, [offer]);

  return (
    <>
      <div>
        <Document {...props}>
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
      <div className="w-full flex justify-center">
        <a
          className="rounded-lg bg-instant-teams-teal-Main text-white px-4 py-2 mr-4"
          // onClick={handleClick}
          href={offer?.seekerSigningUrl ? offer?.seekerSigningUrl : offer?.embeddedSigningUrl}
          target="_blank"
          rel="noreferrer"
        >
          Sign Offer Letter
        </a>
        <button
          className="rounded-lg border-2 border-instant-teams-teal-Main  px-4 py-2 ml-4 text-instant-teams-teal-Main"
          onClick={toggleWithdrawalModal}
        >
          Decline Offer
        </button>
      </div>
    </>
  );
};

export default SignOfferViewer;
