import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import SeekerNoteDisplay from "../../seeker/details/parts/seekerNoteDisplay";
import { useAuth } from "../../../providers/auth";
import { useState } from "react";
import { Timestamp } from "firebase/firestore";
import { createNote } from "../../../data/notes";
import {
  collectionNames,
  subCollectionNames,
} from "../../../data/dictionary/collectionNames";
import { useForm } from "react-hook-form";
import { useAllSharedNotesByCollectionAndId } from "../../../data/notes";
import useModal from "../../../hooks/useModal";
import Tooltip from "../../custom-ui/tooltip/tooltip";
function ExternalNotesApplicationView({ application }: { application: any }) {
  console.log("application", application);
  const minLength = 10;
  const { user } = useAuth();
  const [buttonText, setButtonText] = useState("Add note");
  const [formDisabled, setFormDisabled] = useState(true);
  const [renderNotes, setRenderNotes] = useState(false);
  const [displayNotesCount, setDisplayNotesCount] = useState(3);

  const [characterCount, setCharacterCount] = useState(0);
  const { isShowing: isNotesFormShowing, toggle: toggleNotesForm } = useModal();

  const { data: fetchedNotes, isLoading: isFetchedNotesLoading } =
    useAllSharedNotesByCollectionAndId(
      application?.id,
      collectionNames.applications,
      subCollectionNames.notes,
    );

  const { handleSubmit, register, watch, reset } = useForm({
    defaultValues: {
      collection: "Notes",
      id: application?.id,
      type: "General",
      timestamp: Timestamp.now(),
      who: user.id,
      isPublic: true,
      note: "",
    },
  });

  const handleTextareaChange = (event: any) => {
    // subscribe to the ref of note to give an accurate result
    const watchedNoteInput = watch("note");

    setCharacterCount(event.target.value.length);

    if (watchedNoteInput.length >= minLength) {
      setFormDisabled(false);
      setButtonText("Save Note");
    } else if (watchedNoteInput.length < minLength) {
      setFormDisabled(true);
      setButtonText("Add note");
    }
  };
  const onSubmit = async (data: any) => {
    console.log("Status Update Data: ", data);
    // exclude uncessary prefix form data
    data.isPublic = true;
    data.timestamp = Timestamp.now();
    data.type = "General";
    setButtonText("Saving...");
    console.log("application inside function", application);
    await createNote(application?.id, collectionNames.applications, data)
      .then(() => {
        setButtonText("Save Complete");
        setFormDisabled(true);
        toggleNotesForm();
        reset();
        setCharacterCount(0);
        setButtonText("Add note");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFetchMoreNotes = () => {
    setDisplayNotesCount(displayNotesCount + 10);
  };
  return (
    <div className="my-5">
      <GreenUnderlineHeading title="Notes">
        <Tooltip
          text={isNotesFormShowing ? "Close Notes Form" : "Open Notes Form"}
        >
          <div className="justify-self-end block">
            <i
              onClick={toggleNotesForm}
              id={`resume-add-note`}
              className="fak cursor-pointer fa-notes-add-white text-instant-teams-teal-Main text-xl pl-4 float-right"
            ></i>
          </div>
        </Tooltip>
      </GreenUnderlineHeading>
      <div className="  rounded-lg p-4">
        {isNotesFormShowing ? (
          <form
            className=" rounded-lg p-4"
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <input
              className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
              name="who"
              type="hidden"
              ref={register()}
            />

            <div className="mb-2">
              <textarea
                className={
                  characterCount < minLength
                    ? "shadow-input-field border-red-500 py-1 px-3 font-bold w-full leading-10 bg-instant-teams-teal-L5 rounded"
                    : "shadow-input-field py-1 px-3 font-bold w-full leading-8 bg-instant-teams-teal-L5 rounded border border-gray-300 outline-none"
                }
                name="note"
                onChange={handleTextareaChange}
                ref={register()}
              />
            </div>

            <div className=" flex justify-around my-5">
              <button
                id="general-note-submit-button"
                className={
                  formDisabled
                    ? "py-2 w-1/2  text-white bg-instant-teams-gray-D1 rounded border-0 focus:outline-none disabled:opacity-50"
                    : `py-2 w-1/2  text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-D1 focus:outline-none`
                }
                type="submit"
                disabled={formDisabled}
              >
                {buttonText}
              </button>
              <p className="text-gray-600 text-xs flex justify-end">
                Character Count: {characterCount}
                {characterCount < minLength && (
                  <span className="text-red-500"> (minimum: {minLength})</span>
                )}
              </p>
            </div>
          </form>
        ) : null}
        <div className="my-5 flex justify-center flex-col p-5 rounded-lg">
          <>
            {fetchedNotes?.length > 0 ? (
              <>
                {fetchedNotes?.slice(0, displayNotesCount)?.map((row: any) => {
                  return <SeekerNoteDisplay entry={row} key={row?.id} />;
                })}
                {displayNotesCount < fetchedNotes?.length ? (
                  <button
                    onClick={handleFetchMoreNotes}
                    disabled={displayNotesCount > fetchedNotes?.length}
                    className="text-sm flex justify-end my-2 text-instant-teams-teal-Main hover:font-semibold hover:text-instant-teams-teal-D1 mx-2"
                  >
                    Fetch more notes
                  </button>
                ) : null}
              </>
            ) : (
              <div className="text-lg text-center text-instant-teams-teal-Main w-full">
                No Notes. Create one now.
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
}
export default ExternalNotesApplicationView;
