import { Transition } from "@headlessui/react";

function FadeInFromTop({
  show,
  children,
}: {
  show: boolean;
  children: JSX.Element;
}) {
  return (
    <Transition
      show={show}
      enter="transition ease-in duration-200"
      enterFrom="opacity-0 -translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-out duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-1"
    >
      {children}
    </Transition>
  );
}

export default FadeInFromTop;
