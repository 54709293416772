import React, { useEffect, useState } from "react";
import { useJobDescriptionFiles } from "../../../data/jobDescriptions";
import useModal from "../../../hooks/useModal";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import PDFViewer  from "../../pdf/pdfViewer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/pro-duotone-svg-icons';

const JobListingFiles = ({ id }: { id: string }) => {
  const { data: files } = useJobDescriptionFiles(id);  
  const [title, setTitle] = useState("");
  const [pdf, setPDF] = useState("");
  const { toggle: viewToggle, isShowing: viewPDF } = useModal();

  function handleClick(file: any) {
    setTitle(file.file.label);
    setPDF(file.file.link);
    viewToggle();
  }

  return (
    <div className="cursor-pointer pt-2 pb-2">
      {files &&
        files.map((file: any, index: number) => (
          <div key={index} 
            className="flex flex-col pl-4 pr-4 rounded-lg bg-white mb-2 shadow-lg" 
            onClick={() => handleClick(file)}
          >
            <div className="flex">
              <div className="flex-shrink-0">
                <button
                  className="h-10 w-full rounded-full text-instant-teams-teal-Main hover:text-instant-teams-green-D2"
                >
                  <FontAwesomeIcon
                    icon={faFile}
                    className="h-5 w-5 pr-2 "
                    swapOpacity
                  />
                  {file?.file?.label}
                </button>
              </div>
            </div>
          </div>
        ))}
      {viewPDF && (
        <DialogWrapper
          title={"Viewing " + title.toUpperCase()}
          size="max-w-4xl"
          onClose={() => {
            viewToggle();
          }}
        >
          {pdf && (
            <PDFViewer file={pdf} label={title} />
          )}
        </DialogWrapper>
      )}
    </div>
  );
};

export default JobListingFiles;
