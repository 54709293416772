import { useEffect, useState } from "react";
import { Chat } from "../../dataTypes/Messages";
import {
  newestMessage,
  useAllMessagesByChatId,
  markChatRead,
} from "../../data/messages";
import { useUser } from "../../data/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { DisplayTime, DisplayTimestamp } from '../../util/timestamps/timestamp';

const MessageTabContents = ({ chat }: { chat: Chat }) => {
  const userId: string =
    localStorage.getItem("__user_id")?.replace(/"/g, "") || "";
  const [lastMessage, setLastMessage] = useState({});
  const [messageIsUnread, setMessageIsUnread] = useState(
    chat?.unreadUsers?.includes(userId),
  );
  const { recipientId, id: chatId } = chat;
  const { data: recipient } = useUser(recipientId);
  const { data: messageData } = useAllMessagesByChatId(chatId);
  const { data: senderData } = useUser(lastMessage?.senderId);
  const { data: userData } = useUser(userId);

  const handleMessageData = async () => {
    const message = await newestMessage(chatId);
    setLastMessage(message?.data);
  };

  const handleClick = () => {
    markChatRead(userId, chatId);
  };

  useEffect(() => {
    setMessageIsUnread(chat?.unreadUsers?.includes(userId));
  }, [chat, userId]);

  useEffect(() => {
    handleMessageData();
  }, [recipientId, chatId, messageData]);

  

  return (
    <div
      className="pb-2 pt-2 flex justify-center items-center"
      onClick={handleClick}
    >
      <div className="w-1/5 flex justify-center ">
        <img
          src={recipient?.photoURL || "/images/default_user.png"}
          alt=""
          className={`rounded-full h-12 w-12 ${
            messageIsUnread ? " border-4 border-green-600" : ""
          }`}
        />
      </div>
      <div className="w-4/5 pr-4 h-1/2">
        <div className="text-sm font-black flex items-center relative">
          {recipient?.name}
          {chat?.group === "oneOnOne" ? (
            <span className="absolute top-0 right-0 ml-4">
              <FontAwesomeIcon
                icon={faUser}
                className="pr-2 pl-1 text-instant-teams-teal-D2 sm:hidden flex"
                swapOpacity
              />
            </span>
          ) : (
            <span className="absolute top-0 right-0 ml-4">
              <FontAwesomeIcon
                icon={faUsers}
                className="pr-2 pl-1 text-instant-teams-teal-D2 sm:hidden lex"
                swapOpacity
              />
            </span>
          )}
        </div>
        <div className="text-xs font-semibold italic text-instant-teams-teal-Main flex items-center">
          {senderData?.id !== userData?.id
            ? "From: " + senderData?.name
            : "From: You"}
        </div>
        <div className="text-xs truncate text-ellipsis ">
          {lastMessage?.body}
        </div>
        <div className='flex-1 text-xxs'>{DisplayTimestamp(lastMessage?.createdAt)}</div>
      </div>
    </div>
  );
};

export default MessageTabContents;
