import Meta from "../../components/custom-ui/meta/Meta";
import { requireAuth } from "../../providers/auth";
import { useParams, useNavigate } from "react-router-dom";
import ToastAlert from "../../components/custom-ui/toast-alert/ToastAlert";
import EmployerInfo from "../../components/employer/details/employer-info";
import { useEmployer } from "../../data/employer";
import { useEffect } from "react";
import NotFoundPage from "../404";
import { useState } from "react";

function EmployerDetailsPage() {
  const params = useParams();
  const navigate = useNavigate();

  const {
    data: employer,
    status: employerStatus,
    error: employerError,
  } = useEmployer(params.employerId ?? "");
  const { displayName } = employer ?? {};
  const [id, setId] = useState(params?.employerId);

  useEffect(() => {
    if (
      window.location.pathname.endsWith(`${id}`) ||
      window.location.pathname.endsWith(`${id}/`)
    )
      navigate(`/employers/${id}/overview`, { replace: true });
  }, [params, navigate]);
  return (
    <>
      <Meta
        title={displayName ? `${displayName} Details` : `Employer Details`}
      />
      {employerStatus !== "loading" && employer && (
        <div className="">
          <EmployerInfo />
        </div>
      )}
      {employer === null && (
        <div className="">
          <NotFoundPage />
        </div>
      )}
      <ToastAlert />
    </>
  );
}
export default requireAuth(EmployerDetailsPage);
