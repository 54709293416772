import { useEffect, useState } from "react";
import { useComponent } from "../../data/academy";
import AutoSaveText from "../custom-ui/input-fields/auto-save-field";

function EditSocialMeta({ share }: { share: any }) {
  return (
    <div className="items-center">
      <AutoSaveText
        value={share.title || ""}
        collection="Shares"
        recId={share.id}
        required={false}
        name="title"
        display="Share Title"
        placeholder={"Title"}
        directions=""
        readOnly={false}
      />
      <AutoSaveText
        value={share.description}
        collection="Shares"
        recId={share.id}
        required={false}
        name="description"
        display="Share Description"
        placeholder={"Description"}
        directions="Limit to 200 characters"
        readOnly={false}
      />
      <AutoSaveText
        value={share.hashtags}
        collection="Shares"
        recId={share.id}
        required={false}
        name="hashtags"
        display="Hashtags for post"
        placeholder={"Hashtags - include #"}
        directions="Hashtags - include #"
        readOnly={false}
      />
      {/* TODO: make sure BPO images don't render extra info we don't require */}
      {share && share.wide?.thumbnailUrl && (
        <img
          src={share.wide.thumbnailUrl}
          alt="Share Image"
          className="w-full h-auto"
        />
      )}
      {share && share.square?.thumbnailUrl && (
        <img
          src={share.square.thumbnailUrl}
          alt="Share Image"
          className="w-1/2 h-auto mx-auto"
        />
      )}
    </div>
  );
}

export default EditSocialMeta;
