import Meta from "../../../components/custom-ui/meta/Meta";
import { requireAuth, useAuth } from "../../../providers/auth";

import { Outlet } from "react-router-dom";
import CompanyNotesListing from "../../../components/employerView/pages/CompanyNotesListing";
import { useState } from "react";
import { useEffect } from "react";
import { collectionNames } from "../../../data/dictionary/collectionNames";

function CompanyNotes() {
  const { employers } = collectionNames;
  const { userClaims } = useAuth();
  const [id, setId] = useState(userClaims?.claims?.employerId);
  useEffect(() => {
    setId(userClaims?.claims?.employerId);
  }, [useAuth]);
  return (
    <div className="pt-4">
      <Meta title="Notes" />

      <div className="pt-36">
        <div className=" md:container pb-32 w-full px-12 min-h-screen">
          <CompanyNotesListing collection={employers} paramId={id} />
        </div>
      </div>
    </div>
  );
}

export default requireAuth(CompanyNotes);
