import { useState, useEffect } from "react";
import { useFindLookup } from "../../../../data/lookup";
import { useAuth } from "../../../../providers/auth";
import { MilitaryAffiliation } from "../../../../dataTypes/User";
import { deleteDoc } from "firebase/firestore";
import { deleteUserAffiliation } from "../../../../data/affiliation";

type AffiliationRecord = {
  id: string;
  branch: string;
  affiliation: string;
};
const AffiliationItem = ({
  affiliation,
  background,
}: {
  affiliation: AffiliationRecord;
  background: string;
}) => {
  const { userClaims, user } = useAuth();
  const [affiliationCode, setAffiliationCode] = useState(
    affiliation.affiliation || "",
  );
  const [branchCode, setBranchCode] = useState(affiliation.branch || "");
  const { data: branchData, error: branchError } = useFindLookup(
    "branch",
    branchCode,
  );
  const { data: affiliationData, error: affiliationError } = useFindLookup(
    "affiliation",
    affiliationCode,
  );

  const colorVariant: any = {
    lightBackground: "text-instant-teams-teal-D1 text-lg",
    darkBackground: "text-white text-sm",
  };

  const handleRemove = async () => {
    await deleteUserAffiliation(user.uid, affiliation.id);
  };

  return (
    <>
      {branchData !== undefined && affiliationData !== undefined ? (
        <div className={`flex w-full ${colorVariant[background]}`}>
          <img
            className="w-6 h-6 rounded-full bg-instant-teams-teal-Main p-1"
            src={
              branchData && branchData?.length > 0 ? branchData[0]?.icon : ""
            }
            alt={
              branchData && branchData?.length > 0 ? branchData[0]?.label : ""
            }
          />
          <div className="flex justify-between w-full">
            <div className="flex align-middle h-full pl-2 mt-1">
              {branchData && branchData?.length > 0 ? branchData[0]?.label : ""}
              {" - "}
              {affiliationData && affiliationData?.length > 0
                ? affiliationData[0]?.label
                : ""}
            </div>
            {/* Only the seeker has the ability to remove Affiliations */}
            {userClaims?.claims?.type === "S" ? (
              <button
                onClick={handleRemove}
                className="text-instant-teams-red-Main hover:underline"
              >
                Remove
              </button>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AffiliationItem;
