import React, { useState ,useEffect} from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../providers/auth";
import { updateAutoSave } from "../../../data/utility";
import { useUser } from "../../../providers/database";
import { collectionNames } from "../../../data/dictionary/collectionNames";
import toast from "react-hot-toast";
import ToastAlert from "../../../components/custom-ui/toast-alert/ToastAlert";


function ProfileSettings({
  closeProfileModal,
 }: {
  closeProfileModal: Function;
 }) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const { data: userData } = useUser(auth?.user?.uid);


  const { register, handleSubmit, errors, reset, getValues,trigger } = useForm();

  useEffect(() => {
    if (userData) {
      reset({
        name: userData.name || '',
        preferredName: userData.preferredName || '',
      });
    }
  }, [userData, reset]);

  const onSubmit = async (data: any) => {
    try {
        
        setPending(true);

        
        await updateAutoSave(collectionNames.users, auth?.user?.uid, {
            name: data?.name,
            preferredName: data?.preferredName // Adjusted to `data.name` instead of `data.data.name`
        });

        // Sign out after successful update
       
    } catch (error) {
        toast.error(`${error}`);
        console.error("Failed to update user data:", error);
    } finally {
        // Hide pending indicator
        closeProfileModal()
        setPending(false);
    }
};


  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)} className="mb-6">

      <div className="flex mb-10">
                    
                    <p className="border-b w-full py-2 font-bold text-xl text-[#15415E]">Edit Personal Information</p>
                </div>

      

                <div className="relative mb-10">
          <input
            type="text"
            name="name"
            placeholder=" "  // Empty placeholder for floating effect
            ref={register({
              required: "Please enter your full name.",
            })}
           // value={userData?.name}
            //onChange={async () => await trigger("name")}
            //onBlur={async () => await trigger("name")}
            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
            style={{ boxShadow: 'none' }}
          />
          <label
            htmlFor="name"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Full Name <span className="text-red-600">*</span>
          </label>
          {errors.name && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.name.message}
            </p>
          )}


        </div>


        <div className="relative mb-10">
          <input
            type="text"
            name="preferredName"
            placeholder=" "  // Empty placeholder for floating effect
            ref={register({
                required: "Please enter your preferredName",
              })}
              //value={userData?.preferredName}
            //onChange={async () => await trigger("preferredName")}
            //onBlur={async () => await trigger("preferredName")} 
            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
            style={{ boxShadow: 'none' }}
          />
          <label
            htmlFor="preferredName"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Preferred Name
          </label>

          {errors.preferredName && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.preferredName.message}
            </p>
          )}

        </div>

      



     
      <div className="flex justify-around space-x-10 mt-10 mb-4 mx-5">

<button
    className="py-2 bg-white border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-[#15415E] w-full h-12 rounded-full"
    onClick={() =>  closeProfileModal()}
>
    Cancel
</button>
<button
    className="py-2 bg-[#15415E] text-white w-full rounded-full h-12 "
    type="submit"
    disabled={pending}
>
    Save
</button>
</div>
    </form>
    < ToastAlert/>
    </>
  
  );
}

export default ProfileSettings;
