import { useComponent } from "../../../data/academy";
import { getLevel } from '../../../util/assessments/level';

function PreviewWebSkillPart({ assessmentId }: { assessmentId: string }) {
  
  const { data: assessmentData } = useComponent(assessmentId);
  return (
    <>
      <div className="items-center">
        <img
          src={assessmentData?.badge}
          alt={assessmentData?.skillName}
          className="object-contain h-32 w-32 md:h-16 md:w-16 mx-auto"
        />
        <div className="w-full text-center text-xs">
          {assessmentData?.skillName || assessmentData?.name}
        </div>
      </div>
    </>
  );
}

export default PreviewWebSkillPart;
