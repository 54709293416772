/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useGlobalState } from "../../../../providers/globalState/GlobalStateProvider";
import { useAuth } from "../../../../providers/auth";
import { Timestamp } from "firebase/firestore";
import { createSyncRequest } from "../../../../data/academy";
import toast from "react-hot-toast";
import ToastAlert from "../../../custom-ui/toast-alert/ToastAlert";
import { splitCapitalize } from "../../../../util/strings/stringManipulation";
import AutoSaveWysiwyg from "../../../custom-ui/input-fields/auto-save-wysiwyg";
import AutoSaveNumber from "../../../custom-ui/input-fields/auto-save-number";
import useModal from "../../../../hooks/useModal";
import AcceleratorBadge from '../../parts/Accelerator/accelerator-logo';
import AutoSaveStatusSelector from "../../../custom-ui/input-fields/statusSelector";
import TagListing from "../../../tags/tagList";
import AutoSaveText from "../../../custom-ui/input-fields/auto-save-field";
import TestResults from "../../parts/testResults";
import AutoSaveSelectByLookup from "../../../custom-ui/input-fields/auto-save-select-by-lookup";
import GreenUnderlineHeading from "../../../custom-ui/headings/heading";
import SkillFamily from "../../../tags/SkillFamily";
import AutoSaveSelectSkillFamily from "../../../custom-ui/input-fields/auto-save-select-skill-family";
import { useAccelerator } from '../../../../data/accelerators';
import { collectionNames } from '../../../../data/dictionary/collectionNames';
import DragAndDropZip from '../../../custom-ui/drag-and-drop/DragAndDropZip';
import folderNames from '../../../../storage/dictionary/folderNames';
import { upload } from '../../../../storage/storage';
import StorageBrowser from '../../../custom-ui/storage/StorageBrowser';

function Accelerator() {
  const params = useParams();
  const { user } = useAuth();
  const { globalState, setGlobalState } = useGlobalState();
  const [name, setName] = useState("Instant Teams Academy");
  const [autoDuration, setAutoDuration] = useState(0);
  const [badge, setBadge] = useState("/images/badge.png");
  const [tags, setTags] = useState([]);
  const [family, setFamily] = useState([]);
  const [file, setFile] = useState(null);

  const { isShowing: showBadgeEditModal, toggle: toggleBadgeEditModal } =
    useModal();

  const editorStyle = {
    height: `150px`,
    overflow: "hidden",
    border: "1px solid #ccc",
    padding: "8px",
  };

  const { data: accel } = useAccelerator(params?.acceleratorId?.toString() || "");

  useEffect(() => {
    setName(accel?.name || "Academy");
  }, [accel?.name]);

  useEffect(() => {
    setGlobalState({ ...globalState, pageTitle: name });
  }, [name]);

  useEffect(() => {
      console.log("ACCEL: ", accel);
    }, [accel]);

  useEffect(() => {
    let duration = 0;
    if (accel?.custom_tests_detail) {
      for (const detail of accel.tests_detail) {
        duration += detail?.test?.duration;
      }
    }
    if (autoDuration === 0) {
      setAutoDuration(Math.ceil(duration / 60));
    }
    if (accel?.badge !== undefined && accel?.badge !== null) {
      setBadge(accel.badge);
    }
    if (accel?.tags !== undefined && accel?.tags !== null) {
      setTags(accel?.tags);
    }
    if (
      accel?.skillFamily !== undefined &&
      accel?.skillFamily !== null
    ) {
      setFamily(accel?.skillFamily);
    }
  }, [accel]);

  const confirmUpload = () => {
    upload(file, `accelerator/${params?.acceleratorId?.toString() || "sample"}/files`, `${params?.acceleratorId?.toString()}_${new Date().getTime()}.zip`)
    setFile(null);
    // window.location.reload();
  };

  return (
    <div>
      <div className="flex justify-between w-full">
        <div className="w-1/2 items-center">
          <h1 className="text-2xl font-bold">{name}</h1>
        </div>
      </div>
      <div className="w-full mt-5 flex">
        <div className="w-1/3 mt-10">
          <AutoSaveNumber
            value={
              accel?.duration !== undefined
                ? Number(accel.duration)
                : ""
            }
            collection={collectionNames.accelerators}
            recId={params?.acceleratorId?.toString() || ""}
            required={false}
            name={"duration"}
            display={"Accelerator Duration"}
            placeholder={"How long does this course take?"}
            directions={`EST: ${autoDuration} minutes`}
            readOnly={false}
            minValue={0}
            maxValue={1440}
          />
          <AutoSaveText
            value={accel?.displayName}
            collection={collectionNames.accelerators}
            recId={params?.acceleratorId?.toString() || ""}
            required={false}
            name="displayName"
            display="Display Name"
            placeholder={"Display Name"}
            directions=""
            readOnly={false}
            maxlength={40}
          />
          <AutoSaveSelectSkillFamily
            value={accel?.skillFamily}
            collection={collectionNames.accelerators}
            recId={params?.acceleratorId?.toString() || ""}
            required={false}
            name="skillFamily"
            display="Skill Family"
            placeholder="Skill Family"
            directions=""
            readOnly={false}
          />
        </div>
        <div className="w-1/3 pr-5 pl-8">
          <div className="flex justify-between w-full">
            <div className="w-full items-center text-center text-instant-teams-teal-Main font-bold pr-10 ">
              <GreenUnderlineHeading title="Badge" />
              <img
                src={badge}
                alt={name}
                className="mx-auto max-w-50 h-auto cursor-pointer w-1/2"
                onClick={() => toggleBadgeEditModal()}
              />
              {showBadgeEditModal ? (
                <AcceleratorBadge toggleModal={toggleBadgeEditModal} />
              ) : null}
            </div>
          </div>
        </div>
        <div className="w-1/3 pr-5 pl-8">
          <GreenUnderlineHeading title="Scorm Files" />
          <div className="text-center mb-2">
            <StorageBrowser bucket={`accelerator/${params?.acceleratorId?.toString() || "sample"}/files`}/>
            <DragAndDropZip setFile={setFile} />
            <div className="flex justify-center">
              {file && (
                <div className='w-full'>
                  <p className='w-full text-sm mt-2'>{file.name}</p>
                  <button
                    className="mt-4 py-2 px-4 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                    onClick={confirmUpload}
                  >
                    Upload File
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-5 flex">
        <div className="w-full mr-20">
          <div className="flex justify-between w-full">
            <TagListing
              currentTags={tags}
              collection={collectionNames.accelerators}
              recId={params?.acceleratorId?.toString() || ""}
              name={"tags"}
            />
          </div>
          <div className="grid grid-cols-2 gap-8">
            <AutoSaveWysiwyg
              value={accel?.shortDescription}
              collection={collectionNames.accelerators}
              recId={params?.acceleratorId?.toString() || ""}
              required={false}
              rows={10}
              name={"shortDescription"}
              display={"Accelerator Short Description"}
              placeholder={"Add a Description"}
              directions={"Short description of the Accelerator"}
              readOnly={false}
              maxLength={250}
            />
            <AutoSaveWysiwyg
              value={accel?.description || ""}
              collection={collectionNames.accelerators}
              recId={params?.acceleratorId?.toString() || ""}
              required={false}
              rows={10}
              name={"description"}
              display={"Accelerator Description"}
              placeholder={"Add a Description"}
              directions={"Full description of the Accelerator"}
              readOnly={false}
              maxLength={750}
            />
          </div>
        </div>
      </div>
      <ToastAlert />
    </div>
  );
}

export default Accelerator;
