import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../providers/auth";
import AuthCode from "react-auth-code-input";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { sendVerificationCode, verifyCode } from "../../../../../callable-cloud-functions/cloudFunctions";
import { friendlyFirebaseError } from "../../../../../providers/firebase-errors/friendlyFirebaseError";
import toast from "react-hot-toast";
import ToastAlert from "../../../../../components/custom-ui/toast-alert/ToastAlert";
import "./style.css";
import InfoIcon from "../../../../dashboard/AdminMetricDashboard/InfoTextWithIcon";

function ForgotPasswordComponent() {

  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState("");
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
    trigger,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
      verificationCode: "",
    },
  });

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white">
      <div className="mt-[-20%] ml">
        {/* <p className="text-[25px]  text-gray-500 font-light items-center  ">{ "<"}<span className="text-xl ml-1 mb-2 items-center">Back</span></p> */}
      </div>


      <div className="w-full mt-[25%] text-center mb-16">
        <h2 className="text-2xl font-semibold text-[#15415E] mb-6">Forgot Password?</h2>

        <p className="text-xl text-center text-[#A5BDC5]">

          Please enter your email to reset your password
        </p>
      </div>
      {emailSent === "failedToSent" && <div className=" py-2">
        <p className="text-red-500 mb-3 items-center text-sm">
          Having problem to send reset password link. Please confirm your email address
        </p>
      </div>}
      {emailSent === "sent" && <div className=" w-full justify-center items-center py-2">
        <p className="text-green-600 mb-3  w-full justify-center items-center text-lg">
          Reset password link has been sent to you mail address.
        </p>
      </div>}
      <form
        className="space-y-6"
        onSubmit={handleSubmit((data) => {

          const email = data.email
          // resetPassword({ email, userAgent: window.navigator.userAgent })
          auth.sendPasswordResetEmail(email).then((result: any) => {
            setPending(false);
            setEmailSent("sent")
            window.location.reload();

          })
            .catch((err: any) => {
              if (err.code === "auth/user-not-found") {
                setPending(false);
                setEmailSent("failedToSent")
              }
            });
        })}
      >
        <div className="relative mb-7">
          <input
            type="email"
            name="email"
            placeholder=" "
            ref={register({
              required: "Please enter an email",
            })}
            onChange={async (e) => {
              setEmail(e.target.value);
              await trigger("email");
            }}
            style={{ boxShadow: 'none' }}
            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
          />
          <label
            htmlFor="email"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Email <span className="text-red-600">*</span>
          </label>
          {errors.email && <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>}
        </div>

        <div className="flex justify-center">
          {!emailSent && <button
            type="submit"
            className={`py-2 px-8 text-white font-medium rounded-full w-66 h-12 ${isSubmitting ? "bg-gray-400" : "bg-[#15415E]"
              }`}
            disabled={pending}
          >
            {pending ? "Processing..." : "Continue"}
          </button>}
        </div>
      </form>


      <ToastAlert />
    </div>
  );
}

export default ForgotPasswordComponent;