import Meta from "../../components/custom-ui/meta/Meta";
import EmailVerification from "../../components/seeker/details/onboarding/EmailVerification";
import { requireAuth } from "../../providers/auth";
function EmailVerificationPage() {
  return (
    <>
      <Meta title="Email Verification" />
      <EmailVerification />
    </>
  );
}

export default requireAuth(EmailVerificationPage);
