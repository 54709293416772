import { useState } from "react";
import { useParams } from "react-router";
import GeneralNote from "../../custom-ui/input-fields/modals/GeneralNote";
import ReqSkillItemBasic from "../parts/ReqSkillItemBasic";
import SeekerFiles from "../../seeker/details/parts/SeekerFiles";
import SeekerNotesDisplay from "../../seeker/details/parts/seekerNotes";
import Tooltip from "../../custom-ui/tooltip/tooltip";
import useModal from "../../../hooks/useModal";
import {
  collectionNames,
  subCollectionNames,
} from "../../../data/dictionary/collectionNames";
import Meta from "../../custom-ui/meta/Meta";
import {
  useApplication,
  useApplicationPositions,
  addApplicationPosition,
} from "../../../data/applications";
import { useMeetingsByApplicationId } from "../../../data/meetings";
import { useActiveJobPositions } from "../../../data/positions";
import ApplicationPositionItem from "../../application/ApplicationPositionItem";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import MeetingList from "../../live/MeetingList";
import MessagingCenterLite from "../../messages/MessagingCenterLite";
import { useAuth } from "../../../providers/auth";
import { createNotification } from "../../../data/notifications";

const JobApplicantDetails = () => {
  /**
   * Get Application data
   */
  const params = useParams();
  const { applicationId = "" } = params ?? {};
  const { data: jobApplicationData, status: jobApplicationDataStatus } =
    useApplication(applicationId);

  /**
   * Authenticated user data
   */
  const { user } = useAuth();
  const { id: userId } = user ?? {};

  const { jobId, resumeId } = jobApplicationData ?? {};

  const { requiredSkills } = jobApplicationData ?? {};
  const { data: positionData = [], status: positionDataQueryStatus } =
    useApplicationPositions(applicationId);

  // List of active positions available (filter out positions currently applied to )
  const { data: activeJobPositions = [] } = useActiveJobPositions(jobId);
  const availableJobPositions = activeJobPositions.filter(
    (availablePosition: any) =>
      !positionData.some(
        (existingPosition: any) => existingPosition.id === availablePosition.id,
      ),
  );

  // Application meeting list
  const { data: meetings = [] } = useMeetingsByApplicationId(applicationId);
  // Filter meeting list
  const scheduledMeetings = meetings.filter(
    (meeting: any) => meeting.status === "scheduled",
  );

  const rescheduleRequest = meetings.filter(
    (meeting: any) => meeting.status === "reschedule",
  );

  const awaitingResponse = meetings.filter(
    (meeting: any) => meeting.status === "sent" || meeting.status === "pending",
  );

  const complete = meetings.filter(
    (meeting: any) => meeting.status === "complete",
  );

  const cancelledAndExpired = meetings.filter(
    (meeting: any) =>
      meeting.status === "cancelled" || meeting.status === "expired",
  );

  const sortedMeetings = [
    ...rescheduleRequest,
    ...scheduledMeetings,
    ...awaitingResponse,
    ...complete,
    ...cancelledAndExpired,
  ];

  const { isShowing: showPositionModal, toggle: togglePositionModal } =
    useModal();
  const { isShowing: showSkillModal, toggle: toggleSkillModal } = useModal();

  const [selectedPosition, setSelectedPosition] = useState();

  const { isShowing: isShowingNote, toggle: toggleNote } = useModal();
  const handleDone = () => {
    toggleNote();
    if (jobApplicationData?.TAC) {
      createNotification({
        title: "New Note On Application",
        body: "A new note has been added to an application",
        createdBy: "system",
        isPushNotification: true,
        url: `applications/${applicationId}/details`,
        group: "personal",
        recipientId: jobApplicationData?.TAC,
      });
    }
  };

  const assignApplicationPosition = (
    applicationId: string,
    positionId: string,
  ) => {
    addApplicationPosition(applicationId, positionId)
      .then(() => {
        togglePositionModal();
      })
      .catch((error) => {});
  };

  return (
    <div className="mb-36">
      <Meta title="Job Applicant Details" />
      <div className="grid grid-cols-2 gap-4">

     
        {jobApplicationDataStatus === "success" && (
          <>
            <div>
              <GreenUnderlineHeading title="Meetings" />
              <MeetingList meetings={sortedMeetings} />
              <Tooltip text="Add note" position="-top-6 -right-16">
                {/* having issues adding GreenUnderlineHeading here. The notes icon moves under instead of being on the same line */}
                <div className="uppercase  text-xl font-bold text-instant-teams-teal-D1 border-b-2 border-instant-teams-green-D2 min-w-full mt-6">
                  Notes
                  <i
                    onClick={toggleNote}
                    className="fak cursor-pointer fa-notes-add-white text-instant-teams-teal-Main text-xl pl-4 float-right"
                  ></i>
                </div>
              </Tooltip>
              <SeekerNotesDisplay
                id={applicationId}
                collection={collectionNames.applications}
                subCollection={subCollectionNames.notes}
              />
              {isShowingNote && (
                <GeneralNote
                  onDone={handleDone}
                  collection={collectionNames.applications}
                  id={applicationId}
                />
              )}

              <GreenUnderlineHeading title="Resume" />
              <> {resumeId && <SeekerFiles id={resumeId} /> }</>

            </div>



           <div>
              {positionDataQueryStatus === "success" && (
                <div className="w-full">
                  <Tooltip text="Add Position" position="-top-6 -right-24">
                    <div className="uppercase text-xl font-bold text-instant-teams-teal-D1 border-b-2 border-instant-teams-green-D2 min-w-full mt-6">
                      Positions
                      <i
                        onClick={togglePositionModal}
                        className="cursor-pointer fa-solid fa-circle-plus text-instant-teams-teal-Main text-xl pl-4 float-right"
                      ></i>
                    </div>
                  </Tooltip>

                  
                  {positionData.map((position: any) => (
                    <ApplicationPositionItem
                      key={position.id}
                      jobId={jobId}
                      applicationId={applicationId}
                      positionApplication={position}
                      applicationData={jobApplicationData}
                    />
                  ))}
                  {showPositionModal && (
                    <DialogWrapper
                      onClose={togglePositionModal}
                      title={""}
                      size=" w-1/2 mt-36"
                    >
                      <div className="h-36">
                        <div>
                          <select
                            className="mb-4 form-input shadow-md rounded-md w-full pt-3"
                            onChange={(e) => {
                              const selection = e.target.value;
                              setSelectedPosition(e.target.value);
                            }}
                          >
                            <option disabled selected>
                              Select Position
                            </option>
                            {availableJobPositions.map((item: any) => (
                              <option key={item.id} value={item.id}>
                                {item.positionName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="flex space-x-4">
                          <button
                            className="w-full min-h-12 h-12  text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                            type="button"
                            onClick={() => {
                              assignApplicationPosition(
                                applicationId,
                                selectedPosition,
                              );
                            }}
                          >
                            Assign Position
                          </button>
                          <button
                            className="w-full min-h-12 h-12  text-white bg-instant-teams-gray-D1 rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                            type="button"
                            onClick={togglePositionModal}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </DialogWrapper>
                  )}
                  {showSkillModal && (
                    <DialogWrapper
                      onClose={toggleSkillModal}
                      title={""}
                      size=" w-1/2  mt-36"
                    >
                      <div className="h-2/3">
                        <div className="absolute top-0 left-0 w-full bg-instant-teams-teal-Main">
                          <h1 className="uppercase p-4 text-white">
                            Assessment Details
                          </h1>
                        </div>
                        <div className="grid grid-cols-4 gap-2">
                          {requiredSkills &&
                            requiredSkills.map((skill: any) => (
                              <div onClick={toggleSkillModal}>
                                <ReqSkillItemBasic
                                  assessment={skill}
                                  userId={jobApplicationData.resumeId}
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    </DialogWrapper>
                  )}
                </div>
              )}
              <MessagingCenterLite recipientId={resumeId} />
          </div>

             {/* <div className="mt-36">
              <GreenUnderlineHeading title="Resume" />
              <> {resumeId && <SeekerFiles id={resumeId} /> }</>
            </div> */}

             
          </>

          
        )}
      </div>
    </div>
  );
};

export default JobApplicantDetails;
