import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useJobDescription } from "../../../data/jobDescriptions";
import { useEmployer } from "../../../data/employer";
import { getJobOffer } from "../../../data/offers";

function OfferSidebarHeader() {
  const params = useParams();
  const { offerId = "" } = params ?? {};
  const [offer, setOffer] = useState({});
  const [jobId, setJobId] = useState("");

  const [logo, setLogo] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [title, setTitle] = useState("");

  const { data: employer } = useEmployer(offer?.employerId);

  const { data: jobData } = useJobDescription(jobId);

  useEffect(() => {
    async function fetchData() {
      let res = await getJobOffer(offerId || "");
      setOffer(res);
    }
    if (offerId) {
      fetchData();
    }
  }, [offerId]);

  useEffect(() => {
    if (jobData) {
      setTitle(jobData?.title);
    }
  }, [jobData]);

  useEffect(() => {
    if (employer) {
      setLogo(employer?.logo);
      setDisplayName(employer?.displayName);
    }
  }, [employer]);

  useEffect(() => {
    if (offer) {
      setJobId(offer?.jobId);
    }
  }, [offer]);

  return (
    <div className="">
      <div className="items-center pt-4 pr-3 pb-3">
        <img
          src={logo}
          alt={displayName}
          className="mx-auto  max-w-75 h-auto"
        />
        <div className="text-center text-xl font-bold pb-1 pt-3">
          {displayName}
        </div>
        <div className="text-center text-xl font-bold pb-5">{title}</div>
      </div>
    </div>
  );
}

export default OfferSidebarHeader;
