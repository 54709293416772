import ApplicationItem from "../../application/ApplicationItem";
import {
  SearchBox,
  Hits,
  Configure,
  HitsPerPage,
  Pagination,
  RefinementList,
  CurrentRefinements,
  ClearRefinements,
  RefinementListProps,
  // @ts-ignore
} from "react-instantsearch-hooks-web";
import { getLabel } from "../../../util/search/getLabel";
import { useState,useEffect } from "react";
import { useAuth } from "../../../providers/auth";
import SelectorByRole from "../../custom-ui/input-fields/selectors/selectorByRole";

const transformApplicationStatus: RefinementListProps["transformItems"] = (
  items: any,
) => {
  items = items?.map((item: any) => ({
    ...item,
    label: getLabel(item?.label, "applicationStatus"),
  }));
  return items;
};

const transformProgress: RefinementListProps["transformItems"] = (
  items: any,
) => {
  items = items?.map((item: any) => ({
    ...item,
    label: item?.value + "% Complete",
  }));
  return items;
};

function TacJobApplicants() {
  const { userClaims, user } = useAuth();

  const [tacId, setTacId] = useState(undefined);
  
  return (
    <div className="flex">
      <div className="w-1/5 pr-4">
        <div className=" mt-[23%]">
          <SearchBox
            placeholder="Keyword Search"
            classNames={{
              input: "w-full",
              submitIcon: "hidden",
              resetIcon: "hidden",
              submit: "hidden",
            }}
          />
          <CurrentRefinements />
          Filter by Status
          <RefinementList
            attribute="status"
            operator="or"
            classNames={{
              root: "capitalize",
              showMore: " text-instant-teams-blue-Main text-xs",
            }}
            limit={5}
            showMore={true}
            transformItems={transformApplicationStatus}
          />
          Filter by Progress
          <RefinementList
            attribute="overallProgress"
            operator="or"
            classNames={{
              root: "capitalize",
              showMore: " text-instant-teams-blue-Main text-xs",
            }}
            limit={5}
            showMore={true}
            transformItems={transformProgress}
          />
          Filter by Position Title
          <RefinementList
            attribute="jobPositionName"
            operator="or"
            classNames={{
              root: "capitalize",
              showMore: " text-instant-teams-blue-Main text-xs",
            }}
            limit={5}
            showMore={true}
          />
          <ClearRefinements
            translations={{ reset: "Clear Filters" }}
            classNames={{
              button:
                " bg-instant-teams-teal-Main text-white p-2 rounded mt-5 text-center w-full",
            }}
          />
        </div>
      </div>
      <div className=" w-4/5">
        <div className="flex justify-end w-[100%]">
          <div className="flex items-center">
            {userClaims?.claims?.role === "sadmin" ? (
              <>
                <div className="mx-5">
                  <SelectorByRole
                    value={tacId}
                    setValue={setTacId}
                    role="tac"
                    name="TAC"
                    display="Talent Acquisition Coordinator"
                    placeholder="Talent Acquisition Coordinator"
                    directions=""
                  />
                </div>
              </>
            ) : null}
            <div className=" pr-4">Results per page:</div>
            <HitsPerPage
              items={[
                { label: "10", value: 10, default: true },
                { label: "25", value: 25 },
                { label: "50", value: 50 },
                { label: "100", value: 100 },
              ]}
            />
          </div>
        </div>
        <Configure
          // @ts-ignore
          filters={
            userClaims?.claims?.role === "sadmin"
              ? `TAC:${!!tacId ? tacId : "NA"} AND NOT status:offered`
              : `TAC:${user.uid} AND NOT status:offered`
          }
        />

        <Hits
          hitComponent={({ hit }: { hit: any }) => (
          
            <ApplicationItem application={hit} showJobDescription={false} />
          )}
          classNames={{ list: " mt-4" }}
        />
        <div className="mt-12 w-full flex justify-center">
          <Pagination showNext={true} padding={1} showLast={true} />
        </div>
      </div>
    </div>
  );
}
export default TacJobApplicants;
