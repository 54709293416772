// src/StorageBrowser.js
import React, { useState, useEffect } from "react";
import { firebaseApp } from "../../../providers/firebase";
import { getStorage, ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
const storage = getStorage(firebaseApp);

const StorageBrowser = (bucket) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const storageRef = ref(storage, bucket.bucket.toString());
    listAll(storageRef)
      .then((res) => {
        const fileListing = [];
        console.log(res);
        res.items.forEach((itemRef) => {
          const fileRec = {
            "name": itemRef.name,
            "fullPath": itemRef.fullPath,
          };
          fileListing.push(fileRec);
        });
        setFiles(fileListing);
      })
      .catch((error) => {
        console.error('Error fetching files from Firebase Storage:', error);
      });
  }, []);

  return (
    <div className='w-full text-left mb-2'>
      <ul >
        {files.map((file, index) => (
          <li key={index}>
            {file.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StorageBrowser;
