import { createAssessmentEmail } from "../../data/enrollments";
import { useSetting } from "../../data/settings";
import { useAuth } from "../../providers/auth";
import { debugLogger } from "../../providers/clientLogger";
import "../../styles/dangerouslySet.css";
import { sanitizeMarkup } from "../../util/sanitize/sanitize-html";
import ToastAlert from "../custom-ui/toast-alert/ToastAlert";
import { toast } from "react-hot-toast";
import { errorLogger } from "../../providers/clientLogger";
import { Link } from "react-router-dom";
// public/images/lightbulb.png
function AssessmentInstructions({
  invitationLink,
}: {
  invitationLink: string;
}) {
  const { user } = useAuth();
  const { data: instructioData } = useSetting("assessment_instructions");
  const sendEmail = async () => {
    try {
      await createAssessmentEmail(
        instructioData.value,
        invitationLink,
        user.name,
        user.email,
      );
      toast.success("Link Emailed");
    } catch (error: any) {
      errorLogger(error.toString(), {
        component: "AssessmentInstructions sendEmail()",
        flow: "Send Assessment Email to Seeker",
        userId: user.uid,
      });
      toast.error("Something Went Wrong");
    }
  };
  return (
    <>
      <div className="flex justify-center my-5  ">
        <img
          className=" object-contain h-20 w-20"
          src={"/images/lightbulb.png"}
          alt="lightbulb"
        />
        <h2 className="pl-5 w-64 my-auto text-xl font-bold ">
          Helpful Tips and Tricks!
        </h2>
      </div>
      <div
        className="dangerously my-5"
        dangerouslySetInnerHTML={sanitizeMarkup(instructioData?.value)}
      ></div>
      <div className="flex justify-around ">
        <button
          onClick={sendEmail}
          className="w-1/3 py-3 uppercase bg-instant-teams-blue-Main hover:bg-instant-teams-blue-D1 text-white rounded-lg"
        >
          Email Assessment Link
        </button>
        <a
          href={invitationLink}
          target="_blank"
          rel="noreferrer"
          className=" w-1/3 py-3 uppercase bg-instant-teams-green-Main hover:bg-instant-teams-green-D2 text-white rounded-lg"
        >
          <button className=" w-full uppercase text-white rounded-lg">
            Start Assessment
          </button>
        </a>
      </div>
      <ToastAlert />
    </>
  );
}
export default AssessmentInstructions;
