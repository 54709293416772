/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useQuery,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  doc,
  getDocs,
  collection,
  query,
  where,
  orderBy,
  addDoc,
} from "firebase/firestore";
import { db, createQuery } from "../providers/database";
import { collectionNames } from "./dictionary/collectionNames";

// Temporary List of all Components
export function useTags(type: string) {
  return useQuery(
    ["tags", type],
    createQuery(() =>
      query(
        collection(db, collectionNames.tags),
        where("type", "==", type),
        orderBy("tag"),
      ),
    ),
    { enabled: !!type },
  );
}

export function createTag(data: { type: string; tag: string }) {
  // add employer status "lead"
  return addDoc(collection(db, collectionNames.tags), {
    ...data,
  });
}

export async function checkIfTagExistsForCollection(data: {
  type: string;
  tag: string;
}): Promise<boolean> {
  let ret: boolean = false;
  const newTagVal = data?.tag;
  const tagsSnapshot = await getDocs(
    query(
      collection(db, collectionNames.tags),
      where("type", "==", data?.type),
    ),
  );

  tagsSnapshot.forEach((doc) => {
    const existingTagVal = doc.data()?.tag;
    if (existingTagVal === newTagVal) {
      ret = true;
    }
  });
  return ret;
}
