import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";

type Alert = {
  message: string;
  type: string;
  code?: string;
};

function FormAlert({
  alert,
  children,
}: {
  alert: Alert;
  children?: React.ReactNode;
}) {
  return (
    <>
      {renderAlertType(alert)}
      {children}
    </>
  );
}

function renderAlertType(alert: Alert, children?: React.ReactNode) {
  switch (alert.type) {
    case "error":
      return <AlertError alert={alert} />;
    case "success":
      return <AlertSuccess alert={alert} />;
    case "info":
      return <AlertInfo alert={alert} />;
    case "warn":
      return <AlertWarn alert={alert} />;
  }
}

function AlertError({
  alert,
  children,
}: {
  alert: Alert;
  children?: React.ReactNode;
}) {
  return (
    <div
      className="rounded bg-red-50 rounded-b text-instant-teams-red-Main px-4 py-3"
      role="alert"
    >
      <div className="flex">
        <div className="py-1 px-1">
          <FontAwesomeIcon
            className="text-instant-teams-red-Main"
            //style={faDuotoneStyles}
            icon={faCircleExclamation}
            size="xl"
          />
        </div>
        <div>
          <p className="font-bold">{alert.message}</p>
        </div>
      </div>
      {children}
    </div>
  );
}

function AlertSuccess({ alert }: { alert: Alert }) {
  return (
    <div
      className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-instant-teams-teal-Main px-4 py-3 shadow-md"
      role="alert"
    >
      <div className="flex">
        <div className="py-1 px-1">
          <FontAwesomeIcon
            className="text-instant-teams-red-Main"
            //style={faDuotoneStyles}
            icon={faCircleExclamation}
            size="xl"
          />
        </div>
        <div>
          <p className="font-bold">{alert.message}</p>
        </div>
      </div>
    </div>
  );
}

function AlertInfo({ alert }: { alert: Alert }) {
  return (
    <div
      className="bg-instant-teams-gray-Main border-t-4 border-instant-teams-blue-Main rounded text-instant-teams-teal-Main px-4 py-3 shadow-md"
      role="alert"
    >
      <div className="flex items-center">
        <div className="py-1 px-2">
          <FontAwesomeIcon
            className="text-instant-teams-blue-Main"
            //style={faDuotoneStyles}
            icon={faCircleInfo}
            size="xl"
          />
        </div>
        <div>
          <p className="font-bold">{alert.message}</p>
        </div>
      </div>
    </div>
  );
}

function AlertWarn({ alert }: { alert: Alert }) {
  return (
    <div
      className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-instant-teams-teal-Main px-4 py-3 shadow-md"
      role="alert"
    >
      <div className="flex">
        <div className="py-1 px-1">
          <FontAwesomeIcon
            className="text-instant-teams-red-Main"
            //style={faDuotoneStyles}
            icon={faCircleExclamation}
            size="xl"
          />
        </div>
        <div>
          <p className="font-bold">{alert.message}</p>
        </div>
      </div>
    </div>
  );
}

export default FormAlert;
