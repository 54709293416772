import React, { useEffect, useState } from "react";
import { camel } from "../../../util/strings/stringManipulation";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";

function KnockoutTestDetails(details) {
  return (
    <div className="w-full mt-5 mb-10">
      <GreenUnderlineHeading title="Knockout details" />
      {details?.knockout &&
        details?.knockout.map((item, index) => {
          return (
            <div
              className={index > 0 ? "w-full border-t border-black" : "w-full"}
              key={index}
            >
              <div className="w-full flex">
                <span className="float-left w-1/2">Type: </span>
                <span className="float-right w-1/2">{camel(item.name)}</span>
              </div>
              <div className="w-full flex">
                <span className="float-left w-1/2">Algorithm: </span>
                <span className="float-right w-1/2">
                  {camel(item.algorithm)}
                </span>
              </div>
              <div className="w-full flex">
                <span className="float-left w-1/2">Status: </span>
                <span className="float-right w-1/2">{camel(item.status)}</span>
              </div>
              <div className="w-full flex">
                <span className="float-left w-1/2">Duration: </span>
                <span className="float-right w-1/2">
                  {item.duration + " minutes"}
                </span>
              </div>
              <div className="w-full flex">
                <span className="float-left w-1/2">Premium: </span>
                <span className="float-right w-1/2">
                  {item?.is_premium === false ? "No" : "Yes"}
                </span>
              </div>
              <div className="w-full flex">
                <span className="float-left w-1/2">Private: </span>
                <span className="float-right w-1/2">
                  {item?.is_private_test === false ? "No" : "Yes"}
                </span>
              </div>
              <div className="w-full flex">
                <span className="float-left w-1/2">Question Count: </span>
                <span className="float-right w-1/2">
                  {item?.num_preview_questions}
                </span>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default KnockoutTestDetails;
