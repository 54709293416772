import { useState } from "react";
import { useAuth, requireAuth } from "../../providers/auth";
import SuccessCircleCheck from "../../components/custom-ui/success/SuccessCircleCheck";
import ReauthModal from "../../components/auth/ReauthModal";
import Meta from "../../components/custom-ui/meta/Meta";
import SettingsMFA from "../../components/auth/multi-factor/SettingsMFA";
import HeroImageWrapper from "../../components/layout/HeroImageWrapper";
import { useEmployer } from "../../data/employer";
import { useNavigate } from "react-router-dom";

/**  
  If an internal user is here then that means they are not enrolled in MFA
  and encounter this page after saml login

  If an employer user is here then they are in the process of completing user registration and encounter this
  page during the registration process OR they unenrolled an MFA number and logged out before enrolling a new number
  and encounter this page after email/password login

  Company status and employerId drive the success message displayed and the navigation after success
*/

function MultifactorEnrollmentPage() {
  const auth = useAuth();
  const { user } = auth ?? {};
  const employerId = auth?.userClaims?.claims?.employerId;
  const [formAlert, setFormAlert] = useState(null);
  const navigate = useNavigate();

  // query the user's employer to get employer status and determine where to navigate on success
  const {
    data: employer,
    status: employerQueryStatus,
    error: employerQueryError,
  } = useEmployer(employerId);

  const { status: employerStatus } = employer ?? {};

  // State to control whether we show a re-authentication flow
  // Required by some security sensitive actions, such as changing password.
  const [reauthState, setReauthState] = useState({
    show: false,
  });
  // Handle status of type "success", "error", or "requires-recent-login"
  // We don't treat "requires-recent-login" as an error as we handle it
  // gracefully by taking the user through a re-authentication flow.
  interface HandleAccountStatus {
    type: string;
    code: string;
    message: string;
    callback: () => {};
  }

  const handleStatus = ({
    type,
    code,
    message,
    callback,
  }: HandleAccountStatus) => {
    if (code === "auth/requires-recent-login") {
      // First clear any existing message
      setFormAlert(null);
      // Then update state to show re-authentication modal
      setReauthState({
        show: true,
        // Failed action to try again after reauth
        callback: callback,
      });
    } else {
      // Display message to user (type is success or error)
      setFormAlert({
        type: type,
        message: message,
      });
    }
  };

  const navigateTo = () => {
    if (employerStatus === "invited") {
      navigate("/onboarding");
    } else if (
      employerStatus === "active" ||
      employerStatus === "under_review"
    ) {
      navigate("/dashboard");
    } else if (employerId === "000") {
      // This is an internal user as determined by employerId
      navigate("/dashboard");
    }
  };

  const determineSuccessMessage = () => {
    if (employerStatus === "invited") {
      return "Thank you for completing the Customer Registration process! Please check your email for verification and follow the steps provided to finish setting up your account. Please move forward and complete your Onboarding process.";
    } else {
      return "Please check your email for verification and follow the steps provided to finish setting up your account. Thank you for enrolling in our multifactor authentication process. Your efforts to increase the security of your account are greatly appreciated.";
    }
  };

  return (
    <div className="pt-36">
      <Meta title="Multifactor Enrollment" />
      {user && (
        <HeroImageWrapper>
          <>
            {reauthState.show && (
              <ReauthModal
                callback={reauthState.callback}
                provider={auth.user.providers[0]}
                onDone={() => setReauthState({ show: false })}
              />
            )}
            <section className="py-12 px-4">
              <div className="container mx-auto max-w-sm text-center">
                {formAlert && formAlert.type === "error" && (
                  <div
                    className={
                      "mb-4" +
                      (formAlert.type === "error" ? " text-red-600" : "")
                    }
                  >
                    {formAlert.message}
                  </div>
                  // success message is rendered below using SuccessCircleCheck
                )}
                <h1 className="header-title text-instant-teams-teal-Main mb-3 text-3xl font-medium">
                  Multifactor Enrollment
                </h1>
                <div className="h-96 flex flex-col items-center">
                  <SettingsMFA
                    onStatus={handleStatus}
                    buttonVariant="arrowButton"
                    showChangeNumberButton={false}
                  />

                  {formAlert && formAlert.type === "success" && (
                    <div className="h-full flex items-center">
                      <div>
                        <SuccessCircleCheck
                          action={navigateTo}
                          message={determineSuccessMessage()}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </>
        </HeroImageWrapper>
      )}
    </div>
  );
}
export default requireAuth(MultifactorEnrollmentPage);
