import { useState } from "react";

interface CopyToClipboardButtonProps extends React.ComponentProps<"button"> {
  text?: string;
}

function CopyToClipboardButton(props: CopyToClipboardButtonProps) {
  const [isCopied, setIsCopied] = useState(false);

  function delay(time: number, val: boolean) {
    return new Promise((resolve) => setTimeout(resolve, time, val));
  }
  // omit pending from the button props;
  const { text = "", ...buttonProps } = props;
  return (
    <>
      <button
        {...buttonProps}
        type="button"
        onClick={() => {
          navigator.clipboard.writeText(text);
          setIsCopied(true);
          delay(3000, false).then(() => setIsCopied(false));
        }}
      >
        {isCopied ? (
          <>
            <i className="fa-solid fa-square-check fa-lg text-white"></i>{" "}
            <span className="text-white font-bold ml-2">
              video link copied!
            </span>
          </>
        ) : (
          <i className="fa-regular fa-copy fa-lg text-white"></i>
        )}
        <span className="sr-only">Copy Meeting Video Link</span>
      </button>
    </>
  );
}

export default CopyToClipboardButton;
