import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { Menu, Transition } from "@headlessui/react";
import {
  useAllUnreadNotifications,
  updateAllIsRead,
} from "../../data/notifications";
import Notification from "./notification";
import { Link } from "react-router-dom";
import { useAuth } from "../../providers/auth";

const NotificationList = ({ userId }) => {
  const isRead = false;
  const user = useAuth();
  const employerId = user?.claims?.employerId;

  const { data: unreadNotificationList } = useAllUnreadNotifications(
    userId,
    isRead,
    employerId,
  );

  const handleMarkAllRead = () => {
    updateAllIsRead(userId);
  };

  return (
    <Menu>
      <Menu.Button className="flex relative h-full w-12 mt-2 mr-4 pb-2 hover:pb-1 hover:border-b-4 hover:border-green-400 overflow-hidden justify-center items-center">
        <FontAwesomeIcon icon={faBell} className="h-8 w-6" />
        {unreadNotificationList && unreadNotificationList?.length > 0 && (
          <div className="h-5 w-5 absolute top-4 left-2 rounded-full bg-red-700 text-xs flex justify-center items-center">
            {unreadNotificationList?.length}
          </div>
        )}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="flex absolute top-24 right-4 p-5 w-1/4 pt-2  divide-y divide-gray-100 bg-instant-teams-teal-Main shadow-lg rounded-md">
          <div className="flex w-1/4 flex-1 flex-col items-center ">
            <h3 className="font-bold">NOTIFICATIONS</h3>
            {unreadNotificationList?.length > 0 && (
              <div className="w-full text-right text-sm underline decoration-solid">
                <span
                  className="cursor-pointer text-center"
                  onClick={handleMarkAllRead}
                >
                  Mark All As Read
                </span>
              </div>
            )}
            {unreadNotificationList &&
              unreadNotificationList
                ?.slice(0, 5)
                ?.map((userNotification) => (
                  <Notification
                    userNotification={userNotification}
                    key={userNotification.notificationId}
                  />
                ))}
            {unreadNotificationList && unreadNotificationList.length === 0 && (
              <div>No Unread notifications</div>
            )}
            <Link to={`/notifications/${user?.user?.id}`}>
              <p className="underline cursor-pointer mt-4">
                Show All Notifications
              </p>
            </Link>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default NotificationList;
