import { DisplayTimestamp } from "../../util/timestamps/timestamp";
import { useUser } from "../../providers/database";
import { useRole } from "../../hooks/useRole";
import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";
import { StatusHistory } from "../../dataTypes/StatusHistory";
import { getJobStateLabelFromJobState } from "../../util/search/getLabel";

function ChangeLogItem({ entry }: { entry: StatusHistory }) {
  const { data: alteredBy } = useUser(entry?.alteredBy);

  const getRole = useRole();
  const role = getRole(alteredBy?.claims?.role, true);

  return (
    <>
      <tr
        className={
          TailwindHoverEnlarge(103) + "pl-5 border-b border-black h-24"
        }
      >
        <td className="w-1/4 p-2">
          <strong>
            by: {alteredBy?.preferredName ?? alteredBy?.name} - {role ?? ""}
          </strong>
          <br />
          <span className="text-xs">
            Date: {DisplayTimestamp(entry?.effectiveDate)}
          </span>
        </td>
        <td className="w-1/2">
          <br />
          {getJobStateLabelFromJobState(entry?.from)} <span>-&gt;</span>{" "}
          {getJobStateLabelFromJobState(entry?.status)}
        </td>
      </tr>
    </>
  );
}

export default ChangeLogItem;
