import { useAllMeetingsByRequestorFutureByStatus, usePendingMeetingsByRequestor } from "../../../data/meetings";
import { requireAuth, useAuth } from "../../../providers/auth";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import MeetingList from "../../live/MeetingList";
function TacMeetings() {
  const { user } = useAuth();
  const { data: userMeetingsData } = useAllMeetingsByRequestorFutureByStatus(user?.uid, "scheduled");
  const{ data: pendingMeetings} = usePendingMeetingsByRequestor(user?.uid);
  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <div className='mr-10'>
          <GreenUnderlineHeading title="Meeting Invites" />
          <MeetingList meetings={pendingMeetings} />
        </div>
        <div>
          <GreenUnderlineHeading title="Upcoming Meetings" />
          <MeetingList meetings={userMeetingsData} />
        </div>
      </div>
    </>
  );
}
export default requireAuth(TacMeetings);
