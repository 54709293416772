import React, { useState, useEffect } from "react";
import { useUser } from "../../../../data/user";
import { Timestamp } from "firebase/firestore";
import { LocationItems } from "../../../../dataTypes/Company";
import { DisplayDate } from "../../../../util/timestamps/timestamp";
import AutoSaveText from "../../../custom-ui/input-fields/auto-save-field";
import AutoSaveSelectEmployerUsers from "../../../custom-ui/input-fields/auto-save-select-by-employer-role";
import AutoSaveToggle from "../../../custom-ui/input-fields/auto-save-toggle";
import { TailwindHoverEnlarge } from "../../../../util/tailwind/tailwind-styles";
import Tooltip from "../../../custom-ui/tooltip/tooltip";
const LocationItem = ({
  key,
  employerId,
  location,
}: {
  key: string;
  employerId: string;
  location: LocationItems;
}) => {
  const [createdBy, setCreatedBy] = useState(location.createdBy);
  const [creatorName, setCreatorName] = useState("Unknown");
  const [collectionName, setCollectionName] = useState("");
  const [mapSource, setMapSource] = useState(
    "https://maps.google.com/maps?q=35.856737, 10.606619&z=15&output=embed",
  );

  const {
    data: userData = {},
    status: userStatus = "",
    error: userError,
  } = useUser(createdBy);

  useEffect(() => {
    console.log(location);
    setMapSource(
      "https://maps.google.com/maps?q=" +
        location.address.latitude +
        ", " +
        location.address.longitude +
        "&z=15&output=embed",
    );
    setCreatedBy(location?.createdBy);
  }, [location]);

  useEffect(() => {
    setCreatorName(userData?.preferredName);
  }, [userData]);

  useEffect(() => {
    const collectionUpdate = "Employer/" + employerId + "/Locations";
    setCollectionName(collectionUpdate);
  }, [employerId, collectionName]);

  return (
    <div
      key={key}
      className={
        TailwindHoverEnlarge(103) +
        "rounded-md border border-black border-solid shadow-md bg-white p-1.5 p-3"
      }
    >
      <div className="text-xl flex items-center justify-center w-100">
        <AutoSaveText
          value={location?.label}
          collection={collectionName}
          recId={location?.id}
          required={true}
          name="label"
          display="Location Name"
          placeholder={"Location Name"}
          directions=""
          readOnly={false}
        />
      </div>
      <div className="text-xl flex items-center justify-center w-100">
        <AutoSaveSelectEmployerUsers
          value={location?.manager}
          employerId={employerId}
          collection={collectionName}
          recId={location?.id}
          required={false}
          name="manager"
          display="Location Manager"
          placeholder={"Location Manager"}
          directions=""
        />
      </div>
      <div className="text-xs flex items-center justify-center w-100 pt-5">
        {location?.address?.standardizedAddress}
      </div>
      <div className="text-xs flex items-center justify-center w-100 pt-2 mb-5">
        {location?.timezone?.name}
      </div>
      <div className="mx-auto center-items mb-4 w-full">
        <iframe
          className="w-full"
          src={mapSource}
          width="360"
          height="270"
          frameBorder="0"
          style={{ border: 0 }}
        ></iframe>
      </div>
      <div className="mx-auto center-items mb-4 w-full ml-4">
        <Tooltip text="Status" position="-top-8 left-1/4">
          <AutoSaveToggle
            value={location?.active}
            collection={collectionName}
            id={location?.id}
            name={"active"}
            on={"Active"}
            off={"Inactive"}
          />
        </Tooltip>
      </div>
      <div className="mx-auto center-items mb-4 w-full ml-4">
        <Tooltip text="Location Type" position="-top-8 left-1/3">
          <AutoSaveToggle
            value={location?.remote}
            collection={collectionName}
            id={location?.id}
            name={"remote"}
            on={"Remote Only"}
            off={"On-Site/Hybrid/Remote"}
          />
        </Tooltip>
      </div>
      <div className="text-xs flex items-center justify-center w-100 pt-5">
        (Created: {creatorName} on {DisplayDate(location?.createdAt)})
      </div>
    </div>
  );
};

export default LocationItem;
