import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../providers/auth";
import { TailwindHoverEnlarge } from "../../../../util/tailwind/tailwind-styles";
import Tooltip from "../../../custom-ui/tooltip/tooltip";
import { useEmployer } from "../../../../data/employer";
import { useJobDescription } from "../../../../data/jobDescriptions";
import { useJobPositionDetails } from "../../../../data/positions";
import { useJobOffer } from "../../../../data/offers";
import { useUserByEmail2 } from "../../../../data/user";
// import { useUser } from "../../../providers/database";
import { useUser } from "../../../../data/user";

import { useEmploymentByUserIdAndJobId } from "../../../../data/employee";
import { formatMoney } from "../../../../util/currency/currency";
import { DisplayDate } from "../../../../util/timestamps/timestamp";
const PendingOffersItem = ({ offer }: { offer: any }) => {
  const navigate = useNavigate();
  const { data: employerData, isLoading: isEmployerLoading } = useEmployer(
    offer?.employerId,
  );
  const { data: jobDescription, isLoading: isJobLoading } = useJobDescription(
    offer?.jobId,
  );

  const { data: user, isLoading: isUserLoading } = useUser(offer?.resumeId);

  const { data: positionDetails, isLoading: isPositionDataLoading } =
    useJobPositionDetails(offer?.jobId, offer?.positionId);

  const { data: employeeData, isLoading: isEmployeeDataLodaing } =
    useEmploymentByUserIdAndJobId(offer?.resumeId, offer?.jobId);

  return (
    <div className="grid grid-cols-8">
      <div
        onClick={() => {
          navigate(`/offers/${offer?.id}/details`);
        }}
        className={
          TailwindHoverEnlarge(103) +
          " cursor-pointer bg-white rounded-lg h-28 shadow-lg col-span-8"
        }
      >
        <div className="items-center w-full rounded-lg relative">
          <div className="items-center bg-instant-teams-teal-Main p-1 rounded-t-lg w-full">
            <div className="w-full text-white pl-2 text-center">
              <p className=" text-sm">{user?.name ?? "N/A"}</p>
            </div>
          </div>
          <div className="grid grid-cols-8  mt-2">
            <div className="grid col-span-2">
              <img
                className="object-scale-down max-h-16 h-16 object-center resize rounded-md mx-auto px-4"
                src={employerData?.logo || "/images/default-employer.jpg"}
                alt={employerData?.displayName}
              />
            </div>
            <div className="grid col-span-3 flex items-center justify-start">
              <p className="text-sm">
                <strong>{employerData?.displayName}</strong>
                <br />
                {jobDescription?.title}
              </p>
            </div>
            <div className="grid col-span-3 flex items-center justify-start">
              <p className="text-sm">
                <strong>Position: </strong>
                {positionDetails?.positionName?.split("|")[0]}
                <br />
                <strong className="pr-1">Pay Rate: </strong>$
                {formatMoney(offer?.payRate)}
                <br />
                <strong>Start Date: </strong>
                {DisplayDate(offer?.startDate)}
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingOffersItem;
