import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import {useApplicationsByJobAndStatus} from "../data/applications";
import { faKeySkeletonLeftRight } from '@fortawesome/pro-solid-svg-icons';

ChartJS.register(ArcElement, Tooltip, Legend);

function ApplicationCompletionMetrics({ jobId }) {
  const { data: apps } = useApplicationsByJobAndStatus(jobId, "applied");
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);


  useEffect(() => {
    if (apps) {
      const valuesCountMap = new Map();
      const cData =[];
      const cLabels = [];
      for(const app of apps) {
        if (valuesCountMap.has(app.overallProgress)) {
          valuesCountMap.set(app.overallProgress, valuesCountMap.get(app.overallProgress) + 1);
        } else {
          valuesCountMap.set(app.overallProgress, 1);
        }
      }
      const dataArray = [];
      for(const value of valuesCountMap.entries()) {
        dataArray[value[0]] = value[1];
      }

      for(const value of dataArray.entries()) {
        if(value !== null && value[1] !== undefined && value[1] !== null && value[0] !== undefined && value[0] !== null) {
          console.log(value[0], value[1]);
          cData.push(value[1]);
          cLabels.push(value[0].toString()+"% - "+value[1].toString());
        }
      }
      setChartData(cData);
      setChartLabels(cLabels);
      console.log(chartData);
      console.log(chartLabels);
    }
  },[apps]);

  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: 'Overall Progress Counts',
        data: chartData,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="w-1/2 text-center mx-auto mb-10">
      <h5 className="text-2xl font-bold">Completion Percentages</h5>    
      <Doughnut data={data} />
    </div>
  );
}

export default ApplicationCompletionMetrics;
