import { useState } from "react";
import { Switch } from "@headlessui/react";
import { useParams } from "react-router-dom";
import { useEmployer } from "../../../data/employer";
import AutoSaveWysiwyg from "../../custom-ui/input-fields/auto-save-wysiwyg";
import AutoSaveText from "../../custom-ui/input-fields/auto-save-field";
import { useEffect } from "react";
import { useAuth } from "../../../providers/auth";
import AutoSaveWysiwygWithSwitch from "../../custom-ui/input-fields/auto-save-wysiwyg-with-flip";

const EditCompanyProfile = () => {
  // Page 2
  const [militaryImpact, setMilitaryImpact] = useState(false);
  const [companyValues, setCompanyValues] = useState(false);
  const [companyDI, setCompanyDI] = useState(false);
  const [companyBenefits, setCompanyBenefits] = useState(false);
  const [companyCulture, setCompanyCulture] = useState(false);
  const [socialImpact, setSocialImpact] = useState(false);
  const [missionStatement, setMissionStatement] = useState(false);
  const [videoURL, setVideoUrl] = useState("");
  const auth = useAuth();

  const params = useParams();
  const {
    data: employer,
    status: employerStatus,
    error: employerError,
  } = useEmployer(auth.userClaims.claims.employerId);

  useEffect(() => {
    if (employer?.profile?.militaryImpact !== null) {
      setMilitaryImpact(true);
    }
    if (employer?.profile?.values !== null) {
      setCompanyValues(true);
    }
    if (employer?.profile?.dei !== null) {
      setCompanyDI(true);
    }
    if (employer?.profile?.benefits !== null) {
      setCompanyBenefits(true);
    }
    if (employer?.profile?.culture !== null) {
      setCompanyCulture(true);
    }
    if (employer?.profile?.social !== null) {
      setSocialImpact(true);
    }
    if (employer?.profile?.mission !== null) {
      setMissionStatement(true);
    }
    const videoLink = employer?.profile?.video;
    const cleanLink = videoLink?.split("watch?v=")[1];
    setVideoUrl(cleanLink);
  }, [employer]);

  return (
    <>
      <div className="xl:container xl:mx-auto">
        <div className="mx-auto m-5">
          <AutoSaveText
            value={employer?.profile?.foundedYear}
            collection="Employer"
            recId={employer?.id}
            required={false}
            name="profile.foundedYear"
            display="Founded Year"
            placeholder={"Founded Year"}
            directions=""
            readOnly={false}
            maxlength={4}
          />
          <AutoSaveText
            value={employer?.profile?.crunchbase}
            collection="Employer"
            recId={employer?.id}
            required={false}
            name="profile.crunchbase"
            display="Crunchbase Link"
            placeholder={"Crunchbase Link"}
            directions="Full URL"
            readOnly={false}
            maxlength={200}
          />
          <AutoSaveText
            value={employer?.profile?.facebook}
            collection="Employer"
            recId={employer?.id}
            required={false}
            name="profile.facebook"
            display="Facebook Link"
            placeholder={"Facebook Link"}
            directions="Full URL"
            readOnly={false}
            maxlength={200}
          />
          <AutoSaveText
            value={employer?.profile?.instagram}
            collection="Employer"
            recId={employer?.id}
            required={false}
            name="profile.instagram"
            display="Instagram Link"
            placeholder={"Instagram Link"}
            directions="Full URL"
            readOnly={false}
            maxlength={200}
          />
          <AutoSaveText
            value={employer?.profile?.twitter}
            collection="Employer"
            recId={employer?.id}
            required={false}
            name="profile.twitter"
            display="Twitter Link"
            placeholder={"Twitter Link"}
            directions="Full URL"
            readOnly={false}
            maxlength={200}
          />
          <AutoSaveText
            value={employer?.profile?.linkedin}
            collection="Employer"
            recId={employer?.id}
            required={false}
            name="profile.linkedin"
            display="LinkedIn Profile Link"
            placeholder={"LinkedIn Profile Link"}
            directions="Full URL"
            readOnly={false}
            maxlength={200}
          />
          <AutoSaveWysiwyg
            value={employer?.profile?.overview}
            rows={5}
            collection="Employer"
            recId={employer?.id}
            required={false}
            name="profile.overview"
            display="Company Overview"
            placeholder={"Tell us about yourself..."}
            directions=""
            readOnly={false}
          />
          <AutoSaveWysiwyg
            value={employer?.profile?.intro}
            rows={5}
            collection="Employer"
            recId={employer?.id}
            required={false}
            name="profile.intro"
            display="Company Intro"
            placeholder={
              "Focused introduction of your Company to Career Seekers"
            }
            directions=""
            readOnly={false}
          />
          {videoURL ? (
            <div className="mx-auto m-5 w-full">
              <label className="text-instant-teams-teal-Main text-sm  transform -translate-y-4 scale-75">
                Company Video Preview
              </label>
              <iframe
                title={videoURL}
                width="760"
                height="315"
                src={`https://www.youtube.com/embed/${videoURL}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-full mx-auto"
              />
            </div>
          ) : (
            ""
          )}
          <AutoSaveText
            value={employer?.profile?.video}
            collection="Employer"
            recId={employer?.id}
            required={false}
            name="profile.video"
            display="Company Intro Video"
            placeholder={"YouTube Link to Company Video"}
            directions="YouTube Links Only"
            readOnly={false}
            maxlength={200}
          />
        </div>
        <div className="grid grid-cols-1 mb-10">
          <AutoSaveWysiwygWithSwitch
            value={employer?.profile?.mission}
            rows={5}
            collection="Employer"
            recId={employer?.id}
            required={false}
            name="profile.mission"
            display="Mission Statement"
            placeholder={"What is your Company Mission Statement?"}
            directions=""
            readOnly={false}
          />
        </div>
        <div className="grid grid-cols-1 mb-10">
          <AutoSaveWysiwygWithSwitch
            value={employer?.profile?.military}
            rows={5}
            collection="Employer"
            recId={employer?.id}
            required={false}
            name="profile.military"
            display="Military Community Impact"
            placeholder={"How are you impacting the Military Community?"}
            directions=""
            readOnly={false}
          />
        </div>
        <div className="grid grid-cols-1 mb-10">
          <AutoSaveWysiwygWithSwitch
            value={employer?.profile?.values}
            rows={5}
            collection="Employer"
            recId={employer?.id}
            required={false}
            name="profile.values"
            display="Core Values Statement"
            placeholder={"What are your Core Values?"}
            directions=""
            readOnly={false}
          />
        </div>
        <div className="grid grid-cols-1 mb-10">
          <AutoSaveWysiwygWithSwitch
            value={employer?.profile?.dei}
            rows={5}
            collection="Employer"
            recId={employer?.id}
            required={false}
            name="profile.dei"
            display="Diversity, Equity & Inclusion"
            placeholder={
              "What are you doing toward Diversity, Equity & Inclusion?"
            }
            directions=""
            readOnly={false}
          />
        </div>
        <div className="grid grid-cols-1 mb-10">
          <AutoSaveWysiwygWithSwitch
            value={employer?.profile?.benefits}
            rows={5}
            collection="Employer"
            recId={employer?.id}
            required={false}
            name="profile.benefits"
            display="Company Benefits"
            placeholder={"Company Benefits amazing? Thell us about it."}
            directions=""
            readOnly={false}
          />
        </div>
        <div className="grid grid-cols-1 mb-10">
          <AutoSaveWysiwygWithSwitch
            value={employer?.profile?.culture}
            rows={5}
            collection="Employer"
            recId={employer?.id}
            required={false}
            name="profile.culture"
            display="Company Culture"
            placeholder={"Describe your Company Culture."}
            directions=""
            readOnly={false}
          />
        </div>
        <div className="grid grid-cols-1 mb-10">
          <AutoSaveWysiwygWithSwitch
            value={employer?.profile?.social}
            rows={5}
            collection="Employer"
            recId={employer?.id}
            required={false}
            name="profile.social"
            display="Social Impact"
            placeholder={"How are you making an impact?"}
            directions=""
            readOnly={false}
          />
        </div>
      </div>
    </>
  );
};

export default EditCompanyProfile;
