import { useQuery } from "react-query";
import {
  doc,
  collection,
  query,
  setDoc,
  where,
  orderBy,
  getDoc,
  getDocs,
  updateDoc,
  runTransaction,
  deleteDoc,
  addDoc,
  Timestamp,
} from "firebase/firestore";
import { db, createQuery } from "../providers/database";

import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";

export function useAllMarketings() {
  return useQuery(
    ["marketing-listings"],
    createQuery(() =>
      query(
        collection(db, collectionNames.marketing),
        orderBy("createdAt", "desc"),
      ),
    ),
    { enabled: true },
  );
}

export function useMarketingCampaign(campaignId: string) {
  return useQuery(
    ["marketing-campaign", { campaignId }],
    createQuery(() => doc(db, collectionNames.marketing, campaignId)),
  );
}

export function createMarketingListing(data: any) {
  return addDoc(collection(db, collectionNames.marketing), data);
}
export function useUserMarketingSubCollection(id: string) {
  return useQuery(
    ["allUserMarketings", { id }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.users}/${id}/${subCollectionNames.marketing}`,
        ),
      );
    }),
    { enabled: !!id },
  );
}

const getMarketingViewsDocRef = (userId: string, marketingId: string) => {
  return doc(
    db,
    collectionNames.users,
    userId,
    subCollectionNames.marketing,
    marketingId,
  );
};

export const updateMarketingViews = (
  uid: string,
  marketingId: string,
  timestamps: number[],
) => {
  const docRef = getMarketingViewsDocRef(uid, marketingId);
  return setDoc(docRef, { timestamps });
};

export function useAllActiveMarketings() {
  return useQuery(
    ["active-marketing-listings"],
    createQuery(() =>
      query(
        collection(db, collectionNames.marketing),
        where("active", "==", true),
        orderBy("schedule.dateRange.startDate", "desc"),
      ),
    ),
    { enabled: true },
  );
}
