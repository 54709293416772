import { createContext, useState, useMemo } from "react";
export const ApplicationContext = createContext({
  recordings: [],
  setRecordings: () => null,
});

const ApplicationProvider = ({ children }) => {
  const [recordings, setRecordings] = useState([]);

  const providerValue = useMemo(
    () => ({ recordings, setRecordings }),
    [recordings, setRecordings],
  );

  return (
    <ApplicationContext.Provider value={providerValue}>
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationProvider;
