import JobListingShareRender from '../../components/socials/JobListingDetailsRender';
const ReactDOMServer = require('react-dom/server');

// Function to turn a React component into static HTML
export function renderJobToStaticHTML(share, job, employer, positions, skills) {
    // Render the component to static HTML
    const staticHTML = ReactDOMServer.renderToStaticMarkup(<JobListingShareRender 
        share={share}
        jobData={job}
        employerData={employer}
        jobPositions={positions}
        jobDescriptionSkills={skills}
    />);
    console.log(staticHTML);
    return staticHTML;
}

