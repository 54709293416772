import { memo } from "react";
import React from "react";
import { cn } from "../../../util/cn";

const Tooltip = memo(
  ({ text, position = "top-6 left-1/2", width = "400px", children }) => {
    return (
      <span className="group relative grid">
        <span
          className={cn(
            "pointer-events-none  absolute -translate-x-3/4 rounded-[10px] bg-[white] px-4 py-2 text-[#616161] opacity-0 transition before:absolute before:left-3/4 before:bottom-full before:-translate-x-1/2 before:border-8 before:border-transparent before:border-b-[#f8f8f8] before:content-['']  group-hover:opacity-100 z-40 whitespace-normal shadow-md border-t-[#f8f8f8] border-t-[1px]",
            position,
          )}
          style={{
            width: width,
          }}
        >
          {text}
        </span>

        {React.cloneElement(children)}
      </span>
    );
  },
);

export default Tooltip;
