import {
  faCirclePlus,
  faScreenUsers,
  faCircleInfo,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import useModal from '../../../../hooks/useModal';
import { DisplayDate } from '../../../../util/timestamps/timestamp';
import DialogWrapper from '../../../custom-ui/dialog/Dialog';
import CreateEmployerTraining from './CreateEmployerTraining';

function SortableList({
  list = [],
  displayField,
  collection,
  parentId,
  subcollection,
  callback,
  returnItem,
}) {
  const [active, setActive] = useState("");
  const { toggle: createTrainingToggle, isShowing: createTraining } = useModal();

  function selectItem(item) {
    setActive(item.id);
    returnItem(item);
  }

  const styling = {
    active:
      "w-full items-start grid grid-cols-10 gap-1 p-2 mb-2 rounded-lg shadow-lg col-span-9 bg-instant-teams-teal-Main text-white",
    inactive:
      "w-full items-start text-instant-teams-teal-Main grid grid-cols-10 gap-1 p-2 mb-2 rounded-lg shadow-lg col-span-9 hover:bg-instant-teams-teal-Main hover:text-white",
  };

  return (
    <div className="w-full">
      <div className="w-full text-right mb-4">
        <button className="" onClick={createTrainingToggle}>
          <FontAwesomeIcon
            icon={faCirclePlus}
            className="mr-1 text-instant-teams-green-Main h-6 w-6"
          />
        </button>
      </div>
      {list.map((item) => (
        <div key={item.id} className="grid grid-cols-10 gap-1 mb-4">
          <div
            key={item.id}
            className={active === item.id ? styling.active : styling.inactive}
          >
            <div
              key={item.id}
              className="col-span-8 font-bold pl-2"
              onClick={() => {
                callback(item.id);
                selectItem(item);
              }}
            >
              {item[displayField]} - {DisplayDate(item.startDate)}
            </div>
            <div className='col-span-1 text-center'>
              <FontAwesomeIcon icon={faScreenUsers}/>
            </div>
            <div className='col-span-1 text-center'>
              <FontAwesomeIcon icon={faCircleInfo}/>
            </div>          
          </div>
           
        </div>
      ))}
      {createTraining && (
        <DialogWrapper
          title="Add New Cycle"
          onClose={() => {
            createTrainingToggle();
          }}
        >
          <CreateEmployerTraining closeModal={createTrainingToggle}/>
        </DialogWrapper>
      )}
    </div>
  );
}

export default SortableList;
