import SkillsList from "../../parts/SkillsList";
import { useJobDescriptionSkills } from "../../../../data/jobDescriptions";
import { useParams } from "react-router-dom";
function ExternalJobSkills() {
  // this is the jobId
  const { id = "" } = useParams();

  const { data: skills } = useJobDescriptionSkills(id);
  return (
    <>
      <div className=" w-full">
        <div>
          <SkillsList skills={skills} />
        </div>
      </div>
    </>
  );
}

export default ExternalJobSkills;
