import { EmployerOnboarding } from "../../features/onboarding/employers";
import Meta from "../../components/custom-ui/meta/Meta";
import { requireAuth } from "../../providers/auth";
import HeroImageWrapper from "../../components/layout/HeroImageWrapper";

function EmployerOnboardingPage() {
  return (
    <div className="pt-32 ">
      <Meta title="Onboarding" />
      <HeroImageWrapper>
        <EmployerOnboarding />
      </HeroImageWrapper>
    </div>
  );
}

export default requireAuth(EmployerOnboardingPage);
