import { useState, useEffect } from "react";
import { createNewConversation, useAllChats } from "../../data/messages";
import { useAuth } from "../../providers/auth";
import { useUser, useAllUsers } from "../../data/user";
import { useEmployerUsers, useEmployer } from "../../data/employer";
import { User } from "../../dataTypes/User";
import { Employer } from "../../dataTypes/Employer";
import { useLookup } from "../../data/lookup";
import { toast } from "react-hot-toast";
import ToastAlert from "../custom-ui/toast-alert/ToastAlert";
import { useParams, useNavigate } from "react-router-dom";
import { useUsersByRole } from "../../data/user";

const NewConversationModal = ({
  toggle,
  recipientId,
  employerId,
}: {
  toggle: () => void;
  recipientId?: string;
  employerId?: string;
}) => {
  const [data, setData] = useState({});
  const [value, setValue] = useState("");
  const [messageText, setMessageText] = useState("");
  const [showList, setShowList] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [messageGroup, setMessageGroup] = useState("");
  const [unreadIds, setUnreadIds] = useState([]);
  const [tempRecipient, setTempRecipient] = useState("");
  const userId = useAuth()?.user?.id;
  const params = useParams();

  const { data: recipientFromParams } = useUser(
    params?.seekerId || recipientId || "",
  );
  const { data: userData } = useUser(userId);
  const { data: recipientData } = useUser(
    data?.recipientId || recipientFromParams,
  );
  const { data: employerData } = useEmployer(employerId || "");
  const { data: allUsers } = useAllUsers();
  const { data: adminRoles } = useLookup("adminRole");
  const { data: allChats } = useAllChats();
  const { data: usersByRoleFromAdmin } = useUsersByRole(userData?.claims?.role);
  const { data: usersByRoleFromNonAdmin } = useUsersByRole(messageGroup);
  const { data: usersByEmployer } = useEmployerUsers(employerId || "");
  const navigate = useNavigate();

  const isOnMessagesRoute = window.location.pathname.includes("/messages/");

  useEffect(() => {
    if (employerId && usersByEmployer && usersByRoleFromAdmin) {
      const tempIds = [
        ...usersByEmployer?.map((user: Employer) => user.id),
        ...usersByRoleFromAdmin?.map((user: User) => user.id),
      ];
      setData({
        ...data,
        employerId: employerId,
        status: "inbox",
        unreadUsers: tempIds?.filter((id: string) => id !== userId),
        userId: userId,
        recipientName: employerData?.legalName,
        users: tempIds,
      });
    } else if (recipientId) {
      setData({
        ...data,
        recipientEmail:
          recipientData?.email || recipientFromParams?.email || "",
        recipientId: recipientData?.id || params?.seekerId || recipientId || "",
        recipientName: recipientData?.name || recipientFromParams?.name || "",
        status: "inbox",
        userId: userId,
      });
    } else {
      setData({
        ...data,
        recipientEmail:
          recipientData?.email || recipientFromParams?.email || "",
        recipientId: recipientData?.id || params?.seekerId || recipientId || "",
        recipientName: recipientData?.name || recipientFromParams?.name || "",
        status: "inbox",
        userId: userId,
      });
    }
  }, [
    userId,
    userData,
    recipientData?.id,
    recipientData?.email,
    recipientData?.name,
    recipientId,
    recipientFromParams?.email,
    recipientFromParams?.name,
    employerId,
    params?.seekerId,
    usersByEmployer,
    usersByRoleFromAdmin,
    employerData?.legalName,
  ]);

  useEffect(() => {
    const userIds = usersByRoleFromAdmin
      ?.map((item: any) => item.id)
      .filter((id: any) => id !== userData?.id);
    if (
      userIds?.length > 0 &&
      recipientData?.id !== null &&
      recipientData?.id !== undefined
    ) {
      setUnreadIds([...userIds, recipientData?.id]);
    } else if (
      userIds?.length > 0 &&
      userData?.role !== "A" &&
      usersByRoleFromNonAdmin?.length > 0
    ) {
      const tempIds = usersByRoleFromNonAdmin?.map((item: any) => item.id);
      setUnreadIds([...tempIds]);
    }
  }, [
    usersByRoleFromAdmin,
    data,
    recipientData,
    userData?.id,
    value,
    userData?.role,
    usersByRoleFromNonAdmin,
  ]);

  const handleSelect = (event: any) => {
    event.preventDefault();
    setMessageType(event.target.value);
  };

  const handleMessageGroup = (event: any) => {
    event.preventDefault();
    setMessageGroup(event.target.value);
  };

  const handleChange = (event: any) => {
    const searchTerm = event.target.value;
    setShowList(true);
    if (searchTerm === "" && setShowList(true)) {
      setShowList(false);
    }
    setValue(searchTerm);
    event.preventDefault();
  };

  const handleSendClickForAdmin = () => {
    if (recipientData?.claims?.type === "S" && messageType === "personal") {
      toast.error("Cannot send a personal message to this user type/role");
      return;
    }
    const chatExists = allChats.some((chat: any) => {
      return (
        chat.recipientId === data.recipientId &&
        chat.group === userData.claims.role
      );
    });
    const personalChatExists = allChats.some((chat: any) => {
      return (
        chat.messageType === "personal" &&
        chat.users.includes(userId) &&
        chat.users.includes(data?.recipientId)
      );
    });
    if (
      (messageType === "group" && chatExists) ||
      (messageType === "personal" && personalChatExists)
    ) {
      toast.error("Message already exists");
      return;
    }
    if (
      messageText &&
      data?.recipientName &&
      data?.recipientId &&
      data?.status &&
      messageType &&
      !employerId
    ) {
      let newData = {};
      if (messageType === "personal") {
        newData = {
          ...data,
          body: messageText,
          messageType: messageType,
          group: "oneOnOne",
          users: [userId, recipientData?.id],
          unreadUsers: [recipientData?.id],
          createdByName: userData?.name,
        };
        createNewConversation(newData);
        toggle();
      } else if (messageType === "group") {
        newData = {
          ...data,
          body: messageText,
          messageType: messageType,
          group: userData?.claims?.role,
          unreadUsers: [recipientData?.id],
          createdByName: userData?.name,
        };
        createNewConversation(newData);
        toggle();
        if (!isOnMessagesRoute && recipientData?.claims?.type !== "S") {
          navigate(`/messages/${userId}`);
        }
      }
    } else if (messageText && employerId) {
      let newData = {};
      if (messageType === "personal") {
        newData = {
          ...data,
          employerId: employerId,
          body: messageText,
          messageType: messageType,
          group: "oneOnOne",
          users: [userId, recipientData?.id],
          unreadUsers: [recipientData?.id],
          createdByName: userData?.name,
        };
        createNewConversation(newData);
        toggle();
      } else if (messageType === "group") {
        newData = {
          ...data,
          unreadUsers: [recipientData?.id],
          body: messageText,
          messageType: messageType,
          group: userData?.claims?.role,
          createdByName: userData?.name,
        };
        createNewConversation(newData);
        toggle();
      }
    }
  };

  const handleSendClickForNonAdmin = () => {
    const chatExists = allChats.some((chat: any) => {
      return chat.recipientId === userId && chat.group === messageGroup;
    });
    if (chatExists) {
      toast.error("Message already exists");
      return;
    } else {
      if (messageGroup && messageText) {
        let messageData = {
          body: messageText,
          group: messageGroup,
          messageType: "group",
          recipientId: userId,
          recipientEmail: userData?.email,
          recipientName: userData?.preferredName,
          status: "inbox",
          userId: userId,
          unreadUsers: unreadIds,
          createdByName: userData?.name,
        };
        createNewConversation(messageData);
        toggle();
      }
    }
  };

  return (
    <div>
      {userData?.claims?.type === "A" ? (
        <>
          <div className="flex w-[80%] items-center justify-between mb-2">
            Type of message:
            <select
              className="form-input shadow-md rounded-md w-[60%] pt-2 "
              onChange={handleSelect}
            >
              <option value="">Select message type</option>
              <option value="personal">Personal</option>
              <option value="group">Group</option>
            </select>
          </div>
          <div className="relative">
            {employerId && messageType === "group" ? (
              <div>Send Message to All {employerData?.legalName} Users</div>
            ) : employerId && messageType === "personal" ? (
              <div>
                Send Message To:
                {allUsers && allUsers?.length > 0 && (
                  <>
                    <input
                      type="text"
                      placeholder="Search for users"
                      value={value}
                      onChange={handleChange}
                      className="form-input shadow-lg border-2 w-1/2 ml-4 mb-4"
                    />
                    <div
                      className={`max-h-44 overflow-y-scroll z-30 m-auto ${
                        showList ? "" : "hidden"
                      } `}
                    >
                      {allUsers
                        .filter((user: User) =>
                          user?.name
                            ?.toLowerCase()
                            ?.includes(value?.toLowerCase()),
                        )
                        .filter(
                          (user: User) =>
                            user?.claims?.employerId === employerId,
                        )
                        .map((user: User) => (
                          <div
                            key={user?.id}
                            className="bg-white border border-gray-300 hover:bg-gray-100 cursor-pointer py-2 px-4"
                            onClick={() => {
                              setValue(user.name);
                              setData((prevData: any) => ({
                                ...prevData,
                                recipientId: user.id,
                                recipientName: user.name,
                              }));
                            }}
                          >
                            {user?.name}
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </div>
            ) : (
              ""
            )}
            {allUsers &&
            allUsers?.length > 0 &&
            !params?.seekerId &&
            !recipientId &&
            !employerId ? (
              <>
                <div className="mb-10">
                  Send message to:
                  <input
                    type="text"
                    placeholder="Search for users"
                    value={value}
                    onChange={handleChange}
                    className="form-input shadow-lg border-2 w-1/2 ml-4 mb-4"
                  />
                  <div
                    className={`max-h-44 overflow-y-scroll z-30 m-auto ${
                      showList ? "" : "hidden"
                    } `}
                  >
                    {allUsers &&
                      allUsers?.length > 0 &&
                      employerId &&
                      allUsers
                        .filter((user: User) =>
                          user?.name
                            ?.toLowerCase()
                            ?.includes(value?.toLowerCase()),
                        )
                        .filter(
                          (user: User) =>
                            user?.claims?.employerId === employerId,
                        )
                        .map((user: User) => (
                          <div
                            key={user?.id}
                            className="bg-white border border-gray-300 hover:bg-gray-100 cursor-pointer py-2 px-4"
                            onClick={() => {
                              setValue(user.name);
                              setData((prevData: any) => ({
                                ...prevData,
                                recipientId: user.id,
                                recipientName: user.name,
                              }));
                            }}
                          >
                            {user?.name}
                          </div>
                        ))}
                    {allUsers &&
                      allUsers?.length > 0 &&
                      !employerId &&
                      allUsers
                        .filter((user: User) =>
                          user?.name
                            ?.toLowerCase()
                            ?.includes(value?.toLowerCase()),
                        )
                        .map((user: User) => (
                          <div
                            key={user?.id}
                            className="bg-white border border-gray-300 hover:bg-gray-100 cursor-pointer py-2 px-4"
                            onClick={() => {
                              setValue(user.name);
                              setData((prevData: any) => ({
                                ...prevData,
                                recipientId: user.id,
                                recipientName: user.name,
                              }));
                            }}
                          >
                            {user?.name}
                          </div>
                        ))}
                  </div>
                </div>
              </>
            ) : (
              <div>{recipientFromParams?.name}</div>
            )}

            <div className="flex flex-col w-full mt-4 h-2/3">
              <textarea
                style={{
                  height: "calc(100% - 2rem)",
                  boxSizing: "border-box",
                  resize: "none",
                }}
                value={messageText}
                placeholder="Type your message here..."
                className=" "
                onChange={(e) => setMessageText(e.target.value)}
              />
              <button
                className=" bg-instant-teams-teal-Main text-white py-2 px-4 rounded mt-4 text-sm"
                style={{ alignSelf: "flex-end" }}
                onClick={handleSendClickForAdmin}
              >
                Send
              </button>
            </div>
          </div>
          <ToastAlert />
        </>
      ) : (
        <>
          <div className="relative">
            Send message to:
            <select
              className="form-input shadow-md rounded-md w-[60%] pt-2 m-4"
              onChange={handleMessageGroup}
            >
              <option value="">Select contact</option>
              {adminRoles &&
                adminRoles.map((role: any, index: number) => (
                  <option value={role?.code} key={index}>
                    {role?.label}
                  </option>
                ))}
            </select>
            <div className="flex flex-col w-full mt-4 h-2/3">
              <textarea
                style={{
                  height: "calc(100% - 2rem)",
                  boxSizing: "border-box",
                  resize: "none",
                }}
                value={messageText}
                placeholder="Type your message here..."
                className=" "
                onChange={(e) => setMessageText(e.target.value)}
              />
              <button
                className=" bg-instant-teams-teal-Main text-white py-2 px-4 rounded mt-4 text-sm"
                style={{ alignSelf: "flex-end" }}
                onClick={handleSendClickForNonAdmin}
              >
                Send
              </button>
            </div>
          </div>
        </>
      )}
      <ToastAlert />
    </div>
  );
};

export default NewConversationModal;
