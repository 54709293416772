import GeneralInfoCategoryPost from "./GeneralInfoCategoryPost";

const GeneralInfoPostDisplay = ({ posts }) => {
  return (
    <>
      {posts &&
        posts?.length > 0 &&
        posts.map((post) => <GeneralInfoCategoryPost id={post?.node?.id} />)}
    </>
  );
};

export default GeneralInfoPostDisplay;
