import React, { useEffect, useState } from "react";
import { splitCapitalize } from "../../../util/strings/stringManipulation";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";

function PricingDetails(details) {
  return (
    <div className="w-full mt-5">
      <GreenUnderlineHeading title="Pricing" />
      <div className="w-full flex">
        <span className="float-left w-1/2">Premium: </span>
        <span className="float-right w-1/2">
          {details?.pricing?.is_premium ? "Yes" : "No"}
        </span>
      </div>
      {details?.pricing?.features &&
        details?.pricing?.features.map((item, index) => {
          return (
            <div className="w-full flex" key={index}>
              <span className="float-left w-1/2">Feature: </span>
              <span className="float-right w-1/2">
                {splitCapitalize(item, "_")}
              </span>
            </div>
          );
        })}
    </div>
  );
}

export default PricingDetails;
